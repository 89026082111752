import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import LoadingBtn from './Forms/LoadingBtn';

const drawerRoot = document.getElementById('drawer-root');

const FloatBar = ({
  open,
  disabled,
  onToggle,
  onSubmit,
  children
}) => {
  return ReactDOM.createPortal(
    <div className={`ui-theme-settings${open ? ' settings-open' : ''}`}>
      <button type="button" onClick={onToggle} className="btn-open-options btn btn-warning">
        <FontAwesomeIcon icon={faCog} size="2x" spin />
      </button>
      <div className="theme-settings__inner">
        <div className="floatbar-filter-wrapper">
          <div className="theme-settings__options-wrapper">
            {children}
          </div>
        </div>
        <div className="floatbar-submit-btn">
          <LoadingBtn type="button" disabled={disabled} onClick={onSubmit} className="btn btn-primary btn-block btn-lg">Submit</LoadingBtn>
        </div>
      </div>
    </div>
    , drawerRoot
  );
}

export default FloatBar
