import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { INJECTION_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import UserSelect from '../../../components/Select/UserSelect';
import { toIDR } from '../../../utils/helpers/currency';
import { PRIMARY, POINT } from '../../../utils/constants/dataTypes';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MerchantSelect from '../../../components/Select/MerchantSelect';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import { injectionApi } from '../../../services/injectionApi';

const schema = yup.object().shape({
  model: yup.string().required(),
  userId: yup.string()
    .typeError('Please select a user')
    .when('model', (field, yup) => (field === 'User' ? yup.required('Please select a user') : yup)),
  merchantId: yup.string()
    .typeError('Please select a user')
    .when('model', (field, yup) => (field === 'Merchant' ? yup.required('Please select a merchant') : yup)),
  type: yup.string().required(),
  amount: yup.number().typeError('Must be a number').required(),
  desc: yup.string(),
});

const InjectTab = () => {
  const dispatch = useDispatch();
  const { isCreating, } = useSelector(state => state.deduction);

  const submit = (values) => {
    let data = {
      applicant: values.model === 'User' ? values.userId : values.merchantId,
      applicantModel: values.model,
      description: values.desc,
      primary: values.type === PRIMARY ? values.amount : 0,
      point: values.type === POINT ? values.amount : 0,
    }
    dispatch({ module: INJECTION_MODULE, type: CREATE, call: injectionApi.inject, args: [data] })
      .catch(() => { })
  };

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          isLoading={isCreating}
          submit={submit}
        />
      </div>
    </div>
  )
}

const Form = ({ initialValue, submit, isLoading }) => {
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      model: 'User',
      type: PRIMARY,
      ...initialValue
    }
  })

  const { model, userId, merchantId, type, amount } = watch(['model', 'userId', 'merchantId', 'type', 'amount'])

  useEffect(() => {
    register("model")
    register("type")

    return () => {
      unregister("model")
      unregister("type")

      unregister("userId")
      unregister("merchantId")
    }
  }, [register, unregister])

  useEffect(() => {
    if (model === 'User') register("userId");
    else unregister("userId")
    if (model === 'Merchant') register("merchantId");
    else unregister("merchantId")
  }, [register, unregister, model]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Transaction Inject Form</h5>
        </div>
        <div className="col-12 col-md-9">
          <div>
            <label>Model</label>
            <RadioGroup row name="model" value={model} onChange={e => setValue('model', e.target.value)}>
              <FormControlLabel value="User" control={<Radio color="primary" />} label="User" />
              <FormControlLabel value="Merchant" control={<Radio color="primary" />} label="Merchant" />
            </RadioGroup>
          </div>
          {
            model === 'User'
              ?
              <UserSelect
                value={userId ?? null}
                onChange={v => setValue('userId', v?.value ?? null, { shouldValidate: true })}
                error={errors.userId}
                helperText={errors.userId?.message ?? null}
              />
              :
              <MerchantSelect
                value={merchantId ?? null}
                onChange={(v) => setValue('merchantId', v?.value ?? null, { shouldValidate: true })}
                error={errors.merchantId}
                helperText={errors.merchantId?.message ?? null}
              />
          }
          <div>
            <label>Type</label>
            <RadioGroup row name="type" value={type} onChange={e => setValue('type', e.target.value)}>
              <FormControlLabel value={PRIMARY} control={<Radio color="primary" />} label={PRIMARY} />
              <FormControlLabel value={POINT} control={<Radio color="primary" />} label={POINT} />
            </RadioGroup>
          </div>
          <HookTextField
            ref={register}
            name="amount"
            prepend="Rp"
            label="Amount"
            placeholder="e.g: 5000"
            error={errors.amount}
            helperText={errors.amount?.message ?? toIDR(amount)}
          />
          <HookTextField
            ref={register}
            name="desc"
            label="Description (optional)"
            placeholder="e.g: Error transaction replacement due to blackout"
            multiline
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Create Request</LoadingBtn>
          </div>
        </div>
      </div>
    </form>
  );
}

export default InjectTab;
