import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingBtn = forwardRef(({ children, disabled, loading, type = 'submit', ...rest }, ref) => {
  return (
    <button
      {...rest}
      type={type}
      loading={loading ? 'true' : 'false'}
      disabled={disabled || loading}
      ref={ref}
    >
      {loading &&
        <FontAwesomeIcon className="loading-indicator" icon={faCircleNotch} spin />
      }
      {children}
    </button>
  )
})

export default LoadingBtn;
