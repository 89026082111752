import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import AllStatus from '../../../../utils/constants/enums/status';
import { AdminRole } from '../../../../utils/constants/enums/adminRoles';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getApprover = row => {
  const statusHistories = row.metadata?.settlement?.statusHistories ?? [];
  const history = statusHistories.find(row => row.status === AllStatus.COMPLETED || row.status === AllStatus.FAILED) ?? null;
  const actor = history?.actor ?? null;
  if (actor && actor?.firstName) {
    return `${combineBy([actor?.firstName, actor?.lastName])} (${AdminRole.getStr(actor?.role)})`
  } else {
    return `Unknown`
  }
}

const TableSettlement = ({ items, period }) => {
  const filteredItems = useMemo(() =>
    items.filter(row => row?.metadata?.settlement?.status === AllStatus.COMPLETED)
    , [items]);

  const grandTotal = useMemo(() => {
    let amountArr = [];
    let grandTotal = 0;

    filteredItems.forEach(v => {
      grandTotal += v?.amount?.primary ?? 0;
    });

    amountArr = [
      { value: grandTotal }
    ]

    return amountArr;
  }, [filteredItems]);

  const columns = useMemo(() => {
    return [
      { title: "Date", key: "date", name: "date", render: (v, row) => format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Trans. No", key: "code", name: "code" },
      { title: "Name | Email", key: "name", name: "name", render: (v, row) => `${row?.sender ? combineBy([combineBy([row.sender.firstName, row.sender.lastName]), row.sender.email], ' | ') : '-'}` },
      { title: "Status", key: "status", name: "status", render: (v, row) => row?.metadata?.settlement?.status ?? '-' },
      { title: "Settle To", key: "toBankDesc", name: "toBankDesc" },
      { title: "Approved By", key: "approver", name: "approver", render: (v, row) => getApprover(row) },
      { title: "Amount", key: "amount", name: "amount", withCurrency: true, render: (v) => v?.primary }
    ]
  }, [])

  return (
    <ReportTableTemplateV2
      title="Settlement Merchant"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: filteredItems,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableSettlement
