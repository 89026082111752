import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { cloneElement, useState } from 'react'

const AlertDialog = ({
  buttonComponent,
  onAgree = () => { },
  title,
  description,
  fullWidth = true,
  disabled,
  customOptions,
  className,
  notes
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={className}>
      {
        cloneElement(buttonComponent, {
          onClick: () => { if (!disabled) setOpen(true) }
        })
      }
      <Dialog
        fullWidth={fullWidth}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {
          title &&
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        }
        <DialogContent>
          {
            description &&
            <DialogContentText id="alert-dialog-description">
              <span>{description}</span>
            </DialogContentText>
          }

          {notes &&
            <div className="bg-light p-3 rounded mb-4">
              {notes}
            </div>
          }

          {
            // button cancel for customOptions
            customOptions
              ?
              <div className="d-flex justify-content-end mb-2 pt-3">
                {
                  customOptions
                  &&
                  cloneElement(customOptions, {
                    onClick: () => {
                      setOpen(false)
                      onAgree()
                    }
                  })
                }
                <button
                  className="btn btn-icon btn-icon-only btn-sm px-4 bg-light text-secondary w-50 ml-2"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
              :
              <div className="d-flex justify-content-center mb-2">
                <button
                  className="btn btn-outline-danger w-50 mr-2"
                  onClick={() => setOpen(false)}
                >
                  Tidak
                </button>
                <button
                  className="btn btn-outline-primary w-50"
                  onClick={() => {
                    setOpen(false)
                    onAgree()
                  }}
                >
                  Ya
                </button>
              </div>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertDialog
