export const bodyColumns = [
  // Jumlah Pengguna
  { number: "1", key: '1', formula: "=SUM(D12:D560)" },
  { number: "2", key: '2', formula: "=SUM(E12:E560)" },
  { number: "3", key: '3', formula: "=SUM(F12:F560)" },
  { number: "4", key: '4', formula: "=SUM(G12:G560)" },
  { number: "5", key: '5', formula: "=SUM(H12:H560)" },

  // Jumlah Merchant QR/QRIS
  { number: "6", key: '6', formula: "=SUM(I12:I560)" },
  { number: "7", key: '7', formula: "=SUM(J12:J560)" },
  { number: "8", key: '8', formula: "=SUM(K12:K560)" },
  { number: "9", key: '9', formula: "=SUM(L12:L560)" },
  { number: "10", key: '10', formula: "=SUM(M12:M560)" },

  // Jumlah Merchant QRIS berdasarkan Kriteria Merchant
  { number: "11", key: '11', formula: "=SUM(N12:N560)" },
  { number: "12", key: '12', formula: "=SUM(O12:O560)" },
  { number: "13", key: '13', formula: "=SUM(P12:P560)" },
  { number: "14", key: '14', formula: "=SUM(Q12:Q560)" },
  { number: "15", key: '15', formula: "=SUM(R12:R560)" },
  { number: "16", key: '16', formula: "=SUM(S12:S560)" },

  // Jumlah Merchant QRIS AKTIF berdasarkan Kriteria Merchant **
  { number: "17", key: '17', formula: "=SUM(T12:T560)" },
  { number: "18", key: '18', formula: "=SUM(U12:U560)" },
  { number: "19", key: '19', formula: "=SUM(V12:V560)" },
  { number: "20", key: '20', formula: "=SUM(W12:W560)" },
  { number: "21", key: '21', formula: "=SUM(X12:X560)" },
  { number: "22", key: '22', formula: "=SUM(Y12:Y560)" },
  { number: "23", key: '', formula: "=SUM(Z12:Z560)" },
  { number: "24", key: '24', formula: "=SUM(AA12:AA560)" },

  // Jumlah Merchant QRIS berdasarkan Kategori Merchant (MCC)
  { number: "25", key: '25', formula: "=SUM(AB12:AB560)" },
  { number: "26", key: '26', formula: "=SUM(AC12:AC560)" },
  { number: "27", key: '27', formula: "=SUM(AD12:AD560)" },
  { number: "28", key: '28', formula: "=SUM(AE12:AE560)" },
  { number: "29", key: '29', formula: "=SUM(AF12:AF560)" },
  { number: "30", key: '30', formula: "=SUM(AG12:AG560)" },

  // Jumlah Merchant QRIS AKTIF berdasarkan Kategori Merchant (MCC) **
  { number: "31", key: '31', formula: "=SUM(AH12:AH560)" },
  { number: "32", key: '32', formula: "=SUM(AI12:AI560)" },
  { number: "33", key: '33', formula: "=SUM(AJ12:AJ560)" },
  { number: "34", key: '34', formula: "=SUM(AK12:AK560)" },
  { number: "35", key: '35', formula: "=SUM(AL12:AL560)" },
  { number: "36", key: '36', formula: "=SUM(AM12:AM560)" },

  // Jumlah Merchant QRIS CPM berdasarkan Kriteria Merchant ***
  { number: "37", key: '37', formula: "=SUM(AN12:AN560)" },
  { number: "38", key: '38', formula: "=SUM(AO12:AO560)" },
  { number: "39", key: '39', formula: "=SUM(AP12:AP560)" },
  { number: "40", key: '40', formula: "=SUM(AQ12:AQ560)" },
  { number: "41", key: '41', formula: "=SUM(AR12:AR560)" },
  { number: "42", key: '42', formula: "=SUM(AS12:AS560)" },

  // Jumlah Merchant QRIS CPM berdasarkan Kategori Merchant ***
  { number: "43", key: '43', formula: "=SUM(AT12:AT560)" },
  { number: "44", key: '44', formula: "=SUM(AU12:AU560)" },
  { number: "45", key: '45', formula: "=SUM(AV12:AV560)" },
  { number: "46", key: '46', formula: "=SUM(AW12:AW560)" },
  { number: "47", key: '47', formula: "=SUM(AX12:AX560)" },
  { number: "50", key: '48', formula: "=SUM(AY12:AY560)" },

  // Total Transaksi QRIS
  { number: "49 = 51 + 97 + 143", key: '49', formula: "=SUM(AZ12:AZ560)" },
  { number: "50 = 74 + 120 + 166", key: '50', formula: "=SUM(BA12:BA560)" },

  // Volume/Frekuensi Transaksi as Issuer - On Us
  { number: "51 = sum=(61:67)=52", keyGroup: "51|74", key: '0', formula: "=SUM(BB12:BB560)" },
  { number: "52 = sum=(68:73)=51", keyGroup: "52|75", key: '0', formula: "=SUM(BC12:BC560)" },
  { number: "53", keyGroup: "53|76", key: '0', formula: "=SUM(BD12:BD560)" },
  { number: "54", keyGroup: "54|77", key: '0', formula: "=SUM(BE12:BE560)" },
  { number: "55", keyGroup: "55|78", key: '0', formula: "=SUM(BF12:BF560)" },
  { number: "56", keyGroup: "56|79", key: '0', formula: "=SUM(BG12:BG560)" },
  { number: "57", keyGroup: "57|80", key: '0', formula: "=SUM(BH12:BH560)" },
  { number: "58", keyGroup: "58|81", key: '0', formula: "=SUM(BI12:BI560)" },
  { number: "59", keyGroup: "59|82", key: '0', formula: "=SUM(BJ12:BJ560)" },
  { number: "60", keyGroup: "60|83", key: '0', formula: "=SUM(BK12:BK560)" },
  { number: "61", keyGroup: "61|84", key: '0', formula: "=SUM(BL12:BL560)" },
  { number: "62", keyGroup: "62|85", key: '0', formula: "=SUM(BM12:BM560)" },
  { number: "63", keyGroup: "63|86", key: '0', formula: "=SUM(BN12:BN560)" },
  { number: "64", keyGroup: "64|87", key: '0', formula: "=SUM(BO12:BO560)" },
  { number: "65", keyGroup: "65|88", key: '0', formula: "=SUM(BP12:BP560)" },
  { number: "66", keyGroup: "66|89", key: '0', formula: "=SUM(BQ12:BQ560)" },
  { number: "67", keyGroup: "67|90", key: '0', formula: "=SUM(BR12:BR560)" },
  { number: "68", keyGroup: "68|91", key: '0', formula: "=SUM(BS12:BS560)" },
  { number: "69", keyGroup: "69|92", key: '0', formula: "=SUM(BT12:BT560)" },
  { number: "70", keyGroup: "70|93", key: '0', formula: "=SUM(BU12:BU560)" },
  { number: "71", keyGroup: "71|94", key: '0', formula: "=SUM(BV12:BV560)" },
  { number: "72", keyGroup: "72|95", key: '0', formula: "=SUM(BW12:BW560)" },
  { number: "73", keyGroup: "73|96", key: '0', formula: "=SUM(BX12:BX560)" },

  // Nominal Transaksi as Issuer - On Us
  { number: "(74) = sum(84:90) = (75)", keyGroup: "51|74", key: '1', formula: "=SUM(BY12:BY560)" },
  { number: "(75) = sum'(91:96) = (75)", keyGroup: "52|75", key: '1', formula: "=SUM(BZ12:BZ560)" },
  { number: "76", keyGroup: "53|76", key: '1', formula: "=SUM(CA12:CA560)" },
  { number: "77", keyGroup: "54|77", key: '1', formula: "=SUM(CB12:CB560)" },
  { number: "78", keyGroup: "55|78", key: '1', formula: "=SUM(CC12:CC560)" },
  { number: "79", keyGroup: "56|79", key: '1', formula: "=SUM(CD12:CD560)" },
  { number: "80", keyGroup: "57|80", key: '1', formula: "=SUM(CE12:CE560)" },
  { number: "81", keyGroup: "58|81", key: '1', formula: "=SUM(CF12:CF560)" },
  { number: "82", keyGroup: "59|82", key: '1', formula: "=SUM(CG12:CG560)" },
  { number: "83", keyGroup: "60|83", key: '1', formula: "=SUM(CH12:CH560)" },
  { number: "84", keyGroup: "61|84", key: '1', formula: "=SUM(CI12:CI560)" },
  { number: "85", keyGroup: "62|85", key: '1', formula: "=SUM(CJ12:CJ560)" },
  { number: "86", keyGroup: "63|86", key: '1', formula: "=SUM(CK12:CK560)" },
  { number: "87", keyGroup: "64|87", key: '1', formula: "=SUM(CL12:CL560)" },
  { number: "88", keyGroup: "65|88", key: '1', formula: "=SUM(CM12:CM560)" },
  { number: "89", keyGroup: "66|89", key: '1', formula: "=SUM(CN12:CN560)" },
  { number: "90", keyGroup: "67|90", key: '1', formula: "=SUM(CO12:CO560)" },
  { number: "91", keyGroup: "68|91", key: '1', formula: "=SUM(CP12:CP560)" },
  { number: "92", keyGroup: "69|92", key: '1', formula: "=SUM(CQ12:CQ560)" },
  { number: "93", keyGroup: "70|93", key: '1', formula: "=SUM(CR12:CR560)" },
  { number: "94", keyGroup: "71|94", key: '1', formula: "=SUM(CS12:CS560)" },
  { number: "95", keyGroup: "72|95", key: '1', formula: "=SUM(CT12:CT560)" },
  { number: "96", keyGroup: "73|96", key: '1', formula: "=SUM(CU12:CU560)" },

  // Volume/Frekuensi Transaksi as Issuer - Off Us
  { number: "(97) = sum(107:113) = (98)", keyGroup: "97|120", key: '0', formula: "=SUM(CV12:CV560)" },
  { number: "(98) = sum(114:119) = (97)", keyGroup: "98|121", key: '0', formula: "=SUM(CW12:CW560)" },
  { number: "99", keyGroup: "99|122", key: '0', formula: "=SUM(CX12:CX560)" },
  { number: "100", keyGroup: "100|123", key: '0', formula: "=SUM(CY12:CY560)" },
  { number: "101", keyGroup: "101|124", key: '0', formula: "=SUM(CZ12:CZ560)" },
  { number: "102", keyGroup: "102|125", key: '0', formula: "=SUM(DA12:DA560)" },
  { number: "103", keyGroup: "103|126", key: '0', formula: "=SUM(DB12:DB560)" },
  { number: "104", keyGroup: "104|127", key: '0', formula: "=SUM(DC12:DC560)" },
  { number: "105", keyGroup: "105|128", key: '0', formula: "=SUM(DD12:DD560)" },
  { number: "106", keyGroup: "106|129", key: '0', formula: "=SUM(DE12:DE560)" },
  { number: "107", keyGroup: "107|130", key: '0', formula: "=SUM(DF12:DF560)" },
  { number: "108", keyGroup: "108|131", key: '0', formula: "=SUM(DG12:DG560)" },
  { number: "109", keyGroup: "109|132", key: '0', formula: "=SUM(DH12:DH560)" },
  { number: "110", keyGroup: "110|133", key: '0', formula: "=SUM(DI12:DI560)" },
  { number: "111", keyGroup: "111|134", key: '0', formula: "=SUM(DJ12:DJ560)" },
  { number: "112", keyGroup: "112|135", key: '0', formula: "=SUM(DK12:DK560)" },
  { number: "113", keyGroup: "113|136", key: '0', formula: "=SUM(DL12:DL560)" },
  { number: "114", keyGroup: "114|137", key: '0', formula: "=SUM(DM12:DM560)" },
  { number: "115", keyGroup: "115|138", key: '0', formula: "=SUM(DN12:DN560)" },
  { number: "116", keyGroup: "116|139", key: '0', formula: "=SUM(DO12:DO560)" },
  { number: "117", keyGroup: "117|140", key: '0', formula: "=SUM(DP12:DP560)" },
  { number: "118", keyGroup: "118|141", key: '0', formula: "=SUM(DQ12:DQ560)" },
  { number: "119", keyGroup: "119|142", key: '0', formula: "=SUM(DR12:DR560)" },

  // Nominal Transaksi as Issuer - Off Us
  { number: "(120) = sum(130:136) = (121)", keyGroup: "97|120", key: '1', formula: "=SUM(DS12:DS560)" },
  { number: "(121) = sum(137:142) = (120)", keyGroup: "98|121", key: '1', formula: "=SUM(DT12:DT560)" },
  { number: "122", keyGroup: "99|122", key: '1', formula: "=SUM(DU12:DU560)" },
  { number: "123", keyGroup: "100|123", key: '1', formula: "=SUM(DV12:DV560)" },
  { number: "124", keyGroup: "101|124", key: '1', formula: "=SUM(DW12:DW560)" },
  { number: "125", keyGroup: "102|125", key: '1', formula: "=SUM(DX12:DX560)" },
  { number: "126", keyGroup: "103|126", key: '1', formula: "=SUM(DY12:DY560)" },
  { number: "127", keyGroup: "104|127", key: '1', formula: "=SUM(DZ12:DZ560)" },
  { number: "128", keyGroup: "105|128", key: '1', formula: "=SUM(EA12:EA560)" },
  { number: "129", keyGroup: "106|129", key: '1', formula: "=SUM(EB12:EB560)" },
  { number: "130", keyGroup: "107|130", key: '1', formula: "=SUM(EC12:EC560)" },
  { number: "131", keyGroup: "108|131", key: '1', formula: "=SUM(ED12:ED560)" },
  { number: "132", keyGroup: "109|132", key: '1', formula: "=SUM(EE12:EE560)" },
  { number: "133", keyGroup: "110|133", key: '1', formula: "=SUM(EF12:EF560)" },
  { number: "134", keyGroup: "111|134", key: '1', formula: "=SUM(EG12:EG560)" },
  { number: "135", keyGroup: "112|135", key: '1', formula: "=SUM(EH12:EH560)" },
  { number: "136", keyGroup: "113|136", key: '1', formula: "=SUM(EI12:EI560)" },
  { number: "137", keyGroup: "114|137", key: '1', formula: "=SUM(EJ12:EJ560)" },
  { number: "138", keyGroup: "115|138", key: '1', formula: "=SUM(EK12:EK560)" },
  { number: "139", keyGroup: "116|139", key: '1', formula: "=SUM(EL12:EL560)" },
  { number: "140", keyGroup: "117|140", key: '1', formula: "=SUM(EM12:EM560)" },
  { number: "141", keyGroup: "118|141", key: '1', formula: "=SUM(EN12:EN560)" },
  { number: "142", keyGroup: "119|142", key: '1', formula: "=SUM(EO12:EO560)" },

  // Volume/Frekuensi Transaksi as Acquirer - Off Us
  { number: "(143) = sum(153:159) = (144)", keyGroup: "143|166", key: '0', formula: "=SUM(EP12:EP560)" },
  { number: "(144) = sum(160:165) = (143)", keyGroup: "144|167", key: '0', formula: "=SUM(EQ12:EQ560)" },
  { number: "145", keyGroup: "145|168", key: '0', formula: "=SUM(ER12:ER560)" },
  { number: "146", keyGroup: "146|169", key: '0', formula: "=SUM(ES12:ES560)" },
  { number: "147", keyGroup: "147|170", key: '0', formula: "=SUM(ET12:ET560)" },
  { number: "148", keyGroup: "148|171", key: '0', formula: "=SUM(EU12:EU560)" },
  { number: "149", keyGroup: "149|172", key: '0', formula: "=SUM(EV12:EV560)" },
  { number: "150", keyGroup: "150|173", key: '0', formula: "=SUM(EW12:EW560)" },
  { number: "151", keyGroup: "151|174", key: '0', formula: "=SUM(EX12:EX560)" },
  { number: "152", keyGroup: "152|175", key: '0', formula: "=SUM(EY12:EY560)" },
  { number: "153", keyGroup: "153|176", key: '0', formula: "=SUM(EZ12:EZ560)" },
  { number: "154", keyGroup: "154|177", key: '0', formula: "=SUM(FA12:FA560)" },
  { number: "155", keyGroup: "155|178", key: '0', formula: "=SUM(FB12:FB560)" },
  { number: "156", keyGroup: "156|179", key: '0', formula: "=SUM(FC12:FC560)" },
  { number: "157", keyGroup: "157|180", key: '0', formula: "=SUM(FD12:FD560)" },
  { number: "158", keyGroup: "158|181", key: '0', formula: "=SUM(FE12:FE560)" },
  { number: "159", keyGroup: "159|182", key: '0', formula: "=SUM(FF12:FF560)" },
  { number: "160", keyGroup: "160|183", key: '0', formula: "=SUM(FG12:FG560)" },
  { number: "162", keyGroup: "161|184", key: '0', formula: "=SUM(FH12:FH560)" },
  { number: "162", keyGroup: "162|185", key: '0', formula: "=SUM(FI12:FI560)" },
  { number: "163", keyGroup: "163|186", key: '0', formula: "=SUM(FJ12:FJ560)" },
  { number: "164", keyGroup: "164|187", key: '0', formula: "=SUM(FK12:FK560)" },
  { number: "165", keyGroup: "165|188", key: '0', formula: "=SUM(FL12:FL560)" },

  // Nominal Transaksi as Acquirer - Off Us
  { number: "(166) = sum(176:182) = (167)", keyGroup: "143|166", key: '1', formula: "=SUM(FM12:FM560)" },
  { number: "(167) = sum(183:188) = (166)", keyGroup: "144|167", key: '1', formula: "=SUM(FN12:FN560)" },
  { number: "168", keyGroup: "145|168", key: '1', formula: "=SUM(FO12:FO560)" },
  { number: "169", keyGroup: "146|169", key: '1', formula: "=SUM(FP12:FP560)" },
  { number: "170", keyGroup: "147|170", key: '1', formula: "=SUM(FQ12:FQ560)" },
  { number: "171", keyGroup: "148|171", key: '1', formula: "=SUM(FR12:FR560)" },
  { number: "172", keyGroup: "149|172", key: '1', formula: "=SUM(FS12:FS560)" },
  { number: "173", keyGroup: "150|173", key: '1', formula: "=SUM(FT12:FT560)" },
  { number: "174", keyGroup: "151|174", key: '1', formula: "=SUM(FU12:FU560)" },
  { number: "175", keyGroup: "152|175", key: '1', formula: "=SUM(FV12:FV560)" },
  { number: "176", keyGroup: "153|176", key: '1', formula: "=SUM(FW12:FW560)" },
  { number: "177", keyGroup: "154|177", key: '1', formula: "=SUM(FX12:FX560)" },
  { number: "177", keyGroup: "155|178", key: '1', formula: "=SUM(FY12:FY560)" },
  { number: "177", keyGroup: "156|179", key: '1', formula: "=SUM(FZ12:FZ560)" },
  { number: "180", keyGroup: "157|180", key: '1', formula: "=SUM(GA12:GA560)" },
  { number: "181", keyGroup: "158|181", key: '1', formula: "=SUM(GB12:GB560)" },
  { number: "182", keyGroup: "159|182", key: '1', formula: "=SUM(GC12:GC560)" },
  { number: "183", keyGroup: "160|183", key: '1', formula: "=SUM(GD12:GD560)" },
  { number: "184", keyGroup: "161|184", key: '1', formula: "=SUM(GE12:GE560)" },
  { number: "185", keyGroup: "162|185", key: '1', formula: "=SUM(GF12:GF560)" },
  { number: "186", keyGroup: "163|186", key: '1', formula: "=SUM(GG12:GG560)" },
  { number: "187", keyGroup: "164|187", key: '1', formula: "=SUM(GH12:GH560)" },
  { number: "188", keyGroup: "165|188", key: '1', formula: "=SUM(GI12:GI560)" },
]

export const totalValidasi = [
  { number: "GK", key: '', formula: `=IF(I11=SUM(AA11:AF11),"OK","ERROR")` },
  { number: "GL", key: '', formula: `=IF(AZ11=SUM(BB11,CV11,EP11),"OK","ERROR")` },
  { number: "GM", key: '', formula: `=IF(BA11=SUM(BY11,DS11,FM11),"OK","ERROR")` },
  { number: "GN", key: '', formula: `=IF(SUM(BB11,CV11,EP11)=SUM(BL11:BP11,DF11:DJ11,EZ11:FD11),"OK","ERROR")` },
  { number: "GO", key: '', formula: `=IF(SUM(BZ11,DT11,FN11)=SUM(CI11:CO11,EC11:EI11,FW11:GC11),"OK","ERROR")` },
  { number: "GP", key: '', formula: `=IF(SUM(BC11,CW11,EQ11)=SUM(BS11:BX11,DM11:DR11,FG11:FL11),"OK","ERROR")` },
  { number: "GQ", key: '', formula: `=IF(SUM(BZ11,DT11,FN11)=SUM(CP11:CU11,EJ11:EO11,GD11:GI11),"OK","ERROR")` },
  { number: "GR", key: '', formula: `=IF(AZ11=SUM(BD11:BE11,CX11:CY11,ER11:ES11),"OK","ERROR")` },
  { number: "GS", key: '', formula: `=IF(BA11=SUM(CA11:CB11,DU11:DV11,FO11:FP11),"OK","ERROR")` },
  { number: "GT", key: '', formula: `=IF(AZ11=SUM(BF11:BK11,CZ11:DE11,ET11:EY11),"OK","ERROR")` },
  { number: "GU", key: '', formula: `=IF(BA11=SUM(CC11:CH11,DW11:EB11,FQ11:FV11),"OK","ERROR")` },
  { number: "GY", key: '', formula: `=IF(COUNTIF(GJ11:GU11,"OK")=12,"ALL OK",IF(COUNTIF(GJ11:GU11,"ERROR")=12,"ALL ERROR","SOME ERROR"))` },
]

export const validasiColumns = [
  {
    key: "1",
    type: "pattern1",
    formulaValidasi: {
      alfa1: "I",
      alfa2: "M",
      alfa3: "S",
    }
  },
  {
    key: "2",
    type: "pattern1",
    formulaValidasi: {
      alfa1: "I",
      alfa2: "AA",
      alfa3: "AF",
    }
  },
  {
    key: "3",
    type: "pattern2",
    formulaValidasi: {
      alfa1: "AZ",
      alfa2: "BB",
      alfa3: "CV",
      alfa4: "EP",
    }
  },
  {
    key: "4",
    type: "pattern2",
    formulaValidasi: {
      alfa1: "BA",
      alfa2: "BY",
      alfa3: "DS",
      alfa4: "FM",
    }
  },
  {
    key: "5",
    type: "pattern3",
    formulaValidasi: {
      alfa1: "BB",
      alfa2: "CV",
      alfa3: "EP",
      alfa4: "BL",
      alfa5: "BP",
      alfa6: "DF",
      alfa7: "DJ",
      alfa8: "EZ",
      alfa9: "FD",
    }
  },
  {
    key: "5",
    type: "pattern3",
    formulaValidasi: {
      alfa1: "BZ",
      alfa2: "DT",
      alfa3: "FN",
      alfa4: "CI",
      alfa5: "CO",
      alfa6: "EC",
      alfa7: "EI",
      alfa8: "FW",
      alfa9: "GC",
    }
  },
  {
    key: "6",
    type: "pattern3",
    formulaValidasi: {
      alfa1: "BC",
      alfa2: "CW",
      alfa3: "EQ",
      alfa4: "BS",
      alfa5: "BX",
      alfa6: "DM",
      alfa7: "DR",
      alfa8: "FG",
      alfa9: "FL",
    }
  },
  {
    key: "7",
    type: "pattern3",
    formulaValidasi: {
      alfa1: "BZ",
      alfa2: "DT",
      alfa3: "FN",
      alfa4: "CP",
      alfa5: "CU",
      alfa6: "EJ",
      alfa7: "EO",
      alfa8: "GD",
      alfa9: "GI",
    }
  },
  {
    key: "8",
    type: "pattern4",
    formulaValidasi: {
      alfa1: "AZ",
      alfa2: "BD",
      alfa3: "BE",
      alfa4: "CX",
      alfa5: "CY",
      alfa6: "ER",
      alfa7: "ES",
    }
  },
  {
    key: "9",
    type: "pattern4",
    formulaValidasi: {
      alfa1: "BA",
      alfa2: "CA",
      alfa3: "CB",
      alfa4: "DU",
      alfa5: "DV",
      alfa6: "FO",
      alfa7: "FP",
    }
  },
  {
    key: "10",
    type: "pattern4",
    formulaValidasi: {
      alfa1: "AZ",
      alfa2: "BF",
      alfa3: "BK",
      alfa4: "CZ",
      alfa5: "DE",
      alfa6: "ET",
      alfa7: "EY",
    }
  },
  {
    key: "11",
    type: "pattern4",
    formulaValidasi: {
      alfa1: "BA",
      alfa2: "CC",
      alfa3: "CH",
      alfa4: "DW",
      alfa5: "EB",
      alfa6: "FQ",
      alfa7: "FV",
    }
  },
  {
    key: "12",
    type: "pattern5",
    formulaValidasi: {
      alfa1: "GJ",
      alfa2: "GU",
    }
  },
]

// =IF(I13=SUM(AA13:AF13),"OK","ERROR")
export const validasiColumnsPattern1 = ({ columnIndex, alfa1, alfa2, alfa3 }) => {
  return `=IF(${alfa1}${columnIndex}=SUM(${alfa2}${columnIndex}:${alfa3}${columnIndex}),"OK","ERROR")`
}

// =IF(BA12=SUM(BY12,DS12,FM12),"OK","ERROR")
export const validasiColumnsPattern2 = ({ columnIndex, alfa1, alfa2, alfa3, alfa4 }) => {
  return `=IF(${alfa1}${columnIndex}=SUM(${alfa2}${columnIndex},${alfa3}${columnIndex},${alfa4}${columnIndex}),"OK","ERROR")`
}

// =IF(SUM(BB12,CV12,EP12)=SUM(BL12:BS12,DF12:DJ12,EZ12:FD12),"OK","ERROR")
export const validasiColumnsPattern3 = ({ columnIndex, alfa1, alfa2, alfa3, alfa4, alfa5, alfa6, alfa7, alfa8, alfa9 }) => {
  return `=IF(SUM(${alfa1}${columnIndex},${alfa2}${columnIndex},${alfa3}${columnIndex})=SUM(${alfa4}${columnIndex}:${alfa5}${columnIndex},${alfa6}${columnIndex}:${alfa7}${columnIndex},${alfa8}${columnIndex}:${alfa9}${columnIndex}),"OK","ERROR")`
}

// =IF(AZ12=SUM(BD12:BE12,CX12:CY12,ER12:ES12),"OK","ERROR")
export const validasiColumnsPattern4 = ({ columnIndex, alfa1, alfa2, alfa3, alfa4, alfa5, alfa6, alfa7 }) => {
  return `=IF(${alfa1}${columnIndex}=SUM(${alfa2}${columnIndex}:${alfa3}${columnIndex},${alfa4}${columnIndex}:${alfa5}${columnIndex},${alfa6}${columnIndex}:${alfa7}${columnIndex}),"OK","ERROR")`
}

// =IF(COUNTIF(GJ12:GU12,"OK")=12,"ALL OK",IF(COUNTIF(GJ12:GU12,"ERROR")=12,"ALL ERROR","SOME ERROR"))
export const validasiColumnsPattern5 = ({ columnIndex, alfa1, alfa2 }) => {
  return `=IF(COUNTIF(${alfa1}${columnIndex}:${alfa2}${columnIndex},"OK")=12,"ALL OK",IF(COUNTIF(${alfa1}${columnIndex}:${alfa2}${columnIndex},"ERROR")=12,"ALL ERROR","SOME ERROR"))`
}

export const generateFormulaPattern = ({ type, validasiIndex, formulaValidasi }) => {
  switch (type) {
    case "pattern1":
      return validasiColumnsPattern1({
        columnIndex: validasiIndex.toString(),
        alfa1: formulaValidasi?.alfa1,
        alfa2: formulaValidasi?.alfa2,
        alfa3: formulaValidasi?.alfa3,
      })
    case "pattern2":
      return validasiColumnsPattern2({
        columnIndex: validasiIndex.toString(),
        alfa1: formulaValidasi?.alfa1,
        alfa2: formulaValidasi?.alfa2,
        alfa3: formulaValidasi?.alfa3,
        alfa4: formulaValidasi?.alfa4,
      })
    case "pattern3":
      return validasiColumnsPattern3({
        columnIndex: validasiIndex.toString(),
        alfa1: formulaValidasi?.alfa1,
        alfa2: formulaValidasi?.alfa2,
        alfa3: formulaValidasi?.alfa3,
        alfa4: formulaValidasi?.alfa4,
        alfa5: formulaValidasi?.alfa5,
        alfa6: formulaValidasi?.alfa6,
        alfa7: formulaValidasi?.alfa7,
        alfa8: formulaValidasi?.alfa8,
        alfa9: formulaValidasi?.alfa9,
      })
    case "pattern4":
      return validasiColumnsPattern4({
        columnIndex: validasiIndex.toString(),
        alfa1: formulaValidasi?.alfa1,
        alfa2: formulaValidasi?.alfa2,
        alfa3: formulaValidasi?.alfa3,
        alfa4: formulaValidasi?.alfa4,
        alfa5: formulaValidasi?.alfa5,
        alfa6: formulaValidasi?.alfa6,
        alfa7: formulaValidasi?.alfa7,
      })
    case "pattern5":
      return validasiColumnsPattern5({
        columnIndex: validasiIndex.toString(),
        alfa1: formulaValidasi?.alfa1,
        alfa2: formulaValidasi?.alfa2,
      })
    default:
      return;
  }
};
