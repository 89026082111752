import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import FloatBar from './FloatBar2';
import {
  REPORT_TOPUP,
  REPORT_MUTASI,
  REPORT_BILLER_PULSA,
  REPORT_WALLET,
  REPORT_BILLER_PLN,
  REPORT_BILLER_PDAM,
  REPORT_BILLER_GAME_VOUCHER,
  REPORT_BILLER_ETOLL,
  REPORT_BILLER_POSTPAID,
  REPORT_BILLER_TELKOM,
  REPORT_BILLER_BPJS,
  REPORT_BILLER_PGN,
  REPORT_BILLER_TV,
  REPORT_DIRECT_INTEGRATION,
  REPORT_INJECTION,
  REPORT_DEDUCTION,
  REPORT_SETTLEMENT,
  REPORT_WITHDRAW,
  REPORT_VOUCHER,
  REPORT_BILLER_MULTIFINANCE,
  REPORT_BILLER_SAMOLNAS,
  REPORT_BILLER_CC,
  REPORT_BILLER_PBB,
  REPORT_BILLER_PHONE_PACKAGE,
  REPORT_BILLER_PLN_TOKEN,
  REPORT_BILLER_PLN_NON_BILL,
  REPORT_MF_INDOFUND,
  REPORT_BILLER_INTERNET,
  REPORT_BILLER_STREAMING,
  REPORT_BILLER_EDU_VOUCHER,
  REPORT_BILLER_EDUCATION,
  REPORT_MERCHANT_INDOFUND
} from '../../../utils/constants/dataTypes';
import Fade from '@material-ui/core/Fade';
import { MAIN_TAB_UNLOAD, REPORT_MODULE, UPDATE } from '../../../utils/constants/actionTypes';
import TableBillerMFIndofund from './Tables/TableBillerMFIndofund';
import TableBillerPulsa from './Tables/TableBillerPulsa';
import TableBillerPostpaid from './Tables/TableBillerPostpaid';
import TableBillerPhonePackage from './Tables/TableBillerPhonePackage';
import TableBillerPln from './Tables/TableBillerPln';
import TableBillerPlnToken from './Tables/TableBillerPlnToken';
import TableBillerPlnNonBill from './Tables/TableBillerPlnNonBill';
import TableBillerPdam from './Tables/TableBillerPdam';
import TableBillerGameVoucher from './Tables/TableBillerGameVoucher';
import TableBillerEToll from './Tables/TableBillerEToll';
import TableBillerTelkom from './Tables/TableBillerTelkom';
import TableBillerBPJS from './Tables/TableBillerBPJS';
import TableBillerPGN from './Tables/TableBillerPGN';
import TableBillerTV from './Tables/TableBillerTV';
import TableBillerMultiFinance from './Tables/TableBillerMultiFinance';
import TableBillerSamolnas from './Tables/TableBillerSamolnas';
import TableBillerCC from './Tables/TableBillerCCT';
import TableBillerPBB from './Tables/TableBillerPBB';
import TableTopup from './Tables/TableTopup';
import TableVoucher from './Tables/TableVoucher';
import TableSettlement from './Tables/TableSettlement';
import TableDeduction from './Tables/TableDeduction';
import TableInjection from './Tables/TableInjection';
import TableWithdraw from './Tables/TableWithdraw';
import TableDirectIntegration from './Tables/TableDirectIntegration';
import TableMerchantIndofund from './Tables/TableMerchantIndofund';
import TableWallet from './Tables/TableWallet';
import TableBillerEducation from './Tables/TableBillerEducation';
import TableBillerEduVoucher from './Tables/TableBillerEduVoucher';
import TableBillerInternet from './Tables/TableBillerInternet';
import TableBillerStreaming from './Tables/TableBillerStreaming';
import TableMutasi from './Tables/TableMutasi';

import useMountedState from '../../../components/HooksUse/useMountedState';
import { endOfDay, format } from 'date-fns';
import { transactionApi } from '../../../services/transactionApi';
import { toast } from 'react-toastify';

const excludedViewType = [
  REPORT_WALLET,
  REPORT_SETTLEMENT,
  REPORT_INJECTION,
  REPORT_DEDUCTION,
  REPORT_WITHDRAW,
  REPORT_VOUCHER,
  REPORT_DIRECT_INTEGRATION,
  REPORT_MERCHANT_INDOFUND
]

const MainTab = () => {
  const [open, setOpen] = useState(false);
  const isMounted = useMountedState();
  const {
    totalBalance,
    items,
    total,
    period,
    isUpdating,
    admin,
    notes,
    selectedModel,

    filter: {
      fromDate,
      toDate,
      selectedUser,
      selectedPartner,
      selectedMerchant,
      selectedBusiness,
      walletModel,
      viewType,
      selectedType,
    }
  } = useSelector(state => state.report);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ module: REPORT_MODULE, type: MAIN_TAB_UNLOAD })
    }
  }, [dispatch])

  const submit = useCallback(() => {
    const userId = selectedUser ? selectedUser.value : null;
    const businessId = selectedBusiness ? selectedBusiness.value : null;
    const merchantId = selectedMerchant ? selectedMerchant.value : null;
    const partnerId = selectedPartner ? selectedPartner.value : null;
    const fDate = format(new Date(fromDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    const tDate = format(endOfDay(new Date(toDate)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    // let model = selectedUser ? selectedUser : selectedMerchant;
    let model = function () {
      if (selectedUser) return selectedUser
      if (selectedMerchant) return selectedMerchant
      if (selectedPartner) return selectedPartner
    }()

    if (
      (userId === null && businessId === null && merchantId === null && partnerId === null) &&
      !excludedViewType.includes(viewType)
    ) {
      toast.warn("Please select a model");
      return;
    }

    if (selectedType === null) {
      toast.warn("Please select a Type of Filter");
      return;
    }

    let call;
    let args;
    if (viewType === REPORT_WALLET) {
      call = transactionApi.walletReport;
      args = [{
        uType: walletModel,
        fromDate: fDate,
        toDate: tDate,
        limit: 100,
        page: 1
      }];
      model = walletModel;
    } else if (viewType === REPORT_MUTASI) {
      if (userId || businessId || merchantId || partnerId) {
        call = transactionApi.getMutation;
        args = [userId, businessId, merchantId, partnerId, fDate, tDate, selectedType];
      } else {
        toast("Please fill the Model first!", { type: 'error' });
      }
    } else if (viewType === REPORT_VOUCHER) {
      call = transactionApi.vouchersReport;
      args = [fDate, tDate];
    } else if (viewType === REPORT_DIRECT_INTEGRATION) {
      call = transactionApi.directIntegration;
      args = [fDate, tDate, selectedType, walletModel]
      model = walletModel;
    } else if (viewType === REPORT_MERCHANT_INDOFUND && walletModel === "MERCHANT") {
      call = transactionApi.merchantIndofund;
      args = [fDate, tDate]
      model = walletModel;
    } else {
      call = transactionApi.listTransaction;
      args = [userId, businessId, merchantId, fDate, tDate, selectedType];
    }

    dispatch({
      module: REPORT_MODULE,
      type: UPDATE,
      call: call,
      args: args,
      selectedModel: model,
      period: [fDate, tDate]
    }).then(() => {
      if (isMounted()) setOpen(false);
    }).catch(() => { })
  }, [
    dispatch,
    fromDate,
    isMounted,
    selectedBusiness,
    selectedPartner,
    selectedMerchant,
    selectedType,
    selectedUser,
    toDate,
    viewType,
    walletModel
  ])

  const renderTable = useMemo(() => {
    if (selectedModel) {
      let view;

      switch (viewType) {
        case REPORT_TOPUP:
          view = <TableTopup items={items} period={period} selectedModel={selectedModel} />;
          break;
        case REPORT_MUTASI:
          if (selectedModel.value === 'all') {
            view = items.map((row, k) => {
              const modelFromUser = {
                model: selectedModel.model,
                firstName: row.firstName,
                lastName: row.lastName,
                uniqueId: row.uniqueId,
                phoneNumber: row.phoneNumber,
                email: row.email
              };
              return row.transactions?.length
                ?
                <TableMutasi key={k} items={row.transactions} period={period} selectedModel={modelFromUser} />
                :
                ''
            });
          } else {
            const modelFromUser = {
              model: selectedModel.model,
              firstName: selectedModel.optData?.firstName,
              lastName: selectedModel.optData?.lastName,
              uniqueId: selectedModel.optData?.uniqueId,
              phoneNumber: selectedModel.optData?.phoneNumber,
              email: selectedModel.optData?.email
            };
            view = <TableMutasi items={items} period={period} selectedModel={modelFromUser} />;
          }
          break;
        case REPORT_MF_INDOFUND:
          view = <TableBillerMFIndofund items={items} period={period} />;
          break;
        case REPORT_BILLER_PULSA:
          view = <TableBillerPulsa items={items} period={period} />;
          break;
        case REPORT_BILLER_POSTPAID:
          view = <TableBillerPostpaid items={items} period={period} />;
          break;
        case REPORT_BILLER_PHONE_PACKAGE:
          view = <TableBillerPhonePackage items={items} period={period} />
          break;
        case REPORT_BILLER_PLN:
          view = <TableBillerPln items={items} period={period} />;
          break;
        case REPORT_BILLER_PLN_TOKEN:
          view = <TableBillerPlnToken items={items} period={period} />;
          break;
        case REPORT_BILLER_PLN_NON_BILL:
          view = <TableBillerPlnNonBill items={items} period={period} />;
          break;
        case REPORT_BILLER_PDAM:
          view = <TableBillerPdam items={items} period={period} />;
          break;
        case REPORT_BILLER_GAME_VOUCHER:
          view = <TableBillerGameVoucher items={items} period={period} />;
          break;
        case REPORT_BILLER_ETOLL:
          view = <TableBillerEToll items={items} period={period} />;
          break;
        case REPORT_BILLER_TELKOM:
          view = <TableBillerTelkom items={items} period={period} />;
          break;
        case REPORT_BILLER_BPJS:
          view = <TableBillerBPJS items={items} period={period} />;
          break;
        case REPORT_BILLER_PGN:
          view = <TableBillerPGN items={items} period={period} />;
          break;
        case REPORT_BILLER_TV:
          view = <TableBillerTV items={items} period={period} />;
          break;
        case REPORT_BILLER_MULTIFINANCE:
          view = <TableBillerMultiFinance items={items} period={period} />;
          break;
        case REPORT_BILLER_SAMOLNAS:
          view = <TableBillerSamolnas items={items} period={period} />;
          break;
        case REPORT_BILLER_CC:
          view = <TableBillerCC items={items} period={period} />;
          break;
        case REPORT_BILLER_PBB:
          view = <TableBillerPBB items={items} period={period} />;
          break;
        case REPORT_BILLER_INTERNET:
          view = <TableBillerInternet items={items} period={period} />;
          break;
        case REPORT_BILLER_STREAMING:
          view = <TableBillerStreaming items={items} period={period} />;
          break;
        case REPORT_BILLER_EDU_VOUCHER:
          view = <TableBillerEduVoucher items={items} period={period} />;
          break;
        case REPORT_BILLER_EDUCATION:
          view = <TableBillerEducation items={items} period={period} />;
          break;
        case REPORT_DIRECT_INTEGRATION:
          view = <TableDirectIntegration submit={submit} items={items} period={period} selectedType={selectedType} />;
          break;
        case REPORT_MERCHANT_INDOFUND:
          view = <TableMerchantIndofund notes={notes} items={items} period={period} selectedType={selectedType} />;
          break;
        case REPORT_WALLET:
          view = <TableWallet totalBalance={totalBalance} total={total} admin={admin} items={items} period={period} model={selectedModel || null} />;
          break;
        case REPORT_INJECTION:
          view = <TableInjection items={items} period={period} />;
          break;
        case REPORT_DEDUCTION:
          view = <TableDeduction items={items} period={period} />;
          break;
        case REPORT_SETTLEMENT:
          view = <TableSettlement items={items} period={period} />;
          break;
        case REPORT_WITHDRAW:
          view = <TableWithdraw items={items} period={period} />;
          break;
        case REPORT_VOUCHER:
          view = <TableVoucher items={items} period={period} />;
          break;
        default:
          view = <h5 className="mb-0 px-3 py-4 text-center">View not defined</h5>
          break;
      }

      return view
    }
  }, [
    admin,
    items,
    notes,
    period,
    selectedModel,
    selectedType,
    total,
    totalBalance,
    viewType,
    submit
  ])

  return (
    <>
      <FloatBar
        setOpen={setOpen}
        open={open}
        submit={submit}
        excludedViewType={excludedViewType}
      />
      <div className="report-table-wrapper">
        {renderTable}
        <Fade in={isUpdating} timeout={300}>
          <div className="loader">
            <div className="line-scale-pulse-out">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default MainTab
