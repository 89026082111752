import React from "react";
import { Helmet } from "react-helmet";
import MainTab from "./MainTab";

const Index = () => {
  return (
    <div className="app-main__inner">
      <Helmet>
        <title>QRIS Artajasa Recon</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-hourglass icon-gradient bg-sunny-morning"></i>
            </div>
            <div>
              QRIS Artajasa Recon
              <div className="page-title-subheading">View QRIS Artajasa Recon</div>
            </div>
          </div>
        </div>
      </div>
      <MainTab />
    </div>
  );
};

export default Index;
