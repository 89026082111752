import {
  ASYNC_START,
  ASYNC_END,
  QRIS_RECON_MODULE,
  MAIN_TAB_LOAD,
} from "../../utils/constants/actionTypes";

const defaultState = {
  items: [],
  rptDate: null,
  isLoading: false,
};

const reducer = (state = defaultState, action) => {
  if (action.module !== QRIS_RECON_MODULE) return { ...state };
  switch (action.type) {
    case MAIN_TAB_LOAD:
      return {
        ...state,
        rptDate:
          action.payload && action.payload.data
            ? action.payload.data.result.Rptdate
            : null,
        items:
          action.payload && action.payload.data
            ? action.payload.data.result.detail
            : [],
      };
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, isLoading: true };
        default:
          return { ...state };
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, isLoading: false };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer;
