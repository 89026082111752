import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import {
  NOTIFICATION_MODULE
} from "../../../utils/constants/actionTypes";
import Resource from "../../../components/Resource";
import DetailTab from "../DetailTab";
import FormTab from "../FormTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSms, faMobile } from '@fortawesome/free-solid-svg-icons';
import { notificationApi } from "../../../services/notificationApi";
import { SELECT_FIELD } from "../../../utils/constants/inputTypes";

const NotificationMerchant = () => {
  const { currentPage, limit, sort, filter } = useSelector(
    (state) => state.notification
  );

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    },
    { title: 'Title', key: 'title', name: 'title' },
    { title: 'Type', key: 'type', name: 'type', render: v => v ?? "-" },
    { title: 'Body', key: 'body', name: 'body', className: 'dummy-text', render: (v, row) => <div title={v}>{v}</div> },
    {
      title: 'Cases', key: 'cases', name: 'cases', sortable: false, render: (v, row) => (
        <div className="d-inline-flex">
          <div
            className={`mr-1 ${v.email && v.email.enabled ? (v.email.sentAt ? 'text-success' : 'text-danger') : 'opacity-2'}`}
            title={v.email && v.email.enabled ? (v.email.sentAt ? v.email.sentAt : 'Failed') : 'Disabled'}
          >
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
          </div>
          <div
            className={`mx-1 ${v.sms && v.sms.enabled ? (v.sms.sentAt ? 'text-success' : 'text-danger') : 'opacity-2'}`}
            title={v.sms && v.sms.enabled ? (v.sms.sentAt ? v.sms.sentAt : 'Failed') : 'Disabled'}
          >
            <FontAwesomeIcon icon={faSms} size="lg" />
          </div>
          <div
            className={`ml-1 ${v.push && v.push.enabled ? (v.push.sentAt ? 'text-success' : 'text-danger') : 'opacity-2'}`}
            title={v.push && v.push.enabled ? (v.push.sentAt ? v.push.sentAt : 'Failed') : 'Disabled'}
          >
            <FontAwesomeIcon icon={faMobile} size="lg" />
          </div>
        </div>
      )
    }
  ];

  const apiQuery = useMemo(() => {
    const search = filter.search ? `title|${filter.search},body|${filter.search}` : null;

    return {
      page: currentPage,
      limit,
      sort,
      filter: {
        search,
        model: "Merchant",
        type: filter.type
      }
    };
  }, [currentPage, limit, sort, filter]);

  return (
    <div>
      <Resource
        title="Notification"
        subTitle="Notification Detail."
        icon="lnr-bullhorn"
        list={{
          columns: columns,
          reducerPath: "notification",
          module: NOTIFICATION_MODULE,
          call: notificationApi.list,
          apiQuery: apiQuery,
          search: true,
          filters: [
            {
              label: "Filter Type",
              type: SELECT_FIELD,
              key: "type",
              options: [
                {
                  value: "TRANSACTION",
                  label: "Transaction"
                },
                {
                  value: "INFO",
                  label: "Info"
                },
                {
                  value: "PROMO",
                  label: "Promo"
                },
              ],
              value: filter.type
            }
          ]
        }}
        detail={{ component: DetailTab }}
        additionalTab={{
          component: FormTab,
          props: {
            type: "merchant"
          },
          title: "Create Firebase Notification"
        }}
      />
    </div>
  );
};

export default NotificationMerchant;
