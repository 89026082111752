class productOptsTypes {
  static USER = 'USER';
  static BUSINESS = 'BUSINESS';
  static WEBSITE = 'WEBSITE';

  static getStr(en) {
    switch (en) {
      case productOptsTypes.USER:
        return 'Dipay Personal';
      case productOptsTypes.BUSINESS:
        return 'Dipay Bisnis';
      case productOptsTypes.WEBSITE:
        return 'Website';
      default:
        return '-';
    }
  }
}

const productOpts = [
  { value: productOptsTypes.USER, label: productOptsTypes.getStr(productOptsTypes.USER) },
  { value: productOptsTypes.BUSINESS, label: productOptsTypes.getStr(productOptsTypes.BUSINESS) },
  { value: productOptsTypes.WEBSITE, label: productOptsTypes.getStr(productOptsTypes.WEBSITE) }
];

export { productOptsTypes, productOpts };
