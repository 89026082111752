import React, { useMemo, useState } from 'react';
import { combineBy } from '../../../../utils/helpers/combineBy';
import ReportTableTemplateV2 from './ReportTableTemplateV2';
import { TransactionType } from '../../../../utils/constants/enums/transactionTypes';
import { CREDIT, DEBIT } from '../../../../utils/constants/dataTypes';
import { format } from 'date-fns';

const TableMutasi = ({ items, period, selectedModel }) => {
  const [isShowSaldo, setIsShowSaldo] = useState(true);

  const {
    saldoItems,
    pointItems,
    grandTotal
  } = useMemo(() => {
    let totalDebitSaldo = 0;
    let totalKreditSaldo = 0;
    let totalDebitPoint = 0;
    let totalKreditPoint = 0;
    let saldoItems = [];
    let pointItems = [];
    let grandTotal = [];
    let currentSaldo = 0;

    items.forEach(row => {
      if (row.amount.primary) {
        saldoItems.push(row);
        if (row.as === DEBIT) {
          totalDebitSaldo += row.amount.primary;
        } else if (row.as === CREDIT) {
          totalKreditSaldo += row.amount.primary;
        }

      }
      // if (row.amount.point) {
      //   pointItems.push(row);
      //   if (row.as === DEBIT) {
      //     if (selectedModel.model === 'Merchant') {
      //       totalDebitSaldo += row.amount.point;
      //     } else {
      //       totalDebitPoint += row.amount.point;
      //     }
      //   } else if (row.as === CREDIT) {
      //     totalKreditPoint += row.amount.point;
      //   }
      // }

      if (selectedModel.model === 'Merchant') {
        if (row.as === CREDIT) {
          currentSaldo = row.senderBalance.primary - row.amount.primary
        } else if (row.as === DEBIT) {
          currentSaldo = row.recipientBalance.primary + row.amount.primary
        }
      } else if (isShowSaldo && saldoItems.length) {
        if (row.as === CREDIT) {
          currentSaldo = row.senderBalance.primary - row.amount.primary
        } else if (row.as === DEBIT) {
          currentSaldo = row.recipientBalance.primary + row.amount.primary
        }
      } else if (!isShowSaldo && pointItems.length) {
        if (row.as === CREDIT) {
          currentSaldo = row.senderBalance.point - row.amount.point
        } else if (row.as === DEBIT) {
          currentSaldo = row.recipientBalance.point + row.amount.point
        }
      }
    });

    if (selectedModel.model === 'Merchant') {
      grandTotal = [
        { value: totalDebitSaldo },
        { value: totalKreditSaldo },
        { value: currentSaldo }
      ]
    } else if (isShowSaldo && saldoItems.length) {
      grandTotal = [
        { value: totalDebitSaldo },
        { value: totalKreditSaldo },
        { value: currentSaldo }
      ]
    } else if (!isShowSaldo && pointItems.length) {
      grandTotal = [
        { value: totalDebitPoint },
        { value: totalKreditPoint },
        { value: currentSaldo }
      ]
    } else {
      grandTotal = []
    }

    return {
      saldoItems,
      pointItems,
      grandTotal
    }
  }, [items, selectedModel, isShowSaldo]);

  const generateTitle = useMemo(() => () => {
    if (selectedModel.model === 'USER') {
      return `${combineBy([selectedModel.firstName, selectedModel.lastName])} | ${selectedModel.uniqueId} | ${selectedModel.phoneNumber}`;
    } else if (selectedModel.model === 'Merchant') {
      return `${combineBy([selectedModel.firstName, selectedModel.lastName])} | ${selectedModel.email} | ${selectedModel.phoneNumber}`;
    } else {
      return `${combineBy([selectedModel.firstName, selectedModel.lastName])}`;
    }
  }, [selectedModel])

  const columns = useMemo(() => {
    const basicInfo = [
      { title: "Created at", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Trans. No", key: "code", name: "code" },
      { title: "Business", hide: selectedModel.model !== 'Merchant', key: "business", name: "business", render: (v, row) => row.metadata.business ? row.metadata.business.name : '-' },
      { title: "Type", key: "type", name: "type", render: (v) => TransactionType.getStr(v) },
      { title: "Deskripsi", key: "description", name: "description", render: (v, row) => combineBy([row?.metadata?.payment?.metadata?.biller, row.mutationDesc], '-') || '-' }
    ]

    if (selectedModel.model === 'Merchant') {
      return [
        ...basicInfo,
        {
          title: "Debit", key: "debit", withCurrency: true, variant: "debit", name: "debit", render: (v, row) => {

            if (row.as === DEBIT) {
              return row.amount.primary ? row.amount.primary : row.amount.point
            }
          }
        },
        {
          title: "Kredit", key: "kredit", withCurrency: true, variant: "credit", name: "kredit", render: (v, row) => {
            if (row.as === CREDIT) {
              return row.amount.primary ? row.amount.primary : row.amount.point
            }
          }
        },
        {
          title: isShowSaldo ? "Saldo" : "Poin", key: "balance", withCurrency: true, name: "", render: (v, row) => {
            if (row.as === CREDIT) {
              return row.senderBalance.primary - row.amount.primary
            } else if (row.as === DEBIT) {
              return row.recipientBalance.primary + row.amount.primary
            } else {
              return "Data Error"
            }
          }
        }
      ]
    } else if (isShowSaldo && saldoItems.length) {
      return [
        ...basicInfo,
        {
          title: "Debit", key: "debit", withCurrency: true, variant: "debit", name: "debit", render: (v, row) => {
            if (row.as === DEBIT) {
              return row.amount.primary
            }
          }
        },
        {
          title: "Kredit", key: "kredit", withCurrency: true, variant: "credit", name: "kredit", render: (v, row) => {
            if (row.as === CREDIT) {
              return row.amount.primary
            }
          }
        },
        {
          title: isShowSaldo ? "Saldo" : "Poin", key: "balance", withCurrency: true, name: "", render: (v, row) => {
            if (row.as === CREDIT) {
              return row.senderBalance.primary - row.amount.primary
            } else if (row.as === DEBIT) {
              return row.recipientBalance.primary + row.amount.primary
            } else {
              return "Data Error"
            }
          }
        }
      ]
    } else if (!isShowSaldo && pointItems.length) {
      return [
        ...basicInfo,
        {
          title: "Debit", key: "debit", withCurrency: true, variant: "debit", name: "debit", render: (v, row) => {
            if (row.as === DEBIT) {
              return row.amount.point
            }
          }
        },
        {
          title: "Kredit", key: "kredit", withCurrency: true, variant: "credit", name: "kredit", render: (v, row) => {
            if (row.as === CREDIT) {
              return row.amount.point
            }
          }
        },
        {
          title: isShowSaldo ? "Saldo" : "Poin", key: "balance", withCurrency: true, name: "", render: (v, row) => {
            if (row.as === CREDIT) {
              return row.senderBalance.point - row.amount.point
            } else if (row.as === DEBIT) {
              return row.recipientBalance.point + row.amount.point
            } else {
              return "Data Error"
            }
          }
        }
      ]
    } else {
      return []
    }
  }, [isShowSaldo, pointItems, saldoItems, selectedModel])

  return (
    <ReportTableTemplateV2
      title={generateTitle()}
      period={period}
      withIndex
      withPrintButton
      withExportExcel
      withOptionsButton={[
        {
          label: "Saldo",
          response: () => setIsShowSaldo(true)
        },
        {
          label: "Poin",
          response: () => setIsShowSaldo(false)
        }
      ]}
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableMutasi
