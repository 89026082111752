import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const careerApi = {
  list: ({ page, limit, sort }) => {
    let url = `/careers/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url);
  },
  create: (data) => agent.requests.post("/careers/create", data),
  detail: (id) => agent.requests.get(`/careers/${id}/detail`),
  delete: (id) => agent.requests.post(`/careers/${id}/delete`),
  update: (id, data) => agent.requests.post(`/careers/${id}/update`, data),
};
