import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_LOGS_AUDIT,
  ROUTE_LOGS_ERROR,
  ROUTE_LOGS_TRANSACTION,
  // ROUTE_LOGS_AUTODEBIT,
} from '../utils/helpers/roleHelper';

import Audit from '../pages/Logs/Audit'
import ErrorLog from '../pages/Logs/ErrorLog'
import Transaction from '../pages/Logs/Transaction'
// import AutoDebitLog from '../pages/Logs/AutoDebit'

export const logs = {
  type: NAVMENU,
  name: 'Logs',
  icon: 'pe-7s-notebook',
  children: [
    {
      type: NAVLINK,
      name: 'Audit Logs',
      url: '/logs/audit',
      routeTitle: ROUTE_LOGS_AUDIT,
      component: Audit
    },
    {
      type: NAVLINK,
      name: 'Error Logs',
      url: '/logs/errors',
      routeTitle: ROUTE_LOGS_ERROR,
      component: ErrorLog
    },
    {
      type: NAVLINK,
      name: 'Transaction Logs',
      url: '/logs/transactions',
      routeTitle: ROUTE_LOGS_TRANSACTION,
      component: Transaction
    },
    // {
    //   type: NAVLINK,
    //   name: 'Auto Debit',
    //   url: '/logs/auto-debit',
    //   routeTitle: ROUTE_LOGS_AUTODEBIT,
    //   component: AutoDebitLog
    // },
  ]
}
