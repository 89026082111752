class TransactionType {
  static PAY = 'PAY';
  static TRANSFER = 'TRANSFER';
  static REFUND = 'REFUND';
  static TOPUP = 'TOPUP';
  static TOPUP_FEE = 'TOPUP_FEE';
  static REVERSE_TOPUP = 'REVERSE_TOPUP';
  static WITHDRAW = 'WITHDRAW';
  static SETTLEMENT = 'SETTLEMENT';
  static SETTLEMENT_DECLINED = 'SETTLEMENT_DECLINED';
  static CASHBACK = 'CASHBACK';
  static REWARD = 'REWARD';
  static INJECTION_PRIMARY = 'INJECTION_PRIMARY';
  static INJECTION_POINT = 'INJECTION_POINT';
  static INDOFUND_FUNDING = 'INDOFUND_FUNDING';
  static INDOFUND_DEBT = 'INDOFUND_DEBT';
  static INDOFUND = 'INDOFUND';
  static PIGIJO = 'PIGIJO';
  static DEDUCTION_PRIMARY = 'DEDUCTION_PRIMARY';
  static DEDUCTION_POINT = 'DEDUCTION_POINT';
  static POINT_EXPIRED = 'POINT_EXPIRED';
  static CASHOUT = 'CASHOUT';
  static REWARD_REFUND = 'REWARD_REFUND';
  static BANK_TRANSFER = 'BANK_TRANSFER';
  static PARTNER_PAY = 'PARTNER_PAY';
  static QRIS_PAY = 'QRIS_PAY';
  static QRIS_REFUND = 'QRIS_REFUND';
  static QRIS_CANCEL_REFUND = 'QRIS_CANCEL_REFUND';
  static HOLD_BLNC = 'HOLD_BLNC';
  static UNHOLD_BLNC = "UNHOLD_BLNC"
  static BANK_TRANSFER_REFUND = 'BANK_TRANSFER_REFUND';
  static QRIS_MDR = 'QRIS_MDR';
  static QRIS_MDR_INJECTION = 'QRIS_MDR_INJECTION';
  static PARTNER_SETTLEMENT_DECLINE = "PARTNER_SETTLEMENT_DECLINE";
  static PARTNER_SETTLEMENT = "PARTNER_SETTLEMENT"

  static getStr(en) {
    switch (en) {
      case TransactionType.PAY:
        return 'Pay';
      case TransactionType.TRANSFER:
        return 'Transfer';
      case TransactionType.REFUND:
        return 'Refund';
      case TransactionType.TOPUP:
        return 'Topup';
      case TransactionType.TOPUP_FEE:
        return 'Topup Fee';
      case TransactionType.REVERSE_TOPUP:
        return 'Reverse Topup';
      case TransactionType.WITHDRAW:
        return 'Withdraw';
      case TransactionType.SETTLEMENT:
        return 'Settlement';
      case TransactionType.SETTLEMENT_DECLINED:
        return 'Settlement Declined';
      case TransactionType.CASHBACK:
        return 'Cashback';
      case TransactionType.REWARD:
        return 'Reward';
      case TransactionType.INJECTION_PRIMARY:
        return 'Injection Primary';
      case TransactionType.INJECTION_POINT:
        return 'Injection Point';
      case TransactionType.DEDUCTION_PRIMARY:
        return 'Deduction Primary';
      case TransactionType.DEDUCTION_POINT:
        return 'Deduction Point';
      case TransactionType.POINT_EXPIRED:
        return 'Point Expired';
      case TransactionType.CASHOUT:
        return 'Cash Out';
      case TransactionType.REWARD_REFUND:
        return 'Reward Refund';
      case TransactionType.BANK_TRANSFER:
        return 'Bank Transfer';
      case TransactionType.PARTNER_PAY:
        return 'Partner Pay';
      case TransactionType.INDOFUND_FUNDING:
        return 'Indofund Funding';
      case TransactionType.INDOFUND_DEBT:
        return 'Indofund Debt';
      case TransactionType.INDOFUND:
        return 'Indofund';
      case TransactionType.PIGIJO:
        return 'Pigijo';
      case TransactionType.QRIS_PAY:
        return 'QRIS Pay';
      case TransactionType.QRIS_REFUND:
        return 'QRIS Refund';
      case TransactionType.QRIS_CANCEL_REFUND:
        return 'QRIS Cancel Refund';
      case TransactionType.HOLD_BLNC:
        return 'Settlement Hold';
      case TransactionType.UNHOLD_BLNC:
        return 'Settlement Unhold';
      case TransactionType.BANK_TRANSFER_REFUND:
        return 'Bank Transfer Refund';
      case TransactionType.QRIS_MDR:
        return 'QRIS MDR';
      case TransactionType.QRIS_MDR_INJECTION:
        return 'QRIS MDR Injection';
      case TransactionType.PARTNER_SETTLEMENT:
        return 'Partner Settlement';
      case TransactionType.PARTNER_SETTLEMENT_DECLINE:
        return 'Partner Settlement Decline';
      default:
        return 'Unknown';
    }
  }

  static getCode(en) {
    switch (en) {
      case TransactionType.PAY:
        return 'PAY';
      case TransactionType.TRANSFER:
        return 'TRF';
      case TransactionType.REFUND:
        return 'RFD';
      case TransactionType.TOPUP:
        return 'TOP';
      case TransactionType.TOPUP_FEE:
        return 'TOPFEE';
      case TransactionType.REVERSE_TOPUP:
        return 'REVTOP';
      case TransactionType.WITHDRAW:
        return 'WTH';
      case TransactionType.SETTLEMENT:
        return 'SET';
      case TransactionType.SETTLEMENT_DECLINED:
        return 'SETDEC';
      case TransactionType.CASHBACK:
        return 'CSB';
      case TransactionType.REWARD:
        return 'RWD';
      case TransactionType.INJECTION_PRIMARY:
        return 'INJS';
      case TransactionType.INJECTION_POINT:
        return 'INJP';
      case TransactionType.DEDUCTION_PRIMARY:
        return 'DDCS';
      case TransactionType.DEDUCTION_POINT:
        return 'DDCP';
      case TransactionType.POINT_EXPIRED:
        return 'EXPP';
      case TransactionType.CASHOUT:
        return 'CO';
      case TransactionType.BANK_TRANSFER:
        return 'BANKTRANSFER';
      case TransactionType.PARTNER_PAY:
        return 'PARTNERPAY';
      case TransactionType.REWARD_REFUND:
        return 'RWDRFD';
      case TransactionType.QRIS_PAY:
        return 'QRISPAY';
      case TransactionType.QRIS_REFUND:
        return 'QRISRFD';
      case TransactionType.QRIS_CANCEL_REFUND:
        return 'QRISCANCELRFD';
      case TransactionType.HOLD_BLNC:
        return 'HOLDBLNC';
      case TransactionType.UNHOLD_BLNC:
        return 'UNHOLDBLNC';
      case TransactionType.PARTNER_SETTLEMENT:
        return 'PARTNERSETTLEMENT';
      case TransactionType.PARTNER_SETTLEMENT_DECLINE:
        return 'PARTNERSETTLEMENTDECLINE';
      default:
        return 'Unknown';
    }
  }
}

const AllTransactionType = [
  TransactionType.PAY,
  TransactionType.TRANSFER,
  TransactionType.REFUND,
  TransactionType.TOPUP,
  TransactionType.TOPUP_FEE,
  TransactionType.REVERSE_TOPUP,
  TransactionType.WITHDRAW,
  TransactionType.SETTLEMENT,
  TransactionType.SETTLEMENT_DECLINED,
  TransactionType.CASHBACK,
  TransactionType.REWARD,
  TransactionType.INJECTION_PRIMARY,
  TransactionType.INJECTION_POINT,
  TransactionType.INDOFUND_FUNDING,
  TransactionType.INDOFUND_DEBT,
  TransactionType.INDOFUND,
  TransactionType.PIGIJO,
  TransactionType.DEDUCTION_PRIMARY,
  TransactionType.DEDUCTION_POINT,
  TransactionType.POINT_EXPIRED,
  TransactionType.CASHOUT,
  TransactionType.REWARD_REFUND,
  TransactionType.BANK_TRANSFER,
  TransactionType.PARTNER_PAY,
  TransactionType.QRIS_PAY,
  TransactionType.QRIS_REFUND,
  TransactionType.QRIS_CANCEL_REFUND,
  TransactionType.HOLD_BLNC,
  TransactionType.UNHOLD_BLNC,
  TransactionType.QRIS_MDR,
  TransactionType.QRIS_MDR_INJECTION,
  TransactionType.PARTNER_SETTLEMENT,
  TransactionType.PARTNER_SETTLEMENT_DECLINE
];

const TransactionOpts = [
  { value: TransactionType.PAY, label: TransactionType.getStr(TransactionType.PAY) },
  { value: TransactionType.TRANSFER, label: TransactionType.getStr(TransactionType.TRANSFER) },
  { value: TransactionType.REFUND, label: TransactionType.getStr(TransactionType.REFUND) },
  { value: TransactionType.TOPUP, label: TransactionType.getStr(TransactionType.TOPUP) },
  { value: TransactionType.TOPUP_FEE, label: TransactionType.getStr(TransactionType.TOPUP_FEE) },
  { value: TransactionType.REVERSE_TOPUP, label: TransactionType.getStr(TransactionType.REVERSE_TOPUP) },
  { value: TransactionType.WITHDRAW, label: TransactionType.getStr(TransactionType.WITHDRAW) },
  { value: TransactionType.SETTLEMENT, label: TransactionType.getStr(TransactionType.SETTLEMENT) },
  { value: TransactionType.SETTLEMENT_DECLINED, label: TransactionType.getStr(TransactionType.SETTLEMENT_DECLINED) },
  { value: TransactionType.QRIS_MDR, label: TransactionType.getStr(TransactionType.QRIS_MDR) },
  { value: TransactionType.QRIS_MDR_INJECTION, label: TransactionType.getStr(TransactionType.QRIS_MDR_INJECTION) },
  { value: TransactionType.CASHBACK, label: TransactionType.getStr(TransactionType.CASHBACK) },
  { value: TransactionType.REWARD, label: TransactionType.getStr(TransactionType.REWARD) },
  { value: TransactionType.INJECTION_POINT, label: TransactionType.getStr(TransactionType.INJECTION_POINT) },
  { value: TransactionType.INJECTION_PRIMARY, label: TransactionType.getStr(TransactionType.INJECTION_PRIMARY) },
  { value: TransactionType.INDOFUND_FUNDING, label: TransactionType.getStr(TransactionType.INDOFUND_FUNDING) },
  { value: TransactionType.INDOFUND_DEBT, label: TransactionType.getStr(TransactionType.INDOFUND_DEBT) },
  { value: TransactionType.INDOFUND, label: TransactionType.getStr(TransactionType.INDOFUND) },
  { value: TransactionType.PIGIJO, label: TransactionType.getStr(TransactionType.PIGIJO) },
  { value: TransactionType.DEDUCTION_POINT, label: TransactionType.getStr(TransactionType.DEDUCTION_POINT) },
  { value: TransactionType.DEDUCTION_PRIMARY, label: TransactionType.getStr(TransactionType.DEDUCTION_PRIMARY) },
  { value: TransactionType.POINT_EXPIRED, label: TransactionType.getStr(TransactionType.POINT_EXPIRED) },
  { value: TransactionType.CASHOUT, label: TransactionType.getStr(TransactionType.CASHOUT) },
  { value: TransactionType.REWARD_REFUND, label: TransactionType.getStr(TransactionType.REWARD_REFUND) },
  { value: TransactionType.BANK_TRANSFER, label: TransactionType.getStr(TransactionType.BANK_TRANSFER) },
  { value: TransactionType.PARTNER_PAY, label: TransactionType.getStr(TransactionType.PARTNER_PAY) },
  { value: TransactionType.QRIS_PAY, label: TransactionType.getStr(TransactionType.QRIS_PAY) },
  { value: TransactionType.QRIS_REFUND, label: TransactionType.getStr(TransactionType.QRIS_REFUND) },
  { value: TransactionType.QRIS_CANCEL_REFUND, label: TransactionType.getStr(TransactionType.QRIS_CANCEL_REFUND) },
  { value: TransactionType.HOLD_BLNC, label: TransactionType.getStr(TransactionType.HOLD_BLNC) },
  { value: TransactionType.UNHOLD_BLNC, label: TransactionType.getStr(TransactionType.UNHOLD_BLNC) },
  { value: TransactionType.PARTNER_SETTLEMENT, label: TransactionType.getStr(TransactionType.PARTNER_SETTLEMENT) },
  { value: TransactionType.PARTNER_SETTLEMENT_DECLINE, label: TransactionType.getStr(TransactionType.PARTNER_SETTLEMENT_DECLINE) },
];

export { TransactionType, AllTransactionType, TransactionOpts };
