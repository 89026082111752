import { format } from 'date-fns'
import id from 'date-fns/locale/id';
import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import LogoDipay from '../../../assets/icons/dipay-logo/dipay-inline-text.png'
import { toIDR } from '../../../utils/helpers/currency'

const ReportPDF = forwardRef((_, ref) => {
  const newDate = new Date()
  const date = format(newDate, 'dd MMMM yyyy', { locale: id })
  const backDate = format(newDate.setDate(newDate.getDate() - 1), 'dd MMMM yyyy', { locale: id })

  const {
    itemsReport,
    itemsSummaryBillerPrint,
    totalAmountReport,
  } = useSelector(state => state.businesses.settlement);

  return (
    <div
      ref={ref}
      style={{ display: 'none', fontFamily: 'Calibri, sans-serif', fontSize: '12px', color: '#000' }}
      className='d-print-block'
    >
      {
        itemsReport.length
          ?
          <div style={{ padding: '70px' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'end', marginBottom: '20px' }}>
              <img src={LogoDipay} alt="" width={120} />
            </div>
            <h6 style={{ textAlign: 'center' }}>INSTRUKSI SETTLEMENT MERCHANT</h6>
            <p>{`Pada hari ini, ${date} PT Mareco Prima Mandiri akan melakukan settlement pada Merchant atas transaksi ${backDate} dengan rincian sebagai berikut:`}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <table border='1' style={{ fontWeight: '600', fontSize: '12px', borderColor: '#000', color: '#000', marginBottom: '30px', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center', padding: '0 3px' }}>No.</th>
                    <th style={{ textAlign: 'center', padding: '0 15px' }}>Nama Merchant | Unique Code</th>
                    <th style={{ textAlign: 'center', padding: '0 15px' }}>Nama Bank</th>
                    <th style={{ textAlign: 'center', padding: '0 15px' }}>Nomor Rekening</th>
                    <th style={{ textAlign: 'center', padding: '0 15px' }}>Nominal</th>
                    <th style={{ textAlign: 'center', padding: '0 15px' }}>Via Agregator</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    itemsReport.map((row, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: 'center', padding: '4px 3px' }}>{index + 1}</td>
                        <td style={{ textAlign: 'center', padding: '4px 15px' }}>{`${row?.applicant?.businesses?.name} | ${row?.applicant?.uniqueId}`}</td>
                        <td style={{ textAlign: 'center', padding: '4px 15px' }}>{row?.bankAccount?.bank?.name}</td>
                        <td style={{ textAlign: 'center', padding: '4px 15px' }}>{row?.bankAccount?.accountNumber}</td>
                        <td style={{ textAlign: 'center', padding: '4px 15px' }}>{toIDR(row?.amount)}</td>
                        <td style={{ textAlign: 'center', padding: '4px 15px' }}>{row?.aggrBank}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>Total</td>
                    <td style={{ textAlign: 'center' }}>{toIDR(totalAmountReport)}</td>
                    <td style={{ textAlign: 'center' }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
            {renderSummary({ items: itemsSummaryBillerPrint, columns: columnsSummaryBiller })}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <table border="1" style={{ fontSize: '12px', borderColor: '#000', color: '#000', width: '100%' }}>
                <thead>
                  <tr>
                    <th colSpan="2" style={{ textAlign: "center", padding: '0 120px' }}>Mengetahui</th>
                    <th colSpan="2" style={{ textAlign: "center", padding: '0 120px' }}>Menyetujui</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: '40px 20px', borderRight: '1px solid #000' }}></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center", fontWeight: '700' }}>Finance</td>
                    <td style={{ textAlign: "center", fontWeight: '700' }}>Head Accounting</td>
                    <td style={{ textAlign: "center", fontWeight: '700' }}>Direktur</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p style={{ marginTop: '30px' }}>
              1. Minimum settlement Rp 50.000,-
              <br />
              2. Nama, Nama Bank dan Nomor Rekening harus sesuai dengan yang tercantum pada Formulir pendaftaran Merchant
              <br />
              3. PT Mareco Prima Mandiri wajib melakukan settlement pada Merchant H+1 (hari kerja)
            </p>
          </div>
          : null
      }
    </div>
  )
})

export default ReportPDF;

export const reportExcel = ({ itemsReport, totalAmountReport }) => {
  const newDate = new Date()
  const date = format(newDate, 'dd MMMM yyyy', { locale: id })
  const backDate = format(newDate.setDate(newDate.getDate() - 1), 'dd MMMM yyyy', { locale: id })

  return (
    <div style={{ display: 'none', fontFamily: 'Calibri, sans-serif', fontSize: '12px' }} className='d-print-block'>
      {
        itemsReport
        &&
        <>
          <table>
            <thead>
              <tr></tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th><img src={window.location.origin + '/dipay-logo.png'} alt="" width={120} /></th>
              </tr>
              <tr></tr>
            </thead>
          </table>

          <h6 style={{ textAlign: 'center' }}>INSTRUKSI SETTLEMENT MERCHANT</h6>
          <p>{`Pada hari ini, ${date} PT Mareco Prima Mandiri akan melakukan settlement pada Merchant atas transaksi ${backDate} dengan rincian sebagai berikut:`}</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <table border="1" style={{ fontSize: '12px', borderColor: '#000', color: '#000', marginBottom: '30px', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center', padding: '0 14px' }} colSpan='1'>No.</th>
                  <th style={{ textAlign: 'center', padding: '0 20px' }} colSpan='3'>Nama Merchant | Unique Code</th>
                  <th style={{ textAlign: 'center', padding: '0 20px' }} colSpan='1'>Nama Bank</th>
                  <th style={{ textAlign: 'center', padding: '0 20px' }} colSpan='2'>Nomor Rekening</th>
                  <th style={{ textAlign: 'center', padding: '0 20px' }} colSpan='2'>Nominal</th>
                  <th style={{ textAlign: 'center', padding: '0 20px' }} colSpan='1'>Via Agregator</th>
                </tr>
              </thead>
              <tbody>
                {
                  itemsReport.map((row, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: 'center', padding: '4px 14px', fontWeight: '700' }} colSpan='1'>{index + 1}</td>
                      <td style={{ textAlign: 'center', padding: '4px 15px', fontWeight: '700' }} colSpan='3'>{`${row?.applicant?.businesses?.name} | ${row?.applicant?.uniqueId}`}</td>
                      <td style={{ textAlign: 'center', padding: '4px 15px', fontWeight: '700' }} colSpan='1'>{row?.bankAccount?.bank?.name}</td>
                      <td style={{ textAlign: 'center', padding: '4px 15px', fontWeight: '700' }} colSpan='2'>{row?.bankAccount?.accountNumber}</td>
                      <td style={{ textAlign: 'center', padding: '4px 15px', fontWeight: '700' }} colSpan='2'>{toIDR(row?.amount, false)}</td>
                      <td style={{ textAlign: 'center', padding: '4px 15px', textTransform: 'lowercase', fontWeight: '700' }} colSpan='1'>{row?.aggrBank}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td style={{ textAlign: "center", fontWeight: '700' }} colSpan='7'>Total</td>
                  <td style={{ textAlign: 'center', fontWeight: '700' }} colSpan='2'>{toIDR(totalAmountReport, false)}</td>
                  <td style={{ textAlign: 'center', fontWeight: '700' }} colSpan='1'></td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <table border="1" style={{ fontSize: '12px', borderColor: '#000', color: '#000', width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan="5" style={{ textAlign: "center" }}>Mengetahui</th>
                  <th colSpan="5" style={{ textAlign: "center" }}>Menyetujui</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: '80px' }}>
                  <td colSpan="2"></td>
                  <td colSpan="3"></td>
                  <td colSpan="5"></td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center", fontWeight: '700' }} colSpan='2'>Finance</td>
                  <td colSpan="3" style={{ textAlign: "center", fontWeight: '700' }}>Head Accounting</td>
                  <td colSpan="5" style={{ textAlign: "center", fontWeight: '700' }}>Direktur</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <p style={{ marginTop: '30px' }}>
            1. Minimum settlement Rp 50.000,-
            <br />
            2. Nama, Nama Bank dan Nomor Rekening harus sesuai dengan yang tercantum pada Formulir pendaftaran Merchant
            <br />
            3. PT Mareco Prima Mandiri wajib melakukan settlement pada Merchant H+1 (hari kerja)
          </p>
        </>
      }
    </div>
  )
}

const renderSummary = ({ items, columns }) => (
  <table border='1' style={{ fontWeight: '600', fontSize: '12px', borderColor: '#000', color: '#000', marginBottom: '30px', width: '100%' }}>
    <thead>
      <tr>
        {
          columns.map(column => (
            <th key={column.key} style={{ textAlign: 'center', padding: '0 3px' }}>
              {column.name}
            </th>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        items.length ?
          items.map((data, index) => (
            <tr key={data._id}>
              {
                columns.map((column) => (
                  <td key={column.key} style={{ textAlign: 'center', padding: '4px 3px' }}>
                    {
                      column?.render
                        ? column.render(data[column.key], data)
                        : data[column.key]
                    }
                  </td>
                ))
              }
            </tr>
          )) : (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center', padding: '4px 3px' }}>
                No Data
              </td>
            </tr>
          )
      }
    </tbody>
  </table>
)

const columnsSummaryBiller = [
  { name: 'Aggregator', key: '_id', render: v => <span>{v}</span> },
  { name: 'Fee', key: 'fee', className: 'text-center', render: v => <span>{toIDR(v)}</span> },
  { name: 'Amount', key: 'amount', className: 'text-center', render: v => <span>{toIDR(v)}</span> },
  { name: 'Total Amount', key: 'totalAmount', className: 'text-right', render: v => <span>{toIDR(v)}</span> },
]
