import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import {
  PARTNER_MODULE
} from "../../../utils/constants/actionTypes";
import Resource from "../../../components/Resource";
import Detail from "./Detail/index";
import { partnerApi } from "../../../services/partnerApi";
import { IntegrationTypeOpts } from "../../../utils/constants/enums/integrationTypes";
import { SELECT_FIELD } from "../../../utils/constants/inputTypes";
import { toIDR } from "../../../utils/helpers/currency";
import RoundBadge from '../../../components/Badge/RoundBadge'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateTab from './CreateTab'

const Index = () => {
  const { currentPage, limit, sort, filter } = useSelector(
    (state) => state.partner.list
  );

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Partner Name', key: 'name', name: 'name' },
    { title: 'Unique ID', key: 'uniqueId', name: 'uniqueId' },
    { title: 'Field', key: 'field', name: 'field' },
    {
      title: 'Fee', key: 'fee', name: 'fee', render: (v, row) => {
        return row.feeType === 'PERCENTAGE' ? `${v}%` : toIDR(v, true);
      }
    },
    {
      title: 'Status', key: 'status', name: 'status', sortable: false, render: (v, row) => <Statuses user={row} />
    }
  ];

  const apiQuery = useMemo(() => {
    const search = filter.integrationType ? `integrationType|${filter.integrationType}` : null;
    const status = filter.status;

    return {
      page: currentPage,
      limit,
      sort,
      search,
      status
    };
  }, [currentPage, limit, sort, filter]);

  return (
    <div>
      <Resource
        title="Partner List"
        subTitle="Full Partner Control includes Create, Delete & Updates."
        icon="pe-7s-global"
        list={{
          columns: columns,
          reducerPath: "partner.list",
          module: PARTNER_MODULE,
          call: partnerApi.list,
          apiQuery: apiQuery,
          filters: [
            {
              label: "Integration Type",
              type: SELECT_FIELD,
              key: "integrationType",
              options: IntegrationTypeOpts,
              value: filter.integrationType
            },
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: [
                { value: 'CLOSED', label: 'Closed' },
                { value: 'NOT_ACTIVATED', label: 'Not Activated' },
                { value: 'ACTIVATED', label: 'Activated' }
              ],
              value: filter.status
            }
          ]
        }}
        detail={{ component: Detail }}
        create={{ component: CreateTab }}
      />
    </div>
  );
};

const Statuses = ({ user }) => {
  const ActivatedOrClosed = () => {
    if (user.status?.closed) {
      return <RoundBadge
        title="User Closed"
        active={true}
        variant="danger"
        icon={CancelIcon}
      />;
    } else {
      return <RoundBadge
        title={user.status?.isTemporaryPassword ? `Not Activated` : 'Activated'}
        active={!user.status?.isTemporaryPassword}
        variant="primary"
        icon={VerifiedUserIcon}
      />;
    }
  }

  return (
    <div className="d-flex align-items-center">
      {ActivatedOrClosed()}
    </div>
  )
}

export default Index;
