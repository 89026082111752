class UserStatusHistoriesType {
    static USER_UPGRADE_APPROVED = 'USER_UPGRADE_APPROVED';
    static USER_UPGRADE_REJECTED = 'USER_UPGRADE_REJECTED';
    static USER_SUSPENDED = 'USER_SUSPENDED';
    static USER_UNSUSPEND_APPROVED = 'USER_UNSUSPEND_APPROVED';
    static USER_UNSUSPEND_REJECTED = 'USER_UNSUSPEND_REJECTED';
    static USER_UNLOCK_APPROVED = 'USER_UNLOCK_APPROVED';
    static USER_UNLOCK_REJECTED = 'USER_UNLOCK_REJECTED';
    static FORCE_KYC = 'FORCE_KYC';
    static UNFORCE_KYC = 'UNFORCE_KYC';
    static USER_CLOSE_APPROVED = 'USER_CLOSE_APPROVED';
    static USER_CLOSE_REJECTED = 'USER_CLOSE_REJECTED';

    static getStr(en) {
        switch (en) {
            case UserStatusHistoriesType.USER_UPGRADE_APPROVED:
                return 'User Upgraded';
            case UserStatusHistoriesType.USER_UPGRADE_REJECTED:
                return 'User Upgrade Rejected';
            case UserStatusHistoriesType.USER_SUSPENDED:
                return 'User Suspended';
            case UserStatusHistoriesType.USER_UNSUSPEND_APPROVED:
                return 'User Unsuspended';
            case UserStatusHistoriesType.USER_UNSUSPEND_REJECTED:
                return 'User Unsuspend Rejected';
            case UserStatusHistoriesType.USER_UNLOCK_APPROVED:
                return 'User Unlocked';
            case UserStatusHistoriesType.USER_UNLOCK_REJECTED:
                return 'User Unlock Rejected';
            case UserStatusHistoriesType.FORCE_KYC:
                return 'Force KYC';
            case UserStatusHistoriesType.UNFORCE_KYC:
                return 'Un-Force KYC';
            case UserStatusHistoriesType.USER_CLOSE_APPROVED:
                return 'User Closed';
            case UserStatusHistoriesType.USER_CLOSE_REJECTED:
                return 'User Close Rejected';
            default:
                return 'Unknown';
        }
    }

    static getColor(en) {
        switch (en) {
            case UserStatusHistoriesType.USER_UPGRADE_APPROVED:
                return 'badge-success';
            case UserStatusHistoriesType.USER_UPGRADE_REJECTED:
                return 'badge-danger';
            case UserStatusHistoriesType.USER_SUSPENDED:
                return 'badge-danger';
            case UserStatusHistoriesType.USER_UNSUSPEND_APPROVED:
                return 'badge-primary';
            case UserStatusHistoriesType.USER_UNSUSPEND_REJECTED:
                return 'badge-danger';
            case UserStatusHistoriesType.USER_UNLOCK_APPROVED:
                return 'badge-primary';
            case UserStatusHistoriesType.USER_UNLOCK_REJECTED:
                return 'badge-danger';
            case UserStatusHistoriesType.FORCE_KYC:
                return 'badge-warning';
            case UserStatusHistoriesType.UNFORCE_KYC:
                return 'badge-primary';
            case UserStatusHistoriesType.USER_CLOSE_APPROVED:
                return 'badge-danger';
            case UserStatusHistoriesType.USER_CLOSE_REJECTED:
                return 'badge-primary';
            default:
                return 'Unknown';
        }
    }
}
const UserStatusHistoriesEnum = [
    UserStatusHistoriesType.USER_UPGRADE_APPROVED,
    UserStatusHistoriesType.USER_UPGRADE_REJECTED,
    UserStatusHistoriesType.USER_SUSPENDED,
    UserStatusHistoriesType.USER_UNSUSPENDED,
    UserStatusHistoriesType.USER_UNLOCKED,
    UserStatusHistoriesType.FORCE_KYC,
    UserStatusHistoriesType.USER_CLOSED
];

export { UserStatusHistoriesType, UserStatusHistoriesEnum };
