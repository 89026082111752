import AllStatus from ".";

const UserStatusOpts = [
  { value: AllStatus.SUSPENDED, label: AllStatus.getStr(AllStatus.SUSPENDED) },
  { value: AllStatus.LOCKED, label: AllStatus.getStr(AllStatus.LOCKED) },
  { value: AllStatus.PENDING_VERIFY, label: AllStatus.getStr(AllStatus.PENDING_VERIFY) },
  { value: AllStatus.UPGRADED, label: AllStatus.getStr(AllStatus.UPGRADED) },
  { value: AllStatus.NOT_UPGRADED, label: AllStatus.getStr(AllStatus.NOT_UPGRADED) },
  { value: AllStatus.FLAGGED, label: AllStatus.getStr(AllStatus.FLAGGED) },
  { value: AllStatus.CLOSED, label: AllStatus.getStr(AllStatus.CLOSED) },
  { value: AllStatus.FORCE_KYC, label: AllStatus.getStr(AllStatus.FORCE_KYC) },
];

export default UserStatusOpts
