import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { bankTransferFeeApi } from '../../../services/bankTransferFeeApi'
import { BANK_TRANSFER_FEE_MODULE, CREATE } from '../../../utils/constants/actionTypes'
import useMountedState from '../../../components/HooksUse/useMountedState'
import Form from './Form'

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState();

  const onSubmit = (val) => {
    const data = {
      name: val.name,
      billerFee: val.billerFee,
      adminFee: val.adminFee,
      revenue: val.adminFee - val.billerFee
    }

    setIsLoading(true)
    dispatch({
      module: BANK_TRANSFER_FEE_MODULE,
      type: CREATE,
      call: bankTransferFeeApi.create,
      args: [data]
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  )
}

export default CreateTab
