import { faPrint, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { qrisRintisApi } from '../../../services/qrisRintisApi';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import FormField from '../../../components/Forms/FormField';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import SelectField from '../../../components/Forms/SelectField';
import Resource from '../../../components/Resource';
import { ExcelIcon } from '../../../components/Icons/Icons';

function Index() {
  return (
    <Resource
      title="QRIS Rintis"
      subTitle="View QRIS Rintis Non Recon"
      icon="pe-7s-note2"
      customComponent={FilterComponent}
    />
  )
}

const FilterComponent = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [reconnecting, setReconnecting] = useState(false)
  const [type, setType] = useState(null)
  const [txtData, setTxtData] = useState('');
  const [date, setDate] = useState(null);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const submit = useCallback(() => {
    setLoading(true)
    dispatch({
      type: null,
      call: qrisRintisApi.getQrisFile,
      args: [{ type: type, date: date.toISOString() }]
    })
      .then(({ data: { fileBuffer: { data } } }) => {
        var enc = new TextDecoder("utf-8");
        var arr = new Uint8Array(data);
        var decodedText = enc.decode(arr);
        setTxtData(decodedText);
      })
      .catch(() => setTxtData(''))
      .finally(() => setLoading(false))
  }, [dispatch, type, date])

  const reconnect = useCallback(() => {
    setReconnecting(true)
    dispatch({
      type: null,
      call: qrisRintisApi.nginxReconnectRintis
    })
      .then(() => {
        toast("Berhasil terkoneksi dengan Rintis", { type: 'success' });
      })
      .catch(() => {
        toast("Gagal terkoneksi dengan Rintis", { type: 'error' });
      })
      .finally(() => setReconnecting(false))
  }, [dispatch])

  const excelFilename = useMemo(() => {
    switch (type) {
      case 'SETTLE_ACQ':
        return 'LAPORAN_TRANSAKSI_QR_AS_ACQUIRER_PT_RINTIS_SEJAHTERA';
      case 'SETTLE_ISS':
        return 'LAPORAN_TRANSAKSI_QR_AS_ISSUER_PT_RINTIS_SEJAHTERA';
      case 'DISPUTE':
        return 'LAPORAN_TRANSAKSI_HAK_DISPUTE_QR_PT_RINTIS_SEJAHTERA';
      default: return null
    }
  }, [type])

  const exportExcel = useCallback(() => {
    const blob = new Blob([txtData], { type: 'application/octet-stream' });

    // Create a download link and trigger a click to download the file
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${excelFilename}.xls`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [txtData, excelFilename])

  return (
    <div>
      <div className="main-card mb-3 card">
        <div className="card-body">
          <SelectField
            label="Status Filter"
            onChange={v => setType(v?.value ?? null)}
            options={[
              { label: 'Settle Acquirer', value: 'SETTLE_ACQ' },
              { label: 'Settle Issuer', value: 'SETTLE_ISS' },
              { label: 'Dispute', value: 'DISPUTE' },
            ]}
            placeholder="No Filter"
            value={type}
            componentProps={{
              isClearable: true
            }}
          />
          <FormField label="Date Filter">
            <EvyDatePicker
              value={date}
              onChange={v => setDate(startOfDay(new Date(v)))}
              variant="inline"
              placeholder="Select date"
              autoOk
            />
          </FormField>
          <LoadingBtn
            className="btn btn-primary btn-block btn-lg"
            loading={loading}
            type="button"
            disabled={!type || loading}
            onClick={submit}
          >
            Submit
          </LoadingBtn>
          <LoadingBtn
            className="btn btn-outline-primary btn-block btn-lg"
            type="button"
            onClick={reconnect}
            loading={reconnecting}
            disabled={reconnecting}
          >
            <FontAwesomeIcon icon={faSyncAlt} className="mr-1" />
            Reconnect
          </LoadingBtn>
        </div>
      </div>
      {
        txtData ?
          <div className="main-card mb-3 card">
            <div className="card-body">
              <button className="btn btn-secondary mr-2" onClick={handlePrint}>
                Print
                <FontAwesomeIcon className="ml-2" icon={faPrint} size="1x" />
              </button>
              <button className="btn btn-excel btn-icon btn-icon-only" onClick={exportExcel}>
                <span className='mr-2'>Export Excel</span>
                <ExcelIcon size={20} />
              </button>
              <div ref={componentRef}>
                <pre>{txtData}</pre>
              </div>
            </div>
          </div> :
          <h5 className="mb-0 px-3 py-4 text-center">No Data</h5>
      }
    </div>
  )
}

export default Index;
