import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import styles from './connectedPartner.module.scss'

const ConnectedPartner = ({ item }) => {
  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className="card-title mb-0">Connected Partner</h5>
        </div>
        <div>
          <h5 className="card-title mb-0">Total : {item.connectedPartner.length}</h5>
        </div>
      </div>
      {item.connectedPartner.length !== 0 ?
        <div className="referreds">
          <PerfectScrollbar>
            <div className="card-body border p-0">
              {item.connectedPartner.map((item, k) =>
                <div className="referred-wrapper m-0 p-3" key={k}>
                  <div className={styles.ref}>
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0 mr-1">{item.companyCode}</h6>
                      {item?.privateUser ?
                        <div className={styles.badge}>
                          <span>Private</span>
                        </div>
                        : null
                      }
                    </div>
                    {item?.companyName ?
                      <p className='mt-1'>{item?.companyName}</p>
                      :
                      null
                    }
                  </div>
                  <div>
                    <p className="referred-index">{k + 1}</p>
                  </div>
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </div> :
        <div>No Partner Connected</div>
      }
    </>
  )
}

export default ConnectedPartner

