import { NAVHEAD } from '../utils/constants/dataTypes';
import {
  ROUTE_NAVHEAD_ONE,
  ROUTE_NAVHEAD_TWO,
  ROUTE_NAVHEAD_THREE,
} from '../utils/helpers/roleHelper';

import { dashboard } from './dashboard';
import { users } from './users';
import { partner } from './partner';
import { businesses } from './businesses';
import { transactions } from './transactions';
import { articles } from './articles';
import { vouchers } from './vouchers';
import { reports } from './reports';
import { billers } from './billers';
import { internal } from './internal';
import { notification } from './notification';
import { logs } from './logs';
import { career } from "./career"
import { promo } from './promo';
import { announcement } from './announcement';

const routes = [
  // Main Course
  {
    type: NAVHEAD,
    name: 'Main Course',
    routeTitle: ROUTE_NAVHEAD_ONE
  },
  dashboard,

  // Menu
  {
    type: NAVHEAD,
    name: 'Menu',
    routeTitle: ROUTE_NAVHEAD_TWO,
  },
  users,
  partner,
  businesses,
  transactions,
  articles,
  announcement,
  vouchers,
  reports,
  career,

  // Dipay System Control
  {
    type: NAVHEAD,
    name: 'Dipay System Control',
    routeTitle: ROUTE_NAVHEAD_THREE,
  },
  billers,
  // documentation,
  internal,
  promo,
  notification,
  logs
];

export default routes
