import React, { Component } from 'react';
import Select from 'react-select'
import FormField from './FormField';

class EvySelectField extends Component {
  render() {
    const {
      id,
      row,
      label,
      onChange,
      onBlur,
      error,
      helperText,
      placeholder,
      options,
      className,
      nullable,
      disabled,
      readOnly,
      advanceSelect,
      componentProps,
      formFieldAppend,
      isLoading
    } = this.props;
    const value = this.props.value ? this.props.value : '';

    return (
      <FormField
        row={row}
        label={label}
        error={error}
        helperText={helperText}
        append={formFieldAppend}
      >
        {advanceSelect ? (
          <Select
            {...componentProps}
            id={id}
            name={id}
            value={value}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            isLoading={isLoading}
            placeholder={placeholder}
            className={`select-styled ${className ? className : ''} ${error ? 'is-invalid' : ''}`}
          />
        ) : (
          <select
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            name={id}
            value={value}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            className={`form-control${className ? ' ' + className : ''} ${error ? ' is-invalid' : ''}`}
          >
            {placeholder && (
              <option disabled={!nullable} value="">{placeholder}</option>
            )}
            {options.map((opt, key) =>
              <option key={key} value={opt.value}>{opt.label}</option>
            )}
          </select>
        )}
      </FormField>
    )
  }
}

export default EvySelectField;
