import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { bankApi } from '../../../services/bankApi';
import { BANK_MODULE, UPDATE } from '../../../utils/constants/actionTypes';
import useMountedState from '../../../components/HooksUse/useMountedState';
import Form from './Form';

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    name: '',
    code: '',
    abbr: '',
    logo: '',
    artajasaCode: null,
  }
  const isMounted = useMountedState();

  const submit = useCallback((values) => {
    const data = {
      ...values,
      artajasaCode: values.artajasaCode.value
    }

    setIsLoading(true);
    dispatch({
      module: BANK_MODULE,
      type: UPDATE,
      call: bankApi.update,
      args: [null, data]
    }).catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }, [dispatch, isMounted])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          submit={submit}
          defaultValues={defaultValues}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default CreateTab
