import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = process.env.REACT_APP_BASE_API;
const responseBody = res => res.body;

const jsonToFormData = function (inJSON, inFormData, parentKey) {
  // http://stackoverflow.com/a/22783314/260665
  // Raj: Converts any nested JSON to formData.
  var formData = inFormData || new FormData();
  for (var key in inJSON) {
    // 1. If it is a recursion, then key has to be constructed like "parent[child]" where parent JSON contains a child JSON
    // 2. Perform append data only if the value for key is not a JSON, recurse otherwise!
    var constructedKey = key;
    if (parentKey) {
      constructedKey = `${parentKey}[${key}]`;
      // constructedKey = parentKey + "[" + key + "]";
    }

    var value = inJSON[key];
    if (value && (value.constructor === {}.constructor || value.constructor === [].constructor)) {
      // This is a JSON, we now need to recurse!
      jsonToFormData(value, formData, constructedKey);
    } else {
      formData.append(constructedKey, inJSON[key]);
    }
  }
  return formData;
}

let token = null;

const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Bearer ${token}`);
  }
}

const setHeader = (req, file) => {
  if (!file) req.set('Content-Type', 'application/json')
}

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body, file = false, secretKey = '') => {
    let formData;
    if (file) {
      formData = jsonToFormData(body);
    } else {
      formData = body
    }

    return superagent
      .post(`${API_ROOT}${url}`)
      .send(formData)
      .use(tokenPlugin)
      .use(req => setHeader(req, file))
      .use(req => secretKey ? req.set('x-secret-key', secretKey) : null)
      .then(responseBody);
  },
  patch: (url, body, file = false) => {
    let formData;
    if (file) {
      formData = jsonToFormData(body);
    } else {
      formData = body
    }

    return superagent
      .patch(`${API_ROOT}${url}`)
      .send(formData)
      .use(tokenPlugin)
      .use(req => setHeader(req, file))
      .then(responseBody);
  },
  delete: (url, body, file = false) => {
    let formData;
    if (file) {
      formData = jsonToFormData(body);
    } else {
      formData = body
    }

    return superagent
      .del(`${API_ROOT}${url}`)
      .send(formData)
      .use(tokenPlugin)
      .use(req => setHeader(req, file))
      .then(responseBody);
  }
};

const agent = {
  requests,
  setToken: _token => { token = _token }
}

export default agent;
