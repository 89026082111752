import React, { useMemo } from 'react';
import Resource from '../../../components/Resource';
import { useSelector } from 'react-redux';
import { REFERRAL_MODULE } from '../../../utils/constants/actionTypes';
import CreateTab from './CreateTab';
import { referralApi } from '../../../services/referralApi';
import { endOfDay, format, startOfDay } from 'date-fns';
import { DATE_PICKER, SELECT_FIELD } from '../../../utils/constants/inputTypes';
import ReferredList from './List';
import UpdateTab from './UpdateTab';

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.referral.mainList);

  const columns = [
    { title: 'Referral Code', key: 'name', name: 'name' },
    { title: 'Status', key: 'isActive', name: 'isActive', render: v => v ? "Active" : "Inactive" },
    { title: 'Periode From', key: 'startPeriod', name: 'startPeriod', render: v => format(new Date(v), 'yyyy-MM-dd'), },
    { title: 'Periode To', key: 'endPeriod', name: 'endPeriod', render: v => format(new Date(v), 'yyyy-MM-dd') },
    { title: 'Total referred', key: 'totalReferred', name: 'totalReferred' },
    { title: 'Quota', key: 'quota', name: 'quota', render: (v, row) => row.isQuotaEnabled ? v : "-" },
    { title: 'Remaining Quota', key: 'remainingQuota', name: 'remainingQuota', render: (v, row) => row.isQuotaEnabled ? v : "-" },
  ]

  const apiQuery = useMemo(() => {
    const startPeriod = filter.startPeriod ? startOfDay(new Date(filter.startPeriod), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const endPeriod = filter.endPeriod ? endOfDay(new Date(filter.endPeriod), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const search = filter.search ? `name|${filter.search}` : null;

    return {
      startPeriod,
      endPeriod,
      search,
      status: filter.status,
      page: currentPage,
      limit,
      sort
    }
  }, [currentPage, limit, sort, filter])

  return (
    <Resource
      title="Referral List"
      subTitle="Referral Listing page."
      icon="pe-7s-share"
      list={{
        search: true,
        columns: columns,
        reducerPath: "referral.mainList",
        module: REFERRAL_MODULE,
        call: referralApi.list,
        apiQuery,
        filters: [
          {
            label: "Filter Status",
            type: SELECT_FIELD,
            key: "status",
            options: [
              {
                value: "ACTIVE",
                label: "Active"
              },
              {
                value: "INACTIVE",
                label: "Inactive"
              }
            ],
            value: filter.status
          },
          {
            label: "Start Period",
            type: DATE_PICKER,
            key: "startPeriod",
            value: filter.startPeriod,
            placeholder: "Select date"
          },
          {
            label: "End Period",
            type: DATE_PICKER,
            key: "endPeriod",
            value: filter.endPeriod,
            placeholder: "Select date"
          }
        ]
      }}
      create={{
        component: CreateTab
      }}
      update={{
        component: UpdateTab
      }}
      detail={{
        component: ReferredList
      }}
    />
  );
}

export default Index;
