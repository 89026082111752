import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { partnerApi } from '../../services/partnerApi';
import SelectField from '../Forms/SelectField';
import useMountedState from '../HooksUse/useMountedState';

const staticValues = {
  sort: 'name|asc',
  limit: 10,
}

const PartnerSelect = ({
  label,
  disabled,
  error,
  helperText,
  onChange,
  value,
  placeholder,
  filterIntegrationType,
  getOptionValue,
  isMulti
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [totalItems, setTotalItems] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const { sort, limit } = staticValues;
  const isMounted = useMountedState();
  const [initialValue] = useState(value)

  const getPartnerListDebounced = useMemo(
    () => AwesomeDebouncePromise(partnerApi.list, 500),
    [],
  )

  const generateOpts = data => data.map(row => ({
    value: row._id,
    label: `${row.name} | ${row.integrationType}`,
    model: 'Partner',
    optData: row
  }))

  const dispatchLoad = useCallback(
    (call, page, limit, sort, search, searchByID) => {
      return dispatch({
        type: null,
        call: call,
        args: [
          {
            page: filterIntegrationType ? 0 : page,
            limit: filterIntegrationType ? 0 : limit,
            sort: search ? null : sort,
            search: searchByID ?
              `_id|${searchByID}`
              :
              filterIntegrationType && !search ?
                `integrationType|${filterIntegrationType}`
                :
                search ?
                  `name|${search},integrationType|${filterIntegrationType ? filterIntegrationType : search}`
                  : null
          }
        ]
      })
    },
    [dispatch, filterIntegrationType],
  )

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);
      dispatchLoad(partnerApi.list, newPage, limit, sort, search)
        .then(({ data: { partners, count } }) => {
          setOptions([...options, ...generateOpts(partners)])
          setTotalItems(count)
        })
        .catch(() => { })
        .finally(() => setIsLoading(false));
    }
  }

  const handleSelectChange = opt => {
    setSelectedOption(opt)
    setSearch('')

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(getPartnerListDebounced, newPage, limit, sort, search)
        .then(({ data: { partners, count } }) => {
          if (isMounted()) {
            options = generateOpts(partners)
            setTotalItems(count)
            const initial = typeof initialValue === 'object' ? initialValue.value : initialValue
            let selectionExist = Boolean(partners.find(v => v._id === initial))
            if (initial && !search && !selectionExist && !filterIntegrationType) return dispatchLoad(getPartnerListDebounced, 0, 0, null, null, initial)
          }
        })
        .then(({ data: { partners } = {} } = {}) => {
          if (isMounted() && partners) options = [...generateOpts(partners), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setOptions(options)
            setIsLoading(false)
          }
        });
    }
    loadData()
  }, [search, sort, limit, dispatchLoad, getPartnerListDebounced, isMounted, initialValue, filterIntegrationType])

  return (
    <SelectField
      id="selectPartner"
      isMulti={isMulti}
      label={label ?? "Select Partner"}
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder={placeholder ?? "Type anything.."}
      componentProps={{
        inputValue: search,
        onInputChange: setSearch,
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom,
      }}
      isDisabled={disabled}
      error={error}
      helperText={helperText}
      getOptionValue={getOptionValue}
    />
  )
}

export default PartnerSelect;
