import { Autorenew, Cancel, Done, DonutLarge, GridOff, Pause, Payment } from '@material-ui/icons';
import { format } from "date-fns";
import { merchantCriteriaType } from "../../../../utils/constants/enums/merchantCriteriaTypes";
import AllStatus from "../../../../utils/constants/enums/status";
import { switcherMerchantType } from "../../../../utils/constants/enums/switcherMerchantTypes";
import { combineBy } from "../../../../utils/helpers/combineBy";
import { toIDR } from "../../../../utils/helpers/currency";
import RoundBadge from "../../../../components/Badge/RoundBadge";
import stringToTitle from '../../../../utils/helpers/stringToTitle';

const getStatusColor = (status) => {
  if (
    status === AllStatus.PROCESSING ||
    status === AllStatus.REFUND_PROCESSING
  ) return 'primary'
  if (
    status === AllStatus.PENDING
  ) return 'warning'
  if (
    status === AllStatus.CANCELLED ||
    status === AllStatus.FAILED ||
    status === AllStatus.SUSPECTED
  ) return 'danger'
  if (
    status === AllStatus.COMPLETED ||
    status === AllStatus.REFUNDED
  ) return 'success'
  return null
}

const getIcon = (status) => {
  if (status === AllStatus.PROCESSING) return Autorenew
  if (status === AllStatus.CANCELLED) return Cancel
  if (status === AllStatus.PENDING) return Pause
  if (status === AllStatus.COMPLETED) return Done
  if (status === AllStatus.REFUNDED) return Payment
  if (status === AllStatus.FAILED) return GridOff
  if (status === AllStatus.REFUND_PROCESSING) return DonutLarge
  if (status === AllStatus.SUSPECTED) return Cancel
  return null
}

const QRISStatuses = ({ v }) => {
  return v.map((res, i) =>
    <div className="d-flex" key={i}>
      <RoundBadge
        title={`${AllStatus.getStr(res.status)} at: ${format(
          new Date(res.createdAt),
          "yyyy-MM-dd HH:mm:ss"
        )}`}
        active
        variant={getStatusColor(res.status)}
        icon={getIcon(res.status)}
      />
      {(v.length - 1) !== i &&
        <div className="font-weight-bold mx-1">{'>'}</div>
      }
    </div>
  )
}

export const columns = [
  {
    title: 'Created At', key: 'createdAt', name: 'createdAt', render: (v, row) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
  },
  {
    title: 'Trnno/RRN', key: 'code', name: 'code', render: (v, row) => `${v} | ${row.metadata?.creditRequest?.retrievalReferenceNumber ?? '-'}`
  },
  { title: 'Merchant Criteria', key: 'MCriteria', name: 'MCriteria', render: (v) => v ? merchantCriteriaType.getStr(v) : "-" },
  {
    title: 'User', key: 'applicant', name: 'applicant', sortable: false, render: (v, row) => {
      const combine = combineBy([v?.firstName, v?.lastName]);
      return combine ? `${combine} | ${v?.uniqueId}` : '-';
    }
  },
  {
    title: 'Merchant', key: 'merchant', name: 'merchant', render: (v, row) =>
      `
        ${row.metadata?.creditRequest?.merchantNameLocation?.slice(0, 25).trim()}
        ${row.metadata?.merchant ? `| ${row.metadata?.creditRequest?.trxPAN.slice(9, 25).substring(0, 9).trim()}` : ""}
        `
      ?? '-'
  },
  {
    title: 'Connected Partner', key: 'partner', name: 'partner', render: (v, row) => 
      `${stringToTitle(row?.metadata?.clientData?.client) ?? '-'}`
  },
  {
    title: 'Merchant Connected Partner', key: 'mPartner', name: 'mPartner', render: (v, row) => 
      `${row?.metadata?.merchantPartners?.map((v) => {
        return ` ${stringToTitle(v)}`
      }) ?? '-'}`
  },
  {
    title: 'Issuer', key: 'issuerName', name: 'issuerName', render: (v, row) => v ? `${v} | ${row.metadata?.creditRequest?.issuerID}` : '-'
  },
  {
    title: 'Acquirer', key: 'acquirerName', name: 'acquirerName', render: (v, row) => v ? `${v} | ${row.metadata?.creditRequest?.acquirerID}` : '-'
  },
  {
    title: 'Issuer SWC', key: 'issuerSWC', name: 'issuerSWC'
  },
  {
    title: 'Acquirer SWC', key: 'acquirerSWC', name: 'acquirerSWC'
  },
  {
    title: 'User Payment', key: 'amount', name: 'amount', render: v => toIDR(v)
  },
  {
    title: 'MDR PCT', key: 'mdr', name: 'mdr'
  },
  {
    title: 'MDR Amount', key: 'mdrAmount', name: 'mdrAmount', render: v => toIDR(v)
  },
  {
    title: 'Revenue', key: 'revenue', name: 'revenue', render: v => toIDR(v)
  },
  {
    title: 'Merchant Receive', key: 'merchantReceive', name: 'merchantReceive', render: v => toIDR(v)
  },
  {
    title: 'switcher', key: 'switcher', name: 'switcher', render: v => switcherMerchantType.getStr(v)
  },
  {
    title: 'Opr Status', key: 'OprSts', name: 'OprSts', render: v => AllStatus.getStrIdn(v.current)
  },
  {
    title: 'Status Progress', key: 'statusProgress', name: 'status', className: 'text-center', sortable: false, render: (v) => {
      return (
        <div className="d-flex">
          <QRISStatuses v={v} />
        </div>
      )
    }
  }
];

export const excelColumns = [
  {
    title: 'PROCESSING', key: 'statusProgress', name: 'status', render: v => {
      return (
        <div>
          {v.some((res) => res.status === "PROCESSING") && format(new Date(v.find((res) => res.status === "PROCESSING")?.createdAt), 'yyyy-MM-dd HH:mm')}
        </div>
      )
    }
  },
  {
    title: 'COMPLETED', key: 'statusProgress', name: 'status', render: v => {
      return (
        <div>
          {v.some((res) => res.status === "COMPLETED") && format(new Date(v.find((res) => res.status === "COMPLETED")?.createdAt), 'yyyy-MM-dd HH:mm')}
        </div>
      )
    }
  },
  {
    title: 'FAILED', key: 'statusProgress', name: 'status', render: v => {
      return (
        <div>
          {
            (v.some((res) => res.status === "FAILED") && v.some((res) => res.status === "CANCELLED"))
            &&
            format(new Date(v.find((res) => res.status === "FAILED" || res.status === "CANCELLED")?.createdAt), 'yyyy-MM-dd HH:mm')
          }
        </div>
      )
    }
  },
  {
    title: 'REFUND PROCESSING', key: 'statusProgress', name: 'status', render: v => {
      return (
        <div>
          {v.some((res) => res.status === "REFUND_PROCESSING") && format(new Date(v.find((res) => res.status === "REFUND_PROCESSING")?.createdAt), 'yyyy-MM-dd HH:mm')}
        </div>
      )
    }
  },
  {
    title: 'REFUND FAILED', key: 'statusProgress', name: 'status', render: v => {
      return (
        <div>
          {
            (
              v.some((res) => res.status === "REFUND_PROCESSING")
              &&
              v.some((res) => res.status === "COMPLETED")
              &&
              !v.some((res) => res.status === "REFUNDED")
            )
            &&
            format(new Date(v.find((res) => res.status === "COMPLETED")?.createdAt), 'yyyy-MM-dd HH:mm')
          }
        </div>
      )
    }
  },
  {
    title: 'REFUNDED COMPLETED', key: 'statusProgress', name: 'status', render: v => {
      return (
        <div>
          {v.some((res) => res.status === "REFUNDED") && format(new Date(v.find((res) => res.status === "REFUNDED")?.createdAt), 'yyyy-MM-dd HH:mm')}
        </div>
      )
    }
  },
  {
    title: 'Trnno/RRN', key: 'code', name: 'code', render: (v, row) => `${v} | ${row.metadata?.creditRequest?.retrievalReferenceNumber ?? '-'}`
  },
  {
    title: 'User', key: 'applicant', name: 'applicant', sortable: false, render: (v, row) => {
      const combine = combineBy([v?.firstName, v?.lastName]);
      return combine ? `${combine} | ${v?.uniqueId}` : "-";
    }
  },
  {
    title: 'Merchant', key: 'merchant', name: 'merchant', render: (v, row) =>
      `
        ${row.metadata?.creditRequest?.merchantNameLocation?.slice(0, 25).trim()}
        ${row.metadata?.merchant ? `| ${row.metadata?.creditRequest?.trxPAN.slice(9, 25).trim()}` : ""}
        `
      ?? '-'
  },
  {
    title: 'Connected Partner', key: 'partner', name: 'partner', render: (v, row) => 
      `${stringToTitle(row?.metadata?.clientData?.client) ?? '-'}`
  },
  {
    title: 'Merchant Connected Partner', key: 'mPartner', name: 'mPartner', render: (v, row) => 
      `${row?.metadata?.merchantPartners}`
      ?? '-'
  },
  {
    title: 'Issuer', key: 'issuerName', name: 'issuerName', render: (v, row) => v ? `${v} | ${row.metadata?.creditRequest?.issuerID}` : '-'
  },
  {
    title: 'Acquirer', key: 'acquirerName', name: 'acquirerName', render: (v, row) => v ? `${v} | ${row.metadata?.creditRequest?.acquirerID}` : '-'
  },
  {
    title: 'Issuer SWC', key: 'issuerSWC', name: 'issuerSWC'
  },
  {
    title: 'Acquirer SWC', key: 'acquirerSWC', name: 'acquirerSWC'
  },
  {
    title: 'User Payment', key: 'amount', name: 'amount', render: v => toIDR(v, false)
  },
  {
    title: 'MDR PCT', key: 'mdr', name: 'mdr'
  },
  {
    title: 'MDR Amount', key: 'mdrAmount', name: 'mdrAmount', render: v => toIDR(v, false)
  },
  {
    title: 'Revenue', key: 'revenue', name: 'revenue', render: v => toIDR(v, false)
  },
  {
    title: 'Merchant Receive', key: 'merchantReceive', name: 'merchantReceive', render: v => toIDR(v, false)
  },
  {
    title: 'switcher', key: 'switcher', name: 'switcher', render: v => switcherMerchantType.getStr(v)
  },
  {
    title: 'Status', key: 'OprSts', name: 'OprSts', render: v => AllStatus.getStrIdn(v.current)
  },
  {
    title: 'Status Progress', key: 'statusProgress', name: 'status', render: v => {
      return v.map((res, i) =>
        <div key={i}>
          {`${AllStatus.getStr(res.status)} (${format(new Date(res.createdAt), 'yyyy-MM-dd HH:mm')})`}
          {(v.length - 1) !== i ? (<span className="font-weight-bold">{' > '}</span>) : ''}
        </div>
      )
    }
  }
];
