import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const companyApi = {
  list: (page, limit, sort) => {
    let url = `/company/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) => agent.requests.get(`/company/${id}/detail`),
  create: (data) => agent.requests.post('/company/create', data),
  update: (id, data) => agent.requests.post(`/company/${id}/update`, data),
  addUser: (id, data) => agent.requests.post(`/company/${id}/add-user`, { staff: data }),
  removeUser: (id, data) => agent.requests.post(`/company/${id}/remove-user`, { staff: data })
}
