import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { toIDR } from '../../../../../utils/helpers/currency'
import getPropByString from '../../../../../utils/helpers/getPropByString'

const RowRender = ({
  withIndex,
  columns,
  index,
  row
}) => {
  return (
    <TableRow key={index}>
      {withIndex && <TableCell>{index + 1}</TableCell>}
      {
        columns.map((col, i) => {
          return !col.hide ?
            typeof col.render === "function" ?
              <TableCell
                align={col.align}
                key={i}
                className={
                  col.render(getPropByString(row, col.key), row) ?
                    col.withCurrency && !col.variant ? "text-nowrap" :
                      col.variant === "debit" ?
                        "text-success font-weight-bold text-nowrap" :
                        col.variant === "credit" ?
                          "text-danger font-weight-bold text-nowrap" :
                          ''
                    : ''
                }
              >
                {col.render(getPropByString(row, col.key), row) ||
                  Number(col.render(getPropByString(row, col.key), row)) === 0
                  ?
                  col.withCurrency ?
                    col.variant === "credit" ?
                      `-${toIDR(col.render(getPropByString(row, col.key), row))}`
                      :
                      col.variant === "debit" ?
                        `+${toIDR(col.render(getPropByString(row, col.key), row))}`
                        :
                        toIDR(col.render(getPropByString(row, col.key), row))
                    :
                    col.render(getPropByString(row, col.key), row)
                  :
                  col.withCurrency ? toIDR(0) : '-'
                }
              </TableCell>
              :
              <TableCell
                align={col.align}
                key={i}
                className={
                  getPropByString(row, col.key) ?
                    col.withCurrency && !col.variant ? "text-nowrap" :
                      col.variant === "debit" ?
                        "text-success font-weight-bold text-nowrap" :
                        col.variant === "credit" ?
                          "text-danger font-weight-bold text-nowrap"
                          : ''
                    : ''
                }>
                {getPropByString(row, col.key) ||
                  Number(getPropByString(row, col.key)) === 0
                  ?
                  col.withCurrency ?
                    col.variant === "credit" ?
                      `-${toIDR(getPropByString(row, col.key))}`
                      :
                      col.variant === "debit" ?
                        `+${toIDR(getPropByString(row, col.key))}`
                        :
                        toIDR(getPropByString(row, col.key))
                    :
                    getPropByString(row, col.key)
                  :
                  col.withCurrency ? toIDR(0) : '-'
                }
              </TableCell>
            : null
        })
      }
    </TableRow>
  )
}

export default RowRender
