import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, PROMO_CONFIG_MODULE, CREATE_TAB, FILTER_TABLE } from '../../../utils/constants/actionTypes';
import { format } from 'date-fns';
import Table from '../../../components/Table/Table';
import AllStatus from '../../../utils/constants/enums/status';
import SelectField from '../../../components/Forms/SelectField';
import { promoConfigApi } from '../../../services/promoConfigApi';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total,
    filter
  } = useSelector(state => state.promoConfig);

  const load = useCallback(
    () => {
      dispatch({
        module: PROMO_CONFIG_MODULE,
        type: MAIN_TAB_LOAD,
        call: promoConfigApi.list,
        args: [
          currentPage,
          limit,
          sort,
          filter.productCategory,
          filter.type,
          filter.status
        ]
      });
    },
    [currentPage, limit, sort, dispatch, filter]
  )

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: PROMO_CONFIG_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const createTab = useCallback((key, title, tabType) => {
    dispatch({
      module: PROMO_CONFIG_MODULE,
      type: CREATE_TAB,
      key,
      title,
      tabType
    });
  }, [dispatch])

  const table = useMemo(() => {
    return {
      inProgress: inProgress,
      data: items,
      sort: sort,
      limit: limit,
      total: total,
      currentPage: currentPage,
      columns: [
        { title: 'From', key: 'periodFrom', name: 'periodFrom', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss'), },
        { title: 'To', key: 'periodTo', name: 'periodTo', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
        {
          title: 'Product', key: 'product', name: 'product', sortable: false, render: v => (
            <div>
              <div className="text-primary">{`${v.category} - ${v.subCategory}`}</div>
              <div className="text-muted">{v.type}</div>
            </div>
          )
        },
        { title: 'Method', key: 'method', name: 'method' },
        { title: 'Type', key: 'type', name: 'type' },
        { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) },
        {
          title: 'Action', key: '_id', name: 'action', className: 'text-center', sortable: false, render: (v, row) => {
            return (
              <div className="td-action">
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => {
                    createTab(v, `${row.product.category} - ${row.product.subCategory} (${row.uniqueId})`)
                  }}
                >
                  <i className="pe-7s-tools btn-icon-wrapper"> </i>
                </button>
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => createTab(v + 'detail', `Detail - ${row.uniqueId}`, 'DETAIL')}
                >
                  <i className="lnr-eye btn-icon-wrapper"></i>
                </button>
              </div>
            )
          }
        }
      ]
    }
  }, [currentPage, inProgress, items, limit, sort, total, createTab])

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
          <div className="form-row">
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Type"
                className="form-control"
                placeholder="No Filter"
                options={[
                  {
                    value: 'DISCOUNT',
                    label: 'Discount'
                  },
                  {
                    value: 'CASHBACK',
                    label: 'Cashback'
                  }
                ]}
                onChange={v => onFilter('type')(v?.value)}
                value={filter.type}
                componentProps={{
                  isClearable: true,
                }}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Status"
                className="form-control"
                placeholder="No Filter"
                options={[
                  {
                    value: AllStatus.INACTIVE,
                    label: AllStatus.getStr(AllStatus.INACTIVE)
                  },
                  {
                    value: AllStatus.ACTIVE,
                    label: AllStatus.getStr(AllStatus.ACTIVE)
                  }
                ]}
                onChange={v => onFilter('status')(v?.value)}
                value={filter.status}
                componentProps={{
                  isClearable: true,
                }}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Product Category"
                className="form-control"
                placeholder="No Filter"
                options={[
                  {
                    value: 'BANKTRANSFER',
                    label: 'Bank Transfer'
                  },
                  {
                    value: 'PPOB',
                    label: 'PPOB'
                  },
                  {
                    value: 'QRIS',
                    label: 'QRIS'
                  }
                ]}
                onChange={v => onFilter('productCategory')(v?.value)}
                value={filter.productCategory}
                componentProps={{
                  isClearable: true,
                }}
              />
            </div>
          </div>
        </Table>
      </div>
    </div >
  )
}

export default MainTab
