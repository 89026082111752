import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const paymentApi = {
  listQris: ({
    page,
    limit,
    sort,
    type,
    search,
    startDate,
    endDate,
    status,
    ppobType,
    biller,
    partner,
    switcher,
    issuer,
    acquirer,
    issuerSWC,
    acquirerSWC,
    oprstatus,
    qrisMode,
    qrisType,
    merchant,
    merchantPartner,
    merchantCriteria,
    mccCategory,
    connectedPartner
  }) => {
    let url = `/payments/list-qris?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (ppobType) {
      url += `&ppobType=${ppobType}`;
    }
    if (biller) {
      url += `&biller=${biller}`;
    }
    if (partner) {
      url += `&partner=${partner}`;
    }
    if (switcher) {
      url += `&switcher=${switcher}`;
    }
    if (issuer) {
      url += `&issuer=${issuer}`;
    }
    if (acquirer) {
      url += `&acquirer=${acquirer}`;
    }
    if (issuerSWC) {
      url += `&issuerSWC=${issuerSWC}`;
    }
    if (acquirerSWC) {
      url += `&acquirerSWC=${acquirerSWC}`;
    }
    if (oprstatus) {
      url += `&oprstatus=${oprstatus}`;
    }
    if (qrisMode) {
      url += `&qrisMode=${qrisMode}`;
    }
    if (qrisType) {
      url += `&qrisType=${qrisType}`;
    }
    if (merchant) {
      url += `&merchant=${merchant}`;
    }
    if (merchantPartner) {
      url += `&merchantPartner=${merchantPartner}`;
    }
    if (merchantCriteria) {
      url += `&merchantCriteria=${merchantCriteria}`;
    }
    if (mccCategory) {
      url += `&mccCategory=${mccCategory}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${connectedPartner}`;
    }
    return agent.requests.get(url)
  },
  listQrisCPM: ({
    page,
    limit,
    sort,
    type,
    search,
    startDate,
    endDate,
    status,
    ppobType,
    biller,
    partner,
    switcher,
    issuer,
    acquirer,
    issuerSWC,
    acquirerSWC,
    oprstatus,
    qrisMode,
    merchant,
    merchantPartner,
    merchantCriteria,
    mccCategory
  }) => {
    let url = `/payments/list-qris-cpm?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (ppobType) {
      url += `&ppobType=${ppobType}`;
    }
    if (biller) {
      url += `&biller=${biller}`;
    }
    if (partner) {
      url += `&partner=${partner}`;
    }
    if (switcher) {
      url += `&switcher=${switcher}`;
    }
    if (issuer) {
      url += `&issuer=${issuer}`;
    }
    if (acquirer) {
      url += `&acquirer=${acquirer}`;
    }
    if (issuerSWC) {
      url += `&issuerSWC=${issuerSWC}`;
    }
    if (acquirerSWC) {
      url += `&acquirerSWC=${acquirerSWC}`;
    }
    if (qrisMode) {
      url += `&qrisMode=${qrisMode}`;
    }
    if (oprstatus) {
      url += `&oprstatus=${oprstatus}`;
    }
    if (merchant) {
      url += `&merchant=${merchant}`;
    }
    if (merchantPartner) {
      url += `&merchantPartner=${merchantPartner}`;
    }
    if (merchantCriteria) {
      url += `&merchantCriteria=${merchantCriteria}`;
    }
    if (mccCategory) {
      url += `&mccCategory=${mccCategory}`;
    }
    return agent.requests.get(url)
  },
  list: ({
    page,
    limit,
    sort,
    type,
    search,
    startDate,
    endDate,
    status,
    ppobType,
    biller,
    partner,
    switcher,
    issuer,
    acquirer,
    issuerSWC,
    acquirerSWC,
    oprstatus,
    mccgrp
  }) => {
    let url = `/payments/list2?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (ppobType) {
      url += `&ppobType=${ppobType}`;
    }
    if (biller) {
      url += `&biller=${biller}`;
    }
    if (partner) {
      url += `&partner=${partner}`;
    }
    if (switcher) {
      url += `&switcher=${switcher}`;
    }
    if (issuer) {
      url += `&issuer=${issuer}`;
    }
    if (acquirer) {
      url += `&acquirer=${acquirer}`;
    }
    if (issuerSWC) {
      url += `&issuerSWC=${issuerSWC}`;
    }
    if (acquirerSWC) {
      url += `&acquirerSWC=${acquirerSWC}`;
    }
    if (oprstatus) {
      url += `&oprstatus=${oprstatus}`;
    }
    if (mccgrp) {
      url += `&mccgrp=${mccgrp}`;
    }
    return agent.requests.get(url)
  },
  listNns: () => {
    return agent.requests.get('/payments/list-nns')
  },
  listNnsSWC: () => {
    return agent.requests.get('/payments/list-nns-swc')
  },
  listMostPurchased: ({ sort, fromDate, toDate }) => {
    let url = `/payments/most-purchased-ppob?`;
    if (sort) {
      url += `sort=${sort}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  listMostPopularMerchant: ({ sort, fromDate, toDate, connectedPartner }) => {
    let url = `/merchants/popular?`;
    if (sort) {
      url += `sort=${sort}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${encodeURIComponent(connectedPartner)}`;
    }
    return agent.requests.get(url)
  },
  listMostSpend: ({ type, sort, fromDate, toDate, transactionType, connectedPartner }) => {
    let url = `/transactions/most-spend-leaderboard?`;
    if (type) {
      url += `type=${type}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    if (transactionType) {
      url += `&transactionType=${encodeURIComponent(transactionType)}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${encodeURIComponent(connectedPartner)}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) => {
    return agent.requests.get(`/payments/${id}/detail`)
  },
  getGraphData: ({ fromDate, toDate }) => (
    agent.requests.get(`/payments/count?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`)
  ),
  totalTransactions: ({ fromDate, toDate, reload, type }) => {
    let url = `/transactions/total-transactions?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    if (reload) {
      url += `&reload=${reload}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    return agent.requests.get(url)
  },
  updateStatus: ({ id, data }) => {
    let url = `/payments/${id}/change-status`;

    return agent.requests.post(url, data)
  }
}
