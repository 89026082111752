import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { CREATE, PPOB_MODULE } from '../../../../utils/constants/actionTypes';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';
import ProductList from './ProductList';
import Form from './Form';
import AllStatus from '../../../../utils/constants/enums/status';
import { ppobApi } from '../../../../services/ppobApi';

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(PpobType.PHONE_CREDIT);

  const submit = useCallback((values) => {
    const data = {
      ...values,
      referralBonus: 0,
      aggregator: values.aggregator.map((el, i) => (
        {
          ...el,
          periority: i + 1,
          revenue: el.custom ? +el.revenue : (+values.sellPrice - +el.price),
          price: el.custom ? 0 : +el.price,
          type: el.biller === 'INTERNAL' ? 'INTERNAL' : 'EXTERNAL'
        }
      )),
      active: values.active === AllStatus.INACTIVE ? '' : values.active
    }
    if (values.image[0]) data.image = values.image[0]
    else delete data.image

    setIsLoading(true);
    dispatch({
      module: PPOB_MODULE,
      type: CREATE,
      call: ppobApi.create,
      args: [data]
    }).finally(() => setIsLoading(false))
  }, [dispatch])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <ProductList type={selectedType} />
        <Form
          submit={submit}
          initialValue={{
            type: PpobType.PHONE_CREDIT,
            active: AllStatus.ACTIVE,
            data: {},
          }}
          setSelectedType={setSelectedType}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default CreateTab
