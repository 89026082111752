import objectPath from 'object-path';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { announcementApi } from '../../../services/announcementApi';
import { UPDATE, USER_ANNOUNCEMENT_APP_MODULE } from '../../../utils/constants/actionTypes';
import useMountedState from '../../../components/HooksUse/useMountedState';
import Form from './Form';

const UpdateTab = ({ index, reducerPath, filterOptions, module }) => {
  const dispatch = useDispatch()
  const { item, key } = useSelector(state => objectPath.get(state, reducerPath).tabs[index])
  const [loading, setLoading] = useState(false)
  const isMounted = useMountedState()

  const onSubmit = (values) => {
    const data = {
      ...values,
      isActive: values.isActive === "true" ? true : false,
      isCloseable: values.isCloseable === "true" ? true : false,
      application: module === USER_ANNOUNCEMENT_APP_MODULE ? "USER" : "BUSINESS",
    }
    setLoading(true)
    dispatch({
      module: module,
      type: UPDATE,
      call: announcementApi.update,
      args: [key, data],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setLoading(false) });
  }

  return item ?
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={loading}
          initialValues={item}
          previousImageUrl={item?.attachment?.url}
          announcementId={key}
          filterOptions={filterOptions}
        >
        </Form>
      </div>
    </div>
    :
    <div className="card">
      <div className="card-body">
        <h5 className="text-center mb-0">Not Found</h5>
      </div>
    </div>
}

export default UpdateTab
