import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import AlertDialog from '../Dialog/AlertDialog'
import styles from './buttonDropdown.module.scss'

const ButtonDropdown = ({
  title,
  color = 'primary',
  menu = [],
  alignRight
}) => {
  const [show, setShow] = useState(false)

  const menuRef = useRef()

  useEffect(() => {
    const closeOnClickOutside = (e) => {
      const div = menuRef.current;
      if (div && e && !div.contains(e.target)) setShow(false)
    }
    if (show) window.addEventListener('click', closeOnClickOutside);

    return () => {
      if (show) window.removeEventListener('click', closeOnClickOutside);
    };
  }, [show])

  return (
    <div className={styles.buttonDropdownRoot}>
      <button onClick={() => setShow(prev => !prev)} className={`${styles.buttonToggle} btn btn-${color}`}>
        {title}
      </button>
      {
        show &&
        <div ref={menuRef} className={`${styles.dropdownMenuContainer}${alignRight ? ' ' + styles.alignRight : ''}`}>
          <ul className={styles.dropdownMenu}>
            {
              menu.map((el, i) => {
                let Tag = el.href ? Link : "button";
                return (
                  <li key={i} className={styles.menuList}>
                    {
                      el.withAlert ?
                        <AlertDialog
                          buttonComponent={<Tag className={`btn btn-block ${styles.button}`}>{el.title}</Tag>}
                          onAgree={el.onClick}
                          title={el.withAlert.title ?? "Are you sure?"}
                          description={el.withAlert.description ?? "Tindakan ini tidak dapat dibatalkan"}
                        />
                        :
                        <Tag className={`btn btn-block ${styles.button}`} to={el.href} onClick={el.onClick}>{el.title}</Tag>
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
      }
    </div>
  )
}

export default ButtonDropdown
