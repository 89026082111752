class EventType {
  static NEW_KYC = 'NEW_KYC';
  static NEW_INJECTION = 'NEW_INJECTION';
  static NEW_DEDUCTION = 'NEW_DEDUCTION';
  static NEW_UPDATE_REQUEST = 'NEW_UPDATE_REQUEST';
  static NEW_DONATION = 'NEW_DONATION';
  static NEW_DONATION_UPDATE = 'NEW_DONATION_UPDATE';
  static NEW_DONATION_NEWS = 'NEW_DONATION_NEWS';
  static NEW_DONATION_NEWS_UPDATE = 'NEW_DONATION_NEWS_UPDATE';
  static NEW_MERCHANT = 'NEW_MERCHANT';

  static COMPLETED_BULK_SETTLEMENT_MERCHANT = 'COMPLETED_BULK_SETTLEMENT_MERCHANT';

  static getMsg(type) {
    switch (type) {
      case EventType.NEW_KYC:
        return 'You have New KYC data to do';
      case EventType.NEW_INJECTION:
        return 'You have New Injection data to do';
      case EventType.NEW_DEDUCTION:
        return 'You have New Deduction data to do';
      case EventType.NEW_UPDATE_REQUEST:
        return 'You have New Update Request to do';
      case EventType.NEW_DONATION:
        return 'You have New Donation to do';
      case EventType.NEW_DONATION_UPDATE:
        return 'You have New Update Donation Request to do';
      case EventType.NEW_DONATION_NEWS:
        return 'You have New Donation News to do';
      case EventType.NEW_DONATION_NEWS_UPDATE:
        return 'You have New Update Donation News Request to do';
      case EventType.NEW_MERCHANT:
        return 'You have New Merchant to do';
      case EventType.COMPLETED_BULK_SETTLEMENT_MERCHANT:
        return 'Approve settlement bulk completed';
      default:
        return 'Unknown';
    }
  }
}

export { EventType };
