import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SelectField from '../Forms/SelectField';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useMountedState from '../HooksUse/useMountedState';
import { ppobApi } from '../../services/ppobApi';

const staticValues = {
  sort: 'title|asc',
  limit: 10,
}

const PPOBSelect = ({
  label,
  disabled,
  error,
  helperText,
  onChange,
  value,
  customOptions = [],
  ppobType
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [options, setOptions] = useState([])
  const [customOpts,] = useState(customOptions)
  const [search, setSearch] = useState('')
  const [totalItems, setTotalItems] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const { sort, limit } = staticValues;
  const isMounted = useMountedState();
  const [initialValue] = useState(value)

  const getPPOBListDebounced = useMemo(
    () => AwesomeDebouncePromise(ppobApi.list, 500),
    [],
  )

  const dispatchLoad = useCallback(
    (call, page, limit, sort, search) => {
      return dispatch({
        type: null,
        call: call,
        args: [
          page,
          limit,
          search ? null : sort,
          ppobType,
        ]
      })
    },
    [dispatch, ppobType],
  )

  const generateOpts = ppobs => ppobs.map(ppob => ({
    value: ppob._id,
    label: ppob.title,
    optData: ppob
  }))

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);
      dispatchLoad(ppobApi.list, newPage, limit, sort, search)
        .then(({ data: { ppobs, count } }) => {
          if (isMounted()) {
            setOptions([...options, ...generateOpts(ppobs)])
            setTotalItems(count)
          }
        })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) });
    }
  }

  const handleSelectChange = opt => {
    setSelectedOption(opt)
    setSearch('')

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(getPPOBListDebounced, newPage, limit, sort, search)
        .then(({ data: { ppobs } = {} } = {}) => {
          if (isMounted() && ppobs) options = [...generateOpts(ppobs), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setOptions(options)
            setIsLoading(false)
          }
        });
    }
    loadData()
  }, [search, sort, limit, dispatchLoad, getPPOBListDebounced, customOpts, isMounted, initialValue])

  return (
    <SelectField
      id="selectPPOB"
      label={label ?? "Select PPOB"}
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder="Type anything.."
      componentProps={{
        inputValue: search,
        onInputChange: setSearch,
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom,
        isDisabled: disabled ? disabled : isLoading
      }}
      error={error}
      helperText={helperText}
    />
  )
}

export default PPOBSelect
