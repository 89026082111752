import React from "react";
import getPropByString from "../../../utils/helpers/getPropByString";

export const ExcelTablePTEN = ({ data, columns } = {}) => (
  <>
    <p style={{ margin: 0 }}>FORM PENDAFTARAN</p>
    <p style={{ margin: 0 }}>NATIONAL MERCHANT REPOSITORY QRIS</p>
    <table border="1">
      <thead>
        <tr>
          <th rowSpan="2" style={{ fontWeight: "normal", width: "70px" }} >No.</th>
          <th colSpan={columns.length} style={{ backgroundColor: "#ffff00" }}>
            Mandatory
          </th>
        </tr>
        <tr>
          {columns.map((col, i) => {
            return (
              <td key={i} style={{ backgroundColor: "#ffff00" }}>
                {col.title}
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => {
          return (
            <tr key={i}>
              <td align="center">{i + 1}</td>
              {columns.map((col, key) => (
                <td align={col.align} key={key} style={{ width: col.width }}>
                  {typeof col.render === "function"
                    ? col.render(getPropByString(row, col.key), row) : getPropByString(row, col.key)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
    <p style={{ margin: 0 }}>end</p>
    <p style={{ margin: 0 }}>notes :</p>
    <h5 style={{ margin: 0 }}>* NMID Eksisting yang tertera di Merchant (isi jika ada di Merchant)</h5>
    <h5 style={{ margin: 0 }}>* Nama Merchant maksimum 50 digit (Tercetak pada Image Label QRIS)</h5>
    <h5 style={{ margin: 0 }}>* Nama Merchant maksimum 25 digit (Sesuai spesifikasi QRIS yang akan dibaca oleh apps)</h5>
    <h5 style={{ margin: 0 }}>* MPAN include Cek Digit</h5>
    <h5 style={{ margin: 0 }}>* MID maksimum 15 digit (sesuai spesifikasi QRIS)</h5>
    <h5 style={{ margin: 0 }}>* Kota maksimum 15 digit dan sesuai dengan referensi kodepos</h5>
    <h5 style={{ margin: 0 }}>* Kodepos harus 5 digit dan sesuai dengan referensi kodepos</h5>
    <h5 style={{ margin: 0 }}>* Tipe Merchant diisi angka 1 atau 2 (1 untuk Individu , 2 untuk Badan Usaha)</h5>
    <h5 style={{ margin: 0 }}>* Untuk Tipe Merchant Badan Usaha wajib mengisi NPWP (15 digit)</h5>
    <h5 style={{ margin: 0 }}>* Untuk Tipe Merchant Perorangan wajib mengisi KTP (16 digit)</h5>
    <h5 style={{ margin: 0 }}>* Tipe QR diisi huruf S, D, atau B --&gt; S untuk Statik, D untuk Dinamik, B untuk Keduanya (Stasik dan Dinamik)</h5>
  </>
);
