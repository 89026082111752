import { paginate } from "../utils/helpers/paginate";
import agent from "./agent"

export const bankTransferFeeApi = {
  list: (page, limit, sort) => {
    let url = `/bank-transfer-configs/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => {
    return agent.requests.post('/bank-transfer-configs/create', data);
  },
  update: (id, data) => {
    return agent.requests.post(`/bank-transfer-configs/${id}/update`, data);
  },
  detail: (id) =>
    agent.requests.get(`/bank-transfer-configs/${id}/detail`),
}
