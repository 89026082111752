import React from 'react'
import BusinessList from '../BusinessList'
import { BUSINESS_ADMIN_PTEN_VERIFY_MODULE } from '../../../utils/constants/actionTypes';

const Index = () => {
  return (
    <BusinessList
      reducerPath="businesses.businessVerify"
      module={BUSINESS_ADMIN_PTEN_VERIFY_MODULE}
      title="Business PTEN Verify Control"
      subTitle="Full Business PTEN Verify Control"
    />
  )
}

export default Index
