import React from 'react';
import { ReactComponent as UserLogo } from '../assets/icons/user-profile.svg';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT } from '../utils/constants/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { combineBy } from '../utils/helpers/combineBy';
import { authApi } from '../services/authApi';
import { AdminRole } from '../utils/constants/enums/adminRoles';

const Header = () => {
  const dispatch = useDispatch();

  const { currentAdmin } = useSelector(state => state.common);
  const onClickLogout = type => key => dispatch({ type: type, call: authApi.logout })

  const toggleSidebar = e => {
    var btn = e.currentTarget;
    var dataClass = btn.dataset.class;
    var appContainer = document.querySelector('.app-container');

    appContainer.classList.toggle(dataClass);
    btn.classList.toggle('is-active');
  }

  const toggleHeaderNav = e => {
    var btn = e.currentTarget;
    var dataClass = btn.dataset.class;
    var appContainer = document.querySelector('.app-header__content');

    appContainer.classList.toggle(dataClass);
    btn.classList.toggle('active');
  }

  return (
    <div className="app-header header-shadow">
      <div className="app-header__logo">
        <Link to="/" className="logo-src" />
        <div className="header__pane ml-auto">
          <div>
            <button onClick={toggleSidebar} type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button onClick={toggleSidebar} type="button" className="hamburger hamburger--elastic mobile-toggle-nav" data-class="sidebar-mobile-open">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button onClick={toggleHeaderNav} type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" data-class="header-mobile-open">
            <span className="btn-icon-wrapper">
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </button>
        </span>
      </div>
      <div className="app-header__content">
        <div className="flex-grow-1 flex-lg-grow-0 ml-lg-auto">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UserLogo width="42px" className="rounded-circle opacity-6" />
              </div>
              <div className="widget-content-left ml-3">
                <div className="widget-heading">{combineBy([currentAdmin.firstName, currentAdmin.lastName])}</div>
                <div className="widget-subheading">Dipay - {AdminRole.getStr(currentAdmin.role)}</div>
              </div>
              <div className="widget-content-right ml-lg-3">
                <button onClick={onClickLogout(LOGOUT)} type="button" className="btn-shadow btn btn-primary btn-sm">
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
