import React from 'react'
import styles from './card.module.scss'

const Card = ({ number, imageUrl, title }) => {

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.number}>{number}</span>
          <div className={styles.icon}>
            <img src='/drag_indicator.png' alt='drag-indicator' />
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.content}>
          <div className={styles.image}>
            <img src={imageUrl} alt={title} />
          </div>
          <div className={styles.text}>
            <p>{title}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
