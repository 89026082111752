import Cropper from "react-easy-crop";
import PopUp from ".";
import LoadingBtn from "../Forms/LoadingBtn";
import styles from './popUp.module.scss'

const PopupCropperKYC = ({
  showCropImage,
  setShowCropImage,
  croppedImage,
  croppedPreview,
  cropperProps,
  isLoading,
  onVerify,
  setCroppedImage,
  showCroppedImage,
  title,
  info
}) => {
  const {
    image,
    crop,
    setCrop,
    zoom,
    setZoom,
    rotation,
    setRotation,
    onCropComplete,
    aspect,
    ...rest
  } = cropperProps;

  return (
    <PopUp in={showCropImage} onClose={() => setShowCropImage(false)}>
      <div className="main-card card">
        <div className="card-body">
          <h5 className="card-title mb-4">{title}</h5>
          <div className={Boolean(croppedImage) ? styles.cropPreview : styles.cropper}>
            {
              Boolean(croppedImage)
                ?
                <img
                  src={croppedPreview}
                  className={styles.preview}
                  alt=""
                />
                :
                <>
                  <div className={styles.cropContainer}>
                    <Cropper
                      image={image}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspect}
                      minZoom={1}
                      maxZoom={1.5}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      rotation={rotation}
                      onRotationChange={setRotation}
                      onZoomChange={setZoom}
                      objectFit="vertical-cover"
                      {...rest}
                    />
                  </div>
                  <div className={styles.controls}>
                    <div>
                      <p className="m-0">Zoom</p>
                      <input
                        type="range"
                        value={zoom}
                        min={1}
                        max={1.5}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => setZoom(e.target.value)}
                        className={styles.range}
                      />
                    </div>
                    <div className="ml-4">
                      <p className="m-0">Rotation</p>
                      <input
                        type="range"
                        value={rotation}
                        min={0}
                        max={360}
                        step={0.5}
                        aria-labelledby="Zoom"
                        onChange={(e) => setRotation(e.target.value)}
                        className={styles.range}
                      />
                    </div>
                  </div>
                </>
            }
          </div>
          <br />
          <br />
          {info}
          <hr />
          {
            Boolean(croppedImage)
              ?
              <>
                <LoadingBtn
                  type="button"
                  loading={isLoading}
                  onClick={onVerify}
                  className="d-block btn btn-primary w-100 mb-2"
                >
                  Verify
                </LoadingBtn>
                <button
                  onClick={() => setCroppedImage(null)}
                  className="d-block btn btn-outline-primary w-100 mb-2"
                >
                  Cancel
                </button>
              </>
              :
              <LoadingBtn
                type="button"
                loading={isLoading}
                onClick={showCroppedImage}
                className="d-block btn btn-primary w-100 mb-2"
              >
                Preview
              </LoadingBtn>
          }
          <button
            onClick={() => {
              setCroppedImage(null)
              setShowCropImage(false)
            }}
            className="d-block btn btn-outline-primary w-100"
          >
            Close
          </button>
        </div>
      </div>
    </PopUp>
  )
}

export default PopupCropperKYC;
