import React from 'react';
import { Tabs, Tab } from '../../../components/Tabs/Tabs';
import { useSelector, useDispatch } from 'react-redux';
import MainTab from './MainTab';
import { CHANGE_TAB, CLOSE_TAB, CONFIG_MODULE } from '../../../utils/constants/actionTypes';
import { Helmet } from 'react-helmet';
import CreateTab from './CreateTab';
import UpdateTab from './UpdateTab';

const Index = () => {
  const { activeTabKey, tabs } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const handleTab = type => key => dispatch({ module: CONFIG_MODULE, type: type, key: key })

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>System Configuration</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-config icon-gradient bg-sunny-morning"></i>
            </div>
            <div>
              Configurations
              <div className="page-title-subheading">Dipay system configurations, make sure you know how this feature works before do any changes.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
        <Tab title="Create">
          <CreateTab />
        </Tab>
        {tabs.map((row, i) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => handleTab(CLOSE_TAB)(row.key)}
            onClick={() => handleTab(CHANGE_TAB)(row.key)}
            active={activeTabKey === row.key}
          >
            <UpdateTab index={i} />
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Index;
