import { useState, useEffect, useMemo, useCallback } from "react";

export const useAudio = url => {
  const [audio] = useState(useMemo(() => new Audio(url), [url]));
  const [playing, setPlaying] = useState(false);

  const toggle = useCallback(
    () => {
      if (!audio) return;
      const play = () => {
        if (!audio) return;
        setPlaying(true);
        audio.play()
          .catch(error => {
            setPlaying(false);
            console.log('Audio play failed because you didn\'t interact with the document first. e.g. Click once anywhere on the document')
          });
      };

      const pause = () => {
        if (!audio) return;
        setPlaying(false);
        audio.pause();
      };
      if (audio.paused) {
        play();
      } else {
        if (!audio.ended && audio.readyState > 2) {
          pause();
        }
      }
    },
    [audio],
  )

  useEffect(() => {
    if (!audio) return;
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};
