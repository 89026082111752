import React from 'react';
import { useSelector } from 'react-redux';
import EvyTextField from '../../components/Forms/EvyTextField'
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSms, faMobile } from '@fortawesome/free-solid-svg-icons';

const Detail = ({ index }) => {
  const { tabs, items } = useSelector(state => state.notification);
  const { key } = tabs[index];
  const selectedItem = items.find(row => row._id === key);

  return (
    selectedItem ? (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="main-card mb-3 card overflow-hidden">
            <div className="card-body p-0">
              <div className="grid-menu grid-menu-3col">
                <div className="no-gutters row">
                  <div className="col-4">
                    <button
                      className={`btn-icon-vertical btn-transition btn-square btn ${selectedItem.cases.email.enabled ? (selectedItem.cases.email.sentAt ? 'btn-outline-success' : 'btn-outline-danger') : 'btn-outline-link'}`}
                      disabled={!selectedItem.cases.email.enabled}
                    >
                      <i className="btn-icon-wrapper btn-icon-lg">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </i>
                      <span className="badge badge-primary badge-dot badge-dot-lg badge-dot-inside"></span>
                      Email
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      className={`btn-icon-vertical btn-transition btn-square btn ${selectedItem.cases.sms.enabled ? (selectedItem.cases.sms.sentAt ? 'btn-outline-succes' : 'btn-outline-danger') : 'btn-outline-link'}`}
                      disabled={!selectedItem.cases.sms.enabled}
                    >
                      <i className="btn-icon-wrapper btn-icon-lg">
                        <FontAwesomeIcon icon={faSms} />
                      </i>
                      <span className="badge badge-primary badge-dot badge-dot-lg badge-dot-inside"></span>
                      SMS
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      className={`btn-icon-vertical btn-transition btn-square btn ${selectedItem.cases.push.enabled ? (selectedItem.cases.push.sentAt ? 'btn-outline-success' : 'btn-outline-danger') : 'btn-outline-link'}`}
                      disabled={!selectedItem.cases.push.enabled}
                    >
                      <i className="btn-icon-wrapper btn-icon-lg">
                        <FontAwesomeIcon icon={faMobile} />
                      </i>
                      <span className="badge badge-primary badge-dot badge-dot-lg badge-dot-inside"></span>
                      Push Notif
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Notification</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Created Date"
                  value={format(new Date(selectedItem.createdAt), 'yyyy-MM-dd HH:mm:ss')}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Title"
                  value={selectedItem.title}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Body"
                  value={selectedItem.body}
                  multiline
                />
                <EvyTextField
                  readOnly
                  row
                  label="Type"
                  value={selectedItem.type}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Target</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Name"
                  value={selectedItem.user ? selectedItem.user.firstName + ' ' + selectedItem.user.lastName : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={selectedItem.user ? selectedItem.user.phoneNumber : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={selectedItem.user ? selectedItem.user.email : '-'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
        </div>
      </div>
  )
}

export default Detail;
