import React, { useMemo } from 'react';
import Resource from '../../components/Resource';
import { useSelector } from 'react-redux';
import UpdateTab from './UpdateTab';
import CreateTab from './CreateTab';
import { format } from 'date-fns';
import { SELECT_FIELD } from '../../utils/constants/inputTypes';
import Image from '../../components/Table/DataImage';
import { articleApi } from '../../services/articleApi';
import { ARTICLE_MODULE } from '../../utils/constants/actionTypes';
import { articleCategoryOpts, articleCategoryTypes } from '../../utils/constants/enums/articleCategoryOptsTypes';
import { productOpts, productOptsTypes } from '../../utils/constants/enums/productOptsTypes';
import activeStatusOpts from '../../utils/constants/enums/status/activeStatusOpts';
import Position from './Position';
import { UserAppOpts, UserAppTypes } from '../../utils/constants/enums/userAppTypes';

const ArticleList = () => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.articles);

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    },
    { title: 'Title', key: 'name', name: 'name' },
    { title: 'Application', key: 'application', name: 'application', render: v => productOptsTypes.getStr(v) },
    { title: 'Type', key: 'type', name: 'type', render: v => UserAppTypes.getStr(v) },
    { title: 'Category', key: 'category', name: 'category', render: v => articleCategoryTypes.getStr(v) },
    { title: 'Position', key: 'position', name: 'position' },
    {
      title: 'Status', key: 'active', name: 'active', render: v =>
        v ?
          // <HorizontalBadge
          //   className="mr-2"
          //   title='New User'
          //   variant="danger"
          //   active={user.status?.isNewUser}
          //   contentInActive={<div className='mr-3'></div>}
          //   content="NEW"
          // />
          'Yes'
          :
          // <HorizontalBadge
          //   className="mr-2"
          //   title='New User'
          //   variant="danger"
          //   active={user.status?.isNewUser}
          //   contentInActive={<div className='mr-3'></div>}
          //   content="NEW"
          // />
          'No'
    },
    {
      title: 'Cover', key: 'attachment', name: 'attachment', sortable: false, render: function (v, row) {
        return <Image src={v ? v.url : ''} />
      }
    }
  ]

  const apiQuery = useMemo(() => {
    const type = filter.type;
    const application = filter.application;
    const category = filter.category;
    const active = filter.active;

    return {
      page: currentPage,
      limit,
      sort,
      type,
      application,
      category,
      active
    }
  }, [currentPage, limit, sort, filter])

  return (
    <Resource
      title="Dipay Article"
      subTitle="Full Control of Dipay User App Articles includes Create, Delete & Updates."
      icon="pe-7s-news-paper"
      list={{
        columns: columns,
        reducerPath: 'articles',
        module: ARTICLE_MODULE,
        call: articleApi.list,
        apiQuery: apiQuery,
        deleteButton: {
          api: articleApi.delete
        },
        filters: [
          {
            label: "Application",
            type: SELECT_FIELD,
            key: "application",
            options: productOpts,
            value: filter.application
          },
          {
            label: "Type",
            type: SELECT_FIELD,
            key: "type",
            options: UserAppOpts,
            value: filter.type
          },
          {
            label: "Category",
            type: SELECT_FIELD,
            key: "category",
            options: articleCategoryOpts,
            value: filter.category
          },
          {
            label: "Status",
            type: SELECT_FIELD,
            key: "active",
            options: activeStatusOpts,
            value: filter.active
          }
        ]
      }}
      update={{
        component: UpdateTab,
        props: {
          reducerPath: 'articles',
          module: ARTICLE_MODULE
        }
      }}
      create={{
        component: CreateTab
      }}
      additionalTab={{
        component: Position,
        title: 'Position',
        props: {
          type: "merchant"
        },
        call: articleApi.list,
        filters: [
          {
            label: "Application",
            type: SELECT_FIELD,
            key: "application",
            options: productOpts,
            value: filter.application
          },
          {
            label: "Type",
            type: SELECT_FIELD,
            key: "type",
            options: UserAppOpts,
            value: filter.type
          },
          {
            label: "Category",
            type: SELECT_FIELD,
            key: "category",
            options: articleCategoryOpts,
            value: filter.category
          }
        ]
      }}
    />
  );
}

export default ArticleList;
