class merchantCriteriaType {
  static UMI = 'UMI';
  static UME = 'UME';
  static UKE = 'UKE';
  static URE = 'URE';
  static UBE = 'UBE';
  static PSO = 'PSO';
  static BLU = "BLU";

  static getStr(en) {
    switch (en) {
      case merchantCriteriaType.UMI:
        return '(UMI) - Usaha Mikro';
      case merchantCriteriaType.UME:
        return '(UME) - Usaha Menengah';
      case merchantCriteriaType.UKE:
        return '(UKE) - Usaha Kecil';
      case merchantCriteriaType.URE:
        return '(URE) - Usaha Reguler';
      case merchantCriteriaType.UBE:
        return '(UBE) - Usaha Besar';
      case merchantCriteriaType.PSO:
        return '(PSO) - Public Service Obligation';
      case merchantCriteriaType.BLU:
        return '(BLU) - Badan Layanan Umum';
      default:
        return 'Unknown';
    }
  }
}

const merchantCriteriaTypeOpts = [
  { value: merchantCriteriaType.UMI, label: merchantCriteriaType.getStr(merchantCriteriaType.UMI) },
  { value: merchantCriteriaType.UME, label: merchantCriteriaType.getStr(merchantCriteriaType.UME) },
  { value: merchantCriteriaType.URE, label: merchantCriteriaType.getStr(merchantCriteriaType.URE) },
  { value: merchantCriteriaType.UKE, label: merchantCriteriaType.getStr(merchantCriteriaType.UKE) },
  { value: merchantCriteriaType.UBE, label: merchantCriteriaType.getStr(merchantCriteriaType.UBE) },
  { value: merchantCriteriaType.PSO, label: merchantCriteriaType.getStr(merchantCriteriaType.PSO) },
  { value: merchantCriteriaType.BLU, label: merchantCriteriaType.getStr(merchantCriteriaType.BLU) },
];

export { merchantCriteriaType, merchantCriteriaTypeOpts };
