import React, { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import Resource from "../../../../components/Resource";
import * as yup from 'yup';
import { MAIN_TAB_LOAD, TRANSFER_BANK_MODULE } from "../../../../utils/constants/actionTypes";
import { combineBy } from "../../../../utils/helpers/combineBy";
import { DATE_PICKER, PARTNER_SELECT, SELECT_FIELD, TEXT_FIELD, USER_SELECT } from "../../../../utils/constants/inputTypes";
import { toIDR } from "../../../../utils/helpers/currency";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import DetailTab from "./DetailTab";
import { bankApi } from "../../../../services/bankApi";
import useAsync from "../../../../components/HooksUse/useAsync";
import { withdrawApi } from "../../../../services/withdrawApi";
import { TransactionType } from '../../../../utils/constants/enums/transactionTypes';
import { BankNameTypeOpts } from "../../../../utils/constants/enums/bankNameTypes";
import { partnerTypes } from "../../../../utils/constants/enums/partnerTypes";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectField from "../../../../components/Forms/SelectField";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Index = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalAmount,
    totalFee
  } = useSelector(state => state.transfer.transferBank);

  const {
    value: { data: { banks = [] } = {} } = {},
    pending: isGettingBank
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: bankApi.list, args: [0, 0] }),
    [dispatch]), true)
  const bankOpts = banks.map(row => ({ value: row.artajasaCode, label: row.name }))

  const getWithdrawDebounced = useMemo(
    () => AwesomeDebouncePromise(withdrawApi.list, 500),
    [],
  )

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.user && typeof filter.user === 'object' ? `applicantModel|User,applicant|${filter.user.value}` : false,
      filter.transactionCodeNumber ? `code|${filter.transactionCodeNumber},metadata.transfer.request.TransactionInfo.RefNumber|${filter.transactionCodeNumber}` : false
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      type: [TransactionType.CASHOUT, TransactionType.BANK_TRANSFER],
      search,
      startDate,
      endDate,
      partner: filter.partner,
      status: filter.status,
      beneficiary: filter.beneficiary,
      bankType: filter.bankType
    }
  }, [filter, currentPage, limit, sort])

  const load = useCallback(() => {
    dispatch({
      module: TRANSFER_BANK_MODULE,
      type: MAIN_TAB_LOAD,
      call: getWithdrawDebounced,
      args: [apiQuery],
    });
  }, [apiQuery, dispatch, getWithdrawDebounced]);

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    { title: 'Trans. Code', key: 'code', name: 'code', sortable: false },
    { title: 'Partner', key: 'partner', name: 'partner', sortable: false, render: v => v ? partnerTypes.getStr(v) : "-" },
    {
      title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}`
    },
    { title: 'Biller', key: 'bankType', name: 'bankType', sortable: false, render: v => v ?? 'ARTAJASA' },
    {
      title: 'Beneficiary', key: 'bankTarget', name: 'bankTarget', sortable: false, render: ({ bankName, accountName, accountNumber } = {}) => (
        <div>
          <div className="text-primary">{bankName}</div>
          <div className="text-muted">{accountName}</div>
          <div className="text-muted">{accountNumber}</div>
        </div>
      )
    },
    { title: 'Trans. Number', key: 'metadata.transfer.request.TransactionInfo.RefNumber', name: 'number', sortable: false },
    { title: 'Total Transfer', key: 'amount', name: 'amount', render: v => toIDR(v) },
    { title: 'Total Fee', key: 'fee', name: 'fee', render: v => toIDR(v) },
    { title: 'Total Amount', key: 'total', name: 'total', render: (v, row) => toIDR(row.amount + row.fee) },
    { title: 'Status', key: 'status', name: 'status' },
    {
      title: 'Ubah Status', key: 'status', name: 'status', className: 'text-center', sortable: false, render: (status, row) => (
        <div className="td-action">
          {status === 'PROCESSING' || status === 'REQUEST' ?
            <ChangeStatusDialog
              _id={row._id}
              load={load}
              getWithdrawDebounced={getWithdrawDebounced}
              buttonComponent={
                <button
                  className="btn px-2 btn-primary inline-block w-100"
                  style={{ fontSize: '11.5px' }}
                >
                  Ubah status
                </button>
              }
              opts={[
                { value: 'COMPLETED', label: 'Berhasil' },
                { value: 'FAILED', label: 'Gagal' },
                { value: 'FAILED_WITH_REFUND', label: 'Gagal dengan Refund' },
              ]}
              title="Ubah Status Transfer Bank"
              description="Apakah Anda yakin mengubah status transaksi? Pastikan status yang diubah sesuai dengan data yang diterima dari pengguna."
            />
            :
            <span>-</span>
          }
        </div>
      )
    },
  ];

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Trans. Code', key: 'code' },
    { title: 'Partner', key: 'partner', name: 'partner', sortable: false, render: v => v ? partnerTypes.getStr(v) : "-" },
    { title: 'Applicant', key: 'applicant', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Biller', key: 'bankType', render: v => v ?? 'ARTAJASA' },
    { title: 'Beneficiary', key: 'bankTarget', render: ({ bankName, accountName, accountNumber } = {}) => `${bankName} | ${accountName} | ${accountNumber}` },
    { title: 'Trans. Number', key: 'metadata.transfer.request.TransactionInfo.RefNumber' },
    { title: 'Total Transfer', key: 'amount', name: 'amount', render: v => toIDR(v, false) },
    { title: 'Total Fee', key: 'fee', name: 'fee', render: v => toIDR(v, false) },
    { title: 'Total Amount', key: 'total', name: 'total', render: (v, row) => toIDR(row.amount + row.fee, false) },
    { title: 'Status', key: 'status' }
  ], [])

  return (
    <div>
      <Resource
        title="Transfer Bank"
        subTitle="Full Transfer Bank list."
        icon="pe-7s-ribbon"
        list={{
          columns: columns,
          reducerPath: "transfer.transferBank",
          call: getWithdrawDebounced,
          apiQuery: apiQuery,
          module: TRANSFER_BANK_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Transfer: <b>{toIDR(totalAmount)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Fee: <b>{toIDR(totalFee)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount + totalFee)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transfer_Bank_List',
            apiResponseKey: 'data.withdraws',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Transaction Code/Number",
              type: TEXT_FIELD,
              key: "transactionCodeNumber",
              placeholder: "Search code/number",
              value: filter.transactionCodeNumber
            },
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              value: filter.status,
              options: [
                {
                  value: 'PROCESSING',
                  label: 'Diproses'
                },
                {
                  value: 'COMPLETED',
                  label: 'Berhasil'
                },
                {
                  value: 'FAILED',
                  label: 'Gagal'
                },
                {
                  value: 'REFUNDED',
                  label: 'Refund'
                },
                {
                  value: 'REQUEST',
                  label: 'Request'
                }
              ],
            },
            {
              label: "Filter Beneficiary",
              type: SELECT_FIELD,
              key: "beneficiary",
              value: filter.beneficiary,
              options: bankOpts,
              loading: isGettingBank
            },
            {
              label: "Filter Biller",
              type: SELECT_FIELD,
              key: "bankType",
              value: filter.bankType,
              options: BankNameTypeOpts
            },
            {
              type: USER_SELECT,
              key: "user",
              value: filter.user
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
            {
              label: "Filter Connected Partner",
              type: PARTNER_SELECT,
              key: "partner",
              value: filter.partner,
              filterIntegrationType: "COMPANY",
              filterByCompanyCode: true
            },
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

const schema = yup.object().shape({
  status: yup.string().required(),
});

const ChangeStatusDialog = ({
  buttonComponent,
  title,
  description,
  opts,
  className,
  _id,
  load
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { register, unregister, setValue, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { status } = watch(['status']);

  const onSubmit = (values) => {
    setIsLoading(true);
    dispatch({
      type: null,
      call: withdrawApi.updateStatus,
      args: [_id, values?.status]
    })
      .then(() => {
        setOpen(false)
        load()
        toast("Ubah status berhasil", { type: 'success' });
      })
      .catch(() => {
        setOpen(false)
        toast("Ubah status gagal", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    register("status")

    return () => {
      unregister("status")
    };
  }, [register, unregister]);

  return (
    <div className={className}>
      {
        cloneElement(buttonComponent, {
          onClick: () => setOpen(true)
        })
      }
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {
          title &&
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        }
        <DialogContent className="relative">
          {
            description &&
            <DialogContentText id="alert-dialog-description">
              <span>{description}</span>
            </DialogContentText>
          }
          <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-between">
            <SelectField
              name="status"
              options={opts}
              onChange={v => setValue('status', v?.value, { shouldValidate: true })}
              value={status}
              error={errors.status}
              helperText={errors.status?.message ?? null}
            />
            <div className="d-flex justify-content-center mb-2 mt-5">
              <button
                disabled={!status}
                loading={isLoading}
                className="btn btn-outline-primary w-50 mr-2"
                type="submit"
              >
                Konfirmasi
              </button>
              <button
                type="button"
                className="btn btn-outline-danger w-50"
                onClick={() => setOpen(false)}
              >
                Batalkan
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
