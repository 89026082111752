import { combineReducers } from 'redux';
import cashback from './cashback';
import businessApproval from './businessApproval';
import businessVerify from './businessVerify';
import business from './business';
import businessReferral from './businessReferral';
import requestUpdate from './requestUpdate';
import settlement from './settlement';

export default combineReducers({
  business,
  businessReferral,
  cashback,
  businessApproval,
  businessVerify,
  requestUpdate,
  settlement
});
