import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { format } from 'date-fns';
import { TRANSFER_DIPAY_MODULE, GET_DETAIL } from '../../../../utils/constants/actionTypes';
import { combineBy } from '../../../../utils/helpers/combineBy';
import { toIDR } from '../../../../utils/helpers/currency';
import useAsync from '../../../../components/HooksUse/useAsync';
import { transferEvyApi } from '../../../../services/transferEvyApi';

const Detail = ({ index }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.transfer.transferEvy.tabs[index]);

  const {
    value: { data: { transfer: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: TRANSFER_DIPAY_MODULE,
      type: GET_DETAIL,
      call: transferEvyApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  return loading ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Sender</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Name"
                  value={combineBy([item.sender.firstName, item.sender.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.sender?.phoneNumber ? item.sender.phoneNumber : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.sender?.email ? item.sender.email : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.sender?.uniqueId ? item.sender.uniqueId : '-'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Details</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Code"
                  value={item.code ? item.code : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Date"
                  value={format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Amount"
                  value={!item.amount ? '-' : item.amount.primary ? toIDR(item.amount.primary, true) : item.amount.point}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Description"
                  value={item.description ? item.description : '-'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Recipient</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Name"
                  value={combineBy([item.recipient.firstName, item.recipient.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.recipient?.phoneNumber ? item.recipient.phoneNumber : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.recipient?.email ? item.recipient.email : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.recipient?.uniqueId ? item.recipient.uniqueId : '-'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail;
