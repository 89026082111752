import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useMountedState from '../HooksUse/useMountedState';
import SelectField from '../Forms/SelectField';
import { donationApi } from '../../services/donationApi';

const staticValues = {
  sort: 'createdAt|desc',
  limit: 10,
}

const DonationSelect = ({
  label,
  disabled,
  error,
  helperText,
  onChange,
  value,
  placeholder
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [totalItems, setTotalItems] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const { sort, limit } = staticValues;
  const isMounted = useMountedState();
  const [initialValue] = useState(value)

  const getDonationListDebounced = useMemo(
    () => AwesomeDebouncePromise(donationApi.list, 500),
    [],
  )

  const generateOpts = data => data.map(row => ({
    value: row._id,
    label: `${row.name} | ${row._id}`,
    model: 'Donation',
    optData: row
  }))

  const dispatchLoad = useCallback(
    (call, page, limit, sort, search, searchByID) => {
      return dispatch({
        type: null,
        call: call,
        args: [
          {
            page,
            limit,
            sort: search ? null : sort,
            startDate: null,
            endDate: null,
            search: searchByID ? `_id|${searchByID}` : search ? `name|${search}` : null
          }
        ]
      })
    },
    [dispatch],
  )

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);
      dispatchLoad(donationApi.list, newPage, limit, sort, search)
        .then(({ data: { donations, count } }) => {
          if (isMounted()) {
            setOptions([...options, ...generateOpts(donations)])
            setTotalItems(count)
          }
        })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) });
    }
  }

  const handleSelectChange = opt => {
    setSelectedOption(opt)
    setSearch('')

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(getDonationListDebounced, newPage, limit, sort, search)
        .then(({ data: { donations, count } }) => {
          if (isMounted()) {
            options = generateOpts(donations)
            setTotalItems(count)
            const initial = typeof initialValue === 'object' ? initialValue.value : initialValue
            let selectionExist = Boolean(donations.find(v => v._id === initial))
            if (initial && !search && !selectionExist) return dispatchLoad(getDonationListDebounced, 0, 0, null, null, initial)
          }
        })
        .then(({ data: { donations } = {} } = {}) => {
          if (isMounted() && donations) options = [...generateOpts(donations), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setIsLoading(false)
            setOptions(options)
          }
        });
    }
    loadData()
  }, [limit, sort, search, dispatchLoad, getDonationListDebounced, isMounted, initialValue])

  return (
    <SelectField
      id="selectDonation"
      label={label ?? "Select Donation"}
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder={placeholder ?? "Type anything.."}
      componentProps={{
        inputValue: search,
        onInputChange: setSearch,
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom,
        isDisabled: disabled
      }}
      error={error}
      helperText={helperText}
    />
  )
}

export default DonationSelect;
