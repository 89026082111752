import { yupResolver } from "@hookform/resolvers";
import { endOfDay, format, startOfDay } from "date-fns";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { UserTypesOpts } from "../../../utils/constants/enums/userTypes";
import { numberRegExp } from "../../../utils/constants/regexTypes";
import { toIDR } from "../../../utils/helpers/currency";
import EvyDatePicker from "../../../components/Forms/EvyDatePicker";
import HookTextField from "../../../components/Forms/HookTextField";
import SelectField from "../../../components/Forms/SelectField";

const schema = yup.object().shape({
  // General Information
  type: yup.string().required(),
  description: yup.string().required(),
  subType: yup.string().required(),
  value: yup.string().matches(numberRegExp, 'Only Number Allowed').required()
    .when('subType', {
      is: subType => subType === 'PERCENTAGE',
      then: yup => yup.test(
        "range",
        "Input must be 0 - 100%",
        value => +value >= 0 && +value <= 100
      ),
      otherwise: yup => yup,
    }),
  maxAmount: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('subType', {
      is: subType => subType === "PERCENTAGE",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  minAmount: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('subType', {
      is: subType => subType === "FIXED",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),

  // Date
  periodFrom: yup.string().required(),
  periodTo: yup.string().required(),

  // Quota
  maxQuota: yup.string().matches(numberRegExp, 'Only Number Allowed').required(),
  applicableType: yup.string().required(),
  userType: yup.string().required(),
  maxQuotaApplicable: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('applicableType', {
      is: applicableType => applicableType === "PER_USER",
      then: yup.string().required(),
      otherwise: yup.string(),
    })
});

const Form = ({ initialValue, onSubmit }) => {
  const { register, handleSubmit, errors, setValue, watch, unregister, setError } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValue,
      periodFrom: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      periodTo: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    }
  });

  const handleDateChange = field => date => {
    let v;
    if (field === 'periodFrom') {
      v = startOfDay(new Date(date));
    } else if (field === 'periodTo') {
      v = endOfDay(new Date(date));
    }
    setValue(field, format(new Date(v), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), { shouldValidate: true });
  }

  const {
    type,
    subType,
    value,
    maxAmount,
    minAmount,
    periodFrom,
    periodTo,
    applicableType,
    userType,
    maxQuota,
    maxQuotaApplicable
  } = watch([
    'type',
    'subType',
    'value',
    'maxAmount',
    'minAmount',
    'periodFrom',
    'periodTo',
    'applicableType',
    'userType',
    'maxQuota',
    'maxQuotaApplicable'
  ]);

  useEffect(() => {
    if (+maxQuotaApplicable > +maxQuota) {
      setError("maxQuotaApplicable", { message: "Exceed Maximun Quota!" })
    } else {
      setError("maxQuotaApplicable", { message: "" })
    }
  }, [maxQuota, maxQuotaApplicable, setError])

  useEffect(() => {
    register('type');
    register('subType');
    register('periodFrom');
    register('periodTo');
    register('applicableType');
    register('userType');

    return () => {
      unregister('type');
      unregister('subType');
      unregister('periodFrom');
      unregister('periodTo');
      unregister('applicableType');
      unregister('userType');
    }
  }, [register, unregister])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)} id="cashbackForm">
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">GENERAL INFORMATION</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-sm-4">
                  <SelectField
                    label="Type"
                    options={[
                      {
                        value: 'CASHBACK',
                        label: 'Cashback'
                      }
                    ]}
                    onChange={v => setValue('type', v?.value, { shouldValidate: true })}
                    value={type}
                    error={errors.type}
                    helperText={errors.type ? errors.type?.message : null}
                  />
                </div>
                <div className="col-sm-4">
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Description"
                    name="description"
                    error={errors.description}
                    helperText={errors.description ? errors.description?.message : null}
                    placeholder="e.g: Promo Kemerdekaan!"
                  />
                </div>
                <div className="col-sm-4">
                  <SelectField
                    label="Subtype"
                    options={
                      [
                        {
                          value: 'PERCENTAGE',
                          label: 'Percentage'
                        },
                        {
                          value: 'FIXED',
                          label: 'Fixed'
                        }
                      ]
                    }
                    onChange={v => setValue('subType', v?.value, { shouldValidate: true })}
                    value={subType}
                    error={errors.subType}
                    helperText={errors.subType ? errors.subType?.message : null}
                  />
                </div>
                {
                  subType &&
                  <>
                    <div className="col-sm-4">
                      <HookTextField
                        prepend={subType === 'FIXED' ? 'Rp' : null}
                        append={subType === 'PERCENTAGE' ? '%' : null}
                        ref={register}
                        autoComplete="off"
                        label="Value"
                        name="value"
                        error={errors.value}
                        helperText={errors.value ? errors.value?.message : (subType === 'PERCENTAGE' ? null : toIDR(value))}
                      />
                    </div>
                    {
                      subType !== 'FIXED' ?
                        <div className="col-sm-4">
                          <HookTextField
                            prepend="Rp"
                            ref={register}
                            autoComplete="off"
                            label="Maximum Amount"
                            name={"maxAmount"}
                            error={errors.maxAmount}
                            helperText={errors.maxAmount ? errors.maxAmount?.message : toIDR(maxAmount)}
                            placeholder="e.g: 10000"
                          />
                        </div>
                        :
                        <div className="col-sm-4">
                          <HookTextField
                            prepend="Rp"
                            ref={register}
                            autoComplete="off"
                            label="Minimum Amount"
                            name={"minAmount"}
                            error={errors.minAmount}
                            helperText={errors.minAmount ? errors.minAmount?.message : toIDR(minAmount)}
                            placeholder="e.g: 10000"
                          />
                        </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Date</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-sm-4">
                  <label>From</label>
                  <EvyDatePicker
                    value={periodFrom}
                    onChange={handleDateChange('periodFrom')}
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    disableToolbar
                    autoOk
                  />
                </div>
                <div className="col-sm-4">
                  <label>To</label>
                  <EvyDatePicker
                    value={periodTo}
                    onChange={handleDateChange('periodTo')}
                    margin="dense"
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    disableToolbar
                    disablePast
                    autoOk
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Quota</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-sm-4">
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Maximum Quota"
                    name="maxQuota"
                    error={errors.maxQuota}
                    helperText={errors.maxQuota ? errors.maxQuota?.message : null}
                    placeholder="e.g: 100"
                  />
                </div>
                <div className="col-sm-4">
                  <SelectField
                    label="Quota Applicable"
                    options={[
                      {
                        value: 'ALL_USER',
                        label: 'All User'
                      },
                      {
                        value: 'PER_USER',
                        label: 'Per User'
                      },
                    ]}
                    onChange={v => setValue('applicableType', v?.value, { shouldValidate: true })}
                    value={applicableType}
                    error={errors.applicableType}
                    helperText={errors.applicableType ? errors.applicableType?.message : null}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectField
                    label="User Type"
                    options={UserTypesOpts}
                    onChange={v => setValue('userType', v?.value, { shouldValidate: true })}
                    value={userType}
                    error={errors.userType}
                    helperText={errors.userType ? errors.userType?.message : null}
                  />
                </div>
                {applicableType === "PER_USER" ?
                  <div className="col-sm-4">
                    <HookTextField
                      ref={register}
                      autoComplete="off"
                      label="Maximum Quota Applicable"
                      name="maxQuotaApplicable"
                      error={errors.maxQuotaApplicable}
                      helperText={errors.maxQuotaApplicable ? errors.maxQuotaApplicable?.message : null}
                      placeholder="e.g: 100"
                    />
                  </div> : null
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Form;
