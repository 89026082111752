import React, { useCallback, useEffect, useMemo } from 'react'
import * as yup from 'yup'
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import { numberRegExp } from '../../../../utils/constants/regexTypes';
import { toIDR } from '../../../../utils/helpers/currency';
import { PpobType, PpobTypeOpts } from '../../../../utils/constants/enums/ppobTypes';
import useAsync from '../../../../components/HooksUse/useAsync';
import { useDispatch } from 'react-redux';
import PartnerSelect from '../../../../components/Select/PartnerSelect';
import HookTextField from '../../../../components/Forms/HookTextField';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import SelectField from '../../../../components/Forms/SelectField';
import styles from './ppob.module.scss'
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { IMAGE_FORMATS } from '../../../../utils/constants/actionTypes';
import HookImage from '../../../../components/Forms/HookImage';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import activeStatusOpts from '../../../../utils/constants/enums/status/activeStatusOpts';
import { ppobApi } from '../../../../services/ppobApi';

const aggregatorInitVal = {
  code: '',
  price: '',
  revenue: '',
  biller: '',
  custom: false
}

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  aggregator: yup.array().of(
    yup.object().shape({
      code: yup.string().required('Code is required'),
      price: yup.mixed().when('custom', {
        is: custom => !custom,
        then: yup => yup
          .test(
            "number",
            "Only Number Allowed",
            value => value.match(numberRegExp)
          )
          .test(
            "required",
            "Price is required",
            value => value
          )
          .test(
            "zero",
            "Must be more than 0",
            value => +value > 0
          ),
      }),
      revenue: yup.string().when('custom', {
        is: custom => custom,
        then: yup => yup.matches(numberRegExp, 'Only Number Allowed').required('Revenue is required')
      }),
      biller: yup.string().required('Biller is required'),
    })
  ),
  image: yup.mixed().test(
    "fileFormat",
    "Unsupported Format",
    value => value?.[0] ? IMAGE_FORMATS.includes(value[0].type) : true
  ),
  type: yup.string(),
  sellPrice: yup.string().matches(numberRegExp, 'Only Number Allowed').required(),
  isDisc: yup.string().default(''),
  DiscSellPrice: yup.string().when('isDisc', {
    is: isDisc => isDisc,
    then: yup => yup.matches(numberRegExp, 'Only Number Allowed').required(),
    otherwise: yup => yup.nullable()
  }),
  active: yup.string(),
  isHide: yup.string().required(),
  data: yup.object()
    .when('type', {
      is: type => type === PpobType.PHONE_CREDIT || type === PpobType.PHONE_PACKAGE || type === PpobType.PHONE_POSTPAID,
      then: yup.object({
        mobileCarrier: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.GAME_VOUCHER,
      then: yup.object({
        gameVoucher: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.ETOLL,
      then: yup.object({
        etoll: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.PPOB_EWALLET,
      then: yup.object({
        ewallet: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.TELKOM,
      then: yup.object({
        telkom: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.DIRECT_INTEGRATE || type === PpobType.DI_IPL || type === PpobType.MF_INDOFUND,
      then: yup.object({
        partnerId: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.MULTIFINANCE,
      then: yup.object({
        mfcode: yup.string().required()
      })
    })
    .when('type', {
      is: type => type === PpobType.INTERNET || type === PpobType.STREAMING || type === PpobType.EDU_VOUCHER || type === PpobType.EDUCATION,
      then: yup.object({
        subcode: yup.string().required()
      })
    })
});

const Form = ({ setSelectedType, initialValue, submit, isLoading, previousImageUrl }) => {
  const dispatch = useDispatch();

  const getGameVoucherCategory = useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.getGameVoucherCategory
    }),
    [dispatch],
  )
  const getBillers = useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.getBillers
    }),
    [dispatch]
  )
  const { value: { data: gameVoucherOpts = [] } = {} } = useAsync(getGameVoucherCategory, true);
  const gameVoucherCategory = useMemo(() => gameVoucherOpts.map(row => ({ label: row, value: row })) ?? [], [gameVoucherOpts]);

  const { value: { data: billerOpts = [] } = {}, pending: gettingBillers } = useAsync(getBillers, true);
  const billerOptions = useMemo(() => billerOpts.map(row => ({ label: row, value: row })) ?? [], [billerOpts]);

  const { register, handleSubmit, errors, setValue, watch, unregister, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      aggregator: [aggregatorInitVal],
      ...initialValue,
      isHide: initialValue?.isHide ? "1" : "0",
    }
  });

  const { fields, remove, append, move } = useFieldArray(
    {
      control,
      name: "aggregator"
    }
  );

  const {
    aggregator,
    type,
    data: { gameVoucher, partnerId, telkom, mobileCarrier, etoll, ewallet, mfcode, subcode } = {},
    active,
    isHide,
    sellPrice,
    isDisc,
    DiscSellPrice
  } = watch(['aggregator', 'type', 'data', 'active', 'isHide', 'sellPrice', 'isDisc', 'DiscSellPrice']);

  useEffect(() => {
    register("active");
    register("isHide");
    register("type");
    register("isDisc");

    return () => {
      unregister("active");
      unregister("isHide");
      unregister("type");
      unregister("isDisc");
    };
  }, [register, unregister]);

  useEffect(() => {
    if (type === PpobType.GAME_VOUCHER) register("data.gameVoucher");
    else unregister("data.gameVoucher")
    if (type === PpobType.DIRECT_INTEGRATE || type === PpobType.DI_IPL || type === PpobType.MF_INDOFUND) register("data.partnerId");
    else unregister("data.partnerId")
    if (type === PpobType.PHONE_CREDIT || type === PpobType.PHONE_PACKAGE || type === PpobType.PHONE_POSTPAID) register("data.mobileCarrier")
    else unregister("data.mobileCarrier")
    if (type === PpobType.ETOLL) register("data.etoll")
    else unregister("data.etoll")
    if (type === PpobType.PPOB_EWALLET) register("data.ewallet")
    else unregister("data.ewallet")
    if (type === PpobType.TELKOM) register("data.telkom")
    else unregister("data.telkom")
    if (type === PpobType.MULTIFINANCE) register("data.mfcode")
    else unregister("data.mfcode")
    if (type === PpobType.INTERNET || type === PpobType.STREAMING || type === PpobType.EDU_VOUCHER || type === PpobType.EDUCATION) register("data.subcode")
    else unregister("data.subcode")
  }, [register, unregister, type]);

  const handleMove = useCallback(
    (direction, currentIndex) => {
      if (direction === 'up') {
        if (currentIndex === 0) return
        move(currentIndex, currentIndex - 1)
      } else if (direction === 'down') {
        if (currentIndex === fields.length - 1) return
        move(currentIndex, currentIndex + 1)
      }
    },
    [fields.length, move]
  )

  const TypeField = useMemo(() => {
    if (type === PpobType.PHONE_CREDIT || type === PpobType.PHONE_PACKAGE || type === PpobType.PHONE_POSTPAID) {
      return <EvyTextField
        label="Mobile Carrier"
        value={mobileCarrier}
        onChange={e => setValue('data.mobileCarrier', e.target.value, { shouldValidate: true })}
        error={errors.data?.mobileCarrier}
        helperText={errors.data?.mobileCarrier?.message}
        placeholder="e.g: As 10000"
      />
    } else if (type === PpobType.GAME_VOUCHER) {
      return <SelectField
        label="Game Voucher"
        options={gameVoucherCategory}
        onChange={v => setValue('data.gameVoucher', v?.value, { shouldValidate: true })}
        value={gameVoucher}
        error={errors.data?.gameVoucher}
        helperText={errors.data?.gameVoucher?.message}
      />
    } else if (type === PpobType.ETOLL) {
      return <EvyTextField
        label="E-TOLL"
        value={etoll}
        onChange={e => setValue('data.etoll', e.target.value, { shouldValidate: true })}
        error={errors.data?.etoll}
        helperText={errors.data?.etoll?.message}
        placeholder="e.g: MANDIRI"
      />
    } else if (type === PpobType.PPOB_EWALLET) {
      return <EvyTextField
        label="E-Wallet"
        value={ewallet}
        onChange={e => setValue('data.ewallet', e.target.value, { shouldValidate: true })}
        error={errors.data?.ewallet}
        helperText={errors.data?.ewallet?.message}
        placeholder="e.g: OVO"
      />
    } else if (type === PpobType.TELKOM) {
      return <EvyTextField
        label="Telkom"
        value={telkom}
        onChange={e => setValue('data.telkom', e.target.value, { shouldValidate: true })}
        error={errors.data?.telkom}
        helperText={errors.data?.telkom?.message}
        placeholder="e.g: TELKOM SPEEDY"
      />
    } else if (type === PpobType.DIRECT_INTEGRATE || type === PpobType.DI_IPL || type === PpobType.MF_INDOFUND) {
      return (
        <PartnerSelect
          onChange={v => setValue('data.partnerId', v?.optData?.partnerId, { shouldValidate: true })}
          getOptionValue={v => v.optData.partnerId}
          value={partnerId ?? ''}
          error={errors.data?.partnerId}
          helperText={errors.data?.partnerId?.message}
          filterIntegrationType={(type === PpobType.DIRECT_INTEGRATE || type === PpobType.MF_INDOFUND) ? 'COMPANY' : type === PpobType.DI_IPL ? 'IPL' : null}
        />
      )
    } else if (type === PpobType.MULTIFINANCE) {
      return <EvyTextField
        label="Filter Code"
        value={mfcode}
        onChange={e => setValue('data.mfcode', e.target.value, { shouldValidate: true })}
        error={errors.data?.mfcode}
        helperText={errors.data?.mfcode?.message}
      />
    } else if (type === PpobType.INTERNET || type === PpobType.STREAMING || type === PpobType.EDU_VOUCHER || type === PpobType.EDUCATION) {
      return <EvyTextField
        label="Filter Code"
        value={subcode}
        onChange={e => setValue('data.subcode', e.target.value, { shouldValidate: true })}
        error={errors.data?.subcode}
        helperText={errors.data?.subcode?.message}
      />
    }
  }, [type, errors.data, gameVoucher, setValue, gameVoucherCategory, partnerId, etoll, ewallet, mobileCarrier, telkom, mfcode, subcode]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Title"
            name="title"
            error={errors.title}
            helperText={errors.title?.message}
            placeholder="e.g: As 10000"
          />
          <HookTextField
            multiline
            ref={register}
            label="Description"
            name="description"
            error={errors.description}
            helperText={errors.description?.message}
            placeholder="e.g: 750MB Kuota Utama + 2GB 4G"
          />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
              <SelectField
                label="Select Type"
                options={PpobTypeOpts}
                onChange={(v) => {
                  setValue('type', v?.value, { shouldValidate: true })
                  setSelectedType(v?.value)
                }}
                value={type}
                error={errors.type}
                helperText={errors.type?.message}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-8 col-xl-9">
              {TypeField}
            </div>
          </div>
          <SelectField
            label="Set Active Status"
            id="active"
            options={activeStatusOpts}
            onChange={v => setValue('active', v?.value, { shouldValidate: true })}
            value={active}
            error={errors.active}
            helperText={errors.active?.message}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isHide === "1" ? true : false}
                onChange={(v) => setValue('isHide', v.target.checked ? "1" : "0", { shouldValidate: true })}
                color="primary"
              />
            }
            label="Hide"
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Image</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookImage
            ref={register}
            previousImageUrl={previousImageUrl}
            name="image"
            format={IMAGE_FORMATS}
            error={errors.image}
            helperText={errors?.image?.message ?? null}
            onClear={() => setValue('image', null, { shouldValidate: true })}
            viewVariant="landscape"
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Aggregator</h5>
        </div>
        <div className="col-12 col-md-9">
          {
            fields.map((item, index) => {
              return (
                <div key={item.id} className={styles.aggregatorBox}>
                  <div className={styles.aggregatorWrapper}>
                    <h1>{index + 1}</h1>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Controller
                          defaultValue={item.biller}
                          control={control}
                          name={`aggregator[${index}].biller`}
                          render={({ onChange, value }) => (
                            <SelectField
                              label="Select Biller"
                              options={billerOptions}
                              onChange={(v) => onChange(v?.value)}
                              value={value}
                              error={Boolean(errors?.aggregator?.[index]?.biller)}
                              helperText={errors?.aggregator?.[index]?.biller?.message}
                              componentProps={{
                                isLoading: gettingBillers
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <HookTextField
                          defaultValue={item.code}
                          ref={register()}
                          label="Code"
                          name={`aggregator[${index}].code`}
                          error={Boolean(errors?.aggregator?.[index]?.code)}
                          helperText={errors?.aggregator?.[index]?.code?.message}
                          placeholder="e.g: P218013"
                        />
                      </div>
                    </div>
                    <Controller
                      defaultValue={item.custom}
                      control={control}
                      name={`aggregator[${index}].custom`}
                      render={({ onChange, onBlur, value }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={onBlur}
                              checked={value}
                              onChange={(v) => onChange(v.target.checked)}
                              color="primary"
                            />
                          }
                          label="Custom Revenue"
                        />
                      )}
                    />
                    <div className="row">
                      <div className="col-12 col-md-6">
                        {aggregator?.[index]?.custom ? (
                          <HookTextField
                            defaultValue={item.revenue}
                            ref={register()}
                            label="Revenue"
                            name={`aggregator[${index}].revenue`}
                            error={Boolean(errors?.aggregator?.[index]?.revenue)}
                            helperText={errors?.aggregator?.[index]?.revenue?.message ?? toIDR(aggregator?.[index]?.revenue)}
                            placeholder="e.g: 10000"
                            prepend="Rp"
                          />
                        ) : (
                          <HookTextField
                            defaultValue={item.price}
                            ref={register()}
                            label="Price"
                            name={`aggregator[${index}].price`}
                            error={Boolean(errors?.aggregator?.[index]?.price)}
                            helperText={errors?.aggregator?.[index]?.price?.message ?? toIDR(aggregator?.[index]?.price)}
                            placeholder="e.g: 10000"
                            prepend="Rp"
                          />
                        )
                        }
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center">
                        <h6 className="text-primary">Total Revenue: <span className="font-weight-bold">{aggregator?.[index]?.custom ? toIDR(aggregator?.[index]?.revenue ? aggregator?.[index]?.revenue : 0) : toIDR((sellPrice ? +sellPrice : 0) - (aggregator?.[index]?.price ? +aggregator[index].price : 0))}</span></h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Controller
                          defaultValue={item.status ? "ACTIVE" : "INACTIVE"}
                          control={control}
                          name={`aggregator[${index}].status`}
                          render={({ onChange, value }) => {
                            return <SelectField
                              label="Set Active Status"
                              options={activeStatusOpts}
                              onChange={(v) => onChange(v?.value)}
                              value={value}
                              error={Boolean(errors?.aggregator?.[index]?.status)}
                              helperText={errors?.aggregator?.[index]?.status?.message}
                            />
                          }}
                        />
                      </div>
                    </div>
                    <button disabled={index === 0} type="button" className="btn btn-outline-primary" onClick={() => handleMove('up', index)}>Move Up </button>
                    <button disabled={index === fields.length - 1} type="button" className="btn btn-outline-primary ml-2" onClick={() => handleMove('down', index)}>Move Down</button>
                    {
                      fields.length > 1 &&
                      <button type="button" className="btn btn-danger ml-2" onClick={() => remove(index)}>Remove</button>
                    }
                  </div>
                </div>
              )
            }
            )
          }
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success"
              type="button"
              onClick={() => append(aggregatorInitVal)}
            >
              + Add Aggregator
            </button>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Pricing</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Sell Price"
            name="sellPrice"
            error={errors.sellPrice}
            helperText={errors.sellPrice?.message ?? toIDR(sellPrice)}
            placeholder="e.g: 10000"
            prepend="Rp"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isDisc}
                onChange={(v) => setValue('isDisc', v.target.checked, { shouldValidate: true })}
                color="primary"
              />
            }
            label="Promo"
          />
          <HookTextField
            readOnly={!isDisc}
            ref={register}
            label="Promo Price"
            name="DiscSellPrice"
            error={errors.DiscSellPrice}
            helperText={errors.DiscSellPrice?.message ?? toIDR(DiscSellPrice)}
            placeholder="e.g: 10000"
            prepend="Rp"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Form
