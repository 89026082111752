import React, { useState } from 'react';
import * as yup from 'yup';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { format } from 'date-fns'
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import HookTextField from '../../../../components/Forms/HookTextField';
import FormField from '../../../../components/Forms/FormField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import CloseIcon from '@material-ui/icons/Close';
import { phoneNumberRegex } from '../../../../utils/constants/regexTypes';
import { partnerApi } from '../../../../services/partnerApi';

const PersonInChargeCard = ({ item, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Person In Charge</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ?
            <><CloseIcon fontSize="small" /><span className=" ml-2 text-uppercase">Cancel</span></> :
            <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
          }
        </button>
      </div>
      {isEditing ?
        <Form
          item={item}
          onSuccess={onSuccess}
          setIsLoading={setIsLoading}
          setIsEditing={setIsEditing}
        />
        :
        <View
          item={item}
        />
      }
      <LoadingBlocker in={isLoading} />
    </div>
  )
}

const View = ({ item }) => (
  <>
    <EvyTextField
      readOnly
      row
      label="Email"
      value={item.email}
      helperText={`Verified at: ${item.status.emailVerifiedAt ? format(new Date(item.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
    />
    <EvyTextField
      readOnly
      row
      label="Name"
      value={item.picName}
    />
    <EvyTextField
      readOnly
      row
      label="Identification Number"
      value={item.picIdNumber}
    />
    <EvyTextField
      readOnly
      row
      label="Role"
      value={item.picJobTitle}
    />
    <EvyTextField
      readOnly
      row
      label="Phone Number"
      value={item.phoneNumber}
      helperText={`Verified at: ${item.status.phoneNumberVerifiedAt ? format(new Date(item.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
    />
  </>
)

const schema = yup.object().shape({
  picName: yup.string().required('Person Name is required'),
  picJobTitle: yup.string().required('Role is required'),
  picEmail: yup.string().email('Format is invalid').required('Email is required'),
  picPhoneNumber: yup.string().required('Phone Number is required').matches(phoneNumberRegex, 'Format is invalid. e.g: +628xxxxxxxxxx').min(5, 'Minimum length 5 digits'),
  picIdNumber: yup.string()
});

const Form = ({ item, onSuccess = () => { }, setIsLoading, setIsEditing }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      picName: item.picName ?? '',
      picEmail: item.email ?? '',
      picJobTitle: item.picJobTitle ?? '',
      picPhoneNumber: item.phoneNumber ?? '',
      picIdNumber: item.picIdNumber ?? '',
    },
  });

  const onSubmit = (values) => {
    const data = {
      email: values.picEmail,
      picName: values.picName,
      picIdNumber: values.picIdNumber,
      picJobTitle: values.picJobTitle,
      phoneNumber: values.picPhoneNumber
    };

    setIsLoading(true);
    dispatch({ type: null, call: partnerApi.updatePic, args: [item._id, data] })
      .then(() => {
        onSuccess();
        setIsEditing(false);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HookTextField
        ref={register}
        label="Email"
        name="picEmail"
        error={errors.picEmail}
        helperText={errors.picEmail ? errors.picEmail.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Name"
        name="picName"
        error={errors.picName}
        helperText={errors.picName ? errors.picName.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Identification Number"
        name="picIdNumber"
        error={errors.picIdNumber}
        helperText={errors.picIdNumber ? errors.picIdNumber.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Role"
        name="picJobTitle"
        error={errors.picJobTitle}
        helperText={errors.picJobTitle ? errors.picJobTitle.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Phone Number"
        name="picPhoneNumber"
        error={errors.picPhoneNumber}
        helperText={errors.picPhoneNumber ? errors.picPhoneNumber.message : null}
        row
      />
      <FormField
        row
      >
        <LoadingBtn type="submit" className="btn btn-primary mr-2">Submit</LoadingBtn>
        <button onClick={() => setIsEditing(false)} className="btn btn-danger">Cancel</button>
      </FormField>
    </form>
  )
}

export default PersonInChargeCard;
