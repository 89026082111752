import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MyForm from './Form'
import { UPDATE, VOUCHER_MODULE, GET_DETAIL } from '../../utils/constants/actionTypes';
import { REDEEM, CASHBACK } from '../../utils/constants/voucherTypes';
import useAsync from '../../components/HooksUse/useAsync';
import useMountedState from '../../components/HooksUse/useMountedState';
import { voucherApi } from '../../services/voucherApi';

const UpdateTab = ({ index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { key, loading } = useSelector(state => state.voucher.tabs[index])
  const dispatch = useDispatch()
  const isMounted = useMountedState()

  const {
    value: { data: { voucher: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: VOUCHER_MODULE,
      type: GET_DETAIL,
      call: voucherApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const iValue = {
    _id: item?._id,
    code: item?.code,
    quantity: item?.quantity,
    status: item?.status,
    startAt: item?.startAt,
    endAt: item?.endAt,
    tnc: {
      user: {
        upgrade: item?.tnc?.user?.upgrade,
      },
      claimable: item?.tnc?.claimable,
      minTransaction: item?.tnc?.minTransaction,
      type: item?.tnc?.redeem ? REDEEM : CASHBACK,
      redeem: {
        fixed: item?.tnc.redeem?.fixed,
        fixedType: item?.tnc.redeem?.fixedType
      },
      cashback: {
        percentage: item?.tnc.cashback?.percentage,
        fixed: item?.tnc.cashback?.fixed,
        max: item?.tnc.cashback?.max
      }
    }
  }

  const onSubmit = (values) => {
    let data = {
      code: values.code,
      startAt: values.startAt,
      endAt: values.endAt,
      quantity: values.quantity,
      status: values.status ? values.status : '',
      attachment: values.attachment,
      tnc: {
        user: {
          upgrade: values.tnc.user.upgrade || ''
        },
        minTransaction: values.tnc.minTransaction,
        claimable: values.tnc.claimable,
      },
    }

    if (values.tnc.type === REDEEM) {
      data.tnc.redeem = values.tnc.redeem;
    } else if (values.tnc.type === CASHBACK) {
      data.tnc.cashback = values.tnc.cashback;
    }

    setIsLoading(true)
    dispatch({
      module: VOUCHER_MODULE,
      type: UPDATE,
      call: voucherApi.update,
      args: [key, data],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return loading && !item ?
    null
    :
    item ?
      <div className="main-card mb-3 card">
        <div className="card-body">

          <MyForm
            onSubmit={onSubmit}
            isLoading={loading || isLoading}
            initialValues={iValue}
            previousImageUrl={item?.attachment?.url}
          >
          </MyForm>

        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default UpdateTab
