import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainTab from './MainTab';
import { CHANGE_TAB, DTTOT_MODULE } from '../../../utils/constants/actionTypes';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from '../../../components/Tabs/Tabs';

const Index = () => {
  const dispatch = useDispatch();
  const { activeTabKey } = useSelector(state => state.dttot);

  const handleTab = type => key => dispatch({ module: DTTOT_MODULE, type: type, key: key })

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>DTTOT</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-shield icon-gradient bg-premium-dark"></i>
            </div>
            <div>
              DTTOT
              <div className="page-title-subheading">Daftar Terduga Teroris dan Organisasi Teroris.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Index;
