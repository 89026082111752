import objectPath from 'object-path';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { articleApi } from '../../services/articleApi';
import { ARTICLE_MODULE, UPDATE } from '../../utils/constants/actionTypes';
import AllStatus from '../../utils/constants/enums/status';
import useMountedState from '../../components/HooksUse/useMountedState';
import Form from './Form';

const UpdateTab = ({ index, reducerPath }) => {
  const dispatch = useDispatch()
  const { item, key } = useSelector(state => objectPath.get(state, reducerPath).tabs[index])
  const [loading, setLoading] = useState(false)
  const isMounted = useMountedState()

  const onSubmit = (values) => {
    const data = {
      application: values.application,
      type: values.type,
      category: values.category,
      description: values.description,
      name: values.name,
      content: values.content,
      eventDate: values.eventDate,
      active: values.active === AllStatus.ACTIVE,
      isAlwaysActive: values.isAlwaysActive === 'YES',
      periodFrom: values.periodFrom || null,
      periodTo: values.periodTo || null
    }
    if (values.attachment[0]) data.attachment = values.attachment[0]
    setLoading(true)
    dispatch({
      module: ARTICLE_MODULE,
      type: UPDATE,
      call: articleApi.update,
      args: [key, data],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setLoading(false) });
  }

  return item ?
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={loading}
          initialValues={item}
          previousImageUrl={item?.attachment?.url}
        >
        </Form>
      </div>
    </div>
    :
    <div className="card">
      <div className="card-body">
        <h5 className="text-center mb-0">Not Found</h5>
      </div>
    </div>
}

export default UpdateTab
