import React from 'react'
import { BUSINESS_MODULE } from '../../../utils/constants/actionTypes'
import BusinessList from "../BusinessList"

const Index = () => {
  return (
    <BusinessList
      reducerPath="businesses.business"
      module={BUSINESS_MODULE}
      title="Business Control"
      subTitle="Full Business Control includes Create, Delete & Updates."
    />
  )
}

export default Index
