import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_PROMO_QRIS_CONFIG
} from "../utils/helpers/roleHelper";

import QRIS from '../pages/PromoConfig/QRIS';

export const promo = {
  type: NAVMENU,
  name: 'Promo Configuration',
  icon: 'pe-7s-gift',
  children: [
    {
      type: NAVLINK,
      name: 'QRIS',
      url: '/promo/qris',
      routeTitle: ROUTE_PROMO_QRIS_CONFIG,
      component: QRIS
    }
  ]
}
