import React from 'react'
import * as yup from 'yup';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import HookTextField from '../../../components/Forms/HookTextField';
import { useDispatch, useSelector } from 'react-redux';
import { COMPANY_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import { companyApi } from '../../../services/companyApi';
import styles from './create.module.scss'

const schema = yup.object().shape({
  fullName: yup.string().required(),
  shortName: yup.string().required(),
  csOperationalHour: yup.string().required(),
  csEmailOprHour: yup.string().required(),
  csWaOprHour: yup.string().required(),
  csPhoneNumber: yup.string().required(),
  csEmail: yup.string().required(),
  csWa: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required(),
  address: yup.string().required(),
  latitude: yup.string().required(),
  longitude: yup.string().required()
});

const CreateTab = () => {
  const dispatch = useDispatch();
  const { isCreating } = useSelector(state => state.company)

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    const data = {
      type: "AFFILIATE",
      fullName: values.fullName,
      shortName: values.shortName,
      csOperationalHour: values.csOperationalHour,
      csWaOprHour: values.csWaOprHour,
      csEmailOprHour: values.csEmailOprHour,
      csPhoneNumber: values.csPhoneNumber,
      csEmail: values.csEmail,
      csWa: values.csWa,
      phoneNumber: values.csPhoneNumber,
      email: values.csEmail,
      addresses: [{
        address: values.address,
        longitude: values.longitude,
        latitude: values.latitude,
        type: "HQ"
      }]
    };
    dispatch({ module: COMPANY_MODULE, type: CREATE, call: companyApi.create, args: [data], tabKey: 'CREATETAB' })
      .catch(() => { });
  };

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">General Information</h5>
            </div>
            <div className="col-12 col-md-9">
              <HookTextField
                ref={register}
                label="Full Name"
                name="fullName"
                placeholder="e.g. Jhon Doe"
                error={errors.fullName}
                helperText={errors.fullName?.message}
              />
              <HookTextField
                ref={register}
                label="Short Name"
                name="shortName"
                placeholder="e.g. Jhon"
                error={errors.shortName}
                helperText={errors.shortName?.message}
              />
            </div>
          </div>

          {/* <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Contact</h5>
            </div>
            <div className="col-12 col-md-9">
              <HookTextField
                ref={register}
                label="Phone Number"
                name="phoneNumber"
                error={errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
              <HookTextField
                ref={register}
                label="Email"
                name="email"
                error={errors.email}
                helperText={errors.email?.message}
              />
              <HookTextField
                ref={register}
                label="CS Phone Number"
                name="csPhoneNumber"
                error={errors.csPhoneNumber}
                helperText={errors.csPhoneNumber?.message}
              />
              <HookTextField
                ref={register}
                label="CS Email"
                name="csEmail"
                error={errors.csEmail}
                helperText={errors.csEmail?.message}
              />
              <HookTextField
                ref={register}
                label="CS Whatsapp"
                name="csWa"
                error={errors.csWa}
                helperText={errors.csWa?.message}
              />
            </div>
          </div> */}

          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Address</h5>
            </div>
            <div className="col-12 col-md-9">
              <HookTextField
                ref={register}
                label="Address"
                name="address"
                placeholder="e.g. Jl. Mega Kuningan"
                error={errors.address}
                helperText={errors.address?.message}
              />
              <HookTextField
                ref={register}
                label="Latitude"
                name="latitude"
                error={errors.latitude}
                helperText={errors.latitude?.message}
                placeholder="e.g. -6.225159"
              />
              <HookTextField
                ref={register}
                label="Longitude"
                name="longitude"
                error={errors.longitude}
                helperText={errors.longitude?.message}
                placeholder="e.g. 106.828117"
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Contact</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className='row'>
                <div className="col-12 col-md-4">
                  <div className={styles.operationalHourCard}>
                    <div className={styles.heading}>
                      <h5>Phone</h5>
                    </div>
                    <HookTextField
                      ref={register}
                      label="CS Phone Number"
                      name="csPhoneNumber"
                      error={errors.csPhoneNumber}
                      helperText={errors.csPhoneNumber?.message}
                      placeholder="e.g. 021-***-***"
                    />
                    <HookTextField
                      ref={register}
                      label="Operational Hour"
                      name="csOperationalHour"
                      error={errors.csOperationalHour}
                      helperText={errors.csOperationalHour?.message}
                      placeholder="e.g. Senin - Jumat (09:00 - 17:00)"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className={styles.operationalHourCard}>
                    <div className={styles.heading}>
                      <h5>Email</h5>
                    </div>
                    <HookTextField
                      ref={register}
                      label="CS Email"
                      name="csEmail"
                      error={errors.csEmail}
                      helperText={errors.csEmail?.message}
                      placeholder="e.g. cs@dipay.id"
                    />
                    <HookTextField
                      ref={register}
                      label="CS Email Opr Hour"
                      name="csEmailOprHour"
                      error={errors.csEmailOprHour}
                      helperText={errors.csEmailOprHour?.message}
                      placeholder="e.g. Senin - Jumat (09:00 - 17:00)"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className={styles.operationalHourCard}>
                    <div className={styles.heading}>
                      <h5>Whatsapp</h5>
                    </div>
                    <HookTextField
                      ref={register}
                      label="CS Whatsapp"
                      name="csWa"
                      error={errors.csWa}
                      helperText={errors.csWa?.message}
                      placeholder="e.g. +62811*******"
                    />
                    <HookTextField
                      ref={register}
                      label="Operational Hour"
                      name="csWaOprHour"
                      error={errors.csWaOprHour}
                      helperText={errors.csWaOprHour?.message}
                      placeholder="e.g. Senin - Jumat (09:00 - 17:00)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn type="submit" loading={isCreating} className="btn btn-primary">Submit</LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div >
    </div>
  )
}

export default CreateTab
