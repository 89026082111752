import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  UPDATE,
  ADD_DELETE_DATA,
  REMOVE_DELETE_DATA,
  DELETE,
  BANK_MODULE,
  CREATE_TAB,
  CLOSE_TAB,
  GET_DETAIL,
} from '../../utils/constants/actionTypes';
import { closeTab, createTab } from '../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'name|asc',
  currentPage: 1,
  activeTabKey: 0,
  isUpdating: false,
  deleteRowId: null,
  tabs: [],
  reload: false
};

const reducer = (state = defaultState, action) => {
  if (action.module !== BANK_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      return {
        ...state,
        items: action.payload && action.payload.data ? action.payload.data.banks : [],
        total: action.payload && action.payload.data ? action.payload.data.count : 0,
      };
    case GET_DETAIL: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.tabKey]) clonedTabs[action.tabKey].loading = false;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case ADD_DELETE_DATA:
      return {
        ...state,
        deleteRowId: action.args._id
      }
    case REMOVE_DELETE_DATA:
      return {
        ...state,
        deleteRowId: null
      }
    case DELETE:
      return {
        ...state,
        deleteRowId: action.error ? state.deleteRowId : null,
        reload: action.error ? false : true
      }
    case UPDATE: {
      const newTabs = state.tabs.filter((row, key) => key !== action.tabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: 0,
      }
    }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: false };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        case UPDATE:
          const newTabs = [...state.tabs];
          newTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: newTabs
          };
        case GET_DETAIL:
          const clonedTabs = [...state.tabs];
          clonedTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: clonedTabs
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
