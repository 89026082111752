import React, { useState } from 'react'
import Form from './Form'
import { useDispatch, useSelector } from 'react-redux';
import { nnsApi } from '../../../services/nnsApi';
import { CREATE, NNS_MODULE } from '../../../utils/constants/actionTypes';

const CreateTab = ({ index }) => {
  const dispatch = useDispatch()
  const { isCreating } = useSelector((state) => state.nns);
  const [errorExistingValue, setErrorExistingValue] = useState("");

  const onSubmit = (values) => {
    const data = {
      bankName: values.bankName,
      codeBankOrPJP: values.codeBankOrPJP,
      codeNNS: values.codeNNS,
      refundTransaction: values.refundTransaction,
      switcher: values.switcher,
    };
    dispatch({
      module: NNS_MODULE,
      type: CREATE,
      call: nnsApi.create,
      args: [data],
    }).catch((error) => {
      setErrorExistingValue(error.message);
    });
  };

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={isCreating}
          errorExistingValue={errorExistingValue}
        />
      </div>
    </div>
  )
}

export default CreateTab
