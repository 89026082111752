import {
  ADD_DELETE_DATA,
  ASYNC_END,
  ASYNC_START,
  CAREER_MODULE,
  CHANGE_TAB,
  CLOSE_TAB,
  CREATE,
  CREATE_TAB,
  DELETE,
  LIMIT_TABLE,
  MAIN_TAB_LOAD,
  REMOVE_DELETE_DATA,
  SORT_TABLE,
  SET_PAGE_TABLE,
  UPDATE,
} from "../../utils/constants/actionTypes";
import { closeTab, createTab } from "../../utils/helpers/tabHelper";

const defaultState = {
  inProgress: false,
  total: 0,
  limit: 10,
  currentPage: 1,
  activeTabKey: 0,
  items: [],
  tabs: [],
  sort: "createdAt|desc",
  deleteRowId: null,
  filter: {
    type: "",
  },
  isUpdating: false,
  isCreating: false,
};

const reducer = (state = defaultState, action) => {
  if (action.module !== CAREER_MODULE) return { ...state };
  switch (action.type) {
    case ADD_DELETE_DATA:
      return {
        ...state,
        deleteRowId: action.args._id,
      };
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        case CREATE:
          return {
            ...state,
            isCreating: true,
          };
        case UPDATE:
          return {
            ...state,
            isUpdating: true,
          };
        default:
          return { ...state };
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
            reload: false,
          };
        default:
          return { ...state };
      }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      };
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(
        state.tabs,
        action.key,
        state.activeTabKey
      );
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey,
      };
    }
    case CREATE:
      return {
        ...state,
        activeTabKey: action.error ? state.activeTabKey : 0,
        isCreating: false,
      };
    case CREATE_TAB:
      const newTabs = createTab(state.tabs, action.key, action.title, {
        item: state.items.find((b) => b._id === action.key),
      });
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key,
      };
    case DELETE:
      return {
        ...state,
        deleteRowId: action.error ? state.deleteRowId : null,
        reload: action.error ? false : true,
      };
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1,
      };
    case MAIN_TAB_LOAD:
      return {
        ...state,
        items:
          action.payload && action.payload.data
            ? action.payload.data.careers
            : [],
        total:
          action.payload && action.payload.data ? action.payload.data.count : 0,
      };
    case REMOVE_DELETE_DATA:
      return {
        ...state,
        deleteRowId: null,
      };
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      };
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value,
      };
    case UPDATE:
      return {
        ...state,
        tabs: action.error ? state.tabs.filter((_, i) => i !== action.tabKey) : [],
        activeTabKey: action.error ? state.activeTabKey : 0,
        isUpdating: false,
      };
    default:
      return state;
  }
};

export default reducer;
