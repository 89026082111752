import React, { useState } from 'react'
import HookTextField from '../../../../components/Forms/HookTextField'
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { useDispatch } from 'react-redux';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import FormField from '../../../../components/Forms/FormField';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../services/businessesApi';

const schema = yup.object().shape({
  instagram: yup.string().url().nullable(),
  facebook: yup.string().url().nullable(),
});

const SocialMedia = ({ business, onSuccess = () => { } }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      instagram: business?.socialMediaUrls?.instagram ?? '',
      facebook: business?.socialMediaUrls?.facebook ?? '',
    }
  });

  const onSubmit = (values) => {
    const data = {
      socialMediaUrls: values
    }

    setIsLoading(true);
    dispatch({
      type: null,
      call: businessesApi.updateSocialMedia,
      args: [business._id, data]
    }).then(() => { if (isMounted()) onSuccess() })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HookTextField
        ref={register}
        label="Instagram URL"
        name="instagram"
        error={errors.instagram}
        helperText={errors.instagram?.message ?? null}
        row
        placeholder="e.g: https://www.instagram.com/dipayindonesia"
      />
      <HookTextField
        ref={register}
        label="Facebook URL"
        name="facebook"
        error={errors.facebook}
        helperText={errors.facebook?.message ?? null}
        row
        placeholder="e.g: https://www.facebook.com/dipayindonesia"
      />
      <FormField row>
        <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary btn-lg">Update</LoadingBtn>
      </FormField>
    </form>
  )
}

export default SocialMedia
