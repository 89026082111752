import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const injectionBulkingApi = {
  list: ({ page, limit, sort, startDate, endDate, search }) => {
    let url = `/bulk-injections/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  validate: (file) => agent.requests.post('/bulk-injections/validate', { file }, true),
  create: (data) => agent.requests.post('/bulk-injections/create', data, true),
  reject: (id, remarks) =>
    agent.requests.post(`/bulk-injections/${id}/reject`, { remarks }),
  approve: id =>
    agent.requests.post(`/bulk-injections/${id}/approve`),
}
