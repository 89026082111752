import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { TypeFilterGroup, TypeFilterItem, TypeFilterWrapper } from '../../../layouts/TypeFilter';
import PerfectScrollbar from 'react-perfect-scrollbar'
import styles from './aml.module.scss'
import { useDispatch } from 'react-redux';
import useMountedState from '../../../components/HooksUse/useMountedState';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import HookTextField from '../../../components/Forms/HookTextField';
import { amlApi } from '../../../services/amlApi';

function getStrType(en) {
  switch (en) {
    case 'bankAccount':
      return 'BANK ACCOUNT';
    case 'email':
      return 'EMAIL';
    case 'phoneNumber':
      return 'PHONE NUMBER';
    case 'uuid':
      return 'UUID';
    case 'blockdevice':
      return 'BLOCK DEVICE';
    default:
      return 'Unknown';
  }
}

const AMLPage = () => {
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const inputRef = useRef(null)

  const [selectedType, setSelectedType] = useState(null)
  const [viewType, setViewType] = useState(null)
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [input, setInput] = useState('')

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value)
    setViewType(e.target.dataset.viewtype)
  }

  const load = useCallback(() => {
    if (!selectedType || !viewType) return
    setIsLoading(true)
    dispatch({ type: null, call: amlApi.list, args: [viewType, selectedType] })
      .then(({ data }) => {
        if (isMounted()) setItems(data)
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })

  }, [dispatch, selectedType, viewType, isMounted])

  const deleteData = (data) => {
    setIsLoading(true)
    dispatch({ type: null, call: amlApi.delete, args: [viewType, selectedType, data] })
      .then(() => {
        if (isMounted()) load()
      })
      .catch(() => { if (isMounted()) setIsLoading(false) })
  }

  const hideInput = () => {
    setInput('')
    setShowInput(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!input) {
      inputRef.current?.focus()
      return
    }

    setIsLoading(true)
    dispatch({ type: null, call: amlApi.create, args: [viewType, selectedType, input] })
      .then(() => {
        hideInput()
        if (isMounted()) load()
      })
      .catch(() => { if (isMounted()) setIsLoading(false) })
  }

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (showInput) inputRef.current?.focus()
  }, [showInput])

  return (
    <>
      <Helmet>
        <title>Block List</title>
      </Helmet>
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-umbrella icon-gradient bg-premium-dark"></i>
              </div>
              <div>
                Anti Money Laundering
                <div className="page-title-subheading">Full AML Control includes List, Create & Delete.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4">
                <div className="mb-3 p-2">
                  <h5 className="card-title">Type Filter</h5>
                  <TypeFilterWrapper>
                    <TypeFilterGroup name="BLOCKLIST">
                      <TypeFilterItem
                        selectedViewType={viewType}
                        name="UUID"
                        type="uuid"
                        selectedType={selectedType}
                        viewType="BLOCKLIST"
                        onChange={handleTypeChange}
                      />
                      <TypeFilterItem
                        selectedViewType={viewType}
                        name="BANK ACCOUNT"
                        type="bankAccount"
                        selectedType={selectedType}
                        viewType="BLOCKLIST"
                        onChange={handleTypeChange}
                      />
                      <TypeFilterItem
                        selectedViewType={viewType}
                        name="EMAIL"
                        type="email"
                        selectedType={selectedType}
                        viewType="BLOCKLIST"
                        onChange={handleTypeChange}
                      />
                      <TypeFilterItem
                        selectedViewType={viewType}
                        name="PHONE NUMBER"
                        type="phoneNumber"
                        selectedType={selectedType}
                        viewType="BLOCKLIST"
                        onChange={handleTypeChange}
                      />
                      <TypeFilterItem
                        selectedViewType={viewType}
                        name="BLOCK DEVICE"
                        type="blockdevice"
                        selectedType={selectedType}
                        viewType="BLOCKLIST"
                        onChange={handleTypeChange}
                      />
                    </TypeFilterGroup>
                    <TypeFilterGroup name="PREFIX">
                      <TypeFilterItem
                        selectedViewType={viewType}
                        name="BANK ACCOUNT"
                        type="bankAccount"
                        selectedType={selectedType}
                        viewType="PREFIX"
                        onChange={handleTypeChange}
                      />
                    </TypeFilterGroup>
                  </TypeFilterWrapper>
                </div>
              </div>

              <div className="col-xl-8">
                <div className={styles.listView}>
                  <LoadingBlocker in={isLoading} />
                  {
                    selectedType ?
                      <>
                        <div className="px-4">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <h5 className="card-title mb-0">{viewType}</h5>
                              <p className="text-secondary mb-0">{getStrType(selectedType)}</p>
                            </div>
                            <div>
                              <h5 className="mb-0">Total: {items.length}</h5>
                            </div>
                          </div>
                          {
                            !showInput ?
                              <button className="btn btn-primary mb-3" onClick={() => setShowInput(true)}>+ Add</button>
                              :
                              <form onSubmit={onSubmit}>
                                <div className="row mb-3">
                                  <div className="row-3">
                                    <HookTextField
                                      ref={inputRef}
                                      formFieldClassName="mb-0 mr-2"
                                      value={input}
                                      onChange={e => setInput(e.target.value)}
                                      error={Boolean(!input)}
                                      helperText={input ? '' : '*required field'}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-danger mr-2"
                                      onClick={hideInput}
                                    >
                                      Cancel
                                    </button>
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                  </div>
                                </div>
                              </form>
                          }
                        </div>
                        <div className={styles.AMLTableWrapper}>
                          <PerfectScrollbar>
                            <div className={styles.AMLTableContainer}>
                              {
                                items.length ?
                                  items.map((item, k) =>
                                    <div className={styles.AMLTableItemWrapper} key={k}>
                                      <div className={styles.info}>
                                        <p className="text-muted mb-0">{item}</p>
                                      </div>
                                      <div className="d-flex">
                                        <p className={styles.index}>{k + 1}</p>
                                      </div>
                                      <button onClick={() => deleteData(item)} className={`btn btn-sm btn-danger mr-3 ${styles.deleteBtn}`}>Delete</button>
                                    </div>
                                  )
                                  :
                                  <p className="mb-0 p-3 text-center">No data</p>
                              }
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </>
                      :
                      <p className={styles.noView}><span className="font-weight-semibold font-size-xlg">View not defined</span><br></br>Please select a type</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AMLPage
