import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../components/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, COMPANY_MODULE, CREATE_TAB } from '../../../utils/constants/actionTypes';
import { format } from 'date-fns';
// import ButtonDispatch from '../../../components/ButtonDispatch';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import { companyApi } from '../../../services/companyApi';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    inProgress,
    items,
    sort,
    limit,
    total,
    currentPage,
    // deleteRowId,
    reload
  } = useSelector(state => state.company);

  const load = useCallback(
    () => {
      dispatch({ module: COMPANY_MODULE, type: MAIN_TAB_LOAD, call: companyApi.list, args: [currentPage, limit, sort] });
    }, [currentPage, limit, sort, dispatch])

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: COMPANY_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const createTab = useCallback((key, title, tabType) => {
    dispatch({
      module: COMPANY_MODULE,
      type: CREATE_TAB,
      key,
      title,
      tabType
    });
  }, [dispatch])

  useEffect(() => {
    load();
  }, [load])

  useEffect(() => {
    if (reload) load();
  }, [reload, load]);

  const table = useMemo(() => {
    // const deleteSelected = () => {
    //   if (deleteRowId) dispatch({ module: COMPANY_MODULE, type: DELETE, call: companyApi.delete, args: [deleteRowId] });
    // }
    return {
      inProgress: inProgress,
      data: items,
      sort: sort,
      limit: limit,
      total: total,
      currentPage: currentPage,
      columns: [
        {
          title: 'Created At', key: 'createdAt', name: 'createdAt', sortable: false, render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
        },
        { title: 'Name', key: 'fullName', name: 'fullName', sortable: false },
        { title: 'Short Name', key: 'shortName', name: 'shortName', sortable: false },
        { title: 'Type', key: 'type', name: 'type', sortable: false },
        {
          title: 'Updated At', key: 'updatedAt', name: 'updatedAt', sortable: false, render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
        },
        {
          title: 'Action', key: '_id', name: 'action', sortable: false, render: (v, row) => {
            return (
              <div className="d-flex">
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => {
                    createTab(v, row.fullName)
                  }}
                >
                  <i className="lnr-eye btn-icon-wrapper" />
                </button>
                {/* <div className="btn-group ml-1">
                  {deleteRowId === v ? (
                    <React.Fragment>
                      <ButtonDispatch type={REMOVE_DELETE_DATA} module={COMPANY_MODULE} className="btn btn-sm btn-danger lh-1">No</ButtonDispatch>
                      <button onClick={deleteSelected} type="button" className="btn btn-sm btn-danger lh-1 py-0">Yes, Delete</button>
                    </React.Fragment>
                  ) : (
                    <ButtonDispatch className="btn btn-icon btn-icon-only btn-sm btn-danger" args={{ _id: v }} module={COMPANY_MODULE} type={ADD_DELETE_DATA}>
                      <i className="pe-7s-trash btn-icon-wrapper"></i>
                    </ButtonDispatch>
                  )}
                </div> */}
              </div>
            )
          }
        }
      ]
    }
  }, [inProgress, items, sort, limit, total, currentPage, createTab])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <LoadingBlocker in={Boolean(inProgress)} />
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        />
      </div>
    </div>
  )
}

export default MainTab
