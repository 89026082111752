import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import EvyTextField from '../../../components/Forms/EvyTextField';
import { format, differenceInDays } from 'date-fns'
import { toIDR } from '../../../utils/helpers/currency';
import { combineBy } from '../../../utils/helpers/combineBy';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AdminRole } from '../../../utils/constants/enums/adminRoles';

const DetailTab = ({ index }) => {
  const { tabs, items } = useSelector(state => state.businesses.cashback);
  const { key } = tabs[index];
  const detailId = useMemo(() => key.replace(/detail/g, ''), [key]);
  const selectedItem = items.find(row => row._id === detailId);
  const startAt = selectedItem ? selectedItem.startAt : '';
  const endAt = selectedItem ? selectedItem.endAt : '';

  return selectedItem ?
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">General Information</h5>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="ID"
                value={selectedItem._id}
                helperText={`Created at: ${selectedItem.createdAt ? format(new Date(selectedItem.createdAt), 'yyyy-MM-dd HH:mm') : '-'}`}
              />
              <EvyTextField
                readOnly
                row
                label="Code"
                value={selectedItem.code}
              />
              <EvyTextField
                readOnly
                row
                label="Percentage"
                value={selectedItem.percentage + '%'}
              />
              <EvyTextField
                readOnly
                row
                label="Start At"
                value={format(new Date(startAt), 'dd-MM-yyyy | HH:mm')}
              />
              <EvyTextField
                readOnly
                row
                label="End At"
                value={format(new Date(endAt), 'dd-MM-yyyy | HH:mm')}
              />
              <EvyTextField
                readOnly
                row
                label="Total Day(s)"
                value={`${differenceInDays(new Date(endAt), new Date(startAt)) + 1} day(s)`}
              />
              <EvyTextField
                readOnly
                row
                label="Active"
                value={selectedItem.active ? 'Yes' : 'No'}
              />
            </div>
            <h5 className="card-title">TNC</h5>
            <div>
              <table className="mb-0 table">
                <thead>
                  <tr>
                    <th>Rule Name</th>
                    <th className="text-right">Normal</th>
                    <th className="text-right">Upgraded</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Max Amount / Merchant</td>
                    <td className="text-right">{toIDR(selectedItem.tnc.maxAmount, false)}</td>
                    <td className="text-right">{toIDR(selectedItem.tncUpgraded.maxAmount, false)}</td>
                  </tr>
                  <tr>
                    <td>Max Accumulate / Reset Day</td>
                    <td className="text-right">{toIDR(selectedItem.tnc.maxAccumulate, false)}</td>
                    <td className="text-right">{toIDR(selectedItem.tncUpgraded.maxAccumulate, false)}</td>
                  </tr>
                  <tr>
                    <td>Min Transaction Amount</td>
                    <td className="text-right">{toIDR(selectedItem.tnc.minTransactionAmount, false)}</td>
                    <td className="text-right">{toIDR(selectedItem.tncUpgraded.minTransactionAmount, false)}</td>
                  </tr>
                  <tr>
                    <td>Reset Day</td>
                    <td className="text-right">{selectedItem.tnc.spanDays} days</td>
                    <td className="text-right">{selectedItem.tncUpgraded.spanDays} days</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">Creator | Admin</h5>
            <div>
              <EvyTextField
                readOnly
                row
                label="Full Name"
                value={combineBy([selectedItem.creator.firstName, selectedItem.creator.lastName])}
              />
              <EvyTextField
                readOnly
                row
                label="Email"
                value={selectedItem.creator.email}
              />
              <EvyTextField
                readOnly
                row
                label="Role"
                value={AdminRole.getStr(selectedItem.creator.role)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="card mb-3">
          <div className="card-body d-flex justify-content-between align-items-center">
            <div>
              <h5 className="card-title mb-0">Bussinesses</h5>
            </div>
            <div>
              <h5 className="card-title mb-0">{selectedItem.businesses.length}</h5>
            </div>
          </div>
          <div className="referreds">
            <PerfectScrollbar>
              <div className="card-body">
                {selectedItem.businesses.map((item, k) =>
                  <div className="referred-wrapper" key={k}>
                    <div className="referred-info">
                      <h6 className="mb-0">{item.name}</h6>
                      <p className="text-muted mb-0">{item.cityName || item.city}</p>
                    </div>
                    <div>
                      <p className="referred-index">{k + 1}</p>
                    </div>
                  </div>
                )}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
    :
    <div className="main-card mb-3 card">
      <div className="card-body">
        <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
      </div>
    </div>
}

export default DetailTab;
