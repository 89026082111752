import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import ExcelExportBtn from '../../../General/Tables/ExcelExportBtn';
import { bodyColumns, generateFormulaPattern, totalValidasi, validasiColumns } from './columns/bodyColumns';
import { headColumns, headColumns1, headColumns2, headColumns3 } from './columns/headColumns';
import { numberingGroup1, numberingGroup2, numberingGroup3 } from './columns/numberingColumns';
import styles from './merchant.module.scss';

const renderRow = (row, index) => (
  <TableRow key={index}>
    {/* Merchant */}
    <TableCell>{index + 1}</TableCell>
    <TableCell>{row.province}</TableCell>
    <TableCell>{row.location}</TableCell>
    {headColumns.map((v, i) => {
      if (v.group !== '3') {
        return <TableCell key={i}>{row[v.key] ?? 0}</TableCell>
      }

      return null
    })}
  </TableRow>
)

const render = (column, row) => {
  let result
  if (column?.keyGroup) {
    if (!column?.key) return result = "0";
    if (!row?.[column?.keyGroup]?.[column?.key]) return result = "0";
    return result = row?.[column?.keyGroup]?.[column?.key]?.toString();
  } else if (!column?.keyGroup) {
    if (!column?.key) return result = "0";
    if (!row[column?.key]) return result = "0";
    return result = row[column?.key]?.toString()
  } else {
    result = "0"
  }
  return result
}

const renderExcelRow = (row, index) => (
  <tr key={index}>
    <td align="center" style={{ verticalAlign: "middle" }}>{index === 0 ? 2 : (index + 1) + 1}</td>
    <td style={{ verticalAlign: "middle" }}>{row.province}</td>
    <td style={{ verticalAlign: "middle" }}>{row.location}</td>
    {bodyColumns.map((column, i) => {
      return (
        <td
          key={column.number}
          align="right"
          style={{
            verticalAlign: "middle",
            backgroundColor: "#ffffcc"
          }}
        >
          {render(column, row)}
          {/* {row[column.key] && 0}
          {column.keyGroup && row?.[column?.keyGroup]?.[column?.key]?.toString()}
          {!column.keyGroup && row[column.key]?.toString()} */}
        </td>
      )
    })}
    {validasiColumns.map((column, i) => {
      let validasiIndex = 12 + index;
      return (
        <td
          key={column.key}
          align="left"
          style={{ verticalAlign: "middle", backgroundColor: "#00b050", color: "#fff", border: "none" }}
        >
          {generateFormulaPattern({
            type: column.type,
            formulaValidasi: column?.formulaValidasi,
            validasiIndex: validasiIndex
          })}
        </td>
      )
    })}
  </tr>
)

const TableAll = ({
  items,
  startDate,
  endDate,
  setLoading
}) => {
  const tableTitle = 'Report BI Merchant';

  const renderTotal = useCallback((column) => {
    return (
      <td
        key={column.key}
        align="right"
        style={{ verticalAlign: "middle", backgroundColor: "#00b050", color: "#fff" }}
      >
        {column.formula}
      </td>
    )
  }, [])

  const renderTotalValidasi = useCallback((column) => {
    return (
      <td
        key={column.key}
        align="left"
        style={{ verticalAlign: "middle", backgroundColor: "#00b050", color: "#fff", border: "none" }}
      >
        {column.formula}
      </td>
    )
  }, [])

  const excelHref = useMemo(() => {
    setLoading(true)
    const table = <>
      <table border="1">
        <tbody>
          <tr>
            <td colSpan="3">Laporan Mingguan Penyelenggara QRIS</td>
          </tr>
          <tr>
            <td colSpan="2" style={{ backgroundColor: '#d0cece' }}>Nama PJSP: </td>
            <td>[Nama PJP]</td>
          </tr>
          <tr>
            <td colSpan="2" style={{ backgroundColor: '#d0cece' }}>Posisi Laporan: </td>
            <td>'[YYYY-MM]</td>
          </tr>
          <tr>
            <td colSpan="3">Mohon baca Tab <span style={{ color: "red" }}>PETUNJUK</span> sebelum melakukan pengisian data</td>
          </tr>
        </tbody>
      </table>
      <table border="1">
        <thead>
          <tr>
            <th rowSpan="5" style={{ width: "20px", backgroundColor: "#bdd6ee" }}>No</th>
            <th colSpan="2" style={{ backgroundColor: "#bdd6ee" }}>Wilayah Penyelenggaraan</th>
            <th colSpan="5" style={{ width: "850px", height: "50px", backgroundColor: "#ffd966" }}>Jumlah Pengguna</th>
            <th colSpan="4" style={{ width: "200px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QR/QRIS</th>
            <th colSpan="7" style={{ width: "650px", padding: "0 10px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QRIS berdasarkan Kriteria Merchant</th>
            <th colSpan="7" style={{ width: "650px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QRIS AKTIF berdasarkan Kriteria Merchant **</th>
            <th colSpan="6" style={{ width: "650px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QRIS berdasarkan Kategori Merchant (MCC)</th>
            <th colSpan="6" style={{ width: "650px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QRIS AKTIF berdasarkan Kategori Merchant (MCC) **</th>
            <th colSpan="7" style={{ width: "650px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QRIS CPM berdasarkan Kriteria Merchant ***</th>
            <th colSpan="6" style={{ width: "650px", backgroundColor: "#c5e0b3" }}>Jumlah Merchant QRIS CPM berdasarkan Kategori Merchant (MCC) ***</th>
            <th colSpan="2" style={{ width: "240px", backgroundColor: "#adb9ca" }}>Total Transaksi QRIS</th>
            <th colSpan="138" style={{ backgroundColor: "#adb9ca" }}>Transaksi QRIS berdasarkan Fungsi PJSP, Kriteria Merchant dan MCC</th>
            <th colSpan="5" style={{ backgroundColor: "#fff", textAlign: "right", borderRight: "none", borderColor: "#0070c0", color: "#0070c0", fontSize: "28px" }}>Validasi:</th>
            <th style={{ backgroundColor: "#fff", textAlign: "left", border: "none", fontSize: "28px", color: "#00b050" }}>=IF(ISBLANK(C2);" ";IF(AND($GV$11:$GV$560="ALL OK");"OK, NO ERROR";IF(AND($GV$11:$GV$560="ERROR");"ALL ERRORS";"SOME ERRORS")))</th>
            <th colSpan="7" style={{ backgroundColor: "#fff", borderLeft: "none", borderColor: "#0070c0", color: "#0070c0" }}></th>
          </tr>
          <tr>
            <th rowSpan="4" style={{ width: "220px", backgroundColor: "#bdd6ee" }}>Provinsi</th>
            <th rowSpan="4" style={{ width: "220px", backgroundColor: "#bdd6ee" }}>Daerah Tingkat II</th>
            {
              headColumns.map((column, index) => {
                let bgColor = ''
                let color = ''
                if (column.group === '1') bgColor = "#ffd966";
                if (column.group === '2') bgColor = "#c5e0b3";
                if (column.group === '3') bgColor = "#adb9ca";
                if (column.group === '4') bgColor = "#548235";
                if (column.group === '4') color = "#fff";
                return (
                  <th
                    key={index}
                    rowSpan={column.rowSpan}
                    colSpan={column.colSpan}
                    style={{
                      height: column?.height,
                      width: column?.width,
                      color: color,
                      backgroundColor: column?.bgColor || bgColor,
                      fontWeight: column?.fontWeight,
                      border: column?.border,
                    }}
                  >
                    {column.title}
                  </th>
                )
              })
            }
          </tr>
          <tr>
            {
              headColumns1.map((column, index) => {
                let bgColor = ''
                if (column.group === '1') bgColor = "#ffd966";
                if (column.group === '2') bgColor = "#c5e0b3";
                if (column.group === '3') bgColor = "#adb9ca";
                if (column.group === '4') bgColor = "#f7caac";
                return (
                  <th
                    key={index}
                    rowSpan={column.rowSpan}
                    colSpan={column.colSpan}
                    style={{ height: column?.height, width: column?.width, backgroundColor: column?.bgColor || bgColor }}
                  >
                    {column.title}
                  </th>
                )
              })
            }
          </tr>
          <tr>
            {
              headColumns2.map((column, index) => {
                let bgColor = ''
                if (column.group === '1') bgColor = "#ffd966";
                if (column.group === '2') bgColor = "#c5e0b3";
                if (column.group === '3') bgColor = "#adb9ca";
                if (column.group === '4') bgColor = "#f7caac";
                return (
                  <th
                    key={index}
                    rowSpan={column.rowSpan}
                    colSpan={column.colSpan}
                    style={{ height: column?.height, width: column?.width, backgroundColor: column?.bgColor || bgColor }}
                  >
                    {column.title}
                  </th>
                )
              })
            }
          </tr>
          <tr>
            {
              headColumns3.map((column, index) => {
                let bgColor = ''
                if (column.group === '1') bgColor = "#ffd966";
                if (column.group === '2') bgColor = "#c5e0b3";
                if (column.group === '3') bgColor = "#adb9ca";
                if (column.group === '4') bgColor = "#f7caac";
                return (
                  <th
                    key={index}
                    rowSpan={column.rowSpan}
                    colSpan={column.colSpan}
                    style={{ height: column?.height, width: column?.width, backgroundColor: column?.bgColor || bgColor }}
                  >
                    {column.title}
                  </th>
                )
              })
            }
          </tr>
          <tr>
            <th style={{ width: "60px", backgroundColor: "#bdd6ee" }}></th>
            <th style={{ width: "200px", backgroundColor: "#bdd6ee" }}></th>
            <th style={{ width: "200px", backgroundColor: "#bdd6ee" }}></th>
            {
              numberingGroup1.map((number) => {
                return <th key={number} style={{ height: "60px", backgroundColor: "#ffd966" }}>{number}</th>
              })
            }
            {
              numberingGroup2.map((number) => {
                return <th key={number} style={{ height: "60px", backgroundColor: "#c5e0b3" }}>{number}</th>
              })
            }
            {
              numberingGroup3.map((number) => {
                return <th key={number} style={{ height: "60px", backgroundColor: "#adb9ca" }}>{number}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center" style={{ verticalAlign: "middle", backgroundColor: "#00b050", color: "#fff" }}>1</td>
            <td style={{ backgroundColor: "#00b050", fontWeight: "bold", color: "#fff" }}>Total Keseluruhan</td>
            <td style={{ backgroundColor: "#00b050", fontWeight: "bold", color: "#fff" }}>Total Keseluruhan</td>
            {
              bodyColumns.map((column) => renderTotal(column))
            }
            <td
              align="left"
              style={{ verticalAlign: "middle", backgroundColor: "#00b050", color: "#fff", borderRight: "none", borderTop: "none", borderBottom: "none", }}
            >
              =IF(I11=SUM(M11:S11);"OK";"ERROR")
            </td>
            {
              totalValidasi.map((column) => renderTotalValidasi(column))
            }
          </tr>
          {
            items.map((row, index) => renderExcelRow(row, index))
          }
          <tr>
            <td align="center" style={{ verticalAlign: "middle" }}>551</td>
            <td style={{ verticalAlign: "middle" }}>UnRegistered</td>
            <td style={{ verticalAlign: "middle" }}>UnRegistered</td>
            <td align="center" style={{ verticalAlign: "middle", backgroundColor: "#ffffcc" }}></td>
            <td align="center" style={{ verticalAlign: "middle", backgroundColor: "#ffffcc" }}></td>
            <td align="center" style={{ verticalAlign: "middle", backgroundColor: "#ffffcc" }}></td>
            <td align="center" style={{ verticalAlign: "middle", backgroundColor: "#ffffcc" }}></td>
            <td align="center" style={{ verticalAlign: "middle", backgroundColor: "#ffffcc" }}></td>
            <td colSpan="183" style={{ backgroundColor: "#000" }}></td>
            <td colSpan="13" style={{ border: "none" }}></td>
          </tr>
        </tbody>
      </table>
    </>

    setLoading(false)

    const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])
    const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))

    const html = ReactDOMServer.renderToStaticMarkup(table);
    const excelHref = 'data:application/vnd.ms-excel;base64,'
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{html}</body></html>';
    const context = {
      worksheet: 'Worksheet',
      html,
    };

    return excelHref + base64(format(template, context))
  }, [setLoading, renderTotal, renderTotalValidasi, items]);

  return (
    <div className="card">
      <div className="table-header">
        <div className="info-cell">
          <div className="d-flex justify-content-between">
            <div>{tableTitle}</div>
            <div>
              {format(new Date(startDate), 'dd MMM yyyy')} - {format(new Date(endDate), 'dd MMM yyyy')}
            </div>
          </div>
        </div>
        <div className="action-cell">
          <ExcelExportBtn
            excelHref={excelHref}
            filename={`${format(new Date(startDate), 'yyyy-MM')} MarecoPrimaMandiri`}
          />
        </div>
      </div>
      <div className={styles.reportTableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Provinsi</TableCell>
              <TableCell>Wilayah</TableCell>
              {headColumns.map((v, i) => {
                if (v.group !== '3') {
                  return <TableCell key={i}>{v.title}</TableCell>
                }

                return null
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => renderRow(row, index))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TableAll
