class divisionOptsTypes {
  static OPERATION = 'OPERATION';
  static FINANCE = 'FINANCE';
  static MARKETING = 'MARKETING'
  static LEGAL_COMPLIANCE = 'LEGAL_COMPLIANCE';
  static PRODUCT = 'PRODUCT';
  static TECH = 'TECH'


  static getStr(en) {
    switch (en) {
      case divisionOptsTypes.OPERATION:
        return 'Operation';
      case divisionOptsTypes.FINANCE:
        return 'Finance';
      case divisionOptsTypes.MARKETING:
        return 'Marketing';
      case divisionOptsTypes.LEGAL_COMPLIANCE:
        return 'Legal & Compliance';
      case divisionOptsTypes.PRODUCT:
        return 'Product';
      case divisionOptsTypes.TECH:
        return 'Tech';
      default:
        return '-';
    }
  }
}

const divisionOpts = [
  { value: divisionOptsTypes.OPERATION, label: divisionOptsTypes.getStr(divisionOptsTypes.OPERATION) },
  { value: divisionOptsTypes.FINANCE, label: divisionOptsTypes.getStr(divisionOptsTypes.FINANCE) },
  { value: divisionOptsTypes.MARKETING, label: divisionOptsTypes.getStr(divisionOptsTypes.MARKETING) },
  { value: divisionOptsTypes.LEGAL_COMPLIANCE, label: divisionOptsTypes.getStr(divisionOptsTypes.LEGAL_COMPLIANCE) },
  { value: divisionOptsTypes.PRODUCT, label: divisionOptsTypes.getStr(divisionOptsTypes.PRODUCT) },
  { value: divisionOptsTypes.TECH, label: divisionOptsTypes.getStr(divisionOptsTypes.TECH) }
];

export { divisionOptsTypes, divisionOpts };
