import React from 'react'
import styles from './emptyState.module.scss'

const EmptyState = () => {

  return (
    <>
      <div className={styles.container}>
        <div className={styles.image}>
          <img src='/Empty-State.png' alt='empty state' />
        </div>
        <div className={styles.content}>
          <h2>Konten Kosong</h2>
          <p>Pilih filter produk dan tipe untuk melihat konten yang relevan</p>
        </div>
      </div>
    </>
  )
}

export default EmptyState
