import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../../services/businessesApi';
import HookTextField from '../../../../../components/Forms/HookTextField';
import { numberRegExp } from '../../../../../utils/constants/regexTypes';
import SelectField from '../../../../../components/Forms/SelectField';
import provinceList from '../../../../../utils/helpers/provinceList';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import CitySelect from '../../../../../components/Select/CitySelect';
import { BUSINESS_MODULE, BUSINESS_ADMIN_VERIFY_MODULE } from '../../../../../utils/constants/actionTypes';
import UploadPhotoTemplate from '../UploadPhotoOutlet';

function MerchantOutlet({ item, module, onSuccess = () => { }, readOnly }) {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false);

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: item.email,
      phoneNumber: item.phoneNumber?.replace(/\+62/g, ""),
      address: item.address,
      landmark: item.landmark,
      province: item.province,
      city: item.city,
      postalCode: item.postalCode,
      latitude: item.location?.coordinates[0],
      longitude: item.location?.coordinates[1],
      subDistrict: item.subDistrict,
      urbanVillage: item.urbanVillage,
    }
  });

  const { province, city } = watch(['province', 'city']);

  const onSubmit = (values) => {
    const data = {
      ...values,
      phoneNumber: '+62' + String(values.phoneNumber),
      _id: item._id,
      location: {
        type: "Point",
        coordinates: [values.latitude, values.longitude]
      }
    }

    setIsLoading(true);
    dispatch({ type: null, call: businessesApi.updateContact, args: [item._id, data] })
      .then(() => {
        if (isMounted()) handleSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  };

  useEffect(() => {
    register("province");
    register("city");

    return () => {
      unregister("province");
      unregister("city");
    };
  }, [register, unregister]);

  return (
    <>
      {
        (
          module === BUSINESS_MODULE ||
          // module === BUSINESS_ADMIN_VERIFY_MODULE ||
          module === BUSINESS_ADMIN_VERIFY_MODULE
        )
        &&
        <>
          <button
            className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ?
              <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
              <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
            }
          </button>
          <br /><br />
        </>
      }
      <div className="row">
        <div className="col-12 col-xl-4">
          <UploadPhotoTemplate
            label="Foto Outlet Dari Luar"
            id={item._id}
            call={businessesApi.uploadPhotoOutlet}
            onSuccess={onSuccess}
            previousImageUrl={item?.photoOutlet?.url}
          />
        </div>
        <div className="col-12 col-xl-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              prepend="+62"
              ref={register}
              label="Nomor Telepon Outlet"
              name="phoneNumber"
              error={errors.phoneNumber}
              helperText={errors.phoneNumber?.message ?? null}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Email"
              name="email"
              error={errors.email}
              helperText={errors.email?.message ?? null}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Alamat Lengkap Outlet"
              name="address"
              error={errors.address}
              helperText={errors.address?.message ?? null}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Patokan Lokasi (Opsional)"
              name="landmark"
              error={errors.landmark}
              helperText={errors.landmark?.message ?? null}
            />
            <SelectField
              isDisabled={isEditing ? !isEditing : readOnly}
              onChange={v => setValue('province', v?.value ?? null, { shouldValidate: true })}
              options={provinceList}
              label="Provinsi"
              value={province}
              error={errors.province}
              helperText={errors.province?.message ?? null}
              componentProps={{
                isClearable: true
              }}
            />
            <CitySelect
              disabled={isEditing ? !isEditing : readOnly}
              label="Kota"
              onChange={v => setValue('city', v?.value ?? null, { shouldValidate: true })}
              value={city}
              error={errors.city}
              helperText={errors.city?.message ?? null}
              componentProps={{
                placeholder: 'Select City',
              }}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Kode Pos"
              name="postalCode"
              error={errors.postalCode}
              helperText={errors.postalCode?.message ?? null}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Kecamatan"
              name="subDistrict"
              error={errors.subDistrict}
              helperText={errors.subDistrict?.message ?? null}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Kelurahan"
              name="urbanVillage"
              error={errors.urbanVillage}
              helperText={errors.urbanVillage?.message ?? null}
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Latitude Coordinate"
              name="latitude"
              error={errors.latitude}
              helperText={errors.latitude?.message ?? null}
              placeholder="e.g: -6.2253492"
            />
            <HookTextField
              readOnly={isEditing ? !isEditing : readOnly}
              ref={register}
              label="Longitude Coordinate"
              name="longitude"
              error={errors.longitude}
              helperText={errors.longitude?.message ?? null}
              placeholder="e.g: 106.828202"
            />
            {
              (
                module === BUSINESS_MODULE ||
                module === BUSINESS_ADMIN_VERIFY_MODULE
              )
              &&
              <LoadingBtn type="submit" disabled={isEditing ? !isEditing : readOnly} loading={isLoading} className="btn btn-primary btn-lg mr-2">Update</LoadingBtn>
            }
            {
              isEditing
              &&
              <LoadingBtn type="button" onClick={() => setIsEditing(false)} className="btn btn-danger btn-lg">Cancel</LoadingBtn>
            }
          </form>
        </div>
      </div>
    </>
  )
}

const schema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  phoneNumber: yup.string().required('Phone Number is required').matches(numberRegExp, 'Format is invalid. e.g: +628xxxxxxxxxx').min(5, 'Minimum length 5 digits'),
  address: yup.string().required('Address is required'),
  landmark: yup.string(),
  province: yup.string().required('Province is required').nullable(),
  city: yup.string().required('City is required').nullable(),
  postalCode: yup.number().typeError('Must be a number').required('Postal Code is required'),
  latitude: yup.number().typeError('Must be a number').required('Latitude is required'),
  longitude: yup.number().typeError('Must be a number').required('Longitude is required'),
  subDistrict: yup.string().required('Sub-District is required'),
  urbanVillage: yup.string().required('Urban-Village is required'),
});

export default MerchantOutlet
