import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MyForm from './Form'
import { CREATE, REFERRAL_MODULE } from '../../../utils/constants/actionTypes';
import useMountedState from '../../../components/HooksUse/useMountedState';
import { businessReferralApi } from '../../../services/businessReferralApi';

const CreateTab = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState()

  const onSubmit = (values) => {
    setIsLoading(true)
    dispatch({
      module: REFERRAL_MODULE,
      type: CREATE,
      call: businessReferralApi.create,
      args: [
        {
          ...values,
          isActive: values.isActive === "ACTIVE" ? true : false,
          isQuotaEnabled: values.isQuotaEnabled === "1" ? true : false,
        }
      ]
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <MyForm
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
        </MyForm>
      </div>
    </div>
  )
}

export default CreateTab
