import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bankApi } from '../../../services/bankApi';
import { UPDATE, BANK_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import Form from './Form';

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.bank.tabs[index]);

  const {
    value: { data: { bank: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: BANK_MODULE,
      type: GET_DETAIL,
      call: bankApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const defaultValues = {
    name: item?.name ?? '',
    code: item?.code ?? '',
    abbr: item?.abbr ?? '',
    artajasaCode: item?.artajasaCode ? { label: item.artajasaCode, value: item.artajasaCode } : null,
    prevImageUrl: item?.logo?.url,
  }

  const submit = useCallback((values) => {
    const data = {
      ...values,
      artajasaCode: values.artajasaCode.value
    }

    dispatch({
      module: BANK_MODULE,
      type: UPDATE,
      call: bankApi.update,
      args: [key, data],
      tabKey: index
    }).catch(() => { })
  }, [dispatch, key, index])

  return loading && !item ?
    null
    :
    item ?
      <div className="main-card mb-3 card">
        <div className="card-body">
          <Form
            submit={submit}
            defaultValues={defaultValues}
            isLoading={loading}
          />
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default UpdateTab
