import { format } from 'date-fns'
import React from 'react'
import PopUp from '../../PopUp'
import Timeline from '../../VerticalTimeline/Timeline';
import { combineBy } from '../../../utils/helpers/combineBy';
import { StatusHistoryCard } from './StatusHistoryCard';

const StatusHistory = ({
  show,
  setShow,
  statusHistories,
  warningType,
  successType,
  dangerType,
  mutateStatus
}) => {
  const generateActor = (value) => {
    return (
      <b className="d-block text-black-50 mt-3">
        Actor: {value?.actor?.uniqueId ? `${value.actor.uniqueId} | ${value.actor.firstName}` : `${value?.actor?.email} | ${combineBy([value.actor?.firstName, value.actor?.lastName])}`} [{value.actorModel} ]
      </b>
    )
  }

  return (
    <div>
      <PopUp
        in={show}
        onClose={() => setShow(false)}
      >
        <StatusHistoryCard
          title="Status History"
          withFooter={<Footer onClick={() => setShow(false)} />}
        >
          {statusHistories.map((s, k) => {
            return (
              <Timeline.Dot
                key={k}
                title={s.status}
                date={format(new Date(s.createdAt), 'dd MMM HH:mm:ss')}
                warningType={warningType}
                successType={successType}
                dangerType={dangerType}
                mutateStatus={mutateStatus}
                desc={generateActor(s)}
              />
            )
          })}
        </StatusHistoryCard>
      </PopUp>
    </div>
  )
}

const Footer = ({ onClick }) => (
  <div className="d-flex justify-content-end w-100">
    <button onClick={onClick} className="btn btn-primary">Close</button>
  </div>
)

export default StatusHistory
