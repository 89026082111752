import { combineReducers } from 'redux';
import cashback from './cashback';
import partner from './partner';
import mdr from './mdr';
import partnerFee from './partnerFee';
import PPOB from './PPOB';
import qrisMPM from './qrisMPM';
import qrisCPM from './qrisCPM';
import qrisTuntas from './qrisTuntas';
import refunds from './refunds';
import reward from './reward';
import topupTransferPartner from './topupTransferPartner';

export default combineReducers({
  cashback,
  mdr,
  partner,
  partnerFee,
  PPOB,
  qrisMPM,
  qrisCPM,
  qrisTuntas,
  refunds,
  reward,
  topupTransferPartner
});
