import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const partnerUserApi = {
  list: ({ page, limit, sort }) => {
    let url = `/partners/user-list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  }
}
