import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../../components/Resource";
import { TRANSFER_DIPAY_MODULE } from "../../../../utils/constants/actionTypes";
import { combineBy } from "../../../../utils/helpers/combineBy";
import { DATE_PICKER, PARTNER_SELECT, SELECT_FIELD, TEXT_FIELD, USER_SELECT } from "../../../../utils/constants/inputTypes";
import { toIDR } from "../../../../utils/helpers/currency";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import DetailTab from "./DetailTab";
import { transferEvyApi } from "../../../../services/transferEvyApi";
import KYCStatusOpts from '../../../../utils/constants/enums/status/KYCStatusOpts';
import stringToTitle from "../../../../utils/helpers/stringToTitle";

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalAmount
  } = useSelector(state => state.transfer.transferEvy);

  const getTransferDebounced = useMemo(
    () => AwesomeDebouncePromise(transferEvyApi.list, 500),
    [],
  )

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    { title: 'Trans. Code', key: 'code', name: 'code', sortable: false },
    { title: 'Partner', key: 'partner', name: 'partner', sortable: false, render: (_, row) => `${stringToTitle(row?.metadata?.clientData?.client) ?? '-'}` },
    {
      title: 'Sender', key: 'sender', name: 'sender', sortable: false, render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}`
    },
    {
      title: 'Receiver', key: 'recipient', name: 'recipient', sortable: false, render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}`
    },
    { title: 'Amount', key: 'amount', name: 'amount', render: v => v.primary ? toIDR(v.primary, true) : v.point }
  ];

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Trans. Code', key: 'code' },
    { title: 'Partner', key: 'partner', name: 'partner', sortable: false, render: (_, row) => `${row?.metadata?.clientData?.client} ?? '-`  },
    { title: 'Sender', key: 'sender', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Receiver', key: 'recipient', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Amount', key: 'amount.primary', render: (v) => toIDR(v, false) }
  ], [])

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.transactionCode ? `code|${filter.transactionCode}` : false
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const senderId = filter.sender?.value ?? null;
    const recipientId = filter.recipient?.value ?? null;
    const statusRecipient = filter.statusRecipient ?? null;
    const partner = filter.partner ?? null;

    return {
      page: currentPage,
      limit,
      sort,
      startDate,
      endDate,
      search,
      senderId,
      recipientId,
      statusRecipient,
      partner
    }
  }, [filter, currentPage, limit, sort])

  return (
    <div>
      <Resource
        title="Transfer Dipay"
        subTitle="Full Transfer Dipay list."
        icon="pe-7s-ribbon"
        list={{
          columns: columns,
          reducerPath: "transfer.transferEvy",
          call: getTransferDebounced,
          apiQuery: apiQuery,
          module: TRANSFER_DIPAY_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transfer_Evy_List',
            apiResponseKey: 'data.transfers',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Transaction Code",
              type: TEXT_FIELD,
              key: "transactionCode",
              value: filter.transactionCode,
              placeholder: "Search code"
            },
            {
              label: "Filter Connected Partner",
              type: PARTNER_SELECT,
              key: "partner",
              value: filter.partner || "",
              getOptionValue: v => {
                return v.optData.companyCode
              },
              filterIntegrationType: "COMPANY",
              filterByCompanyCode: true
            },
            {
              label: "Filter Sender",
              type: USER_SELECT,
              key: "sender",
              value: filter.sender
            },
            {
              label: "Filter Receiver",
              type: USER_SELECT,
              key: "recipient",
              value: filter.recipient
            },
            {
              label: "Filter Status Recipient",
              type: SELECT_FIELD,
              key: "statusRecipient",
              value: filter.statusRecipient,
              options: KYCStatusOpts,
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
