import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { merchantApi } from '../../../../services/merchantApi';
import { toIDR } from '../../../../utils/helpers/currency';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import PartnerSelect from '../../../../components/Select/PartnerSelect';

const MerchantInfo = ({ connectedPartner, merchant, item, onSuccess }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const currentType = item?.merchant?.status?.settlementType;
  const [selectedPartners, setSelectedPartners] = useState(connectedPartner);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [type, setType] = useState(currentType);

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      settlementType: type,
      companyCode: selectedPartners
    }

    setIsLoading(true);
    dispatch({
      type: null,
      call: merchantApi.changeAccountInfo,
      args: [item?.merchant?._id, data]
    }).then(() => {
      if (isMounted()) handleSuccess();
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  return (
    <div>
      <button
        className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
        onClick={() => setIsEditing(!isEditing)}
      >
        {isEditing ?
          <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
          <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
        }
      </button>
      <br /><br />
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12 col-xl-5">
            <h5 className="card-title mb-3">Balance</h5>
            <div className="form-group">
              <label className="text-grey mb-1">Primary Balance</label>
              <p className="mb-0 font-weight-semibold">{toIDR(merchant.balance.primary) ?? '-'}</p>
            </div>
            <div className="form-group">
              <label className="text-grey mb-1">Point Balance</label>
              <p className="mb-0 font-weight-semibold">{toIDR(merchant.balance.point) ?? '-'}</p>
            </div>
            {/* <div className="form-group">
              <label className="text-grey mb-1">Total Setllement Balance</label>
              <p className="mb-0 font-weight-semibold">{toIDR(Number(merchant.balance.primary) - Number(merchant.balance.mdr)) ?? '-'}</p>
            </div> */}
            <br />
            <h5 className="card-title mb-3">Settlement Type</h5>
            <RadioGroup
              row
              name="settlementType"
              value={type}
              onChange={e => setType(e.target.value)}
            >
              <FormControlLabel
                value="Auto"
                control={
                  <Radio
                    color="primary"
                    disabled={!isEditing}
                  />
                }
                label="Auto"
              />
              <FormControlLabel
                value="Manual"
                control={
                  <Radio
                    color="primary"
                    disabled={!isEditing}
                  />
                }
                label="Manual"
              />
            </RadioGroup>
          </div>
          <div className="col-12 col-xl-7">
            <h5 className="card-title mb-3">Merchant Partner</h5>
            <PartnerSelect
              isMulti
              label=""
              disabled={!isEditing}
              value={selectedPartners}
              filterIntegrationType="COMPANY"
              onChange={v => {
                if (v?.length) {
                  setSelectedPartners(v?.map(j => j.optData.companyCode))
                } else {
                  setSelectedPartners([])
                }
              }}
              getOptionValue={v => v.optData.companyCode}
            />
          </div>
        </div>
        <br />
        {isEditing ?
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn
              type="submit"
              loading={isLoading}
              className="btn btn-primary btn-lg mr-2"
            >
              Update
            </LoadingBtn>
          </div>
          : null
        }
      </form>
    </div>
  )
}

export default MerchantInfo
