import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { IMAGE_FORMATS } from '../../../../utils/constants/actionTypes';
import HookImage from '../../../../components/Forms/HookImage';

const UploadPhotoTemplate = ({ id, label, onSuccess, previousImageUrl, call, readOnly }) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = (file) => {
    if (!IMAGE_FORMATS.includes(file.type)) return toast('Format Invalid', { type: "error" })

    setIsUpdating(true)
    dispatch({
      type: null,
      call: call,
      args: [id, file]
    }).then(() => onSuccess())
      .catch(() => { })
      .finally(() => setIsUpdating(false))
  }

  return (
    <div>
      <h5 className="card-title">{label}</h5>
      <HookImage
        onChange={handleChange}
        previousImageUrl={previousImageUrl}
        helperText={
          !readOnly &&
          <p>
            Format Didukung: ${IMAGE_FORMATS.join(', ')}<br />
            <b>Maximum upload file size: 1 MB.</b>
          </p>
        }
        readOnly={readOnly}
      />
      {isUpdating ?
        <div className="alert alert-info font-weight-bold">
          Menyimpan...
        </div>
        :
        <div className="alert alert-warning font-weight-bold">
          Gambar akan tersimpan otomatis ketika terjadi perubahan
        </div>
      }
    </div>
  )
}

export default UploadPhotoTemplate;
