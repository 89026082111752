import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import { ROUTE_ANNOUNCEMENT_BISNIS, ROUTE_ANNOUNCEMENT_USER } from "../utils/helpers/roleHelper";

import UserApps from '../pages/Announcement/UserApps'
import MerchantApps from '../pages/Announcement/MerchantApps'

export const announcement = {
  type: NAVMENU,
  name: 'Announcement',
  icon: 'pe-7s-headphones',
  children: [
    {
      type: NAVLINK,
      name: 'Dipay User App',
      icon: 'pe-7s-headphones',
      url: '/announcement/user-apps',
      routeTitle: ROUTE_ANNOUNCEMENT_USER,
      component: UserApps
    },
    {
      type: NAVLINK,
      name: 'Dipay Bisnis App',
      icon: 'pe-7s-news-paper',
      url: '/announcement/merchant-apps',
      routeTitle: ROUTE_ANNOUNCEMENT_BISNIS,
      component: MerchantApps
    },
  ]
}
