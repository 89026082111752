import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { AdminRoleOpts } from '../../../utils/constants/enums/adminRoles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import SelectField from '../../../components/Forms/SelectField';
import { passwordRegex } from '../../../utils/constants/regexTypes';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText, DialogTitle } from '@material-ui/core';

const schema = yup.object().shape({
  _id: yup.string().nullable(),
  firstName: yup.string().required(),
  lastName: yup.string(),
  email: yup.string().email().required(),
  phoneNumber: yup.number().required(),
  role: yup.string().required(),
  password: yup
    .string()
    .matches(passwordRegex, "Password must contain uppercase lowercase number and special symbol")
    .when('_id', (id, yup) => (!id ? yup.required('Password is required') : yup)),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Password confirmation not match')
    .when('_id', (id, yup) => (!id ? yup.required('Password is required') : yup))
    .when('password', (password, yup) => (password ? yup.required('Password is required') : yup))
});

const FormTab = ({
  initialValue,
  onSubmit,
  onDelete,
  isLoading
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValue
  });

  const {
    role
  } = watch(['role'])

  useEffect(() => {
    register('role')

    return () => {
      unregister('role')
    }
  }, [register, unregister])

  return (
    <>
      <div className="main-card mb-3 card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 col-md-3">
                <h5 className="card-title">General Information</h5>
              </div>
              <div className="col-12 col-md-9">
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="First name"
                  name="firstName"
                  error={errors.firstName}
                  helperText={errors.firstName?.message}
                  placeholder="e.g: Your First Name"
                />
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="Last name"
                  name="lastName"
                  error={errors.lastName}
                  helperText={errors.lastName?.message}
                  placeholder="e.g: Your Last Name"
                />
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="Email"
                  name="email"
                  error={errors.email}
                  helperText={errors.email?.message}
                  placeholder="e.g: example@email.com"
                />
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="Phone Number"
                  name="phoneNumber"
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  placeholder="e.g: +62xxxxxxxxxxx"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-3">
                <h5 className="card-title">Role</h5>
              </div>
              <div className="col-12 col-md-9">
                <SelectField
                  label="Select Role"
                  placeholder="Select Role"
                  options={AdminRoleOpts}
                  onChange={v => setValue('role', v.value, { shouldValidate: true })}
                  value={role}
                  error={errors.role}
                  helperText={errors.role?.message}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-3">
                <h5 className="card-title">Key</h5>
              </div>
              <div className="col-12 col-md-9">
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="Password"
                  name="password"
                  error={errors.password}
                  helperText={errors.password?.message}
                  placeholder="e.g: Your Password"
                  showPassword
                />
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  error={errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message}
                  placeholder="e.g: Your Password"
                  showPassword
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
              </div>
              <div className="col-12 col-md-9">
                <div className="d-flex flex-row-reverse flex-sm-row">
                  <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary mr-2">Submit</LoadingBtn>
                  {initialValue &&
                    <LoadingBtn
                      type="button"
                      className="btn btn-danger"
                      onClick={() => setOpenModal(true)}
                    >
                      Delete Admin
                    </LoadingBtn>
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">Delete Admin</DialogTitle>
        <DialogContent className="p-3">
          <DialogContentText>Are you sure want to delete this admin?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="default">
            Cancel
          </Button>
          <Button onClick={onDelete} color={'secondary'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FormTab
