import React, { useCallback, useMemo } from "react";
import { endOfDay, format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TRANSACTIONS_PPOB_MODULE } from "../../../utils/constants/actionTypes";
import DetailTab from "./DetailTab";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { PpobType, PpobTypeOpts } from '../../../utils/constants/enums/ppobTypes';
import OrderStatusOpts from '../../../utils/constants/enums/status/orderStatusOpts';
import { paymentApi } from "../../../services/paymentApi";
import { toIDR } from "../../../utils/helpers/currency";
import AllStatus from "../../../utils/constants/enums/status";
import startOfDay from "date-fns/startOfDay";
import { combineBy } from "../../../utils/helpers/combineBy";
import useAsync from "../../../components/HooksUse/useAsync";
import { ppobApi } from "../../../services/ppobApi";
import { DATE_PICKER, PARTNER_SELECT, SELECT_FIELD, TEXT_FIELD, USER_SELECT } from "../../../utils/constants/inputTypes";

const Index = () => {
  const dispatch = useDispatch();

  const getBillers = useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.getBillers
    }),
    [dispatch]
  )
  const { value: { data: billerOpts = [] } = {}, pending: gettingBillers } = useAsync(getBillers, true);
  const billerOptions = useMemo(() => billerOpts.map(row => ({ label: row, value: row })) ?? [], [billerOpts]);

  const {
    currentPage,
    limit,
    sort,
    filter,
    totalAmount,
    totalRevenue
  } = useSelector(state => state.transactions.PPOB);

  const getPaymentDebounced = useMemo(
    () => AwesomeDebouncePromise(paymentApi.list, 500),
    [],
  )

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
      }
    },
    {
      title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: (v, row) => (
        v ? `${combineBy([v.firstName, v.lastName], ' ')} | ${v.uniqueId}` : '(Closed Account)'
      )
    },
    { title: 'Amount', key: 'amount', name: 'amount', render: (v, row) => row.metadata.point > 0 ? v : toIDR(v) },
    { title: 'Biller', key: 'biller', name: 'biller', render: (v, row) => row.metadata.biller },

    { title: 'Provider', key: 'provider', name: 'provider' },
    { title: 'Wallet', key: 'wallet', name: 'wallet', render: (v, row) => row.metadata.primary > 0 ? 'PRIMARY' : row.metadata.point > 0 ? 'POINT' : '-' },
    {
      title: 'Type', key: 'type', name: 'type', render: (v, row) => PpobType.getStrIdn(row.metadata.ppob.type)
    },
    {
      title: 'Transaction Code', key: 'code', name: 'transactionCode', render: (v, row) => v
    },
    { title: 'Status', key: 'status', name: 'status' }
  ];

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm') },
    {
      title: 'Applicant', key: 'applicant', render: v => {
        return v ? `${combineBy([v.firstName, v.lastName], ' ')} | ${v.uniqueId}` : '(Closed Account)'
      }
    },
    { title: 'Amount', key: 'amount', render: (v) => toIDR(v, false) },
    { title: 'Biller', key: 'biller', render: (v, row) => row.metadata.biller },
    { title: 'Provider', key: 'provider', name: 'provider' },
    { title: 'Wallet', key: 'wallet', render: (v, row) => row.metadata.primary > 0 ? 'PRIMARY' : row.metadata.point > 0 ? 'POINT' : '-' },
    {
      title: 'Type', key: 'type', render: (v, row) => PpobType.getStrIdn(row.metadata.ppob.type)
    },
    {
      title: 'Transaction Code', key: 'code', render: (v, row) => v
    },
    { title: 'Status', key: 'status', render: v => AllStatus.getStr(v) }
  ], [])

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.user && typeof filter.user === 'object' ? `applicantModel|User,applicant|${filter.user.value}` : false,
      filter.transactionCode ? `code|${filter.transactionCode}` : false
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    return {
      page: currentPage,
      limit,
      sort,
      type: filter.type,
      search,
      startDate,
      endDate,
      status: filter.status,
      ppobType: filter.ppobType,
      biller: filter.biller,
      partner: filter.partner
    }
  }, [filter, currentPage, limit, sort])

  return (
    <div>
      <Resource
        title="Transactions PPOB"
        subTitle="Full Transactions PPOB list."
        icon="pe-7s-ribbon"
        list={{
          columns: columns,
          reducerPath: "transactions.PPOB",
          call: getPaymentDebounced,
          apiQuery: apiQuery,
          module: TRANSACTIONS_PPOB_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Revenue: <b>{toIDR(totalRevenue)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transactions_PPOB_List',
            apiResponseKey: 'data.payments',
            queryParams: apiQuery
          },
          customActionColumns: [
            {
              role: {
                key: "status",
                value: AllStatus.PROCESSING
              },
              title: <i className="lnr-history btn-icon-wrapper"></i>,
              action: {
                call: ppobApi.checkStatus,
                args: { model: 'payment' }
              }
            },
            {
              role: {
                key: "status",
                value: AllStatus.PENDING
              },
              title: <i className="lnr-history btn-icon-wrapper"></i>,
              action: {
                call: ppobApi.checkStatus,
                args: { model: 'payment' }
              }
            }
          ],
          filters: [
            {
              label: "Filter PPOB Type",
              type: SELECT_FIELD,
              key: "ppobType",
              options: PpobTypeOpts,
              value: filter.ppobType,
            },
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: OrderStatusOpts,
              value: filter.status,
            },
            {
              label: "Filter Biller",
              type: SELECT_FIELD,
              key: "biller",
              options: billerOptions,
              value: filter.biller,
              loading: gettingBillers
            },
            {
              type: USER_SELECT,
              key: "user",
              value: filter.user
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
            {
              label: "Filter Connected Partner",
              role: filter.ppobType === PpobType.DIRECT_INTEGRATE,
              type: PARTNER_SELECT,
              key: "partner",
              value: filter.partner,
              filterIntegrationType: "COMPANY"
            },
            {
              label: "Filter Transaction Code",
              type: TEXT_FIELD,
              key: "transactionCode",
              value: filter.transactionCode,
              placeholder: "Search Code"
            }
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
