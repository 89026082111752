import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { transactionApi } from '../../../../services/transactionApi'
import { AllTransactionType, TransactionType } from '../../../../utils/constants/enums/transactionTypes'
import { toIDR } from '../../../../utils/helpers/currency'
import useAsync from '../../../../components/HooksUse/useAsync'
import PopUp from '../../../../components/PopUp'
import { HistoryCard, HistoryCardList } from '../../../../components/templates/HistoryDialog/HistoryCard'

const HistoryChanges = ({ show, setShow, userId }) => {
  const dispatch = useDispatch()
  const {
    execute: getTransaction,
    value: { data: { transactions } = {} } = {},
    pending: loading,
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: transactionApi.list,
      args: [
        0,
        5,
        'createdAt|desc',
        AllTransactionType,
        userId ? `sender|${userId},recipient|${userId}` : null
      ]
    }),
    [dispatch, userId]))

  useEffect(() => {
    if (show) getTransaction()
  }, [show, getTransaction])

  return (
    <div>
      <PopUp
        in={show}
        onClose={() => setShow(false)}
      >
        <HistoryCard
          title="Last 5 Transactions History"
          withFooter={<Footer onClick={() => setShow(false)} />}
          isLoading={loading}
        >
          {
            transactions?.map((el, i) => (
              <HistoryCardList
                key={i}
                content={<Content item={el} />}
                date={format(new Date(el.createdAt), 'dd MMM HH:mm:ss')}
              />
            ))
          }
        </HistoryCard>
      </PopUp>
    </div>
  )
}

const Content = ({ item }) => {
  return (
    <div>
      {
        <div className="mb-2">
          <div><span className="font-weight-bold">{TransactionType.getStr(item.type)}</span></div>
          <div className="text-muted">{toIDR(item.amount.primary)}</div>
        </div>
      }
    </div>
  )
}

const Footer = ({ onClick }) => (
  <div className="d-flex justify-content-end w-100">
    <button onClick={onClick} className="btn btn-primary">Close</button>
  </div>
)

export default HistoryChanges
