import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { rejectReasonApi } from '../../../services/rejectReasonApi';
import { UPDATE, REJECT_REASON_MODULE } from '../../../utils/constants/actionTypes';
import Form from './Form';

const UpdateTab = ({ id, index }) => {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.rejectReason);
  const selectedItem = items.find(row => row._id === id);
  const [isLoading, setIsLoading] = useState(false);

  const submit = useCallback((values) => {
    setIsLoading(true);
    dispatch({
      module: REJECT_REASON_MODULE,
      type: UPDATE,
      call: rejectReasonApi.update,
      args: [
        id,
        {
          ...values,
          type: values.category
        }
      ],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }, [dispatch, index, id])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        {selectedItem ?
          <Form
            submit={submit}
            initialValue={selectedItem}
            isLoading={isLoading}
          />
          :
          <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
        }
      </div>
    </div>
  )
}

export default UpdateTab
