import React from 'react';
import { Tabs, Tab } from '../../../components/Tabs/Tabs';
import { useSelector, useDispatch } from 'react-redux';
import MainTab from './MainTab';
import { CLOSE_TAB, CHANGE_TAB, REJECT_REASON_MODULE } from '../../../utils/constants/actionTypes';
import { Helmet } from 'react-helmet';
import CreateTab from './CreateTab';
import UpdateTab from './UpdateTab';

const Index = () => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => state.rejectReason);

  const changeTab = type => key => dispatch({ module: REJECT_REASON_MODULE, type: type, key: key })

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Reject Reason</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-display2 icon-gradient bg-sunny-morning"></i>
            </div>
            <div>
              Reject Reasons
              <div className="page-title-subheading">Dipay system reject reasons templates.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={changeTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
        <Tab title="Create">
          <CreateTab />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => changeTab(CLOSE_TAB)(row.key)}
            onClick={() => changeTab(CHANGE_TAB)(row.key)}
            active={activeTabKey === row.key}
          >
            <UpdateTab id={row.key} index={key} />
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default Index;
