class WorkingOptsTypes {
  static ON_SITE = "ON_SITE";
  static HYBRID = "HYBRID";
  static REMOTE = "REMOTE";

  static getStr(en) {
    switch (en) {
      case WorkingOptsTypes.ON_SITE:
        return "On-Site";
      case WorkingOptsTypes.HYBRID:
        return "Hybrid";
      case WorkingOptsTypes.REMOTE:
        return "Remote";
      default:
        return "Unknown";
    }
  }
}

const WorkingOpts = [
  { value: WorkingOptsTypes.ON_SITE, label: WorkingOptsTypes.getStr(WorkingOptsTypes.ON_SITE) },
  { value: WorkingOptsTypes.HYBRID, label: WorkingOptsTypes.getStr(WorkingOptsTypes.HYBRID) },
  { value: WorkingOptsTypes.REMOTE, label: WorkingOptsTypes.getStr(WorkingOptsTypes.REMOTE) },
];

export { WorkingOptsTypes, WorkingOpts }
