import agent from "./agent";
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

export const qrisRintisApi = {
  getQrisFile: ({ type, date }) => {
    let url = `/rintis/get-rpt-qris-file`;
    if (type) {
      url += `?type=${type}`;
    }
    if (date) {
      url += `&date=${date}`
    }
    return agent.requests.get(url);
  },
  getQrisRecon: ({ date }) => {
    let url = `/rintis/rpt-recon`;
    if (date) {
      url += `?rptDate=${date}`;
    }
    return agent.requests.get(url);
  },
  getQrisRekap: ({ type, date }) => {
    let url = `/rintis/rpt-rekap`;
    if (type) {
      url += `?type=${type}`;
    }
    if (date) {
      url += `&date=${date}`
    }
    return agent.requests.get(url);
  },
  reconnectRintis: () => agent.requests.get('/rintis/mount-sftp'),
  nginxReconnectRintis: () => superagent.get('https://sftprintis.dipay.id')
};
