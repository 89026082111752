import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const deductionApi = {
  list: ({ page, limit, sort, search, startDate, endDate, status }) => {
    let url = `/deductions/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    return agent.requests.get(url)
  },
  inject: data =>
    agent.requests.post(`/deductions/create`, data),
  approve: id =>
    agent.requests.post(`/deductions/${id}/approve`),
  reject: (id) =>
    agent.requests.post(`/deductions/${id}/reject`),
  detail: (id) =>
    agent.requests.get(`/deductions/${id}/detail`),
}
