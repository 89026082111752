import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import HookTextField from "../../../components/Forms/HookTextField";
import SelectField from "../../../components/Forms/SelectField";
import LoadingBtn from "../../../components/Forms/LoadingBtn";
import { switcherMerchantTypesOpts } from "../../../utils/constants/enums/switcherMerchantTypes";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const schema = yup.object().shape({
  bankName: yup.string().required(),
  codeBankOrPJP: yup.string().required(),
  codeNNS: yup.string().required(),
  refundTransaction: yup.boolean().required(),
  switcher: yup.string().required(),
});

const Form = ({ initialValues, onSubmit, isLoading, errorExistingValue }) => {
  const { register, unregister, watch, setValue, handleSubmit, errors } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues: {
        ...initialValues,
      },
    });

  const { switcher, refundTransaction } = watch(["switcher", "refundTransaction"]);

  useEffect(() => {
    register("refundTransaction");
    register("switcher");

    return () => {
      unregister("refundTransaction");
      unregister("switcher");
    };
  }, [register, unregister]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">NNS Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Code Bank"
            name="codeBankOrPJP"
            placeholder="e.g: MANDIRI"
            error={errors.codeBankOrPJP}
            helperText={errors.codeBankOrPJP ? errors.codeBankOrPJP.message : null}
          />
          <HookTextField
            ref={register}
            label="Bank Name"
            name="bankName"
            placeholder="e.g: Bank Danamon"
            error={errors.bankName}
            helperText={errors.bankName ? errors.bankName.message : null}
          />
          <HookTextField
            ref={register}
            label="Code NNS"
            name="codeNNS"
            placeholder="e.g: 93600002"
            error={errors.codeNNS}
            helperText={errors.codeNNS ? errors.codeNNS.message : null}
          />
          <SelectField
            label="Select Switcher"
            name="switcher"
            placeholder="Select Switcher"
            options={switcherMerchantTypesOpts}
            onChange={(v) =>
              setValue("switcher", v?.value, { shouldValidate: true })
            }
            value={switcher}
            error={errors.switcher || errorExistingValue}
          />
          <div>
            <label>Refund Transaction</label>
            <RadioGroup row name="refundTransaction" value={refundTransaction?.toString()} onChange={e => setValue('refundTransaction', e.target.value)}>
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Refundable" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="Non-Refundable" />
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3"></div>
        <div className="col-12 col-md-9">
          <LoadingBtn
            type="submit"
            loading={isLoading}
            className="btn btn-primary"
          >
            Submit
          </LoadingBtn>
        </div>
      </div>
    </form>
  );
};

export default Form;
