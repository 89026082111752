import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bankTransferFeeApi } from '../../../services/bankTransferFeeApi'
import { BANK_TRANSFER_FEE_MODULE, GET_DETAIL, UPDATE } from '../../../utils/constants/actionTypes'
import useAsync from '../../../components/HooksUse/useAsync'
import Form from './Form'

const UpdateTab = ({ index, tabType }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.billers.bankTransferFee.tabs[index]);

  const {
    value: { data: { bankTransferConfig: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: BANK_TRANSFER_FEE_MODULE,
      type: GET_DETAIL,
      call: bankTransferFeeApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const defaultValues = {
    name: item?.name ?? '',
    revenue: item?.revenue ?? '',
    billerFee: item?.billerFee ?? '',
    adminFee: item?.adminFee ?? '',
    status: item?.status ?? '',
  }

  const onSubmit = (data) => {
    const value = {
      ...data,
      revenue: data.adminFee - data.billerFee
    }
    dispatch({
      module: BANK_TRANSFER_FEE_MODULE,
      type: UPDATE,
      call: bankTransferFeeApi.update,
      args: [key, value],
      tabKey: index
    })
      .catch(() => { })
  }

  return loading && !item ?
    null
    :
    item ?
      <div className="main-card mb-3 card">
        <div className="card-body">
          <Form
            isLoading={loading}
            onSubmit={onSubmit}
            initialValues={defaultValues}
            tabType={tabType}
          />
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default UpdateTab
