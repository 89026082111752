import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getNetRevenue = (gross, referral) => {
  return !(gross - referral) ? 0 : gross - referral;
};

const getWalletType = (obj) => {
  for (let key in obj) {
    if (obj[key] > 0) return key
  }
}

const TableMFIndofund = ({ items, period }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let sumHargaBiller = 0;
    let sumReferral = 0;
    let sumRevenue = 0;
    let sumNetRevenue = 0;
    let sumTotal = 0;

    items.forEach(v => {
      sumHargaBiller += +v.metadata?.payment?.metadata?.ppob?.price || 0;
      sumReferral += +v.metadata?.payment?.metadata?.ppob?.referralBonus || 0;
      sumRevenue += +v.metadata?.payment?.metadata?.ppob?.revenue || 0;
      sumNetRevenue += (+v.metadata?.payment?.metadata?.ppob?.revenue || 0) - (+v.metadata?.payment?.metadata?.ppob?.referralBonus || 0);
      sumTotal += +v.metadata?.payment?.amount || 0;
    });

    amountArr = [
      { value: sumHargaBiller },
      { value: sumReferral },
      { value: sumRevenue },
      { value: sumNetRevenue },
      { value: sumTotal }
    ]
    return amountArr;
  }, [items]);

  const columns = useMemo(() => {
    return [
      { title: "Date", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Trans .No", key: "code", name: "code" },
      { title: "Type", key: "type", name: "type", render: (v, row) => PpobType.getStrIdn(row.metadata.payment.metadata.ppob.type) },
      { title: "Nama", key: "name", name: "name", render: (v, row) => combineBy([row.sender?.firstName, row.sender?.lastName]) },
      { title: "Payment", key: "payment", name: "payment", render: (v, row) => row.metadata?.payment?.metadata?.ppobResponse?.msisdn ?? '-' },
      { title: "Wallet", key: "amount", name: "amount", render: (v, row) => getWalletType(v) },

      { title: "Harga Biller", key: "revenue", withCurrency: true, name: "revenue", render: (v, row) => row.metadata?.payment?.metadata?.ppob?.price ? row.metadata.payment.metadata.ppob.price : 0 },
      { title: "Referral", key: "revenue", withCurrency: true, name: "revenue", render: (v, row) => row.metadata?.payment?.metadata?.ppob?.referralBonus ? row.metadata.payment.metadata.ppob.referralBonus : 0 },
      { title: "Gross Revenue", key: "grossRevenue", withCurrency: true, name: "grossRevenue", render: (v, row) => row.metadata?.payment?.metadata?.ppob?.revenue ? Number(row.metadata.payment.metadata.ppob.revenue) : 0 },
      { title: "Net Revenue", key: "netRevenue", withCurrency: true, name: "netRevenue", render: (v, row) => getNetRevenue((row.metadata?.payment?.metadata?.ppob?.revenue ?? 0), (row.metadata?.payment?.metadata?.ppob?.referralBonus ?? 0)) },
      { title: "Total", key: "total", withCurrency: true, name: "total", render: (v, row) => row.metadata?.payment?.amount ? row.metadata.payment.amount : 0 }
    ]
  }, [])

  return (
    <ReportTableTemplateV2
      title="Biller Multifinance Indofund"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableMFIndofund
