import React, { Children, cloneElement } from 'react';
import styles from "./tabs.module.scss";
import { Transition } from 'react-transition-group';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const duration = 100;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateY(-5px)' },
  entered: { opacity: 1 },
  exiting: { opacity: 0, transform: 'translateY(5px)', position: 'absolute', top: 0, left: 0, width: '100%', height: 0 },
}

export const Tabs = ({ onClick, children, activeKey }) => {
  return (
    <div className={styles.tabsRoot}>
      <nav className={styles.tabNav}>
        {Children.map(children, (child, i) =>
          child ?
            cloneElement(child, {
              index: i,
              onClick: child.props?.onClick ?? onClick,
              active: child.props?.active ?? activeKey === i,
            })
            :
            null
        )}
      </nav>
      <div className={styles.tabContent}>
        {Children.map(children, (child, i) =>
          child ?
            <Transition
              in={child.props?.active ?? activeKey === i}
              timeout={duration}
              appear={true}
              unmountOnExit
            >
              {state =>
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}>
                  {child.props.children}
                </div>
              }
            </Transition>
            :
            null
        )}
      </div>
    </div>
  )
}

export const Tab = ({
  onClick,
  title,
  index,
  active,
  isLoading,
  onClose
}) => {

  return (
    <div
      className={`${styles.tabWrapper} ${active ? ' ' + styles.active : ''}`}
    >
      <button
        onClick={() => onClick(index)}
        className={styles.tabTitle}
        title={title}
      >
        <p className={typeof onClose === 'function' ? styles.dynamic : ''}>
          {title}
        </p>
      </button>
      {typeof onClose === 'function' &&
        <button onClick={onClose} className={styles.tabAction}>
          <div className={`${styles.loadingCloseWrapper}${isLoading ? ' ' + styles.loading : ''}`}>
            {isLoading && <CircularProgress color="inherit" size={20} />}
            <div className={styles.closeIcon}>
              <CloseRoundedIcon color="inherit" fontSize="small" />
            </div>
          </div>
        </button>
      }
    </div>
  )
}
