import React, { useEffect } from 'react'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../components/Forms/HookTextField';
import SelectField from '../../components/Forms/SelectField';
import { IMAGE_FORMATS } from '../../utils/constants/actionTypes';
import HookImage from '../../components/Forms/HookImage';
import LoadingBtn from '../../components/Forms/LoadingBtn';
import activeStatusOpts from '../../utils/constants/enums/status/activeStatusOpts';
import EvyDatePicker from '../../components/Forms/EvyDatePicker';
import { endOfDay, format, startOfDay } from 'date-fns';
import TextEditor from "../../components/Forms/TextEditor";
import { productOpts, productOptsTypes } from '../../utils/constants/enums/productOptsTypes';
import { articleCategoryOpts } from '../../utils/constants/enums/articleCategoryOptsTypes';
import { UserAppOpts, UserAppTypes } from '../../utils/constants/enums/userAppTypes';
import AllStatus from '../../utils/constants/enums/status';

const schema = yup.object().shape({
  application: yup.string().required(),
  type: yup.string().required(),
  category: yup.string()
    .when('application', {
      is: application => application === productOptsTypes.WEBSITE,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
  name: yup.string().required(),
  description: yup.string().required(),
  content: yup.string().required(),
  eventDate: yup.string(),
  active: yup.string().required(),
  isAlwaysActive: yup.string().required(),
  periodFrom: yup.string(),
  periodTo: yup.string(),
  hasData: yup.string(),
  action: yup.string(),
  attachment: yup.mixed()
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),
});

const Form = ({ initialValues, onSubmit, previousImageUrl, isLoading }) => {
  const { register, unregister, setValue, handleSubmit, formState: { errors }, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      application: initialValues?.application ?? "",
      type: initialValues?.type ?? "",
      category: initialValues?.category ?? "",
      name: initialValues?.name,
      periodFrom: initialValues?.periodFrom ? new Date(initialValues.periodFrom) : format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      periodTo: initialValues?.periodTo ? new Date(initialValues.periodTo) : format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      eventDate: initialValues?.eventDate ? new Date(initialValues.eventDate) : format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      description: initialValues?.description,
      content: initialValues?.content,
      active: initialValues?.active ? AllStatus.ACTIVE : AllStatus.INACTIVE,
      isAlwaysActive: initialValues?.isAlwaysActive ? 'YES' : 'NO',

    }
  });

  const {
    application,
    type,
    category,
    content,
    eventDate,
    active,
    isAlwaysActive,
    periodFrom,
    periodTo,
  } = watch([
    'application',
    'type',
    'category',
    'content',
    'eventDate',
    'active',
    'isAlwaysActive',
    'periodFrom',
    'periodTo',
  ]);

  useEffect(() => {
    register("application")
    register("type")
    register("category")
    register("content")
    register("action")
    register('eventDate')
    register("active")
    register('isAlwaysActive')
    register('periodFrom')
    register('periodTo')
    return () => {
      unregister("application")
      unregister("type")
      unregister("category")
      unregister("content")
      unregister("action")
      unregister('eventDate')
      unregister("active")
      unregister('isAlwaysActive')
      unregister('periodFrom')
      unregister('periodTo')
    };
  }, [register, unregister]);

  const handleDateChange = field => date => {
    let v;
    if (field === 'periodFrom') {
      v = startOfDay(new Date(date));
    } else if (field === 'periodTo') {
      v = endOfDay(new Date(date));
    } else if (field === 'eventDate') {
      v = new Date(date);
    }
    setValue(field, format(new Date(v), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), { shouldValidate: true });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <SelectField
            label="Select Application"
            name="application"
            placeholder="Select Application"
            options={productOpts}
            onChange={(v) => {
              setValue("application", v?.value, { shouldValidate: true })
              if (v?.value === productOptsTypes.WEBSITE) {
                setValue("type", UserAppTypes.ARTICLE, { shouldValidate: true })
              }
            }}
            value={application}
            error={errors.application}
            helperText={errors.application?.message ?? null}
          />
          {application !== productOptsTypes.WEBSITE ?
            <SelectField
              label="Select Type"
              name="type"
              placeholder="Select Type"
              options={UserAppOpts}
              onChange={(v) => setValue("type", v?.value, { shouldValidate: true })}
              value={type}
              error={errors.type}
              helperText={errors.type?.message ?? null}
            />
            :
            null
          }
          {application === productOptsTypes.WEBSITE ?
            <SelectField
              label="Select Category"
              name="category"
              placeholder="Select Category"
              options={articleCategoryOpts}
              onChange={(v) => setValue("category", v?.value, { shouldValidate: true })}
              value={category}
              error={errors.category}
              helperText={errors.category?.message ?? null}
            />
            : null
          }
          <HookTextField
            ref={register}
            label="Name"
            name="name"
            error={errors.name}
            helperText={errors.name ? errors.name.message : null}
            placeholder="e.g: Cukup upgrade dapat 500 ribu"
          />
          <HookTextField
            ref={register}
            label="Description"
            name="description"
            error={errors.description}
            helperText={errors.description ? errors.description.message : null}
            multiline
          />
          <TextEditor
            label="Content"
            onChange={(v) => setValue("content", v, { shouldValidate: true })}
            helperText={errors.content?.message}
            error={Boolean(errors.content)}
            defaultValue={content}
          />
          <div>
            <label>Event Date</label>
            <EvyDatePicker
              value={eventDate}
              onChange={handleDateChange('eventDate')}
              variant="dialog"
              placeholder="Select date"
              clearable={false}
            />
          </div>
          <SelectField
            label="Set Active active"
            name="active"
            options={activeStatusOpts}
            onChange={v => setValue('active', v?.value, { shouldValidate: true })}
            value={active}
            error={errors.active}
            helperText={errors.active?.message ?? null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Period</h5>
        </div>
        <div className="col-12 col-md-9">
          <div>
            <SelectField
              label="Set Period Always on"
              name="isAlwaysActive"
              options={[
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
              ]}
              onChange={v => {
                setValue('isAlwaysActive', v?.value, { shouldValidate: true });
              }}
              value={isAlwaysActive}
              error={errors.isAlwaysActive}
              helperText={errors.isAlwaysActive?.message ?? null}
            />
          </div>
          {isAlwaysActive !== 'YES' ?
            <div className="date-range">
              <div>
                <label>From</label>
                <EvyDatePicker
                  value={periodFrom}
                  onChange={handleDateChange('periodFrom')}
                  variant="dialog"
                  placeholder="Select date"
                  clearable={false}
                  disableToolbar
                  disablePast
                />
              </div>
              <div>
                <label>To</label>
                <EvyDatePicker
                  value={periodTo}
                  onChange={handleDateChange('periodTo')}
                  margin="dense"
                  variant="dialog"
                  placeholder="Select date"
                  clearable={false}
                  disableToolbar
                  disablePast
                />
              </div>
            </div>
            : null
          }
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Cover</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookImage
            ref={register}
            previousImageUrl={previousImageUrl}
            name="attachment"
            format={IMAGE_FORMATS}
            error={errors.attachment}
            helperText={errors?.attachment?.message ?? null}
            onClear={() => setValue('attachment', null, { shouldValidate: true })}
            viewVariant="landscape"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Form
