import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MyForm from './Form'
import { VOUCHER_MODULE, CREATE } from '../../utils/constants/actionTypes';
import { REDEEM, CASHBACK } from '../../utils/constants/voucherTypes';
import useMountedState from '../../components/HooksUse/useMountedState';
import { voucherApi } from '../../services/voucherApi';

const CreateTab = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState()

  const onSubmit = (values) => {
    let data = {
      code: values.code,
      startAt: values.startAt,
      endAt: values.endAt,
      quantity: values.quantity,
      status: values.status ? values.status : '',
      attachment: values.attachment,
      tnc: {
        user: {
          upgrade: values.tnc.user.upgrade || ''
        },
        minTransaction: values.tnc.minTransaction,
        claimable: values.tnc.claimable,
      },
    }

    if (values.tnc.type === REDEEM) {
      data.tnc.redeem = values.tnc.redeem;
    } else if (values.tnc.type === CASHBACK) {
      data.tnc.cashback = values.tnc.cashback;
    }

    setIsLoading(true)
    dispatch({
      module: VOUCHER_MODULE,
      type: CREATE,
      call: voucherApi.create,
      args: [data]
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <MyForm
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
        </MyForm>
      </div>
    </div>
  )
}

export default CreateTab
