import Dashboard from "../pages/Dashboard";
import { NAVLINK } from "../utils/constants/dataTypes";
import { ROUTE_DASHBOARD } from "../utils/helpers/roleHelper";

export const dashboard = {
  type: NAVLINK,
  name: 'Dashboard',
  icon: 'pe-7s-display1',
  url: '/',
  exact: true,
  routeTitle: ROUTE_DASHBOARD,
  component: Dashboard
}
