import React, { Component } from 'react';
import AllStatus from '../../utils/constants/enums/status';

const Dot = (props) => {
  const { warningType, successType, dangerType } = props;
  const getColor = status => {
    let className = 'badge-primary';
    if (
      (warningType && status === warningType) ||
      status === AllStatus.PENDING ||
      status === AllStatus.SUSPENDED
    ) {
      className = 'badge-warning'
    } else if (
      (successType && status === successType) ||
      status === AllStatus.COMPLETED ||
      status === AllStatus.UNLOCKED ||
      status === AllStatus.UNBLACKLIST ||
      status === AllStatus.UNSUSPEND ||
      status === AllStatus.APPROVED ||
      status === AllStatus.VERIFIED
    ) {
      className = 'badge-success'
    } else if (
      (dangerType && status === dangerType) ||
      status === AllStatus.FAILED ||
      status === AllStatus.LOCKED ||
      status === AllStatus.BLACKLIST ||
      status === AllStatus.REJECTED ||
      status === AllStatus.REJECTED_VERIFY ||
      status === AllStatus.REJECT_UNBLACKLIST ||
      status === AllStatus.REJECT_UNLOCKED ||
      status === AllStatus.REJECT_UNSUSPEND
    ) {
      className = 'badge-danger'
    }
    return className
  }

  const getString = status => {
    const { mutateStatus } = props;
    let string = status;
    if (mutateStatus && status === AllStatus.FAILED) {
      string = 'Declined';
    }
    return string
  }

  return (
    <div className="vertical-timeline-item vertical-timeline-element">
      <div>
        <span className="vertical-timeline-element-icon">
          <i className={`badge badge-dot badge-dot-xl ${getColor(props.title)}`}> </i>
        </span>
        <div className="vertical-timeline-element-content">
          <h4 className="timeline-title">{AllStatus.getStrIdn(getString(props.title))}</h4>
          <p>{props.desc}</p>
          <span className="vertical-timeline-element-date">{props.date}</span>
        </div>
      </div>
    </div>
  )
}

class Timeline extends Component {
  static Dot = Dot;

  render() {
    return (
      <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
        {this.props.children}
      </div>
    )
  }
}

export default Timeline
