import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { INJECTION_MODULE } from "../../../utils/constants/actionTypes";
import { combineBy } from "../../../utils/helpers/combineBy";
import { DATE_PICKER, SELECT_FIELD, TEXT_FIELD, USER_SELECT, MERCHANT_SELECT } from "../../../utils/constants/inputTypes";
import { toIDR } from "../../../utils/helpers/currency";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import DetailTab from "./DetailTab";
import roleHelper, { CREATE_UPDATE_FORM } from "../../../utils/helpers/roleHelper";
import InjectTab from "./InjectTab";
import { injectionApi } from "../../../services/injectionApi";

const Index = () => {
  const { injection: { currentPage, sort, limit, filter }, admin } =
    useSelector(state => ({ injection: state.injection, admin: state.common.currentAdmin }));

  const getInjectionDebounced = useMemo(
    () => AwesomeDebouncePromise(injectionApi.list, 500),
    [],
  )

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    { title: 'Trans. Code', key: 'code', name: 'code' },
    { title: 'Applicant Model', key: 'applicantModel', name: 'applicantModel' },
    { title: 'Applicant', key: 'applicant', name: 'applicant', render: (v, row) => v ? <p style={{ whiteSpace: 'nowrap', margin: 0 }}>{combineBy([v.firstName, v.lastName])} | {row.applicantModel === 'Merchant' ? v.uniqueId : v.phoneNumber}</p> : 'Unknown' },
    {
      title: 'Requested By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[0];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    {
      title: 'Approved By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[1];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    { title: 'Primary', key: 'amount.primary', name: 'amountPrimary', render: v => toIDR(v) },
    { title: 'Status', key: 'status', name: 'status' }
  ];

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.user && typeof filter.user === 'object' ? `applicantModel|User,applicant|${filter.user.value}` : false,
      filter.merchant && typeof filter.merchant === 'object' ? `applicantModel|Merchant,applicant|${filter.merchant.value}` : false,
      filter.transactionCode ? `code|${filter.transactionCode}` : false
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      search,
      startDate,
      endDate,
      status: filter.status
    };
  }, [currentPage, limit, sort, filter]);

  const excelColumns = useMemo(() => [
    { title: 'Trans. Code', key: 'code', name: 'code' },
    { title: 'Applicant Model', key: 'applicantModel', name: 'applicantModel' },
    { title: 'Applicant', key: 'applicant', name: 'applicant', render: (v, row) => v ? `${combineBy([v.firstName, v.lastName])} | ${row.applicantModel === 'Merchant' ? v.uniqueId : v.phoneNumber}` : 'Unknown' },
    {
      title: 'Requested By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[0];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    {
      title: 'Approved By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[1];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    { title: 'Primary', key: 'amount.primary', name: 'amountPrimary' },
    { title: 'Status', key: 'status', name: 'status' }
  ], [])

  return (
    <div>
      <Resource
        title="Injection"
        subTitle="Injection list."
        icon="pe-7s-next"
        role={roleHelper.hasAccess(admin.role, CREATE_UPDATE_FORM)}
        list={{
          columns: columns,
          reducerPath: "injection",
          call: getInjectionDebounced,
          apiQuery: apiQuery,
          module: INJECTION_MODULE,
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Injection_One-to-One',
            apiResponseKey: 'data.injections',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Transaction Code",
              type: TEXT_FIELD,
              key: "transactionCode",
              value: filter.transactionCode,
              placeholder: "Search code"
            },
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: [
                {
                  value: 'PENDING',
                  label: 'Pending'
                },
                {
                  value: 'COMPLETED',
                  label: 'Completed'
                },
                {
                  value: 'FAILED',
                  label: 'Failed'
                }
              ],
              value: filter.status,
            },
            {
              type: USER_SELECT,
              key: "user",
              value: filter.user,
              selectProps: {
                search: (search) => `fullName|${search},uniqueId|${search},phoneNumber|${search}`,
                label: (user) => `${combineBy([user.firstName, user.lastName])} | ${user.uniqueId} | ${user.phoneNumber}`
              }
            },
            {
              type: MERCHANT_SELECT,
              key: "merchant",
              value: filter.merchant,
              selectProps: {
                search: (search) => `fullName|${search},uniqueId|${search},phoneNumber|${search}`,
                label: (merchant) => `${combineBy([merchant.firstName, merchant.lastName])} | ${merchant.uniqueId} | ${merchant.phoneNumber}`
              }
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        additionalTab={{
          component: InjectTab,
          title: "Inject"
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
