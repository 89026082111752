import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const cashbackApi = {
  list: ({ page, limit, sort, search, filter }) => {
    let url = `/business-cashbacks/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (filter) {
      url += `&filter=${filter}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => {
    return agent.requests.post(`/business-cashbacks/create`, data);
  },
  update: (id, data) => {
    return agent.requests.post(`/business-cashbacks/${id}/update`, data);
  }
}
