import { endOfMonth, startOfMonth } from 'date-fns';
import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  SORT_TABLE,
  FILTER_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  TRANSACTIONS_MDR_MODULE,
} from '../../../utils/constants/actionTypes';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'createdAt|desc',
  currentPage: 1,
  activeTabKey: 0,
  tabs: [],
  filter: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    merchant: '',
  },
  totalAmount: 0
};

const reducer = (state = defaultState, action) => {
  if (action.module !== TRANSACTIONS_MDR_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD: {
      const items = !action.error ? action.payload.data.transactions : state.items;
      return {
        ...state,
        items: items,
        total: !action.error ? action.payload.data.count : 0,
        totalAmount: !action.error ? action.payload.data.totalAmount : 0,
      };
    }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        },
        currentPage: 1
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
