import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import React, { useState } from 'react';
import { REPORT_DIRECT_INTEGRATION, REPORT_MERCHANT_INDOFUND, REPORT_WALLET } from '../utils/constants/dataTypes';

export const TypeFilterWrapper = ({ children }) => (
  <div className="type-filter-wrapper">
    {children}
  </div>
)

export const TypeFilterGroup = ({ name, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="type-filter-group">
      <div className="label" onClick={e => setOpen(!open)}>
        <span>{name}</span>
        <div className="checks">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>
      <Collapse in={open}>
        <div className="childs">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export const TypeFilterItem = ({ name, type, selectedType, viewType, selectedViewType, onChange, walletModel, setWalletModel }) => {
  const checked = viewType === selectedViewType && (Array.isArray(type) ? type.join(',') === selectedType : selectedType === type);

  return (
    <div className="type-filter-item">
      <label className="label">
        <input
          type="radio"
          name="reportViewType"
          value={type}
          checked={checked}
          data-viewtype={viewType}
          onChange={onChange}
        />
        <span>{name}</span>
        {viewType === REPORT_WALLET || viewType === REPORT_DIRECT_INTEGRATION || viewType === REPORT_MERCHANT_INDOFUND
          ?
          <div className="switch-bar">
            <div className={`switch${walletModel === 'USER' ? ' active' : ''}`} onClick={() => setWalletModel('USER')}>
              U
            </div>
            <div className={`switch${walletModel === 'MERCHANT' ? ' active' : ''}`} onClick={() => setWalletModel('MERCHANT')}>
              M
            </div>
          </div>
          :
          <div className="checks">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        }
      </label>
    </div>
  )
}
