import { endOfDay, format, startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { businessesSettlementApi } from '../../../services/businessesSettlementApi';
import {
  BUSINESS_SETTLEMENT_MODULE,
  CREATE_TAB,
  FILTER_TABLE,
  LIMIT_TABLE,
  LIST_SETTLEMENT_REJECTED,
  MAIN_TAB_LOAD,
  SET_PAGE_TABLE,
  SORT_TABLE,
} from '../../../utils/constants/actionTypes';
import { settlementTypes, settlementTypesOpts } from '../../../utils/constants/enums/settlementTypes';
import { toIDR } from '../../../utils/helpers/currency';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import FormField from '../../../components/Forms/FormField';
import SelectField from '../../../components/Forms/SelectField';
import useMountedState from '../../../components/HooksUse/useMountedState';
import PopUp from '../../../components/PopUp';
import AdminSelect from '../../../components/Select/AdminSelect';
import Table from '../../../components/Table/Table';
import TableBulk from './TableBulk';
import useDetectBrowser from '../../../components/HooksUse/useDetectBrowser';

const excelFormatName = ".xls";

function MainTab({ listType }) {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const { browser } = useDetectBrowser();
  const {
    currentPageBulks,
    limit,
    sort,
    total,
    totalAmount,
    totalSettlementAmount,
    totalFee,
    totalMDR,
    inProgress,
    filter,
    items,
    itemsBulksReject,
  } = useSelector(state => state.businesses.settlement);
  const [showExcelAction, setShowExcelAction] = useState(false)
  const [selectedBulks, setSelectedBulks] = useState({})
  const [isCreateBulks, setIsCreateBulks] = useState(false);

  const formatFile = useMemo(() => {
    // temporary solved bug at the Excel Download on Firefox Browser
    if (browser === "firefox") return excelFormatName;
    return "";
  }, [browser])

  const selectedBulksArr = useMemo(() => Object.values(selectedBulks).reduce((acc, cur) => Boolean(cur) ? [...acc, { merchantSettlement: cur }] : acc, []), [selectedBulks])

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const status = filter.status?.value
    const checker = filter.checker?.value
    const approver = filter.approver?.value
    const type = filter.type?.value

    return {
      page: currentPageBulks,
      limit: limit,
      sort,
      startDate,
      endDate,
      status,
      checker,
      approver,
      type
    }
  }, [currentPageBulks, limit, sort, filter])

  const load = useCallback(() => {
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: MAIN_TAB_LOAD,
      call: businessesSettlementApi.list,
      args: [apiQuery]
    });
  }, [dispatch, apiQuery])

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: type,
      value: val,
      key: key,
      listType: listType
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const GetBulksReject = useCallback(() => {
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: LIST_SETTLEMENT_REJECTED,
      call: businessesSettlementApi.listMerchantRejectBySettlement,
    })
  }, [dispatch])

  const onCreteBulk = () => {
    setIsCreateBulks(true)
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: null,
      call: businessesSettlementApi.requestBulk,
      args: [{ merchantSettlements: selectedBulksArr }],
    }).catch(() => { }).finally(() => { if (isMounted()) setIsCreateBulks(false) })
    setSelectedBulks({})
    load()
    setShowExcelAction(false)
  }

  const renderCreteBulk = () => (
    <>
      <button
        className="btn btn-icon btn-icon-only btn-sm btn-outline-primary mr-3"
        onClick={() => {
          setShowExcelAction(true)
          GetBulksReject()
        }}
      >
        Request Bulk
      </button>
      <PopUp
        in={showExcelAction}
        width="130vh"
        onClose={() => setShowExcelAction(false)}
      >
        <div className="main-card card">
          <div className="card-body">
            <h5 className="card-title mb-4">Request Bulk</h5>
            <TableBulk
              loading={isCreateBulks}
              selectedBulks={selectedBulks}
              setSelectedBulks={setSelectedBulks}
              items={itemsBulksReject}
            />
            <hr />
            <div className='row'>
              <div className='col-12 col-md-6'>
                <button
                  onClick={() => setShowExcelAction(false)}
                  className="d-block btn btn-light w-100 py-2"
                >
                  Close
                </button>
              </div>
              <div className='col-12 col-md-6'>
                <button
                  onClick={() => onCreteBulk()}
                  className="d-block btn btn-primary w-100 py-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </PopUp>
    </>
  )

  const table = useMemo(() => {
    const createTab = (key, code, status) => {
      dispatch({
        module: BUSINESS_SETTLEMENT_MODULE,
        type: CREATE_TAB,
        key,
        restData: {
          code,
          status,
          listType: "bulk",
        }
      });
    }

    return {
      inProgress,
      data: items,
      sort,
      limit: limit,
      total,
      currentPage: currentPageBulks,

      columns: [
        { title: 'date', key: 'createdAt', name: 'date', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
        { title: 'code', key: 'code', name: 'code' },
        { title: 'status', key: 'statusDescription', name: 'description' },
        { title: 'type', key: 'settlementType', name: 'type', render: v => settlementTypes.getStr(v) },
        { title: 'total amount', key: 'total', name: 'total', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
        { title: 'total fee', key: 'fee', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
        { title: 'total mdr', key: 'mdr', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
        { title: 'amount', key: 'amount', name: 'amount', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
        {
          title: 'checker', key: 'statusChecked', name: 'checker', render: (v) => (
            <div>
              <div className="text-black font-weight-semibold">{v?.checker?.firstName ?? '-'} {v?.checker?.lastName ?? ''}</div>
              <div className="text-muted">{v?.checker?.email}</div>
              <div className="text-muted">{v?.checkedAt && format(new Date(v?.checkedAt), 'HH:mm:ss')}</div>
            </div>
          )
        },
        {
          title: 'approver', key: 'statusApproved', name: 'approver', render: (v) => (
            <div>
              <div className="text-black font-weight-semibold">{v?.approver?.firstName ?? '-'} {v?.approver?.lastName ?? ''}</div>
              <div className="text-muted">{v?.approver?.email}</div>
              <div className="text-muted">{v?.approvedAt && format(new Date(v?.approvedAt), 'HH:mm:ss')}</div>
            </div>
          )
        },
        {
          title: 'actions', key: 'status', name: 'action', className: 'text-center', sortable: false, render: (status, row) => (
            <div className="td-action">
              <button
                className="btn px-4 btn-primary inline-block"
                onClick={() => createTab(row._id, row.code, status)}
                style={{ fontSize: '11.5px' }}
              >
                List
              </button>
            </div>
          )
        },
      ]
    }
  }, [currentPageBulks, inProgress, items, limit, sort, total, dispatch])

  const excelColumns = useMemo(() => [
    { title: 'date', key: 'createdAt', name: 'date', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'code', key: 'code', name: 'code' },
    { title: 'status', key: 'statusDescription', name: 'description' },
    { title: 'type', key: 'settlementType', name: 'type', render: v => settlementTypes.getStr(v) },
    { title: 'total amount', key: 'total', name: 'total', render: v => toIDR(v, false) },
    { title: 'total fee', key: 'fee', name: 'Fee', render: v => toIDR(v, false) },
    { title: 'total mdr', key: 'mdr', name: 'Fee', render: v => toIDR(v, false) },
    { title: 'amount', key: 'amount', name: 'amount', render: v => toIDR(v, false) },
    { title: 'checker', key: 'statusChecked', name: 'checker', render: (v) => v?.checker ? (`${v?.checker?.firstName} ${v?.checker?.lastName ?? ''} | ${v?.checker?.email}`) : '-' },
    { title: 'approver', key: 'statusApproved', name: 'approver', render: (v) => v?.approver ? (`${v?.approver?.firstName} ${v?.approver?.lastName ?? ''} | ${v?.approver?.email}`) : '-' }
  ], [])

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title mb-4">Settlement Group</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
          customTopRightTable={renderCreteBulk()}
          renderTotal={() => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Settlement Amount: <b>{toIDR(totalSettlementAmount)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Fee: <b>{toIDR(totalFee)}</b>
              </li>
              <li className="list-group-item px-0">
                Total MDR: <b>{toIDR(totalMDR)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Transfer Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          )}
          excel={{
            columns: excelColumns,
            filename: `Merchant_Settlement_${format(
              new Date(),
              "yyyy-MM-dd"
            )}${formatFile}`,
            api: {
              apiResponseKey: "data.merchantSettlementBulks",
              service: businessesSettlementApi.list,
              queryParams: apiQuery
            },
          }}
        >
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <FormField label="Start Date">
                <EvyDatePicker
                  value={filter.startDate}
                  onChange={onFilter("startDate")}
                  variant="dialog"
                  placeholder="Select date"
                  autoOk
                  clearable={true}
                />
              </FormField>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <FormField label="End Date">
                <EvyDatePicker
                  value={filter.endDate}
                  onChange={onFilter("endDate")}
                  variant="dialog"
                  placeholder="Select date"
                  autoOk
                  clearable={true}
                />
              </FormField>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Status"
                className="form-control"
                placeholder="No Filter"
                options={[
                  { value: "COMPLETED", label: "Completed" },
                  { value: "CHECKED", label: "Checked" },
                  { value: "PENDING", label: "Pending" },
                ]}
                onChange={onFilter("status")}
                value={filter.status}
                nullable
                componentProps={{
                  isClearable: true
                }}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Type"
                className="form-control"
                placeholder="No Filter"
                options={settlementTypesOpts}
                onChange={onFilter("type")}
                value={filter.type}
                nullable
                componentProps={{
                  isClearable: true
                }}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <AdminSelect
                label="Select Checker"
                value={filter.checker}
                onChange={onFilter('checker')}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <AdminSelect
                label="Select Approver"
                value={filter.approver}
                onChange={onFilter('approver')}
              />
            </div>
          </div>
        </Table>
      </div>
    </div>
  )
}

export default MainTab;
