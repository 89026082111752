export class switcherMerchantType {
  static ARTAJASA = 'ARTAJASA';
  static RINTIS = 'RINTIS';
  static DIPAY = 'DIPAY';
  static JALIN = 'JALIN';
  static ALTO = 'ALTO';

  static getStr(en) {
    switch (en) {
      case switcherMerchantType.ARTAJASA:
        return 'Artajasa';
      case switcherMerchantType.RINTIS:
        return 'Rintis';
      case switcherMerchantType.DIPAY:
        return 'Dipay';
      case switcherMerchantType.JALIN:
        return 'Jalin';
      case switcherMerchantType.ALTO:
        return 'Alto';
      default:
        return 'Unknown';
    }
  }
}

export const switcherMerchantTypesOpts = [
  { value: switcherMerchantType.ARTAJASA, label: switcherMerchantType.getStr(switcherMerchantType.ARTAJASA) },
  { value: switcherMerchantType.RINTIS, label: switcherMerchantType.getStr(switcherMerchantType.RINTIS) },
  { value: switcherMerchantType.DIPAY, label: switcherMerchantType.getStr(switcherMerchantType.DIPAY) },
  // { value: switcherMerchantType.JALIN, label: switcherMerchantType.getStr(switcherMerchantType.JALIN) },
  // { value: switcherMerchantType.ALTO, label: switcherMerchantType.getStr(switcherMerchantType.ALTO) },
];

export const issuerSwitcherMerchantTypesOpts = [
  { value: switcherMerchantType.ARTAJASA, label: switcherMerchantType.getStr(switcherMerchantType.ARTAJASA) },
  { value: switcherMerchantType.RINTIS, label: switcherMerchantType.getStr(switcherMerchantType.RINTIS) },
  { value: switcherMerchantType.DIPAY, label: switcherMerchantType.getStr(switcherMerchantType.DIPAY) },
  { value: switcherMerchantType.JALIN, label: switcherMerchantType.getStr(switcherMerchantType.JALIN) },
  { value: switcherMerchantType.ALTO, label: switcherMerchantType.getStr(switcherMerchantType.ALTO) },
];
