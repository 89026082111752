import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MyForm from './Form'
import { CREATE, REFERRAL_MODULE } from '../../../utils/constants/actionTypes';
import useMountedState from '../../../components/HooksUse/useMountedState';
import { referralApi } from '../../../services/referralApi';

const UpdateTab = ({
  index
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState()

  const { tabs, items } = useSelector(state => state.referral.mainList);
  const { key } = tabs[index];
  const selectedItem = items.find(row => row._id === key);

  const onSubmit = (values) => {
    setIsLoading(true)
    dispatch({
      module: REFERRAL_MODULE,
      type: CREATE,
      call: referralApi.update,
      args: [
        key,
        {
          ...values,
          isActive: values.isActive === "ACTIVE" ? true : false,
          isQuotaEnabled: values.isQuotaEnabled === "1" ? true : false,
        }
      ]
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <MyForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          initialValues={selectedItem}
        >
        </MyForm>
      </div>
    </div>
  )
}

export default UpdateTab
