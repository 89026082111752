import React from 'react';

class Image extends React.Component {
  state = {
    placeholder: process.env.PUBLIC_URL + '/assets/images/no-image.jpg'
  }

  render() {
    return (
      <img className="table-img-preview" alt="" src={this.props.src ? this.props.src : this.state.placeholder}/>
    )
  }
}

export default Image