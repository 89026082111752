import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TRANSACTIONS_REWARD_MODULE } from "../../../utils/constants/actionTypes";
import { combineBy } from "../../../utils/helpers/combineBy";
import { DATE_PICKER } from "../../../utils/constants/inputTypes";
import { toIDR } from "../../../utils/helpers/currency";
import { transactionsRewardApi } from "../../../services/transactionsRewardApi";

const Index = () => {
  const { currentPage, limit, sort, filter } = useSelector((state) => state.transactions.reward);

  const columns = [
    {
      title: 'Trans. Date', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    },
    { title: 'User', key: 'recipient', name: 'recipient', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Trans. Code', key: 'code', name: 'code' },
    { title: 'Remark', key: 'description', name: 'description' },
    { title: 'Primary', key: 'amount.primary', name: 'amountPrimary', render: v => toIDR(v) },
  ];

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      startDate,
      endDate,
    };
  }, [currentPage, limit, sort, filter]);

  const excelColumns = useMemo(() => [
    { title: 'Trans. Date', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm') },
    { title: 'User', key: 'recipient', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Trans. Code', key: 'code' },
    { title: 'Remark', key: 'description' },
    { title: 'Primary', key: 'amount.primary', render: v => toIDR(v, false) },
  ], [])

  return (
    <div>
      <Resource
        title="Transactions Reward"
        subTitle="Full Transactions Reward list."
        icon="pe-7s-info"
        list={{
          columns: columns,
          reducerPath: "transactions.reward",
          call: transactionsRewardApi.list,
          apiQuery: apiQuery,
          module: TRANSACTIONS_REWARD_MODULE,
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Reward_List',
            apiResponseKey: 'data.cashbacks',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
      />
    </div>
  );
};

export default Index;
