import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_NOTIFICATIONS_MERCHANT,
  ROUTE_NOTIFICATIONS_USER
} from "../utils/helpers/roleHelper";
import NotificationUser from "../pages/Notification/User";
import NotificationMerchant from "../pages/Notification/Merchant";

export const notification = {
  type: NAVMENU,
  name: 'Push Notifications',
  icon: 'lnr-bullhorn',
  children: [
    {
      type: NAVLINK,
      name: 'Dipay User App',
      url: '/notification/user',
      routeTitle: ROUTE_NOTIFICATIONS_USER,
      component: NotificationUser
    },
    {
      type: NAVLINK,
      name: 'Dipay Bisnis App',
      url: '/notification/merchant',
      routeTitle: ROUTE_NOTIFICATIONS_MERCHANT,
      component: NotificationMerchant
    }
  ]
}
