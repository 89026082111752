import React, { useCallback, useEffect, useMemo, useState } from "react";
// import Resource from "../../../../../components/Resource";
// import { OPEN_API_SETTING_MODULE } from "../../../../../utils/constants/actionTypes";
// import { useSelector } from 'react-redux';
// import { openApiSettingApi } from "../../../../../services/openApiSettingApi";
// import PopUp from "../../../../../components/PopUp";
// import EditForm from "./Edit";
import Table from "../../../../../components/Table/Table";
import EditForm from "./Edit";
import CloseIcon from '@material-ui/icons/Close';
import PopUp from "../../../../../components/PopUp";
import CreateFeature from "./CreateFeature";
import { partnerApi } from "../../../../../services/partnerApi";

const OpenAPISetting = ({
  item,
  onSuccess
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showCreateFeature, setShowCreateFeature] = useState(false);
  const [showEditFeature, setShowEditFeature] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [deleteRowId, setDeleteRowId] = useState('');
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    if (item?.openApiSetting?.features?.length) {
      setFeatures(item?.openApiSetting?.features)
    }
  }, [item])

  const deleteSelected = useCallback(() => {
    partnerApi.deleteOpenApiFeature(item?._id, deleteRowId)
      .then(() => {
        setFeatures(prev => prev.filter(v => v._id !== deleteRowId))
        setDeleteRowId('');
      })
      .catch(() => { })
  }, [item, deleteRowId])

  const table = useMemo(() => {
    return ({
      inProgress: false,
      data: features,
      columns: [
        {
          title: "Name",
          key: "name",
          name: "name",
          sortable: false,
        },
        {
          title: "Enabled",
          key: "enabled",
          name: "enabled",
          sortable: false,
          render: (v) => v ? "Yes" : "No"
        },
        {
          title: "Actions",
          key: "_id",
          name: "_id",
          sortable: false,
          render: (v, row) => {
            return (
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => {
                    setSelectedFeature(row)
                    setShowEditFeature(true)
                  }}
                >
                  <i className="pe-7s-tools btn-icon-wrapper"></i>
                </button>

                <div className="btn-group ml-1">
                  {deleteRowId === v ? (
                    <>
                      <button
                        className="btn btn-sm btn-danger lh-1"
                        onClick={() => setDeleteRowId("")}
                      >
                        No
                      </button>
                      <button
                        onClick={deleteSelected}
                        type="button"
                        className="btn btn-sm btn-danger lh-1 py-0"
                      >
                        Yes, Delete
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn btn-icon btn-icon-only btn-sm btn-danger"
                      onClick={() => setDeleteRowId(v)}
                    >
                      <i className="pe-7s-trash btn-icon-wrapper"></i>
                    </button>
                  )}
                </div>
              </div>
            )
          }
        }
      ]
    })
  }, [features, deleteRowId, deleteSelected])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Edit Open Api Setting</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ?
            <><CloseIcon fontSize="small" /><span className="ml-2 text-uppercase">Cancel</span></> :
            <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
          }
        </button>
      </div>
      <div className="mb-4">
        <EditForm
          partnerId={item?._id}
          initialValues={item?.openApiSetting}
          onSuccess={onSuccess}
          isEditing={isEditing}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Features</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-primary`}
          onClick={() => setShowCreateFeature(true)}
        >
          Create New Feature
        </button>
      </div>
      <Table
        table={table}
      // onReload={load}
      // onSort={setTableConfig(SORT_TABLE)}
      // onChangeShow={setTableConfig(LIMIT_TABLE)}
      // onChangePage={setTableConfig(SET_PAGE_TABLE)}
      >
      </Table>
      {/* Create */}
      <PopUp in={showCreateFeature} onClose={() => setShowCreateFeature(false)}>
        <CreateFeature
          partnerId={item?._id}
          onSuccess={() => {
            onSuccess()
            setShowCreateFeature(false)
          }}
        />
      </PopUp>

      {/* Edit */}
      <PopUp in={showEditFeature} onClose={() => setShowEditFeature(false)}>
        <CreateFeature
          partnerId={item?._id}
          initialValues={selectedFeature}
          onSuccess={() => {
            onSuccess()
            setShowEditFeature(false)
          }}
        />
      </PopUp>
    </>
  );
};

export default OpenAPISetting;
