export class settlementTypes {
  static DEFAULT = 'DEFAULT';
  static CLOSED_ACCOUNT = 'CLOSED_ACCOUNT';

  static getStr(en) {
    switch (en) {
      case settlementTypes.DEFAULT:
        return 'Default';
      case settlementTypes.CLOSED_ACCOUNT:
        return 'Closed Account';
      default:
        return 'Unknown';
    }
  }
}

export const settlementTypesOpts = [
  { value: settlementTypes.DEFAULT, label: settlementTypes.getStr(settlementTypes.DEFAULT) },
  { value: settlementTypes.CLOSED_ACCOUNT, label: settlementTypes.getStr(settlementTypes.CLOSED_ACCOUNT) },
];
