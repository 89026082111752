import React, { useCallback, useMemo } from 'react';
import Resource from '../../../components/Resource';
import { endOfDay, format, startOfDay } from 'date-fns';
import { userApi } from '../../../services/userApi';
import Detail from './Detail';
import { combineBy } from '../../../utils/helpers/combineBy';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserStatuses from '../../../components/UserStatuses';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { USER_MODULE } from '../../../utils/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import UserStatusOpts from '../../../utils/constants/enums/status/userStatusOpts';
import { DATE_PICKER, PARTNER_SELECT, SELECT_FIELD } from '../../../utils/constants/inputTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import { dataListApi } from '../../../services/dataListApi';
import { partnerTypes } from '../../../utils/constants/enums/partnerTypes';
import styles from './list.module.scss'

const Index = () => {
  const { currentAdmin: { role } } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.users.list);

  const getUserDebounced = useMemo(
    () => AwesomeDebouncePromise(userApi.list, 500),
    [],
  )

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
      }
    },
    {
      title: 'Updated At', key: 'updatedAt', name: 'updatedAt', render: function (v, row) {
        return v ? format(new Date(v), 'yyyy-MM-dd HH:mm:ss') : "-"
      }
    },
    { title: 'Dipay ID', key: 'uniqueId', name: 'uniqueId' },
    {
      title: 'Full Name', key: 'firstName', name: 'fullName', render: (v, row) => combineBy([row.firstName, row.lastName])
    },
    {
      title: 'Email', key: 'email', name: 'email', render: (v, row) => (
        <div className="d-flex align-items-center">
          {v}
          {row.status.emailVerifiedAt && (
            <Tooltip title={`Verified at: ${format(new Date(row.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm')}`}>
              <div className="ml-1">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              </div>
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: 'Connected Partner', key: 'connectedPartner', name: 'connectedPartner', render: (v) => (
        <div>
          {v?.length ? v.map((partner, index) => (
            <div key={index} className="d-flex align-items-center my-1">
              <span className='mr-1'>{partnerTypes.getStr(partner.companyCode)}</span>
              {partner?.privateUser ?
                <div className={styles.badge}>
                  <span>Private</span>
                </div>
                : null
              }
            </div>
          )) : '-'}
        </div>
      )
    },
    {
      title: 'Phone Number', key: 'phoneNumber', name: 'phoneNumber', className: 'nowrap', render: (v, row) => (
        <div className="d-flex align-items-center">
          {v}
          {row.status.phoneNumberVerifiedAt && (
            <Tooltip title={`Verified at: ${format(new Date(row.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm')}`}>
              <div className="ml-1">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              </div>
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: 'Statuses', key: 'status', name: 'status', sortable: false, render: (v, row) => <UserStatuses user={row} />
    }
  ]

  const {
    value: { data: jobs = [] } = {}
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: dataListApi.getJobs }),
    [dispatch]), true)

  const excelColumns = useMemo(() => [
    { title: 'Dipay ID', key: 'uniqueId' },
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm') },
    { title: 'Balance/Primary', key: 'balance.primary' },
    { title: 'Phone Number', key: 'phoneNumber', render: v => `'${v}` },
    { title: 'Fullname', key: 'firstName', render: (v, row) => combineBy([row.firstName, row.lastName]) },
    { title: 'Email', key: 'email' },
    { title: 'Device 1/Last Login', key: 'devices', render: (v, row) => row.devices?.[0]?.lastLogin ? format(new Date(row.devices?.[0]?.lastLogin), 'yyyy-MM-dd HH:mm') : '-' },
    { title: 'Device 1/UUID', key: 'devices', render: (v, row) => row.devices?.[0]?.uuid ?? '-' },
    { title: 'Device 2/Last Login', key: 'devices', render: (v, row) => row.devices?.[1]?.lastLogin ? format(new Date(row.devices?.[1]?.lastLogin), 'yyyy-MM-dd HH:mm') : '-' },
    { title: 'Device 2/UUID', key: 'devices', render: (v, row) => row.devices?.[1]?.uuid ?? '-' },
    { title: 'Device 3/Last Login', key: 'devices', render: (v, row) => row.devices?.[2]?.lastLogin ? format(new Date(row.devices?.[2]?.lastLogin), 'yyyy-MM-dd HH:mm') : '-' },
    { title: 'Device 3/UUID', key: 'devices', render: (v, row) => row.devices?.[2]?.uuid ?? '-' }
  ], [])

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const activeStartDate = filter.activeStartDate ? startOfDay(new Date(filter.activeStartDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const activeEndDate = filter.activeEndDate ? endOfDay(new Date(filter.activeEndDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const search = filter.search ? `uniqueId|${filter.search},fullName|${filter.search},email|${filter.search},phoneNumber|${filter.search}` : null;

    return {
      page: currentPage,
      limit,
      sort,
      search,
      filter: filter.upgraded,
      connectedPartner: filter.connectedPartner,
      conPartnerType: filter.conPartnerType,
      gender: filter.gender,
      job: filter.job,
      startDate,
      endDate,
      activeStartDate,
      activeEndDate
    }
  }, [filter, currentPage, limit, sort])

  return (
    <Resource
      title="User Control"
      subTitle="Full User Control includes Create, Delete & Updates."
      icon="pe-7s-users"
      list={{
        search: true,
        columns: columns,
        reducerPath: "users.list",
        module: USER_MODULE,
        call: getUserDebounced,
        apiQuery: apiQuery,
        excel: {
          role: role,
          columns: excelColumns,
          filename: 'User_List',
          apiResponseKey: 'data.users',
        },
        filters: [
          {
            label: "Filter Status",
            type: SELECT_FIELD,
            key: "upgraded",
            options: UserStatusOpts,
            value: filter.upgraded
          },
          {
            label: "Filter Gender",
            type: SELECT_FIELD,
            key: "gender",
            options: [
              {
                value: "MALE",
                label: "Male"
              },
              {
                value: "FEMALE",
                label: "Female"
              }
            ],
            value: filter.gender
          },
          {
            label: "Filter Job",
            type: SELECT_FIELD,
            key: "job",
            options: jobs.map(v => ({ value: v.occupation_id, label: v.occupation_id })),
            value: filter.job
          },
          {
            label: "Filter Connected Partner",
            type: PARTNER_SELECT,
            key: "connectedPartner",
            value: filter.connectedPartner || "",
            getOptionValue: v => v.optData.companyCode,
            filterIntegrationType: "COMPANY",
            filterByCompanyCode: true
          },
          {
            label: "Filter Tipe Connected Partner",
            type: SELECT_FIELD,
            key: "conPartnerType",
            options: [
              {
                value: "PRIVATE_USER",
                label: "Private"
              },
              {
                value: "NON_PRIVATE_USER",
                label: "Non Private"
              }
            ],
            value: filter.conPartnerType
          },
          {
            label: "Start Date",
            type: DATE_PICKER,
            key: "startDate",
            value: filter.startDate,
            placeholder: "Select date"
          },
          {
            label: "End Date",
            type: DATE_PICKER,
            key: "endDate",
            value: filter.endDate,
            placeholder: "Select date"
          },
          {
            label: "Active Start Date",
            type: DATE_PICKER,
            key: "activeStartDate",
            value: filter.activeStartDate,
            placeholder: "Select date"
          },
          {
            label: "Active End Date",
            type: DATE_PICKER,
            key: "activeEndDate",
            value: filter.activeEndDate,
            placeholder: "Select date"
          },
        ]
      }}
      detail={{
        component: Detail
      }}
    />
  );
}

export default Index;
