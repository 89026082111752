import React, { useEffect, useCallback } from 'react';
import FormField from '../../../components/Forms/FormField';
import HookTextField from '../../../components/Forms/HookTextField';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import { PARTNER_MODULE, CREATE, IMAGE_FORMATS } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import { toIDR } from '../../../utils/helpers/currency';
import SelectField from '../../../components/Forms/SelectField';
import EvyCheckbox from '../../../components/Forms/EvyCheckbox';
import { phoneNumberRegex } from '../../../utils/constants/regexTypes';
import HookImage from '../../../components/Forms/HookImage';
import { partnerApi } from '../../../services/partnerApi';

const schema = yup.object().shape({
  partnerId: yup.string().required('Partner ID is required'),
  logo: yup.mixed()
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),
  partnerName: yup.string().required('Partner Name is required'),
  field: yup.string().required('Partner Field is required'),
  address: yup.string().required('Partner Address is required'),
  integrationType: yup.string().required('Integration Type is required'),
  companyCode: yup.string().when('integrationType', {
    is: integrationType => integrationType === 'COMPANY',
    then: yup => yup.required('Company Code is required'),
    otherwise: yup => yup.nullable(),
  }),

  zakat: yup.object({
    maal: yup.boolean(),
    profesi: yup.boolean(),
    fitrah: yup.boolean(),
  }).when('integrationType', {
    is: integrationType => integrationType === 'ZAKAT',
    then: yup => yup.test(
      "zakatValidation",
      "Please select at least one zakat",
      value => (value.maal || value.profesi || value.fitrah)
    ),
    otherwise: yup => yup.nullable(),
  }),

  picName: yup.string().required('Person Name is required'),
  picIdNumber: yup.string(),
  picJobTitle: yup.string().required('Role is required'),
  picEmail: yup.string().email('Format is invalid').required('Email is required'),
  picPhoneNumber: yup.string().required('Phone Number is required').matches(phoneNumberRegex, 'Format is invalid. e.g: +628xxxxxxxxxx').min(5, 'Minimum length 5 digits'),

  operator: yup.string()
    .when('feeRulesType', {
      is: type => type === 'RULES',
      then: yup => yup.required('Operator is required')
    }),
  feeRulesType: yup.string().required('Fee Rules Type is required'),
  feeApplyLimit: yup.number().typeError('Input must be numbers')
    .when('feeRulesType', {
      is: type => type === 'RULES',
      then: yup => yup.required('Maximum Settlement is required').min(0, 'Input must be greater than or equal to 0')
    }),
  feeType: yup.string().required('Fee Type is required'),
  minSettlement: yup.number().typeError('Input must be numbers').required('Maximum Settlement is required').min(0, 'Input must be greater than or equal to 0'),
  revenueFee: yup.number().typeError('Input must be numbers').required('Revenue Fee is required'),
  adminFee: yup.number().typeError('Input must be numbers')
    .when('integrationType', {
      is: type => type === 'COMPANY',
      then: yup => yup.required('Revenue Fee is required')
    }),
  maxSettlement: yup.number().typeError('Input must be numbers')
    .when(['feeType', 'feeRulesType'], {
      is: (feeType, feeRulesType) => feeRulesType !== 'NONE' && feeType === 'PERCENTAGE',
      then: yup => yup.required('Maximum Settlement is required').min(0, 'Input must be greater than or equal to 0')
    }),
  fee: yup.number().typeError('Input must be numbers').required('Fee is required')
    .when(['feeType', 'feeRulesType'], (feeType, feeRulesType, schema) => {
      if (feeType === 'PERCENTAGE') return schema.lessThan(100, 'Input must be 0 - 100%').moreThan(0, 'Input must be 0 - 100%')
      if (feeRulesType === 'NONE') return schema.notRequired().min(0)
    }),

  enabledRules: yup.string(),
  customFee: yup.number().typeError('Input must be numbers').when(['enabledRules'], {
    is: (enabledRules) => enabledRules === 'enable',
    then: yup => yup.required('Custom Fee is required')
  }),
  uniquePartnerNumber: yup.string().typeError('Input must be numbers').when(['enabledRules'], {
    is: (enabledRules) => enabledRules === 'enable',
    then: yup => yup.required('Custom Fee is required').min(2, 'Must be exactly 2 digits').max(2, 'Must be exactly 2 digits')
  }),

  enabledBankTransferRules: yup.string(),
  bankTransferCustomFee: yup.number().typeError('Input must be numbers').when(['enabledBankTransferRules'], {
    is: (enabledBankTransferRules) => enabledBankTransferRules === 'enable',
    then: yup => yup.required('Custom Fee is required')
  }),
});

const CreateTab = () => {
  const dispatch = useDispatch();
  const { isCreating } = useSelector(state => state.partner.list)
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      feeType: 'AMOUNT',
      enabledRules: 'disable',
      enabledBankTransferRules: 'disable'
    }
  });
  const { partnerId, integrationType, feeType, operator, feeRulesType, minSettlement, feeApplyLimit, revenueFee, adminFee, maxSettlement, fee, companyCode, zakat: { maal, fitrah, profesi } = {}, enabledRules, customFee, enabledBankTransferRules, bankTransferCustomFee } = watch(['partnerId', 'integrationType', 'feeType', 'feeRulesType', 'minSettlement', 'operator', 'feeApplyLimit', 'revenueFee', 'adminFee', 'maxSettlement', 'fee', 'companyCode', 'zakat', 'enabledRules', 'customFee', 'enabledBankTransferRules', 'bankTransferCustomFee']);

  const getIntegrationType = useCallback(() => dispatch({ module: null, type: null, call: partnerApi.integrationType }), [dispatch]);
  const getCompanies = useCallback(() => dispatch({ module: null, type: null, call: partnerApi.companyList }), [dispatch]);


  const { value: integrationTypeAsyncValue, pending: isGettingIntegrationType } = useAsync(getIntegrationType, true);
  const integrationTypeOpts = integrationTypeAsyncValue?.data?.integrationType.map(row => ({ value: row, label: row })) ?? [];

  const { value: companyAsyncValue, pending: isGettingCompany } = useAsync(getCompanies, true);
  const companyOpts = companyAsyncValue?.data?.type.map(row => ({ value: row, label: row })) ?? [];

  const onSubmit = (values) => {
    const data = {
      address: values.address,
      fee: values.fee ?? 0,
      feeType: values.feeType,
      field: values.field,
      integrationType: values.integrationType,
      minSettlement: values.minSettlement,
      adminFee: values.adminFee ?? 0,
      revenueFee: values.revenueFee,
      maxSettlement: values.maxSettlement ?? 0,
      partnerId: values.partnerId,
      picIdNumber: values.picIdNumber,
      picJobTitle: values.picJobTitle,
      picName: values.picName,
      name: values.partnerName,
      email: values.picEmail,
      phoneNumber: values.picPhoneNumber,
      feeRules: {
        type: feeRulesType,
        operator: operator ?? '<',
        feeApplyLimit: feeApplyLimit ?? 0
      },
      rules: {
        enable: values.enabledRules === 'enable' ? 1 : 0,
        customFee: values.customFee,
        uniquePartnerNumber: values.uniquePartnerNumber
      },
      bankTransferRules: {
        enable: values.enabledBankTransferRules === 'enable' ? 1 : 0,
        customFee: values.bankTransferCustomFee
      }
    };
    if (values.integrationType === 'COMPANY') data.companyCode = values.companyCode
    if (values.integrationType === 'ZAKAT') {
      data.maal = values.zakat.maal ?? false
      data.fitrah = values.zakat.fitrah ?? false
      data.profesi = values.zakat.profesi ?? false
    }
    if (values.logo[0]) data.logo = values.logo[0]
    dispatch({ module: PARTNER_MODULE, type: CREATE, call: partnerApi.create, args: [data] })
      .catch(() => { });
  };

  useEffect(() => {
    register("partnerId");
    register("integrationType");
    register("feeType");
    register("feeRulesType");
    register("operator");
    register("enabledRules");
    register("enabledBankTransferRules")

    return () => {
      unregister("partnerId");
      unregister("integrationType");
      unregister("feeType");
      unregister("feeRulesType");
      unregister("operator");
      unregister("companyCode");
      unregister("zakat.maal")
      unregister("zakat.profesi")
      unregister("zakat.fitrah")
      unregister("enabledRules");
      unregister("enabledBankTransferRules")
    };
  }, [register, unregister]);

  useEffect(() => {
    if (integrationType === 'COMPANY') register("companyCode")
    else unregister("companyCode")
    if (integrationType === 'ZAKAT') {
      register("zakat.maal")
      register("zakat.profesi")
      register("zakat.fitrah")
    } else {
      unregister("zakat.maal")
      unregister("zakat.profesi")
      unregister("zakat.fitrah")
    }
  }, [integrationType, register, unregister])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form className="px-4 py-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 col-lg-6 mb-2">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="card-title mb-0">Partner Information</h5>
              </div>
              <HookTextField
                value={partnerId ?? ''}
                onChange={e => setValue('partnerId', e.target.value.toUpperCase(), { shouldValidate: true })}
                label="Partner ID"
                error={errors.partnerId}
                helperText={errors.partnerId ? errors.partnerId.message : "This ID will be used as login ID"}
                row
              />
              <HookImage
                row
                label="Logo"
                ref={register}
                name="logo"
                error={errors.logo}
                helperText={errors.logo ? errors.logo.message : null}
                format={IMAGE_FORMATS}
                onClear={() => setValue('logo', null, { shouldValidate: true })}
              />
              <HookTextField
                ref={register}
                label="Partner Name"
                name="partnerName"
                error={errors.partnerName}
                helperText={errors.partnerName ? errors.partnerName.message : null}
                row
              />
              <HookTextField
                ref={register}
                label="Field"
                name="field"
                error={errors.field}
                helperText={errors.field ? errors.field.message : null}
                row
              />
              <HookTextField
                ref={register}
                label="Address"
                name="address"
                error={errors.address}
                helperText={errors.address ? errors.address.message : null}
                row
              />
              <SelectField
                name="integrationType"
                label="Integration Type"
                options={integrationTypeOpts}
                onChange={(v) => setValue('integrationType', v?.value, { shouldValidate: true })}
                value={integrationType}
                error={errors.integrationType}
                helperText={errors.integrationType ? errors.integrationType.message : null}
                row
                componentProps={{
                  isClearable: true,
                  isLoading: isGettingIntegrationType
                }}
              />
              {
                integrationType === 'COMPANY' &&
                <SelectField
                  name="companyCode"
                  label="Company"
                  options={companyOpts}
                  onChange={(v) => setValue('companyCode', v?.value, { shouldValidate: true })}
                  value={companyCode}
                  error={errors.companyCode}
                  helperText={errors.companyCode ? errors.companyCode.message : null}
                  row
                  componentProps={{
                    isClearable: true,
                    isLoading: isGettingCompany
                  }}
                />
              }
              {
                integrationType === 'ZAKAT' &&
                <FormField
                  row
                  label="Zakat"
                  error={errors.zakat}
                  helperText={errors.zakat?.message}
                >
                  <EvyCheckbox
                    label="Maal"
                    onChange={(v) => setValue('zakat.maal', v.target.checked)}
                    checked={maal}
                    id="maal"
                  />
                  <EvyCheckbox
                    label="Profesi"
                    onChange={(v) => setValue('zakat.profesi', v.target.checked)}
                    checked={profesi}
                    id="profesi"
                  />
                  <EvyCheckbox
                    label="Fitrah"
                    onChange={(v) => setValue('zakat.fitrah', v.target.checked)}
                    checked={fitrah}
                    id="fitrah"
                  />
                </FormField>
              }
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="card-title mb-0">Person in Charge</h5>
              </div>
              <HookTextField
                ref={register}
                label="Name"
                name="picName"
                error={errors.picName}
                helperText={errors.picName ? errors.picName.message : null}
                row
              />
              <HookTextField
                ref={register}
                label="Identification Number"
                name="picIdNumber"
                row
              />
              <HookTextField
                ref={register}
                label="Role"
                name="picJobTitle"
                error={errors.picJobTitle}
                helperText={errors.picJobTitle ? errors.picJobTitle.message : null}
                row
              />
              <HookTextField
                ref={register}
                label="Email"
                name="picEmail"
                error={errors.picEmail}
                helperText={errors.picEmail ? errors.picEmail.message : null}
                row
              />
              <HookTextField
                ref={register}
                label="Phone Number"
                name="picPhoneNumber"
                error={errors.picPhoneNumber}
                helperText={errors.picPhoneNumber ? errors.picPhoneNumber.message : null}
                row
              />
              <FormField
                row
                className="mt-4"
                label={
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h5 className="card-title mb-0">Rules Virtual Account</h5>
                  </div>
                }
              >
                <RadioGroup
                  row
                  name="enabledRules"
                  value={enabledRules}
                  onChange={e => setValue('enabledRules', e.target.value)}
                >
                  <FormControlLabel value="enable" control={<Radio color="primary" />} label="Enable" />
                  <FormControlLabel value="disable" control={<Radio color="primary" />} label="Disable" />
                </RadioGroup>
              </FormField>
              {
                enabledRules === 'enable'
                &&
                <>
                  <HookTextField
                    ref={register}
                    label="Unique Partner Number"
                    name="uniquePartnerNumber"
                    disabled={enabledRules === 'disable'}
                    error={errors.uniquePartnerNumber}
                    helperText={errors.uniquePartnerNumber ? errors.uniquePartnerNumber.message : null}
                    row
                  />
                  <HookTextField
                    ref={register}
                    label="Custom Fee"
                    name="customFee"
                    prepend="Rp"
                    disabled={enabledRules === 'disable'}
                    error={errors.customFee}
                    helperText={errors.customFee ? errors.customFee.message : toIDR(customFee)}
                    row
                  />
                </>
              }
              <FormField
                row
                className="mt-4"
                label={
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <h5 className="card-title mb-0">Rules Bank Transfer</h5>
                  </div>
                }
              >
                <RadioGroup
                  row
                  name="enabledBankTransferRules"
                  value={enabledBankTransferRules}
                  onChange={e => setValue('enabledBankTransferRules', e.target.value)}
                >
                  <FormControlLabel value="enable" control={<Radio color="primary" />} label="Enable" />
                  <FormControlLabel value="disable" control={<Radio color="primary" />} label="Disable" />
                </RadioGroup>
              </FormField>
              {
                enabledBankTransferRules === 'enable'
                &&
                <HookTextField
                  prepend="Rp"
                  ref={register}
                  label="Custom Fee"
                  name="bankTransferCustomFee"
                  disabled={enabledBankTransferRules === 'disable'}
                  error={errors.bankTransferCustomFee}
                  helperText={errors.bankTransferCustomFee ? errors.bankTransferCustomFee.message : toIDR(bankTransferCustomFee)}
                  row
                />
              }
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="card-title mb-0">Settlement Information</h5>
              </div>
              {integrationType === 'COMPANY' &&
                <HookTextField
                  ref={register}
                  label="Admin Fee"
                  name="adminFee"
                  error={errors.adminFee}
                  helperText={errors.adminFee ? errors.adminFee.message : toIDR(adminFee)}
                  row
                  prepend="Rp"
                />
              }
              <HookTextField
                ref={register}
                label="Revenue Fee"
                name="revenueFee"
                error={errors.revenueFee}
                helperText={errors.revenueFee ? errors.revenueFee.message : toIDR(revenueFee)}
                row
                prepend="Rp"
              />
              <HookTextField
                ref={register}
                label="Minimum Settlement"
                name="minSettlement"
                error={errors.minSettlement}
                helperText={errors.minSettlement ? errors.minSettlement.message : toIDR(minSettlement)}
                row
                prepend="Rp"
              />
              <SelectField
                name="Fee Rules"
                label="Fee Rules"
                options={[
                  {
                    value: "NONE",
                    label: "None"
                  },
                  {
                    value: "RULES",
                    label: "Rules"
                  },
                  {
                    value: "FIXED",
                    label: "Fixed"
                  }
                ]}
                onChange={(v) => setValue('feeRulesType', v?.value, { shouldValidate: true })}
                value={feeRulesType}
                error={errors.feeRulesType}
                row
                helperText={errors.feeRulesType ? errors.feeRulesType.message : null}
                componentProps={{
                  isClearable: true
                }}
              />
              {
                feeRulesType !== "NONE" &&
                <>
                  {
                    feeRulesType !== "FIXED" &&
                    <FormField
                      row
                      label="Fee Apply Limit"
                    >
                      <SelectField
                        name="operator"
                        options={[
                          {
                            value: ">",
                            label: "Greater Than"
                          },
                          {
                            value: "<",
                            label: "Less Than"
                          }
                        ]}
                        onChange={(v) => setValue('operator', v?.value, { shouldValidate: true })}
                        value={operator}
                        error={errors.operator}
                        helperText={errors.operator ? errors.operator.message : null}
                        componentProps={{
                          isClearable: true
                        }}
                      />
                      <HookTextField
                        ref={register}
                        name="feeApplyLimit"
                        error={errors.feeApplyLimit}
                        helperText={errors.feeApplyLimit ? errors.feeApplyLimit.message : toIDR(feeApplyLimit)}
                        prepend="Rp"
                      />
                    </FormField>
                  }
                  <FormField
                    row
                    label="Fee Type"
                  >
                    <RadioGroup
                      row
                      name="feeType"
                      value={feeType}
                      onChange={e => setValue('feeType', e.target.value)}
                    >
                      <FormControlLabel value="AMOUNT" control={<Radio color="primary" />} label="Amount" />
                      <FormControlLabel value="PERCENTAGE" control={<Radio color="primary" />} label="Percentage" />
                    </RadioGroup>
                  </FormField>
                  <HookTextField
                    ref={register}
                    label="Fee"
                    name="fee"
                    error={errors.fee}
                    helperText={errors.fee ? errors.fee.message : feeType === 'AMOUNT' ? toIDR(fee) : null}
                    row
                    append={feeType === 'PERCENTAGE' ? '%' : null}
                    prepend={feeType === 'AMOUNT' ? 'Rp' : null}
                    className={feeType === 'PERCENTAGE' ? 'text-right' : null}
                  />
                  {
                    feeType === 'PERCENTAGE' &&
                    <HookTextField
                      ref={register}
                      label="Maximum Settlement"
                      name="maxSettlement"
                      error={errors.maxSettlement}
                      helperText={errors.maxSettlement ? errors.maxSettlement.message : toIDR(maxSettlement)}
                      row
                      prepend="Rp"
                    />
                  }
                </>
              }
              <FormField row>
                <button type="submit" className="btn btn-primary">Submit</button>
              </FormField>
            </div>
          </div>
        </form>
      </div>
      <LoadingBlocker in={isCreating} />
    </div>
  )
}

export default CreateTab;
