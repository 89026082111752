import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { combineBy } from '../../utils/helpers/combineBy';
import useMountedState from '../HooksUse/useMountedState';
import SelectField from '../Forms/SelectField';
import { adminApi } from '../../services/adminApi';
import { AdminRole } from '../../utils/constants/enums/adminRoles';

const staticValues = {
  sort: 'firstName|asc',
  limit: 10,
}

const generateOpts = rows => rows.map(row => ({
  value: row._id,
  label: `${combineBy([row.firstName, row.lastName])} | ${AdminRole.getStr(row.role)}`,
  model: 'ADMIN'
}))

const AdminSelect = ({
  onChange,
  value,
  error,
  helperText,
  label
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const { sort, limit } = staticValues
  const isMounted = useMountedState()
  const [initialValue] = useState(value)

  const listDebounced = useMemo(
    () => AwesomeDebouncePromise(adminApi.list, 500),
    [],
  )

  const dispatchLoad = useCallback(
    (call, page, limit, sort, search, searchByID) => {
      return dispatch({
        type: null,
        call: call,
        args: [
          page,
          limit,
          search ? null : sort,
          searchByID ? `_id|${searchByID}` : search ? `firstName|${search},lastName|${search}` : null
        ]
      })
    },
    [dispatch],
  )

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);

      dispatchLoad(adminApi.list, newPage, limit, sort, search)
        .then(({ data: { admins, count } }) => {
          if (isMounted()) {
            setOptions([...options, ...generateOpts(admins)])
            setTotalItems(count)
          }
        })
        .finally(() => {
          if (isMounted()) setIsLoading(false);
        });
    }
  }

  const handleSelectChange = opt => {
    setSearch('')
    setSelectedOption(opt)

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : (opt ? opt.value : opt)
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(listDebounced, newPage, limit, sort, search)
        .then(({ data: { admins, count } }) => {
          if (isMounted()) {
            options = generateOpts(admins)
            setTotalItems(count)
            const initial = typeof initialValue === 'object' ? initialValue.value : initialValue
            let selectionExist = Boolean(admins.find(v => v._id === initial))
            if (initial && !search && !selectionExist) return dispatchLoad(listDebounced, 0, 0, null, null, initial)
          }
        })
        .then(({ data: { admins } = {} } = {}) => {
          if (isMounted() && admins) options = [...generateOpts(admins), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setIsLoading(false)
            setOptions(options)
          }
        });
    }
    loadData()
  }, [search, sort, limit, dispatchLoad, listDebounced, isMounted, initialValue])

  return (
    <SelectField
      label={label ?? "Select Admin"}
      id="selectAdmin"
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder='Type anything..'
      componentProps={{
        inputValue: search,
        onInputChange: setSearch,
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom,
      }}
      error={error}
      helperText={helperText}
    />
  )
}

export default AdminSelect
