import React, { useEffect } from 'react';
import { APP_LOAD, CLEAN_AUTH_STATE, SOCKET_CONNECTED, SOCKET_DISCONNECTED } from './utils/constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import agent from './services/agent';
import AdminLogin from './pages/Login';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { Helmet } from "react-helmet";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useNotification } from './components/HooksUse/useNotification';
import './styles/App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { authApi } from './services/authApi';
import MainLayout from './MainLayout';
import useIsMainWindow from './useIsMainWindow';
import Preview from './pages/Preview';

const theme = createTheme({
  palette: {
    primary: { main: '#1877F2' },
    error: { main: '#FF3B30' },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { appLoaded, currentAdmin, socket } = useSelector(state => state.common);
  const [successNotify] = useNotification('success', true);
  const [errorNotify] = useNotification('error', true);

  const isMain = useIsMainWindow()

  useEffect(() => {
    const token = window.localStorage.getItem('token');

    const onMount = (call, token) => dispatch({ type: APP_LOAD, call, token });
    const cleanAuthState = () => dispatch({ type: CLEAN_AUTH_STATE });
    const onSocketConnect = (id) => dispatch({ type: SOCKET_CONNECTED, id });
    const onSocketDisconnect = () => dispatch({ type: SOCKET_DISCONNECTED });

    const subscribeDefaultEvent = () => {
      if (socket.connected) onSocketConnect(socket.id);
      socket.on("connect", () => {
        onSocketConnect(socket.id);
        successNotify('Socket Connected');
      });
      socket.on("disconnect", () => {
        onSocketDisconnect();
        errorNotify('Socked Disconnected');
      });
    }

    if (token) {
      agent.setToken(token);
    } else if (!token) {
      cleanAuthState();
    }

    onMount(token ? authApi.current : null, token);
    subscribeDefaultEvent();
  }, [dispatch, socket, successNotify, errorNotify])

  useEffect(() => {
    const currentHost = window.location.host;
    const targetHost = `${process.env.REACT_APP_TARGET_HOST}`;
    const targetHost2 = `${process.env.REACT_APP_TARGET_HOST2}`;

    if (targetHost && currentHost !== targetHost && currentHost !== targetHost2) {
      window.location.href = `https://${targetHost}`;
    }
  }, []);

  return appLoaded ?
    isMain ?
      <>
        <Helmet defaultTitle="Dipay - Admin" />
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Switch>
              <Route path="/preview" component={Preview} />
              <Route exact path="/login" render={(props) => (
                currentAdmin
                  ?
                  <Redirect to="/" />
                  :
                  <AdminLogin {...props} />
              )} />
              <Route path="/" render={(props) => (
                currentAdmin
                  ?
                  <MainLayout {...props} />
                  :
                  <Redirect to="/login" />
              )} />
            </Switch>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
        <ToastContainer
          hideProgressBar={false}
          className="toast-container"
        />
      </> :
      <>
        <Helmet>
          <title>Loading</title>
        </Helmet>
        <div className="h-100 bg-arielle-smile bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="text-center text-white">
              <h3>Warning!</h3>
              <h5>App is already opened in another tab!</h5>
              <div className="spinner-border"></div>
            </div>
          </div>
        </div>
      </>
    :
    <>
      <Helmet>
        <title>Loading</title>
      </Helmet>
      <div>
        Loading
      </div>
    </>
}

export default App;
