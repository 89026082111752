import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const closedUserApi = {
  list: ({ page, limit, sort, search, status }) => {
    let url = `/closed-users/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (status) {
      url += `&filter=${status}`;
    }
    return agent.requests.get(url)
  },
  create: data =>
    agent.requests.post(`/closed-users/create`, data),
  approve: (id) =>
    agent.requests.post(`/closed-users/${id}/approve`),
  decline: (id, remarks) =>
    agent.requests.post(`/closed-users/${id}/decline`, { remarks }),
}
