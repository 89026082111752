import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, ADD_DELETE_DATA, REMOVE_DELETE_DATA, DELETE, BANK_ACCOUNT_MODULE, CREATE_TAB, FILTER_TABLE } from '../../../utils/constants/actionTypes';
import ButtonDispatch from '../../../components/ButtonDispatch';
import useMountedState from '../../../components/HooksUse/useMountedState';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import SelectField from '../../../components/Forms/SelectField';
import useAsync from '../../../components/HooksUse/useAsync';
import { bankApi } from '../../../services/bankApi';
import { bankAccountApi } from '../../../services/bankAccountApi';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    deleteRowId,
    items,
    total,
    filter
  } = useSelector(state => state.bankAccount);
  const isMounted = useMountedState()

  const {
    value: { data: { banks = [] } = {} } = {},
    pending: isGettingBank
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: bankApi.list, args: [0, 0] }),
    [dispatch]), true)
  const bankOpts = banks.map(row => ({ value: row._id, label: row.name }))

  const getBankAccountDebounced = useMemo(
    () => AwesomeDebouncePromise(bankAccountApi.list, 500),
    [],
  )

  const load = useCallback(
    () => {
      const search = filter.search ? `accountName|${filter.search},accountNumber|${filter.search}` : null;
      dispatch({
        module: BANK_ACCOUNT_MODULE,
        type: MAIN_TAB_LOAD,
        call: getBankAccountDebounced,
        args: [currentPage, limit, sort, null, null, null, search, filter.bank]
      });
    },
    [dispatch, currentPage, limit, sort, filter, getBankAccountDebounced]
  )

  useEffect(() => {
    load();
  }, [load]);

  const setTableConfig = type => (value, key) => {
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: type,
      value,
      key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const table = useMemo(() => {
    const createTab = (key, title) => {
      dispatch({
        module: BANK_ACCOUNT_MODULE,
        type: CREATE_TAB,
        key,
        title
      });
    }

    const deleteSelected = () => {
      if (deleteRowId) {
        dispatch({ module: BANK_ACCOUNT_MODULE, type: DELETE, call: bankAccountApi.delete, args: [deleteRowId] })
          .then(() => { if (isMounted()) load() })
          .catch(() => { })
      }
    }

    return ({
      search: true,
      inProgress,
      data: items,
      sort,
      limit,
      total,
      currentPage,
      columns: [
        { title: 'Account Name', key: 'accountName', name: 'accountName' },
        { title: 'Account Number', key: 'accountNumber', name: 'accountNumber' },
        { title: 'Bank', key: 'bank.name', name: 'bank' },
        { title: 'Owner', key: 'ownerModel', name: 'ownerModel' },
        {
          title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
            return format(new Date(v), 'yyyy-MM-dd')
          }
        },
        {
          title: 'Action', key: '_id', name: 'action', className: 'action-column', sortable: false, render: (v, row) => {
            return (
              <div className="d-flex">
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => createTab(v, row.accountName)}
                >
                  <i className="pe-7s-tools btn-icon-wrapper" />
                </button>
                <div className="btn-group ml-1">
                  {deleteRowId === v ? (
                    <>
                      <ButtonDispatch type={REMOVE_DELETE_DATA} module={BANK_ACCOUNT_MODULE} className="btn btn-sm btn-danger lh-1">No</ButtonDispatch>
                      <button onClick={deleteSelected} type="button" className="btn btn-sm btn-danger lh-1 py-0">Yes, Delete</button>
                    </>
                  ) : (
                    <ButtonDispatch className="btn btn-icon btn-icon-only btn-sm btn-danger" args={{ _id: v }} module={BANK_ACCOUNT_MODULE} type={ADD_DELETE_DATA}>
                      <i className="pe-7s-trash btn-icon-wrapper"></i>
                    </ButtonDispatch>
                  )}
                </div>
              </div>
            )
          }
        }
      ]
    })
  }, [inProgress, items, sort, limit, total, currentPage, deleteRowId, dispatch, load, isMounted])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          onSearch={onFilter('search')}
          onClearSearch={() => onFilter('search')('')}
          searchValue={filter.search}
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Bank"
                placeholder="No Filter"
                options={bankOpts}
                onChange={v => onFilter('bank')(v?.value)}
                value={filter.bank}
                componentProps={{
                  isClearable: true,
                  isLoading: isGettingBank,
                }}
              />
            </div>
          </div>
        </Table>
      </div>
    </div>
  )
}

export default MainTab;
