import React, { useEffect } from 'react'
import * as yup from 'yup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { CashbackType } from '../../../utils/constants/enums/cashbackTypes';
import BusinessSelect from '../../../components/Select/BusinessSelect';
import HookTextField from '../../../components/Forms/HookTextField';
import { useForm } from 'react-hook-form';
import { toIDR } from '../../../utils/helpers/currency';
import { yupResolver } from '@hookform/resolvers';
import SelectField from '../../../components/Forms/SelectField';
import FormField from '../../../components/Forms/FormField';
import { endOfDay, format, startOfDay } from 'date-fns';
import activeStatusOpts from '../../../utils/constants/enums/status/activeStatusOpts';

const schema = yup.object().shape({
  businesses: yup.mixed()
    .test(
      'required',
      'Businesses is required',
      value => value && Array.isArray(value)
    ),
  percentage: yup.number().typeError('Must be a number').required().min(0).max(100),
  startAt: yup.string().required(),
  endAt: yup.string().required(),
  code: yup.string().required(),
  tncUpgraded: yup.object().shape({
    spanDays: yup.number().typeError('Please select one').required(),
    maxAmount: yup.number().typeError('Must be a number').required(),
    maxAccumulate: yup.number().typeError('Must be a number').required(),
    minTransactionAmount: yup.number().typeError('Must be a number').required()
  }),
  tnc: yup.object().shape({
    spanDays: yup.number().typeError('Please select one').required(),
    maxAmount: yup.number().typeError('Must be a number').required(),
    maxAccumulate: yup.number().typeError('Must be a number').required(),
    minTransactionAmount: yup.number().typeError('Must be a number').required()
  }),
  active: yup.string().required()
});

const FormTab = ({
  initialValue,
  isLoading,
  onSubmit,
}) => {
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      startAt: startOfDay(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      endAt: endOfDay(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      tnc: {
        spanDays: CashbackType.getSpanDay(CashbackType.WEEK)
      },
      tncUpgraded: {
        spanDays: CashbackType.getSpanDay(CashbackType.WEEK)
      },
      ...initialValue
    }
  });

  const handleDateChange = field => date => {
    let v;
    if (field === 'startAt') {
      v = startOfDay(new Date(date));
    } else if (field === 'endAt') {
      v = endOfDay(new Date(date));
    }
    setValue(field, format(new Date(v), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), { shouldValidate: true });
  }

  const handleSelectChange = opts => {
    setValue('businesses', opts, { shouldValidate: true });
  }

  const handleRadioChange = type => e => {
    setValue(type, e.target.value, { shouldValidate: true });
  }

  const {
    businesses,
    startAt,
    endAt,
    tnc,
    tncUpgraded,
    active
  } = watch(['businesses', 'startAt', 'endAt', 'tnc', 'tncUpgraded', 'active']);

  useEffect(() => {
    register('businesses');
    register('active');
    register('startAt');
    register('endAt');
    register('tnc.spanDays');
    register('tncUpgraded.spanDays')

    return () => {
      unregister('businesses');
      unregister('active');
      unregister('startAt');
      unregister('endAt');
      unregister('tnc.spanDays');
      unregister('tncUpgraded.spanDays')
    }
  }, [register, unregister])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">GENERAL</h5>
            </div>
            <div className="col-12 col-md-9">
              <BusinessSelect
                withFilter
                isMulti
                enableSelectAll
                closeMenuOnSelect={false}
                value={businesses}
                onChange={handleSelectChange}
                error={errors.businesses}
                helperText={errors.businesses ? errors.businesses?.message : null}
              />
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Cashback Percentage"
                name="percentage"
                error={errors.percentage}
                helperText={errors.percentage ? errors.percentage?.message : 'Value: 0 - 100'}
                placeholder="e.g: 10"
              />
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Code"
                name="code"
                error={errors.code}
                helperText={errors.code ? errors.code?.message : null}
                placeholder="e.g: CESBEK50PERSEN"
              />
              <SelectField
                label="Set Active Status"
                options={activeStatusOpts}
                onChange={v => setValue('active', v?.value, { shouldValidate: true })}
                value={active}
                error={errors.active}
                helperText={errors.active ? errors.active?.message : null}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Date</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="date-range">
                <div>
                  <label>Start Date</label>
                  <EvyDatePicker
                    value={startAt}
                    onChange={handleDateChange('startAt')}
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    disableToolbar
                  />
                </div>
                <div>
                  <label>End Date</label>
                  <EvyDatePicker
                    value={endAt}
                    onChange={handleDateChange('endAt')}
                    margin="dense"
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    disableToolbar
                    disablePast
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Rules</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h5 className="card-title">UNVERIFIED USER</h5>
                  <hr />
                  <FormField
                    label="Type"
                    error={errors.tnc?.spanDays}
                    helperText={errors.tnc?.spanDays?.message}
                  >
                    <RadioGroup row value={String(tnc?.spanDays)} onChange={handleRadioChange('tnc.spanDays')}>
                      <FormControlLabel value={String(CashbackType.getSpanDay(CashbackType.WEEK))} control={<Radio color="primary" />} label={CashbackType.WEEK} />
                      <FormControlLabel value={String(CashbackType.getSpanDay(CashbackType.MONTH))} control={<Radio color="primary" />} label={CashbackType.MONTH} />
                    </RadioGroup>
                  </FormField>
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Max / Merchant"
                    name="tnc.maxAmount"
                    error={errors.tnc?.maxAmount}
                    helperText={errors.tnc && errors.tnc.maxAmount ? errors.tnc && errors.tnc.maxAmount?.message : toIDR(tnc?.maxAmount)}
                    placeholder="e.g: 10000"
                  />
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Max Accumulative"
                    name="tnc.maxAccumulate"
                    error={errors.tnc?.maxAccumulate}
                    helperText={errors.tnc && errors.tnc.maxAccumulate ? errors.tnc && errors.tnc.maxAccumulate?.message : toIDR(tnc?.maxAccumulate)}
                    placeholder="e.g: 50000"
                  />
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Minimum Transaction Amount"
                    name="tnc.minTransactionAmount"
                    error={errors.tnc?.minTransactionAmount}
                    helperText={errors.tnc && errors.tnc.minTransactionAmount ? errors.tnc && errors.tnc.minTransactionAmount?.message : toIDR(tnc?.minTransactionAmount)}
                    placeholder="e.g: 10000"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <h5 className="card-title">VERIFIED USER</h5>
                  <hr />
                  <FormField
                    label="Type"
                    error={errors.tncUpgraded?.spanDays}
                    helperText={errors.tncUpgraded?.spanDays?.message}
                  >
                    <RadioGroup row value={String(tncUpgraded?.spanDays)} onChange={handleRadioChange('tncUpgraded.spanDays')}>
                      <FormControlLabel value={String(CashbackType.getSpanDay(CashbackType.WEEK))} control={<Radio color="primary" />} label={CashbackType.WEEK} />
                      <FormControlLabel value={String(CashbackType.getSpanDay(CashbackType.MONTH))} control={<Radio color="primary" />} label={CashbackType.MONTH} />
                    </RadioGroup>
                  </FormField>
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Max / Merchant"
                    name="tncUpgraded.maxAmount"
                    error={errors.tncUpgraded?.maxAmount}
                    helperText={errors.tncUpgraded && errors.tncUpgraded.maxAmount ? errors.tncUpgraded && errors.tncUpgraded.maxAmount?.message : toIDR(tncUpgraded?.maxAmount)}
                    placeholder="e.g: 20000"
                  />
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Max Accumulative"
                    name="tncUpgraded.maxAccumulate"
                    error={errors.tncUpgraded?.maxAccumulate}
                    helperText={errors.tncUpgraded && errors.tncUpgraded.maxAccumulate ? errors.tncUpgraded && errors.tncUpgraded.maxAccumulate?.message : toIDR(tncUpgraded?.maxAccumulate)}
                    placeholder="e.g: 100000"
                  />
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Minimum Transaction Amount"
                    name="tncUpgraded.minTransactionAmount"
                    error={errors.tncUpgraded?.minTransactionAmount}
                    helperText={errors.tncUpgraded && errors.tncUpgraded.minTransactionAmount ? errors.tncUpgraded && errors.tncUpgraded.minTransactionAmount?.message : toIDR(tncUpgraded?.minTransactionAmount)}
                    placeholder="e.g: 10000"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FormTab
