import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { format } from 'date-fns'
import IdentityImage from '../../../../components/IdentityImage';
import { combineBy } from '../../../../utils/helpers/combineBy';
import { KYC_HISTORIES_MODULE, GET_DETAIL } from '../../../../utils/constants/actionTypes';
import useAsync from '../../../../components/HooksUse/useAsync';
import { userApi } from '../../../../services/userApi';
import { AdminRole } from '../../../../utils/constants/enums/adminRoles';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { key, userId, loading } = useSelector(state => state.kyc.histories.tabs[index]);

  const {
    value: { data: item } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: KYC_HISTORIES_MODULE,
      type: GET_DETAIL,
      call: userApi.upgradeHistoryDetail,
      args: [{
        userId,
        upgradeHistoryId: key
      }],
      tabKey: index
    }),
    [dispatch, key, index, userId]), true)

  return loading && !item ?
    null
    :
    item ?
      <>
        <div className="card mb-3">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-12 col-xl-6">
                <IdentityImage label="Card Photo" image={item.archive.upgradeHistories.cardPhoto.url} />
              </div>
              <div className="col-12 col-xl-6">
                <IdentityImage label="Self Photo" image={item.archive.upgradeHistories.selfPhoto.url} />
              </div>
            </div>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="ID Number"
                value={item.archive.upgradeHistories.identificationNumber}
              />
              <EvyTextField
                readOnly
                row
                label="Status"
                value={item.archive.upgradeHistories.status}
              />
              <EvyTextField
                readOnly
                row
                label="Remarks"
                value={item.archive.upgradeHistories.remarks}
                multiline
              />
              <EvyTextField
                readOnly
                row
                label="Processed At"
                value={format(new Date(item.processedAt), 'yyyy-MM-dd HH:mm:ss')}
              />
            </div>
            {item.archive.upgradeHistories.checker &&
              <>
                <h6 className="card-title">Checker</h6>
                <div>
                  <EvyTextField
                    readOnly
                    row
                    label="Full Name"
                    value={combineBy([item.archive.upgradeHistories.checker.firstName, item.archive.upgradeHistories.checker.lastName])}
                  />
                  <EvyTextField
                    readOnly
                    row
                    label="Email"
                    value={item.archive.upgradeHistories.checker.email}
                  />
                  <EvyTextField
                    readOnly
                    row
                    label="Role"
                    value={AdminRole.getStr(item.archive.upgradeHistories.checker.role)}
                  />
                </div>
              </>
            }
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <h6 className="card-title">User Detail</h6>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="User ID"
                value={item.uniqueId}
                helperText={`Created at: ${item.createdAt ? format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm') : '-'}`}
              />
              <EvyTextField
                readOnly
                row
                label="First Name"
                value={item.firstName}
              />
              <EvyTextField
                readOnly
                row
                label="Last Name"
                value={item.lastName}
              />
              <EvyTextField
                readOnly
                row
                label="Job"
                value={item.job}
              />
              <EvyTextField
                readOnly
                row
                label="Email"
                value={item.email}
                helperText={`Verified at: ${item.status.emailVerifiedAt ? format(new Date(item.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
              />
              <EvyTextField
                readOnly
                row
                label="Phone Number"
                value={item.phoneNumber}
                helperText={`Verified at: ${item.status.phoneNumberVerifiedAt ? format(new Date(item.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
              />
            </div>
          </div>
        </div>
      </>
      :
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab
