import React, { useState } from 'react'
import FormField from '../../../../components/Forms/FormField'
import HookTextField from '../../../../components/Forms/HookTextField'
import LoadingBtn from '../../../../components/Forms/LoadingBtn'
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { useDispatch } from 'react-redux';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import { companyApi } from '../../../../services/companyApi';

const schema = yup.object().shape({
  csOperationalHour: yup.string().required(),
  csEmailOprHour: yup.string().required(),
  csWaOprHour: yup.string().required(),

  csPhoneNumber: yup.string().required(),
  csEmail: yup.string().required(),
  csWa: yup.string().required()
});

const OperationHours = ({ item, onSuccess = () => { } }) => {
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [updating, setUpdating] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      csOperationalHour: item?.csOperationalHour,
      csEmailOprHour: item?.csEmailOprHour,
      csWaOprHour: item?.csWaOprHour,
      csPhoneNumber: item?.csPhoneNumber,
      csEmail: item?.csEmail,
      csWa: item?.csWa,
    }
  });

  const onSubmit = (values) => {
    setUpdating(true);
    dispatch({ type: null, call: companyApi.update, args: [item._id, values] })
      .then(() => {
        if (isMounted()) onSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setUpdating(false) });
  };

  return (
    <div className="col-9">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <div>
            <h6>Phone</h6>
          </div>
          <hr />
          <HookTextField
            ref={register}
            label="CS Phone Number"
            name="csPhoneNumber"
            error={errors.csPhoneNumber}
            helperText={errors.csPhoneNumber?.message}
            placeholder="e.g. 021-***-***"
            row
          />
          <HookTextField
            ref={register}
            label="Operational Hour"
            name="csOperationalHour"
            error={errors.csOperationalHour}
            helperText={errors.csOperationalHour?.message}
            placeholder="e.g. Senin - Jumat (09:00 - 17:00)"
            row
          />
        </div>
        <div className="mb-5">
          <div>
            <h6>Email</h6>
          </div>
          <hr />
          <HookTextField
            ref={register}
            label="CS Email"
            name="csEmail"
            error={errors.csEmail}
            helperText={errors.csEmail?.message}
            placeholder="e.g. cs@dipay.id"
            row
          />
          <HookTextField
            ref={register}
            label="CS Email Opr Hour"
            name="csEmailOprHour"
            error={errors.csEmailOprHour}
            helperText={errors.csEmailOprHour?.message}
            placeholder="e.g. Senin - Jumat (09:00 - 17:00)"
            row
          />
        </div>
        <div>
          <div>
            <h6>Whatsapp</h6>
          </div>
          <hr />
          <HookTextField
            ref={register}
            label="CS Whatsapp"
            name="csWa"
            error={errors.csWa}
            helperText={errors.csWa?.message}
            placeholder="e.g. +62811*******"
            row
          />
          <HookTextField
            ref={register}
            label="CS Whatsapp Opr Hour"
            name="csWaOprHour"
            error={errors.csWaOprHour}
            helperText={errors.csWaOprHour?.message}
            row
          />
        </div>
        <FormField row>
          <LoadingBtn type="submit" loading={updating} className="btn btn-primary btn-lg">Update</LoadingBtn>
        </FormField>
      </form>
    </div>
  )
}

export default OperationHours
