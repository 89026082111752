import { yupResolver } from '@hookform/resolvers';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import * as yup from 'yup';
import HookTextField from '../../../../components/Forms/HookTextField';
import FormField from '../../../../components/Forms/FormField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import { companyApi } from '../../../../services/companyApi';

const schema = yup.object().shape({
  fullName: yup.string().required(),
  shortName: yup.string().required()
});

const GeneralInfo = ({ item, onSuccess = () => { } }) => {
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [updating, setUpdating] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: item?.fullName,
      shortName: item?.shortName
    }
  });

  const onSubmit = (values) => {
    setUpdating(true);
    dispatch({ type: null, call: companyApi.update, args: [item._id, values] })
      .then(() => {
        if (isMounted()) onSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setUpdating(false) });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HookTextField
        ref={register}
        label="Full Name"
        name="fullName"
        error={errors.fullName}
        helperText={errors.fullName?.message}
        row
      />
      <HookTextField
        ref={register}
        label="Short Name"
        name="shortName"
        error={errors.shortName}
        helperText={errors.shortName?.message}
        row
      />
      <FormField row>
        <LoadingBtn type="submit" loading={updating} className="btn btn-primary btn-lg">Update</LoadingBtn>
      </FormField>
    </form>
  )
}

export default GeneralInfo
