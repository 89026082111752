import React from 'react';
import { format } from 'date-fns'
import FlagIcon from '@material-ui/icons/Flag';
import RoundBadge from './Badge/RoundBadge';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/Lock';
import BlockIcon from '@material-ui/icons/Block';
import AllStatus from '../utils/constants/enums/status';
import HorizontalBadge from './Badge/HorizontalBadge';

const flaggedListType = ['LOCKED', 'CLOSED_USER', 'PRESUMED_TERRORIST'];

const UserStatuses = ({ user }) => {
  const getNewUsers = () => (
    user.status?.isNewUser
    &&
    <HorizontalBadge
      className="mr-2"
      title='New User'
      variant="danger"
      active={user.status?.isNewUser}
      contentInActive={<div className='mr-3'></div>}
      content="NEW"
    />
  );

  const getVerifiedOrClosed = () => {
    if (user.status?.closed) {
      return <RoundBadge
        title="User Closed"
        active={true}
        variant="danger"
        icon={CancelIcon}
      />;
    } else {
      return <RoundBadge
        title={user.status?.upgraded ? `KYC Verified at: ${format(new Date(user.archive.identityCard.updatedAt), 'yyyy-MM-dd HH:mm:ss')}` : user.kycProgress?.isForce ? user.kycProgress?.forcedAt ? `Forced KYC at: ${format(new Date(user.kycProgress?.forcedAt), 'yyyy-MM-dd HH:mm:ss')}` : 'Forced KYC' : 'Not Upgraded'}
        active={user.status?.upgraded || user.kycProgress?.isForce}
        variant={user.status?.upgraded ? 'primary' : 'danger'}
        icon={VerifiedUserIcon}
      />;
    }
  }

  const getFlagged = () => {
    const failedAttempts = user?.failedAttempts?.length ? [...user.failedAttempts].reverse() : [];
    const latestAttempt = failedAttempts.find(a => {
      return flaggedListType.includes(a?.name);
    });

    return (
      <RoundBadge
        title={user?.isFlagged ? `Flagged at: ${latestAttempt ? format(new Date(latestAttempt?.createdAt), 'yyyy-MM-dd HH:mm:ss') : '-'}` : 'Not Flagged'}
        active={user?.isFlagged}
        variant="warning"
        icon={FlagIcon}
      />
    )
  }

  const getLocked = () => {
    const failedAttempts = user?.failedAttempts?.length ? [...user.failedAttempts].reverse() : [];
    const latestAttempt = failedAttempts.find(a => {
      return a?.name === AllStatus.LOCKED;
    });

    return (
      <RoundBadge
        title={user?.status?.locked ? `Locked at: ${latestAttempt ? format(new Date(latestAttempt?.createdAt), 'yyyy-MM-dd HH:mm:ss') : '-'}` : 'Not Locked'}
        active={user?.status?.locked}
        variant="danger"
        icon={LockIcon}
      />
    )
  }

  const getSuspended = () => {
    const failedAttempts = user?.failedAttempts?.length ? [...user.failedAttempts].reverse() : [];
    const latestAttempt = failedAttempts.find(a => {
      return a?.name === AllStatus.SUSPENDED;
    });

    return (
      <RoundBadge
        title={user?.status?.suspended ? `Suspended at: ${latestAttempt ? format(new Date(latestAttempt?.createdAt), 'yyyy-MM-dd HH:mm:ss') : '-'}` : 'Not Suspended'}
        active={user?.status?.suspended}
        variant="danger"
        icon={BlockIcon}
      />
    )
  }

  return (
    <div className="d-flex align-items-center">
      {getNewUsers()}
      {getVerifiedOrClosed()}
      {getFlagged()}
      {getLocked()}
      {getSuspended()}
    </div>
  )
}

export default UserStatuses;
