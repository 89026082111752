import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PPOB_MODULE } from '../../../../utils/constants/actionTypes';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Column, Table } from 'react-virtualized';
import ReactDOMServer from 'react-dom/server';
import 'react-virtualized/styles.css';
import useAsync from '../../../../components/HooksUse/useAsync';
import styles from './ppob.module.scss';
import { PpobSupportedTypeEnum } from '../../../../utils/constants/enums/ppobSupportedTypes';
import { ppobApi } from '../../../../services/ppobApi';
import { ExcelIcon } from '../../../../components/Icons/Icons'
import { ExcelTable } from '../../../../components/Table/ExcelTable';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))
const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const ProductList = ({ type }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const getProduct = useCallback(
    () => dispatch({
      module: PPOB_MODULE,
      type: null,
      call: ppobApi.getProduct,
      args: [type]
    })
      .catch(() => { }),
    [type, dispatch],
  )

  const { value: { data: products = [] } = {}, pending: isGettingProduct } = useAsync(getProduct, PpobSupportedTypeEnum.includes(type))

  const filteredProducts = useMemo(() => {
    const filter = (arr, search) => {
      return arr.filter(function (element) {
        let ref = ['code', 'product', 'biller']
        return ref.some(el => String(element[el]).toLowerCase().includes(search.toLowerCase()));
      });
    }
    return search ? filter(products, search) : products
  }, [products, search])

  const excelColumns = useMemo(() => [
    { title: 'Code', key: 'code', name: 'code' },
    { title: 'Product', key: 'product', name: 'product' },
    { title: 'Denom', key: 'denom', name: 'denom' },
    { title: 'Price', key: 'price', name: 'price' },
    { title: 'Biller', key: 'biller', name: 'biller' },
  ], [])

  const exportExcel = useCallback(() => {
    const generatedTable = ExcelTable({ columns: excelColumns, items: filteredProducts })
    const html = ReactDOMServer.renderToStaticMarkup(generatedTable);
    const excelHref = 'data:application/vnd.ms-excel;base64,'

    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{html}</body></html>';

    const context = {
      worksheet: 'Worksheet',
      html,
    };

    let a = document.createElement('a')
    a.href = excelHref + base64(format(template, context));
    a.download = "tes"
    if (a.download.split(".").filter(el => el.includes("xls")).length > 1) {
      a.download = a.download.replace(".xls", "")
    }
    a.click()
  }, [excelColumns, filteredProducts]);

  return (
    <div className={`product-list-root${open ? ' open' : ''}`}>
      <button type="button" className="btn btn-primary toggler" onClick={e => setOpen(!open)}>
        <p>Product</p>
      </button>
      <div className="product-list-body">
        <div className="product-search-wrapper">
          <div>
            <h5 className="card-title mb-0">{type.replace('_', ' ')}</h5>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-excel btn-icon-only btn-sm mr-2"
              onClick={exportExcel}
            >
              <ExcelIcon size={20} />
            </button>
            <TextField
              placeholder="Search.."
              value={search}
              onChange={e => setSearch(e.target.value)}
              margin="none"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {PpobSupportedTypeEnum.includes(type) ?
          <TableComponent products={filteredProducts} />
          :
          <div className="p-3 text-center">
            <h5 className="card-title">Sorry, Type is not supported yet</h5>
          </div>
        }
        <LoadingBlocker in={isGettingProduct} />
      </div>
    </div>
  )
}

const TableComponent = ({ products }) => {
  const productKeys = ['code', 'product', 'denom', 'price', 'biller']
  return (
    <div className="table-wrapper">
      {products.length > 0 ?
        <Table
          width={700}
          height={436}
          headerHeight={53}
          rowHeight={53}
          rowCount={products.length}
          rowGetter={({ index }) => products[index]}
          rowStyle={{
            borderBottom: '1px solid #ccc',
            paddingLeft: '10px'
          }}
          gridClassName="custom-virtualized-grid"
        >
          {productKeys.map((key, i) => (
            <Column
              key={i}
              label={key}
              dataKey={key}
              width={120}
              cellRenderer={({
                cellData,
                rowData
              }) => key === 'biller' ?
                  <div className={styles.billerColumn} title={cellData}>
                    <div>{cellData}</div>
                    <div className={`${styles.activeIndicator}${['active', 'aktif'].includes(rowData.status?.toLowerCase()) ? ' ' + styles.active : ''}`} />
                  </div> :
                  key === 'code' ?
                    cellData &&
                    <div title={cellData}>
                      <CopyToClipboard text={cellData} className="mr-2 cursor-pointer btn">
                        <button>
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </CopyToClipboard>
                      <span>
                        {cellData}
                      </span>
                    </div>
                    :
                    cellData
              }
            />
          ))}
        </Table>
        :
        <div className="p-3 text-center">
          <h5 className="card-title">No Data</h5>
        </div>
      }
    </div>
  )
}

export default ProductList;
