import React, { useEffect } from 'react'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import HookTextField from '../../components/Forms/HookTextField';
import EvySelectField from '../../components/Forms/EvySelectField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EvyDatePicker from '../../components/Forms/EvyDatePicker';
import LoadingBtn from '../../components/Forms/LoadingBtn';
import { IMAGE_FORMATS } from '../../utils/constants/actionTypes';
import { POINT, PRIMARY } from '../../utils/constants/dataTypes';
import { REDEEM, CASHBACK } from '../../utils/constants/voucherTypes';
import HookImage from '../../components/Forms/HookImage';
import { numberRegExp } from '../../utils/constants/regexTypes';
import { endOfDay, startOfDay } from 'date-fns'
import VoucherStatusOpts from '../../utils/constants/enums/status/voucherStatusOpts';

const schema = yup.object().shape({
  code: yup.string().required(),
  quantity: yup.string().required().matches(numberRegExp, 'Must be a number'),
  status: yup.string(),
  startAt: yup.string().required(),
  endAt: yup.string().required(),
  attachment: yup.mixed().test(
    "fileFormat",
    "Unsupported Format",
    value => value?.[0] ? IMAGE_FORMATS.includes(value[0].type) : true
  ),
  tnc: yup.object({
    user: yup.object({
      upgrade: yup.boolean().default(false),
    }),
    type: yup.string(),
    claimable: yup.string().required().matches(numberRegExp, 'Must be a number'),
    minTransaction: yup.string().required().matches(numberRegExp, 'Must be a number'),

    redeem: yup.object().when('type', {
      is: REDEEM,
      then: yup.object({
        fixed: yup.number().required('Redeem Fixed Price is required').typeError('Must be a number'),
        fixedType: yup.string().required('Redeem Fixed Type is required')
      })
    }),
    cashback: yup.object().when('type', {
      is: CASHBACK,
      then: yup.object({
        percentage: yup.string().required().matches(numberRegExp, 'Must be a number'),
        fixed: yup.string().required().matches(numberRegExp, 'Must be a number'),
        max: yup.string().required().matches(numberRegExp, 'Must be a number')
      })
    })
  })
})

const MyForm = ({ initialValues, onSubmit, isLoading, previousImageUrl }) => {
  const { register, unregister, setValue, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      startAt: startOfDay(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      endAt: endOfDay(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      tnc: {
        user: {
          upgrade: false,
        },
        claimable: 0,
        minTransaction: 0,
        type: REDEEM,
        redeem: {
          fixed: 0,
          fixedType: POINT
        },
        cashback: {
          percentage: 0,
          fixed: 0,
          max: 0
        }
      },
      ...initialValues
    }
  });

  const {
    status,
    startAt,
    endAt,
    tnc: {
      type,
      user: {
        upgrade
      },
      redeem: {
        fixedType
      }
    }
  } = watch([
    'status',
    'startAt',
    'endAt',
    'tnc']);

  useEffect(() => {
    register("status")
    register("startAt")
    register("endAt")
    register("tnc.user.upgrade")
    register("tnc.type")
    register("tnc.redeem.fixedType")

    return () => {
      unregister("status")
      unregister("startAt")
      unregister("endAt")
      unregister("tnc.user.upgrade")
      unregister("tnc.type")
      unregister("tnc.redeem.fixedType")
    };
  }, [register, unregister]);

  const handleDateChange = field => date => {
    let v;
    if (field === 'startAt') {
      v = date ? startOfDay(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    } else if (field === 'endAt') {
      v = date ? endOfDay(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    }
    setValue(field, v, { shouldValidate: true })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Code"
            name="code"
            error={errors.code}
            helperText={errors?.code?.message ?? null}
            placeholder="e.g: VCR2019"
          />
          <HookTextField
            ref={register}
            label="Quantity"
            name="quantity"
            error={errors.quantity}
            helperText={errors?.quantity?.message ?? null}
            placeholder="e.g: 10000"
          />
          {status && (
            <EvySelectField
              label="Select Status"
              id="status"
              options={VoucherStatusOpts}
              onChange={(v) => setValue('status', v, { shouldValidate: true })}
              value={status}
              error={errors.status}
              helperText={errors?.status?.message ?? null}
            />
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Applicable Date</h5>
        </div>
        <div className="col-12 col-md-9">
          <div className="date-range">
            <div>
              <EvyDatePicker
                autoOk
                disableToolbar
                variant="static"
                format="dd/MM/yyyy"
                value={startAt}
                onChange={handleDateChange('startAt')}
                error={errors.startAt}
                helperText={errors?.startAt?.message ?? null}
              />
            </div>
            <div>
              <EvyDatePicker
                autoOk
                disableToolbar
                variant="static"
                format="dd/MM/yyyy"
                value={endAt}
                onChange={handleDateChange('endAt')}
                error={errors.endAt}
                helperText={errors?.endAt?.message ?? null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Cover</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookImage
            ref={register}
            previousImageUrl={previousImageUrl}
            name="attachment"
            format={IMAGE_FORMATS}
            error={errors.attachment}
            helperText={errors?.attachment?.message ?? null}
            onClear={() => setValue('attachment', null, { shouldValidate: true })}
            viewVariant="landscape"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Rules</h5>
        </div>
        <div className="col-12 col-md-9">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={upgrade}
                  onChange={(v) => setValue('tnc.user.upgrade', v.target.checked, { shouldValidate: true })}
                  color="primary"
                />
              }
              label="Only upgrade user"
            />
          </div>
          <HookTextField
            ref={register}
            label="Claimable"
            name="tnc.claimable"
            error={errors.tnc?.claimable}
            helperText={errors?.tnc?.claimable?.message ?? null}
            placeholder="e.g: 5000"
          />
          <HookTextField
            ref={register}
            label="Minimum Transaction"
            name="tnc.minTransaction"
            error={errors.tnc?.minTransaction}
            helperText={errors?.tnc?.minTransaction?.message ?? null}
            placeholder="e.g: 5000"
          />
          <div>
            <label>Type</label>
            <RadioGroup row name="tnc.type" value={type} onChange={(v) => setValue('tnc.type', v.target.value, { shouldValidate: true })}>
              <FormControlLabel value={REDEEM} control={<Radio color="primary" />} label={REDEEM} />
              <FormControlLabel value={CASHBACK} control={<Radio color="primary" />} label={CASHBACK} />
            </RadioGroup>
          </div>
          <div className="border-left pl-3">
            {type === REDEEM
              ?
              <>
                <HookTextField
                  ref={register}
                  label="Redeem Fixed Price"
                  name="tnc.redeem.fixed"
                  error={errors.tnc?.redeem?.fixed}
                  helperText={errors?.tnc?.redeem?.fixed?.message ?? null}
                  placeholder="e.g: 5000"
                />
                <div>
                  <label>Redeem Fixed Type</label>
                  <RadioGroup row name="tnc.redeem.fixedType" value={fixedType} onChange={(v) => setValue('tnc.redeem.fixedType', v.target.value, { shouldValidate: true })}>
                    <FormControlLabel value={POINT} control={<Radio color="primary" />} label={POINT} />
                    <FormControlLabel value={PRIMARY} control={<Radio color="primary" />} label={PRIMARY} />
                  </RadioGroup>
                </div>
              </>
              :
              <>
                <div>
                  <HookTextField
                    ref={register}
                    label="Cashback Percentage"
                    name="tnc.cashback.percentage"
                    error={errors.tnc?.cashback?.percentage}
                    helperText={errors.tnc?.cashback?.percentage?.message ?? null}
                    placeholder="e.g: 5000"
                  />
                </div>
                <HookTextField
                  ref={register}
                  label="Cashback Fixed Price"
                  name="tnc.cashback.fixed"
                  error={errors.tnc?.cashback?.fixed}
                  helperText={errors.tnc?.cashback?.fixed?.message ?? null}
                  placeholder="e.g: 5000"
                />
                <HookTextField
                  ref={register}
                  label="Cashback Max Price"
                  name="tnc.cashback.max"
                  error={errors.tnc?.cashback?.max}
                  helperText={errors.tnc?.cashback?.max?.message ?? null}
                  placeholder="e.g: 5000"
                />
              </>
            }
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MyForm
