import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const partnerSettlementApi = {
  list: ({ page, limit, sort }) => {
    let url = `/partner-settlements/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/partner-settlements/${id}/detail`),
  createBulk: () =>
    agent.requests.get('/partner-settlements/createbulk'),
  createReqSettlement: (hourTime) =>
    agent.requests.post('/partner-settlements/create-by-hour', { hourTime }, false),
  executeAll: () =>
    agent.requests.get('/partner-settlements/execbulk'),
  execute: (id) =>
    agent.requests.get(`/partner-settlements/${id}/execute`),
  approve: (id) =>
    agent.requests.get(`/partner-settlements/${id}/approve`),
  approveWithoutTransfer: (id, data) =>
    agent.requests.post(`/partner-settlements/${id}/approve-without-bank-transfer`, data, true),
  reject: (id, remark) => agent.requests.post(`/partner-settlements/${id}/reject`, { remark }),
}
