import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAudio } from './useAudio';

const getAudio = (type) => {
  if (type === 'success') {
    return process.env.PUBLIC_URL + '/assets/audio/eventually.ogg';
  } else if (type === 'error') {
    return process.env.PUBLIC_URL + '/assets/audio/serious-strike.ogg';
  } else {
    return process.env.PUBLIC_URL + '/assets/audio/just-saying.ogg';
  }
}

export const useNotification = (type = 'default', withAudio, defaultOpt = {}) => {
  const [, toggleAudio] = useAudio(getAudio(type));
  const [defaultOptions] = useState(defaultOpt)

  const toggle = useCallback(
    (msg, opt = {}) => {
      toast(msg, { type, ...defaultOptions, ...opt });
      if (withAudio) toggleAudio();
    },
    [type, withAudio, defaultOptions, toggleAudio],
  )

  return [toggle];
};
