import AllStatus from ".";

const DonationStatusOpts = [
  { value: AllStatus.APPROVED, label: AllStatus.getStr(AllStatus.APPROVED) },
  { value: AllStatus.PROCESSING, label: AllStatus.getStr(AllStatus.PROCESSING) },
  { value: AllStatus.REJECTED, label: AllStatus.getStr(AllStatus.REJECTED) },
  { value: AllStatus.INACTIVE, label: AllStatus.getStr(AllStatus.INACTIVE) },
  { value: AllStatus.COMPLETED, label: AllStatus.getStr(AllStatus.COMPLETED) },
  { value: AllStatus.CANCELED, label: AllStatus.getStr(AllStatus.CANCELED) },
];

export default DonationStatusOpts
