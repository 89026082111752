import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState } from 'react'
import { TxtIcon } from '../../../../components/Icons/Icons';
import styles from './reportG0003.module.scss';
import { sha256 } from 'js-sha256';

const renderRow = (row, index) => (
  <TableRow key={index}>
    <TableCell>{index + 1}</TableCell>
    <TableCell>{row.user.replace(/\+62/g, "")}</TableCell>
    <TableCell>{row.ProvinceCode}</TableCell>
    <TableCell>{row.status ? 1 : 0}</TableCell>
  </TableRow>
)

const TableComponent = ({
  items,
  period,
  setLoading
}) => {
  const [openModal, setOpenModal] = useState(false)

  const tableTitle = 'Report QRIS to BI';

  const exportToCsv = (rows) => {
    let csvContent = "data:text/csv;charset=utf-8,ID;PROV;REG_USER\n" + rows.map(e => `${sha256(e.user.replace(/\+62/g, "")).toUpperCase()};${e.ProvinceCode};${e.status ? 1 : 0}`).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `UserQRIS-DIPAY-${format(new Date(period), 'MMyy')}.csv`);
    document.body.appendChild(link);

    link.click();
    setLoading(false);
    setOpenModal(false)
  }

  return (
    <div className="card">
      <div className="table-header">
        <div className="info-cell">
          <div className="d-flex justify-content-between">
            <div>{tableTitle}</div>
            <div>
              {format(new Date(period), 'MMMM yyyy')}
            </div>
          </div>
        </div>
        <div className="action-cell">
          <button onClick={() => setOpenModal(true)} className={`${styles.btnExportTxt} btn`}>
            <TxtIcon className="mr-2" size="28" />
            Export to CSV
          </button>
          <Dialog
            onClose={() => setOpenModal(false)}
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent className="p-3">
              <div style={{ width: 330 }}>
                <button onClick={() => exportToCsv(items)} className="btn btn-lg w-100 btn-primary mb-2">Download</button>
                <button type="button" className="btn btn-lg w-100 btn-danger" onClick={() => setOpenModal(false)}>Cancel</button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="report-table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Province</TableCell>
              <TableCell>Registered User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => renderRow(row, index))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TableComponent
