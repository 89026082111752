import { endOfMonth, startOfMonth } from 'date-fns';
import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CREATE_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  CREATE_TAB,
  CLOSE_TAB,
  GET_DETAIL,
  FILTER_TABLE,
  CREATE,
  UPDATE,
  PROMO_QRIS_CONFIG_MODULE,
  DELETE
} from '../../../utils/constants/actionTypes';
import { closeTab, createTab } from '../../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  merchants: [],
  limit: 10,
  sort: 'createdAt|desc',
  currentPage: 1,
  activeTabKey: 0,
  tabs: [],
  isCreating: false,
  filter: {
    search: '',
    validFrom: startOfMonth(new Date()),
    validTo: endOfMonth(new Date()),
    type: '',
    subtype: ''
  }
};

const reducer = (state = defaultState, action) => {
  if (action.module !== PROMO_QRIS_CONFIG_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      return {
        ...state,
        items: !action.error ? action.payload.data.promoQris : state.items,
        total: !action.error ? action.payload.data.count : state.total,
        inProgress: false,
      };
    case CREATE_TAB_LOAD:
      return {
        ...state,
        merchants: !action.error ? action.payload.data.businesses : state.items,
        total: !action.error ? action.payload.data.count : state.total,
        inProgress: false,
      };
    case GET_DETAIL: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.tabKey]) clonedTabs[action.tabKey].loading = false;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case UPDATE: {
      if (action.error) return { ...state }
      const newTabs = state.tabs.filter((row, key) => key !== action.tabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: 0,
      }
    }
    case CREATE:
      return {
        ...state,
        isCreating: false,
        activeTabKey: !action.error ? 0 : state.activeTabKey,
      };
    case DELETE:
      return {
        ...state,
        inProgress: false
      };
    case CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title, { listType: action.listType });
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        },
        currentPage: 1
      }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1,
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        case CREATE_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        case DELETE:
          return { ...state, inProgress: false };
        case UPDATE:
          return { ...state, isUpdating: false };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        case CREATE_TAB_LOAD:
          return { ...state, inProgress: true };
        case CREATE:
          return { ...state, isCreating: true };
        case DELETE:
          return { ...state, inProgress: true };
        case GET_DETAIL:
          const clonedTabs = [...state.tabs];
          clonedTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: clonedTabs
          };
        case UPDATE:
          return { ...state, isUpdating: true };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
