import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'
import {
  MAIN_TAB_LOAD,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  ADMIN_MODULE,
  CREATE_TAB
} from '../../../utils/constants/actionTypes';
import { combineBy } from '../../../utils/helpers/combineBy';
import LockIcon from '@material-ui/icons/Lock';
import RoundBadge from '../../../components/Badge/RoundBadge';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { adminApi } from '../../../services/adminApi';
import { AdminRole } from '../../../utils/constants/enums/adminRoles';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total
  } = useSelector(state => state.admin);
  const [updating, setUpdating] = useState(false)

  const load = useCallback(
    () => {
      dispatch({
        module: ADMIN_MODULE,
        type: MAIN_TAB_LOAD,
        call: adminApi.list,
        args: [
          currentPage,
          limit,
          sort,
        ]
      });
    },
    [dispatch, currentPage, limit, sort]
  )

  const createTab = useCallback((key, title) => {
    dispatch({
      module: ADMIN_MODULE,
      type: CREATE_TAB,
      key,
      title
    });
  }, [dispatch])

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: ADMIN_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const unlockAdmin = useCallback((id) => {
    setUpdating(true)
    dispatch({
      type: null,
      call: adminApi.unlock,
      args: [id]
    })
      .then(() => load())
      .catch(() => { })
      .finally(() => setUpdating(false))
  }, [dispatch, load])

  const table = useMemo(() => ({
    inProgress: inProgress,
    data: items,
    sort: sort,
    limit: limit,
    total: total,
    currentPage: currentPage,
    columns: [
      { title: 'Full Name', key: 'firstName', name: 'fullName', render: (v, row) => combineBy([row.firstName, row.lastName]) },
      { title: 'Email', key: 'email', name: 'email' },
      { title: 'Role', key: 'role', name: 'role', render: v => AdminRole.getStr(v) },
      { title: 'Phone Number', key: 'phoneNumber', name: 'phoneNumber' },
      {
        title: 'Lock Status', key: 'status', name: 'status', sortable: false, render: v => (
          <RoundBadge
            title={v.locked ? 'Locked' : 'Not Locked'}
            active={v.locked}
            variant="danger"
            icon={LockIcon}
          />
        )
      },
      {
        title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd')
        }
      },
      {
        title: 'Action', key: '_id', name: 'action', className: 'text-center', sortable: false, render: (v, row) => {
          return (
            <div className="d-flex">
              <button
                className="btn btn-icon btn-icon-only btn-sm btn-primary"
                onClick={() => createTab(v, row.email)}
              >
                <i className="pe-7s-tools btn-icon-wrapper"></i>
              </button>
              {row.status.locked &&
                <AlertDialog
                  buttonComponent={
                    <LoadingBtn
                      className="btn btn-icon btn-icon-only btn-sm btn-success ml-1"
                      title="Unlock"
                      loading={updating}
                    >
                      <i className="pe-7s-unlock btn-icon-wrapper"></i>
                    </LoadingBtn>}
                  onAgree={() => unlockAdmin(v)}
                  title="Apakah anda yakin ingin meng-unlock admin ini?"
                  description="Tindakan ini tidak dapat dibatalkan"
                />
              }
            </div>
          )
        }
      }
    ]
  }), [inProgress, items, sort, limit, total, currentPage, createTab, unlockAdmin, updating])

  useEffect(() => {
    load();
  }, [load])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        />
      </div>
    </div>
  )
}

export default MainTab;
