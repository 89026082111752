import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const userApi = {
  list: ({ page, limit, sort, search, filter, gender, job, connectedPartner, conPartnerType, startDate, endDate, activeStartDate, activeEndDate }) => {
    let url = `/users/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (gender) {
      url += `&gender=${gender}`;
    }
    if (job) {
      url += `&job=${job}`;
    }
    if (filter) {
      url += `&filter=${filter}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${connectedPartner}`;
    }
    if (conPartnerType) {
      url += `&conPartnerType=${encodeURIComponent(conPartnerType)}`
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (activeStartDate) {
      url += `&activeFromDate=${encodeURIComponent(activeStartDate)}`;
    }
    if (activeEndDate) {
      url += `&activeToDate=${encodeURIComponent(activeEndDate)}`;
    }
    return agent.requests.get(url)
  },
  registerVa: id =>
    agent.requests.post(`/users/${id}/register-va`),
  unlock: id =>
    agent.requests.post(`/users/${id}/unlock`),
  verifyID: data =>
    agent.requests.post(`/users/id-verify`, data, true),
  approve: (id, data) =>
    agent.requests.post(`/users/${id}/upgrade`, data),
  reject: (id, remarks) =>
    agent.requests.post(`/users/${id}/reject-upgrade`, { remarks }),
  getReferrals: ({ page, limit, sort }) => {
    let url = `/users/referral-list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  flaggedList: (page, limit, sort, search, startDate, endDate) => {
    let url = `/users/flagged-list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  suspend: (id, remarks) =>
    agent.requests.post(`/users/${id}/suspend`, { remarks }),
  closedAccount: (id, reason) =>
    agent.requests.post(`/users/close?userId=${id}`, { reason }),
  unSuspend: id =>
    agent.requests.post(`/users/${id}/unsuspend`),
  update: (id, data) =>
    agent.requests.post(`/users/${id}/update`, data),
  kycList: ({ page, limit, sort, status, startDate, endDate, connectedPartner, conPartnerType }) => {
    let url = `/users/kyc-list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${connectedPartner}`;
    }
    if (conPartnerType) {
      url += `&conPartnerType=${conPartnerType}`
    }
    return agent.requests.get(url)
  },
  upgradeHistoryList: ({ page, limit, sort, status, startDate, endDate, search }) => {
    let url = `/users/upgrade-histories?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url);
  },
  count: (fromDate, toDate) =>
    agent.requests.get(`/users/count?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  totalRegistered: ({ fromDate, toDate }) =>
    agent.requests.get(`/users/total-registered?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  activeUsers: ({ fromDate, toDate }) =>
    agent.requests.get(`/users/total-active-users?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  totalUsersKYC: ({ fromDate, toDate }) =>
    agent.requests.get(`/users/total-users-kyc?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  totalBalance: () =>
    agent.requests.get('/users/total-balance'),
  upgradeHistoryDetail: ({ userId, upgradeHistoryId }) =>
    agent.requests.get(`/users/upgrade-histories/${upgradeHistoryId}/${userId}/detail`),
  uploadIdentification: (data) =>
    agent.requests.post(`/users/upload-identification-admin`, data, true),
  detail: (id) =>
    agent.requests.get(`/users/${id}/detail`),
  transactionPercentage: (id) =>
    agent.requests.get(`/users/${id}/transactions-percentage`),
  validateBank: (id, data) => agent.requests.post(`/users/${id}/validate`, data),
  updateHistoryList: (id) =>
    agent.requests.get(`/users/${id}/list-update-history`),
  statusHistories: (id) =>
    agent.requests.get(`/users/${id}/status-histories`),
  updateKYCProgress: (id, data) => agent.requests.post(`/users/${id}/kyc-status`, data),
}
