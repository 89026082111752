import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const autoDebitApi = {
  list: ({ page, limit, sort, startDate, endDate }) => {
    let url = `/auto-payments/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/auto-payments/${id}/detail`),
}
