import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ListErrors from '../../components/ListErrors';
import LoadingBtn from '../../components/Forms/LoadingBtn';
import { encrypt } from '../../utils/helpers/crypto';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { authApi } from '../../services/authApi';
import * as yup from 'yup';
import { passwordRegex } from '../../utils/constants/regexTypes';
import HookTextField from '../../components/Forms/HookTextField';
import { LOGIN } from '../../utils/constants/actionTypes';

const schema = yup.object().shape({
  email: yup.string().required('Please enter your email').email(),
  password: yup
    .string()
    .matches(passwordRegex, "Password must contain uppercase lowercase number and special symbol")
    .required('Please enter your password')
});

const Login = ({ setEmail, setStep, setTimer }) => {
  const dispatch = useDispatch();
  const { inProgress, errors: errorReducer } = useSelector(state => state.auth);
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    const email = encrypt(values.email);
    const password = encrypt(values.password);

    dispatch({ type: LOGIN, call: authApi.login, args: [email, password] })
      .then(({ data: { expiredIn } }) => {
        setTimer(expiredIn)
        setEmail(values.email)
        setStep(2)
      })
      .catch(() => { })
  }

  return (
    <div className="modal-dialog w-100 mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
        <div className="modal-body">
          <div className="h5 modal-title text-center">
            <h4 className="mt-2">
              <div>Welcome back,</div>
              <span>Please sign in to your account below.</span>
            </h4>
          </div>
          <ListErrors errors={errorReducer} />
          <div className="form-row">
            <div className="col-md-12">
              <HookTextField
                label="Email"
                ref={register}
                placeholder="e.g example@mail.com"
                name="email"
                error={errors.email}
                helperText={errors.email?.message ?? null}
              />
            </div>
            <div className="col-md-12">
              <HookTextField
                label="Password"
                ref={register}
                placeholder="e.g 123xxx"
                name="password"
                error={errors.password}
                helperText={errors.password?.message ?? null}
                showPassword
              />
            </div>
          </div>
          {/* <div className="position-relative form-check">
            <input name="check" id="exampleCheck" type="checkbox" className="form-check-input" />
            <label htmlFor="exampleCheck" className="form-check-label">Keep me logged in</label>
          </div> */}
        </div>
        <div className="modal-footer clearfix">
          <div className="float-right">
            <LoadingBtn type="submit" loading={inProgress} className="btn btn-primary btn-lg">
              Login to Dashboard
            </LoadingBtn>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
