import AllStatus from ".";

const OrderStatusOpts = [
    { value: AllStatus.PENDING, label: AllStatus.getStr(AllStatus.PENDING) },
    { value: AllStatus.PROCESSING, label: AllStatus.getStr(AllStatus.PROCESSING) },
    { value: AllStatus.COMPLETED, label: AllStatus.getStr(AllStatus.COMPLETED) },
    { value: AllStatus.FAILED, label: AllStatus.getStr(AllStatus.FAILED) },
    { value: AllStatus.CANCELLED, label: AllStatus.getStr(AllStatus.CANCELLED) },
    { value: AllStatus.REFUNDED, label: AllStatus.getStr(AllStatus.REFUNDED) },
    { value: AllStatus.SUSPECTED, label: AllStatus.getStr(AllStatus.SUSPECTED) },
];

export default OrderStatusOpts
