class mccCategoryType {
  static EDU = "EDU";
  static UMKM = 'UMKM';
  static P2G = 'P2G';
  static G2P = 'G2P';
  static NPF = 'NPF';
  static RGL = 'RGL';
  static SPBU = 'SPBU';

  static getStr(en) {
    switch (en) {
      case mccCategoryType.UMKM:
        return '(UMKM) - Usaha Mikro Kecil Menengah';
      case mccCategoryType.EDU:
        return '(EDU) - Pendidikan';
      case mccCategoryType.P2G:
        return '(P2G) - Perhimpunan Pendidikan dan Guru';
      case mccCategoryType.G2P:
        return '(G2P) - Government-to-Person';
      case mccCategoryType.NPF:
        return '(NPF) - Donasi';
      case mccCategoryType.RGL:
        return '(RGL) - Reguler';
      case mccCategoryType.SPBU:
        return '(SPBU) - SPBU';
      default:
        return 'Unknown';
    }
  }
}

const mccCategoryTypeOpts = [
  { value: mccCategoryType.UMKM, label: mccCategoryType.getStr(mccCategoryType.UMKM) },
  { value: mccCategoryType.EDU, label: mccCategoryType.getStr(mccCategoryType.EDU) },
  { value: mccCategoryType.P2G, label: mccCategoryType.getStr(mccCategoryType.P2G) },
  { value: mccCategoryType.G2P, label: mccCategoryType.getStr(mccCategoryType.G2P) },
  { value: mccCategoryType.NPF, label: mccCategoryType.getStr(mccCategoryType.NPF) },
  { value: mccCategoryType.RGL, label: mccCategoryType.getStr(mccCategoryType.RGL) },
  { value: mccCategoryType.SPBU, label: mccCategoryType.getStr(mccCategoryType.SPBU) },
];

export { mccCategoryType, mccCategoryTypeOpts };
