import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_DETAIL, PARTNER_MODULE } from '../../../../utils/constants/actionTypes';
import { Tab, Tabs, TabHeader } from '../../../../components/TabsLandscape/TabsLandscape';
import { toast } from 'react-toastify';
import useAsync from '../../../../components/HooksUse/useAsync';
import { partnerApi } from '../../../../services/partnerApi';
import PartnerInformation from './PartnerInformationCard';
import BankAccounts from './BankAccounts';
import FeeCard from './FeeCard';
import RulesCard from './RulesCard';
import PersonInChargeCard from './PersonInChargeCard';
import OpenAPISetting from './OpenAPISetting';

const DetailTab = ({ index }) => {
  const [activeKey, setActiveKey] = useState(1);
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.partner.list.tabs[index]);

  const {
    execute: getDetail,
    value: { data: { partner } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: PARTNER_MODULE,
      type: GET_DETAIL,
      call: partnerApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const onSuccess = useCallback(() => {
    toast('Business Updated', { type: "success" });
    getDetail()
  }, [getDetail])

  return loading && !partner ?
    null
    :
    partner ?
      <div>
        <div className="mb-3">
          <Tabs
            activeKey={activeKey}
            onClick={(i) => setActiveKey(i)}
          >
            <TabHeader title="Informasi Partner" />
            <Tab
              title="Partner Information"
            >
              <PartnerInformation
                item={partner}
                onSuccess={onSuccess}
              />
            </Tab>
            <Tab
              title="Person In Charge"
            >
              <PersonInChargeCard
                item={partner}
                onSuccess={onSuccess}
              />
            </Tab>
            <Tab
              title="Fee Information"
            >
              <FeeCard item={partner} onSuccess={onSuccess} />
            </Tab>
            <Tab
              title="Bank Accounts"
            >
              <BankAccounts
                partnerId={key}
                item={partner}
                onSuccess={onSuccess}
              />
            </Tab>

            <Tab
              title="Rules"
            >
              <RulesCard item={partner} onSuccess={onSuccess} />
            </Tab>
            <Tab
              title="Open API Settings"
            >
              <OpenAPISetting item={partner} onSuccess={onSuccess} />
            </Tab>
          </Tabs>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
