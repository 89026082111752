import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const transactionApi = {
  list: (page, limit, sort, type, search) => {
    let url = `/transactions/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  referralList: (id, fromDate, toDate) => {
    let url = `/transactions/referral-list?userId=${id}`;
    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    return agent.requests.get(url)
  },
  listCompanyCode: () => agent.requests.get('/reports/list-company-code'),
  getMutation: (userId, businessId, merchantId, partnerId, fromDate, toDate, type) => {
    let url = `/transactions/mutation?type=${type}`;
    if (userId) {
      url += `&userId=${userId}`;
    }
    if (businessId) {
      url += `&businessId=${businessId}`;
    }
    if (merchantId) {
      url += `&merchantId=${merchantId}`;
    }
    if (partnerId) {
      url += `&partnerId=${partnerId}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  geTtotalMerchantTransaction: ({ fromDate, toDate, merchantId }) => {
    let url = `/transactions/total-merchant-transaction?`;
    if (merchantId) {
      url += `&merchantId=${merchantId}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&endDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  lastTransaction: (uType, fromDate, toDate, type) => {
    let url = `/transactions/last-transaction?uType=${uType}`;
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    return agent.requests.get(url)
  },
  directIntegration: (
    fromDate,
    toDate,
    companyCode,
    senderModel
  ) => {
    let url = `/reports/list-report?senderModel=${senderModel}`;
    if (companyCode) {
      url += `&companyCode=${companyCode}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  merchantIndofund: (
    fromDate,
    toDate
  ) => {
    let url = `/reports/list-report-indofund`;
    if (fromDate) {
      url += `?fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  walletReport: ({
    uType,
    fromDate,
    toDate,
    limit,
    page
  }) => {
    let url = `/reports/list-report-wallet?uType=${uType}`;
    if (limit && page) {
      url += `&${paginate(limit, page)}`
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  listTransaction: (userId, businessId, merchantId, fromDate, toDate, type) => {
    let url = `/transactions/list-transaction?type=${type}`;
    if (userId) {
      url += `&userId=${userId}`;
    }
    if (businessId) {
      url += `&businessId=${businessId}`;
    }
    if (merchantId) {
      url += `&merchantId=${merchantId}`;
    }
    if (fromDate) {
      url += `&fromDate=${encodeURIComponent(fromDate)}`;
    }
    if (toDate) {
      url += `&toDate=${encodeURIComponent(toDate)}`;
    }
    return agent.requests.get(url)
  },
  listMDR: ({ startDate, endDate, limit, page, merchant, sort }) => {
    let url = `/transactions/list-mdr?${paginate(limit, page)}`;
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (merchant) {
      url += `&senderId=${merchant}`;
    }
    return agent.requests.get(url)
  },
  topUpTransferIntegration: ({ page, limit, sort, clientStr, startDate, endDate, status }) => {
    let url = `/transactions/topup-transfer-integration?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (clientStr) {
      url += `&clientStr=${clientStr}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  detailTopUpTransferIntegration: (id) => {
    return agent.requests.get(`/transactions/topup-transfer-integration/${id}`)
  },
  vouchersReport: (fromDate, toDate) => {
    let url = `/vouchers/report?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    return agent.requests.get(url)
  },
  transferOut: ({ fromDate, toDate }) => {
    let url = `/transactions/user-active-transfer-transaction?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    return agent.requests.get(url)
  },
  transferIn: ({ fromDate, toDate }) => {
    let url = `/transactions/user-active-topUp-transaction?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    return agent.requests.get(url)
  },
  update: ({ id, data }) => {
    let url = `/reports/${id}/update`;
    return agent.requests.patch(url, data)
  },
  activeMerchantTransactions: ({ fromDate, toDate }) =>
    agent.requests.get(`/transactions/merchant-active-transaction?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  activeUserTransactions: ({ fromDate, toDate }) =>
    agent.requests.get(`/transactions/user-active-transaction?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
}
