import React, { useState } from 'react'
import { CREATE, USER_ANNOUNCEMENT_APP_MODULE } from '../../../utils/constants/actionTypes'
import Form from './Form'
import { useDispatch } from 'react-redux';
import useMountedState from '../../../components/HooksUse/useMountedState';
import { announcementApi } from '../../../services/announcementApi';

const CreateTab = ({ filterOptions, module }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useMountedState()

  const onSubmit = (values) => {
    const data = {
      ...values,
      isActive: values.isActive === "true" ? true : false,
      isCloseable: values.isCloseable === "true" ? true : false,
      application: module === USER_ANNOUNCEMENT_APP_MODULE ? "USER" : "BUSINESS",
    }

    setIsLoading(true)
    dispatch({
      module: module,
      type: CREATE,
      call: announcementApi.create,
      args: [data]
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          filterOptions={filterOptions}
          isLoading={isLoading}
          module={module}
        />
      </div>
    </div>
  )
}

export default CreateTab
