import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { IMAGE_FORMATS, CHANGE_TAB, KYC_UPGRADES_MODULE } from '../../../../utils/constants/actionTypes';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import HookTextField from '../../../../components/Forms/HookTextField';
import FormField from '../../../../components/Forms/FormField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import HookImage from '../../../../components/Forms/HookImage';
import UserSelect from '../../../../components/Select/UserSelect';
import EvySelectField from '../../../../components/Forms/EvySelectField';
import EvyDatePicker from '../../../../components/Forms/EvyDatePicker';
import useAsync from '../../../../components/HooksUse/useAsync';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import { dataListApi } from '../../../../services/dataListApi';
import { userApi } from '../../../../services/userApi';

const schema = yup.object().shape({
  userId: yup.string().typeError('User is required').required('User is required'),
  identificationNumber: yup.string().required('Identification Number is required'),
  fullName: yup.string().required('Full name is required'),
  job: yup.object().typeError('Job is required').required('Job is required'),
  cardPhoto: yup.mixed()
    .test(
      "required",
      "Card Photo is required",
      value => value.length
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),
  selfPhoto: yup.mixed()
    .test(
      "required",
      "Self Photo is required",
      value => value.length
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),

  nationality: yup.string().required('Nationality is required'),
  gender: yup.object().typeError('Gender is required').required('Gender is required'),
  address: yup.string().required('Address is required'),
  isCurrentAddressSame: yup.boolean(),
  currentAddress: yup.string().when('isCurrentAddressSame', (field, schema) => {
    return field ? schema : schema.required('Current Address is required');
  }),
  dateOfBirth: yup.string().typeError('Field required').required('Date of Birth is required'),
  placeOfBirth: yup.string().required('Place of birth is required'),
});

const CreateTab = () => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      job: '',
      userId: null,
      gender: null,
      address: '',
      currentAddress: '',
      dateOfBirth: new Date("1995-01-01"),
      isCurrentAddressSame: false
    }
  });
  const { job, gender, dateOfBirth, isCurrentAddressSame } = watch(['job', 'userId', 'gender', 'dateOfBirth', 'isCurrentAddressSame']);

  const {
    pending,
    value: { data: jobs = [] } = {},
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: dataListApi.getJobs }),
    [dispatch]), true)

  const onSubmit = (values) => {
    let data = {
      ...values,
      idUser: values.userId,
      job: values.job?.value,
      gender: values.gender?.value,
      cardPhoto: values.cardPhoto[0],
      selfPhoto: values.selfPhoto[0],
      addressCurrent: values.isCurrentAddressSame ? values.address : values.currentAddress
    };

    setIsLoading(true);
    dispatch({ type: null, call: userApi.uploadIdentification, args: [data] })
      .then(() => {
        toast('KYC Requested', { type: "success" });
        dispatch({ module: KYC_UPGRADES_MODULE, type: CHANGE_TAB, key: 0 });
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsLoading(false);
      })
  };

  useEffect(() => {
    register("job");
    register("userId");
    register("gender");
    register("dateOfBirth");
    register("isCurrentAddressSame");

    return () => {
      unregister("job");
      unregister("userId");
      unregister("gender");
      unregister("dateOfBirth");
      unregister("isCurrentAddressSame");
    };
  }, [register, unregister]);
  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="card-title mb-0">Upgrade</h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            row
          >
            <UserSelect
              onChange={val => setValue('userId', val ?? '', { shouldValidate: true })}
              error={errors.userId}
              helperText={errors.userId ? errors.userId.message : null}
              withDetail
            />
          </FormField>
          <HookTextField
            ref={register}
            placeholder="Enter Identification Number"
            label="Identification Number"
            name="identificationNumber"
            error={errors.identificationNumber}
            helperText={errors.identificationNumber ? errors.identificationNumber.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="Full Name"
            placeholder="Enter Full Name"
            name="fullName"
            error={errors.fullName}
            helperText={errors.fullName ? errors.fullName.message : null}
            row
          />
          <EvySelectField
            name="job"
            label="Job"
            placeholder="Enter Job"
            options={[...jobs.map(job => ({ value: job.occupation_id, label: job.occupation_id }))]}
            onChange={(v) => setValue('job', v, { shouldValidate: true })}
            value={job}
            error={errors.job}
            helperText={errors.job ? errors.job.message : null}
            advanceSelect
            isLoading={pending}
            row
          />
          <HookTextField
            ref={register}
            label="Nationality"
            placeholder="Enter Nationality"
            name="nationality"
            error={errors.nationality}
            helperText={errors.nationality ? errors.nationality.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="Place of Birth"
            placeholder="Enter Place of Birth"
            name="placeOfBirth"
            error={errors.placeOfBirth}
            helperText={errors.placeOfBirth ? errors.placeOfBirth.message : null}
            row
          />
          <FormField
            row
            label="Date of Birth"
            error={errors.dateOfBirth}
            helperText={errors.dateOfBirth ? errors.dateOfBirth.message : null}
          >
            <EvyDatePicker
              value={dateOfBirth}
              placeholder="Enter Date of Birth"
              variant="inline"
              autoOk
              format="dd-MM-yyyy"
              onChange={(v) => setValue('dateOfBirth', v, { shouldValidate: true })}
            />
          </FormField>
          <EvySelectField
            name="gender"
            label="Gender"
            placeholder="Enter Gender"
            options={[{ label: 'MALE', value: "MALE" }, { label: 'FEMALE', value: "FEMALE" }]}
            onChange={(v) => setValue('gender', v, { shouldValidate: true })}
            value={gender}
            error={errors.gender}
            helperText={errors.gender ? errors.gender.message : null}
            advanceSelect
            row
          />
          <HookTextField
            ref={register}
            label="Address"
            placeholder="Enter Address"
            name="address"
            error={errors.address}
            helperText={errors.address ? errors.address.message : null}
            row
          />
          <FormField
            row
            label="Current Address"
            className="mb-0"
          >
            <small className="form-text text-muted">Set same as address</small>
            <RadioGroup
              row
              value={isCurrentAddressSame}
              onChange={e => setValue('isCurrentAddressSame', e.target.value === 'true' ? true : false)}
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormField>
          {
            !isCurrentAddressSame &&
            <HookTextField
              ref={register}
              name="currentAddress"
              placeholder="Enter Current Address"
              error={errors.currentAddress}
              helperText={errors.currentAddress ? errors.currentAddress.message : null}
              row
            />
          }
          <HookImage
            row
            label="Card Photo"
            ref={register}
            name="cardPhoto"
            error={errors.cardPhoto}
            helperText={errors.cardPhoto ? errors.cardPhoto.message : null}
            format={IMAGE_FORMATS}
            viewVariant="landscape"
            onClear={() => setValue('cardPhoto', null, { shouldValidate: true })}
          />
          <HookImage
            row
            label="Self Photo"
            ref={register}
            name="selfPhoto"
            error={errors.selfPhoto}
            helperText={errors.selfPhoto ? errors.selfPhoto.message : null}
            format={IMAGE_FORMATS}
            viewVariant="landscape"
            onClear={() => setValue('selfPhoto', null, { shouldValidate: true })}
          />
          <FormField
            row
          >
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary mr-2">Submit</LoadingBtn>
          </FormField>
        </form>
      </div>
    </div>
  )
};

export default CreateTab;
