import React from 'react'
import { useSelector } from 'react-redux';
import { transactionApi } from '../../../../../services/transactionApi';
import GraphCard from './GraphCard';

const Index = ({ id }) => {
  const {
    volumeOfTransactions,
    nominalOfTransactions
  } = useSelector(state => state.dashboard);

  return (
    <div className="row my-3 mx-1">
      <div className="col-12 col-lg-6">
        <GraphCard
          title={{
            en: "Volume of Transactions per month",
            id: "Jumlah Transaksi perbulan"
          }}
          merchantId={id}
          objectName="volumeOfTransactions"
          typeProperty={{
            resultProp: "resultTotal",
            avgProp: "avgTotal",
            totalProp: "total"
          }}
          type=""
          call={transactionApi.geTtotalMerchantTransaction}
          filter={volumeOfTransactions.filter}
          data={volumeOfTransactions.data}
          inProgress={volumeOfTransactions.inProgress}
        />
      </div>
      <div className="col-12 col-lg-6">
        <GraphCard
          title={{
            en: "Nominal of Transactions per month",
            id: "Jumlah Nominal Transaksi per bulan"
          }}
          merchantId={id}
          objectName="nominalOfTransactions"
          typeProperty={{
            resultProp: "resultAmount",
            avgProp: "avgAmount",
            totalProp: "amount"
          }}
          type=""
          call={transactionApi.geTtotalMerchantTransaction}
          filter={nominalOfTransactions.filter}
          data={nominalOfTransactions.data}
          inProgress={nominalOfTransactions.inProgress}
        />
      </div>
    </div>
  )
}

export default Index
