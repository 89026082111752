import { endOfMonth, startOfMonth } from 'date-fns';
import {
  ASYNC_START,
  ASYNC_END,
  CHANGE_TAB,
  UPDATE,
  REPORT_MODULE,
  MAIN_TAB_UNLOAD,
  RESET_CACHE,
  FILTER_TABLE,
} from '../../utils/constants/actionTypes';

const defaultState = {
  items: [],
  notes: [],
  total: 0,
  totalBalance: 0,
  period: null,
  isUpdating: false,
  selectedModel: null,
  dataPosition: null,
  admin: null,

  filter: {
    selectedBusiness: null,
    selectedUser: null,
    selectedPartner: null,
    selectedMerchant: null,
    fromDate: startOfMonth(new Date()),
    toDate: endOfMonth(new Date()),
    walletModel: "USER",
    viewType: null,
    selectedType: null,
  }
};

const reducer = (state = defaultState, action) => {
  if (action.module !== REPORT_MODULE) return { ...state }
  switch (action.type) {
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        }
      }
    case RESET_CACHE:
      return {
        ...state,
        items: []
      }
    case MAIN_TAB_UNLOAD:
      return {
        items: [],
        notes: [],
        period: null,
        isUpdating: false,
        selectedModel: null,
        dataPosition: null,
        admin: null,

        filter: {
          selectedBusiness: null,
          selectedUser: null,
          selectedPartner: null,
          selectedMerchant: null,
          fromDate: startOfMonth(new Date()),
          toDate: endOfMonth(new Date()),
          walletModel: "USER",
          viewType: null,
          selectedType: null,
        }
      }
    case UPDATE:
      return {
        ...state,
        period: !action.error ? action.period : null,
        selectedModel: !action.error ? action.selectedModel : null,
        notes: action.payload && action.payload.data ? action.payload.data.notes : [],
        items: action.payload && action.payload.data ? action.payload.data.transactions : [],
        total: action.payload && action.payload.data ? action.payload.data.count : 0,
        totalBalance: action.payload && action.payload.data ? action.payload.data.totalBalance : 0,
        dataPosition: action.payload && action.payload.data ? action.payload.data.date : null,
        admin: action.payload && action.payload.data ? action.payload.data.admin : null,
      }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case ASYNC_END:
      switch (action.subtype) {
        case UPDATE:
          return { ...state, isUpdating: false };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case UPDATE:
          return { ...state, isUpdating: true };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
