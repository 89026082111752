import React from 'react';
import MainTab from './MainTab';
import { Helmet } from 'react-helmet';

const ReportQRIS = () => {
  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Report QRIS</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-mail-open-file icon-gradient bg-premium-dark"></i>
            </div>
            <div>
              Report QRIS user for Report Monthly to BI
              <div className="page-title-subheading">View Reports with multiple modes.</div>
            </div>
          </div>
        </div>
      </div>
      <MainTab />
    </div>
  );
}

export default ReportQRIS;
