import React, { useState } from 'react';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { CalendarToday } from '@material-ui/icons';
import styles from './forms.module.scss';
import FormField from './FormField';

const EvyDatePicker = ({
  value,
  onChange,
  withTime,
  format = "dd-MM-yyyy",
  disabled,
  label,
  className,
  ...props
}) => {
  const [date, setDate] = useState(null)

  const handleChange = (date) => {
    setDate(date)
    if (typeof onChange === 'function') onChange(date)
  }


  return withTime ?
    <DateTimePicker
      {...props}
      TextFieldComponent={(test) => <CustomTextField disabled={disabled} label={label} {...test} />}
      value={value ? value : date}
      disabled={disabled}
      onChange={handleChange}
      className={className}
    /> :
    <DatePicker
      {...props}
      TextFieldComponent={(test) => <CustomTextField disabled={disabled} label={label} {...test} />}
      value={value ? value : date}
      disabled={disabled}
      onChange={handleChange}
      format={format}
      className={className}
    />
}

const CustomTextField = ({
  value,
  onClick,
  onKeyDown,
  placeholder,
  disabled,
  label
}) => {
  return (
    <FormField
      label={label}
    >
      <div onClick={onClick} className={styles.datePickerInputRoot}>
        <input
          className={`form-control ${styles.datePickerInput} ${disabled && styles.disabled}`}
          readOnly
          value={value}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          disabled={disabled}
        />
        <CalendarToday fontSize="small" className={styles.icon} onClick={onClick} />
      </div>
    </FormField>
  )
}

export default EvyDatePicker;
