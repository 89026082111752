// import React, { useEffect, useMemo, useState } from 'react';
import Resource from '../../components/Resource';
import { useSelector } from 'react-redux';
import { userApi } from '../../services/userApi';
import { merchantApi } from '../../services/merchantApi';
// import { paymentApi } from '../../services/paymentApi';
// import { otpApi } from '../../services/otpApi';
import { GET_TOTAL_HOLDED_CASH, GET_TOTAL_MERCHANT_FLOAT } from '../../utils/constants/actionTypes';
import Widget from './Widget';
// import TotalSMSOtp from './TotalSMSOtp';
// import { toIDR } from '../../utils/helpers/currency';
// import { endOfDay, endOfMonth, format, startOfMonth } from 'date-fns';
// import MyMap from './Maps';
// import { statisticApi } from '../../services/statisticApi';
// import FormField from '../../components/Forms/FormField';
// import EvyDatePicker from '../../components/Forms/EvyDatePicker';
// import UserAnalytic from './UserAnalytic';
// import MerchantAnalytic from './MerchantAnalytic';
// import MostOfTable from './MostOfTable';
// import SelectField from '../../components/Forms/SelectField';
// import PartnerSelect from '../../components/Select/PartnerSelect';
// import useDetectBrowser from '../../components/HooksUse/useDetectBrowser';

const Dashboard = () => {
  return (
    <Resource
      title="Dashboard"
      subTitle="This is an ordinary Dipay dashboard crafted with ReactJS."
      icon="pe-7s-display1"
      customComponent={Content}
    />
  )
}

const Content = () => {
  // const [showCash, setShowCash] = useState(true);
  // const [merchantLocation, setMerchantLocation] = useState([]);
  // const [merchantStartDate, setMerchantStartDate] = useState(new Date('01/01/2019'));
  // const [merchantToDate, setMerchantToDate] = useState(new Date());

  // const [qrisTransactionLocation, setQrisTransactionLocation] = useState([]);
  // const [qrisStartDate, setQrisStartDate] = useState(new Date('01/01/2019'));
  // const [qrisToDate, setQrisToDate] = useState(new Date());

  // useEffect(() => {
  //   statisticApi.merchantLocation({
  //     startDate: merchantStartDate,
  //     endDate: merchantToDate
  //   })
  //     .then(({ data }) => {
  //       setMerchantLocation(data.map(v => ({ id: v.province, value: v.totalRegisteredMerchant })))
  //     })
  //     .catch((err) => console.log(err))
  // }, [merchantStartDate, merchantToDate])

  // useEffect(() => {
  //   statisticApi.qrisTransactionLocation({
  //     startDate: qrisStartDate,
  //     endDate: qrisToDate
  //   })
  //     .then(({ data }) => {
  //       setQrisTransactionLocation(data.map(v => ({ id: v.province, value: v.totalRegisteredMerchant })))
  //     })
  //     .catch((err) => console.log(err))
  // }, [qrisStartDate, qrisToDate])

  const {
    totalHoldedCash,
    totalMerchantFloat
  } = useSelector(state => state.dashboard);

  return (
    <div className="row mb-5" style={{ rowGap: 24 }}>
      <div className="col-12">
        <div className="card">
          <div className="container">
            <div className="row py-4">
              <div className="col-6">
                <div className="text-center">
                  <h5 className="card-title m-0">Total Dana Float User</h5>
                  <span className="font-italic text-grey">total penerimaan uang elektronik</span>
                </div>
                <Widget
                  type="secondary"
                  iconClass="lnr-screen"
                  color="danger"
                  value={totalHoldedCash.value.primary}
                  inProgress={totalHoldedCash.inProgress}
                  call={{
                    type: GET_TOTAL_HOLDED_CASH,
                    call: userApi.totalBalance,
                  }}
                />
              </div>
              <div className="col-6">
                <div className="text-center">
                  <h5 className="card-title m-0">Total Dana Float Merchant</h5>
                  <span className="font-italic text-grey">total penerimaan uang elektronik</span>
                </div>
                <Widget
                  type="secondary"
                  iconClass="lnr-screen"
                  color="danger"
                  value={totalMerchantFloat.value.primary}
                  inProgress={totalMerchantFloat.inProgress}
                  call={{
                    type: GET_TOTAL_MERCHANT_FLOAT,
                    call: merchantApi.totalMerchantBalance
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-12">
        <UserAnalytic />
      </div>
      <div className="col-12">
        <MerchantAnalytic />
      </div>
      <MostSpendUsers />
      <MostPopularMerchant />
      <MostPopularPPOB />
      <div className="col-12 col-lg-6">
        <TotalSMSOtp
          api={{
            endpoint: otpApi.list,
            type: null
          }}
        />
      </div>
      <div className="col-6">
        <div className='card'>
          <div className='card-body'>
            <h5 className="card-title">REGISTERED MERCHANT</h5>
            <div className="form-row">
              <div className="col-6">
                <FormField label="Start Date">
                  <EvyDatePicker
                    value={merchantStartDate}
                    onChange={setMerchantStartDate}
                    variant="dialog"
                    placeholder="Select date"
                    autoOk
                    clearable={true}
                  />
                </FormField>
              </div>
              <div className="col-6">
                <FormField label="End Date">
                  <EvyDatePicker
                    value={merchantToDate}
                    onChange={setMerchantToDate}
                    variant="dialog"
                    placeholder="Select date"
                    autoOk
                    clearable={true}
                  />
                </FormField>
              </div>
            </div>
            <MyMap mapData={merchantLocation} />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className='card'>
          <div className='card-body'>
            <h5 className="card-title">QRIS TRANSACTION</h5>
            <div className="form-row">
              <div className="col-6">
                <FormField label="Start Date">
                  <EvyDatePicker
                    value={qrisStartDate}
                    onChange={setQrisStartDate}
                    variant="dialog"
                    placeholder="Select date"
                    autoOk
                    clearable={true}
                  />
                </FormField>
              </div>
              <div className="col-6">
                <FormField label="End Date">
                  <EvyDatePicker
                    value={qrisToDate}
                    onChange={setQrisToDate}
                    variant="dialog"
                    placeholder="Select date"
                    autoOk
                    clearable={true}
                  />
                </FormField>
              </div>
            </div>
            <MyMap mapData={qrisTransactionLocation} />
          </div>
        </div>
      </div> */}

    </div>
  )
}

// const MostSpendUsers = () => {
//   const [sort, setSort] = useState();
//   const [connectedPartner, setConnectedPartner] = useState();
//   const [transactionType, setTransactionType] = useState();
//   const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
//   const [toDate, setToDate] = useState(endOfDay(endOfMonth(new Date())));
//   const { browser } = useDetectBrowser();

//   const apiArgs = useMemo(() => {
//     return {
//       fromDate,
//       toDate,
//       sort,
//       transactionType,
//       connectedPartner
//     }
//   }, [fromDate, toDate, sort, transactionType, connectedPartner]);

//   const columns = [
//     { title: 'Nama | Unique ID', key: 'name', name: 'name', render: (v, row) => <div>{v} | {row.uniqueId}</div> },
//     { title: 'Total Spend', key: 'totalSpend', name: 'totalSpend', sortable: true, render: v => <div>{toIDR(Math.floor(v))}</div> },
//     { title: 'Total Transaction', key: 'totalTransaction', name: 'totalTransaction', sortable: true, render: v => <div>{v}</div> },
//     { title: 'Last Login', key: 'lastLogin', name: 'lastLogin', render: v => <div>{format(new Date(v), 'yyyy-MM-dd')}</div> },
//     { title: 'Average Spend', key: 'averageSpend', name: 'averageSpend', sortable: true, render: v => <div>{toIDR(v)}</div> },
//     { title: 'Registered at', key: 'registeredAt', name: 'registeredAt', render: v => <div>{format(new Date(v), 'yyyy-MM-dd')}</div> },
//   ];

//   const formatFile = useMemo(() => {
//     if (browser === "firefox") return ".xls";
//     return ""
//   }, [browser])

//   return (
//     <div className="col-12 col-lg-6">
//       <MostOfTable
//         api={{
//           endpoint: paymentApi.listMostSpend,
//           args: apiArgs
//         }}
//         exportButtons={[
//           {
//             columns,
//             filename: `Most_Spend_List_${format(
//               new Date(),
//               "yyyy-MM-dd"
//             )}${formatFile}`,
//             buttonExport: "Export All"
//           },
//         ]}
//         onSort={setSort}
//         sort={sort}
//         setFromDate={setFromDate}
//         setToDate={setToDate}
//         fromDate={fromDate}
//         toDate={toDate}
//         filterComponent={
//           [
//             <SelectField
//               label="Transaction Type"
//               value={transactionType}
//               options={[
//                 { label: "QRIS", value: "QRIS" },
//                 { label: "PPOB", value: "PPOB" },
//                 { label: "Transfer Out", value: "TRANSFER_OUT" },
//                 { label: "All", value: "ALL" },
//               ]}
//               placeholder="Transaction"
//               onChange={(v) => setTransactionType(v?.value)}
//             />
//             ,
//             <PartnerSelect
//               placeholder="Partner"
//               label="Filter Connected Partner"
//               value={connectedPartner}
//               onChange={(v) => setConnectedPartner(v?.value)}
//             />
//           ]
//         }
//         columns={columns}
//         title="Most Spend Users"
//       />
//     </div>
//   )
// }

// const MostPopularPPOB = () => {
//   const [sort, setSort] = useState();
//   const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
//   const [toDate, setToDate] = useState(endOfDay(endOfMonth(new Date())));
//   const { browser } = useDetectBrowser();

//   const apiArgs = useMemo(() => {
//     return {
//       fromDate,
//       toDate,
//       sort
//     }
//   }, [fromDate, toDate, sort]);

//   const columns = [
//     { title: 'Product Name', key: 'title', name: 'Product Name', render: v => <div>{v}</div> },
//     { title: 'Total Purchased', key: 'totalPurchased', name: 'Total Purchased', sortable: true },
//     { title: 'Total Amount', key: 'totalAmount', name: 'Total Amount', sortable: true, render: (v, row) => <div>{toIDR(v + row.totalPurchased)}</div> },
//   ];

//   const formatFile = useMemo(() => {
//     if (browser === "firefox") return ".xls";
//     return ""
//   }, [browser])

//   return (
//     <div className="col-12 col-lg-6">
//       <MostOfTable
//         api={{
//           endpoint: paymentApi.listMostPurchased,
//           args: apiArgs,
//           dataIndex: "mostPurchased"
//         }}
//         exportButtons={[
//           {
//             columns,
//             filename: `Most_Purchased_List_${format(
//               new Date(),
//               "yyyy-MM-dd"
//             )}${formatFile}`,
//             buttonExport: "Export All"
//           },
//         ]}
//         onSort={setSort}
//         sort={sort}
//         setFromDate={setFromDate}
//         setToDate={setToDate}
//         fromDate={fromDate}
//         toDate={toDate}
//         columns={columns}
//         title="Most Popular PPOB"
//       />
//     </div>
//   )
// }

// const MostPopularMerchant = () => {
//   const [connectedPartner, setConnectedPartner] = useState();
//   const [sort, setSort] = useState();
//   const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
//   const [toDate, setToDate] = useState(endOfDay(endOfMonth(new Date())));
//   const { browser } = useDetectBrowser();

//   const apiArgs = useMemo(() => {
//     return {
//       fromDate,
//       toDate,
//       sort,
//       connectedPartner
//     }
//   }, [fromDate, toDate, sort, connectedPartner]);

//   const columns = [
//     { title: 'Merchant Name | Unique ID', key: 'name', name: 'name', render: (v, row) => <div>{v} | {row.uniqueId}</div> },
//     { title: 'Total Income', key: 'totalSpend', name: 'totalSpend', sortable: true, render: v => <div>{toIDR(Math.floor(v))}</div> },
//     { title: 'Total Transaction', key: 'totalTransaction', name: 'totalTransaction', sortable: true },
//     { title: 'Average Income', key: 'averageSpend', name: 'averageSpend', sortable: true, render: v => <div>{toIDR(v)}</div> },
//     { title: 'Registered At', key: 'registeredAt', name: 'registeredAt', render: v => `${format(new Date(v), 'yyyy-MM-dd')}` },
//   ];

//   const formatFile = useMemo(() => {
//     if (browser === "firefox") return ".xls";
//     return ""
//   }, [browser])

//   return (
//     <div className="col-12 col-lg-6">
//       <MostOfTable
//         api={{
//           endpoint: paymentApi.listMostPopularMerchant,
//           args: apiArgs,
//           dataIndex: ""
//         }}
//         exportButtons={[
//           {
//             columns,
//             filename: `Most_Popular_Merchant_List_${format(
//               new Date(),
//               "yyyy-MM-dd"
//             )}${formatFile}`,
//             buttonExport: "Export All"
//           },
//         ]}
//         onSort={setSort}
//         sort={sort}
//         setFromDate={setFromDate}
//         setToDate={setToDate}
//         fromDate={fromDate}
//         toDate={toDate}
//         columns={columns}
//         title="Most Popular Merchant"
//         filterComponent={
//           [
//             <PartnerSelect
//               placeholder="Partner"
//               label="Filter Connected Partner"
//               value={connectedPartner}
//               onChange={(v) => setConnectedPartner(v?.value)}
//             />
//           ]
//         }
//       />
//     </div>
//   )
// }

export default Dashboard;
