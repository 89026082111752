import agent from '../../services/agent';
import {
  ASYNC_START,
  ASYNC_END,
  LOGOUT,
  REGISTER,
  REFRESH_TOKEN,
  CLEAN_AUTH_STATE,
  EXPORT_EXCEL_ALL,
  VERIFY_OTP
} from '../../utils/constants/actionTypes';
import { toast } from 'react-toastify';
import React from 'react'
import getPropByString from '../../utils/helpers/getPropByString';
import ReactDOMServer from 'react-dom/server';
import { ExcelTable } from '../../components/Table/ExcelTable';
import ExportExcelToast from '../../components/templates/ExportExcelToast';
import { authApi } from '../../services/authApi';
import { auditLogApi } from '../../services/auditLogApi';

const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))
const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const promiseMiddleware = store => next => action => (
  new Promise((resolve, reject) => {
    if (typeof action.call === 'function') {
      store.dispatch({ type: ASYNC_START, subtype: action.type, module: action.module ?? null, actionData: action });

      action.call(...(action.args ?? []))
        .then(res => {
          action.error = false;
          action.payload = res;
          store.dispatch({ type: ASYNC_END, subtype: action.type, module: action.module ?? null, promise: action.payload, actionData: action });
          resolve(action.payload);
          next(action);
        })
        .catch(error => {
          const refreshToken = window.localStorage.getItem('refreshToken');

          action.error = true;
          action.payload = error.response?.body ?? {
            statusCode: 500,
            error: 'Internal Server Error',
            message: 'Something went wrong, please try again later'
          };
          store.dispatch({ type: ASYNC_END, subtype: action.type, module: action.module ?? null, promise: action.payload, actionData: action });

          if (action.payload.statusCode === 401 && action.type !== REFRESH_TOKEN && refreshToken) {
            authApi.refresh(refreshToken)
              .then(res => {
                store.dispatch({ type: REFRESH_TOKEN, payload: res });
                store.dispatch(action)
                  .then(res => {
                    resolve(res);
                  })
                  .catch(err => {
                    reject(err);
                  });
              })
              .catch(err => {
                store.dispatch({ type: CLEAN_AUTH_STATE });
                reject(action.payload);
                next(action);
              })
          } else {
            toast(
              typeof action.payload.message === 'string' ?
                action.payload.message
                :
                Array.isArray(action.payload.message) && action.payload.message[0]?.constraints ?
                  action.payload.message[0].constraints[Object.keys(action.payload.message[0].constraints)[0]]
                  :
                  "Something went wrong", { type: 'error' }
            )
            reject(action.payload);
            next(action);
          }
        })
    } else {
      resolve(action);
      next(action);
    }
  })
);

const localStorageMiddleware = store => next => action => {
  if (action.type === REGISTER || action.type === VERIFY_OTP || action.type === REFRESH_TOKEN) {
    if (!action.error) {
      window.localStorage.setItem('token', action.payload.data.accessToken);
      window.localStorage.setItem('refreshToken', action.payload.data.refreshToken);
      window.sessionStorage.setItem('activeSession', 'true');
      agent.setToken(action.payload.data.accessToken);
    } else {
      window.localStorage.setItem('token', '');
      window.localStorage.setItem('refreshToken', '');
      window.sessionStorage.setItem('activeSession', '');
      agent.setToken(null);
    }
  } else if (action.type === LOGOUT || action.type === CLEAN_AUTH_STATE || Boolean(!window.sessionStorage.getItem('activeSession'))) {
    window.localStorage.setItem('token', '');
    window.localStorage.setItem('refreshToken', '');
    window.sessionStorage.setItem('activeSession', '');
    agent.setToken(null);
  }

  next(action);
};

const exportExcelMiddleware = store => next => action => {
  new Promise((resolve, reject) => {
    if (action.type === EXPORT_EXCEL_ALL) {
      // toast initial with 0 progress
      const toastId = toast(<ExportExcelToast filename={action.filename} progress={0} />, {
        progress: 0,
        autoClose: false,
        closeButton: false,
        draggable: false,
        closeOnClick: false,
        type: "info"
      })

      // iteration call api
      let items = []
      let uniqueItems = []
      const ids = new Set();
      const recursiveAPICall = (currentPage, totalPage) => {
        let progress = currentPage / totalPage
        store.dispatch({
          type: null,
          call: action.api.service,
          args: [{
            ...action.api.queryParams,
            page: currentPage
          }]
        })
          .then(res => {
            toast.update(toastId, {
              render: <ExportExcelToast filename={action.filename} progress={progress} />,
              progress: progress
            })

            items = [...items, ...getPropByString(res, action.api.apiResponseKey)]
            for (const item of items) {
              if (!ids.has(item?._id)) {
                ids.add(item?._id);
                uniqueItems.push(item);
              }
            }
            let newPage = currentPage + 1

            if (currentPage < totalPage) recursiveAPICall(newPage, totalPage)
            else {
              const html = ReactDOMServer.renderToStaticMarkup(ExcelTable({ columns: action.columns, items: uniqueItems }))
              const excelHref = 'data:application/vnd.ms-excel;base64,'
              const template =
                '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
                'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
                'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
                'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
                '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
                'xml><![endif]--></head><body>{html}</body></html>';
              const context = {
                worksheet: 'Worksheet',
                html,
              };

              store.dispatch({
                type: null,
                call: auditLogApi.create,
                args: [action.filename, 'GET', 'Export Excel All']
              }).finally(() => {
                let a = document.createElement('a')
                a.href = excelHref + base64(format(template, context));
                a.download = action.filename
                a.click()
              })
            }
          })
          .catch(() => toast.dismiss(toastId))
      }
      recursiveAPICall(1, action.totalPage)

    } else {
      resolve(action)
      next(action)
    }
  })
}

export { promiseMiddleware, localStorageMiddleware, exportExcelMiddleware }
