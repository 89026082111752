import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const businessesApi = {
  list: ({
    page,
    limit,
    sort,
    status,
    isOld,
    startDate,
    endDate,
    search,
    mcc,
    action,
    // mccGroup,
    merchantCriteria,
    mccCategory,
    statusHistories,
    startDateHistories,
    endDateHistories,
    connectedPartner,
    module,
    activeStartDate,
    activeEndDate
  } = {}) => {
    let url = `/businesses/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (status) {
      url += `&filter=${status}`;
    }
    if (action) {
      url += `&action=${action}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (mcc) {
      url += `&mcc=${mcc}`;
    }
    if (merchantCriteria) {
      url += `&merchantCriteria=${merchantCriteria}`;
    }
    if (mccCategory) {
      url += `&mccCategory=${mccCategory}`;
    }
    // if (mccGroup) {
    //   url += `&mccGroup=${mccGroup}`;
    // }
    if (statusHistories) {
      url += `&status-type=${statusHistories}`;
    }
    if (startDateHistories) {
      url += `&status-type-fromDate=${encodeURIComponent(startDateHistories)}`;
    }
    if (endDateHistories) {
      url += `&status-type-toDate=${encodeURIComponent(endDateHistories)}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${connectedPartner}`;
    }
    if (activeStartDate) {
      url += `&activeFromDate=${encodeURIComponent(activeStartDate)}`;
    }
    if (activeEndDate) {
      url += `&activeToDate=${encodeURIComponent(activeEndDate)}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/businesses/${id}/detail`),
  update: (id, data) => {
    if (id) {
      return agent.requests.post(`/businesses/${id}/update`, data);
    } else {
      return agent.requests.post(`/businesses/create`, data);
    }
  },
  updateHistoryList: (id) =>
    agent.requests.get(`/businesses/${id}/list-update-history`),
  updateGeneral: (id, data) =>
    agent.requests.post(`/businesses/${id}/update-general`, data),
  updateOwner: (id, data) =>
    agent.requests.post(`/businesses/${id}/update-owner`, data),
  updateNmid: (id, data) =>
    agent.requests.post(`/businesses/${id}/update-nmid`, data),
  updateLogo: (id, file) =>
    agent.requests.post(`/businesses/${id}/update-logo`, { logo: file }, true),
  updateQrisGpn: (id, file) =>
    agent.requests.post(`/businesses/${id}/update-qris-gpn`, { imageQris: file }, true),
  updateContact: (id, data) =>
    agent.requests.post(`/businesses/${id}/update-contact`, data),
  updateArchive: (id, data) =>
    agent.requests.post(`/businesses/${id}/update-archive`, data, true),
  updateNotes: (id, data) =>
    agent.requests.post(`/businesses/${id}/create-note`, data),
  updateReferralCode: (id, data) =>
    agent.requests.post(`/merchants/${id}/update-referral`, data),
  updateOperationHours: (id, operationHours) =>
    agent.requests.post(`/businesses/${id}/update-operation-hours`, { operationHours }),
  updateSocialMedia: (id, data) =>
    agent.requests.post(`/businesses/${id}/update-social-media`, data),
  verifyPten: id =>
    agent.requests.post(`/businesses/${id}/verify-pten`),
  count: (fromDate, toDate) =>
    agent.requests.get(`/businesses/count?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  reject: (id, remark, informationUncompleted) =>
    agent.requests.post(`/businesses/${id}/reject`, { remark, informationUncompleted }),
  rejectPtenVerify: (id, remark) => agent.requests.post(`/businesses/${id}/reject-pten`, { remark }),
  rejectReasons: () =>
    agent.requests.get(`/reasons/list`),
  approve: id =>
    agent.requests.post(`/businesses/${id}/approve`),
  verifyID: (data) =>
    agent.requests.post(`/merchants/id-verify`, data, true),
  // verifyID: data =>
  //   agent.requests.post(`/merchants-web/id-verify`, data),
  check: id =>
    agent.requests.post(`/businesses/${id}/set-checked`),
  suspend: (id, holdReason) =>
    agent.requests.post(`/businesses/${id}/update-status/suspended`, { holdReason }),
  unSuspend: (id, releaseReason) =>
    agent.requests.post(`/businesses/${id}/update-status/un-suspended`, { releaseReason }),
  blacklist: (id, holdReason) =>
    agent.requests.post(`/businesses/${id}/update-status/blacklist`, { holdReason }),
  unBlacklist: (id, releaseReason) =>
    agent.requests.post(`/businesses/${id}/update-status/un-blacklist`, { releaseReason }),
  unlock: id =>
    agent.requests.post(`/businesses/${id}/update-status/un-lock`),
  closedAccount: (id, reason) =>
    agent.requests.post(`/merchants/close?merchantId=${id}`, { reason }),
  uploadPhotoOutlet: (id, file) =>
    agent.requests.post(`/businesses/${id}/update-photo-outlet`, { photoOutlet: file }, true),
  typeList: () => agent.requests.get(`/businesses/type-list`),
  statusHistories: (id) =>
    agent.requests.get(`/businesses/${id}/status-histories`),

  requestVerify: id =>
    agent.requests.post(`/businesses/${id}/update-status/request-verify`)
}
