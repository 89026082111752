import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { bankApi } from '../../../services/bankApi';
import { businessesSettlementApi } from '../../../services/businessesSettlementApi';
import {
  BUSINESS_SETTLEMENT_MODULE,
  CREATE_TAB,
  FILTER_TABLE,
  LIMIT_TABLE,
  LIST_SETTLEMENT_TAB_LOAD,
  PRINT_REPORT,
  SET_PAGE_TABLE,
  SORT_TABLE
} from '../../../utils/constants/actionTypes';
import AllStatus from '../../../utils/constants/enums/status';
import { toIDR } from '../../../utils/helpers/currency';
import roleHelper, { REJECT_BUSINESSES_SETTLEMENT } from '../../../utils/helpers/roleHelper';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import SelectField from '../../../components/Forms/SelectField';
import useAsync from '../../../components/HooksUse/useAsync';
import { ExcelIcon, PdfIcon } from '../../../components/Icons/Icons';
import Table from '../../../components/Table/Table';
import ReportPDF, { reportExcel } from './Report';

const getClass = status => {
  let className = 'badge-primary';
  if (status === AllStatus.PENDING) {
    className = 'badge-warning'
  } else if (status === AllStatus.COMPLETED) {
    className = 'badge-success'
  } else if (status === AllStatus.REJECTED || status === AllStatus.FAILED) {
    className = 'badge-danger'
  }
  return className
}

function ListTab({ idSettlement, listType }) {
  const reportFilename = `Merchant Settlement Form ${format(new Date(), 'dd MMMM yyyy', { locale: id })}`

  const reportRef = useRef()
  const dispatch = useDispatch();
  const {
    currentPageBulk,
    limitBulk,
    sort,
    inProgress,
    itemsList,
    isPrinted,
    total,
    filter,
    totalAmount,
    totalSettlementAmount,
    totalFee,
    totalMDR,
    itemsSummaryBank,
    statusSettlement,
    itemsSummaryBiller,
    itemsReport,
    totalAmountReport
  } = useSelector(state => state.businesses.settlement);

  const { currentAdmin: admin } = useSelector(state => state.common);
  const [approveOnProceed, setApproveOnProceed] = useState(false)

  const apiQuery = useMemo(() => {
    const status = filter.statusDataList?.value
    const aggrBank = filter.aggrBank?.value
    const bankRecipient = filter.bankRecipient?.value
    const search = filter.search ? `code|${filter.search},statusHistories.actorModel|${filter.search}` : null;

    return {
      id: idSettlement,
      page: currentPageBulk,
      limit: limitBulk,
      sort,
      status,
      aggrBank,
      bankRecipient,
      search
    }
  }, [idSettlement, currentPageBulk, limitBulk, sort, filter])

  const load = useCallback(() => {
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: LIST_SETTLEMENT_TAB_LOAD,
      call: businessesSettlementApi.listSettlement,
      args: [apiQuery],
    });
  }, [dispatch, apiQuery])

  const getArtajasaTypes = useCallback(() => {
    return dispatch({
      type: null,
      call: bankApi.getArtajasaTypes,
    })
  }, [dispatch]);

  const { value } = useAsync(getArtajasaTypes, true);
  const options = useMemo(() => {
    return value?.data?.artajasaBank.map(row => ({
      label: `${row.artajasaCode} - ${row.name}`,
      value: row.enum
    })) ?? []
  }, [value])

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: type,
      value: val,
      key: key,
      listType: listType
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const onReject = useCallback((id, remark) => {
    dispatch({
      type: null,
      call: businessesSettlementApi.reject,
      args: [id, remark]
    }).then(() => load())
  }, [dispatch, load])

  const onChecked = useCallback(() => {
    dispatch({
      type: null,
      call: businessesSettlementApi.check,
      args: [idSettlement]
    }).then(() => load())
  }, [dispatch, load, idSettlement])

  const onApproved = useCallback(() => {
    setApproveOnProceed(true)
    dispatch({
      type: null,
      call: businessesSettlementApi.approve,
      args: [idSettlement]
    }).then(() => {
      load()
    })
  }, [dispatch, load, idSettlement])

  const print = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: reportFilename
  })

  const onDownloadReport = async ({ reportType }) => {
    if (reportType === 'pdf') {
      print()
    }
    if (reportType === 'excel') {
      const formatExcel = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])
      const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))

      const html = ReactDOMServer.renderToStaticMarkup(reportExcel({ itemsReport, totalAmountReport }));
      const excelHref = 'data:application/vnd.ms-excel;base64,'

      const template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
        'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
        'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
        'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
        '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
        'xml><![endif]--></head><body>{html}</body></html>';

      const context = {
        worksheet: 'Worksheet',
        html,
      };

      let a = document.createElement('a')
      a.href = excelHref + base64(formatExcel(template, context));
      a.download = reportFilename
      if (a.download.split(".").filter(el => el.includes("xls")).length > 1) {
        a.download = a.download.replace(".xls", "")
      }
      a.click()
    }
  }

  const actionButton = () => (
    <>
      {
        statusSettlement !== AllStatus.PENDING
        &&
        <button
          className="btn btn-icon btn-icon-only btn-sm rounded text-white px-3 mr-2"
          style={{ backgroundColor: '#E13423' }}
          onClick={() => onDownloadReport({ reportType: 'pdf' })}
        >
          <span style={{ fontSize: '11.5px' }} className='pr-2'>Download Report</span>
          <PdfIcon width={14} height={18} />
        </button>
      }
      {
        statusSettlement !== AllStatus.PENDING
        &&
        <button
          className="btn btn-icon btn-excel btn-icon-only btn-sm rounded text-white px-3 mr-2"
          onClick={() => onDownloadReport({ reportType: 'excel' })}
        >
          <span style={{ fontSize: '11.5px' }} className='pr-2'>Download Report</span>
          <ExcelIcon size={18} />
        </button>
      }
      {
        (statusSettlement === AllStatus.PENDING)
        &&
        <AlertDialog
          buttonComponent={
            <button className="btn btn-icon btn-icon-only btn-sm px-3 btn-primary mr-2">
              <span style={{ fontSize: '11.5px' }}>Checked</span>
              <i className="pe-7s-note2 pl-2" style={{ fontSize: '15px' }}></i>
            </button>
          }
          customOptions={
            <button className="btn btn-primary w-50">
              I have checked
            </button>
          }
          onAgree={onChecked}
          title="Are you sure?"
          description="This action cannot be undone!"
        />
      }
      {
        (statusSettlement === AllStatus.CHECKED && isPrinted && !approveOnProceed)
        &&
        <AlertDialog
          buttonComponent={
            <button className="btn btn-icon btn-icon-only btn-sm px-3 btn-success mr-2">
              <span style={{ fontSize: '11.5px' }}>Approve</span>
              <i className="lnr lnr-checkmark-circle pl-2" style={{ fontSize: '14px' }}></i>
            </button>
          }
          customOptions={
            <button className="btn btn-icon btn-icon-only py-0 btn-success w-50">
              Approve
            </button>
          }
          onAgree={onApproved}
          title="Apakah anda yakin?"
          description="This action cannot be undone!"
        />
      }
    </>
  )

  const table = useMemo(() => () => {
    const createTab = (v, row) => {
      dispatch({
        module: BUSINESS_SETTLEMENT_MODULE,
        type: CREATE_TAB,
        key: row._id,
        title: row.code,
        restData: {
          listType: "detail",
        }
      });
    }

    return {
      inProgress,
      sort,
      total,
      data: itemsList,
      limit: limitBulk,
      currentPage: currentPageBulk,
      search: true,

      columns: [
        ...columns,
        {
          title: 'Actions', key: 'status', name: 'action', className: 'text-center', sortable: false, render: (v, row) => (
            <div className="d-flex justify-content-center">
              {
                (roleHelper.hasAccess(admin.role, REJECT_BUSINESSES_SETTLEMENT) && !isPrinted && v === AllStatus.PENDING && (v !== AllStatus.FAILED || v !== AllStatus.COMPLETED))
                &&
                <DeclineDialog
                  className="btn btn-icon btn-icon-only btn-sm btn-danger mx-1"
                  isDisabled={isPrinted}
                  labelAtTable={<i className="lnr-cross btn-icon-wrapper"></i>}
                  onSubmit={onReject}
                  dataId={row._id}
                />
              }
              <button
                className="btn btn-icon btn-icon-only btn-sm btn-primary mx-1"
                onClick={() => createTab(v, row)}
              >
                <i className="lnr-eye btn-icon-wrapper"></i>
              </button>
            </div>
          )
        }
      ]
    }
  }, [currentPageBulk, inProgress, itemsList, limitBulk, sort, total, dispatch, admin, isPrinted, onReject])

  const renderSummary = ({ items, columns }) => (
    <table className="form-group">
      <thead>
        <tr>
          {
            columns.map(column => (
              <th key={column.key} className={`col-2 py-2 ${column.className}`} style={{ color: '#B5B5C3', fontSize: '12px', textTransform: 'uppercase' }}>
                {column.name}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          items ?
            items.map((data, index) => (
              <tr key={data._id} className='border-top border-light' style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : ''}` }}>
                {
                  columns.map((column) => (
                    <td key={column.key} className={`col-2 py-2 ${column.className}`}>
                      {
                        column?.render
                          ? column.render(data[column.key], data)
                          : data[column.key]
                      }
                    </td>
                  ))
                }
              </tr>
            )) : (
              <tr>
                <td colSpan="4" className='text-center py-2' style={{ background: 'rgba(0, 0, 0, 0.03)' }}>
                  No Data
                </td>
              </tr>
            )
        }
      </tbody>
    </table>
  )

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    dispatch({
      module: BUSINESS_SETTLEMENT_MODULE,
      type: PRINT_REPORT,
      call: businessesSettlementApi.printReport,
      args: [{ id: idSettlement }]
    })
  }, [dispatch, idSettlement])

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12 col-xl-6">
          <div className="card mb-3">
            <div className="card-body pb-3 px-3">
              <h5 className="card-title">Summary Settlement Bank</h5>
            </div>
            {renderSummary({ items: itemsSummaryBank, columns: columnsSummaryBank })}
            <div className="card-body pb-2 pt-2 px-3">
              <ul className="list-group list-group-flush">
                <li className="list-group-item px-0">
                  Total Amount: <b>{toIDR(totalAmount)}</b>
                </li>
                <li className="list-group-item px-0">
                  Total Fee: <b>{toIDR(totalFee)}</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className="card mb-3">
            <div className="card-body pb-3 px-3">
              <h5 className="card-title">Summary Settlement Bank Biller</h5>
            </div>
            {renderSummary({ items: itemsSummaryBiller, columns: columnsSummaryBiller })}
            <div className="card-body pb-2 pt-2 px-3">
              <ul className="list-group list-group-flush">
                <li className="list-group-item px-0">
                  Total Amount: <b>{toIDR(totalAmount)}</b>
                </li>
                <li className="list-group-item px-0">
                  Total Fee: <b>{toIDR(totalFee)}</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ReportPDF ref={reportRef} id={idSettlement} />
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="card-title mb-4">LIST SETTLEMENT</h5>
          <Table
            table={table()}
            onReload={load}
            onSearch={onFilter("search")}
            searchValue={filter.search ?? ''}
            onClearSearch={() => onFilter("search")("")}
            onSort={setTableConfig(SORT_TABLE)}
            onChangeShow={setTableConfig(LIMIT_TABLE)}
            onChangePage={setTableConfig(SET_PAGE_TABLE)}
            customTopLeftTable={actionButton()}
            renderTotal={() => (
              <ul className="list-group list-group-flush">
                <li className="list-group-item px-0">
                  Total Settlement Amount: <b>{toIDR(totalSettlementAmount)}</b>
                </li>
                <li className="list-group-item px-0">
                  Total Fee: <b>{toIDR(totalFee)}</b>
                </li>
                <li className="list-group-item px-0">
                  Total MDR: <b>{toIDR(totalMDR)}</b>
                </li>
                <li className="list-group-item px-0">
                  Total Transfer Amount: <b>{toIDR(totalAmount)}</b>
                </li>
              </ul>
            )}
          >
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <SelectField
                  label="Filter Status"
                  className="form-control"
                  placeholder="No Filter"
                  options={[
                    { value: "COMPLETED", label: "Completed" },
                    { value: "CHECKED", label: "Checked" },
                    { value: "FAILED", label: "Failed" },
                    { value: "PENDING", label: "Pending" },
                    { value: "REJECTED", label: "Rejected" },
                  ]}
                  onChange={onFilter("statusDataList")}
                  value={filter.statusDataList}
                  componentProps={{
                    isClearable: true
                  }}
                  nullable
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <SelectField
                  label="Filter Bank Biller"
                  className="form-control"
                  placeholder="No Filter"
                  options={[
                    { label: 'Artajasa', value: 'ARTAJASA' },
                    { label: 'BCA', value: 'BCA' },
                    { label: 'Mandiri', value: 'MANDIRI' },
                    { label: 'CIMB Niaga', value: 'CIMB_NIAGA' },
                    { label: 'BRI', value: 'BRI' },
                    { label: 'BNI', value: 'BNI' }
                  ]}
                  onChange={onFilter("aggrBank")}
                  value={filter.aggrBank}
                  componentProps={{
                    isClearable: true
                  }}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <SelectField
                  label="Filter Bank"
                  className="form-control"
                  placeholder="No Filter"
                  options={options}
                  onChange={onFilter("bankRecipient")}
                  value={filter.bankRecipient}
                  componentProps={{
                    isClearable: true
                  }}
                />
              </div>
            </div>
          </Table>
        </div>
      </div>
    </Fragment>
  )
}

const columnsSummaryBank = [
  { name: 'Bank Name', key: '_id', render: v => <span>{v}</span> },
  { name: 'Fee', key: 'fee', className: 'text-center', render: v => <span>{toIDR(v)}</span> },
  { name: 'Amount', key: 'amount', className: 'text-center', render: v => <span>{toIDR(v)}</span> },
  { name: 'Total Amount', key: 'totalAmount', className: 'text-right', render: v => <span>{toIDR(v)}</span> },
]

const columnsSummaryBiller = [
  { name: 'Aggregator', key: '_id', render: v => <span>{v}</span> },
  { name: 'Fee', key: 'fee', className: 'text-center', render: v => <span>{toIDR(v)}</span> },
  { name: 'Amount', key: 'amount', className: 'text-center', render: v => <span>{toIDR(v)}</span> },
  { name: 'Total Amount', key: 'totalAmount', className: 'text-right', render: v => <span>{toIDR(v)}</span> },
]

const columns = [
  { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd hh:mm:ss') },
  { title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: v => v ? v?.businesses?.name + ' | ' + v?.uniqueId : '-' },
  { title: 'Status', key: 'status', name: 'status', className: 'text-center', render: v => <div className="text-center"><span className={`badge ${getClass(v)}`}>{v}</span></div> },
  { title: 'Trans. Code', key: 'code', name: 'code' },
  { title: 'Bank', key: 'bankAccount', name: 'Bank', render: (v, row) => <div><div>{v?.bank?.name}</div><div className="text-muted">{row?.bankAccount?.accountNumber}</div></div> },
  { title: 'Bank Biller', key: 'aggrBank', name: 'Bank', render: v => v ?? '-' },
  { title: 'Settlement Amount', key: 'totalAmount', name: 'totalAmount', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
  { title: 'Fee', key: 'fee', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
  { title: 'MDR', key: 'mdr', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
  { title: 'Transfer Amount', key: 'amount', name: 'amount', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
]

export default ListTab;
