import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker';
import { useHistory, useLocation } from 'react-router-dom';
import { BANK_ACCOUNT_MODULE, DERIVE } from '../../../../../utils/constants/actionTypes';
import { toast } from 'react-toastify';
import useAsync from '../../../../../components/HooksUse/useAsync';
import { bankAccountApi } from '../../../../../services/bankAccountApi';
import { merchantApi } from '../../../../../services/merchantApi';
import useMountedState from '../../../../../components/HooksUse/useMountedState';

const MerchantBankAccount = ({ merchant, onSuccess = () => { }, readOnly }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState();

  const {
    execute: getBankAccounts,
    value: { data: { bankAccounts = [] } = {} } = {},
    pending: loadingBank,
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: bankAccountApi.list,
      args: [
        0,
        0,
        'createdAt|desc',
        merchant._id,
        'Merchant'
      ]
    }),
    [dispatch, merchant._id]), true)

  const handleSetDefaultBank = (bankAccId) => {
    setIsLoading(true);
    dispatch({ type: null, call: merchantApi.setDefaultBankAccount, args: [merchant._id, bankAccId] })
      .then(() => { if (isMounted()) onSuccess() })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsLoading(false);
      });
  }

  const handleEditBankAccount = (bankAccountData) => {
    if (!bankAccountData?._id) return toast("Partner Bank Account Not Found", { type: 'error' });
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: DERIVE,
      createNewTab: true,
      key: bankAccountData._id,
      title: bankAccountData.accountName,
      ownerId: bankAccountData.owner?._id,
      ownerModel: 'Partner',
      from: location.pathname,
    }).then(() => history.push('/internal/bank-accounts'))
  }

  const handleOnClickAddAccount = () => {
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: DERIVE,
      key: 1,
      ownerId: merchant._id,
      ownerModel: 'Merchant',
      from: location.pathname,
    })
      .then(() => history.push('/internal/bank-accounts'))
  }

  return (
    <>
      <div className="refresh-button" onClick={getBankAccounts}>
        <i className="lnr-sync"></i>
      </div>
      <h5 className="card-title">Informasi Rekening Bank</h5>
      {
        (isLoading || loadingBank) && !bankAccounts.length ?
          null
          :
          bankAccounts.length
            ?
            <div className="bank-accounts-wrapper">
              {bankAccounts.map((row, i) =>
                <div className="bank-accounts-list" key={i}>
                  <div className="bank-account-data pr-0">
                    <div className="bank-account-logo">
                      {row.bank
                        ? <img src={row.bank?.logo?.url ?? ''} alt={row.bank?.abbr} />
                        : 'Bank Not Found'
                      }
                    </div>
                    <div>
                      <p className="bank-account-bank">
                        {row.bank?.name ?? 'Bank Not Found'}
                      </p>
                      <p className="bank-account-name">
                        {row.accountName}
                      </p>
                      <p className="bank-account-number">
                        {row.accountNumber}
                      </p>
                    </div>
                  </div>
                  {!readOnly ?
                    <>
                      {
                        row._id === merchant.defaultBankAccount ?
                          <div className="bank-account-default">
                            Default
                          </div>
                          :
                          <button className="btn bank-account-set-default p-0" onClick={() => handleSetDefaultBank(row._id)}>Set Default</button>
                      }
                      <button className="btn bank-account-set-default p-0 ml-2" onClick={() => handleEditBankAccount(row)}>Edit</button>
                    </>
                    : null
                  }
                </div>
              )}
            </div>
            :
            <div>
              <p className="mb-0">No Account Found</p>
            </div>
      }
      {
        !readOnly &&
        <div className="text-center">
          <button onClick={handleOnClickAddAccount} className="btn btn-primary btn-verify">Add Account</button>
        </div>
      }
      <LoadingBlocker in={isLoading || loadingBank} />
    </>
  )
}

export default MerchantBankAccount
