import React, { forwardRef, Fragment, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingBtn from '../Forms/LoadingBtn';
import EvyCheckbox from '../Forms/EvyCheckbox';
import SelectField from '../Forms/SelectField';

const RejectDialog = forwardRef((
  {
    dataId,
    isDisabled,
    className,
    style,
    onSubmit = function () { },
    label,
    checkbox,
    checkboxLabel,
    initialCheckbox,
    options = [],
    desc,
    fullWidth = false,
    isLoading
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [optionValue, setOptionValue] = useState(null)
  const [checkBoxData, setCheckBoxData] = useState({ ...initialCheckbox });

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleAction = () => {
    onSubmit(dataId, optionValue.join(", "), checkBoxData);
    setCheckBoxData({ ...initialCheckbox })
    setOpen(false);
  }

  return (
    <Fragment>
      <LoadingBtn
        loading={isLoading}
        type="button"
        disabled={isDisabled}
        className={className || ''}
        onClick={handleClickOpen}
        ref={ref}
        style={style}
      >
        {label || 'Reject'}
      </LoadingBtn>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={fullWidth}>
        <DialogContent>
          <h5 className='font-weight-semibold'>{label || 'Reject'}</h5>
          <p className='mb-4'>{desc}</p>
          <SelectField
            label="Remark"
            options={
              options.map((v) => {
                return {
                  value: v.title,
                  label: v.title
                }
              })
            }
            isMulti
            value={optionValue}
            onChange={(v) => setOptionValue(!v?.length ? '' : v.map(el => el.value))}
          />
          {
            checkbox
            &&
            <div className='mt-4'>
              <div>
                <span className='mb-0 text-grey'>
                  {checkboxLabel || ''}{' '}
                  <span className="text-danger font-weight-bold">*</span>
                </span>
              </div>
              <div className='pt-3'>
                {
                  checkbox.map((props) => (
                    <div className='border-bottom mb-3' key={props.key}>
                      <EvyCheckbox
                        key={props.key}
                        label={props.label}
                        id={props.id}
                        className='mb-2'
                        checked={props.checked}
                        onChange={v => setCheckBoxData({ ...checkBoxData, [props.key]: v.target.checked })}
                        defaultChecked={props.defaultChecked}
                      />
                      <ul>
                        {
                          props.information
                          &&
                          props.information.map((value, index) => (
                            <li key={index}>{value}</li>
                          ))
                        }
                      </ul>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions className="bg-light py-3">
          <button onClick={handleClose} className='btn btn-icon btn-icon-only btn-sm px-4 bg-white text-secondary'>
            Cancel
          </button>
          <button onClick={handleAction} className='btn btn-icon btn-icon-only px-4 btn-danger'>
            {label || 'Reject'}
          </button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
})

export default RejectDialog
