class CashbackType {
  static WEEK = 'WEEK';
  static MONTH = 'MONTH';

  static getStr(en) {
    switch (en) {
      case CashbackType.WEEK:
        return 'Week';
      case CashbackType.MONTH:
        return 'Month';
      default:
        return 'Unknown';
    }
  }

  static getSpanDay(type) {
    switch (type) {
      case CashbackType.WEEK:
        return 7;
      case CashbackType.MONTH:
        return 30;
      default:
        return 7;
    }
  }
}

export { CashbackType };
