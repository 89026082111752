import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INJECTION_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes'
import { combineBy } from '../../../utils/helpers/combineBy';
import { format } from 'date-fns'
import { toIDR } from '../../../utils/helpers/currency';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import EvyTextField from '../../../components/Forms/EvyTextField';
import StatusHistory from '../../../components/StatusHistory';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import roleHelper, { FORM_DETAIL_APPROVAL } from '../../../utils/helpers/roleHelper';
import useAsync from '../../../components/HooksUse/useAsync';
import useMountedState from '../../../components/HooksUse/useMountedState';
import AllStatus from '../../../utils/constants/enums/status';
import { injectionApi } from '../../../services/injectionApi';
import { AdminRole } from '../../../utils/constants/enums/adminRoles';

const DetailTab = ({ index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const { injection: { key, loading }, admin } = useSelector(state => ({ injection: state.injection.tabs[index], admin: state.common.currentAdmin }));

  const {
    execute: getDetail,
    value: { data: { injection: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: INJECTION_MODULE,
      type: GET_DETAIL,
      call: injectionApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const getClass = status => {
    let className = 'badge-primary';
    if (status === AllStatus.PENDING) {
      className = 'badge-warning'
    } else if (status === AllStatus.COMPLETED) {
      className = 'badge-success'
    } else if (status === AllStatus.FAILED) {
      className = 'badge-danger'
    }
    return className
  };

  const approve = () => {
    setIsLoading(true);
    dispatch({ module: INJECTION_MODULE, type: null, call: injectionApi.approve, args: [item._id] })
      .then(() => {
        if (isMounted()) getDetail();
      })
      .finally(() => {
        if (isMounted()) setIsLoading(false);
      });
  };

  const reject = () => {
    setIsLoading(true);
    dispatch({ module: INJECTION_MODULE, type: null, call: injectionApi.reject, args: [item._id] })
      .then(() => {
        if (isMounted()) getDetail();
      })
      .finally(() => {
        if (isMounted()) setIsLoading(false);
      });
  };

  const history = item ? item.statusHistories.find(history => history.status === AllStatus.PENDING) : null;
  const actor = history ? history.actor : null;
  return loading && !item ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Requester | Admin</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Full Name"
                  value={actor ? combineBy([actor.firstName, actor.lastName]) : 'Unknown'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={actor ? actor.email : 'Unknown'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Role"
                  value={actor ? AdminRole.getStr(actor.role) : 'Unknown'}
                />
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">To {item.applicantModel}</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Full Name"
                  value={combineBy([item.applicant.firstName, item.applicant.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.applicant.uniqueId}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.applicant.phoneNumber}
                  helperText={`Verified at: ${item.applicant.status.phoneNumberVerifiedAt ? format(new Date(item.applicant.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.applicant.email}
                  helperText={`Verified at: ${item.applicant.status.emailVerifiedAt ? format(new Date(item.applicant.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title mb-3">Injection data</h5>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="text-grey mb-1">Type</label>
                    <p className="mb-0 font-weight-semibold">{item.amount.primary > 0 ? 'PRIMARY' : item.amount.point > 0 ? 'POINT' : '-'}</p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="text-grey mb-1">Date</label>
                    <p className="mb-0 font-weight-semibold">{format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="text-grey mb-0">Transaction Code</label>
                <p className="mb-0 font-weight-semibold">{item.code}</p>
              </div>
              <div className="form-group">
                <label className="text-grey mb-1">Description</label>
                <p className="mb-0 font-weight-semibold">{item.description ? item.description : '-'}</p>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Current Status</label>
                </div>
                <div className="col-8 text-right">
                  <span className={`badge ${getClass(item.status)}`}>{item.status}</span>
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Amount</label>
                </div>
                <div className="col-8 text-right">
                  {item.amount.primary > 0
                    ?
                    <>
                      <span className="text-grey pr-1">Rp</span>
                      <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(item.amount.primary, false)}</span>
                    </>
                    :
                    <>
                      <span className="text-grey pr-1"></span>
                      <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(item.amount.point, false)}</span>
                    </>
                  }
                </div>
              </div>
              {roleHelper.hasAccess(admin.role, FORM_DETAIL_APPROVAL) && item.status === AllStatus.PENDING &&
                <>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <LoadingBtn type="button" onClick={reject} className="btn btn-outline-danger mr-2">Reject</LoadingBtn>
                    <LoadingBtn type="button" onClick={approve} className="btn btn-primary">Approve</LoadingBtn>
                  </div>
                </>
              }
              <LoadingBlocker in={isLoading} />
            </div>
          </div>
          <StatusHistory statusHistories={item.statusHistories} mutateStatus />
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
