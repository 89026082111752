import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from "./table.module.scss";

const Search = ({ onChange, searchable, value, onClearSearch }) => {
  const handleChange = e => {
    const value = e.target.value;
    let search;
    if (value && searchable.length) {
      search = searchable.map(o => `${o}|${value}`).join(',');
    }

    if (typeof onChange === 'function') onChange(search || value);
  }

  return (
    <div className="table-search">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="pe-7s-search"></i>
          </span>
        </div>
        <input type="text" className="form-control form-control-sm pr-4" value={value} onChange={handleChange} placeholder="Search" />
        {value && <FontAwesomeIcon className={styles.clearIcon} icon={faTimesCircle} onClick={onClearSearch} />}
      </div>
    </div>
  )
}

export default Search
