import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { careerApi } from "../../services/careerApi";
import { CAREER_MODULE, CREATE } from "../../utils/constants/actionTypes";
import Form from "./Form";

const CreateTab = () => {
  const dispatch = useDispatch();
  const { isCreating } = useSelector((state) => state.career);
  const [errorExistingValue, setErrorExistingValue] = useState("");

  const onSubmit = (values) => {
    const data = {
      title: values.title,
      division: values.division,
      jobType: values.jobType,
      location: values.location,
      workingType: values.workingType,
      salary: values.salary,
      slug: values.slug,
      content: values.content,
      link: values.link,
      active: values.active === 'ACTIVE' ? true : false,
      periodFrom: values.periodFrom,
      periodTo: values.periodTo
    };
    dispatch({
      module: CAREER_MODULE,
      type: CREATE,
      call: careerApi.create,
      args: [data],
    }).catch((error) => {
      setErrorExistingValue(error.message)
    });
  };

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={isCreating}
          errorExistingValue={errorExistingValue}
        />
      </div>
    </div>
  );
};

export default CreateTab;
