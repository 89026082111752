import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import {
  REQUEST_UPDATE_BUSINESS_MODULE
} from "../../../utils/constants/actionTypes";
import Resource from "../../../components/Resource";
// import CreateTab from "./CreateTab";
import roleHelper, { CREATE_UPDATE_FORM } from "../../../utils/helpers/roleHelper";
import { requestUpdateBusinessesApi } from "../../../services/requestUpdateBusinessesApi";
import { RequestUpdateStatusOpts, RequestUpdateStatus } from '../../../utils/constants/enums/requestUpdateStatus';
import { RequestUpdateBusinessTypeOpts, RequestUpdateBusinessType } from '../../../utils/constants/enums/requestUpdateBusinessTypes';
import DetailTab from "../BusinessList/Detail";
import { DATE_PICKER, SELECT_FIELD } from "../../../utils/constants/inputTypes";
import { combineBy } from "../../../utils/helpers/combineBy";

const Index = () => {
  const {
    businesses: {
      requestUpdate: {
        currentPage,
        limit,
        sort,
        filter
      }
    },
    common: {
      currentAdmin
    }
  } = useSelector(state => state)

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd hh:mm:ss') },
    { title: 'Business Name', key: 'business', name: 'business_name', render: (v, row) => v?.name + ' | ' + (row.business?.merchant?.uniqueId || '-') },
    { title: 'Phone Number', key: 'business.phoneNumber', name: 'phone', sortable: false },
    { title: 'Email', key: 'business.email', name: 'email', sortable: false },
    { title: 'Owner', key: 'business.merchant', name: 'owner', render: v => combineBy([v?.firstName, v?.lastName]) + ' | ' + v?.email },
    { title: 'Update Type', key: 'updateType', name: 'updateType', render: (v) => RequestUpdateBusinessType.getStr(v) },
    { title: 'Status', key: 'status', name: 'status', render: (v) => RequestUpdateStatus.getStr(v) }
  ];

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      status: filter.status,
      type: filter.type,
      startDate,
      endDate
    };
  }, [currentPage, limit, sort, filter]);

  return (
    <div>
      <Resource
        title="Admin approval List"
        subTitle="Approve or reject user changes request."
        icon="pe-7s-portfolio"
        role={roleHelper.hasAccess(currentAdmin.role, CREATE_UPDATE_FORM)}
        list={{
          columns: columns,
          reducerPath: "businesses.requestUpdate",
          module: REQUEST_UPDATE_BUSINESS_MODULE,
          call: requestUpdateBusinessesApi.list,
          apiQuery: apiQuery,
          filters: [
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: RequestUpdateStatusOpts,
              value: filter.status
            },
            {
              label: "Filter Type",
              type: SELECT_FIELD,
              key: "type",
              options: RequestUpdateBusinessTypeOpts,
              value: filter.type
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        // create={{ component: CreateTab }}
        detail={{
          component: DetailTab,
          props: {
            module: REQUEST_UPDATE_BUSINESS_MODULE,
            reducerPath: "businesses.requestUpdate"
          }
        }}
      />
    </div>
  );
};

export default Index;
