import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const voucherApi = {
  list: ({ page, limit, sort }) => {
    let url = `/vouchers/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) => {
    return agent.requests.get(`/vouchers/${id}/detail`, true);
  },
  update: (id, data) => {
    return agent.requests.post(`/vouchers/${id}/update`, data, true);
  },
  create: (data) => {
    return agent.requests.post(`/vouchers/create`, data, true);
  },
  delete: (id) =>
    agent.requests.post(`/vouchers/${id}/delete`)
}
