import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NNS_MODULE } from '../../../utils/constants/actionTypes';
import CreateTab from './CreateTab';
import UpdateTab from './UpdateTab';
import Resource from '../../../components/Resource';
import { nnsApi } from '../../../services/nnsApi';

function Index() {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.nns);

  const columns = [
    { title: 'Code Bank', key: 'codeBankOrPJP', name: 'codeBankOrPJP' },
    { title: 'Bank Name', key: 'bankName', name: 'bankName' },
    { title: 'Code NNS', key: 'codeNNS', name: 'codeNNS' },
    { title: 'Refund Transaction', key: 'refundTransaction', name: 'refundTransaction', render: v => v ? <span>Refundable</span> : <span>Non-Refundable</span> },
    { title: 'Switcher', key: 'switcher', name: 'switcher' },
  ]

  const apiQuery = useMemo(() => {
    const search = filter.search ? `bankName|${filter.search}` : null

    return {
      page: currentPage,
      limit,
      sort,
      search,
    }
  }, [currentPage, limit, sort, filter])

  return (
    <div>
      <Resource
        title="National Numbering System"
        subTitle="Full National Numbering System (NNS) Control"
        icon="pe-7s-leaf"
        list={{
          search: true,
          columns: columns,
          reducerPath: "nns",
          call: nnsApi.list,
          apiQuery: apiQuery,
          deleteButton: {
            api: nnsApi.delete
          },
          module: NNS_MODULE,
          excel: {
            columns: columns,
            filename: 'NNS_List',
            apiResponseKey: 'data.data_nns',
            queryParams: apiQuery
          },
        }}
        create={{ component: CreateTab }}
        update={{ component: UpdateTab }}
      />
    </div>
  )
}

export default Index
