import { yupResolver } from '@hookform/resolvers';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { businessesApi } from '../../../../services/businessesApi';
import { BUSINESS_ADMIN_PTEN_VERIFY_MODULE, BUSINESS_ADMIN_VERIFY_MODULE, BUSINESS_MODULE } from '../../../../utils/constants/actionTypes';
import HookTextField from '../../../../components/Forms/HookTextField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import useMountedState from '../../../../components/HooksUse/useMountedState';

const schema = yup.object().shape({
  referralCode: yup.string().nullable(),
});

const ReferalCode = ({ item, onSuccess, readOnly, module }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      referralCode: item?.merchant?.referralCode || '',
    }
  })

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const onSubmit = (values) => {
    setIsLoading(true);
    dispatch({
      type: null,
      call: businessesApi.updateReferralCode,
      args: [item.merchant._id, values]
    }).then(() => {
      if (isMounted()) handleSuccess();
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  return (
    <Fragment>
      {
        (
          module === BUSINESS_MODULE ||
          module === BUSINESS_ADMIN_PTEN_VERIFY_MODULE ||
          module === BUSINESS_ADMIN_VERIFY_MODULE
        )
        &&
        <Fragment>
          <button
            className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ?
              <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
              <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
            }
          </button>
          <br /><br />
        </Fragment>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <HookTextField
              ref={register}
              label="Referral Code"
              name="referralCode"
              rows={4}
              error={errors.referralCode}
              helperText={errors.referralCode?.message ?? null}
              // placeholder="e.g: Akun merchant mencurigakan"
              spellcheck={false}
              disabled={isEditing ? !isEditing : readOnly}
            />
          </div>
          <div className="col-12">
            <div className="d-flex flex-row-reverse flex-sm-row">
              <LoadingBtn
                type="submit"
                disabled={isEditing ? !isEditing : readOnly} loading={isLoading}
                className="btn btn-primary"
              >
                Update
              </LoadingBtn>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default ReferalCode
