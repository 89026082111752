import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import {
  CASHBACK_MODULE
} from "../../../utils/constants/actionTypes";
import Resource from "../../../components/Resource";
import CreateTab from "./CreateTab";
import UpdateTab from "./UpdateTab";
import DetailTab from "./DetailTab";
import { cashbackApi } from "../../../services/cashbackApi";
import StatusBadge from "../../../components/StatusBadge";

const Index = () => {
  const { currentPage, limit, sort } = useSelector(
    (state) => state.businesses.cashback
  );

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    },
    {
      title: 'Businesses', key: 'businesses', name: 'businesses', render: v => {
        let string = '';
        if (v && v.length === 1) {
          string = <span>{v[0].name}</span>;
        } else if (v && v.length > 1) {
          string = <span>{v[0].name} and <b>{v.length - 1}</b> more</span>;
        }
        return string;
      }
    },
    { title: '%', key: 'percentage', name: 'percentage' },
    { title: 'Code', key: 'code', name: 'code', render: v => <b>{v}</b> },
    {
      title: 'Start / End', key: 'startAt', name: 'startAt', sortable: false, render: function (v, row) {
        return (
          <>
            {format(new Date(v), 'dd-MM-yyyy')}
            <span> / </span>
            {format(new Date(row.endAt), 'dd-MM-yyyy')}
          </>
        )
      }
    },
    { title: 'Active', key: 'active', name: 'active', render: v => <StatusBadge value={v} /> }
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort
    };
  }, [currentPage, limit, sort]);

  return (
    <div>
      <Resource
        title="Cashbacks"
        subTitle="Create Cashback for merchants."
        icon="pe-7s-magic-wand"
        list={{
          columns: columns,
          reducerPath: "businesses.cashback",
          module: CASHBACK_MODULE,
          call: cashbackApi.list,
          apiQuery: apiQuery
        }}
        create={{ component: CreateTab }}
        update={{ component: UpdateTab }}
        detail={{
          component: DetailTab,
          additionalKey: {
            key: "code",
            value: "code"
          }
        }}
      />
    </div>
  );
};

export default Index;
