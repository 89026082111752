import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HookTextField from '../../../../../../components/Forms/HookTextField';
import MCriteriaSelect from '../../../../../../components/Select/MCriteriaSelect';
import MCCSelect from '../../../../../../components/Select/MCCSelect';
import LoadingBtn from '../../../../../../components/Forms/LoadingBtn';
import { useDispatch } from 'react-redux';
import useMountedState from '../../../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../../../services/businessesApi';
import useAsync from '../../../../../../components/HooksUse/useAsync';
import SelectField from '../../../../../../components/Forms/SelectField';
import { BUSINESS_MODULE, BUSINESS_ADMIN_VERIFY_MODULE } from '../../../../../../utils/constants/actionTypes';

const schema = yup.object().shape({
  name: yup.string().required('Business Name is required'),
  // description: yup.string(),
  MCC: yup.string().required('MCC is required').nullable(),
  MCriteria: yup.string().required('MCriteria is required').nullable(),
  type: yup.string().required('type is required').nullable(),
});

const BusinessInformation = ({ item, module, onSuccess, readOnly }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false);

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: item.name,
      MCC: item.MCC,
      MCriteria: item.MCriteria,
      type: item.type
    }
  });
  const { MCC, MCriteria, type } = watch(['MCC', 'MCriteria', 'type']);

  const {
    value: { data: { businessesTypes = [] } = {} } = {},
    pending: isGettingType
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: businessesApi.typeList }),
    [dispatch]), true)
  const businessTypeOpts = businessesTypes.map(row => ({ value: row.type, label: row.description }));

  const onSubmit = (values) => {
    setIsLoading(true);
    dispatch({ type: null, call: businessesApi.updateGeneral, args: [item._id, values] })
      .then(() => {
        if (isMounted()) handleSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  };

  useEffect(() => {
    register("MCC");
    register("MCriteria");
    register("type")

    return () => {
      unregister("MCC");
      unregister("MCriteria");
      unregister("type")
    };
  }, [register, unregister]);

  return (
    <>
      {
        (
          module === BUSINESS_MODULE ||
          module === BUSINESS_ADMIN_VERIFY_MODULE
        )
        &&
        <>
          <button
            className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ?
              <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
              <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
            }
          </button>
          <br /><br />
        </>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <HookTextField
          ref={register}
          label="Nama Usaha"
          name="name"
          error={errors.name}
          helperText={errors.name?.message}
          readOnly={isEditing ? !isEditing : readOnly}
        />
        <SelectField
          isDisabled={isEditing ? !isEditing : readOnly}
          onChange={v => setValue('type', v?.value ?? null, { shouldValidate: true })}
          options={businessTypeOpts}
          label="Bidang Usaha"
          value={type}
          error={errors.type}
          helperText={errors.type?.message ?? null}
          componentProps={{
            isClearable: true,
            isLoading: isGettingType,
          }}
        />
        {
          type === 'OTHER'
          &&
          <div className={`alert custom-alert mb-4 ${isEditing ? "alert-primary" : "alert-secondary text-black-50"}`}>
            <p className="mb-0">Keterangan bidang usaha: <b>{item?.otherType || "-"}</b></p>
          </div>
        }
        <MCCSelect
          disabled={isEditing ? !isEditing : readOnly}
          onChange={v => setValue('MCC', v?.value ?? null, { shouldValidate: true })}
          value={MCC}
          error={errors.MCC}
          helperText={errors.MCC?.message}
        />
        <MCriteriaSelect
          disabled={isEditing ? !isEditing : readOnly}
          onChange={v => setValue('MCriteria', v?.value ?? null, { shouldValidate: true })}
          value={MCriteria}
          error={errors.MCriteria}
          helperText={errors.MCriteria?.message}
        />
        {
          (
            module === BUSINESS_MODULE ||
            module === BUSINESS_ADMIN_VERIFY_MODULE
          )
          &&
          <LoadingBtn type="submit" disabled={isEditing ? !isEditing : readOnly} loading={isLoading} className="btn btn-primary btn-lg mr-2">Update</LoadingBtn>
        }
        {
          isEditing
          &&
          <LoadingBtn type="button" onClick={() => setIsEditing(false)} className="btn btn-danger btn-lg">Cancel</LoadingBtn>
        }
      </form>
    </>
  )
}

export default BusinessInformation
