import React from 'react';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';

const LoadingBlocker = (
  {
    in: isIn,
    className
  }
) => {
  return (
    <Fade in={isIn} timeout={300}>
      <div className={`loader${className ? ' ' + className : ''}`}>
        <div className="line-scale-pulse-out">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Fade>
  )
}

LoadingBlocker.propTypes = {
  in: PropTypes.bool.isRequired
};

export default LoadingBlocker;
