import React, { useMemo, useState } from 'react'
import OperationHour from './OperationHour';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import { useDispatch } from 'react-redux';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../../services/businessesApi';

const OperationHourForm = ({ id, operationHours: prevOH, onSuccess }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false);

  const initialOperationHoursState = useMemo(() => {
    const operationList = [
      {
        day: 0,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
      {
        day: 1,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
      {
        day: 2,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
      {
        day: 3,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
      {
        day: 4,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
      {
        day: 5,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
      {
        day: 6,
        isOpen: false,
        open: '08:00',
        close: '22:00',
      },
    ]

    prevOH.forEach(row => {
      operationList[row.day] = {
        day: row.day,
        open: row.open,
        close: row.close,
        isOpen: true,
      };
    })

    return operationList
  }, [prevOH])

  const [operationHours, setOperationHours] = useState(initialOperationHoursState)

  const handleChange = (day, operationHour) => {
    setOperationHours(state => {
      const clonedState = [...state];
      clonedState[day] = {
        ...operationHour
      }
      return clonedState
    })
  }

  const submit = () => {
    setIsLoading(true);

    dispatch({
      type: null,
      call: businessesApi.updateOperationHours,
      args: [id, operationHours.filter(x => x.isOpen)]
    })
      .then(() => {
        if (isMounted()) onSuccess();
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsLoading(false)
      })
  }

  return (
    <div>
      <div className="mb-3">
        {operationHours.map(operationHour => (
          <OperationHour
            key={operationHour.day}
            day={operationHour.day}
            close={operationHour.close}
            open={operationHour.open}
            isOpen={operationHour.isOpen}
            onChange={handleChange}
          />
        ))}
      </div>
      <LoadingBtn type="button" loading={isLoading} onClick={submit} className="btn btn-primary">Update</LoadingBtn>
    </div>
  )
}

export default OperationHourForm
