import React, { useState } from 'react';
import { Ban } from '../../../../components/Icons/Icons';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DeclineDialog from '../../../../components/Dialog/DeclineDialog';
import { combineBy } from '../../../../utils/helpers/combineBy';
import roleHelper, { SUSPEND_USER } from '../../../../utils/helpers/roleHelper';
import AllStatus from '../../../../utils/constants/enums/status';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import { userApi } from '../../../../services/userApi';

const SuspendCard = ({ admin, item, onSuccess }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const failedAttempts = item?.failedAttempts?.length ? [...item.failedAttempts] : [];
  const lastSuspendAttempt = failedAttempts.reverse().find(att => att.name === AllStatus.SUSPENDED);
  const openDialogRef = React.createRef();
  const suspended = item.status.suspended;

  const suspend = (id, remarks) => {
    setIsLoading(true);
    dispatch({ type: null, call: userApi.suspend, args: [item._id, remarks] })
      .then(() => {
        onSuccess();
        toast("Suspend success", { type: 'success' });
      })
      .catch(() => {
        toast("Suspend failed", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const unSuspend = () => {
    setIsLoading(true);
    dispatch({ type: null, call: userApi.unSuspend, args: [item._id] })
      .then(() => {
        onSuccess();
        toast("Unsuspend request success", { type: 'success' });
      })
      .catch((err) => {
        if (err.message) {
          toast(err.message, { type: 'error' });
        } else {
          toast("Unsuspend request failed", { type: 'error' });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    roleHelper.hasAccess(admin.role, SUSPEND_USER) ?
      <div className="col-12 col-md-4 mbg-3">
        {
          suspended ?
            <AlertDialog
              buttonComponent={(
                <ButtonCard
                  suspended={suspended}
                  lastSuspendAttempt={lastSuspendAttempt}
                  isLoading={isLoading}
                />
              )}
              onAgree={unSuspend}
              title="Apakah anda yakin ingin me-request unsuspend untuk akun ini?"
              description="Tindakan ini tidak dapat dibatalkan"
            />
            :
            <>
              <ButtonCard
                onClick={() => openDialogRef.current.click()}
                suspended={suspended}
                lastSuspendAttempt={lastSuspendAttempt}
                isLoading={isLoading}
              />
              <DeclineDialog
                className="d-none"
                ref={openDialogRef}
                dataId={item._id}
                onSubmit={suspend}
                label="Suspend"
                desc="Are you sure want to suspend this user? user will automatically logged out after suspended"
              />
            </>
        }
      </div>
      :
      null
  )
}

const ButtonCard = ({ onClick, suspended, lastSuspendAttempt, isLoading }) => (
  <div className="card suspend-card" onClick={onClick}>
    <div className="card-body">
      <div className="text-center">
        <div className={`ban-icon-animated${suspended ? ' suspended' : ''}`}>
          <Ban className="fa-6x" />
        </div>
        <p className="mb-2">
          <b>{suspended ? 'Suspended' : 'Not Suspended'}</b>
          {suspended &&
            <>
              <br />
              <b>
                By: {lastSuspendAttempt ? `${combineBy([lastSuspendAttempt.actor.firstName, lastSuspendAttempt.actor.lastName])} [${lastSuspendAttempt.actorModel}]` : '-'}
              </b>
            </>
          }
        </p>
        {suspended && lastSuspendAttempt &&
          <p className="mb-0 text-black-50">{lastSuspendAttempt.description || 'No remarks'}</p>
        }
      </div>
      <LoadingBlocker in={isLoading} />
    </div>
  </div>
)

export default SuspendCard;
