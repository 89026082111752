import React from 'react';

class ListErrors extends React.Component {
  render() {
    const errors = this.props.errors;
    if (Array.isArray(errors)) {
      return (
        <div className="alert alert-danger fade show">
          <ul className="mb-0">
            {
              errors.map((error, index) => {
                return error.constraints ?
                  Object.keys(error.constraints).map((key, i) => (
                    <li key={String(index) + String(i)}>
                      {error.constraints[key]}
                    </li>
                  ))
                  :
                  <li key={index}>
                    {error}
                  </li>
              })
            }
          </ul>
        </div>
      );
    } else if (errors) {
      return (
        <div className="alert alert-danger fade show">{errors}</div>
      );
    } else {
      return null;
    }
  }
}

export default ListErrors;
