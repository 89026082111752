import React from 'react';
import Timeline from './VerticalTimeline/Timeline';
import { format } from 'date-fns'
import CollapseCard from './CollapseCard';
import { combineBy } from '../utils/helpers/combineBy';

const StatusHistory = (
  {
    statusHistories,
    warningType,
    successType,
    dangerType,
    mutateStatus,
    className
  }) => {

  const generateActor = (value) => {
    return value?.actor ? (
      <b className="d-block text-black-50 mt-3">
        Actor: {value?.actor?.uniqueId ? `${value.actor.uniqueId} | ${value.actor.firstName}` : `${value?.actor?.email} | ${combineBy([value.actor?.firstName, value.actor?.lastName])}`} [{value.actorModel} ]
      </b>
    ) : (
      <b className="d-block text-black-50 mt-3">
        Actor: {value.actorModel}
      </b>
    )
  }

  return (
    <CollapseCard
      title="Status Histories"
      className={className}
      defaultOpen
    >
      {statusHistories && statusHistories.length ? (
        <Timeline>
          {statusHistories.map((s, k) => (
            <Timeline.Dot
              key={k}
              title={s.status}
              date={format(new Date(s.createdAt), 'dd MMM HH:mm:ss')}
              warningType={warningType}
              successType={successType}
              dangerType={dangerType}
              mutateStatus={mutateStatus}
              desc={generateActor(s)}
            />
          ))}
        </Timeline>
      ) : ''}
    </CollapseCard>
  )
}

export default StatusHistory;
