class UserAppTypes {
  static BANNER = 'BANNER';
  static POPUP = 'POPUP';
  static NEWS = 'NEWS';
  static TUTORIAL = 'TUTORIAL';
  static ARTICLE = 'ARTICLE';

  static getStr(en) {
    switch (en) {
      case UserAppTypes.BANNER:
        return 'Banner';
      case UserAppTypes.POPUP:
        return 'Pop Up';
      case UserAppTypes.NEWS:
        return 'News';
      case UserAppTypes.TUTORIAL:
        return 'Tutorial';
      case UserAppTypes.ARTICLE:
        return 'Article';
      default:
        return '-';
    }
  }
}

const UserAppOpts = [
  { value: UserAppTypes.BANNER, label: UserAppTypes.getStr(UserAppTypes.BANNER) },
  { value: UserAppTypes.POPUP, label: UserAppTypes.getStr(UserAppTypes.POPUP) },
  { value: UserAppTypes.NEWS, label: UserAppTypes.getStr(UserAppTypes.NEWS) },
  { value: UserAppTypes.TUTORIAL, label: UserAppTypes.getStr(UserAppTypes.TUTORIAL) }
];

export { UserAppTypes, UserAppOpts };
