import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Table from '../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, DTTOT_MODULE } from '../../../utils/constants/actionTypes';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { dttotApi } from '../../../services/dttotApi';

const schema = yup.mixed().test(
  "fileFormat",
  "Unsupported Format",
  value => value ? value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : true
);

const MainTab = () => {
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const fileInputRef = useRef()

  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total
  } = useSelector(state => state.dttot);

  const load = useCallback(
    () => {
      dispatch({
        module: DTTOT_MODULE,
        type: MAIN_TAB_LOAD,
        call: dttotApi.list,
        args: [
          currentPage,
          limit,
          sort
        ]
      });
    },
    [currentPage, limit, sort, dispatch],
  )

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: DTTOT_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const handleFileChange = e => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      schema.validate(file).then(file => {
        setErrorMessage(null)
        setIsUploading(true)
        dispatch({ module: DTTOT_MODULE, type: null, call: dttotApi.import, args: [file] })
          .then(() => {
            load()
            toast('Upload Success', { type: 'success' });
          })
          .catch(() => {
            toast('Upload Failed', { type: 'error' });
          })
          .finally(() => {
            setIsUploading(false)
          });
      }).catch(err => {
        if (err && err.name === 'ValidationError') setErrorMessage(err.message)
      });
    }
  }

  const showFileUpload = () => { if (fileInputRef) fileInputRef.current.click() };

  const table = useMemo(() => ({
    inProgress,
    data: items,
    sort,
    limit,
    total,
    currentPage,
    columns: [
      { title: 'Name', key: 'name', name: 'name' },
      { title: 'Type', key: 'type', name: 'type' },
      { title: 'Nationality', key: 'nationality', name: 'nationality' },
      { title: 'Birth Place', key: 'birthPlace', name: 'birthPlace' },
      { title: 'Address', key: 'address', name: 'address' },
      { title: 'Code', key: 'densusCode', name: 'densusCode' },
      { title: 'Desc', key: 'description', name: 'description' },
      { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd') },
    ]
  }), [currentPage, inProgress, items, limit, sort, total])

  useEffect(() => {
    load();
  }, [load])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <div className="d-flex mb-3 justify-content-end align-items-center">
          <em className="invalid-feedback w-auto d-inline-block mr-3">{errorMessage}</em>
          <div>
            <LoadingBtn type="button" loading={isUploading} className="btn btn-excel" onClick={showFileUpload}>Import Excel</LoadingBtn>
            {!isUploading &&
              <input
                type="file"
                className="d-none"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            }
          </div>
        </div>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        />
      </div>
    </div>
  )
}

export default MainTab
