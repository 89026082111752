import agent from "./agent";

export const generateDataApi = {
  list: (type = 'G0003', mode, startDate, endDate, status) => {
    let url = "/end-of-month/list?"
    if (type) {
      url += `type=${type}`;
    }
    if (mode) {
      url += `&mode=${mode}`;
    }
    if (startDate) {
      url += `&startDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&endDate=${encodeURIComponent(endDate)}`;
    }
    if (status) url += `&status=${status}`;
    return agent.requests.get(url)
  },
  downloadTxt: (startDate, endDate, periodeType, reporterCode, raw = true) => {
    let url = "/end-of-month/download-txt"
    if (startDate && endDate) url += `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
    if (periodeType) url += `&periodeType=${periodeType}`;
    if (reporterCode) url += `&reporterCode=${reporterCode}`;
    return raw ? url : agent.requests.get(url)
  },
  create: (type = 'G0003', period) => {
    let url = "/end-of-month/create"
    if (type) url += `?type=${type}`;
    if (period) url += `&period=${encodeURIComponent(period)}`;
    return agent.requests.post(url)
  },
  generateWalletReport: (startDate, endDate, type) => {
    let url = "/transactions/last-transaction-v2"
    if (type) url += `?uType=${type}`;
    if (startDate && endDate) url += `&fromDate=${encodeURIComponent(startDate)}&toDate=${encodeURIComponent(endDate)}`;
    return agent.requests.get(url)
  },
  regenerateBI: (startDate, endDate) => {
    let url = "/end-of-month/create-r-merchant"
    return agent.requests.post(url, {
      startDate: startDate,
      endDate: endDate
    })
  },
  generateDataQRISToBI: ({ period }) => agent.requests.post("/payments/totalUserQRIS", { period })
}
