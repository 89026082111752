import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainTab from './MainTab';
import { CHANGE_TAB, COMPANY_MODULE, CLOSE_TAB } from '../../../utils/constants/actionTypes';
import DetailTab from './Detail';
import { Helmet } from 'react-helmet';
import { Tabs, Tab } from '../../../components/Tabs/Tabs';
import CreateTab from './CreateTab';

const Index = () => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => state.company);

  const handleTab = type => key => dispatch({ module: COMPANY_MODULE, type: type, key: key });

  return (
    <>
      <Helmet>
        <title>Company</title>
      </Helmet>
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-tools icon-gradient bg-sunny-morning"></i>
              </div>
              <div>
                Company Control
                <div className="page-title-subheading">Full Company info settings Control includes Create & Updates.</div>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          activeKey={activeTabKey}
          onClick={handleTab(CHANGE_TAB)}
        >
          <Tab title="Main">
            <MainTab />
          </Tab>
          <Tab title="Create">
            <CreateTab />
          </Tab>
          {tabs.map((row, key) =>
            <Tab
              key={row.key}
              title={row.title}
              isLoading={row.loading}
              onClose={() => handleTab(CLOSE_TAB)(row.key)}
              onClick={() => handleTab(CHANGE_TAB)(row.key)}
              active={activeTabKey === row.key}
            >
              <DetailTab index={key} />
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  );
}

export default Index;
