import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  KYC_UPGRADES_MODULE,
  CLEAR_FORM,
  UPDATE,
  FILTER_TABLE,
  GET_DETAIL,
  CREATE_TAB,
  CLOSE_TAB,
} from '../../../utils/constants/actionTypes';
import AllStatus from '../../../utils/constants/enums/status';
import { closeTab, createTab } from '../../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'updatedAt|desc',
  currentPage: 1,
  activeTabKey: 0,
  filter: {
    status: AllStatus.PENDING_VERIFY,
    startDate: null,
    endDate: null,
    connectedPartner: ''
  },
  tabs: [],
};

export const VERIFY_ID = 'VERIFY_ID';

const reducer = (state = defaultState, action) => {
  if (action.module !== KYC_UPGRADES_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      return {
        ...state,
        items: !action.error ? action.payload.data?.users : [],
        total: !action.error ? action.payload.data?.count : 0
      };

    case GET_DETAIL: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.tabKey]) clonedTabs[action.tabKey].loading = false;
      return {
        ...state,
        tabs: clonedTabs
      };
    }

    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }

    case CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);

      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }

    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1,
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value,
      }
    case UPDATE:
      const newTabs = state.tabs.filter((row, key) => key !== action.tabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: 0,
      }
    case VERIFY_ID:
      return {
        ...state,
        formErrors: action.error ? action.payload.message : null
      }
    case CLEAR_FORM:
      return {
        ...state,
        selectedItem: null,
        shouldResetForm: false,
        formErrors: null,
        activeTabKey: 1,
      }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        },
        currentPage: 1
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        case GET_DETAIL:
          const clonedTabs = [...state.tabs];
          clonedTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: clonedTabs
          };
        default:
          return { ...state };
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
