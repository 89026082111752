class PpobSupportedType {
  static PHONE_CREDIT = 'PHONE_CREDIT';
  static PHONE_PACKAGE = 'PHONE_PACKAGE';
  static GAME_VOUCHER = 'GAME_VOUCHER';
  static ETOLL = 'ETOLL';
  static PPOB_EWALLET = 'PPOB_EWALLET';

  static getStr(en) {
    switch (en) {
      case PpobSupportedType.PHONE_CREDIT:
        return 'Phone Credit';
      case PpobSupportedType.PHONE_PACKAGE:
        return 'Phone Package';
      case PpobSupportedType.GAME_VOUCHER:
        return 'Game Voucher';
      case PpobSupportedType.ETOLL:
        return 'E-Toll';
      case PpobSupportedType.PPOB_EWALLET:
        return 'E-Wallet';
      default:
        return 'Unknown';
    }
  }

  static getStrIdn(en) {
    switch (en) {
      case PpobSupportedType.PHONE_CREDIT:
        return 'Pulsa';
      case PpobSupportedType.PHONE_PACKAGE:
        return 'Paket';
      case PpobSupportedType.GAME_VOUCHER:
        return 'Game Voucher';
      case PpobSupportedType.ETOLL:
        return 'E-Toll';
      case PpobSupportedType.PPOB_EWALLET:
        return 'E-Wallet';
      default:
        return 'Unknown';
    }

  }
}

const PpobSupportedTypeEnum = [
  PpobSupportedType.PHONE_CREDIT,
  PpobSupportedType.PHONE_PACKAGE,
  PpobSupportedType.GAME_VOUCHER,
  PpobSupportedType.ETOLL,
  PpobSupportedType.PPOB_EWALLET
];

const PpobSupportedTypeOpts = [
  { value: PpobSupportedType.PHONE_CREDIT, label: PpobSupportedType.PHONE_CREDIT },
  { value: PpobSupportedType.PHONE_PACKAGE, label: PpobSupportedType.PHONE_PACKAGE },
  { value: PpobSupportedType.GAME_VOUCHER, label: PpobSupportedType.GAME_VOUCHER },
  { value: PpobSupportedType.ETOLL, label: PpobSupportedType.ETOLL },
  { value: PpobSupportedType.PPOB_EWALLET, label: PpobSupportedType.PPOB_EWALLET },
];

export { PpobSupportedType, PpobSupportedTypeEnum, PpobSupportedTypeOpts };
