export class notificationsTypes {
  static PROMO = 'PROMO';
  static INFO = 'INFO';
  // static TRANSACTION = 'TRANSACTION';

  static getStr(en) {
    switch (en) {
      case notificationsTypes.PROMO:
        return 'Promo';
      case notificationsTypes.INFO:
        return 'Info';
      // case notificationsTypes.TRANSACTION:
      //   return 'Transaction';
      default:
        return 'Unknown';
    }
  }

  static getAllType() {
    return notificationsTypesOpts.map(prop => prop.value).join(',')
  }
}

export const notificationsTypesOpts = [
  { value: notificationsTypes.PROMO, label: notificationsTypes.getStr(notificationsTypes.PROMO) },
  { value: notificationsTypes.INFO, label: notificationsTypes.getStr(notificationsTypes.INFO) },
  // { value: notificationsTypes.TRANSACTION, label: notificationsTypes.getStr(notificationsTypes.TRANSACTION) },
];
