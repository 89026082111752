class PpobType {
  static PHONE_CREDIT = 'PHONE_CREDIT';
  static PHONE_PACKAGE = 'PHONE_PACKAGE';
  static PHONE_POSTPAID = 'PHONE_POSTPAID';
  static PDAM = 'PDAM';
  static PLN = 'PLN';
  static PLN_TOKEN = 'PLN_TOKEN';
  static PLN_NON_BILL = 'PLN_NON_BILL';
  static GAME_VOUCHER = 'GAME_VOUCHER';
  static ETOLL = 'ETOLL';
  static PGN = 'PGN';
  static BPJS_HEALTH = 'BPJS_HEALTH';
  static BPJS_EMPLOYMENT = 'BPJS_EMPLOYMENT';
  static TV = 'TV';
  static TELKOM = 'TELKOM';
  static MULTIFINANCE = 'MULTIFINANCE';
  static SAMOLNAS = "SAMOLNAS";
  static KAI = "KAI";
  static DIRECT_INTEGRATE = "DIRECT_INTEGRATE";
  static CREDIT_CARD = "CREDIT_CARD";
  static PBB = "PBB";
  static MF_INDOFUND = 'MF_INDOFUND';
  static DI_IPL = "DI_IPL"
  static INTERNET = "INTERNET"
  static STREAMING = "STREAMING"
  static EDU_VOUCHER = "EDU_VOUCHER"
  static EDUCATION = "EDUCATION"
  static EWALLET = "EWALLET"
  static PPOB_EWALLET = "PPOB_EWALLET"

  static getStr(en) {
    switch (en) {
      case PpobType.PHONE_CREDIT:
        return 'Phone Credit';
      case PpobType.PHONE_PACKAGE:
        return 'Phone Package';
      case PpobType.PHONE_POSTPAID:
        return 'Phone Postpaid';
      case PpobType.PDAM:
        return 'PDAM';
      case PpobType.PLN:
        return 'PLN';
      case PpobType.PLN_TOKEN:
        return 'PLN Token';
      case PpobType.PLN_NON_BILL:
        return 'PLN Non-Bill';
      case PpobType.GAME_VOUCHER:
        return 'Game Voucher';
      case PpobType.ETOLL:
        return 'E-Toll';
      case PpobType.PGN:
        return 'PGN';
      case PpobType.BPJS_HEALTH:
        return 'BPJS Health';
      case PpobType.BPJS_EMPLOYMENT:
        return 'BPJS Employment';
      case PpobType.TV:
        return 'TV';
      case PpobType.TELKOM:
        return 'Telkom';
      case PpobType.MULTIFINANCE:
        return 'Multifinance';
      case PpobType.SAMOLNAS:
        return 'Samolnas';
      case PpobType.KAI:
        return 'Kai';
      case PpobType.DIRECT_INTEGRATE:
        return 'Direct Integrate';
      case PpobType.CREDIT_CARD:
        return 'Credit Card';
      case PpobType.PBB:
        return 'Property Tax';
      case PpobType.MF_INDOFUND:
        return 'Multifinance Indofund'
      case PpobType.DI_IPL:
        return 'IPL'
      case PpobType.INTERNET:
        return 'Internet'
      case PpobType.STREAMING:
        return 'Streaming'
      case PpobType.EDU_VOUCHER:
        return 'Education Voucher'
      case PpobType.EDUCATION:
        return 'Education'
      case PpobType.EWALLET:
        return 'E-Wallet'
      default:
        return 'Unknown';
    }
  }

  static getStrIdn(en) {
    switch (en) {
      case PpobType.PHONE_CREDIT:
        return 'Pulsa';
      case PpobType.PHONE_PACKAGE:
        return 'Paket Data';
      case PpobType.PHONE_POSTPAID:
        return 'Pascabayar';
      case PpobType.PDAM:
        return 'Air';
      case PpobType.PLN:
        return 'Tagihan Listrik';
      case PpobType.PLN_TOKEN:
        return 'Token Listrik';
      case PpobType.PLN_NON_BILL:
        return 'PLN Non Taglis';
      case PpobType.GAME_VOUCHER:
        return 'Game Voucher';
      case PpobType.ETOLL:
        return 'e-Money';
      case PpobType.PGN:
        return 'Gas PGN';
      case PpobType.BPJS_HEALTH:
        return 'BPJS Kesehatan';
      case PpobType.BPJS_EMPLOYMENT:
        return 'BPJS Ketenagakerjaan';
      case PpobType.TV:
        return 'TV Kabel';
      case PpobType.TELKOM:
        return 'Telpon';
      case PpobType.MULTIFINANCE:
        return 'Angsuran Kredit';
      case PpobType.SAMOLNAS:
        return 'Samolnas';
      case PpobType.KAI:
        return 'KAI';
      case PpobType.DIRECT_INTEGRATE:
        return 'Direct Integrate';
      case PpobType.CREDIT_CARD:
        return 'Kartu Kredit';
      case PpobType.PBB:
        return 'PBB';
      case PpobType.MF_INDOFUND:
        return 'Multifinance Indofund'
      case PpobType.DI_IPL:
        return 'Iuran Lingkungan'
      case PpobType.INTERNET:
        return 'Internet'
      case PpobType.STREAMING:
        return 'Streaming'
      case PpobType.EDU_VOUCHER:
        return 'Voucher Edukasi'
      case PpobType.EDUCATION:
        return 'Edukasi'
      case PpobType.EWALLET:
        return 'e-Wallet Open Denom'
      case PpobType.PPOB_EWALLET:
        return 'e-Wallet'
      default:
        return 'Unknown';
    }

  }
}

const PpobTypeOpts = [
  { value: PpobType.EWALLET, label: PpobType.getStrIdn(PpobType.EWALLET) },
  { value: PpobType.PHONE_CREDIT, label: PpobType.getStrIdn(PpobType.PHONE_CREDIT) },
  { value: PpobType.PHONE_PACKAGE, label: PpobType.getStrIdn(PpobType.PHONE_PACKAGE) },
  { value: PpobType.PHONE_POSTPAID, label: PpobType.getStrIdn(PpobType.PHONE_POSTPAID) },
  { value: PpobType.PDAM, label: PpobType.getStrIdn(PpobType.PDAM) },
  { value: PpobType.PLN, label: PpobType.getStrIdn(PpobType.PLN) },
  { value: PpobType.PLN_TOKEN, label: PpobType.getStrIdn(PpobType.PLN_TOKEN) },
  { value: PpobType.PLN_NON_BILL, label: PpobType.getStrIdn(PpobType.PLN_NON_BILL) },
  { value: PpobType.GAME_VOUCHER, label: PpobType.getStrIdn(PpobType.GAME_VOUCHER) },
  { value: PpobType.ETOLL, label: PpobType.getStrIdn(PpobType.ETOLL) },
  { value: PpobType.PGN, label: PpobType.getStrIdn(PpobType.PGN) },
  { value: PpobType.BPJS_HEALTH, label: PpobType.getStrIdn(PpobType.BPJS_HEALTH) },
  { value: PpobType.BPJS_EMPLOYMENT, label: PpobType.getStrIdn(PpobType.BPJS_EMPLOYMENT) },
  { value: PpobType.TV, label: PpobType.getStrIdn(PpobType.TV) },
  { value: PpobType.TELKOM, label: PpobType.getStrIdn(PpobType.TELKOM) },
  { value: PpobType.MULTIFINANCE, label: PpobType.getStrIdn(PpobType.MULTIFINANCE) },
  { value: PpobType.SAMOLNAS, label: PpobType.getStrIdn(PpobType.SAMOLNAS) },
  { value: PpobType.KAI, label: PpobType.getStrIdn(PpobType.KAI) },
  { value: PpobType.DIRECT_INTEGRATE, label: PpobType.getStrIdn(PpobType.DIRECT_INTEGRATE) },
  { value: PpobType.CREDIT_CARD, label: PpobType.getStrIdn(PpobType.CREDIT_CARD) },
  { value: PpobType.PBB, label: PpobType.getStrIdn(PpobType.PBB) },
  { value: PpobType.MF_INDOFUND, label: PpobType.getStrIdn(PpobType.MF_INDOFUND) },
  { value: PpobType.DI_IPL, label: PpobType.getStrIdn(PpobType.DI_IPL) },
  { value: PpobType.INTERNET, label: PpobType.getStrIdn(PpobType.INTERNET) },
  { value: PpobType.STREAMING, label: PpobType.getStrIdn(PpobType.STREAMING) },
  { value: PpobType.EDU_VOUCHER, label: PpobType.getStrIdn(PpobType.EDU_VOUCHER) },
  { value: PpobType.EDUCATION, label: PpobType.getStrIdn(PpobType.EDUCATION) },
];

export { PpobType, PpobTypeOpts };
