import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_BUSINESSES_CASHBACK,
  ROUTE_BUSINESSES_LIST,
  ROUTE_BUSINESS_APPROVAL_LIST,
  ROUTE_BUSINESS_VERIFY_LIST,
  ROUTE_BUSINESS_REQUEST_UPDATE,
  ROUTE_BUSINESS_SETTLEMENT,
  ROUTE_BUSINESS_REFERRAL_LIST
} from "../utils/helpers/roleHelper";

import Business from '../pages/Businesses/Business'
import Cashback from '../pages/Businesses/Cashback'
import BusinessVerify from '../pages/Businesses/BusinessVerify'
import BusinessPTENVerify from '../pages/Businesses/BusinessPTENVerify'
import RequestUpdate from '../pages/Businesses/RequestUpdate'
import Settlement from '../pages/Businesses/Settlement'
import { EventType } from "../utils/constants/enums/eventTypes";
import BusinessReferral from "../pages/Businesses/Referral";

export const businesses = {
  type: NAVMENU,
  name: 'Business',
  icon: 'pe-7s-portfolio',
  children: [
    {
      type: NAVLINK,
      name: 'List',
      url: '/businesses/list',
      routeTitle: ROUTE_BUSINESSES_LIST,
      eventType: EventType.NEW_MERCHANT,
      component: Business
    },
    {
      type: NAVLINK,
      name: 'Verify',
      url: '/businesses/verify',
      routeTitle: ROUTE_BUSINESS_APPROVAL_LIST,
      component: BusinessVerify
    },
    {
      type: NAVLINK,
      name: 'Approval',
      url: '/businesses/admin-approval',
      routeTitle: ROUTE_BUSINESS_REQUEST_UPDATE,
      component: RequestUpdate
    },
    {
      type: NAVLINK,
      name: 'PTEN Verify',
      url: '/businesses/pten/verify',
      routeTitle: ROUTE_BUSINESS_VERIFY_LIST,
      component: BusinessPTENVerify
    },
    {
      type: NAVLINK,
      name: 'Cashback',
      icon: 'pe-7s-magic-wand',
      url: '/businesses/cashback',
      routeTitle: ROUTE_BUSINESSES_CASHBACK,
      component: Cashback
    },
    {
      type: NAVLINK,
      name: 'Settlement',
      icon: 'pe-7s-magic-wand',
      url: '/businesses/settlement',
      routeTitle: ROUTE_BUSINESS_SETTLEMENT,
      eventType: EventType.COMPLETED_BULK_SETTLEMENT_MERCHANT,
      component: Settlement
    },
    {
      type: NAVLINK,
      name: 'Referral',
      url: '/businesses/referral',
      routeTitle: ROUTE_BUSINESS_REFERRAL_LIST,
      component: BusinessReferral
    },
  ]
}
