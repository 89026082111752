import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const adminApi = {
  list: (page, limit, sort, search) => {
    let url = `/admins/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  update: (id, data) => {
    return agent.requests.post(`/admins/${id}/update`, data);
  },
  softDelete: (id) => {
    return agent.requests.post(`/admins/${id}/soft-delete`);
  },
  create: (data) => {
    return agent.requests.post(`/admins/create`, data);
  },
  unlock: (id) => {
    return agent.requests.post(`/admins/${id}/unlock`);
  }
}
