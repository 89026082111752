import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { startOfDay, format, endOfDay } from 'date-fns';
import { DISCLAIMER_MODULE, MAIN_TAB_LOAD, CREATE_TAB, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE } from '../../../utils/constants/actionTypes';
import Table from '../../../components/Table/Table';
import { DisclaimerTypes } from '../../../utils/constants/enums/disclaimerTypes';
import { disclaimerApi } from '../../../services/disclaimerApi';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total,
    filter,
  } = useSelector(state => state.disclaimer);

  const getDisclaimerDebounced = useMemo(
    () => AwesomeDebouncePromise(disclaimerApi.list, 500),
    [],
  )

  const load = useCallback(
    () => {
      const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
      const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

      dispatch({
        module: DISCLAIMER_MODULE,
        type: MAIN_TAB_LOAD,
        call: getDisclaimerDebounced,
        args: [
          currentPage,
          limit,
          sort,
          startDate,
          endDate,
        ]
      });
    },
    [currentPage, limit, sort, filter, dispatch, getDisclaimerDebounced],
  )

  const createTab = (key, title) => {
    dispatch({
      module: DISCLAIMER_MODULE,
      type: CREATE_TAB,
      key,
      title
    });
  }

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: DISCLAIMER_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const table = () => {
    return {
      inProgress,
      data: items,
      sort,
      limit,
      total,
      currentPage,
      columns: [
        { title: 'Updated At', key: 'updatedAt', name: 'updatedAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
        { title: 'Version', key: 'version', name: 'version' },
        { title: 'Type', key: 'type', name: 'type', render: v => DisclaimerTypes.getStr(v) },
        {
          title: 'Action', key: '_id', name: 'action', className: 'text-center', sortable: false, render: (v, row) => {
            return (
              <button
                className="btn btn-icon btn-icon-only btn-sm btn-primary"
                onClick={() => {
                  createTab(v, DisclaimerTypes.getStr(row.type))
                }}
              >
                <i className="lnr-eye btn-icon-wrapper"> </i>
              </button>
            )
          }
        }
      ]
    };
  }

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table()}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
        </Table>
      </div>
    </div>
  )
}

export default MainTab
