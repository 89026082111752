import Fade from '@material-ui/core/Fade';
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux';
import { generateDataApi } from '../../../../../services/generateDataApi';
import EvyDatePicker from '../../../../../components/Forms/EvyDatePicker';
import FormField from '../../../../../components/Forms/FormField';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
// import { dummyItems } from './dummy';
import TableComponent from './Table';
import { endOfDay, startOfDay } from 'date-fns';

const MainTab = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  // const [regenerating, setRegenerating] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, seteEndDate] = useState(null)
  const [items, setItems] = useState(null)

  const submit = useCallback(() => {
    setLoading(true)
    dispatch({
      type: null,
      call: generateDataApi.list,
      args: ['MERCHANT', null, startDate.toISOString(), endDate.toISOString()]
    })
      .then(({ data }) => {
        setItems(data)
      })
      .catch(() => { })
      .finally(() => setLoading(false))
  }, [dispatch, startDate, endDate])

  // const regenerate = useCallback(() => {
  //   setRegenerating(true)
  //   dispatch({
  //     type: null,
  //     call: generateDataApi.regenerateBI,
  //     args: [startDate, endDate]
  //   })
  //     .catch(() => { })
  //     .finally(() => setRegenerating(false))
  // }, [dispatch, startDate, endDate])

  return (
    <div className="row">
      <div className="col-12">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <FormField
              label="Start Date"
            >
              <EvyDatePicker
                autoOk
                value={startDate}
                onChange={v => setStartDate(startOfDay(new Date(v)))}
              />
            </FormField>
            <FormField
              label="End Date"
            >
              <EvyDatePicker
                autoOk
                value={endDate}
                onChange={v => seteEndDate(endOfDay(new Date(v)))}
              />
            </FormField>
            <LoadingBtn
              className="btn btn-primary btn-block btn-lg mt-4"
              loading={loading}
              type="button"
              disabled={(!startDate && !endDate) || loading}
              onClick={submit}
            >
              Submit
            </LoadingBtn>
            {/* <LoadingBtn
              className="btn btn-outline-primary btn-block btn-lg"
              type="button"
              onClick={regenerate}
              loading={regenerating}
              disabled={(!startDate && !endDate) || regenerating}
            >
              Re-Generate
            </LoadingBtn> */}
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="report-table-wrapper">
          {
            items &&
            (
              items.length ?
                <TableComponent items={items} startDate={startDate} endDate={endDate} setLoading={setLoading} />
                :
                <h5 className="mb-0 px-3 py-4 text-center">No Data</h5>
            )
          }
          <Fade in={loading} timeout={300}>
            <div className="loader">
              <div className="line-scale-pulse-out">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}


export default MainTab
