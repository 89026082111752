import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { IMAGE_TEMP_MODULE } from "../../utils/constants/actionTypes";
import Resource from "../../components/Resource";
import { blogApi } from "../../services/blogApi";
import Image from "../../components/Table/DataImage";

const Index = () => {
  const {
    currentPage,
    limit,
    sort
  } = useSelector(state => state.imageTemp);

  const columns = [
    {
      title: "Created At",
      key: "createdAt",
      name: "createdAt",
      render: function (v, row) {
        return format(new Date(v), "yyyy-MM-dd HH:mm");
      },
    },
    {
      title: 'Cover', key: 'url', name: 'url', sortable: false, render: function (v, row) {
        return <Image src={v ? v : ''} />
      }
    },
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort,
    };
  }, [currentPage, limit, sort]);

  return (
    <div>
      <Resource
        title="Image Temp"
        subTitle="Image Temp"
        icon="pe-7s-id"
        list={{
          columns: columns,
          reducerPath: "imageTemp",
          module: IMAGE_TEMP_MODULE,
          call: blogApi.uploadList,
          apiQuery: apiQuery,
          deleteButton: {
            api: blogApi.deleteUpload
          }
        }}
      />
    </div>
  );
};

export default Index;
