import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, startOfDay } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { qrisRintisApi } from "../../../services/qrisRintisApi";
import {
  MAIN_TAB_LOAD,
  QRIS_RECON_MODULE
} from "../../../utils/constants/actionTypes";
import { combineBy } from '../../../utils/helpers/combineBy';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import FormField from '../../../components/Forms/FormField';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import useDetectBrowser from '../../../components/HooksUse/useDetectBrowser';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import VirtualTable from '../../../components/VirtualTable';
import ExcelExportBtn from '../../Report/General/Tables/ExcelExportBtn';
import { excelHref } from './TableExcel';

const columns = [
  { title: "#", key: "index" },
  { title: "Terminal ID", key: "TerminalID" },
  { title: "RRN", key: "RRN" },
  { title: "PAN", key: "PAN" },
  { title: "TRX Date", key: "TRXdate" },
  { title: "TRX time", key: "TRXtime", align: "right" },
  { title: "Proc Code", key: "ProcCode" },
  { title: "Amount", key: "Amount", align: "right" },
  { title: "Fee Type", key: "FeeType" },
  { title: "Fee", key: "Fee" },
  { title: "Currency", key: "Currency" },
  { title: "MCC", key: "MCC" },
  { title: "MCriteria", key: "MCr" },
  { title: "NNS Acquirer", key: "NNSAcq" },
  { title: "NNS Issuer", key: "NNSIss" },
  { title: "NNS Swc", key: "NNSSwc" },
  { title: "RC", key: "RC" },
  { title: "from Account", key: "fromAccnt" },
  { title: "Invoice", key: "Invoice" },
  { title: "Approval Code", key: "ApvCode" },
];

function MainTab() {
  const tableTitle = "QRIS Rintis Recon";
  const excelFormatName = ".xls";

  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [error, setError] = useState(null);
  const [reconnecting, setReconnecting] = useState(false)
  const [data, setData] = useState([])
  const { items, isLoading, rptDate } = useSelector((state) => state.qrisRecon);
  const { browser } = useDetectBrowser();

  const submit = useCallback(() => {
    if (date) {
      dispatch({
        module: QRIS_RECON_MODULE,
        type: MAIN_TAB_LOAD,
        call: qrisRintisApi.getQrisRecon,
        args: [{ date: date.toISOString() }],
      })
        .catch((error) => {
          if (error) {
            setError(true);
          }
        });
    }
    setData(items)
  }, [date, dispatch, items]);

  const reconnect = useCallback(() => {
    setReconnecting(true)
    dispatch({
      type: null,
      call: qrisRintisApi.nginxReconnectRintis
    })
      .then(() => {
        toast("Berhasil terkoneksi dengan Rintis", { type: 'success' });
      })
      .catch(() => {
        toast("Gagal terkoneksi dengan Rintis", { type: 'error' });
      })
      .finally(() => setReconnecting(false))
  }, [dispatch])

  const renderDateFilter = useMemo(() => {
    if (rptDate) return format(new Date(rptDate), "yyyy-MM-dd");
  }, [rptDate])

  const renderExcelFileName = useMemo(() => {
    // handle error at download Excel Table filename when using Firefox browser
    return `${combineBy(tableTitle.split(" "), "_")}_${renderDateFilter}${browser === "firefox" ? excelFormatName : ""}`;
  }, [browser, renderDateFilter])

  return (
    <>
      <div className="main-card mb-3 card">
        <div className="card-body">
          <FormField label="Date Filter">
            <EvyDatePicker
              value={date}
              onChange={(value) => setDate(startOfDay(new Date(value)))}
              placeholder="Select date"
              variant="inline"
              autoOk
            />
            <LoadingBtn
              className="btn btn-primary btn-block btn-lg mt-4"
              loading={isLoading}
              type="button"
              disabled={!date || isLoading}
              onClick={submit}
            >
              Submit
            </LoadingBtn>
            <LoadingBtn
              className="btn btn-outline-primary btn-block btn-lg"
              type="button"
              onClick={reconnect}
              loading={reconnecting}
              disabled={reconnecting}
            >
              <FontAwesomeIcon icon={faSyncAlt} className="mr-1" />
              Reconnect
            </LoadingBtn>
          </FormField>
        </div>
      </div>
      {
        data.length ? (
          <div className="card mutasi-card mb-3" key={data[0].TRXdate}>
            <div className="table-header">
              <div className="info-cell">
                <div className="d-flex justify-content-between align-items-center">
                  <div>{`${tableTitle} Data List`}</div>
                  <div>{renderDateFilter}</div>
                </div>
              </div>
              <div className="action-cell">
                <ExcelExportBtn
                  excelHref={excelHref(data)}
                  filename={renderExcelFileName}
                />
              </div>
            </div>
            <div className="report-table-container">
              <LoadingBlocker in={isLoading} />
              <VirtualTable
                rowHeight={30}
                tableHeight={400}
                data={data}
                columns={columns}
              />
            </div>
          </div>
        ) : (
          <div>
            {
              error && <h5 className="mb-0 px-3 py-4 text-center">No Data</h5>
            }
          </div>
        )
      }
    </>
  )
}

export default MainTab
