import React from 'react'
// import React, { useCallback, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { businessReferralApi } from '../../../../services/businessReferralApi';
// import { BUSINESS_REFERRAL_MODULE, GET_DETAIL } from '../../../../utils/constants/actionTypes';
// import useAsync from '../../../components/HooksUse/useAsync';
// import useMountedState from '../../../components/HooksUse/useMountedState';
// import Detail from './DetailTab';
// import MyForm from './Form'

const UpdateTab = () => {
  // const [isLoading, setIsLoading] = useState(false);
  // const { key, loading } = useSelector(state => state.businesses.businessReferral.tabs[index])
  // const { tabs } = useSelector(state => state.businesses.businessReferral)
  // const dispatch = useDispatch()
  // const isMounted = useMountedState()

  // const {
  //   value: { data: { businessReferral } = {} } = {}
  // } = useAsync(useCallback(
  //   () => dispatch({
  //     module: BUSINESS_REFERRAL_MODULE,
  //     type: GET_DETAIL,
  //     call: businessReferralApi.detail,
  //     args: [key],
  //     tabKey: index
  //   }),
  //   [dispatch, key, index]), true)

  // const onSubmit = (values) => {
  //   console.log("test")
  // }

  // return (
  //   <>
  //     <div className="main-card mb-3 card">
  //       <div className="card-body">
  //         <MyForm
  //           onSubmit={onSubmit}
  //         >
  //         </MyForm>
  //       </div>
  //     </div>
  //     <Detail />
  //   </>
  // )
  return () => <div>Update</div>
}

export default UpdateTab
