import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { careerApi } from "../../services/careerApi";
import { UPDATE, CAREER_MODULE } from "../../utils/constants/actionTypes";
import Form from "./Form";

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { isUpdating } = useSelector((state) => state.career);
  const { item, key } = useSelector((state) => state.career.tabs[index]);
  const [errorExistingValue, setErrorExistingValue] = useState("");

  const onSubmit = (values) => {
    const data = {
      title: values.title,
      division: values.division,
      jobType: values.jobType,
      location: values.location,
      workingType: values.workingType,
      salary: values.salary,
      slug: values.slug,
      content: values.content,
      link: values.link,
      active: values.active === 'ACTIVE' ? true : false,
      periodFrom: values.periodFrom,
      periodTo: values.periodTo
    };
    dispatch({
      module: CAREER_MODULE,
      type: UPDATE,
      call: careerApi.update,
      args: [key, data],
    }).catch((error) => {
      setErrorExistingValue(error.message);
    });
  };

  return item ? (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={isUpdating}
          errorExistingValue={errorExistingValue}
          initialValues={{
            title: item.title,
            division: item.division,
            jobType: item.jobType,
            workingType: item.workingType,
            slug: item.slug,
            location: item.location,
            content: item.content,
            salary: item.salary,
            link: item.link,
            active: item.active === true ? 'ACTIVE' : 'INACTIVE',
            periodFrom: item.periodFrom,
            periodTo: item.periodTo
          }}
        />
      </div>
    </div>
  ) : (
    <div className="card">
      <div className="card-body">
        <h5 className="text-center mb-0">Not Found</h5>
      </div>
    </div>
  );
};

export default UpdateTab;
