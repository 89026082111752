import { TransactionType } from "../../../utils/constants/enums/transactionTypes";
import {
  REPORT_TOPUP,
  REPORT_MUTASI,
  REPORT_BILLER_PULSA,
  REPORT_WALLET,
  REPORT_BILLER_PLN,
  REPORT_BILLER_PDAM,
  REPORT_BILLER_GAME_VOUCHER,
  REPORT_BILLER_ETOLL,
  REPORT_BILLER_POSTPAID,
  REPORT_BILLER_TELKOM,
  REPORT_BILLER_BPJS,
  REPORT_BILLER_PGN,
  REPORT_BILLER_TV,
  REPORT_DIRECT_INTEGRATION,
  REPORT_INJECTION,
  REPORT_DEDUCTION,
  REPORT_SETTLEMENT,
  REPORT_WITHDRAW,
  REPORT_VOUCHER,
  REPORT_BILLER_MULTIFINANCE,
  REPORT_BILLER_SAMOLNAS,
  REPORT_BILLER_CC,
  REPORT_BILLER_PBB,
  REPORT_BILLER_PHONE_PACKAGE,
  REPORT_BILLER_PLN_TOKEN,
  REPORT_BILLER_PLN_NON_BILL,
  REPORT_MF_INDOFUND,
  REPORT_BILLER_INTERNET,
  REPORT_BILLER_STREAMING,
  REPORT_BILLER_EDU_VOUCHER,
  REPORT_BILLER_EDUCATION,
  REPORT_MERCHANT_INDOFUND
} from '../../../utils/constants/dataTypes';
import { PpobType } from "../../../utils/constants/enums/ppobTypes";
import { transactionApi } from "../../../services/transactionApi";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_TABLE, REPORT_MODULE } from "../../../utils/constants/actionTypes";
import useAsync from "../../../components/HooksUse/useAsync";

export const useFiltersArr = () => {
  const dispatch = useDispatch();
  const {
    filter: {
      walletModel
    }
  } = useSelector(state => state.report);

  const {
    value: { data: { listCompanyCode = [] } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: transactionApi.listCompanyCode, args: [] }),
    [dispatch]), true)

  const setTableConfig = key => val => {
    dispatch({
      module: REPORT_MODULE,
      type: FILTER_TABLE,
      value: val,
      key: key
    });
  }

  return ([
    {
      name: "TOP UP",
      type: [TransactionType.TOPUP, TransactionType.INJECTION_PRIMARY, TransactionType.TOPUP_FEE],
      viewType: REPORT_TOPUP,
    },
    {
      name: "MUTASI",
      type: [
        TransactionType.PAY,
        TransactionType.TRANSFER,
        TransactionType.REFUND,
        TransactionType.TOPUP,
        TransactionType.REVERSE_TOPUP,
        TransactionType.WITHDRAW,
        TransactionType.SETTLEMENT,
        TransactionType.SETTLEMENT_DECLINED,
        TransactionType.CASHBACK,
        TransactionType.REWARD,
        TransactionType.INJECTION_PRIMARY,
        TransactionType.INJECTION_POINT,
        TransactionType.DEDUCTION_PRIMARY,
        TransactionType.DEDUCTION_POINT,
        TransactionType.POINT_EXPIRED,
        TransactionType.CASHOUT,
        TransactionType.REWARD_REFUND,
        TransactionType.BANK_TRANSFER,
        TransactionType.TOPUP_FEE,
        TransactionType.PARTNER_PAY,
        TransactionType.QRIS_PAY,
        TransactionType.QRIS_REFUND,
        TransactionType.QRIS_CANCEL_REFUND,
        TransactionType.HOLD_BLNC,
        TransactionType.UNHOLD_BLNC,
        TransactionType.BANK_TRANSFER_REFUND,
        TransactionType.QRIS_MDR,
        TransactionType.QRIS_MDR_INJECTION,
        TransactionType.PARTNER_SETTLEMENT,
        TransactionType.PARTNER_SETTLEMENT_DECLINE
      ],
      viewType: REPORT_MUTASI,
    },
    {
      name: "BILLER",
      group: [
        {
          name: "MULTIFINANCE-INDOFUND",
          type: [TransactionType.PAY, PpobType.MF_INDOFUND],
          viewType: REPORT_MF_INDOFUND,
        },
        {
          name: "PULSA",
          type: [TransactionType.PAY, PpobType.PHONE_CREDIT],
          viewType: REPORT_BILLER_PULSA,
        },
        {
          name: "PHONE PASCABAYAR",
          type: [TransactionType.PAY, PpobType.PHONE_POSTPAID],
          viewType: REPORT_BILLER_POSTPAID,
        },
        {
          name: "PHONE PACKAGE",
          type: [TransactionType.PAY, PpobType.PHONE_PACKAGE],
          viewType: REPORT_BILLER_PHONE_PACKAGE,
        },
        {
          name: "PLN",
          type: [TransactionType.PAY, PpobType.PLN],
          viewType: REPORT_BILLER_PLN,
        },
        {
          name: "PLN TOKEN",
          type: [TransactionType.PAY, PpobType.PLN_TOKEN],
          viewType: REPORT_BILLER_PLN_TOKEN
        },
        {
          name: "PLN NON BILL",
          type: [TransactionType.PAY, PpobType.PLN_NON_BILL],
          viewType: REPORT_BILLER_PLN_NON_BILL
        },
        {
          name: "PDAM",
          type: [TransactionType.PAY, PpobType.PDAM],
          viewType: REPORT_BILLER_PDAM
        },
        {
          name: "GAME VOUCHER",
          type: [TransactionType.PAY, PpobType.GAME_VOUCHER],
          viewType: REPORT_BILLER_GAME_VOUCHER
        },
        {
          name: "E-TOLL",
          type: [TransactionType.PAY, PpobType.ETOLL],
          viewType: REPORT_BILLER_ETOLL
        },
        {
          name: "TELKOM",
          type: [TransactionType.PAY, PpobType.TELKOM],
          viewType: REPORT_BILLER_TELKOM
        },
        {
          name: "BPJS",
          type: [TransactionType.PAY, PpobType.BPJS_HEALTH, PpobType.BPJS_EMPLOYMENT],
          viewType: REPORT_BILLER_BPJS
        },
        {
          name: "PGN",
          type: [TransactionType.PAY, PpobType.PGN],
          viewType: REPORT_BILLER_PGN
        },
        {
          name: "TV",
          type: [TransactionType.PAY, PpobType.TV],
          viewType: REPORT_BILLER_TV
        },
        {
          name: "MULTIFINANCE",
          type: [TransactionType.PAY, PpobType.MULTIFINANCE],
          viewType: REPORT_BILLER_MULTIFINANCE
        },
        {
          name: "SAMOLNAS",
          type: [TransactionType.PAY, PpobType.SAMOLNAS],
          viewType: REPORT_BILLER_SAMOLNAS
        },
        {
          name: "CREDIT CARD",
          type: [TransactionType.PAY, PpobType.CREDIT_CARD],
          viewType: REPORT_BILLER_CC
        },
        {
          name: "PBB",
          type: [TransactionType.PAY, PpobType.PBB],
          viewType: REPORT_BILLER_PBB
        },
        {
          name: "INTERNET",
          type: [TransactionType.PAY, PpobType.INTERNET],
          viewType: REPORT_BILLER_INTERNET
        },
        {
          name: "STREAMING",
          type: [TransactionType.PAY, PpobType.STREAMING],
          viewType: REPORT_BILLER_STREAMING
        },
        {
          name: "EDUCATION VOUCHER",
          type: [TransactionType.PAY, PpobType.EDU_VOUCHER],
          viewType: REPORT_BILLER_EDU_VOUCHER
        },
        {
          name: "EDUCATION",
          type: [TransactionType.PAY, PpobType.EDUCATION],
          viewType: REPORT_BILLER_EDUCATION
        },
      ]
    },
    {
      name: "DIRECT INTEGRATION",
      group: listCompanyCode.map((v) => (
        {
          name: v.description.toUpperCase(),
          type: v.companyCode,
          viewType: v.companyCode === "INDOFUND" && walletModel === "MERCHANT" ?
            REPORT_MERCHANT_INDOFUND
            :
            REPORT_DIRECT_INTEGRATION,
          walletModel,
          setWalletModel: (j) => setTableConfig("walletModel")(j)
        }
      ))
    },
    {
      name: "WALLET",
      type: REPORT_WALLET,
      viewType: REPORT_WALLET,
      walletModel,
      setWalletModel: (v) => setTableConfig("walletModel")(v)
    },
    {
      name: "INJECTION",
      type: [TransactionType.INJECTION_PRIMARY, TransactionType.INJECTION_POINT],
      viewType: REPORT_INJECTION
    },
    {
      name: "DEDUCTION",
      type: [TransactionType.DEDUCTION_PRIMARY, TransactionType.DEDUCTION_POINT],
      viewType: REPORT_DEDUCTION
    },
    {
      name: "SETTLEMENT",
      type: [TransactionType.SETTLEMENT],
      viewType: REPORT_SETTLEMENT
    },
    {
      name: "WITHDRAW",
      type: [TransactionType.WITHDRAW],
      viewType: REPORT_WITHDRAW
    },
    {
      name: "VOUCHER",
      type: [],
      viewType: REPORT_VOUCHER
    }
  ])
}
