import React, { Component } from 'react';

class EvyTextField extends Component {
  render() {
    const {
      id,
      value,
      label,
      onChange,
      onBlur,
      error,
      helperText,
      placeholder,
      multiline,
      className,
      autoComplete,
      spellCheck,
      readOnly,
      disabled,
      row,
      labelTitle,
      append,
      prepend,
    } = this.props;

    let field;

    if (multiline) {
      field = <textarea
        disabled={disabled}
        readOnly={readOnly}
        id={id}
        name={id}
        placeholder={placeholder}
        value={value === null ? '' : value}
        onChange={onChange}
        onBlur={onBlur}
        spellCheck={spellCheck}
        className={`form-control ${className ? className : ''}`}
      />
    } else {
      field = <input
        title={readOnly ? value : ''}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        id={id}
        name={id}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        type="text"
        className={`form-control ${className ? className : ''}`}
      />
    }

    return (
      <div className={`form-group ${row ? 'row' : ''}`}>
        <label title={labelTitle} className={row ? 'col-form-label col-sm-3 text-md-right' : ''} htmlFor={id}>{label}</label>
        <div className={`evy-input${row ? ' col-sm-9' : ''}${error ? ' is-error' : ''}`}>
          <div className={`${append || prepend ? 'input-group' : ''}`}>
            {prepend ? (
              <div className="input-group-prepend">
                <span className="input-group-text">
                  {prepend}
                </span>
              </div>
            ) : ''}
            {field}
            {append ? (
              <div className="input-group-append">
                <span className="input-group-text">
                  {append}
                </span>
              </div>
            ) : ''}
          </div>
          {error ? <em className="error invalid-feedback">{helperText}</em> : <small className="form-text text-muted">{helperText}</small>}
        </div>
      </div>
    )
  }
}

export default EvyTextField;
