import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div className="page-404">
        <svg className="animated" viewBox="0 0 537 1144" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Prise" transform="translate(-9.000000, 1.000000)">
              <text id="4" fontFamily="Quicksand-Regular, Quicksand" fontSize="288" fontWeight="normal" fill="#1C1C1C">
                <tspan x="0" y="651">4</tspan>
              </text>
              <text id="4" fontFamily="Quicksand-Regular, Quicksand" fontSize="288" fontWeight="normal" fill="#1C1C1C">
                <tspan x="397" y="651">4</tspan>
              </text>
              <circle id="Oval" stroke="#1C1C1C" strokeWidth="10" cx="280" cy="567" r="84"></circle>
              <circle id="Oval" stroke="#1C1C1C" strokeWidth="5" cx="281" cy="568" r="63"></circle>
              <path d="M278,651 C268,779.333333 283.833333,843.5 325.5,843.5 C388,843.5 449.668025,755.542835 260.5,762.5 C71.3319753,769.457165 118.5,1046.5 118.5,1143" id="Path-2" stroke="#1C1C1C" strokeWidth="6"></path>
              <rect id="Rectangle-4" fill="#1C1C1C" x="230" y="363" width="100" height="8" rx="4"></rect>
              <rect id="Rectangle-5" fill="#1C1C1C" x="275" y="288" width="10" height="11"></rect>
              <path d="M280.5,292 C282.5,200.666667 257.666667,183.916667 206,241.75 C128.5,328.5 618,184.5 506,292 C431.333333,363.666667 396.5,266.333333 401.5,0" id="Path-3" stroke="#1C1C1C" strokeWidth="6"></path>
              <polygon id="Path-4" stroke="#1C1C1C" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" points="243 368 243 335 259.658385 335 259.658385 302 297.602484 302 297.602484 335 317.5 335 317.5 368"></polygon>
              <rect id="Rectangle-6" fill="#1C1C1C" x="258" y="366" width="5" height="41" rx="2.5"></rect>
              <rect id="Rectangle-6" fill="#1C1C1C" x="296" y="366" width="5" height="41" rx="2.5"></rect>
              <circle id="eye-left" fill="#1C1C1C" cx="257" cy="568" r="6"></circle>
              <circle id="eye-right" fill="#1C1C1C" cx="301" cy="568" r="6"></circle>
            </g>
          </g>
        </svg>
        <div className="text-wrapper">
          <div className="separator"></div>
          <div>
            <h1>Oops!</h1>
            <h4>There's something wrong</h4>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

export default NotFound;
