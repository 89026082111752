import React from 'react'
import { businessesApi } from '../../../../../services/businessesApi'
import UploadPhotoTemplate from '../UploadPhotoOutlet'
import BusinessInformation from './BusinessInformation'

const PTENInformation = ({ item, module, onSuccess = () => { }, readOnly }) => {
  return (
    <div className="row">
      <div className="col-12 col-xl-5">
        <UploadPhotoTemplate
          previousImageUrl={item?.imageQris?.url}
          onSuccess={onSuccess}
          id={item._id}
          label="QRIS Dari PTEN"
          call={businessesApi.updateQrisGpn}
        />
      </div>
      <div className="col-12 col-xl-7">
        <BusinessInformation
          item={item}
          module={module}
          onSuccess={onSuccess}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

export default PTENInformation;
