import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import {
  DONATION_NEWS_MODULE
} from "../../../../utils/constants/actionTypes";
import Resource from "../../../../components/Resource";
import DetailTab from "./DetailTab";
import AllStatus from "../../../../utils/constants/enums/status";
import { donationNewsApi } from "../../../../services/donationNewsApi";
import { combineBy } from "../../../../utils/helpers/combineBy";
import { DATE_PICKER, DONATION_SELECT, SELECT_FIELD } from "../../../../utils/constants/inputTypes";
import DonationStatusOpts from "../../../../utils/constants/enums/status/donationStatusOpts";

const Index = () => {
  const { currentPage, limit, sort, filter } = useSelector(
    (state) => state.donation.news
  );

  const columns = [
    {
      title: 'Last Updated At', key: 'updatedAt', name: 'updatedAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      title: 'Owner', key: 'donation.partner', name: 'partner', sortable: false, render: v =>
        <div >
          <div className="text-primary">{v.name}</div>
          <div className="text-muted">{v.integrationType}</div>
        </div >
    },
    {
      title: 'Title', key: 'title', name: 'title'
    },
    {
      title: 'Donation', key: 'donation', name: 'donation', render: v => {
        return (
          <div>
            <div className="text-primary">{v.name}</div>
            <div className="text-muted">ID: {v._id}</div>
          </div>
        )
      }
    },
    {
      title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v)
    }
  ];

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.status ? `status|${filter.status}` : false,
      filter.donation && typeof filter.donation === 'object' ? `donation|${filter.donation.value}` : false
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      startDate,
      endDate,
      search,
    };
  }, [currentPage, limit, sort, filter]);

  return (
    <div>
      <Resource
        title="Donation News"
        subTitle="Donation News list."
        icon="pe-7s-drop"
        list={{
          columns: columns,
          reducerPath: "donation.news",
          module: DONATION_NEWS_MODULE,
          call: donationNewsApi.list,
          apiQuery: apiQuery,
          filters: [
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: DonationStatusOpts,
              value: filter.status
            },
            {
              label: "Filter by Donation",
              type: DONATION_SELECT,
              key: "donation",
              value: filter.donation,
              placeholder: "No Filter"
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        detail={{ component: DetailTab }}
      />
    </div>
  );
};

export default Index;
