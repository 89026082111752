import React, { useMemo } from 'react';
import Resource from '../../../components/Resource';
import { useSelector } from 'react-redux';
import { BUSINESS_REFERRAL_MODULE } from '../../../utils/constants/actionTypes';
import CreateTab from './CreateTab';
import UpdateTab from './UpdateTab';
import { DATE_PICKER, SELECT_FIELD } from '../../../utils/constants/inputTypes';
import { businessReferralApi } from '../../../services/businessReferralApi';
import { endOfDay, startOfDay } from 'date-fns';

const BusinessReferral = () => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.businesses.businessReferral);

  const columns = [
    { title: 'Referral Code', key: 'name', name: 'name' },
    {
      title: 'Quota', key: 'quota', name: 'quota'
    },
    { title: 'Total Referred', key: 'totalReferred', name: 'referred' },
    { title: 'Remaining Quota', key: 'remainingQuota', name: 'remainingQuota' },
    { title: 'Status', key: 'isActive', name: 'isActive', render: (v) => v ? "Active" : "Inactive" },
  ]

  const apiQuery = useMemo(() => {
    const startPeriod = filter.startPeriod ? startOfDay(new Date(filter.startPeriod), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const endPeriod = filter.endPeriod ? endOfDay(new Date(filter.endPeriod), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    return {
      page: currentPage,
      limit,
      sort,
      isActive: filter.status,
      startPeriod,
      endPeriod
    }
  }, [currentPage, limit, sort, filter])

  return (
    <Resource
      title="Referral List"
      subTitle="Referral Listing page."
      icon="pe-7s-share"
      list={{
        search: true,
        columns: columns,
        reducerPath: "businesses.businessReferral",
        module: BUSINESS_REFERRAL_MODULE,
        call: businessReferralApi.list,
        apiQuery: apiQuery,
        filters: [
          {
            label: "Filter Status",
            type: SELECT_FIELD,
            key: "status",
            value: filter.status,
            options: [
              {
                value: "true",
                label: "Active",
              },
              {
                value: "false",
                label: "Inactive",
              }
            ]
          },
          {
            label: "Start Date Histories",
            type: DATE_PICKER,
            key: "startPeriod",
            placeholder: "Select date",
            value: filter.startPeriod,
          },
          {
            label: "End Date Histories",
            type: DATE_PICKER,
            key: "endPeriod",
            placeholder: "Select date",
            value: filter.status,
          }
        ]
      }}
      create={{
        component: CreateTab
      }}
      update={{
        component: UpdateTab
      }}
    />
  );
}

export default BusinessReferral;
