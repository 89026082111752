import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE, PPOB_MODULE } from '../../../../utils/constants/actionTypes';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';
import ProductList from './ProductList';
import Form from './Form';
import AllStatus from '../../../../utils/constants/enums/status';
import { ppobApi } from '../../../../services/ppobApi';

const UpdateTab = ({ id, index }) => {
  const dispatch = useDispatch();
  const { tabs, items } = useSelector(state => state.billers.ppob);
  const tab = tabs[index];
  const selectedItem = items.find(row => row._id === id);
  const [selectedType, setSelectedType] = useState(selectedItem?.type ?? PpobType.PHONE_CREDIT);

  const submit = useCallback((values) => {
    const data = {
      ...values,
      isDisc: values.isDisc === 'true' ? true : '',
      referralBonus: 0,
      aggregator: values.aggregator.map((el, i) => (
        {
          ...el,
          periority: i + 1,
          revenue: el.custom ? +el.revenue : (+values.sellPrice - +el.price),
          price: el.custom ? 0 : +el.price,
          status: el.status === "ACTIVE" ? "ACTIVE" : "INACTIVE",
          type: el.biller === 'INTERNAL' ? 'INTERNAL' : 'EXTERNAL'
        }
      )),
      active: values.active === AllStatus.INACTIVE ? '' : values.active
    }
    if (values.image[0]) data.image = values.image[0]
    else delete data.image

    dispatch({
      module: PPOB_MODULE,
      type: UPDATE,
      call: ppobApi.update,
      args: [id, data],
      tabKey: index
    })
  }, [dispatch, index, id])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        {selectedItem ?
          <>
            <ProductList type={selectedType} />
            <Form
              submit={submit}
              initialValue={{
                ...selectedItem,
                aggregator: selectedItem?.aggregator.sort((a, b) => a.periority - b.periority).map(el => ({ ...el, custom: !Boolean(+el.price) })),
                active: selectedItem?.active ? AllStatus.ACTIVE : AllStatus.INACTIVE,
                image: null
              }}
              setSelectedType={setSelectedType}
              isLoading={tab.loading}
              previousImageUrl={selectedItem?.image?.url}
            />
          </>
          :
          <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
        }
      </div>
    </div>
  )
}

export default UpdateTab
