import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainTab from './MainTab';
import { CHANGE_TAB, BANK_ACCOUNT_MODULE, CLOSE_TAB } from '../../../utils/constants/actionTypes';
import CreateTab from './CreateTab';
import UpdateTab from './UpdateTab';

import { Helmet } from 'react-helmet';
import { Tabs, Tab } from '../../../components/Tabs/Tabs';

const Index = () => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => state.bankAccount);

  const handleTab = type => key => dispatch({ module: BANK_ACCOUNT_MODULE, type: type, key: key });

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Bank Accounts</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-id icon-gradient bg-malibu-beach"></i>
            </div>
            <div>
              Bank Account Control
              <div className="page-title-subheading">Full Bank Account Control includes Create, Delete & Updates.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
        <Tab title="Create">
          <CreateTab />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => handleTab(CLOSE_TAB)(row.key)}
            onClick={() => handleTab(CHANGE_TAB)(row.key)}
            active={activeTabKey === row.key}
          >
            <UpdateTab index={key} />
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Index;
