import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DatePicker } from "@material-ui/pickers";
import { useSelector, useDispatch } from 'react-redux';
import { EXCEL_FORMATS, FILTER_TABLE, REPORT_MODULE } from '../../../utils/constants/actionTypes';
import { TypeFilterGroup, TypeFilterItem, TypeFilterWrapper } from '../../../layouts/TypeFilter';
import FloatBar from '../../../components/FloatBar';
import UserSelect from '../../../components/Select/UserSelect';
import MerchantSelect from '../../../components/Select/MerchantSelect';
import { useFiltersArr } from './filters';
import PartnerSelect from '../../../components/Select/PartnerSelect';
import { Switch } from '@material-ui/core';
import EvyTextField from '../../../components/Forms/EvyTextField';
import { useNotification } from '../../../components/HooksUse/useNotification';
// import BusinessSelect from '../../../components/Select/BusinessSelect';

const optionAll = { value: 'all', label: 'SELECT ALL' };

const FloatBarComponent = ({
  excludedViewType,
  open,
  setOpen,
  submit
}) => {
  const [bulking, setBulking] = useState(false);
  const filtersArr = useFiltersArr();
  const inputFileRef = useRef();
  const [filename, setFilename] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [email, setEmail] = useState("");
  const [successNotify] = useNotification('success', true);
  const {
    items,
    isUpdating,
    filter: {
      // selectedBusiness,
      selectedPartner,
      selectedMerchant,
      selectedUser,
      selectedType,
      viewType,
      fromDate,
      toDate
    }
  } = useSelector(state => state.report);
  const dispatch = useDispatch();

  const setTableConfig = key => val => {
    dispatch({
      module: REPORT_MODULE,
      type: FILTER_TABLE,
      value: val,
      key: key
    });
  }

  const handleTypeChange = e => {
    setTableConfig("selectedType")(e.target.value);
    setTableConfig("viewType")(e.target.dataset.viewtype)
  }

  const handleBulkingChange = e => {
    setBulking(e.target.checked);
  }

  useEffect(() => {
    if (items.length < 1) setOpen(true);
  }, [items, setOpen])

  const showFileUpload = useCallback(
    () => inputFileRef?.current?.click(),
    []
  )

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) return
    if (!EXCEL_FORMATS.includes(file.type)) {
      setError('Invalid File Format')
      return
    }
    setFile(file)
    setFilename(file.name)
  }

  const submitBulking = async () => {
    try {
      const formData  = new FormData();
      formData.append("email", email);
      formData.append("file", file);
      
      await fetch(`${process.env.REACT_APP_BASE_API_DENO}/api/reports/export-mutations`, {
        method: 'POST',
        body: formData
      });
      successNotify('Silahkan check email guys')

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <FloatBar
      open={open}
      onToggle={() => setOpen(prev => !prev)}
      disabled={isUpdating}
      onSubmit={bulking ? submitBulking : submit}
    >
      <h3 className="themeoptions-heading">Model Filter</h3>
      <div className="p-3">
        <div className="row">
          <div className="col-12 col-md-6">
            <UserSelect
              value={selectedUser}
              customOptions={[{ ...optionAll, model: 'USER' }]}
              onChange={(v) => {
                setTableConfig("selectedUser")(v);
                setTableConfig("selectedMerchant")(null);
                setTableConfig("selectedPartner")(null);
              }}
              disabled={excludedViewType.includes(viewType)}
            />
          </div>
          {/* <div className="col-12 col-md-6">
            <BusinessSelect
              value={selectedBusiness}
              customOptions={[optionAll]}
              onChange={v => setTableConfig("selectedBusiness")(v)}
              disabled={excludedViewType.includes(viewType)}
            />
          </div> */}
          <div className="col-12 col-md-6">
            <MerchantSelect
              value={selectedMerchant}
              customOptions={[{ ...optionAll, model: 'MERCHANT' }]}
              onChange={(v) => {
                setTableConfig("selectedMerchant")(v);
                setTableConfig("selectedUser")(null);
                setTableConfig("selectedPartner")(null);
              }}
              disabled={excludedViewType.includes(viewType)}
            />
          </div>
          <div className="col-12 col-md-6">
            <PartnerSelect
              value={selectedPartner}
              disabled={excludedViewType.includes(viewType)}
              onChange={(v) => {
                setTableConfig("selectedPartner")(v);
                setTableConfig("selectedMerchant")(null);
                setTableConfig("selectedUser")(null);
              }}
            />
          </div>
        </div>
      </div>
      <h3 className="themeoptions-heading d-flex justify-content-between">
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <div>Date Filter</div>
          <div>
            <Switch value={bulking} color='primary' onChange={handleBulkingChange}></Switch>
            <label>Bulking</label>
          </div>
        </div>

      </h3>
      <div className="p-3 drawer-picker">
        {bulking ? (
          <div className="">
            <div className={`form-group row`}>
              <label title={"Upload Xlsx"} className={'col-form-label col-sm-3 text-md-right'}>Upload Xlsx</label>

              <div className="evy-input col-sm-9" >
                <button type="button" className="btn btn-light btn-icon" onClick={showFileUpload} >
                  <i className="btn-icon-wrapper pe-7s-upload" />
                  <span>{filename ? filename : 'No File Selected'}</span>
                </button>
                {error && <em className="error invalid-feedback d-block">{error}</em>}
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  onChange={handleChange}
                  accept={EXCEL_FORMATS.join(', ')}
                />
              </div>
            </div>
            <div className="">
              <EvyTextField
                row
                label="Email"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md-6">
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="From Date"
                format="dd-MM-yyyy"
                value={fromDate}
                onChange={v => setTableConfig("fromDate")(new Date(v))}
                fullWidth
                margin="dense"
              />
            </div>
            <div className="col-12 col-md-6">
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="To Date"
                format="dd-MM-yyyy"
                value={toDate}
                onChange={v => setTableConfig("toDate")(new Date(v))}
                fullWidth
                margin="dense"
              />
            </div>
          </div>
        )}
      </div>
      <h3 className="themeoptions-heading">Type Filter</h3>
      <div className="p-3">
        <TypeFilterWrapper>
          {filtersArr.map((v, i) => {
            return v.group?.length ?
              (
                <TypeFilterGroup name={v.name} key={i}>
                  {v.group.map((j, k) => {
                    return (
                      <TypeFilterItem
                        key={k}
                        selectedViewType={viewType}
                        name={j.name}
                        type={j.type}
                        selectedType={selectedType}
                        viewType={j.viewType}
                        onChange={(e) => {
                          handleTypeChange(e)
                        }}
                        walletModel={j.walletModel}
                        setWalletModel={j.setWalletModel}
                      />
                    )
                  })}
                </TypeFilterGroup>
              )
              :
              (
                <TypeFilterItem
                  key={i}
                  selectedViewType={viewType}
                  name={v.name}
                  type={v.type}
                  selectedType={selectedType}
                  viewType={v.viewType}
                  onChange={(e) => {
                    handleTypeChange(e)
                  }}
                  walletModel={v.walletModel}
                  setWalletModel={v.setWalletModel}
                />
              )
          })}
        </TypeFilterWrapper>
      </div>
    </FloatBar>
  )
}

export default FloatBarComponent
