import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import HookTextField from '../../../components/Forms/HookTextField';
import { useForm } from 'react-hook-form';
import { toIDR } from '../../../utils/helpers/currency';
import { yupResolver } from '@hookform/resolvers';
import SelectField from '../../../components/Forms/SelectField';
import { endOfDay, format, startOfDay } from 'date-fns';
import activeStatusOpts from '../../../utils/constants/enums/status/activeStatusOpts';
import { useDispatch } from 'react-redux';
import CompanySelect from '../../../components/Select/CompanySelect';
import { PpobTypeOpts } from '../../../utils/constants/enums/ppobTypes';
import PPOBSelect from '../../../components/Select/PPOBSelect';
import { numberRegExp } from '../../../utils/constants/regexTypes';
import PartnerSelect from '../../../components/Select/PartnerSelect';
import { ProductCategoryTypeOpts } from '../../../utils/constants/enums/productCategory';
import { promoConfigApi } from '../../../services/promoConfigApi';

const schema = yup.object().shape({
  method: yup.string().required(),
  type: yup.string().required(),
  subType: yup.string().required(),
  maxAmount: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('subType', {
      is: subType => subType === "PCT",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  periodFrom: yup.string().required(),
  periodTo: yup.string().required(),
  status: yup.string().required(),
  maxQuota: yup.string().matches(numberRegExp, 'Only Number Allowed').required(),
  quotaApplicable: yup.string().required(),
  maxQuotaApplicable: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('quotaApplicable', {
      is: quotaApplicable => quotaApplicable === "PER_USER",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  value: yup.string().matches(numberRegExp, 'Only Number Allowed').required()
    .when('subType', {
      is: subType => subType === 'PCT',
      then: yup => yup.test(
        "range",
        "Input must be 0 - 100%",
        value => +value >= 0 && +value <= 100
      ),
      otherwise: yup => yup,
    }),
  rules: yup.object().shape({
    type: yup.string().required(),
    subType: yup.string().required(),
  }),
  product: yup.object().shape({
    category: yup.string().required(),
    subCategory: yup.string().required(),
    type: yup.string(),
  })
});

const Form = ({
  initialValue,
  isLoading,
  onSubmit,
  productSubCategory
}) => {
  const dispatch = useDispatch();
  const [subCategories, setSubCategories] = useState([]);

  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      periodFrom: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      periodTo: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      ...initialValue
    }
  });

  const getSubCategoryProduct = useCallback((e) => {
    dispatch({
      type: null,
      call: promoConfigApi.getSubCategoryProduct,
      args: [e]
    })
      .then(({ data: { subCategoryValues = [] } = {} } = {}) => {
        setSubCategories(subCategoryValues);
      })
      .catch(() => { })
  }, [dispatch]);
  const subCategoryOpts = subCategories.map(row => ({ value: row, label: row }));

  const handleDateChange = field => date => {
    let v;
    if (field === 'periodFrom') {
      v = startOfDay(new Date(date));
    } else if (field === 'periodTo') {
      v = endOfDay(new Date(date));
    }
    setValue(field, format(new Date(v), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), { shouldValidate: true });
  }

  const {
    method,
    type,
    subType,
    value,
    maxAmount,
    status,
    periodFrom,
    periodTo,
    quotaApplicable,
    rules,
    product
  } = watch(['method', 'type', 'subType', 'value', 'maxAmount', 'status', 'periodFrom', 'periodTo', 'quotaApplicable', 'rules', 'product']);

  useEffect(() => {
    register('method');
    register('type');
    register('subType');
    register('status');
    register('periodFrom');
    register('periodTo');
    register('quotaApplicable');
    register('rules.type');
    register('rules.subType');
    register('product.category');
    register('product.subCategory');
    register('product.type');
    register('maxAmount');

    return () => {
      unregister('method');
      unregister('type');
      unregister('subType');
      unregister('status');
      unregister('periodFrom');
      unregister('periodTo');
      unregister('quotaApplicable');
      unregister('rules.type');
      unregister('rules.subType');
      unregister('product.category');
      unregister('product.subCategory');
      unregister('product.type');
      unregister('maxAmount');
    }
  }, [register, unregister])

  useEffect(() => {
    if (productSubCategory) {
      getSubCategoryProduct(productSubCategory)
    }
  }, [getSubCategoryProduct, productSubCategory]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">GENERAL</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-sm-6">
                  <SelectField
                    label="Method"
                    options={
                      [
                        {
                          value: 'VOUCHER',
                          label: 'Voucher'
                        },
                        {
                          value: 'SYSTEM',
                          label: 'System'
                        }
                      ]
                    }
                    onChange={v => setValue('method', v?.value, { shouldValidate: true })}
                    value={method}
                    error={errors.method}
                    helperText={errors.method ? errors.method?.message : null}
                  />
                </div>
                <div className="col-sm-6">
                  <SelectField
                    label="Status"
                    options={activeStatusOpts}
                    onChange={v => setValue('status', v?.value, { shouldValidate: true })}
                    value={status}
                    error={errors.status}
                    helperText={errors.status ? errors.status?.message : null}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <SelectField
                    label="Type"
                    options={
                      [
                        {
                          value: 'DISCOUNT',
                          label: 'Discount'
                        },
                        {
                          value: 'CASHBACK',
                          label: 'Cashback'
                        }
                      ]
                    }
                    onChange={v => setValue('type', v?.value, { shouldValidate: true })}
                    value={type}
                    error={errors.type}
                    helperText={errors.type ? errors.type?.message : null}
                  />
                </div>
                <div className="col-sm-6">
                  <SelectField
                    label="Subtype"
                    options={
                      [
                        {
                          value: 'PCT',
                          label: 'Percentage'
                        },
                        {
                          value: 'FIXED',
                          label: 'Fixed'
                        }
                      ]
                    }
                    onChange={v => setValue('subType', v?.value, { shouldValidate: true })}
                    value={subType}
                    error={errors.subType}
                    helperText={errors.subType ? errors.subType?.message : null}
                  />
                </div>
              </div>
              {
                subType &&
                <div className="row">
                  <div className="col-sm-6">
                    <HookTextField
                      prepend={subType === 'FIXED' ? 'Rp' : null}
                      append={subType === 'PCT' ? '%' : null}
                      ref={register}
                      autoComplete="off"
                      label="Value"
                      name="value"
                      error={errors.value}
                      helperText={errors.value ? errors.value?.message : (subType === 'PCT' ? null : toIDR(value))}
                    />
                  </div>
                  {
                    subType !== "FIXED" &&
                    <div className="col-sm-6">
                      <HookTextField
                        prepend="Rp"
                        ref={register}
                        autoComplete="off"
                        label="Maximum Amount"
                        name={"maxAmount"}
                        error={errors.maxAmount}
                        helperText={errors.maxAmount ? errors.maxAmount?.message : toIDR(maxAmount)}
                        placeholder="e.g: 10000"
                      />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Period & Quota</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="date-range">
                <div>
                  <label>From</label>
                  <EvyDatePicker
                    value={periodFrom}
                    onChange={handleDateChange('periodFrom')}
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    disableToolbar
                  />
                </div>
                <div>
                  <label>To</label>
                  <EvyDatePicker
                    value={periodTo}
                    onChange={handleDateChange('periodTo')}
                    margin="dense"
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    disableToolbar
                    disablePast
                  />
                </div>
              </div>
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Maximum Quota"
                name="maxQuota"
                error={errors.maxQuota}
                helperText={errors.maxQuota ? errors.maxQuota?.message : null}
                placeholder="e.g: 100"
              />
              <SelectField
                label="Quota Applicable"
                options={[
                  {
                    value: 'ALL_USER',
                    label: 'All User'
                  },
                  {
                    value: 'PER_USER',
                    label: 'Per User'
                  },
                ]}
                onChange={v => setValue('quotaApplicable', v?.value, { shouldValidate: true })}
                value={quotaApplicable}
                error={errors.quotaApplicable}
                helperText={errors.quotaApplicable ? errors.quotaApplicable?.message : null}
              />
              {quotaApplicable === "PER_USER" ?
                <HookTextField
                  ref={register}
                  autoComplete="off"
                  label="Maximum Quota Applicable"
                  name="maxQuotaApplicable"
                  error={errors.maxQuotaApplicable}
                  helperText={errors.maxQuotaApplicable ? errors.maxQuotaApplicable?.message : null}
                  placeholder="e.g: 100"
                /> : null
              }
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Rules</h5>
            </div>
            <div className="col-12 col-md-9">
              <SelectField
                label="Type"
                options={[
                  {
                    value: 'ALL',
                    label: 'All'
                  },
                  {
                    value: 'COMPANY',
                    label: 'Company'
                  },
                  {
                    value: 'REFERRAL',
                    label: 'Referal'
                  }
                ]}
                onChange={e => {
                  if (e.value === "ALL") {
                    setValue("rules.subType", e.value, { shouldValidate: true })
                  } else {
                    setValue("rules.subType", "", { shouldValidate: true })
                  }

                  setValue("rules.type", e.value, { shouldValidate: true })
                }}
                value={rules?.type}
                error={errors.rules ? errors.rules.type : null}
                helperText={errors.rules && errors.rules.type ? errors.rules.type.message : null}
              />
              {rules?.type === 'COMPANY' ?
                <CompanySelect
                  label="Sub Type"
                  value={rules?.subType}
                  onChange={v => setValue('rules.subType', v?.value, { shouldValidate: true })}
                  error={errors.rules ? errors.rules.subType : null}
                  helperText={errors.rules && errors.rules.subType ? errors.rules.subType.message : null}
                />
                :
                rules?.type === 'REFERRAL' ?
                  <HookTextField
                    ref={register()}
                    autoComplete="off"
                    label="Sub Type"
                    name="rules.subType"
                    error={errors.rules ? errors.rules.subType : null}
                    helperText={errors.rules && errors.rules.subType ? errors.rules.subType.message : null}
                    placeholder="e.g: 10000"
                  />
                  : rules?.type === 'ALL' ?
                    <HookTextField
                      label="Sub Type"
                      disabled
                      value={"ALL"}
                    /> : null
              }
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Product</h5>
            </div>
            <div className="col-12 col-md-9">
              <SelectField
                label="Category"
                options={ProductCategoryTypeOpts}
                onChange={(e) => {
                  if (e.value === 'PARTNER') {
                    setValue('product.subCategory', e.value, { shouldValidate: true })
                  }
                  setValue('product.category', e.value, { shouldValidate: true })

                  getSubCategoryProduct(e.value)
                }}
                value={product?.category}
                error={errors.product?.category}
                helperText={errors.product && errors.product.category ? errors.product.category.message : null}
              />
              {product?.category === 'PPOB' ?
                <>
                  <SelectField
                    label="Sub Category"
                    options={PpobTypeOpts}
                    onChange={(v) => {
                      setValue('product.subCategory', v?.value, { shouldValidate: true })
                      setValue('product.type', "", { shouldValidate: true })
                    }}
                    value={product?.subCategory}
                    error={Boolean(errors?.product?.subCategory)}
                    helperText={errors?.product?.subCategory?.message}
                  />
                  <PPOBSelect
                    label="Type"
                    ppobType={product?.subCategory}
                    value={product?.type}
                    onChange={v => setValue('product.type', v?.value, { shouldValidate: true })}
                    error={errors.product?.type}
                    helperText={errors.product && errors.product.type ? errors.product.type.message : null}
                  />
                </>
                : product?.category === 'PARTNER' ?
                  <PartnerSelect
                    label="Type"
                    value={product?.type}
                    filterIntegrationType="COMPANY"
                    onChange={v => setValue('product.type', v?.value, { shouldValidate: true })}
                    error={errors.product?.type}
                    helperText={errors.product && errors.product.type ? errors.product.type.message : null}
                  /> :
                  <SelectField
                    label="Sub Category"
                    options={subCategoryOpts}
                    onChange={e => setValue('product.subCategory', e.value, { shouldValidate: true })}
                    value={product?.subCategory}
                    error={errors.product?.subCategory}
                    helperText={errors.product && errors.product.subCategory ? errors.product.subCategory.message : null}
                  />
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Form
