import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import roleHelper, { CLOSED_ACCOUNT_USER } from '../../../../utils/helpers/roleHelper';
import { userApi } from '../../../../services/userApi';

import { useHistory } from 'react-router-dom';
import AllStatus from '../../../../utils/constants/enums/status';
import DeclineDialog from '../../../../components/Dialog/DeclineDialog';
import { UserXMark } from '../../../../components/Icons/Icons';
import { combineBy } from '../../../../utils/helpers/combineBy';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';

const ClosedAccountCard = ({ admin, item, onSuccess }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const failedAttempts = item?.failedAttempts?.length ? [...item.failedAttempts] : [];
  const lastClosedAccountAttempt = failedAttempts.reverse().find(att => att.name === AllStatus.CLOSED_ACCOUNT);
  const openDialogRef = React.createRef();
  const { closed, closeAccountProcess, closedRequest, isNewUser } = item.status;
  const isProcessingStatus = closeAccountProcess === 1 && closedRequest.requestStatus === AllStatus.PROCESSING;
  const isDisable = closed || isProcessingStatus || isNewUser;

  const closedAccount = (id, holdReason) => {
    setIsLoading(true);
    dispatch({ type: null, call: userApi.closedAccount, args: [id, holdReason] })
      .then(() => {
        onSuccess();
        toast("Request Closed Account Success", { type: 'success' });
        history.push('/users/approval')
      })
      .catch(() => {
        toast("Closed Account failed", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    roleHelper.hasAccess(admin.role, CLOSED_ACCOUNT_USER) ?
      <div className="col-12 col-md-4 mbg-3">
        <ButtonCard
          isDisable={isDisable}
          onClick={() => isDisable ? null : openDialogRef.current.click()}
          closedAccount={closed}
          lastClosedAccountAttempt={lastClosedAccountAttempt}
          isLoading={isLoading}
        />
        <DeclineDialog
          className="d-none"
          fieldLabel="Reason"
          ref={openDialogRef}
          dataId={item._id}
          onSubmit={closedAccount}
          label="Closed Account"
          desc="Are you sure want to closed this user account? user will be deleted on database after closed account action"
        />
      </div>
      :
      null
  )
}

const ButtonCard = ({ onClick, isDisable, closedAccount, lastClosedAccountAttempt, isLoading }) => (
  <div className={`card closed-card ${isDisable ? 'disabled' : 'active'}`} onClick={onClick}>
    <div className="card-body">
      <div className="text-center">
        <div className={`xMark-icon-animated${closedAccount ? ' account-closed' : ''}`}>
          <UserXMark className="fa-6x" />
        </div>
        <p className="mb-2">
          <b>{closedAccount ? 'Closed Account' : 'Not Closed Account'}</b>
          {closedAccount &&
            <>
              <br />
              <b>
                By: {lastClosedAccountAttempt ? `${combineBy([lastClosedAccountAttempt.actor.firstName, lastClosedAccountAttempt.actor.lastName])} [${lastClosedAccountAttempt.actorModel}]` : '-'}
              </b>
            </>
          }
        </p>
        {closedAccount && lastClosedAccountAttempt &&
          <p className="mb-0 text-black-50">{lastClosedAccountAttempt.description || 'No remarks'}</p>
        }
      </div>
      <LoadingBlocker in={isLoading} />
    </div>
  </div>
)

export default ClosedAccountCard;
