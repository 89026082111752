import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useWindowSize from '../HooksUse/useWindowSize';
import styles from './virtualTable.module.scss';

function getPropByString(obj, propString) {
  if (!obj) return '';
  if (!propString) return obj;

  let prop, props = propString.split('.');
  let i = 0;
  let iLen = props.length - 1;

  for (i; i < iLen; i++) {
    prop = props[i];

    const candidate = obj[prop];
    if (candidate) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

const VirtualTable = memo(({
  data,
  columns,
  rowHeight = 50,
  tableHeight = 500,
  components: {
    RowList: RowListComponent
  } = {}
}) => {
  const dummyTableHeadRef = useRef()
  const tableScrollHeaderRef = useRef()
  const tableScrollBodyRef = useRef()
  const { width } = useWindowSize()

  const [scrollY, setScrollY] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(Math.ceil((tableHeight - rowHeight) * 2 / rowHeight))
  const [renderHeader, setRenderHeader] = useState(null)

  const displayTotal = useMemo(() => Math.ceil((tableHeight - rowHeight) / rowHeight), [tableHeight, rowHeight])

  // handle custom component
  const RowList = useMemo(() => (typeof RowListComponent === 'object' ? RowListComponent.component : RowListComponent) ?? RowListRaw, [RowListComponent])
  const rowListProps = useMemo(() => typeof RowListComponent === 'object' ? RowListComponent.props : null, [RowListComponent])


  const renderData = useMemo(() => {
    if (!Boolean(data?.length) || !Boolean(columns?.length)) return (
      <tr style={{ border: 'none' }}>
        <td className="text-center p-3" colSpan={columns.length}>No Data</td>
      </tr>
    )

    let result = []
    let end = to >= data.length ? data.length : to

    for (let i = from; i < end; i++) {
      result.push(
        <RowList
          key={`rowList-${i}`}
          rowData={data[i]}
          {...rowListProps}
        >
          {columns.map((column, index) =>
            <>
              <td
                key={index}
                style={{
                  height: rowHeight,
                  textAlign: column.align ?? 'left',
                }}
                // if column key === index then the index column will be sticky 
                className={column.key === "index" ? styles.indexNumber : null}
              >
                {column.key === "index" ?
                  (
                    i + 1
                  ) : (
                    <Render
                      col={column}
                      rowData={data[i]}
                    />
                  )}
              </td>
            </>
          )}
        </RowList>
      )
    }
    return result
  }, [data, columns, rowHeight, from, to, rowListProps])

  const handleScroll = useCallback((event) => {
    const { target: { scrollLeft, scrollTop } } = event
    // scroll horizontal
    if (scrollLeft !== scrollX) tableScrollHeaderRef.current.scrollTo(scrollLeft, 0) // imitate tableScrollBody scroll behavior to tableScrollHeader 

    // scroll vertical
    else if (scrollTop !== scrollY) {
      const index = Math.floor(scrollTop / rowHeight)
      setFrom(index - 2 * displayTotal < 0 ? index : index - displayTotal)
      setTo(index + 2 * displayTotal)
    }
    setScrollX(scrollLeft)
    setScrollY(scrollTop)
  }, [scrollX, scrollY, rowHeight, displayTotal])

  useEffect(() => {
    if (dummyTableHeadRef.current?.children) setRenderHeader(
      <tr style={{
        display: 'inline-block',
        width: dummyTableHeadRef.current?.offsetWidth,
        marginRight: tableScrollBodyRef.current.offsetWidth - tableScrollBodyRef.current.clientWidth // for scrollbar width
      }}>
        {
          columns.map((column, i) => (
            <th
              key={i}
              style={{
                height: rowHeight,
                width: dummyTableHeadRef.current?.children[i].clientWidth,
                minWidth: dummyTableHeadRef.current?.children[i].clientWidth,
                textAlign: column.align ?? 'left',
              }}
              className={column.key === "index" ? styles.indexNumber : null}
            >
              {column.title}
            </th>
          ))
        }
      </tr >
    )
  }, [dummyTableHeadRef.current?.children, columns, rowHeight, width, renderData])

  return (
    <div
      className={styles.tableWrapper}
      style={{
        height: tableHeight
      }}
    >
      <div
        className={styles.tableScrollHeader}
        style={{
          height: rowHeight
        }}
        ref={tableScrollHeaderRef}
      >
        <table>
          <thead>
            {renderHeader}
          </thead>
        </table>
      </div>
      <div
        ref={tableScrollBodyRef}
        className={styles.tableScrollBody}
        style={{
          height: tableHeight - rowHeight
        }}
        onScroll={handleScroll}
      >
        <div style={{ height: rowHeight * data.length }}>
          <table
            style={{
              top: from * rowHeight
            }}
          >
            <thead>
              <tr ref={dummyTableHeadRef}>
                {
                  columns.map((el, i) =>
                    <th key={i}>
                      {el.title}
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {renderData}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
})

const Render = ({ rowData, col } = {}) => {
  const keyValue = getPropByString(rowData, col.key) ?? undefined
  if (typeof col.render === "function") return col.render(keyValue, rowData)
  return keyValue ?? null
}

const RowListRaw = ({ children }) => {
  return (
    <tr >
      {children}
    </tr>
  )
}

export default VirtualTable
