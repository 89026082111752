import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const paymentRefundApi = {
  list: ({ page, limit, sort, startDate, endDate, status, biller, search, approvedBy }) => {
    let url = `/payment-refunds/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (biller) {
      url += `&biller=${biller}`;
    }
    if (approvedBy) {
      url += `&approvedBy=${approvedBy}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  create: (id, remark) => (
    agent.requests.post(`/payment-refunds/create`, { paymentId: id, remark })
  ),
  approve: (id) =>
    agent.requests.post(`/payment-refunds/${id}/approve`),
  decline: (id, value) =>
    agent.requests.post(`/payment-refunds/${id}/decline`, { rejectReason: value }),
  detail: (id) => {
    return agent.requests.get(`/payment-refunds/${id}/detail`)
  },
}
