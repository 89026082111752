import { yupResolver } from "@hookform/resolvers";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import activeStatusOpts from '../../../../utils/constants/enums/status/activeStatusOpts';
import { UserTypesOpts } from '../../../../utils/constants/enums/userTypes';
import { numberRegExp } from '../../../../utils/constants/regexTypes';
import { toIDR } from '../../../../utils/helpers/currency';
import HookTextField from '../../../../components/Forms/HookTextField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import SelectField from '../../../../components/Forms/SelectField';

const schema = yup.object().shape({
  // General Information
  type: yup.string().required(),
  subType: yup.string().required(),
  value: yup.string().matches(numberRegExp, 'Only Number Allowed').required()
    .when('subType', {
      is: subType => subType === 'PERCENTAGE',
      then: yup => yup.test(
        "range",
        "Input must be 0 - 100%",
        value => +value >= 0 && +value <= 100
      ),
      otherwise: yup => yup,
    }),
  maxAmount: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('subType', {
      is: subType => subType === "PERCENTAGE",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  minAmount: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('subType', {
      is: subType => subType === "PERCENTAGE",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  status: yup.string().required(),

  // Quota
  maxQuota: yup.string().matches(numberRegExp, 'Only Number Allowed').required(),
  applicableType: yup.string().required(),
  userType: yup.string().required(),
  maxQuotaApplicable: yup.string().matches(numberRegExp, 'Only Number Allowed')
    .when('applicableType', {
      is: applicableType => applicableType === "PER_USER",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
});

const DetailTab = ({ setTabs, onSubmit, isLoading, items }) => {
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...items,
      maxQuotaApplicable: items?.maxApplicableQuota
    }
  });

  const {
    type,
    subType,
    status,
    value,
    maxAmount,
    minAmount,
    applicableType,
    userType
  } = watch([
    'type',
    'subType',
    'status',
    'value',
    'maxAmount',
    'minAmount',
    'applicableType',
    'userType'
  ]);

  useEffect(() => {
    register('type');
    register('subType');
    register('status');
    register('applicableType');
    register('userType');

    return () => {
      unregister('type');
      unregister('subType');
      unregister('status');
      unregister('applicableType');
      unregister('userType');
    }
  }, [register, unregister])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <div className="row mb-5">
          <div className="col-12 col-md-3">
            <h5 className="card-title">MERCHANT INFORMATION</h5>
          </div>
          <div className="col-12 col-md-9">
            <div className="row">
              <div className="col-2 pr-1">
                <div className="d-flex justify-content-between">
                  <label className="text-grey mb-1">Merchant ID</label>
                  <span>:</span>
                </div>
              </div>
              <div className="col-10 pl-0">
                <p className="mb-0 font-weight-semibold">{items?.merchants?.merchant?.uniqueId}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-2 pr-1">
                <div className="d-flex justify-content-between">
                  <label className="text-grey mb-1">Owner</label>
                  <span>:</span>
                </div>
              </div>
              <div className="col-10 pl-0">
                <p className="mb-0 font-weight-semibold">{`${items?.merchants?.merchant?.firstName} ${items?.merchants?.merchant?.lastName}`}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-2 pr-1">
                <div className="d-flex justify-content-between">
                  <label className="text-grey mb-1">Merchant Name</label>
                  <span>:</span>
                </div>
              </div>
              <div className="col-10 pl-0">
                <p className="mb-0 font-weight-semibold">{items?.merchants?.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-2 pr-1">
                <div className="d-flex justify-content-between">
                  <label className="text-grey mb-1">Address</label>
                  <span>:</span>
                </div>
              </div>
              <div className="col-10 pl-0">
                <p className="mb-0 font-weight-semibold">{items?.merchants?.address}</p>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">GENERAL INFORMATION</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-sm-4">
                  <SelectField
                    label="Type"
                    options={[
                      {
                        value: 'CASHBACK',
                        label: 'Cashback'
                      }
                    ]}
                    onChange={v => setValue('type', v?.value, { shouldValidate: true })}
                    value={type}
                    error={errors.type}
                    helperText={errors.type ? errors.type?.message : null}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectField
                    label="Subtype"
                    options={
                      [
                        {
                          value: 'PERCENTAGE',
                          label: 'Percentage'
                        },
                        {
                          value: 'FIXED',
                          label: 'Fixed'
                        }
                      ]
                    }
                    onChange={v => setValue('subType', v?.value, { shouldValidate: true })}
                    value={subType}
                    error={errors.subType}
                    helperText={errors.subType ? errors.subType?.message : null}
                  />
                </div>
                {
                  subType &&
                  <>
                    <div className="col-sm-4">
                      <HookTextField
                        prepend={subType === 'FIXED' ? 'Rp' : null}
                        append={subType === 'PERCENTAGE' ? '%' : null}
                        ref={register}
                        autoComplete="off"
                        label="Value"
                        name="value"
                        error={errors.value}
                        helperText={errors.value ? errors.value?.message : (subType === 'PERCENTAGE' ? null : toIDR(value))}
                      />
                    </div>
                  </>
                }
                <div className="col-sm-4">
                  <HookTextField
                    prepend="Rp"
                    ref={register}
                    autoComplete="off"
                    label="Minimum Amount"
                    name={"minAmount"}
                    error={errors.minAmount}
                    helperText={errors.minAmount ? errors.minAmount?.message : toIDR(minAmount)}
                    placeholder="e.g: 10000"
                  />
                </div>
                <div className="col-sm-4">
                  <HookTextField
                    prepend="Rp"
                    ref={register}
                    autoComplete="off"
                    label="Maximum Amount haha"
                    name={"maxAmount"}
                    error={errors.maxAmount}
                    helperText={errors.maxAmount ? errors.maxAmount?.message : toIDR(maxAmount)}
                    placeholder="e.g: 10000"
                  />
                </div>
                <div className="col-sm-4">
                  <HookTextField
                    prepend="Rp"
                    ref={register}
                    autoComplete="off"
                    label="Minimum Amount"
                    name={"minAmount"}
                    error={errors.minAmount}
                    helperText={errors.minAmount ? errors.minAmount?.message : toIDR(minAmount)}
                    placeholder="e.g: 10000"
                  />
                </div>
                <div className="col-sm-4">
                  <SelectField
                    label="Status"
                    options={activeStatusOpts}
                    onChange={v => setValue('status', v?.value, { shouldValidate: true })}
                    value={status}
                    error={errors.status}
                    helperText={errors.status ? errors.status?.message : null}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Quota</h5>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-sm-4">
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Maximum Quota"
                    name="maxQuota"
                    error={errors.maxQuota}
                    helperText={errors.maxQuota ? errors.maxQuota?.message : null}
                    placeholder="e.g: 100"
                  />
                </div>
                <div className="col-sm-4">
                  <SelectField
                    label="User Type"
                    options={UserTypesOpts}
                    onChange={v => setValue('userType', v?.value, { shouldValidate: true })}
                    value={userType}
                    error={errors.userType}
                    helperText={errors.userType ? errors.userType?.message : null}
                  />
                </div>
                {applicableType === "PER_USER" ?
                  <div className="col-sm-4">
                    <HookTextField
                      ref={register}
                      autoComplete="off"
                      label="Maximum Quota Applicable"
                      name="maxQuotaApplicable"
                      error={errors.maxQuotaApplicable}
                      helperText={errors.maxQuotaApplicable ? errors.maxQuotaApplicable?.message : null}
                      placeholder="e.g: 100"
                    />
                  </div> : null
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn
                  type="button"
                  onClick={() => setTabs('')}
                  className="btn btn-outline-primary mr-3"
                >
                  Close
                </LoadingBtn>
                <LoadingBtn
                  type="submit"
                  loading={isLoading}
                  className="btn btn-primary"
                >
                  Update
                </LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DetailTab;
