import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingBtn from '../Forms/LoadingBtn';

const DeclineDialog = React.forwardRef((
  {
    dataId,
    isDisabled,
    className,
    style,
    onSubmit = function () { },
    label,
    labelAtTable,
    fieldLabel,
    desc,
    colorType,
    reasons = [],
    maxWidth,
    fullWidth = false,
    isLoading
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleAction = () => {
    onSubmit(dataId, fieldValue);
    setOpen(false);
  }

  const copyValue = v => e => {
    setFieldValue(v);
  }

  return (
    <Fragment>
      <LoadingBtn
        loading={isLoading}
        type="button"
        disabled={isDisabled || isLoading}
        className={className || ''}
        onClick={handleClickOpen}
        ref={ref}
        style={style}
      >
        {label || labelAtTable || 'Reject'}
      </LoadingBtn>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogTitle id="form-dialog-title">{label || 'Reject'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc || 'Are you sure want to reject this data? Please fill below remarks clearly'}</DialogContentText>
          <TextField
            autoFocus
            autoComplete="off"
            fullWidth
            multiline
            rowsMax="4"
            margin="dense"
            label={fieldLabel || 'Remarks'}
            id="declineDialogField"
            value={fieldValue}
            onChange={e => setFieldValue(e.target.value)}
          />
          {reasons.length > 0 &&
            <div className="mt-3">
              <label className="text-grey">Reason List - Click to Select</label>
              <div className="reasons-wrapper">
                {reasons.map((row, key) =>
                  <div className="reasons" key={key} onClick={copyValue(row.description)}>
                    <h6 className="card-title mb-2">{row.title}</h6>
                    <p className="mb-0">{row.description}</p>
                  </div>
                )}
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleAction} color={colorType || 'secondary'}>
            {label || 'Reject'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default DeclineDialog;
