import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { MAIN_TAB_LOAD, CONFIG_MODULE, UPDATE } from '../../../../utils/constants/actionTypes';
import { configApi } from '../../../../services/configApi';
import { mobilePPOBTypes } from '../../../../utils/constants/enums/mobilePPOBTypes';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    inProgress,
    items
  } = useSelector(state => state.config);

  const load = useCallback(
    () => {
      dispatch({
        module: CONFIG_MODULE,
        type: MAIN_TAB_LOAD,
        call: configApi.list,
        args: [{
          page: 0,
          limit: 200,
          sort: 'name|asc',
          search: `name|E_PPOB_`
        }]
      });
    },
    [dispatch],
  )

  const setToggle = useCallback((name, value) => {
    if (name) {
      const val = value ? "0" : "1";
      dispatch({
        module: CONFIG_MODULE,
        type: UPDATE,
        call: configApi.update,
        args: [{
          name,
          value: val
        }]
      })
        .catch(() => { })
    }
  }, [dispatch]);

  const setBadge = useCallback((name, value) => {
    dispatch({
      module: CONFIG_MODULE,
      type: UPDATE,
      call: configApi.update,
      args: [{
        name,
        value
      }]
    })
      .catch(() => { })
  }, [dispatch]);

  const modifiedData = useMemo(() => {
    return items.filter(v => mobilePPOBTypes.getStr(v.name) !== 'Unknown')
  }, [items])

  const table = useMemo(() => ({
    inProgress: inProgress,
    data: modifiedData,
    columns: [
      { title: 'Name', key: 'name', name: 'name', sortable: false, render: (v) => mobilePPOBTypes.getStr(v) },
      {
        title: 'Maintenance', key: 'value', name: 'name', sortable: false, render: (v, row) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                onChange={(e) => setToggle(row.name, e.target.checked)}
                defaultChecked={v === '0' ? true : false}
              />
            </div>
          )
        }
      },
      {
        title: 'Unavailability', key: 'value', name: 'value', sortable: false, render: (_, row) => {
          const availabilityData = items.filter(j => j.name.includes("AVAILABILITY")).find(p => p.name.includes(row.name));
          if (availabilityData && Object.keys(availabilityData).length)
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  onChange={(e) => setToggle(availabilityData.name, e.target.checked)}
                  defaultChecked={availabilityData?.value === '0' ? true : false}
                />
              </div>
            )
        }
      },
      {
        title: 'Hide', key: 'value', name: 'value', sortable: false, render: (_, row) => {
          const hideData = items.filter(j => j.name.includes("HIDE")).find(p => p.name.includes(row.name));
          if (hideData && Object.keys(hideData).length)
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  onChange={(e) => setToggle(hideData.name, !e.target.checked)}
                  defaultChecked={hideData?.value === '1' ? true : false}
                />
              </div>
            )
        }
      },
      {
        title: 'Badge', key: 'value', name: 'value', sortable: false, render: (_, row) => {
          const badgeData = items.filter(j => j.name.includes("BADGE")).find(p => p.name.includes(row.name.slice(7)));
          if (badgeData && Object.keys(badgeData).length)
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <select
                  onChange={(e) => setBadge(badgeData.name, e.target.value)}
                  defaultValue={badgeData.value}
                >
                  <option value="PROMO">Promo</option>
                  <option value="BARU">New</option>
                  <option value=" ">No Badge</option>
                </select>
              </div>
            )
        }
      }
    ]
  }), [inProgress, modifiedData, items, setToggle, setBadge])

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table}
          onReload={load}
        />
      </div>
    </div>
  )
}

export default MainTab
