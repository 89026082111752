import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { combineBy } from '../../utils/helpers/combineBy';
import Collapse from '@material-ui/core/Collapse';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toIDR } from '../../utils/helpers/currency';
import SelectField from '../Forms/SelectField';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useMountedState from '../HooksUse/useMountedState';
import { userApi } from '../../services/userApi';

const staticValues = {
  sort: 'firstName|asc',
  limit: 10,
}

const UserSelect = ({
  label,
  disabled,
  error,
  helperText,
  onChange,
  value,
  withDetail,
  customOptions = [],
  ...rest
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [options, setOptions] = useState([])
  const [selectProps] = useState({ ...rest?.selectProps })
  const [customOpts,] = useState(customOptions)
  const [search, setSearch] = useState('')
  const [totalItems, setTotalItems] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const { sort, limit } = staticValues;
  const isMounted = useMountedState();
  const [initialValue] = useState(value)

  const getUserListDebounced = useMemo(
    () => AwesomeDebouncePromise(userApi.list, 500),
    [],
  )

  const dispatchLoad = useCallback(
    (call, page, limit, sort, search, searchByID, selectProps) => {
      const { search: searchBy } = selectProps;
      return dispatch({
        type: null,
        call: call,
        args: [
          {
            page,
            limit,
            sort: search ? null : sort,
            search: searchByID ? `_id|${searchByID}` : (searchBy && search) ? searchBy(search) : search ? `fullName|${search},uniqueId|${search}` : null
          }
        ]
      })
    },
    [dispatch],
  )

  const generateOpts = (users, labelBy) => users.map(user => ({
    value: user._id,
    label: labelBy ? labelBy(user) : `${combineBy([user.firstName, user.lastName])} | ${user.uniqueId}`,
    upgraded: user.status.upgraded,
    balance: user.balance,
    model: 'User',
    phoneNumber: user.phoneNumber,
    optData: user
  }))

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);
      dispatchLoad(userApi.list, newPage, limit, sort, search, null, selectProps)
        .then(({ data: { users, count } }) => {
          if (isMounted()) {
            const { label: labelBy } = selectProps;
            setOptions([...options, ...generateOpts(users, labelBy)])
            setTotalItems(count)
          }
        })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) });
    }
  }

  const handleSelectChange = opt => {
    setSelectedOption(opt)
    setSearch('')

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }


  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(getUserListDebounced, newPage, limit, sort, search, null, selectProps)
        .then(({ data: { users, count } }) => {
          if (isMounted()) {
            const { label: labelBy } = selectProps;
            options = [...customOpts, ...generateOpts(users, labelBy)]
            setTotalItems(count)
            const initial = typeof initialValue === 'object' ? initialValue.value : initialValue
            let selectionExist = Boolean(users.find(v => v._id === initial))
            if (initial && !search && !selectionExist) return dispatchLoad(getUserListDebounced, 0, 0, null, null, initial, null, null)
          }
        })
        .then(({ data: { users } = {} } = {}) => {
          if (isMounted() && users) options = [...generateOpts(users), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setOptions(options)
            setIsLoading(false)
          }
        });
    }
    loadData()
  }, [search, sort, limit, dispatchLoad, getUserListDebounced, customOpts, isMounted, initialValue, selectProps])

  return (
    <SelectField
      id="selectUser"
      label={label ?? "Select User"}
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder="Type anything.."
      componentProps={{
        inputValue: search,
        onInputChange: setSearch,
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom
      }}
      isDisabled={disabled}
      error={error}
      helperText={helperText}
      formFieldAppend={withDetail && <DataDetail data={typeof value !== 'undefined' ? value : selectedOption} />}
    />
  )
}

const DataDetail = ({ data }) => {
  return (
    <Collapse in={data ? true : false}>
      {data ?
        <div className="alert alert-primary mb-0">
          <h6>
            <b>{data.label}</b>
            {data.upgraded &&
              <span title="Verified User"><FontAwesomeIcon icon={faCheckCircle} className="text-success ml-2" /></span>
            }
          </h6>
          <hr />
          <div className="row">
            <div className="col-5 col-md-4 col-lg-3">
              <div className="d-flex justify-content-between">
                <label>Upgraded</label>
                <span>:</span>
              </div>
            </div>
            <div className="col pl-0">
              <b>{data.upgraded ? 'Upgraded' : 'Not Upgraded'}</b>
            </div>
          </div>
          <div className="row">
            <div className="col-5 col-md-4 col-lg-3">
              <div className="d-flex justify-content-between">
                <label>Primary balance</label>
                <span>:</span>
              </div>
            </div>
            <div className="col pl-0">
              <b>{toIDR(data?.balance?.primary)}</b>
            </div>
          </div>
          <div className="row">
            <div className="col-5 col-md-4 col-lg-3">
              <div className="d-flex justify-content-between">
                <label>Point balance</label>
                <span>:</span>
              </div>
            </div>
            <div className="col pl-0">
              <b>{toIDR(data?.balance?.point, false)}</b>
            </div>
          </div>
        </div>
        : null
      }
    </Collapse>
  )
}

export default UserSelect
