import { useState, useEffect } from "react";

export default function useIdleState(expiredTime) {
  const [idleTime, setIdleTime] = useState(0)
  const [isExpired, setIsExpired] = useState(false)

  // listen cursor move event
  useEffect(() => {
    // if cursor move reset idleTime
    const listenCursorMove = () => setIdleTime(0)
    if (!isExpired) window.addEventListener("mousemove", listenCursorMove)
    else window.removeEventListener("mousemove", listenCursorMove)
    return () => {
      if (!isExpired) window.removeEventListener("mousemove", listenCursorMove)
    }
  }, [isExpired])

  // count idle time
  useEffect(() => {
    const countIdle = setInterval(() => {
      if (idleTime < expiredTime) setIdleTime(prev => prev + 1)
      else {
        clearInterval(countIdle)
        setIsExpired(true)
      }
    }, 1000);
    return () => {
      clearInterval(countIdle)
    }
  }, [idleTime, expiredTime])

  return {
    idleTime,
    isExpired
  }
}
