import AllStatus from ".";

const VoucherStatusOpts = [
  { value: AllStatus.USABLE, label: AllStatus.getStr(AllStatus.USABLE) },
  { value: AllStatus.USED, label: AllStatus.getStr(AllStatus.USED) },
  { value: AllStatus.EXPIRED, label: AllStatus.getStr(AllStatus.EXPIRED) }
];

export default VoucherStatusOpts

