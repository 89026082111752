import React from 'react';
import { Tabs, Tab } from '../../components/Tabs/Tabs';
import { CHANGE_TAB, CLOSE_TAB } from '../../utils/constants/actionTypes';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import ResourceList from './ResourceList'
import objectPath from 'object-path'

const Resource = ({
  title,
  subTitle,
  icon,
  customComponent,
  additionalTab,
  isLoading,
  list,
  create,
  detail,
  update,
  role
}) => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => objectPath.get(state, list?.reducerPath));

  const Main = list?.component ?? customComponent ?? ResourceList;
  const Create = create?.component;
  const Detail = detail?.component;
  const Update = update?.component;
  const AdditionalComponent = additionalTab?.component;

  const handleTab = type => key => dispatch({ module: list?.module, type: type, key: key });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="app-main__inner">
        <TitleDiv title={title} subTitle={subTitle} icon={icon} />
        {
          list ?
            <Tabs
              activeKey={activeTabKey}
              onClick={handleTab(CHANGE_TAB)}
            >
              <Tab title="Main">
                <Main
                  excel={list?.excel}
                  isLoading={isLoading}
                  list={list}
                  detail={{
                    isExist: Boolean(detail),
                    additionalKey: detail?.additionalKey
                  }}
                  update={{
                    isExist: Boolean(update),
                    additionalKey: update?.additionalKey
                  }}
                />
              </Tab>
              {typeof role !== 'undefined' ?
                typeof role === 'boolean' &&
                role &&
                create &&
                <Tab title="Create">
                  <Create {...create.props} />
                </Tab>
                :
                create &&
                <Tab title="Create">
                  <Create {...create.props} />
                </Tab>
              }
              {
                Boolean(detail) || Boolean(update) ?
                  tabs.map((row, key) => {
                    return (
                      <Tab
                        key={row.key}
                        title={row.title}
                        isLoading={row.loading}
                        onClose={() => handleTab(CLOSE_TAB)(row.key)}
                        onClick={() => handleTab(CHANGE_TAB)(row.key)}
                        active={activeTabKey === row.key}
                      >
                        {
                          Boolean(detail || row?.title?.split(" ")[0] === "Detail") ?
                            <Detail {...detail?.props} index={key} /> :
                            null
                        }
                        {
                          Boolean(update || row?.title?.split(" ")[0] === "Update") ?
                            <Update {...update?.props} index={key} /> :
                            null
                        }
                      </Tab>
                    )
                  }) : null
              }
              {typeof role !== 'undefined' ?
                typeof role === 'boolean' &&
                role &&
                AdditionalComponent &&
                <Tab title={additionalTab?.title}>
                  <AdditionalComponent {...additionalTab?.props} />
                </Tab>
                :
                AdditionalComponent &&
                <Tab title={additionalTab?.title}>
                  <AdditionalComponent {...additionalTab?.props} />
                </Tab>
              }
            </Tabs> :

            <Main
              isLoading={isLoading}
              list={list}
            />
        }
      </div>
    </>
  );
}

const TitleDiv = ({ title, subTitle, icon }) => (
  <div className="app-page-title">
    <div className="page-title-wrapper">
      <div className="page-title-heading">
        <div className="page-title-icon">
          <i className={`${icon} icon-gradient bg-malibu-beach`}></i>
        </div>
        <div>
          {title}
          <div className="page-title-subheading">{subTitle}</div>
        </div>
      </div>
    </div>
  </div>
)

export default Resource;
