import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_QRIS_RINTIS_RECON,
  ROUTE_QRIS_RINTIS_NON_RECON,
  ROUTE_QRIS_RINTIS_REKAP,
  ROUTE_REPORTS_302,
  ROUTE_REPORTS_DUKCAPIL,
  ROUTE_REPORTS_G0003,
  ROUTE_REPORTS_GENERAL,
  ROUTE_REPORTS_BI_MERCHANT,
  ROUTE_REPORTS_QRIS_TO_BI,
  ROUTE_QRIS_ARTAJASA_RECON,
  ROUTE_QRIS_ARTAJASA_NON_RECON
} from "../utils/helpers/roleHelper";

import Report from '../pages/Report/General'
import Report302 from '../pages/Report/GenerateData/Report302'
import ReportG0003 from '../pages/Report/GenerateData/ReportG0003'
import KYCVerifyHistories from '../pages/Report/VerifyHistories'
import QRIS from "../pages/QRIS/NonRekon";
import Recon from "../pages/QRIS/Recon";
import QrisRintisRecon from "../pages/QrisRintis/Recon";
import QrisRintisNonRecon from "../pages/QrisRintis/NonRecon";
import QrisRintisRekap from "../pages/QrisRintis/Rekap";
import ReportBIMerchant from "../pages/Report/GenerateData/ReportBI/Merchant";
import ReportQRIS from "../pages/Report/GenerateData/ReportQRIS";
// import Transaction from "../pages/Report/GenerateData/ReportBI/Transaction";

export const reports = {
  type: NAVMENU,
  name: 'Reports',
  icon: 'pe-7s-diskette',
  children: [
    {
      type: NAVLINK,
      name: 'General',
      url: '/reports/general',
      routeTitle: ROUTE_REPORTS_GENERAL,
      component: Report
    },
    {
      type: NAVLINK,
      name: 'eKYC',
      url: '/reports/dukcapil',
      routeTitle: ROUTE_REPORTS_DUKCAPIL,
      component: KYCVerifyHistories
    },
    {
      type: NAVMENU,
      name: "Bank Indonesia",
      icon: "pe-7s-phone",
      children: [
        {
          type: NAVLINK,
          name: 'Report 302',
          url: '/reports/report302',
          routeTitle: ROUTE_REPORTS_302,
          component: Report302
        },
        {
          type: NAVLINK,
          name: 'Report G0003',
          url: '/reports/reportG0003',
          routeTitle: ROUTE_REPORTS_G0003,
          component: ReportG0003
        },
        {
          type: NAVLINK,
          name: 'Total User QRIS',
          url: '/reports/reportQRIS',
          routeTitle: ROUTE_REPORTS_QRIS_TO_BI,
          component: ReportQRIS
        },
        {
          type: NAVLINK,
          name: 'Report BI Merchant',
          url: '/report-bi/merchant',
          routeTitle: ROUTE_REPORTS_BI_MERCHANT,
          component: ReportBIMerchant
        },
        // {
        //   type: NAVLINK,
        //   name: 'Report BI Transaction',
        //   url: '/report-bi/transaction',
        //   routeTitle: ROUTE_REPORTS_BI_TRANSACTION,
        //   component: Transaction
        // },
      ]
    },
    {
      type: NAVMENU,
      name: "QRIS Artajasa",
      icon: "pe-7s-phone",
      children: [
        {
          type: NAVLINK,
          name: 'Non-Recon',
          url: '/qris-artajasa/general',
          routeTitle: ROUTE_QRIS_ARTAJASA_NON_RECON,
          component: QRIS
        },
        {
          type: NAVLINK,
          name: 'Recon',
          url: '/qris-artajasa/recon',
          routeTitle: ROUTE_QRIS_ARTAJASA_RECON,
          component: Recon
        },
      ]
    },
    {
      type: NAVMENU,
      name: "QRIS Rintis",
      icon: "pe-7s-note2",
      children: [
        {
          type: NAVLINK,
          name: 'Non-Recon',
          url: '/qris-rintis/non-recon',
          routeTitle: ROUTE_QRIS_RINTIS_NON_RECON,
          component: QrisRintisNonRecon
        },
        {
          type: NAVLINK,
          name: 'Recon',
          url: '/qris-rintis/recon',
          routeTitle: ROUTE_QRIS_RINTIS_RECON,
          component: QrisRintisRecon
        },
        {
          type: NAVLINK,
          name: 'Rekap',
          url: '/qris-rintis/rekap',
          routeTitle: ROUTE_QRIS_RINTIS_REKAP,
          component: QrisRintisRekap
        },
      ]
    }
  ]
}
