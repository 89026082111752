import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusHistory from '../../../components/StatusHistory';
import { combineBy } from '../../../utils/helpers/combineBy';
import { format } from 'date-fns'
import { toIDR } from '../../../utils/helpers/currency';
import CollapseCard from '../../../components/CollapseCard';
import { TOP_UP_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import AllStatus from '../../../utils/constants/enums/status';
import { topUpApi } from '../../../services/topUpApi';
// import RoundBadge from '../../../components/Badge/RoundBadge';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from '@material-ui/icons/Check';
import HookTextField from '../../../components/Forms/HookTextField';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs } = useSelector(state => state.topUp);
  const { key, loading } = tabs[index];
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [notes, setNotes] = useState("");
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const {
    execute,
    value: { data: { topup: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: TOP_UP_MODULE,
      type: GET_DETAIL,
      call: topUpApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const isBCA = item?.virtualAccount?.CompanyCode ? true : false;

  const getClass = status => {
    let className = 'badge-primary';
    if (status === AllStatus.PENDING) {
      className = 'badge-warning'
    } else if (status === AllStatus.COMPLETED) {
      className = 'badge-success'
    } else if (status === AllStatus.FAILED) {
      className = 'badge-danger'
    }
    return className
  }

  const updateStatus = () => {
    setIsUpdatingStatus(true)
    topUpApi.updateLimit(key, { notes, status: "FAILED" })
      .then(() => {
        execute()
        setShowUpdateStatus(false)
      })
      .catch(() => { })
      .finally(() => setIsUpdatingStatus(false))
  }

  return loading && !item ?
    null
    :
    item ?
      <>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">Applicant Info</h5>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Name</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{combineBy([item.applicant.firstName, item.applicant.lastName])}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Phone Number</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{item.applicant.phoneNumber}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Email</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{item.applicant.email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Desc</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{item.description}</p>
                  </div>
                </div>
                {item?.virtualAccount && (
                  <div className="row">
                    <div className="col-4 pr-1">
                      <div className="d-flex justify-content-between">
                        <label className="text-grey mb-1">VA Number</label>
                        <span>:</span>
                      </div>
                    </div>
                    {item?.virtualAccount ?
                      <div className="col-8 pl-0">
                        <p className="mb-0 font-weight-semibold">
                          {isBCA
                            ? `${item.virtualAccount?.CompanyCode}${item.virtualAccount?.CustomerNumber}`
                            : `${item.virtualAccount?.prefix}${item.virtualAccount?.customerId}`
                          }
                        </p>
                      </div> : '-'}
                  </div>
                )}
              </div>
              <hr className="my-0" />
              <div className="card-body ">
                <h5 className="card-title mb-3">Top up Info</h5>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Date</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                  </div>
                </div>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Transaction Code</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.code}</p>
                  </div>
                </div>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Current Status</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className={`badge ${getClass(item.status)}`}>{item.status === AllStatus.FAILED ? 'Declined' : item.status}</span>
                  </div>
                </div>
                {item.status === AllStatus.FAILED
                  ?
                  <div className="row mb-2 align-items-end">
                    <div className="col-4">
                      <label className="text-grey mb-0">Remarks</label>
                    </div>
                    <div className="col-8 text-right">
                      <p className="mb-0 font-weight-semibold">{item.remarks}</p>
                    </div>
                  </div>
                  :
                  ''
                }
                <div className="row align-items-end mt-3">
                  <div className="col-4">
                    <label className="text-grey mb-0">Amount</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className="text-grey pr-1">Rp</span>
                    <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(item.amount, false)}</span>
                  </div>
                </div>
              </div>
              {item?.limitExceeded?.primary || item?.limitExceeded?.cummulative ?
                <div className="card-body">
                  <h5 className="card-title mb-3">Limit Exceeded Info</h5>
                  <div className="row mb-2">
                    {item?.limitExceeded?.primary ?
                      <div className="col-4 d-flex align-items-center mb-2">
                        <div className="mr-2">
                          {/* <RoundBadge
                      title="Primary"
                      active={item?.limitExceeded?.primary}
                      variant={item?.limitExceeded?.primary ? "primary" : "muted"}
                      icon={CheckIcon}
                    /> */}
                          {item?.limitExceeded?.primary ? <CheckIcon /> : null}
                        </div>
                        <p className="mb-0 text-grey">Primary</p>
                      </div>
                      : null
                    }
                    {item?.limitExceeded?.cummulative ?
                      <div className="col-4 d-flex align-items-center">
                        <div className="mr-2">
                          {/* <RoundBadge
                      title="Cummulative"
                      active={item?.limitExceeded?.cummulative}
                      variant={item?.limitExceeded?.cummulative ? "primary" : "muted"}
                      icon={CheckIcon}
                    /> */}
                          {item?.limitExceeded?.cummulative ? <CheckIcon /> : null}
                        </div>
                        <p className="mb-0 text-grey">Cummulative</p>
                      </div>
                      : null
                    }
                  </div>
                  {
                    item?.status !== "FAILED" ?
                      <div>
                        <button
                          className="btn btn-primary w-100"
                          onClick={() => setShowUpdateStatus(true)}
                        >
                          Update Status
                        </button>
                      </div>
                      :
                      <div className="row mb-2 align-items-end">
                        <div className="col-12">
                          <label className="text-grey mb-0">Note :</label>
                        </div>
                        <div className="col-12">
                          <p className="mb-0 font-weight-semibold">{item?.notes}</p>
                        </div>
                      </div>
                  }
                </div>
                : null
              }
            </div>
            {!isBCA && item.virtualAccount &&
              <CollapseCard title="NICEPAY DATA">
                <div className="row mb-2">
                  <div className="col-4">
                    <label className="text-grey mb-0">TX ID</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.virtualAccount?.tXid}</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label className="text-grey mb-0">Bank Code</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.virtualAccount?.bankCd}</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label className="text-grey mb-0">Customer ID</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.virtualAccount?.customerId}</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label className="text-grey mb-0">Prefix</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.virtualAccount?.prefix}</p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4">
                    <label className="text-grey mb-0">Reference No</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.virtualAccount?.referenceNo}</p>
                  </div>
                </div>
                <div className="row align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Amount</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className="text-grey pr-1">Rp</span>
                    <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(item.virtualAccount?.amt, false)}</span>
                  </div>
                </div>
                {item.status === AllStatus.REFUNDED && item.virtualAccount?.reversal &&
                  <div className="mt-2">
                    <div className="row align-items-end">
                      <div className="col-4">
                        <label className="text-grey mb-0">Refunded amount</label>
                      </div>
                      <div className="col-8 text-right">
                        <span className="text-grey pr-1">Rp</span>
                        <span className="h5 mb-0 font-weight-bold text-danger">{toIDR(item.virtualAccount?.reversal.amt, false)}</span>
                      </div>
                    </div>
                  </div>
                }
              </CollapseCard>
            }
          </div>
          <div className="col-12 col-lg-6">
            <StatusHistory statusHistories={item.statusHistories} />
          </div>
        </div>
        <Dialog
          open={showUpdateStatus}
          onClose={() => setShowUpdateStatus(false)}
          aria-labelledby="form-dialog-title"
          fullWidth={500}
        >
          <DialogContent>
            {/* <h5 className='font-weight-semibold'>Update Status</h5> */}
            {/* <p className='mb-4'>bla bla bla</p> */}
            <div>
              <span className='mb-0 text-grey'>
                Note {' '}
                <span className="text-danger font-weight-bold">*</span>
              </span>
            </div>
            <HookTextField
              value={notes}
              onChange={e => setNotes(e.target.value)}
              rows={5}
              className='border-bottom w-100'
              multiline
              placeholder="contoh: sudah ditransfer kembali"
            />
          </DialogContent>
          <DialogActions className="bg-light py-3">
            <button onClick={() => setShowUpdateStatus(false)} className='btn btn-icon btn-icon-only btn-sm px-4 bg-white text-secondary'>
              Cancel
            </button>
            <button onClick={updateStatus} disabled={isUpdatingStatus || !notes} className='btn btn-icon btn-icon-only px-4 btn-primary'>
              Update
            </button>
          </DialogActions>
        </Dialog>
      </>
      :
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
