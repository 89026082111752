class rejectReasonTypes {
  static KYC = "KYC";
  static CLOSE_ACCOUNT = "CLOSE_ACCOUNT";

  static getStr(en) {
    switch (en) {
      case rejectReasonTypes.KYC:
        return "KYC";
      case rejectReasonTypes.CLOSE_ACCOUNT:
        return "Close Account";
      default:
        return "Unknown";
    }
  }
}

const rejectReasonOpts = [
  { value: rejectReasonTypes.KYC, label: rejectReasonTypes.getStr(rejectReasonTypes.KYC) },
  { value: rejectReasonTypes.CLOSE_ACCOUNT, label: rejectReasonTypes.getStr(rejectReasonTypes.CLOSE_ACCOUNT) },
];

export { rejectReasonTypes, rejectReasonOpts }
