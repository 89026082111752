import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSED_USER_ACCOUNT_MODULE, APPROVE_OR_REJECT } from '../../../utils/constants/actionTypes'
import { combineBy } from '../../../utils/helpers/combineBy';
import { format } from 'date-fns';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import EvyTextField from '../../../components/Forms/EvyTextField';
import StatusHistory from '../../../components/StatusHistory';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import roleHelper, { FORM_DETAIL_APPROVAL } from '../../../utils/helpers/roleHelper';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import AllStatus from '../../../utils/constants/enums/status';
import { closedUserApi } from '../../../services/closedUserApi';
import { AdminRole } from '../../../utils/constants/enums/adminRoles';
import useAsync from '../../../components/HooksUse/useAsync';
import { rejectReasonApi } from '../../../services/rejectReasonApi';
import { rejectReasonTypes } from '../../../utils/constants/enums/rejectReasonTypes';
import useMountedState from '../../../components/HooksUse/useMountedState';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [rejecting, setRejecting] = useState(false);

  const {
    users: {
      closeAccount: {
        isUpdating,
        tabs,
        items
      }
    },
    common: {
      currentAdmin: admin
    },
  } = useSelector(state => state)

  const { key } = tabs[index];
  const selectedItem = items.find(row => row._id === key);

  const history = selectedItem ? selectedItem.statusHistories.find(history => history.status === AllStatus.PROCESSING) : null;
  const actor = history ? history.actor : null;

  const onApprove = () => {
    dispatch({ module: CLOSED_USER_ACCOUNT_MODULE, type: APPROVE_OR_REJECT, call: closedUserApi.approve, args: [selectedItem._id], tabKey: index })
      .catch(() => { })
  }

  const onReject = (id, remarks) => {
    setRejecting(true);
    dispatch({ module: CLOSED_USER_ACCOUNT_MODULE, type: APPROVE_OR_REJECT, call: closedUserApi.decline, args: [id, remarks], tabKey: index })
      .catch(() => { })
      .finally(() => { if (isMounted()) setRejecting(false) });
  }

  const getClass = (status) => {
    let className = 'badge-primary';
    if (status === AllStatus.PROCESSING) {
      className = 'badge-warning'
    } else if (status === AllStatus.APPROVED) {
      className = 'badge-success'
    } else if (status === AllStatus.DECLINED) {
      className = 'badge-danger'
    }
    return className
  }

  const {
    value: { data: { reasons = [] } = {} } = {},
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: rejectReasonApi.list,
      args: [{
        page: 0,
        limit: 0,
        category: rejectReasonTypes.CLOSE_ACCOUNT
      }]
    }),
    [dispatch]), true)

  return (
    selectedItem
      ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Requester | Admin</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Full Name"
                  value={combineBy([actor.firstName, actor.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={actor.email}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Role"
                  value={AdminRole.getStr(actor.role)}
                />
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Target | User</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Full Name"
                  value={combineBy([selectedItem.user.firstName, selectedItem.user.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={selectedItem.user.uniqueId}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={selectedItem.user.phoneNumber}
                  helperText={`Verified at: ${selectedItem.user.status.phoneNumberVerifiedAt ? format(new Date(selectedItem.user.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={selectedItem.user.email}
                  helperText={`Verified at: ${selectedItem.user.status.emailVerifiedAt ? format(new Date(selectedItem.user.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title mb-3">Request data</h5>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="text-grey mb-1">Date</label>
                    <p className="mb-0 font-weight-semibold">{format(new Date(selectedItem.createdAt), 'dd MMMM yyyy')}</p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="text-grey mb-1">Reason</label>
                <p className="mb-0 font-weight-semibold">{selectedItem.reason}</p>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Current Status</label>
                </div>
                <div className="col-8 text-right">
                  <span className={`badge ${getClass(selectedItem.status)}`}>{selectedItem.status}</span>
                </div>
              </div>
              {selectedItem.remarks &&
                <div className="form-group mb-0 mt-3">
                  <label className="text-grey mb-1">Decline Remarks</label>
                  <p className="mb-0 font-weight-semibold">{selectedItem.remarks}</p>
                </div>
              }
              {roleHelper.hasAccess(admin.role, FORM_DETAIL_APPROVAL) && selectedItem.status === AllStatus.PROCESSING &&
                <>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <DeclineDialog
                      isLoading={rejecting}
                      className="btn btn-outline-danger mr-2"
                      dataId={selectedItem._id}
                      reasons={reasons}
                      onSubmit={onReject}
                    />
                    <LoadingBtn type="button" onClick={onApprove} className="btn btn-primary">Approve</LoadingBtn>
                  </div>
                </>
              }
              <LoadingBlocker in={isUpdating} />
            </div>
          </div>
          <StatusHistory statusHistories={selectedItem.statusHistories} warningType={AllStatus.PROCESSING} successType={AllStatus.APPROVED} dangerType={AllStatus.DECLINED} />
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
        </div>
      </div>
  )
}

export default DetailTab;
