import { CheckCircle, LocalOffer } from '@material-ui/icons';
import React, { useMemo } from 'react';
import RoundBadge from '../../../../components/Badge/RoundBadge';

const BillerStatuses = ({ biller }) => {
  const getRecommended = useMemo(() => (
    <RoundBadge
      title={biller?.active ? 'Active' : 'Not active'}
      active={biller?.active}
      variant="primary"
      icon={CheckCircle}
    />
  ), [biller])

  const getPromo = useMemo(() => (
    <RoundBadge
      title={biller?.isDisc ? 'Promo is active' : 'Promo is inactive'}
      active={biller?.isDisc}
      variant="success"
      icon={LocalOffer}
    />
  ), [biller])

  return (
    <div className="d-flex align-items-center">
      {getRecommended}
      {getPromo}
    </div>
  )
}

export default BillerStatuses
