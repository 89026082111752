import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const transferEvyApi = {
  list: ({ page, limit, sort, startDate, endDate, search, senderId, recipientId, statusRecipient, partner }) => {
    let url = `/transactions/transfer-list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (search) {
      url += `&search=${search}`
    }
    if (senderId) {
      url += `&senderId=${senderId}`
    }
    if (recipientId) {
      url += `&recipientId=${recipientId}`
    }
    if (statusRecipient) {
      url += `&statusRecipient=${statusRecipient}`
    }
    if (partner) {
      url += `&connectedPartner=${partner}`
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/transactions/transfer-list/${id}/detail`),
}
