import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AUTO_DEBIT_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import EvyTextField from '../../../components/Forms/EvyTextField';
import { combineBy } from '../../../utils/helpers/combineBy';
import { format } from 'date-fns'
import { toIDR } from '../../../utils/helpers/currency';
import UserStatuses from '../../../components/UserStatuses';
import FormField from '../../../components/Forms/FormField';
import { PpobType } from '../../../utils/constants/enums/ppobTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import { autoDebitApi } from '../../../services/autoDebitApi';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.autoDebit.tabs[index]);

  const {
    value: { data: { autoPayment: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: AUTO_DEBIT_MODULE,
      type: GET_DETAIL,
      call: autoDebitApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  return loading && !item ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Owner</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Name"
                  value={combineBy([item.owner.firstName, item.owner.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.owner?.phoneNumber ? item.owner.phoneNumber : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.owner?.email ? item.owner.email : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.owner?.uniqueId ? item.owner.uniqueId : '-'}
                />
                <FormField
                  label="Statuses"
                  row
                  className="align-items-center"
                >
                  <UserStatuses user={item.owner} />
                </FormField>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">PPOB Details</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Billing Date"
                  value={item.date ? format(new Date(item.date), 'yyyy-MM-dd HH:mm:ss') : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Code"
                  value={item.ppobId?.code ? item.ppobId.code : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Title"
                  value={item.ppobId?.title ? item.ppobId.title : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Type"
                  value={item.ppobId?.type ? PpobType.getStrIdn(item.ppobId.type) : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Price"
                  value={item.ppobId?.price ? toIDR(item.ppobId.price) : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Revenue"
                  value={item.ppobId?.revenue ? toIDR(item.ppobId.revenue) : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Referral Bonus"
                  value={item.ppobId?.referralBonus ? toIDR(item.ppobId.referralBonus) : '-'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab
