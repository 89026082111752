import { combineReducers } from 'redux';
import merchantApp from './merchantApp';
import userApp from './userApp';
import blog from './blog';

export default combineReducers({
  merchantApp,
  userApp,
  blog
});
