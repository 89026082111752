import React from 'react'
import { BISNIS_ANNOUNCEMENT_APP_MODULE } from '../../../utils/constants/actionTypes'
import AnnouncementList from '../AnnouncementList'

const Index = () => {
  return (
    <AnnouncementList
      reducerPath="announcement.merchantApp"
      module={BISNIS_ANNOUNCEMENT_APP_MODULE}
      title="Dipay Bisnis App"
      subTitle="Full Control of Dipay Bisnis App Announcement includes Create, Delete & Updates."
    />
  )
}

export default Index
