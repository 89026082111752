import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import { ROUTE_ARTICLES_USER } from "../utils/helpers/roleHelper";
import ArticleList from "../pages/ArticleNew";

export const articles = {
  type: NAVMENU,
  name: 'Articles',
  icon: 'pe-7s-news-paper',
  children: [
    {
      type: NAVLINK,
      name: 'Dipay Article',
      icon: 'pe-7s-news-paper',
      url: '/articles/user-apps',
      routeTitle: ROUTE_ARTICLES_USER,
      component: ArticleList
    }
  ]
}
