import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const notificationApi = {
  list: ({ page, limit, sort, filter: { model, type, search } }) => {
    let url = `/notifications/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (model) {
      url += `&filter=${model}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  create: data => {
    let url = "/notifications/create/custom";
    return agent.requests.post(url, data, true)
  }
}
