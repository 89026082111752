import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useMemo } from 'react'
import { combineBy } from '../../../../utils/helpers/combineBy';
import { toIDR } from '../../../../utils/helpers/currency';
import ExcelExportBtn from '../../General/Tables/ExcelExportBtn';
import ReactDOMServer from 'react-dom/server';

const renderRow = (row, index) => (
  <TableRow key={index}>
    <TableCell>{index + 1}</TableCell>
    <TableCell>{row.uniqueId}</TableCell>
    <TableCell>{row.fullName}</TableCell>
    <TableCell>{combineBy([row.cityCode, row.cityName], ' - ')}</TableCell>
    <TableCell>{toIDR(row.totalBalance)}</TableCell>
    <TableCell>{toIDR(row.firstTopupAmount)}</TableCell>
    <TableCell>{row.ppob.count}</TableCell>
    <TableCell>{toIDR(row.ppob.sum)}</TableCell>
    <TableCell>{row.transfer.count}</TableCell>
    <TableCell>{toIDR(row.transfer.sum)}</TableCell>
    <TableCell>{row.injectiontopup.count}</TableCell>
    <TableCell>{toIDR(row.injectiontopup.sum)}</TableCell>
  </TableRow>
)

const renderExcelRow = (row, index) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>{row.uniqueId}</td>
    <td>{row.fullName}</td>
    <td>{combineBy([row.cityCode, row.cityName], ' - ')}</td>
    <td>{row.totalBalance}</td>
    <td>{row.firstTopupAmount}</td>
    <td>{row.ppob.count}</td>
    <td>{row.ppob.sum}</td>
    <td>{row.transfer.count}</td>
    <td>{row.transfer.sum}</td>
    <td>{row.injectiontopup.count}</td>
    <td>{row.injectiontopup.sum}</td>
  </tr>
)

const TableAll = ({
  items,
  period,
  setLoading
}) => {
  const tableTitle = 'Report 302 All';

  const excelHref = useMemo(() => {
    setLoading(true)
    const table = <table border="1">
      <thead>
        <tr>
          <th>#</th>
          <th>Unique ID</th>
          <th>User</th>
          <th>City</th>
          <th>Balance</th>
          <th>Total First Topup</th>
          <th>Total PPOB</th>
          <th>Total PPOB Amount</th>
          <th>Total Transfer</th>
          <th>Total Transfer Amount</th>
          <th>Total Injection</th>
          <th>Total Injection Amount</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((row, index) => renderExcelRow(row, index))
        }
      </tbody>
    </table>
    const html = ReactDOMServer.renderToStaticMarkup(table)
    setLoading(false)
    return `data:application/vnd.ms-excel,${encodeURIComponent(html)}`
  }, [items, setLoading]);

  return (
    <div className="card">
      <div className="table-header">
        <div className="info-cell">
          <div className="d-flex justify-content-between">
            <div>{tableTitle}</div>
            <div>
              {format(new Date(period), 'MMMM yyyy')}
            </div>
          </div>
        </div>
        <div className="action-cell">
          <ExcelExportBtn
            excelHref={excelHref}
            filename={`${combineBy(tableTitle.split(' '), '_')}_${format(new Date(period), 'MMMM_yyyy')}`}
          />
        </div>
      </div>
      <div className="report-table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Unique ID</TableCell>
              <TableCell>User</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Total First Topup</TableCell>
              <TableCell>Total PPOB</TableCell>
              <TableCell>Total PPOB Amount</TableCell>
              <TableCell>Total Transfer</TableCell>
              <TableCell>Total Transfer Amount</TableCell>
              <TableCell>Total Injection</TableCell>
              <TableCell>Total Injection Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => renderRow(row, index))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TableAll
