import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nnsApi } from '../../../services/nnsApi';
import { NNS_MODULE, UPDATE } from '../../../utils/constants/actionTypes';
import Form from './Form';

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { isUpdating } = useSelector((state) => state.nns);
  const { key, item } = useSelector(state => state.nns.tabs[index]);
  const [errorExistingValue, setErrorExistingValue] = useState("");

  const onSubmit = (values) => {
    const data = {
      bankName: values.bankName,
      codeBankOrPJP: values.codeBankOrPJP,
      codeNNS: values.codeNNS,
      refundTransaction: values.refundTransaction,
      switcher: values.switcher,
    };
    dispatch({
      module: NNS_MODULE,
      type: UPDATE,
      call: nnsApi.update,
      args: [key, data],
    }).catch((error) => {
      setErrorExistingValue(error.message);
    });
  };

  return item ?
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={isUpdating}
          errorExistingValue={errorExistingValue}
          initialValues={{
            bankName: item?.bankName,
            codeBankOrPJP: item?.codeBankOrPJP,
            codeNNS: item?.codeNNS,
            refundTransaction: item?.refundTransaction,
            switcher: item?.switcher,
          }}
        />
      </div>
    </div>
    :
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="text-center mb-0">Not Found</h5>
      </div>
    </div>
}

export default UpdateTab;
