import React from 'react'
import { Tabs, Tab } from '../../../components/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { KYC_VERIFY_HISTORIES_MODULE, CHANGE_TAB } from '../../../utils/constants/actionTypes';
import MainTab from './MainTab';
import { Helmet } from 'react-helmet';

const Index = () => {
  const dispatch = useDispatch();
  const { activeTabKey } = useSelector(state => state.kyc.verifyHistories);

  const handleTab = type => key => dispatch({ module: KYC_VERIFY_HISTORIES_MODULE, type, key });

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>KYC Verify Histories</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-hourglass icon-gradient bg-sunny-morning"></i>
            </div>
            <div>
              KYC Verify Histories
              <div className="page-title-subheading">KYC Verify History List.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Index
