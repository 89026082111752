class promoTypes {
  static CASHBACK = 'CASHBACK';
  static DISCOUNT = 'DISCOUNT';

  static getStr(en) {
    switch (en) {
      case promoTypes.CASHBACK:
        return 'Cashback';
      case promoTypes.DISCOUNT:
        return 'Discount';
      default:
        return 'Unknown';
    }
  }
}

const promoTypesOpts = [
  { value: promoTypes.CASHBACK, label: promoTypes.CASHBACK },
  { value: promoTypes.DISCOUNT, label: promoTypes.DISCOUNT },
];

export { promoTypes, promoTypesOpts };
