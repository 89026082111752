import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import { EventType } from "../utils/constants/enums/eventTypes";
import {
  ROUTE_PARTNER_DONATION_LIST,
  ROUTE_PARTNER_DONATION_NEWS,
  ROUTE_PARTNER_LIST,
  ROUTE_PARTNER_SETTLEMENT,
  ROUTE_PARTNER_USER
} from "../utils/helpers/roleHelper";

import PartnerList from '../pages/Partner/List';
import PartnerSettlement from '../pages/Partner/Settlement'
import PartnerUser from '../pages/Partner/User'
import DonationList from '../pages/Partner/Donations/List'
import DonationNews from '../pages/Partner/Donations/News'

export const partner = {
  type: NAVMENU,
  name: 'Partners',
  icon: 'pe-7s-global',
  children: [
    {
      type: NAVLINK,
      name: 'List',
      url: '/partner/list',
      routeTitle: ROUTE_PARTNER_LIST,
      component: PartnerList
    },
    {
      type: NAVLINK,
      name: 'Settlement',
      url: '/partner/settlement',
      routeTitle: ROUTE_PARTNER_SETTLEMENT,
      component: PartnerSettlement
    },
    {
      type: NAVMENU,
      name: 'Donations',
      icon: 'pe-7s-drop',
      children: [
        {
          type: NAVLINK,
          name: 'List',
          url: '/partner/donations/list',
          routeTitle: ROUTE_PARTNER_DONATION_LIST,
          eventType: [EventType.NEW_DONATION, EventType.NEW_DONATION_UPDATE],
          component: DonationList
        },
        {
          type: NAVLINK,
          name: 'News',
          url: '/partner/donations/news',
          routeTitle: ROUTE_PARTNER_DONATION_NEWS,
          eventType: [EventType.NEW_DONATION_NEWS, EventType.NEW_DONATION_NEWS_UPDATE],
          component: DonationNews
        },
      ]
    },
    {
      type: NAVLINK,
      name: 'Partner Admin',
      url: '/partner/admin',
      routeTitle: ROUTE_PARTNER_USER,
      component: PartnerUser
    }
  ]
}
