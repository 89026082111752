import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { format } from 'date-fns'
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import HookTextField from '../../../../components/Forms/HookTextField';
import FormField from '../../../../components/Forms/FormField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import useAsync from '../../../../components/HooksUse/useAsync';
import CloseIcon from '@material-ui/icons/Close';
import SelectField from '../../../../components/Forms/SelectField';
import EvyCheckbox from '../../../../components/Forms/EvyCheckbox';
import { toIDR } from '../../../../utils/helpers/currency';
import { IMAGE_FORMATS } from '../../../../utils/constants/actionTypes';
import HookImage from '../../../../components/Forms/HookImage';
import { partnerApi } from '../../../../services/partnerApi';

const PartnerInformation = ({ item, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Partner Information</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ?
            <><CloseIcon fontSize="small" /><span className=" ml-2 text-uppercase">Cancel</span></> :
            <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
          }
        </button>
      </div>
      <EvyTextField
        readOnly
        row
        label="Partner ID"
        value={item.partnerId}
        helperText={`Created at: ${item.createdAt ? format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm') : '-'}`}
      />
      <EvyTextField
        readOnly
        row
        label="Balance"
        prepend="Rp"
        value={toIDR(item.balance?.primary, false) || '0'}
      />
      {isEditing ?
        <Form
          item={item}
          onSuccess={onSuccess}
          setIsLoading={setIsLoading}
          setIsEditing={setIsEditing}
        />
        :
        <View
          item={item}
        />
      }
      <LoadingBlocker in={isLoading} />
    </div>
  )
}

const View = ({ item }) => (
  <>
    <HookImage
      readOnly
      previousImageUrl={item.logo?.url}
      row
      label="Logo"
    />
    <EvyTextField
      readOnly
      row
      label="Partner Name"
      value={item.name}
    />
    <EvyTextField
      readOnly
      row
      label="Field"
      value={item.field}
    />
    <EvyTextField
      readOnly
      row
      label="Address"
      value={item.address}
    />
    <EvyTextField
      readOnly
      row
      label="Integration Type"
      value={item.integrationType}
    />
    {
      item.integrationType === 'COMPANY' &&
      <EvyTextField
        readOnly
        row
        label="Company"
        value={item.companyCode}
      />
    }
    {
      item.integrationType === 'ZAKAT' &&
      <FormField
        row
        label="Zakat"
      >
        <EvyCheckbox
          disabled
          label="Maal"
          checked={item.zakat?.maal}
          id="maal"
        />
        <EvyCheckbox
          disabled
          label="Profesi"
          checked={item.zakat?.profesi}
          id="profesi"
        />
        <EvyCheckbox
          disabled
          label="Fitrah"
          checked={item.zakat?.fitrah}
          id="fitrah"
        />
      </FormField>
    }
  </>
)

const schema = yup.object().shape({
  logo: yup.mixed()
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),
  partnerName: yup.string().required('Partner Name is required'),
  field: yup.string().required('Partner Field is required'),
  address: yup.string().required('Partner Address is required'),
  integrationType: yup.string().required('Integration Type is required'),
  companyCode: yup.string().when('integrationType', {
    is: integrationType => integrationType === 'COMPANY',
    then: yup => yup.required('Company Code is required'),
    otherwise: yup => yup.nullable(),
  }),

  zakat: yup.object({
    maal: yup.boolean(),
    profesi: yup.boolean(),
    fitrah: yup.boolean(),
  }).when('integrationType', {
    is: integrationType => integrationType === 'ZAKAT',
    then: yup => yup.test(
      "zakatValidation",
      "Please select at least one zakat",
      value => (value.maal || value.profesi || value.fitrah)
    ),
    otherwise: yup => yup.nullable(),
  }),
});

const Form = ({ item, onSuccess = () => { }, setIsLoading, setIsEditing }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      partnerName: item.name ?? '',
      field: item.field ?? '',
      address: item.address ?? '',
      integrationType: item.integrationType ?? '',
      companyCode: item.companyCode ?? '',
      zakat: {
        maal: item.zakat?.maal ?? false,
        fitrah: item.zakat?.fitrah ?? false,
        profesi: item.zakat?.profesi ?? false
      }
    },
  });
  const { integrationType, companyCode, zakat: { maal, fitrah, profesi } = {} } = watch(['integrationType', 'companyCode', 'zakat']);

  const getCompanies = useCallback(() => dispatch({ module: null, type: null, call: partnerApi.companyList }), [dispatch]);

  const { value: companyAsyncValue, pending: isGettingCompany } = useAsync(getCompanies, true);
  const companyOpts = companyAsyncValue?.data?.type.map(row => ({ value: row, label: row })) ?? [];

  const onSubmit = (values) => {
    const data = {
      partnerId: item.partnerId,
      name: values.partnerName,
      field: values.field,
      address: values.address,
      integrationType: values.integrationType,

      minSettlement: item.minSettlement,
      maxSettlement: item.maxSettlement,
      fee: item.fee,
      feeType: item.feeType,
      revenueFee: item.revenueFee,
      feeRules: item.feeRules
    };

    if (values.integrationType === 'COMPANY') data.companyCode = values.companyCode
    if (values.integrationType === 'ZAKAT') {
      data.maal = values.zakat.maal ?? false
      data.fitrah = values.zakat.fitrah ?? false
      data.profesi = values.zakat.profesi ?? false
    }
    if (values.logo[0]) data.logo = values.logo[0]

    setIsLoading(true);
    dispatch({ type: null, call: partnerApi.update, args: [item._id, data] })
      .then(() => {
        onSuccess();
        setIsEditing(false);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      })
  };

  useEffect(() => {
    register("integrationType");
    register("companyCode");

    return () => {
      unregister("integrationType");
      unregister("companyCode");
      unregister("zakat.maal")
      unregister("zakat.profesi")
      unregister("zakat.fitrah")
    };
  }, [register, unregister])

  useEffect(() => {
    if (integrationType === 'COMPANY') register("companyCode")
    else unregister("companyCode")
    if (integrationType === 'ZAKAT') {
      register("zakat.maal")
      register("zakat.profesi")
      register("zakat.fitrah")
    } else {
      unregister("zakat.maal")
      unregister("zakat.profesi")
      unregister("zakat.fitrah")
    }
  }, [integrationType, register, unregister])

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <HookImage
        previousImageUrl={item.logo?.url}
        row
        label="Logo"
        ref={register}
        name="logo"
        error={errors.logo}
        helperText={errors.logo ? errors.logo.message : null}
        format={IMAGE_FORMATS}
        onClear={() => setValue('logo', null, { shouldValidate: true })}
      />
      <HookTextField
        ref={register}
        label="Partner Name"
        name="partnerName"
        error={errors.partnerName}
        helperText={errors.partnerName ? errors.partnerName.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Field"
        name="field"
        error={errors.field}
        helperText={errors.field ? errors.field.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Address"
        name="address"
        error={errors.address}
        helperText={errors.address ? errors.address.message : null}
        row
      />
      <EvyTextField
        readOnly
        row
        label="Integration Type"
        value={integrationType}
      />
      {
        integrationType === 'COMPANY' &&
        <SelectField
          name="companyCode"
          label="Company"
          options={companyOpts}
          onChange={(v) => setValue('companyCode', v?.value, { shouldValidate: true })}
          value={companyCode}
          error={errors.companyCode}
          helperText={errors.companyCode ? errors.companyCode.message : null}
          row
          componentProps={{
            isClearable: true,
            isLoading: isGettingCompany
          }}
        />
      }
      {
        integrationType === 'ZAKAT' &&
        <FormField
          row
          label="Zakat"
          error={errors.zakat}
          helperText={errors.zakat?.message}
        >
          <EvyCheckbox
            label="Maal"
            onChange={(v) => setValue('zakat.maal', v.target.checked)}
            checked={maal}
            id="maal"
          />
          <EvyCheckbox
            label="Profesi"
            onChange={(v) => setValue('zakat.profesi', v.target.checked)}
            checked={profesi}
            id="profesi"
          />
          <EvyCheckbox
            label="Fitrah"
            onChange={(v) => setValue('zakat.fitrah', v.target.checked)}
            checked={fitrah}
            id="fitrah"
          />
        </FormField>
      }
      <FormField
        row
      >
        <LoadingBtn type="submit" className="btn btn-primary mr-2">Submit</LoadingBtn>
        <button onClick={() => setIsEditing(false)} className="btn btn-danger">Cancel</button>
      </FormField>
    </form>
  )
}

export default PartnerInformation;
