import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const Reload = ({ onReload, className }) => {
  return (
    <div className={`table-reload ${className}`}>
      <button type="button" className="btn btn-icon btn-icon-only btn-sm btn-outline-dark" onClick={onReload}>
        <FontAwesomeIcon icon={faSyncAlt} />
      </button>
    </div>
  )
}

export default Reload
