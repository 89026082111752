import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { bankTransferFeeApi } from '../../../services/bankTransferFeeApi';
import { BANK_TRANSFER_FEE_MODULE, CREATE_TAB, LIMIT_TABLE, MAIN_TAB_LOAD, SET_PAGE_TABLE, SORT_TABLE } from '../../../utils/constants/actionTypes';
import { toIDR } from '../../../utils/helpers/currency';
import Table from '../../../components/Table/Table';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total
  } = useSelector(state => state.billers.bankTransferFee);

  const load = useCallback(
    () => {
      dispatch({
        module: BANK_TRANSFER_FEE_MODULE,
        type: MAIN_TAB_LOAD,
        call: bankTransferFeeApi.list,
        args: [currentPage, limit, sort]
      });
    },
    [dispatch, currentPage, limit, sort]
  )

  useEffect(() => {
    load();
  }, [load]);

  const setTableConfig = type => (value, key) => {
    dispatch({
      module: BANK_TRANSFER_FEE_MODULE,
      type: type,
      value,
      key
    });
  }

  const table = useMemo(() => {
    const createTab = (key, title, tabType) => {
      dispatch({
        module: BANK_TRANSFER_FEE_MODULE,
        type: CREATE_TAB,
        key,
        title,
        tabType
      });
    }

    return ({
      inProgress,
      data: items,
      sort,
      limit,
      total,
      currentPage,
      columns: [
        {
          title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd')
        },
        { title: 'Name', key: 'name', name: 'name' },
        { title: 'Revenue', key: 'revenue', name: 'revenue', render: v => toIDR(v) },
        { title: 'Admin', key: 'billerFee', name: 'billerFee', render: v => toIDR(v) },
        { title: 'Charge to User', key: 'adminFee', name: 'adminFee', render: v => toIDR(v) },
        {
          title: 'Action', key: '_id', name: 'action', className: 'action-column', sortable: false, render: (v, row) => {
            return (
              <div className="d-flex">
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => createTab(v, row.name, 'UPDATE')}
                >
                  <i className="pe-7s-tools btn-icon-wrapper" />
                </button>
              </div>
            )
          }
        }
      ]
    })
  }, [inProgress, items, sort, limit, total, currentPage, dispatch])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
        </Table>
      </div>
    </div>
  )
}

export default MainTab
