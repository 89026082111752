import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { cashbackApi } from '../../../services/cashbackApi';
import { CASHBACK_MODULE, UPDATE } from '../../../utils/constants/actionTypes';
import AllStatus from '../../../utils/constants/enums/status';
import Form from './FormTab';

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs, items } = useSelector(state => state.businesses.cashback);
  const { loading, key } = tabs[index];
  const selectedItem = items.find(row => row._id === key);
  const [isLoading, setIsLoading] = useState(false);

  const submit = (values) => {
    const data = {
      ...values,
      businesses: values.businesses.map(v => v.value),
      active: values.active === AllStatus.INACTIVE ? '' : values.active
    }

    setIsLoading(true)
    dispatch({
      module: CASHBACK_MODULE,
      type: UPDATE,
      call: cashbackApi.update,
      args: [key, data],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return selectedItem ?
    <Form
      initialValue={{
        ...selectedItem,
        businesses: selectedItem.businesses.map(b => {
          return {
            value: b._id,
            label: b.name + ' | ' + b.cityName
          }
        }),
        active: selectedItem.active ? AllStatus.ACTIVE : AllStatus.INACTIVE
      }}
      onSubmit={submit}
      isLoading={loading || isLoading}
    />
    :
    <div className="card">
      <div className="card-body">
        <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
      </div>
    </div>
}

export default UpdateTab
