import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HookTextField from '../../../../../components/Forms/HookTextField';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import { useDispatch } from 'react-redux';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../../services/businessesApi';
import { BUSINESS_ADMIN_PTEN_VERIFY_MODULE } from '../../../../../utils/constants/actionTypes';

const schema = yup.object().shape({
  nmid: yup.string()
});

const BusinessInformation = ({ item, module, onSuccess, readOnly }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false);

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      nmid: item.nmid
    }
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    const data = {
      nmid: values.nmid
    }

    dispatch({ type: null, call: businessesApi.updateNmid, args: [item._id, data] })
      .then(() => {
        if (isMounted()) handleSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  };

  return (
    <>
      {
        (
          // module === BUSINESS_MODULE ||
          module === BUSINESS_ADMIN_PTEN_VERIFY_MODULE
        )
        &&
        <>
          <button
            className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ?
              <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
              <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
            }
          </button>
          <br /><br />
        </>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <HookTextField
          ref={register}
          label="National Merchant ID"
          name="nmid"
          error={errors.nmid}
          helperText={errors.nmid?.message}
          readOnly={isEditing ? !isEditing : readOnly}
        />
        {
          module === BUSINESS_ADMIN_PTEN_VERIFY_MODULE
          &&
          <LoadingBtn type="submit" disabled={isEditing ? !isEditing : readOnly} loading={isLoading} className="btn btn-primary btn-lg mr-2">Update</LoadingBtn>
        }
        {
          isEditing
          &&
          <LoadingBtn type="button" onClick={() => setIsEditing(false)} className="btn btn-danger btn-lg">Cancel</LoadingBtn>
        }
      </form>
    </>
  )
}

export default BusinessInformation
