export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

function customFetch(url) {
  return new Promise((resolve, reject) => {
    fetch(url).then(response => {
      if (response.ok) {
        resolve(response)
      } else {
        reject(new Error('error'))
      }
    }, error => {
      reject(new Error(error.message))
    })
  })
}
function convertToBlob(promisePayload) {
  return new Promise((resolve, reject) => {
    promisePayload.then(data => {
      resolve(data)
    })
  })
}

export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  // imageSrc = `https://api.allorigins.win/raw?url=${imageSrc}`
  const fetchImage = await customFetch(imageSrc)
  const blob = await convertToBlob(fetchImage.blob())

  var imageUrl = URL.createObjectURL(blob);
  const image = await createImage(imageUrl)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  let error;

  ctx.putImageData(data, 0, 0)

  if (pixelCrop.width < 480 || pixelCrop.height < 640) {
    error = "Should be more than 480 x 640 pixels"
  }

  // Check pixelCrop
  // console.log(pixelCrop)

  const img = canvas.toDataURL('image/jpeg', 1.0)
  const file = await toDataURL(img).then(dataUrl => dataURLtoFile(dataUrl, "swafotoCropped.jpg"));

  return { file, img, error }
}

export async function urlToFile(
  imageSrc,
  filename
) {
  // imageSrc = `https://api.allorigins.win/raw?url=${encodeURIComponent(imageSrc)}`
  const fetchImage = await customFetch(imageSrc)
  const blob = await convertToBlob(fetchImage.blob())

  var imageUrl = URL.createObjectURL(blob);
  const file = await toDataURL(imageUrl).then(dataUrl => dataURLtoFile(dataUrl, filename));
  return file;
}

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()

    // ?? how to check buffer file size ??
    // const bufferSize = blob.size;
    // console.log(`Image buffer size: ${bufferSize} bytes`);

    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))
