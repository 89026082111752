import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { adminApi } from '../../../services/adminApi';
import { ADMIN_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import Form from './Form';

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const submit = (values) => {
    setIsLoading(true);
    dispatch({
      module: ADMIN_MODULE,
      type: CREATE,
      call: adminApi.create,
      args: [values]
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return (
    <Form
      onSubmit={submit}
      isLoading={isLoading}
    />
  )
}

export default CreateTab
