import React, { Children, cloneElement } from 'react';
import styles from "./tabs.module.scss";
import { Transition } from 'react-transition-group';

const duration = 100;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateY(-5px)' },
  entered: { opacity: 1 },
  exiting: { opacity: 0, transform: 'translateY(5px)', position: 'absolute', top: 0, left: 0, width: '100%', height: 0 },
}

export const Tabs = ({ onClick, children, activeKey }) => {

  return (
    <div className={styles.tabsRoot}>
      <div className="d-flex flex-column flex-lg-row">
        <div className={styles.sideNav}>
          <div className="card overflow-hidden">
            <ul className={styles.menu}>
              {Children.map(children, (child, i) =>
                child ?
                  cloneElement(child, {
                    index: i,
                    onClick: child.props?.onClick ?? onClick,
                    active: child.props?.active ?? activeKey === i,
                  })
                  :
                  null
              )}
            </ul>
          </div>
        </div>
        <div className={styles.tabContent}>
          {Children.map(children, (child, i) =>
            child ?
              <Transition
                in={child.props?.active ?? activeKey === i}
                timeout={duration}
                appear={true}
                unmountOnExit
              >
                {state =>
                  <div
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}>
                    <div className="card">
                      <div className="card-body">
                        {child.props.children}
                      </div>
                    </div>
                  </div>
                }
              </Transition>
              :
              null
          )}
        </div>
      </div>

    </div>
  )
}

export const Tab = ({
  onClick,
  title,
  index,
  active,
  icon
}) => {

  return (
    <li className={`${styles.menuBtn}${active ? ' ' + styles.active : ''}`}>
      <button
        onClick={() => onClick(index)}
        className="btn"
      >
        {title}
        {icon}
      </button>
    </li>
  )
}

export const TabHeader = ({
  title,
}) => {

  return (
    <li className={styles.menuHeader}>{title}</li>
  )
}
