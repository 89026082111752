import React from 'react'
import styles from './history.module.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LoadingBlocker from '../../Loading/LoadingBlocker'

export const HistoryCard = ({ title, children, className, withFooter, isLoading }) => (
  <div className={`main-card card ${className ? + ' ' + className : ''}`}>
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <div className={styles.historyContainer}>
        <LoadingBlocker in={isLoading} />
        {
          children?.length ?
            <PerfectScrollbar>
              <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                {children}
              </div>
            </PerfectScrollbar>
            :
            <div className="text-center py-3">
              No Data
            </div>
        }
      </div>
    </div>
    {
      withFooter && <div className="card-footer">
        {withFooter}
      </div>
    }
  </div>
)

export const HistoryCardList = ({ content, date }) => (
  <div className="vertical-timeline-item vertical-timeline-element">
    <div>
      <span className="vertical-timeline-element-icon">
        <i className={"badge badge-dot badge-dot-xl badge-primary"}> </i>
      </span>
      <div className="vertical-timeline-element-content">
        {content}
        <span className="vertical-timeline-element-date">{date}</span>
      </div>
    </div>
  </div>
)
