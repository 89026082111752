import React from 'react'
import styles from "./horizontalBadge.module.scss"
import Tooltip from '@material-ui/core/Tooltip';

const HorizontalBadge = ({
  title,
  variant,
  content,
  contentInActive,
  active,
  className,
  inactive,
}) => {
  return (
    <Tooltip title={active ? title : ''}>
      <div className={`${styles.root}${variant ? ' ' + styles[variant] : ''} ${className ? className : ''} ${active ? ' ' + styles.active : inactive}`}>
        {active ? content : contentInActive}
      </div>
    </Tooltip>
  )
}

export default HorizontalBadge
