import agent from './agent'
import { paginate } from '../utils/helpers/paginate'

export const bankApi = {
  list: (page, limit, sort) => {
    let url = `/banks/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  update: (id, data) => {
    if (id) {
      return agent.requests.post(`/banks/${id}/update`, data, true);
    } else {
      return agent.requests.post(`/banks/create`, data, true);
    }
  },
  delete: (id) =>
    agent.requests.post(`/banks/${id}/delete`),
  getArtajasaTypes: () =>
    agent.requests.get(`/banks/artajasa-bank-type`),
  detail: (id) =>
    agent.requests.get(`/banks/${id}/detail`),
}
