import React, { useEffect, useMemo } from 'react';
import Header from './layouts/Header';
import Sidebar from './layouts/Sidebar';
import { Route, Switch } from 'react-router-dom';
import NotFound from './components/Error/NotFound';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import RoledRoute from './RoledRoute';
import routes from './routes';
import useIdleState from './components/HooksUse/useIdleState';
import { LOGOUT } from './utils/constants/actionTypes';
import { authApi } from './services/authApi';

const routeMapping = (routes, adminRole, currentResult = []) => {
  for (let i = 0; i < routes.length; i++) {
    const currentRoute = routes[i]
    if (currentRoute.children) {
      currentResult = routeMapping(currentRoute.children, adminRole, currentResult)
    }
    else if (currentRoute.component) currentResult.push(
      <RoledRoute key={currentRoute.url} role={adminRole} routeTitle={currentRoute.routeTitle} exact={currentRoute.exact} path={currentRoute.url} component={currentRoute.component} />
    )
  }
  return currentResult
}

const MainLayout = () => {
  const dispatch = useDispatch()
  const { role } = useSelector(state => state.common.currentAdmin)
  const routeRender = useMemo(() => routeMapping(routes, role), [role])
  const { isExpired } = useIdleState(process.env.REACT_APP_MAX_IDLE_TIME || 500)

  useEffect(() => {
    if (isExpired) dispatch({ type: LOGOUT, call: authApi.logout })
  }, [isExpired, dispatch])

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
      <Helmet titleTemplate="%s - Admin" />
      <Header></Header>
      <div className="app-main">
        <Sidebar></Sidebar>
        <div className="app-main__outer">
          <Switch>
            {routeRender}
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
