import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { configApi } from '../../../services/configApi';
import { CONFIG_MODULE, UPDATE } from '../../../utils/constants/actionTypes';
import FormTab from './FormTab';

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs, items, isUpdating } = useSelector(state => state.config);
  const { loading, key } = tabs[index];
  const selectedItem = items.find(row => row._id === key);

  const submit = (values) => {
    dispatch({
      module: CONFIG_MODULE,
      type: UPDATE,
      call: configApi.update,
      args: [values],
      tabKey: index
    })
      .catch(() => { })
  }

  return selectedItem ?
    <FormTab
      initialValue={selectedItem}
      onSubmit={submit}
      isLoading={isUpdating || loading}
    />
    :
    <div className="card">
      <div className="card-body">
        <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
      </div>
    </div>
}

export default UpdateTab
