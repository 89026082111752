import React, { Fragment, useMemo } from 'react';
import Resource from '../../components/Resource';
import { CASHBACK, REDEEM } from '../../utils/constants/voucherTypes';
import { format } from 'date-fns';
import Image from '../../components/Table/DataImage';
import { voucherApi } from '../../services/voucherApi';
import CreateTab from './CreateTab';
import UpdateTab from './UpdateTab';
import { VOUCHER_MODULE } from '../../utils/constants/actionTypes';
import { useSelector } from 'react-redux';
import roleHelper, { CREATE_UPDATE_FORM } from '../../utils/helpers/roleHelper';

const Index = () => {
  const {
    voucher: {
      currentPage,
      limit,
      sort
    },
    common: {
      currentAdmin
    }
  } = useSelector(state => state);

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    },
    { title: 'Code', key: 'code', name: 'code' },
    { title: 'Quantity', key: 'quantity', name: 'quantity' },
    {
      title: 'Type', key: 'type', name: 'type', render: (v, row) => {
        return row.tnc ? (row.tnc.redeem ? REDEEM : CASHBACK) : 'Undefined'
      }
    },
    {
      title: 'Cover', key: 'attachment', name: 'attachment', render: function (v, row) {
        return <Image src={v ? v.url : ''} />
      }
    },
    {
      title: 'Start / End', key: 'startAt', name: 'startAt', render: function (v, row) {
        return (
          <Fragment>
            {format(new Date(v), 'dd-MM-yyyy')}
            <span> / </span>
            {format(new Date(row.endAt), 'dd-MM-yyyy')}
          </Fragment>
        )
      }
    },
    { title: 'User', key: 'user.fullName', name: 'user.fullName' },
    { title: 'Status', key: 'status', name: 'status' }
  ]

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort
    }
  }, [currentPage, limit, sort])

  return (
    <Resource
      title="Vouchers"
      subTitle="Full Voucher Control includes Create, Delete & Updates."
      icon="pe-7s-album"
      role={roleHelper.hasAccess(currentAdmin.role, CREATE_UPDATE_FORM)}
      list={{
        columns: columns,
        reducerPath: "voucher",
        module: VOUCHER_MODULE,
        call: voucherApi.list,
        apiQuery: apiQuery
      }}
      create={{
        component: CreateTab
      }}
      update={{
        component: UpdateTab
      }}
    />
  );
}

export default Index;
