import React from 'react';
import Form from './Form';

const CreateTab = () => {
  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title mb-3">Create</h5>
        <Form />
      </div>
    </div>
  )
};

export default CreateTab;
