import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { configApi } from '../../../services/configApi';
import { CONFIG_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import Form from './FormTab';

const CreateTab = () => {
  const dispatch = useDispatch();
  const { isCreating } = useSelector(state => state.config)

  const onSubmit = (values) => {
    dispatch({
      module: CONFIG_MODULE,
      type: CREATE,
      call: configApi.update,
      args: [values]
    })
      .catch(() => { })
  }

  return (
    <Form
      onSubmit={onSubmit}
      isLoading={isCreating}
    />
  )
}

export default CreateTab
