import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { rejectReasonApi } from '../../../services/rejectReasonApi';
import { CREATE, REJECT_REASON_MODULE } from '../../../utils/constants/actionTypes';
import Form from './Form';

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const submit = useCallback((values) => {
    setIsLoading(true);
    dispatch({
      module: REJECT_REASON_MODULE,
      type: CREATE,
      call: rejectReasonApi.create,
      args: [
        {
          ...values,
          type: values.category
        }
      ]
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }, [dispatch])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          submit={submit}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default CreateTab
