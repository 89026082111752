import clsx from 'clsx';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import styles from './otp.module.scss'

const OTPInput = forwardRef((
  {
    length,
    value,
    onChange,
    error
  },
  ref
) => {
  const OTPRef = useRef();
  const maskRef = useRef();

  const [focus, setFocus] = useState(false)

  const renderMask = useMemo(() => {
    let view = [];
    for (let i = 0; i < length; i++) {
      view.push(
        <div
          className={clsx(
            styles.OTPValue,
            (value.length === i && focus) && styles.OTPValueActive
          )}
          key={i}
        >
          {value ? value.charAt(i) : ''}
        </div>
      )
    }
    return view
  }, [length, value, focus])

  const handleChange = e => {
    const v = e.target.value;
    if (v.length > length) {
      e.preventDefault()
      return
    }
    if (typeof onChange === 'function') onChange(v);
  }

  useEffect(() => {
    const handleClick = (e) => {
      const div = maskRef.current;
      if (div && div.contains(e.target)) setFocus(true)
      else setFocus(false)
    }

    const disableKeyboardArrow = (e) => {
      const div = OTPRef.current;
      if (div && div.contains(e.target) && ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(e.key)) {
        e.preventDefault()
      }
    }

    window.addEventListener('click', handleClick);
    window.addEventListener('keydown', disableKeyboardArrow);
    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', disableKeyboardArrow);
    };
  }, [])

  useEffect(() => {
    if (focus) OTPRef.current?.focus()
  }, [focus])

  return (
    <div className={styles.OTPMaskInputContainer}>
      <input
        value={value}
        onChange={handleChange}
        maxLength={length}
        ref={(el) => {
          OTPRef.current = el;
          if (ref) ref.current = el
        }}
        autoComplete="off"
        autoCorrect="off"
        className={styles.OTPInput}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <div
        className={clsx(styles.OTPMask, error && styles.OTPMaskError, (value.length === length && focus) && styles.OTPMaskFilled)}
        ref={maskRef}
      >
        {renderMask}
      </div>
      {
        error &&
        <p className={styles.errorText}>{error}</p>
      }
    </div>
  )
})

export default OTPInput
