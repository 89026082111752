import getPropByString from "../../utils/helpers/getPropByString"
import React from "react";

export const ExcelTable = ({ columns, items }) => {
  return items ? (
    <table border="1">
      <thead>
        <tr style={{ textAlign: "center" }}>
          <th>#</th>
          {columns.map((col, i) => {
            return <th key={i}>{col.title}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {
          items.map((row, i) => {
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                {columns.map((col, key) =>
                  <td align={col.align} key={key}>
                    {
                      typeof col.render === "function" ?
                        !/[a-zA-Z]/g.test(col.render(getPropByString(row, col.key), row)) &&
                          !String(col.render(getPropByString(row, col.key), row)).includes(" ") &&
                          String(col.render(getPropByString(row, col.key), row)).length > 11 ?
                          `'${col.render(getPropByString(row, col.key), row)}` :
                          col.render(getPropByString(row, col.key), row)
                        :
                        !/[a-zA-Z]/g.test(getPropByString(row, col.key)) &&
                          !String(getPropByString(row, col.key)).includes(" ") &&
                          String(getPropByString(row, col.key)).length > 11 ?
                          `'${getPropByString(row, col.key)}` :
                          getPropByString(row, col.key)
                    }
                  </td>
                )}
              </tr>
            )
          })
        }
      </tbody>
    </table>
  ) : null
}
