import React, { useEffect } from 'react'
import * as yup from 'yup';

import { CLOSED_USER_ACCOUNT_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import UserSelect from '../../../components/Select/UserSelect';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import HookTextField from '../../../components/Forms/HookTextField';
import { useDispatch, useSelector } from 'react-redux';
import { closedUserApi } from '../../../services/closedUserApi';

const schema = yup.object().shape({
  userId: yup.string().required('Please select a user'),
  reason: yup.string().required(),
});

const CreateTab = () => {
  const dispatch = useDispatch();

  const { isCreating } = useSelector(state => state.users.closeAccount);

  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const submit = (values) => {
    dispatch({
      module: CLOSED_USER_ACCOUNT_MODULE,
      type: CREATE,
      call: closedUserApi.create,
      args: [values]
    })
      .catch(() => { })
  }

  const {
    userId
  } = watch(['userId']);

  useEffect(() => {
    register('userId');

    return () => {
      unregister('userId')
    }
  }, [register, unregister])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Closing Form</h5>
            </div>
            <div className="col-12 col-md-9">
              <UserSelect
                onChange={v => setValue('userId', v?.value ?? null)}
                value={userId}
                error={errors.userId}
                helperText={errors.userId ? errors.userId?.message : null}
              />
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Reason"
                name="reason"
                error={errors.reason}
                helperText={errors.reason ? errors.reason?.message : null}
                placeholder="e.g: User request for closing this account due to stolen phone"
                multiline
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn type="submit" loading={isCreating} className="btn btn-primary">Create Request</LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateTab;
