import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useMountedState from '../../../../../components/HooksUse/useMountedState'
import LoadingBtn from '../../../../../components/Forms/LoadingBtn'
import ListAllUsers from './ListAllUsers'
import ListStaff from './ListStaff'
import styles from './staff.module.scss'
import { companyApi } from '../../../../../services/companyApi'

const Staff = ({ item, refresh }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedStaffs, setSelectedStaffs] = useState({})
  const [selectedUsers, setSelectedUsers] = useState({});

  const selectedUsersArr = useMemo(() => Object.values(selectedUsers).reduce((acc, cur) => Boolean(cur) ? [...acc, { user: cur }] : acc, []), [selectedUsers])
  const selectedStaffsArr = useMemo(() => Object.values(selectedStaffs).reduce((acc, cur) => Boolean(cur) ? [...acc, { user: cur }] : acc, []), [selectedStaffs])

  const onAdd = () => {
    setIsAdding(true)
    dispatch({
      type: null,
      call: companyApi.addUser,
      args: [item._id, selectedUsersArr],
    })
      .then(() => refresh())
      .then(() => setSelectedUsers({}))
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsAdding(false) })
  }

  const onRemove = () => {
    setIsRemoving(true)
    dispatch({
      type: null,
      call: companyApi.removeUser,
      args: [item._id, selectedStaffsArr],
    })
      .then(() => refresh())
      .then(() => setSelectedStaffs({}))
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsRemoving(false) })
  }

  return (
    <div className={styles.staff}>
      <ListAllUsers
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        staff={item.staff}
        loading={isAdding}
      />
      <div className="d-flex justify-content-center m-3">
        <LoadingBtn type="submit" disabled={!Boolean(selectedUsersArr.length)} className="btn btn-primary mr-2" loading={isAdding} onClick={onAdd}>Add</LoadingBtn>
        <LoadingBtn type="submit" disabled={!Boolean(selectedStaffsArr.length)} className="btn btn-danger" loading={isRemoving} onClick={onRemove}>Remove</LoadingBtn>
      </div>
      <ListStaff
        loading={isRemoving}
        staff={item.staff}
        selectedStaffs={selectedStaffs}
        setSelectedStaffs={setSelectedStaffs}
      />
    </div>
  )
}

export default Staff
