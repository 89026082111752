import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import { TransactionType } from '../../../../utils/constants/enums/transactionTypes';
import AllStatus from '../../../../utils/constants/enums/status';
import { AdminRole } from '../../../../utils/constants/enums/adminRoles';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getApprover = row => {
  const statusHistories = row.metadata?.withdraw?.statusHistories ?? [];
  const history = statusHistories.find(row => row.status === AllStatus.COMPLETED) ?? null;
  const actor = history?.actor ?? null;
  if (actor && actor?.firstName) {
    return `${combineBy([actor?.firstName, actor?.lastName])} (${AdminRole.getStr(actor?.role)})`
  } else {
    return `Unknown`
  }
}

const getCreator = row => {
  const statusHistories = row.metadata?.withdraw?.statusHistories ?? [];
  const history = statusHistories.find(row => row.status === AllStatus.PENDING) ?? null;
  const actor = history?.actor ?? null;
  if (actor && actor?.firstName) {
    return `${combineBy([actor?.firstName, actor?.lastName])} (${AdminRole.getStr(actor?.role)})`
  } else {
    return `Unknown`
  }
}

const TableWithdraw = ({ items, period }) => {
  const filteredItems = useMemo(() =>
    items.filter(row => row.type === TransactionType.WITHDRAW)
    , [items]);

  const grandTotal = useMemo(() => {
    let amountArr = [];
    let amountTotal = 0;
    let feeTotal = 0;
    let grandTotal = 0;
    if (filteredItems) {
      for (let i = 0; i < filteredItems.length; i++) {
        amountTotal += filteredItems[i]?.metadata?.withdraw?.amount ?? 0;
        feeTotal += filteredItems[i]?.metadata?.withdraw?.fee ?? 0;
        grandTotal += filteredItems[i]?.amount?.primary ?? 0;
      }
    }

    amountArr = [
      { value: amountTotal },
      { value: feeTotal },
      { value: grandTotal }
    ]
    return amountArr;
  }, [filteredItems]);

  const columns = useMemo(() => {
    return [
      { title: "Trans. No", key: "code", name: "code", render: (v, row) => row?.metadata?.withdraw?.code },
      { title: "Name", key: "name", name: "name", render: (v, row) => row?.sender ? combineBy([combineBy([row.sender.firstName, row.sender.lastName]), row.sender.uniqueId], ' | ') : '-' },
      { title: "Created Date", key: "date", name: "date", render: (v, row) => format(new Date(row?.metadata?.withdraw?.createdAt), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Created By", key: "creator", name: "creator", render: (v, row) => getCreator(row) },
      { title: "Approved Date", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Approved By", key: "approver", name: "approver", render: (v, row) => getApprover(row) },
      { title: "Amount", key: "amount", name: "amount", withCurrency: true, render: (v, row) => row?.metadata?.withdraw?.amount },
      { title: "Admin Fee", key: "fee", name: "fee", withCurrency: true, render: (v, row) => row?.metadata?.withdraw?.fee },
      { title: "Total", key: "total", name: "total", withCurrency: true, render: (v, row) => row?.amount?.primary }
    ]
  }, [])

  return (
    <ReportTableTemplateV2
      title="Withdraw"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: filteredItems,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableWithdraw
