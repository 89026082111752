import React, { useMemo } from 'react';

const Show = ({ show, onChangeShow, total, currentPage }) => {
  const from = useMemo(() => {
    if (!total) return "0";
    let res = ((currentPage - 1) * show) + 1
    return res
  }, [currentPage, show, total])

  const to = useMemo(() => {
    let res = currentPage * show;
    if (res > total) res = total;
    return res
  }, [currentPage, show, total])

  const handleChange = e => {
    onChangeShow(Number(e.target.value))
  }

  return (
    <div className="table-show">
      <div className="show-text">
        Showing {from} to {to} of {total}
      </div>
      <select className="form-control form-control-sm" value={show} onChange={handleChange}>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  )
}

export default Show
