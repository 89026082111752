import { NAVLINK } from "../utils/constants/dataTypes";
import {
  ROUTE_VOUCHERS
} from "../utils/helpers/roleHelper";

import Voucher from '../pages/Voucher'

export const vouchers = {
  type: NAVLINK,
  name: 'Vouchers',
  icon: 'pe-7s-album',
  url: '/vouchers',
  routeTitle: ROUTE_VOUCHERS,
  component: Voucher
}
