import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { urlRegex } from "../../utils/constants/regexTypes";
import { CareerOpts } from "../../utils/constants/enums/careerOptsTypes";
import { WorkingOpts } from "../../utils/constants/enums/workingOptsTypes";
import activeStatusOpts from '../../utils/constants/enums/status/activeStatusOpts'
import HookTextField from "../../components/Forms/HookTextField";
import SelectField from "../../components/Forms/SelectField";
import LoadingBtn from "../../components/Forms/LoadingBtn";
import TextEditor from "../../components/Forms/TextEditor";
import EvyDatePicker from '../../components/Forms/EvyDatePicker';
import { endOfDay, format, startOfDay } from 'date-fns';
import { divisionOpts } from "../../utils/constants/enums/divisionOptsTypes";

const schema = yup.object().shape({
  title: yup.string().required(),
  division: yup.string().required(),
  jobType: yup.string().required(),
  location: yup.string().required(),
  workingType: yup.string().required(),
  salary: yup.string(),
  slug: yup.string(),
  content: yup.string().required(),
  link: yup.string().matches(urlRegex, "Only Valid URL Allowed").required(),
  active: yup.string().required(),
  periodFrom: yup.string(),
  periodTo: yup.string(),
});

const Form = ({ initialValues, onSubmit, isLoading, errorExistingValue }) => {
  const { register, unregister, setValue, handleSubmit, errors, watch } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues: {
        periodFrom: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        periodTo: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        ...initialValues,
      },
    });

  const {
    division,
    jobType,
    workingType,
    active,
    content,
    // isAlwaysActive,
    periodFrom,
    periodTo
  } = watch([
    'division',
    'jobType',
    'workingType',
    'active',
    'content',
    // 'isAlwaysActive',
    'periodFrom',
    'periodTo'
  ]);

  useEffect(() => {
    register('division')
    register('jobType')
    register('workingType')
    register('active')
    register('content')
    // register('isAlwaysActive')
    register('periodFrom')
    register('periodTo')
    return () => {
      unregister('division')
      unregister('jobType')
      unregister('workingType')
      unregister('active')
      unregister('content')
      // unregister('isAlwaysActive')
      unregister('periodFrom')
      unregister('periodTo')
    }
  }, [register, unregister])

  const handleDateChange = field => date => {
    let v;
    if (field === 'periodFrom') {
      v = startOfDay(new Date(date));
    } else if (field === 'periodTo') {
      v = endOfDay(new Date(date));
    }
    setValue(field, format(new Date(v), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), { shouldValidate: true });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Title"
            name="title"
            placeholder="e.g: Front-End Software Engineer"
            error={errors.title || errorExistingValue.title}
            helperText={errors.title?.message ?? null}
          />
          <SelectField
            label="Select Division"
            name="division"
            placeholder="Select Division"
            options={divisionOpts}
            onChange={(v) =>
              setValue("division", v?.value, { shouldValidate: true })
            }
            value={division}
            error={errors.division || errorExistingValue.division}
            helperText={errors.division?.message ?? null}
          />
          <SelectField
            label="Select Job Type"
            name="jobType"
            placeholder="Select Job Type"
            options={CareerOpts}
            onChange={(v) =>
              setValue("jobType", v?.value, { shouldValidate: true })
            }
            value={jobType}
            error={errors.jobType || errorExistingValue}
            helperText={errors.jobType?.message ?? null}
          />
          <HookTextField
            ref={register}
            label="Location"
            name="location"
            placeholder="e.g: Jakarta Selatan"
            error={errors.location || errorExistingValue}
            helperText={errors.location?.message ?? null}
          />
          <SelectField
            label="Select Working Type"
            name="workingType"
            placeholder="Select Working Type"
            options={WorkingOpts}
            onChange={(v) =>
              setValue("workingType", v?.value, { shouldValidate: true })
            }
            value={workingType}
            error={errors.workingType || errorExistingValue}
            helperText={errors.workingType?.message ?? null}
          />
          <HookTextField
            ref={register}
            label="Salary"
            name="salary"
            placeholder="e.g: Rp 5.000.000 - Rp 7.000.000"
            error={errors.salary || errorExistingValue}
            helperText={errors.salary?.message ?? null}
          />
          <TextEditor
            label="content"
            onChange={(v) => setValue("content", v, { shouldValidate: true })}
            helperText={errors.content?.message}
            error={Boolean(errors.content)}
            defaultValue={content}
          />
          <HookTextField
            ref={register}
            label="Link"
            name="link"
            placeholder="e.g: http://www.indeed.com"
            error={errors.link}
            helperText={errors.link ? errors.link.message : null}
          />
          <SelectField
            label="Set Active Status"
            name="active"
            options={activeStatusOpts}
            onChange={v => setValue('active', v?.value, { shouldValidate: true })}
            value={active}
            error={errors.active}
            helperText={errors.active?.message ?? null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Period</h5>
        </div>
        <div className="col-12 col-md-9">
          <div className="date-range">
            <div>
              <label>From</label>
              <EvyDatePicker
                value={periodFrom}
                onChange={handleDateChange('periodFrom')}
                variant="dialog"
                placeholder="Select date"
                clearable={false}
                disableToolbar
                disablePast
              />
            </div>
            <div>
              <label>To</label>
              <EvyDatePicker
                value={periodTo}
                onChange={handleDateChange('periodTo')}
                margin="dense"
                variant="dialog"
                placeholder="Select date"
                clearable={false}
                disableToolbar
                disablePast
              />
            </div>
          </div>
          {/* : null
          } */}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3"></div>
        <div className="col-12 col-md-9">
          <LoadingBtn
            type="submit"
            loading={isLoading}
            className="btn btn-primary"
            onClick={() => onSubmit}
          >
            Submit
          </LoadingBtn>
        </div>
      </div>
    </form>
  );
};

export default Form;
