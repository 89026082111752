import React, { useEffect } from 'react'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { numberRegExp } from '../../../utils/constants/regexTypes';
import HookTextField from '../../../components/Forms/HookTextField';
import { useForm } from 'react-hook-form';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { toIDR } from '../../../utils/helpers/currency';
import SelectField from '../../../components/Forms/SelectField';
import activeStatusOpts from '../../../utils/constants/enums/status/activeStatusOpts';
import { BankNameFeeTypeOpts } from '../../../utils/constants/enums/bankNameFeeTypes';

const schema = yup.object().shape({
  name: yup.string().required(),
  adminFee: yup.string().required().matches(numberRegExp, 'Must be a number'),
  billerFee: yup.string().required().matches(numberRegExp, 'Must be a number'),
  status: yup.string()
});

const Form = ({ initialValues, onSubmit, isLoading, tabType }) => {
  const { register, unregister, handleSubmit, errors, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues
  })

  const { adminFee, status, name, billerFee } = watch(['adminFee', 'status', 'name', 'billerFee'])

  useEffect(() => {
    register("status");
    register("name")

    return () => {
      unregister("status");
      unregister("name")
    };
  }, [register, unregister]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 col-md-3">
          <h5 className="card-title">GENERAL</h5>
        </div>
        <div className="col-12 col-md-9">
          <SelectField
            label="Name"
            options={BankNameFeeTypeOpts}
            onChange={(e) => setValue('name', e.value, { shouldValidate: true })}
            value={name}
            error={errors.name}
            helperText={errors.name && errors.name ? errors.name.message : null}
          />
          {
            tabType === "UPDATE" ?
              <SelectField
                label="Set Status"
                name="status"
                options={activeStatusOpts}
                onChange={v => setValue('status', v?.value, { shouldValidate: true })}
                value={status}
                error={errors.status}
                helperText={errors.status?.message ?? null}
              />
              : null
          }
          <HookTextField
            ref={register}
            label="Admin"
            name="billerFee"
            error={errors.billerFee}
            helperText={errors.billerFee?.message ?? toIDR(billerFee)}
            prepend="Rp"
          />
          <HookTextField
            ref={register}
            label="Charge to User"
            name="adminFee"
            error={errors.adminFee}
            helperText={errors.adminFee?.message ?? toIDR(adminFee)}
            prepend="Rp"
          />
          <hr />
          <h6 className="text-primary mb-4">Total Revenue:&nbsp;
            <span className="font-weight-bold">
              {toIDR((adminFee ?? 0) - (billerFee ?? 0))}
            </span>
          </h6>
          {/* </div> */}
          <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
        </div>
      </div>
    </form>
  )
}

export default Form
