import React from 'react'
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => ({
  onClick: (module, type, args) =>
    dispatch({ module, type, args })
});

class ButtonDispatch extends React.Component {
  render() {
    const { className, children, type, module, args } = this.props;
    return (
      <button type="button" className={className} onClick={(e) => this.props.onClick(module, type, args)}>{children}</button>
    )
  }
}

export default connect(null, mapDispatchToProps)(ButtonDispatch)
