class BankNameFeeType {
  static BCA = 'BCA';
  static BNI = 'BNI';
  static BRI = 'BRI';
  static MANDIRI = 'MANDIRI';
  static CIMB_NIAGA = 'CIMB_NIAGA';
  static ARTAJASA = 'ARTAJASA';
  static BNI_DISBURSEMENT = "BNI_DISBURSEMENT";
  static MERCHANT_SETTLEMENT_ARTAJASA = "MERCHANT_SETTLEMENT_ARTAJASA";
  static MERCHANT_SETTLEMENT_BCA = "MERCHANT_SETTLEMENT_BCA";
  static MERCHANT_SETTLEMENT_CIMB_NIAGA = "MERCHANT_SETTLEMENT_CIMB_NIAGA";
  static MERCHANT_SETTLEMENT_MANDIRI = "MERCHANT_SETTLEMENT_MANDIRI";
  static MERCHANT_SETTLEMENT_BRI = "MERCHANT_SETTLEMENT_BRI";
  static MERCHANT_SETTLEMENT_BNI = "MERCHANT_SETTLEMENT_BNI";
  static MERCHANT_SETTLEMENT_BNI_DISBURSEMENT = "MERCHANT_SETTLEMENT_BNI_DISBURSEMENT";

  static getStr(en) {
    switch (en) {
      case BankNameFeeType.BCA:
        return 'BCA';
      case BankNameFeeType.BNI:
        return 'BNI';
      case BankNameFeeType.BRI:
        return 'BRI';
      case BankNameFeeType.MANDIRI:
        return 'Mandiri';
      case BankNameFeeType.CIMB_NIAGA:
        return 'CIMB Niaga';
      case BankNameFeeType.ARTAJASA:
        return 'Artajasa';
      case BankNameFeeType.BNI_DISBURSEMENT:
        return 'BNI Disbursement';
      case BankNameFeeType.MERCHANT_SETTLEMENT_BCA:
        return 'Merchant Settlement BCA';
      case BankNameFeeType.MERCHANT_SETTLEMENT_BNI:
        return 'Merchant Settlement BNI';
      case BankNameFeeType.MERCHANT_SETTLEMENT_BRI:
        return 'Merchant Settlement BRI';
      case BankNameFeeType.MERCHANT_SETTLEMENT_MANDIRI:
        return 'Merchant Settlement Mandiri';
      case BankNameFeeType.MERCHANT_SETTLEMENT_CIMB_NIAGA:
        return 'Merchant Settlement CIMB Niaga';
      case BankNameFeeType.MERCHANT_SETTLEMENT_ARTAJASA:
        return 'Merchant Settlement Artajasa';
      case BankNameFeeType.MERCHANT_SETTLEMENT_BNI_DISBURSEMENT:
        return 'Merchant Settlement BNI Disbursement';
      default:
        return 'Unknown';
    }
  }
}

const BankNameFeeTypeOpts = [
  { value: BankNameFeeType.BCA, label: BankNameFeeType.getStr(BankNameFeeType.BCA) },
  { value: BankNameFeeType.BNI, label: BankNameFeeType.getStr(BankNameFeeType.BNI) },
  { value: BankNameFeeType.BRI, label: BankNameFeeType.getStr(BankNameFeeType.BRI) },
  { value: BankNameFeeType.MANDIRI, label: BankNameFeeType.getStr(BankNameFeeType.MANDIRI) },
  { value: BankNameFeeType.CIMB_NIAGA, label: BankNameFeeType.getStr(BankNameFeeType.CIMB_NIAGA) },
  { value: BankNameFeeType.ARTAJASA, label: BankNameFeeType.getStr(BankNameFeeType.ARTAJASA) },
  { value: BankNameFeeType.BNI_DISBURSEMENT, label: BankNameFeeType.getStr(BankNameFeeType.BNI_DISBURSEMENT) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_BCA, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_BCA) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_BNI, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_BNI) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_BRI, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_BRI) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_MANDIRI, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_MANDIRI) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_CIMB_NIAGA, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_CIMB_NIAGA) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_ARTAJASA, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_ARTAJASA) },
  { value: BankNameFeeType.MERCHANT_SETTLEMENT_BNI_DISBURSEMENT, label: BankNameFeeType.getStr(BankNameFeeType.MERCHANT_SETTLEMENT_BNI_DISBURSEMENT) }
];

export { BankNameFeeType, BankNameFeeTypeOpts };
