import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const businessesSettlementApi = {
  list: ({ page, limit, sort, startDate, endDate, status, checker, approver, type }) => {
    let url = `/merchant-settlements/bulks?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (checker) {
      url += `&checker=${checker}`;
    }
    if (approver) {
      url += `&approver=${approver}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    return agent.requests.get(url)
  },
  listSettlement: ({ id, page, limit, sort, status, aggrBank, bankRecipient, search }) => {
    let url = `/merchant-settlements/bulk/${id}/list`
    if (limit && page) {
      url += `?${paginate(limit, page)}`
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (aggrBank) {
      url += `&aggrBank=${aggrBank}`;
    }
    if (bankRecipient) {
      url += `&bankRecipient=${bankRecipient}`;
    }
    return agent.requests.get(url)
  },
  listMerchantRejectBySettlement: () => agent.requests.get('/merchant-settlements/list/merchant-reject'),
  requestBulk: (data) => {
    agent.requests.post('/merchant-settlements/request-bulk', data)
  },
  printReport: ({ id }) =>
    agent.requests.post(`/merchant-settlements/bulk/${id}/print`),
  // createBulk: () =>
  //   agent.requests.post('/merchant-settlements/create-bulk'),
  detailSettlement: (id) =>
    agent.requests.get(`/merchant-settlements/${id}/detail`),
  reject: (id, remark) =>
    agent.requests.post(`/merchant-settlements/${id}/reject`, { remark }),
  approve: (id) =>
    agent.requests.post(`/merchant-settlements/bulk/${id}/approve`),
  check: (id) =>
    agent.requests.post(`/merchant-settlements/bulk/${id}/check`),
  detailMutation: (id) =>
    agent.requests.get(`/merchant-settlements/${id}/detail/mutation`),
}
