import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const partnerApi = {
  list: ({ page, limit, sort, search, status }) => {
    let url = `/partners/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/partners/${id}/detail`),
  create: (data) =>
    agent.requests.post('/partners/create', data, true),
  integrationType: () =>
    agent.requests.get('/partners/get-integration-type'),
  companyList: () =>
    agent.requests.get('/integration/get-type'),
  update: (id, data) =>
    agent.requests.post(`/partners/${id}/update`, data, true),
  updatePic: (id, data) =>
    agent.requests.post(`/partners/${id}/update-pic`, data),
  resetPassword: (partnerId) =>
    agent.requests.post(`/partners/reset-password`, { partnerId }),
  setDefaultBankAccount: (partnerId, bankAccId) =>
    agent.requests.post(`/partners/${partnerId}/set-default-bank-account`, { bankAccount: bankAccId }),
  validateBank: (id, data) => agent.requests.post(`/partners/${id}/validate`, data),
  closeAccount: (partnerId) =>
    agent.requests.post(`/partners/${partnerId}/close-account`),
  unCloseAccount: (partnerId) =>
    agent.requests.post(`/partners/${partnerId}/unclose-account`),
  updateOpenApiSetting: (partnerId, data) =>
    agent.requests.post(`/partners/${partnerId}/update-open-api-setting`, data),
  updateOpenApiFeature: (partnerId, featureId, data) =>
    agent.requests.post(`/partners/${partnerId}/update-open-api-feature`, { ...data, featureId }),
  deleteOpenApiFeature: (partnerId, featureId) =>
    agent.requests.post(`/partners/${partnerId}/delete-open-api-feature`, { featureId })
}
