import { Switch } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import LoadingBtn from '../../../../../../components/Forms/LoadingBtn'
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../../../../components/Forms/HookTextField';
import FormField from '../../../../../../components/Forms/FormField';
import { partnerApi } from '../../../../../../services/partnerApi';

const schema = yup.object().shape({
  name: yup.string().required("Cannot be empty!"),
  enabled: yup.boolean().required("Cannot be empty!"),
  endpoints: yup.array()
    .of(
      yup.string()
        .required("Cannot be empty!")
    )
    .min(1, "Add tt least 1 Endpoint")
    .required("Cannot be empty!")
})

const CreateFeature = ({
  partnerId,
  initialValues,
  onSuccess
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [arrEndpoints, setArrEndpoints] = useState(initialValues?.endpoints ?? []);

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      enabled: false,
      ...initialValues
    }
  })

  const {
    enabled
  } = watch([
    "enabled"
  ])

  useEffect(() => {
    if (arrEndpoints.length) {
      setValue('endpoints', arrEndpoints, { shouldValidate: true, shouldDirty: true })
    }
  }, [arrEndpoints, setValue])

  const onSubmit = (values) => {
    setIsUpdating(true)
    partnerApi.updateOpenApiFeature(partnerId, initialValues?._id, {
      ...values
    })
      .then(() => {
        setIsUpdating(false)
        onSuccess()
      })
      .catch(() => {
        setIsUpdating(false)
      })
  }

  const handleInputChange = (index, value) => {
    const newInputs = [...arrEndpoints];
    newInputs[index] = value;
    setArrEndpoints(newInputs);
  };

  const handleAutoInputChange = (index, value) => {
    const newInputs = [...arrEndpoints];
    if (value.includes(',')) {
      value.split(',').forEach((v, i) => {
        newInputs[index + i] = v;
      })
    }
    setArrEndpoints(newInputs);
  };

  const handleAddInput = () => {
    setArrEndpoints([...arrEndpoints, '']);
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...arrEndpoints];
    newInputs.splice(index, 1);
    setArrEndpoints(newInputs);
  };

  useEffect(() => {
    register('endpoints');
    register('enabled');

    return () => {
      unregister('endpoints');
      unregister('enabled');
    }
  }, [register, unregister])

  return (
    <div className="main-card card">
      <div className="card-body">
        <h5 className="card-title mb-4">
          Create New Feature
        </h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HookTextField
            ref={register}
            name='name'
            placeholder="Feature Name"
            className="m-0"
            error={errors.name}
            helperText={errors?.name?.message ?? null}
          />
          <FormField
            error={errors.enabled}
            helperText={errors?.enabled?.message ?? null}
          >
            <div className='d-flex align-items-center mb-2'>
              <span>Enable?</span>
              <Switch
                value={enabled}
                color='primary'
                onChange={(e) => setValue("enabled", e.target.checked, { shouldValidate: true })}
              >

              </Switch>
            </div>
          </FormField>
          <FormField
            error={errors.endpoints}
            helperText={errors?.endpoints?.message ?? null}
            className='mb-4'
          >
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className='card-title m-0'>
                  Endpoints
                </h5>
                <LoadingBtn
                  className="btn btn-xs btn-outline-primary w-25"
                  type='button'
                  onClick={handleAddInput}
                >
                  <span>Add</span>
                </LoadingBtn>
              </div>
              {arrEndpoints.map((v, index) => {
                return (
                  <div className='d-flex' key={index}>
                    <HookTextField
                      placeholder="Endpoint"
                      formFieldClassName='m-0 w-100'
                      value={v}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      onBlur={(e) => handleAutoInputChange(index, e.target.value)}
                    />
                    <button
                      className="btn text-danger"
                      onClick={() => handleRemoveInput(index)}
                      type='button'
                    >
                      <i className="pe-7s-trash btn-icon-wrapper"></i>
                    </button>
                  </div>
                )
              })}
            </div>
          </FormField>
          <div>
            <LoadingBtn
              loading={isUpdating}
              disabled={!isValid}
              className="btn btn-primary w-100"
            >
              Submit
            </LoadingBtn>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateFeature
