import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, PPOB_MODULE, FILTER_TABLE, CREATE_TAB } from '../../../../utils/constants/actionTypes';
import EvySelectField from '../../../../components/Forms/EvySelectField';
import { PpobType, PpobTypeOpts } from '../../../../utils/constants/enums/ppobTypes';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import SelectField from '../../../../components/Forms/SelectField';
import useAsync from '../../../../components/HooksUse/useAsync';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { toIDR } from '../../../../utils/helpers/currency';
import BillerStatuses from './BillerStatuses';
import { ppobApi } from '../../../../services/ppobApi';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    filter,
    inProgress,
    items,
    total,
  } = useSelector(state => state.billers.ppob);

  const getBillers = useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.getBillers
    }),
    [dispatch]
  )

  const { value: { data: billerOpts = [] } = {}, pending: gettingBillers } = useAsync(getBillers, true);
  const billerOptions = useMemo(() => billerOpts.map(row => ({ label: row, value: row })) ?? [], [billerOpts]);

  const ppobListDebounced = useMemo(
    () => AwesomeDebouncePromise(ppobApi.list, 500),
    [],
  )

  const load = useCallback(
    () => {
      const search = filter.search ? `title|${encodeURIComponent(filter.search.replace(",", "`"))},code|${encodeURIComponent(filter.search.replace(",", "`"))}` : null
      dispatch({
        module: PPOB_MODULE,
        type: MAIN_TAB_LOAD,
        call: ppobListDebounced,
        args: [
          currentPage,
          limit,
          sort,
          filter.type,
          search,
          filter.status,
          filter.biller,
          filter.revenue !== '-' ? filter.revenue : null
        ]
      });
    },
    [currentPage, limit, sort, filter, dispatch, ppobListDebounced],
  )

  const createTab = (key, title) => {
    dispatch({
      module: PPOB_MODULE,
      type: CREATE_TAB,
      key,
      title
    });
  }

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: PPOB_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const table = () => ({
    inProgress: inProgress,
    data: items,
    sort: sort,
    limit: limit,
    total: total,
    currentPage: currentPage,
    columns: [
      { title: 'Title', key: 'title', name: 'title' },
      {
        title: 'Code', key: 'code', name: 'code', sortable: false, render: (v, row) => row.aggregator.find(el => +el.periority === 1)?.code
      },
      { title: 'Type', key: 'type', name: 'type', render: v => PpobType.getStrIdn(v) },
      {
        title: 'Status', key: 'status', name: 'type', sortable: false, render: (_, data) => (
          <BillerStatuses biller={data} />
        )
      },
      {
        title: 'Sell Price', key: 'sellPrice', name: 'sellPrice', render: (v, row) => (
          <div>
            <div className="text-primary font-weight-bolder mb-2">{toIDR(v)}</div>
            <div>Promo:&nbsp;<span className="font-weight-bolder">{toIDR(row?.DiscSellPrice)}</span></div>
          </div>
        )
      },
      {
        title: 'Price', key: 'price', name: 'price', sortable: false, render: (v, row) => toIDR(row.aggregator.find(el => +el.periority === 1)?.price)
      },
      {
        title: 'Revenue', key: 'revenue', name: 'revenue', sortable: false, render: (v, row) => toIDR(row.aggregator.find(el => +el.periority === 1)?.revenue)
      },
      {
        title: 'Biller', key: 'biller', name: 'biller', sortable: false, render: (v, row) => row.aggregator.find(el => +el.periority === 1)?.biller
      },
      {
        title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd')
        }
      },
      {
        title: 'Action', key: '_id', name: 'action', className: 'text-center', sortable: false, render: (v, row) => {
          return (
            <button
              className="btn btn-icon btn-icon-only btn-sm btn-primary"
              onClick={() => createTab(v, row.title)}
            >
              <i className="pe-7s-tools btn-icon-wrapper"></i>
            </button>
          )
        }
      }
    ]
  })

  useEffect(() => {
    load();
  }, [load])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table()}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
          <div className="form-row">
            <div className="col-12 col-md-4 col-lg-3">
              <EvyTextField
                label="Filter Code/Tilte"
                className="form-control"
                id="search"
                placeholder="Search"
                onChange={e => onFilter('search')(e.target.value)}
                value={filter.search}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <EvySelectField
                label="Filter Type"
                className="form-control"
                id="filterType"
                placeholder="No Filter"
                options={PpobTypeOpts}
                onChange={onFilter('type')}
                value={filter.type}
                nullable
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Status"
                className="form-control"
                placeholder="No Filter"
                options={[{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'INACTIVE', value: 'INACTIVE' }]}
                onChange={v => onFilter('status')(v?.value)}
                value={filter.status}
                componentProps={{
                  isClearable: true,
                }}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <SelectField
                label="Filter Biller"
                className="form-control"
                placeholder="No Filter"
                options={billerOptions}
                onChange={v => onFilter('biller')(v?.value)}
                value={filter.biller}
                componentProps={{
                  isClearable: true,
                  isLoading: gettingBillers
                }}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <EvyTextField
                label="Filter Revenue Under"
                className="form-control"
                placeholder="No Filter"
                onChange={({ target: { value } }) => {
                  if (+value || !value.length || value === '-')
                    onFilter('revenue')(value.trim())
                }}
                value={filter.revenue}
                prepend="Rp"
                helperText={filter.revenue.length ? toIDR(filter.revenue) : null}
              />
            </div>
          </div>
        </Table>
      </div>
    </div>
  )
}

export default MainTab
