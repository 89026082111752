import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { format } from 'date-fns';
import { combineBy } from '../../../utils/helpers/combineBy';

const VerifyIdHistoryCard = ({
  item
}) => {
  const [collapseKeys, setCollapseKeys] = useState([]);

  const setCollapse = (key) => {
    const shownState = Array.from(collapseKeys);
    const index = shownState.indexOf(key);

    if (index >= 0) {
      shownState.splice(index, 1)
      setCollapseKeys(shownState)
    } else {
      shownState.push(key)
      setCollapseKeys(shownState)
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-0 table overflow-auto table-hover table-striped">
          <table className='w-100'>
            <thead>
              <tr>
                <th></th>
                <th>Created At</th>
                <th>name</th>
                <th>Id Number</th>
                <th>Selfie Photo</th>
                <th>Id Card Photo</th>
                <th>Date of Birth</th>
                <th>Status</th>
                <th>Verifier</th>
              </tr>
            </thead>
            <tbody>
              {item.map((v, i) => {
                return (
                  <Fragment key={i}>
                    <tr>
                      <td onClick={() => setCollapse(i)}>
                        {collapseKeys.includes(i) ?
                          <FontAwesomeIcon icon={faChevronUp} /> :
                          <FontAwesomeIcon icon={faChevronDown} />
                        }
                      </td>
                      <td>{format(new Date(v?.createdAt), 'yyyy-MM-dd')}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span>{v?.name?.value}</span>
                          {
                            v?.name?.status ?
                              <div className="ml-1">
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                              </div>
                              :
                              <div className="ml-1">
                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                              </div>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span>{v?.identificationNumber?.value}</span>

                          {
                            v?.identificationNumber?.status ?
                              <div className="ml-1">
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                              </div>
                              :
                              <div className="ml-1">
                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                              </div>
                          }

                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {
                            Array.isArray(v?.metadata) && v?.metadata.find(v => v.fieldName === "selfie_photo")?.status === "match" ?
                              <>
                                <span>MATCH</span>
                                <div className="ml-1">
                                  <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                </div>
                              </>
                              :
                              <>
                                <span>NO MATCH</span>
                                <div className="ml-1">
                                  <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                                </div>
                              </>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {
                            Array.isArray(v?.metadata) && v?.metadata.find(v => v.fieldName === "ktp_photo")?.status === "match" ?
                              <>
                                <span>MATCH</span>
                                <div className="ml-1">
                                  <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                </div>
                              </>
                              :
                              <>
                                <span>NO MATCH</span>
                                <div className="ml-1">
                                  <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                                </div>
                              </>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span>{format(new Date(v?.dateOfBirth?.value), 'yyyy-MM-dd')}</span>

                          {
                            v?.dateOfBirth?.status ?
                              <div className="ml-1">
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                              </div>
                              :
                              <div className="ml-1">
                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                              </div>
                          }

                        </div>
                      </td>
                      <td>{v?.responseStatus?.value}</td>
                      <td>{v?.checker ? `${combineBy([v?.checker?.firstName, v?.checker?.lastName])} | ${v?.checker?.role}` : '-'}</td>
                      {/* <td>{`${v?.similarity}%`}</td> */}
                    </tr>
                    {
                      collapseKeys.includes(i) &&
                      <tr>
                        <td colSpan={9} className="p-0">
                          <JSONInput
                            locale={locale}
                            placeholder={v.metadata}
                            theme="light_mitsuketa_tribute"
                            width="100%"
                            height="300px"
                            viewOnly
                            confirmGood={false}
                            style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
                          />
                        </td>
                      </tr>
                    }
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default VerifyIdHistoryCard
