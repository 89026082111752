import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { mobileShowPPOBTypes } from '../../../../../../utils/constants/enums/mobileShowPPOBTypes';
import LoadingBtn from '../../../../../../components/Forms/LoadingBtn';
import Table from '../../../../../../components/Table/Table';

const AppearanceList = ({
  onAdd = () => { },
  data
}) => {
  const {
    inProgress,
  } = useSelector(state => state.config);

  const table = useMemo(() => ({
    inProgress: inProgress,
    data,
    columns: [
      { title: 'Name', key: 'name', name: 'name', sortable: false, render: (v) => mobileShowPPOBTypes.getStr(v) },
      {
        title: 'Action', key: 'name', name: 'name', className: 'text-center', sortable: false, render: (v, row) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LoadingBtn
                onClick={() => onAdd(v, row)}
                className="btn btn-primary"
              >
                Add
              </LoadingBtn>
            </div>
          )
        }
      }
    ]
  }), [inProgress, data, onAdd])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Table
          table={table}
        />
      </div>
    </div>
  )
}

export default AppearanceList
