export class mobilePPOBTypes {
  static MOBILE_PPOB_INTERNET = 'MOBILE_PPOB_INTERNET';
  static MOBILE_PPOB_PGN = 'MOBILE_PPOB_PGN';
  static MOBILE_PPOB_IPL = 'MOBILE_PPOB_IPL';
  static MOBILE_PPOB_TV = 'MOBILE_PPOB_TV';
  static MOBILE_PPOB_POSTPAID = 'MOBILE_PPOB_POSTPAID';
  static MOBILE_PPOB_CREDIT_CARD = 'MOBILE_PPOB_CREDIT_CARD';
  static MOBILE_PPOB_MULTIFINANCE = 'MOBILE_PPOB_MULTIFINANCE';
  static MOBILE_PPOB_TELKOM_PSTN = 'MOBILE_PPOB_TELKOM_PSTN';
  static MOBILE_PPOB_PBB = 'MOBILE_PPOB_PBB';
  static MOBILE_PPOB_SAMOLNAS = 'MOBILE_PPOB_SAMOLNAS';
  static MOBILE_PPOB_PHONE_PACKAGE = 'MOBILE_PPOB_PHONE_PACKAGE';
  static MOBILE_PPOB_PHONE_CREDIT = 'MOBILE_PPOB_PHONE_CREDIT';
  static MOBILE_PPOB_PLN = 'MOBILE_PPOB_PLN';
  static MOBILE_PPOB_AIR = 'MOBILE_PPOB_AIR';
  static MOBILE_PPOB_PLN_NON_BILL = 'MOBILE_PPOB_PLN_NON_BILL';
  static MOBILE_PPOB_BPJS = 'MOBILE_PPOB_BPJS';
  static MOBILE_PPOB_BPJSTK = 'MOBILE_PPOB_BPJSTK';
  static MOBILE_PPOB_BPJS_HEALTH = 'MOBILE_PPOB_BPJS_HEALTH';
  static MOBILE_PPOB_ETOLL = 'MOBILE_PPOB_ETOLL';
  static MOBILE_PPOB_ETOLL_BRI = 'MOBILE_PPOB_ETOLL_BRI';
  static MOBILE_PPOB_ETOLL_MANDIRI = 'MOBILE_PPOB_ETOLL_MANDIRI';
  static MOBILE_PPOB_ETOLL_BNI = 'MOBILE_PPOB_ETOLL_BNI';
  static MOBILE_PPOB_PLN_TOKEN = 'MOBILE_PPOB_PLN_TOKEN';

  static MOBILE_PPOB_EWALLET = 'MOBILE_PPOB_EWALLET';
  static MOBILE_PPOB_EWALLET_DANA = 'MOBILE_PPOB_EWALLET_DANA';
  static MOBILE_PPOB_EWALLET_OVO = 'MOBILE_PPOB_EWALLET_OVO';
  static MOBILE_PPOB_EWALLET_SHOPEE = 'MOBILE_PPOB_EWALLET_SHOPEE';
  static MOBILE_PPOB_EWALLET_GOPAY = 'MOBILE_PPOB_EWALLET_GOPAY';

  static getStr(en) {
    switch (en) {
      case mobilePPOBTypes.MOBILE_PPOB_INTERNET:
        return 'Internet';
      case mobilePPOBTypes.MOBILE_PPOB_PGN:
        return 'Gas PGN';
      case mobilePPOBTypes.MOBILE_PPOB_IPL:
        return 'Iuran Lingkungan';
      case mobilePPOBTypes.MOBILE_PPOB_TV:
        return 'TV Kabel';
      case mobilePPOBTypes.MOBILE_PPOB_POSTPAID:
        return 'Pascabayar';
      case mobilePPOBTypes.MOBILE_PPOB_CREDIT_CARD:
        return 'Kartu Kredit';
      case mobilePPOBTypes.MOBILE_PPOB_MULTIFINANCE:
        return 'Angsuran Kredit';
      case mobilePPOBTypes.MOBILE_PPOB_TELKOM_PSTN:
        return 'Telepon';
      case mobilePPOBTypes.MOBILE_PPOB_PBB:
        return 'PBB';
      case mobilePPOBTypes.MOBILE_PPOB_SAMOLNAS:
        return 'Samolnas';
      case mobilePPOBTypes.MOBILE_PPOB_PHONE_PACKAGE:
        return 'Paket Data';
      case mobilePPOBTypes.MOBILE_PPOB_PHONE_CREDIT:
        return 'Pulsa';
      case mobilePPOBTypes.MOBILE_PPOB_PLN:
        return 'Listrik';
      case mobilePPOBTypes.MOBILE_PPOB_AIR:
        return 'Air';
      case mobilePPOBTypes.MOBILE_PPOB_PLN_NON_BILL:
        return 'PLN Non Taglis';
      case mobilePPOBTypes.MOBILE_PPOB_BPJS:
        return 'BPJS';
      case mobilePPOBTypes.MOBILE_PPOB_BPJSTK:
        return 'BPJS Ketenagakerjaan';
      case mobilePPOBTypes.MOBILE_PPOB_BPJS_HEALTH:
        return 'BPJS Kesehatan';
      case mobilePPOBTypes.MOBILE_PPOB_ETOLL:
        return 'e-Money';
      case mobilePPOBTypes.MOBILE_PPOB_ETOLL_BRI:
        return 'BRIZZI';
      case mobilePPOBTypes.MOBILE_PPOB_ETOLL_MANDIRI:
        return 'Mandiri E-Money';
      case mobilePPOBTypes.MOBILE_PPOB_ETOLL_BNI:
        return 'BNI TapCash';
      case mobilePPOBTypes.MOBILE_PPOB_PLN_TOKEN:
        return 'Token PLN';
      case mobilePPOBTypes.MOBILE_PPOB_EWALLET:
        return 'E-Wallet';
      case mobilePPOBTypes.MOBILE_PPOB_EWALLET_DANA:
        return 'Dana';
      case mobilePPOBTypes.MOBILE_PPOB_EWALLET_OVO:
        return 'OVO';
      case mobilePPOBTypes.MOBILE_PPOB_EWALLET_SHOPEE:
        return 'Shopee';
      case mobilePPOBTypes.MOBILE_PPOB_EWALLET_GOPAY:
        return 'Gopay';
      default:
        return 'Unknown';
    }
  }
}
