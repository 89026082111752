import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import ReportTableTemplateV2 from './ReportTableTemplateV2';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';

const getNoPelanggan = (row) => {
  return row.metadata?.payment?.metadata?.ppob?.type === PpobType.BPJS_HEALTH
    ? row.metadata?.payment?.metadata?.ppobResponse?.noVA
    : row.metadata?.payment?.metadata?.ppobResponse?.kodeIuran
}

const getAtasNama = (row) => {
  return row.metadata?.payment?.metadata?.ppob?.type === PpobType.BPJS_HEALTH
    ? row.metadata?.payment?.metadata?.ppobResponse?.nama
    : row.metadata?.payment?.metadata?.ppobResponse?.nik
}

const getBiller = (row) => {
  let result = row.metadata?.payment?.metadata?.ppob?.type === PpobType.BPJS_HEALTH
    ? row.metadata?.payment?.metadata?.ppobResponse?.total >= 0 ? row.metadata.payment.metadata.ppobResponse.total : '-'
    : row.metadata?.payment?.metadata?.ppobResponse?.totalBayar >= 0 ? row.metadata.payment.metadata.ppobResponse.totalBayar : '-';
  return result;
}

const getWalletType = (obj) => {
  for (let key in obj) {
    if (obj[key] > 0) return key
  }
}

const TableBillerBPJS = ({ items, period }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let sumHargaBiller = 0;
    let sumRevenue = 0;
    let sumTotal = 0;

    items.forEach(row => {
      sumHargaBiller += +getBiller(row) || 0;
      sumRevenue += +row.metadata?.payment?.metadata?.ppob?.revenue || 0;
      sumTotal += +row.metadata?.payment?.amount || 0;
    })

    amountArr = [
      { value: sumHargaBiller },
      { value: sumRevenue },
      { value: sumTotal }
    ]
    return amountArr;
  }, [items]);

  const columns = useMemo(() => {
    return [
      { title: "Date", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Trans .No", key: "code", name: "code" },
      { title: "Type", key: "type", name: "type", render: (v, row) => PpobType.getStrIdn(row.metadata.payment.metadata.ppob.type) },
      { title: "Nama", key: "name", name: "name", render: (v, row) => combineBy([row.sender?.firstName, row.sender?.lastName]) },
      { title: "Payment", key: "payment", name: "payment", render: (v, row) => getNoPelanggan(row) },
      { title: "Atas Nama", key: "custumerName", name: "custumerName", render: (v, row) => getAtasNama(row) },
      { title: "Wallet", key: "amount", name: "amount", render: (v, row) => getWalletType(v) },
      { title: "Harga Biller", key: "biller", name: "biller", withCurrency: true, render: (v, row) => getBiller(row) },
      { title: "Revenue", key: "revenue", name: "revenue", withCurrency: true, render: (v, row) => row?.metadata?.payment?.metadata?.ppob?.revenue },
      { title: "Total", key: "total", name: "total", withCurrency: true, render: (v, row) => row.metadata?.payment?.amount }
    ]
  }, [])

  return (
    <ReportTableTemplateV2
      title="Biller BPJS"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableBillerBPJS
