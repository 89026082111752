import { combineReducers } from 'redux';
import ppob from './ppob';
import checkPrice from './checkPrice';
import bankTransferFee from './bankTransferFee';

export default combineReducers({
  ppob,
  checkPrice,
  bankTransferFee
});
