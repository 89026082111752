import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const announcementApi = {
  list: ({ page, limit, sort, type, application }) => {
    let url = `/announcements/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (application) {
      url += `&app=${application}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => agent.requests.post("/announcements/create", data),
  update: (id, data) => agent.requests.patch(`/announcements/${id}/update`, data),
  delete: (id) => agent.requests.delete(`/announcements/${id}/delete`)
}
