import { endOfDay, endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import {
  ASYNC_END,
  ASYNC_START,
  SET_FROM_DATE_FILTER,
  SET_TO_DATE_FILTER,
  FILTER_RESULT,

  GET_GRAPH_DATA,
  GET_TOTAL_HOLDED_CASH,
  GET_TOTAL_MERCHANT_FLOAT,
  SET_GRAPH_DATA
} from '../../utils/constants/actionTypes';

export const beginning = format(new Date('2018-01-01'), "yyyy-MM-dd");

const defaultState = {
  transferIn: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  transferOut: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  activeUserTransactions: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  activeMerchantTransactions: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  activeUsers: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  activeMerchants: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  registeredUsers: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'User',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  registeredMerchants: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
      status: ''
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'Merchant',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  kycUsers: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'KYC',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  transactions: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
      type: '',
      status: '',
      reload: false
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'Transactions',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  totalHoldedCash: {
    inProgress: false,
    value: {
      primary: 0,
      point: 0,
    }
  },

  // Business Detail Statistic
  volumeOfTransactions: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
      merchantId: ""
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'Volume Of Transactions',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  nominalOfTransactions: {
    inProgress: false,
    filter: {
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
      merchantId: ""
    },
    data: {
      labels: [],
      datasets: [
        {
          label: 'Nominal Of Transactions',
          data: [],
          backgroundColor: 'transparent',
          borderColor: 'rgba(24, 119, 242, 1)',
          pointBorderColor: 'rgba(24, 119, 242, 1)',
          pointBackgroundColor: 'rgba(24, 119, 242, 1)',
          borderWidth: 2
        }
      ]
    }
  },
  totalMerchantFloat: {
    inProgress: false,
    value: {
      primary: 0,
      point: 0,
    }
  },

  ppobAnalytics: {
    filter: {
      type: "ALL",
      sortBy: 'totalAmount',
      sortValue: 'desc',
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    },
  },
  mostSpend: {
    filter: {
      type: "ALL",
      sortBy: 'totalSpend',
      sortValue: 'desc',
      fromDate: subMonths(startOfMonth(new Date()), 1),
      toDate: endOfDay(endOfMonth(new Date())),
    }
  }
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_GRAPH_DATA:
      return {
        ...state,
        [action.objectName]: {
          ...state[action.objectName],
          data: {
            labels: action.labels,
            datasets: [
              {
                ...state[action.objectName].data.datasets[0],
                data: action.graphData
              }
            ]
          },
          inProgress: false
        },
      }
    case FILTER_RESULT:
      return {
        ...state,
        [action.objectName]: {
          ...state[action.objectName],
          filter: {
            ...state[action.objectName].filter,
            [action.key]: action.value,
            reload: false
          }
        }
      };
    case SET_FROM_DATE_FILTER:
      return {
        ...state,
        [action.objectName]: {
          ...state[action.objectName],
          filter: {
            ...state[action.objectName].filter,
            [action.key]: action.value,
            reload: false
          }
        }
      };
    case SET_TO_DATE_FILTER:
      return {
        ...state,
        [action.objectName]: {
          ...state[action.objectName],
          filter: {
            ...state[action.objectName].filter,
            [action.key]: action.value,
            reload: false
          }
        }
      };
    case GET_TOTAL_HOLDED_CASH:
      return {
        ...state,
        totalHoldedCash: {
          ...state.totalHoldedCash,
          value: !action.error ? action.payload.data : state.totalHoldedCash.value,
        }
      }
    case GET_TOTAL_MERCHANT_FLOAT:
      return {
        ...state,
        totalMerchantFloat: {
          ...state.totalMerchantFloat,
          value: !action.error ? action.payload.data : state.totalMerchantFloat.value,
        }
      }
    case ASYNC_START:
      switch (action.subtype) {
        case GET_GRAPH_DATA:
          return {
            ...state,
            [action.actionData.objectName]: {
              ...state[action.actionData.objectName],
              inProgress: true
            }
          };
        case GET_TOTAL_HOLDED_CASH:
          return {
            ...state,
            totalHoldedCash: {
              ...state.totalHoldedCash,
              inProgress: true,
            }
          }
        case GET_TOTAL_MERCHANT_FLOAT:
          return {
            ...state,
            totalMerchantFloat: {
              ...state.totalMerchantFloat,
              inProgress: true,
            }
          }
        default:
          return { ...state };
      }
    case ASYNC_END:
      switch (action.subtype) {
        case GET_GRAPH_DATA:
          return {
            ...state,
            [action.actionData.objectName]: {
              ...state[action.actionData.objectName],
              inProgress: false
            }
          };
        case GET_TOTAL_HOLDED_CASH:
          return {
            ...state,
            totalHoldedCash: {
              ...state.totalHoldedCash,
              inProgress: false,
            }
          }

        case GET_TOTAL_MERCHANT_FLOAT:
          return {
            ...state,
            totalMerchantFloat: {
              ...state.totalMerchantFloat,
              inProgress: false,
            }
          }
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
