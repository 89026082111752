import React from 'react'
import { BUSINESS_ADMIN_VERIFY_MODULE } from '../../../utils/constants/actionTypes'
import BusinessList from '../BusinessList'

const Index = () => {
  return (
    <BusinessList
      reducerPath="businesses.businessApproval"
      module={BUSINESS_ADMIN_VERIFY_MODULE}
      title="Business Verify Control"
      subTitle="Full Business Verify Control"
    />
  )
}

export default Index
