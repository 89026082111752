import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { careerApi } from "../../services/careerApi";
import {
  CAREER_MODULE
} from "../../utils/constants/actionTypes";
import Resource from "../../components/Resource";
import CreateTab from "./CreateTab";
import UpdateTab from "./UpdateTab";
import { CareerOptsTypes } from "../../utils/constants/enums/careerOptsTypes";
import { WorkingOptsTypes } from "../../utils/constants/enums/workingOptsTypes";
import HorizontalBadge from "../../components/Badge/HorizontalBadge";
import { divisionOptsTypes } from "../../utils/constants/enums/divisionOptsTypes";

const Index = () => {
  const { currentPage, limit, sort } = useSelector(
    (state) => state.career
  );

  const columns = [
    {
      title: "Created At",
      key: "createdAt",
      name: "createdAt",
      render: function (v, row) {
        return format(new Date(v), "yyyy-MM-dd");
      },
    },
    { title: "Title", key: "title", name: "title" },
    { title: "Division", key: "division", name: "division", render: v => divisionOptsTypes.getStr(v) },
    { title: "Job Type", key: "jobType", name: "jobType", render: v => CareerOptsTypes.getStr(v) },
    { title: "Working Type", key: "workingType", name: "workingType", render: v => WorkingOptsTypes.getStr(v) },
    { title: "Link", key: "link", name: "link" },
    {
      title: 'Status', key: 'active', name: 'active', render: v =>
        v ?
          <HorizontalBadge
            className="mr-2"
            title='Active'
            variant="primary"
            active={'ACTIVE'}
            contentInActive={<div className='mr-3'></div>}
            content="Active"
          />
          :
          <HorizontalBadge
            className="mr-2"
            title='Inactive'
            variant="danger"
            active={'INACTIVE'}
            contentInActive={<div className='mr-3'></div>}
            content="Inactive"
          />
    },
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort,
    };
  }, [currentPage, limit, sort]);

  return (
    <div>
      <Resource
        title="Career"
        subTitle="Job List"
        icon="pe-7s-id"
        list={{
          columns: columns,
          reducerPath: "career",
          module: CAREER_MODULE,
          call: careerApi.list,
          apiQuery: apiQuery,
          deleteButton: {
            api: careerApi.delete
          }
        }}
        create={{ component: CreateTab }}
        update={{ component: UpdateTab }}
      />
    </div>
  );
};

export default Index;
