import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  BUSINESS_MODULE,
  FILTER_TABLE,
  GET_DETAIL,
  CREATE_TAB,
  CLOSE_TAB,
} from '../../../utils/constants/actionTypes';
import { closeTab, createTab } from '../../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'updatedAt|desc',
  currentPage: 1,
  activeTabKey: 0,
  isUpdating: false,
  filter: {
    status: null,
    MCC: null,
    search: null,
    // mccGroup: null,
    action: null,
    merchantCriteria: null,
    mccCategory: null,
    startDate: null,
    endDate: null,
    statusHistories: null,
    startDateHistories: null,
    endDateHistories: null,
    connectedPartner: null,
    activeStartDate: '',
    activeEndDate: ''
  },
  tabs: [],
};

const reducer = (state = defaultState, action) => {
  if (action.module !== BUSINESS_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      let items = action.payload && action.payload.data ? action.payload.data.businesses : [];
      return {
        ...state,
        items: items,
        total: action.payload && action.payload.data ? action.payload.data.count : 0,
      };
    case GET_DETAIL: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.tabKey]) clonedTabs[action.tabKey].loading = false;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1,
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        },
        currentPage: 1
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        case GET_DETAIL:
          const clonedTabs = [...state.tabs];
          clonedTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: clonedTabs,
            inProgress: true,
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
