import React from 'react';
import { useSelector } from 'react-redux';
import MainTab from './MainTab';
import { Helmet } from 'react-helmet';
import { Tabs, Tab } from '../../../../components/Tabs/Tabs';

const Index = () => {
  const { activeTabKey } = useSelector(state => state.billers.checkPrice);

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Check PPOB Price</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-way icon-gradient bg-love-kiss"></i>
            </div>
            <div>
              Check PPOB Price
              <div className="page-title-subheading">Check PPOB Price</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Index;
