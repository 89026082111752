import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../../../../components/Forms/HookTextField';
import { partnerApi } from '../../../../../../services/partnerApi';
import LoadingBtn from '../../../../../../components/Forms/LoadingBtn';

const schema = yup.object().shape({
  directDebitNotifyURL: yup.string().required(),
  OTPLimit: yup.string().required()
})

const EditForm = ({
  partnerId,
  initialValues,
  onSuccess,
  isEditing
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      directDebitNotifyURL: initialValues?.directDebitNotifyURL ?? "",
      OTPLimit: initialValues?.OTPLimit ?? 0,
    }
  })

  const {
    OTPLimit
  } = watch([
    "OTPLimit"
  ])

  const onSubmit = (values) => {
    if (isEditing) {
      setIsUpdating(true)
      partnerApi.updateOpenApiSetting(partnerId, {
        ...values,
        OTPLimit: +values.OTPLimit
      })
        .then(() => {
          setIsUpdating(false)
          onSuccess()
        })
        .catch(() => {
          setIsUpdating(false)
        })
    }
  }

  useEffect(() => {
    register("OTPLimit");

    return () => {
      unregister("OTPLimit");
    }
  }, [register, unregister])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HookTextField
          ref={register}
          label="Direct Debit Notify URL"
          name="directDebitNotifyURL"
          error={errors.directDebitNotifyURL}
          helperText={errors?.directDebitNotifyURL?.message ?? null}
          readOnly={!isEditing}
        />
        <HookTextField
          label="OTP Limit"
          name="OTPLimit"
          value={OTPLimit}
          format={Number}
          onChange={(v) => setValue("OTPLimit", v, { shouldValidate: true, shouldDirty: true })}
          error={errors.OTPLimit}
          helperText={errors?.OTPLimit?.message ?? null}
          readOnly={!isEditing}
        />
        {isEditing ?
          <LoadingBtn
            className={`d-block btn btn-primary`}
            type='submit'
            loading={isUpdating}
            disabled={!isDirty && !isValid}
          >
            Save Changes
          </LoadingBtn>
          : null
        }
      </form>
    </>
  )
}

export default EditForm
