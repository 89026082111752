import React, { memo, useState } from 'react'
import FormField from './FormField'
import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import styles from './forms.module.scss'
import clsx from 'clsx';
import { blogApi } from '../../services/blogApi';

const TextEditor = memo(({
  id,
  row,
  label,
  error,
  helperText,
  formFieldClassName,
  onFocus,
  onBlur,
  defaultValue = "",
  placeholder,
  onChange
}) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(defaultValue))));
  const [focus, setFocus] = useState(false);

  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      blogApi.uploadImage(file)
        .then(({ data }) => {
          if (data?.url) {
            const imageData = {
              link: `${data.url}?id=${data._id}`
            };
            resolve({ data: imageData });
          } else {
            reject('Image upload failed.');
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  return (
    <FormField
      id={id}
      row={row}
      label={label}
      error={error}
      helperText={helperText}
      className={formFieldClassName}
    >
      <div className={clsx(styles.textEditorWrapper, focus && styles.isFocused)}>
        <Editor
          editorState={editorState}
          placeholder={placeholder}
          editorClassName={styles.editor}
          onBlur={(e) => {
            setFocus(false);
            if (typeof onBlur === "function") onBlur(e);
          }}
          onFocus={(e) => {
            setFocus(true);
            if (typeof onFocus === "function") onFocus(e);
          }}
          onEditorStateChange={(val) => {
            setEditorState(val)
            if (typeof onChange === 'function') onChange(draftToHtml(convertToRaw(val.getCurrentContent())))
          }}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              previewImage: true,
              uploadCallback: uploadImageCallback,
              defaultSize: {
                height: '100%',
                width: '100%',
              },
              alt: { default: "" },
            },
          }}
        />
      </div>
    </FormField>
  )
})

export default TextEditor
