import React from 'react'
import Archive from './Archive'

const ArchiveTab = ({ business, onSuccess }) => {
  return (
    <div>
      <Archive
        businessId={business._id}
        onSuccess={onSuccess}
        uniqueKey="SIUP"
        prevArchive={business?.archives?.find(x => x.key === "SIUP")}
      />
    </div>
  )
}

export default ArchiveTab
