import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DONATION_MODULE, GET_DETAIL } from '../../../../../utils/constants/actionTypes';
import DonationCard from './DonationCard'
import { format } from 'date-fns'
import DeclineDialog from '../../../../../components/Dialog/DeclineDialog';
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker';
import ButtonDropdown from '../../../../../components/ButtonDropdown';
import useAsync from '../../../../../components/HooksUse/useAsync';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import AllStatus from '../../../../../utils/constants/enums/status';
import { donationApi } from '../../../../../services/donationApi';

const DetailTab = ({ index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const { key, loading } = useSelector(state => state.donation.list.tabs[index]);

  const {
    execute: getDetail,
    value: { data: item } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: DONATION_MODULE,
      type: GET_DETAIL,
      call: donationApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const handleApprove = () => {
    setIsLoading(true);
    dispatch({
      type: null,
      call: donationApi.approve,
      args: [item.donation._id, item.donationChanges ? 'UPDATE' : 'CREATE'],
    })
      .then(() => { if (isMounted()) getDetail() })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  const handleReject = (id, remark) => {
    setIsLoading(true);
    dispatch({
      type: null,
      call: donationApi.reject,
      args: [id, item.donationChanges ? 'UPDATE' : 'CREATE', remark],
    })
      .then(() => { if (isMounted()) getDetail() })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  const getClass = status => {
    let className = 'badge-primary';
    if (status === AllStatus.PROCESSING) {
      className = 'badge-warning'
    } else if (status === AllStatus.COMPLETED) {
      className = 'badge-success'
    } else if (status === AllStatus.CANCELED || status === AllStatus.REJECTED || status === AllStatus.INACTIVE) {
      className = 'badge-danger'
    }
    return className
  }

  const getDropdownMenu = useMemo(() => {
    const handleSetStatus = (status) => () => {
      setIsLoading(true);
      dispatch({
        type: null,
        call: donationApi.setStatus,
        args: [key, { status }],
      })
        .then(() => { if (isMounted()) getDetail() })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) });
    }

    if (!item?.donation?.status) return undefined
    const fixedMenu = [
      {
        title: "Set Canceled",
        onClick: handleSetStatus(AllStatus.CANCELED),
        withAlert: {
          title: 'Are you sure want to set this canceled?',
        }
      }
    ];

    if (item?.donation?.status === AllStatus.INACTIVE) fixedMenu.unshift(
      {
        title: "Set Active",
        onClick: handleSetStatus(AllStatus.PROCESSING)
      }
    )
    else fixedMenu.unshift(
      {
        title: "Set Completed",
        onClick: handleSetStatus(AllStatus.COMPLETED),
        withAlert: {
          title: 'Are you sure want set this completed?',
        }
      },
      {
        title: "Set Inactive",
        onClick: handleSetStatus(AllStatus.INACTIVE)
      }
    )
    return fixedMenu
  }, [item?.donation?.status, dispatch, getDetail, isMounted, key])

  return loading && !item ?
    null
    :
    item ?
      <>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">Partner Info</h5>
                <div className="form-group">
                  <label className="text-grey mb-1">Name</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.name}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">email</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.email}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Phone Number</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.phoneNumber}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Field</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.field}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Integration Type</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.integrationType}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9">
            <div className="card mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title mb-0">Donation Info</h5>
                  {
                    ![AllStatus.COMPLETED, AllStatus.CANCELED].includes(item.donation?.status) &&
                    <ButtonDropdown
                      alignRight
                      title='More Actions'
                      menu={getDropdownMenu}
                    />
                  }
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Request Number</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.noRequest}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Created At</label>
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.donation?.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Last Updated At</label>
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.donation?.updatedAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
                <div className="row form-group align-items-center">
                  <div className="col-4">
                    <label className="text-grey mb-0">Current Status</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className={`badge ${getClass(item.donation?.status)}`}>{item.donation?.status}</span>
                  </div>
                </div>
                {
                  item.donation?.status === AllStatus.REJECTED &&
                  <div className="form-group">
                    <label className="text-grey mb-1">Reject Reason</label>
                    <p className="mb-0 font-weight-semibold">{item.donation?.rejectReason ? item.donation.rejectReason : '-'}</p>
                  </div>
                }
                <hr />
                {
                  (item.donation.status === AllStatus.PROCESSING) &&
                  <div className="mb-3">
                    <DeclineDialog
                      className="btn btn-outline-danger mr-2"
                      dataId={item.donation._id}
                      onSubmit={handleReject}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleApprove}
                    >
                      Approve
                    </button>
                  </div>
                }
                <div className="row">
                  <div className={`col-8 ` + item.donationChanges ? "col-md-6" : "col-md-12"}>
                    <DonationCard
                      title="Current Release"
                      item={item.donation}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    {
                      item.donationChanges &&
                      <DonationCard
                        title="New Release Request"
                        item={item.donationChanges}
                        customCover={item.donationChanges.cover?.url ? null : item.donation.cover?.url}
                      />
                    }
                  </div>
                </div>
                <LoadingBlocker in={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
