import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import VirtualTable from '../../../../../components/VirtualTable';
import { AutoSizer } from 'react-virtualized';
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker';
import styles from './staff.module.scss'
import HookTextField from '../../../../../components/Forms/HookTextField';
import { userApi } from '../../../../../services/userApi';

const filter = (data, search, column = []) => data.filter((element) => column.some(el => String(element[el]).toLowerCase().includes(search.toLowerCase())));
const column = ['uniqueId', 'fullName', 'email', 'phoneNumber']

const ListAllUsers = ({
  setSelectedUsers,
  selectedUsers,
  staff,
  loading
}) => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [gettingUser, setGettingUser] = useState(false)
  const [search, setSearch] = useState('')

  const filteredUsers = useMemo(() => search ? filter(users, search, column) : users, [users, search])

  const loadUser = useCallback(() => dispatch({
    type: null,
    call: userApi.list,
    args: [{
      page: 0,
      limit: 0
    }]
  }), [dispatch])

  useEffect(() => {
    setGettingUser(true)
    loadUser()
      .then(({ data: { users } }) => setUsers(users))
      .catch(() => { })
      .finally(() => setGettingUser(false))
  }, [loadUser])

  return (
    <div className={styles.tableWrapper}>
      <div className="d-flex justify-content-between flex-wrap">
        <h5 className="card-title">List All Users</h5>
        <HookTextField
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          className={styles.searchInput}
          prepend={<i className="pe-7s-search"></i>}
        />
      </div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width, border: '1px solid #E5E5EA', position: 'relative' }}>
            <LoadingBlocker in={gettingUser || loading} />
            <VirtualTable
              rowHeight={30}
              tableHeight={240}
              data={filteredUsers}
              components={{
                RowList: {
                  component: CustomRowList,
                  props: {
                    setSelectedUsers,
                    staff
                  }
                }
              }}
              columns={[
                {
                  render: (_, dataRow) => {
                    const isStaff = Boolean(staff.find(el => el.user._id === dataRow._id))
                    return (
                      <input
                        type="checkbox"
                        disabled={isStaff}
                        className="form-check-input position-static m-0"
                        checked={Boolean(selectedUsers[dataRow._id]) || isStaff}
                        onChange={() => { }}
                      />
                    )
                  }
                },
                { title: 'Dipay ID', key: 'uniqueId' },
                { title: 'Full Name', key: 'fullName' },
                { title: 'Email', key: 'email' },
                { title: 'Phone Number', key: 'phoneNumber' }
              ]}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  )
}

const CustomRowList = ({ children, staff, setSelectedUsers, rowData }) => {
  const isStaff = Boolean(staff.find(el => el.user._id === rowData._id))
  return (
    <tr
      className={`${styles.rowList}${isStaff ? ` ${styles.staff}` : ''}`}
      onClick={() => {
        if (isStaff) return
        setSelectedUsers(prev => ({ ...prev, [rowData._id]: !prev[rowData._id] ? rowData._id : false }))
      }}
    >
      {children}
    </tr>
  )
}

export default ListAllUsers
