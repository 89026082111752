import AwesomeDebouncePromise from "awesome-debounce-promise";
import { endOfDay, format, startOfDay } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { promoConfigApi } from "../../../services/promoConfigApi";
import { DELETE, MAIN_TAB_LOAD, PROMO_QRIS_CONFIG_MODULE } from "../../../utils/constants/actionTypes";
import { applicablePromoTypes } from "../../../utils/constants/enums/applicablePromoTypes";
import { promoTypes } from "../../../utils/constants/enums/promoTypes";
import AllStatus from "../../../utils/constants/enums/status";
import { userTypes } from "../../../utils/constants/enums/userTypes";
import { DATE_PICKER, SELECT_FIELD } from "../../../utils/constants/inputTypes";
import { combineBy } from "../../../utils/helpers/combineBy";
import { toIDR } from "../../../utils/helpers/currency";
import AlertDialog from "../../../components/Dialog/AlertDialog";
import LoadingBtn from "../../../components/Forms/LoadingBtn";
import Resource from "../../../components/Resource";
import CreateTab from "./CreateTab";

const Index = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const {
    currentPage,
    limit,
    sort,
    items,
    filter
  } = useSelector(state => state.promo.qris);

  const load = useCallback(
    () => {
      const search = combineBy([
        filter.search ? `mainPromo.description|${filter.search},business.name|${filter.search}` : false
      ], ',');
      const validFrom = filter.validFrom ? startOfDay(new Date(filter.validFrom), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
      const validTo = filter.validTo ? endOfDay(new Date(filter.validTo), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

      dispatch({
        module: PROMO_QRIS_CONFIG_MODULE,
        type: MAIN_TAB_LOAD,
        call: promoConfigApi.listPromoQRIS,
        args: [{
          page: currentPage,
          limit,
          sort,
          search,
          type: filter.type,
          subType: filter.subType,
          validFrom,
          validTo
        }]
      });
    },
    [currentPage, limit, sort, filter, dispatch]
  )

  const handleDeleteMode = () => {
    setDeleteMode(!deleteMode)
  }

  const handleDelete = () => {
    dispatch({
      module: PROMO_QRIS_CONFIG_MODULE,
      type: DELETE,
      call: promoConfigApi.inActivePromoQRIS,
      args: [{
        listId: selectedRow
      }]
    })
      .catch(() => { })
      .finally(() => {
        setDeleteMode(false)
        setIsReset(true)
        load()
      })
  }

  const columns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) ?? '-' },
    { title: 'Periode From', key: 'mainPromo.validFrom', name: 'validFrom', render: v => format(new Date(v), 'yyyy-MM-dd'), },
    { title: 'Periode To', key: 'mainPromo.validTo', name: 'validTo', render: v => format(new Date(v), 'yyyy-MM-dd') },
    { title: 'Merchant Name', key: 'business', name: 'name', render: (v, row) => v?.name + ' | ' + row?.merchant?.uniqueId },
    { title: 'Type', key: 'type', name: 'type', render: v => promoTypes.getStr(v) ?? '-' },
    { title: 'Description', key: 'mainPromo.description', name: 'mainPromo.description' },
    { title: 'Sub Type', key: 'subType', name: 'subType' },
    { title: 'Value', key: 'value', name: 'value', render: (v, row) => row?.subType === "FIXED" ? toIDR(Number(v) || 0) : `${v}%` },
    { title: 'Max Amount', key: 'maxAmount', name: 'maxAmount', render: v => toIDR(Number(v) || 0) },
    { title: 'Min Amount', key: 'minAmount', name: 'minAmount', render: v => toIDR(Number(v) || 0) },
    { title: 'Max Quota', key: 'maxQuota', name: 'maxQuota' },
    { title: 'Max Quota Applicable:', key: 'maxApplicableQuota', name: 'maxApplicableQuota' },
    { title: 'Quota Applicable', key: 'applicableType', name: 'applicableType', render: v => applicablePromoTypes.getStr(v) ?? '-' },
    { title: 'User Type', key: 'userType', name: 'userType', render: v => userTypes.getStr(v) ?? '-' },
  ], [])

  const getPromoConfigDebounced = useMemo(
    () => AwesomeDebouncePromise(promoConfigApi.listPromoQRIS, 500),
    [],
  )

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.search ? `mainPromo.description|${filter.search},business.name|${filter.search}` : false
    ], ',');
    const validFrom = filter.validFrom ? startOfDay(new Date(filter.validFrom), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const validTo = filter.validTo ? endOfDay(new Date(filter.validTo), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      search,
      type: filter.type,
      subType: filter.subType,
      validFrom,
      validTo
    };
  }, [currentPage, limit, sort, filter]);

  return (
    <Resource
      title="Promo QRIS Config"
      subTitle="Full Promo QRIS Control includes Create, Delete & Updates."
      icon="pe-7s-gift"
      list={{
        columns,
        search: true,
        withCheckbox: {
          enable: deleteMode,
          callback: (v) => setSelectedRow(v),
          isReset,
          key: "_id",
          disabledSelected: items.map(v => v.status === AllStatus.INACTIVE)
        },
        reducerPath: "promo.qris",
        call: getPromoConfigDebounced,
        apiQuery,
        module: PROMO_QRIS_CONFIG_MODULE,
        excel: {
          columns: columns,
          filename: 'Promo_QRIS_List',
          apiResponseKey: 'data.businesses',
          queryParams: apiQuery
        },
        customTopLeftTable:
          <>
            <LoadingBtn
              className="btn btn-outline-primary mr-3"
              onClick={handleDeleteMode}
              disabled={!Boolean(items.length)}
            >
              {deleteMode ? "Deactivate Inactive Mode" : "Activate Inactive Mode"}
            </LoadingBtn>
            {
              deleteMode
              &&
              <AlertDialog
                buttonComponent={
                  <LoadingBtn
                    className="btn btn-danger mr-3"
                    disabled={!Boolean(Object.keys(selectedRow).length)}
                  >
                    Inactive
                  </LoadingBtn>
                }
                onAgree={handleDelete}
                title="Konfirmasi Inactive?"
                description="Apakah Anda yakin menonaktifkan promo?"
              />
            }
          </>
        ,
        filters: [
          {
            label: "Filter Type",
            type: SELECT_FIELD,
            key: "type",
            options: [
              {
                value: 'CASHBACK',
                label: 'Cashback'
              }
            ],
            value: filter.type,
          },
          {
            label: "Filter SubType",
            type: SELECT_FIELD,
            key: "subType",
            options: [
              {
                value: 'PERCENTAGE',
                label: 'Percentage'
              },
              {
                value: 'FIXED',
                label: 'Fixed'
              }
            ],
            value: filter.subType,
          },
          {
            label: "Start Date",
            type: DATE_PICKER,
            key: "validFrom",
            value: filter.validFrom,
            placeholder: "Select date"
          },
          {
            label: "End Date",
            type: DATE_PICKER,
            key: "validTo",
            value: filter.validTo,
            placeholder: "Select date"
          }
        ]
      }}
      additionalTab={{
        component: CreateTab,
        title: "Create"
      }}
    />
  )
}

export default Index
