import React from 'react';
import { Tab, Tabs } from '../../../components/Tabs/Tabs'
import MainTab from './MainTab';
import { CHANGE_TAB, TRANSACTION_LOG_MODULE } from '../../../utils/constants/actionTypes';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

const Transaction = () => {
  const dispatch = useDispatch();
  const { activeTabKey } = useSelector(state => state.logs.transaction);

  const handleTab = type => key => dispatch({ module: TRANSACTION_LOG_MODULE, type: type, key: key })

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Transaction Logs</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-info icon-gradient bg-happy-fisher"></i>
            </div>
            <div>
              Transaction Log
              <div className="page-title-subheading">Full Transaction Log listing with event name.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Transaction;
