import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const businessReferralApi = {
  list: ({ page, limit, sort, isActive, startPeriod, endPeriod, MCriteria }) => {
    let url = `/referral-code/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (isActive) {
      url += `&isActive=${isActive}`;
    } else {
      url += `&isActive=true`
    }
    if (startPeriod) {
      url += `&startPeriod=${startPeriod}`;
    }
    if (endPeriod) {
      url += `&endPeriod=${endPeriod}`;
    }
    if (MCriteria) {
      url += `&MCriteria=${MCriteria}`;
    }
    return agent.requests.get(url);
  },
  create: (data) => agent.requests.post("/referral-code/create-referral-code", data),
  delete: (id) => agent.requests.post(`/careers/${id}/delete`),
  update: (id, data) => agent.requests.post(`/careers/${id}/update`, data),
  detail: (id) => agent.requests.get(`/referral-code/${id}/detail`),
  referredMerchant: (id) => agent.requests.get(`/referral-code/${id}/detail`),
};
