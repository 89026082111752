import { combineReducers } from 'redux';
import error from './error';
import audit from './audit';
import transaction from './transaction';
import autoDebit from './autoDebit';

export default combineReducers({
  error,
  audit,
  transaction,
  autoDebit
});
