import React, { useCallback, useEffect, useState } from 'react';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { toIDR } from '../../../utils/helpers/currency';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import useAsync from '../../../components/HooksUse/useAsync';
import Tooltip from '@material-ui/core/Tooltip';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import { bankAccountApi } from '../../../services/bankAccountApi';
import { settlementApi } from '../../../services/settlementApi';

const schema = yup.object().shape({
  bankAccount: yup.string().required(),
  adminFee: yup.number().required().min(0).integer().typeError('Must be a number'),
});

const ApprovalCard = ({ selectedItem, onSuccess }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);

  const {
    value: { data: { bankAccounts = [] } = {} } = {},
    pending: loadingBank
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: bankAccountApi.list, args: [0, 0, 'createdAt|desc', null, null, true] }),
    [dispatch]), true)

  const approve = values => {
    let data = {
      fromBankAccount: values.bankAccount,
      fee: values.adminFee,
    };
    setIsloading(true);
    dispatch({ type: null, call: settlementApi.approve, args: [selectedItem._id, data] })
      .then(() => {
        onSuccess();
      })
      .finally(() => {
        setIsloading(false);
      });
  }

  const reject = (dataId, remarks) => {
    setIsloading(true);
    dispatch({ type: null, call: settlementApi.decline, args: [selectedItem._id, remarks] })
      .then(() => {
        onSuccess();
      })
      .finally(() => {
        setIsloading(false);
      });
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          bankAccounts={bankAccounts}
          isLoading={isLoading || loadingBank}
          submit={approve}
          onReject={reject}
        />
      </div>
    </div>
  )
}

const Form = ({
  onReject,
  bankAccounts,
  submit,
  isLoading,
}) => {
  const [selectedBank, setSelectedBank] = useState(null)

  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      bankAccount: '',
    }
  })

  const { adminFee } = watch(['adminFee'])

  useEffect(() => {
    register("bankAccount")

    return () => {
      unregister("bankAccount")
    }
  }, [register, unregister])

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <h5 className="card-title">Approval</h5>
        <div className="mb-3">
          <p>Please select a bank account to withdraw from.</p>
          {
            isLoading ? null :
              bankAccounts.length
                ?
                <div className="row bank-select-row">
                  {
                    bankAccounts.map((row, i) =>
                      <Tooltip title={`${row.bank.name} | ${row.accountName} | ${row.accountNumber}`}>
                        <label key={i} className="bank-select-list">
                          <input
                            onChange={e => {
                              setValue('bankAccount', e.target.value, { shouldValidate: true })
                              setSelectedBank({
                                bankName: row.bank.name,
                                accountName: row.accountName,
                                accountNumber: row.accountNumber
                              })
                            }}
                            name="bankAccount"
                            type="radio"
                            value={row._id}
                          />
                          <div className="bank-box">
                            {row.bank ?
                              <img className="img-fluid" src={row.bank.logo ? row.bank.logo.url : ''} alt={row.bank.abbr} />
                              : 'Bank Not Found'
                            }
                          </div>
                        </label>
                      </Tooltip>
                    )
                  }
                </div>
                :
                <div className="alert alert-warning">
                  <p className="font-weight-bold mb-1">System Bank Account not found!</p>
                  You will need System Bank Account to complete this Approval.
                </div>
          }
          {
            errors.bankAccount?.message &&
            <em className="error invalid-feedback d-block">{errors.bankAccount.message}</em>
          }
          {
            selectedBank &&
            <div className="mt-3">
              <p className="mb-1 text-grey">{selectedBank.bankName}</p>
              <p className="mb-1 font-weight-bold">{selectedBank.accountName}</p>
              <p className="mb-1">{selectedBank.accountNumber}</p>
            </div>
          }
        </div>
        <HookTextField
          prepend="Rp"
          ref={register}
          name="adminFee"
          label="Admin Fee"
          error={errors.adminFee}
          helperText={errors.adminFee?.message ?? toIDR(adminFee)}
          placeholder="e.g: 1000"
        />
        <div className="d-flex justify-content-end">
          <DeclineDialog className="btn btn-outline-danger mr-2" onSubmit={onReject} />
          <LoadingBtn type="submit" className="btn btn-primary">Approve</LoadingBtn>
        </div>
      </form>
      <LoadingBlocker in={isLoading} />
    </>
  );
}

export default ApprovalCard;
