import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const articleApi = {
  list: ({ page, limit, sort, type, application, category, active }) => {
    let url = `/articles/list?query=`;
    if (limit && page) {
      url += `&${paginate(limit, page)}`
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&filter=${type}`;
    }
    if (application) {
      url += `&application=${application}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    if (active) {
      url += `&status=${active === 'ACTIVE'}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => agent.requests.post("/articles/create", data, true),
  update: (id, data) => agent.requests.post(`/articles/${id}/update`, data, true),
  position: (id, data) => agent.requests.post(`/articles/${id}/set-position`, data),
  delete: (id) =>
    agent.requests.post(`/articles/${id}/delete`)
}
