import agent from './agent'

export const authApi = {
  current: () =>
    agent.requests.get('/admins/me'),
  refresh: (refreshToken) =>
    agent.requests.post('/admins/refresh', { refreshToken }),
  login: (email, password) =>
    agent.requests.post('/admins/login-with-otp', { email, password }),
  verifyOtp: (email, otp) =>
    agent.requests.post('/admins/verify-otp', { email, otp }),
  resendOTP: (email) =>
    agent.requests.post('/admins/request-otp', { email }),
  logout: () =>
    agent.requests.post('/admins/logout'),
};
