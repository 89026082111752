export const numberingGroup1 = [
  "'(1)",
  "'(2)",
  "'(3)",
  "'(4)",
  "'(5)",
]

export const numberingGroup2 = [
  "'(6)",
  "'(7)",
  "'(8)",
  "'(9)",
  "'(10)",
  "'(11)",
  "'(12)",
  "'(13)",
  "'(14)",
  "'(15)",
  "'(16)",
  "'(17)",
  "'(18)",
  "'(19)",
  "'(20)",
  "'(21)",
  "'(22)",
  "'(23)",
  "'(24)",
  "'(25)",
  "'(26)",
  "'(27)",
  "'(28)",
  "'(29)",
  "'(30)",
  "'(31)",
  "'(32)",
  "'(33)",
  "'(34)",
  "'(35)",
  "'(36)",
  "'(37)",
  "'(38)",
  "'(39)",
  "'(40)",
  "'(41)",
  "'(42)",
  "'(43)",
  "'(44)",
  "'(45)",
  "'(46)",
  "'(47)",
  "'(50)",
]

export const numberingGroup3 = [
  "'(49) = '(51)+'(97)+'(143)",
  "'(50) = '(74)+'(120)+'(166)",
  "'(51) = sum(61:67)=52",
  "'(52) = sum(68:73)='(51)",
  "'(53)",
  "'(54)",
  "'(55)",
  "'(56)",
  "'(57)",
  "'(58)",
  "'(59)",
  "'(60)",
  "'(61)",
  "'(62)",
  "'(63)",
  "'(64)",
  "'(65)",
  "'(66)",
  "'(67)",
  "'(68)",
  "'(69)",
  "'(70)",
  "'(71)",
  "'(72)",
  "'(73)",
  "'(74) = sum'(84:90) = (75)",
  "'(75) = sum'(91:96) = (75)",
  "'(76)",
  "'(77)",
  "'(78)",
  "'(79)",
  "'(80)",
  "'(81)",
  "'(82)",
  "'(83)",
  "'(84)",
  "'(85)",
  "'(86)",
  "'(91)",
  "'(88)",
  "'(89)",
  "'(90)",
  "'(91)",
  "'(92)",
  "'(93)",
  "'(94)",
  "'(95)",
  "'(96)",
  "'(97) = sum '(107:113) = '(98)",
  "'(98) = sum '(114:119) = '(97)",
  "'(99)",
  "'(100)",
  "'(101)",
  "'(102)",
  "'(103)",
  "'(104)",
  "'(105)",
  "'(106)",
  "'(107)",
  "'(108)",
  "'(109)",
  "'(110)",
  "'(111)",
  "'(112)",
  "'(113)",
  "'(114)",
  "'(115)",
  "'(116)",
  "'(117)",
  "'(118)",
  "'(119)",
  "'(120) = sum '(130:136) = '(121)",
  "'(121) = sum '(137:142) = '(120)",
  "'(122)",
  "'(123)",
  "'(124)",
  "'(125)",
  "'(126)",
  "'(127)",
  "'(128)",
  "'(129)",
  "'(130)",
  "'(131)",
  "'(132)",
  "'(133)",
  "'(134)",
  "'(135)",
  "'(136)",
  "'(137)",
  "'(138)",
  "'(139)",
  "'(140)",
  "'(141)",
  "'(142)",
  "'(143) = sum '(153:159) = '(144)",
  "'(144) = sum '(160:165) = '(143)",
  "'(145)",
  "'(146)",
  "'(147)",
  "'(148)",
  "'(149)",
  "'(150)",
  "'(151)",
  "'(152)",
  "'(153)",
  "'(154)",
  "'(155)",
  "'(156)",
  "'(157)",
  "'(158)",
  "'(159)",
  "'(160)",
  "'(162)",
  "'(162)",
  "'(163)",
  "'(164)",
  "'(165)",
  "'(166) = sum '(176:182) = '(167)",
  "'(167) = sum '(183:188) = '(166)",
  "'(168)",
  "'(169)",
  "'(170)",
  "'(171)",
  "'(172)",
  "'(173)",
  "'(174)",
  "'(175)",
  "'(176)",
  "'(177)",
  "'(178)",
  "'(179)",
  "'(180)",
  "'(181)",
  "'(182)",
  "'(183)",
  "'(184)",
  "'(185)",
  "'(186)",
  "'(187)",
  "'(188)",
]