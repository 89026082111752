import React from 'react'
import { USER_ANNOUNCEMENT_APP_MODULE } from '../../../utils/constants/actionTypes'
import ArticleList from '../AnnouncementList'

const Index = () => {
  return (
    <ArticleList
      reducerPath="announcement.userApp"
      module={USER_ANNOUNCEMENT_APP_MODULE}
      title="Dipay User App"
      subTitle="Full Control of Dipay User App Announcement includes Create, Delete & Updates."
    />
  )
}

export default Index
