import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MyForm from './Form'
import { BANK_ACCOUNT_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import { useHistory } from 'react-router-dom';
import useMountedState from '../../../components/HooksUse/useMountedState';
import { bankAccountApi } from '../../../services/bankAccountApi';

const CreateTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { derivedData } = useSelector(state => state.bankAccount);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState();

  const onSubmit = (data) => {
    setIsLoading(true)
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: CREATE,
      call: bankAccountApi.create,
      args: [data]
    })
      .then(() => {
        if (isMounted()) {
          if (Boolean(derivedData)) history.push(derivedData.from)
        }
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <MyForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          initialValues={
            derivedData ? {
              ownerModel: derivedData.ownerModel,
              ownerId: derivedData.ownerId
            } : undefined
          }
        >
        </MyForm>
      </div>
    </div>
  )
}

export default CreateTab
