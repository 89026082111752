import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import EvyCheckbox from '../../../components/Forms/EvyCheckbox';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MerchantSelect from '../../../components/Select/MerchantSelect';
import UserSelect from '../../../components/Select/UserSelect';
import { numberRegExp } from '../../../utils/constants/regexTypes';
import SelectField from '../../../components/Forms/SelectField';
import PartnerSelect from '../../../components/Select/PartnerSelect';
import { BANK_ACCOUNT_MODULE, CLEAR_DERIVE } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import CheckIcon from '@material-ui/icons/Check';
import { bankApi } from '../../../services/bankApi';
import { userApi } from '../../../services/userApi';
import { partnerApi } from '../../../services/partnerApi';
import { merchantApi } from '../../../services/merchantApi';

const schema = yup.object().shape({
  accountName: yup.string()
    .when('isSystem', {
      is: isSystem => isSystem,
      then: yup => yup.required(),
      otherwise: yup => yup.nullable(),
    }),
  accountNumber: yup.string().required().matches(numberRegExp, 'Must be a number'),
  bank: yup.string().required(),
  valid: yup.mixed()
    .when('isSystem', {
      is: isSystem => !isSystem,
      then: yup => yup.test(
        "checkValue",
        "Should be validated first",
        value => value
      ),
      otherwise: yup => yup.nullable(),
    }),
  ownerModel: yup.string()
    .when('isSystem', {
      is: isSystem => !isSystem,
      then: yup => yup.required(),
      otherwise: yup => yup.nullable(),
    }),
  ownerId: yup.string()
    .when('isSystem', {
      is: isSystem => !isSystem,
      then: yup => yup.nullable().required('Please select one'),
      otherwise: yup => yup.nullable(),
    }),
  isSystem: yup.boolean(),
  typeRdn: yup.number().required(),
});

const Form = ({ initialValues, onSubmit, isLoading }) => {
  const dispatch = useDispatch();
  const { derivedData } = useSelector(state => state.bankAccount);
  const [isValidating, setIsValidating] = useState(false)

  const { register, unregister, setValue, handleSubmit, errors, watch, trigger } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      valid: false,
      ownerModel: 'Merchant',
      typeRdn: 0,
      ...initialValues
    }
  });

  const { bank, isSystem, ownerModel, ownerId, valid, accountNumber, typeRdn } = watch(['bank', 'isSystem', 'ownerModel', 'ownerId', 'valid', 'accountNumber', 'typeRdn']);

  const {
    value: { data: { banks = [] } = {} } = {},
    pending: isGettingBank
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: bankApi.list, args: [0, 0] }),
    [dispatch]), true)
  const bankOpts = banks.map(row => ({ value: row._id, label: row.name }))

  useEffect(() => {
    register('bank')
    register('isSystem')
    register('ownerId')
    register('valid')
    register('ownerModel')
    register('typeRdn');

    return () => {
      unregister('bank')
      unregister('isSystem')
      unregister('ownerId')
      unregister('valid')
      unregister('ownerModel')
      unregister('typeRdn');
    }
  }, [dispatch, register, unregister])

  useEffect(() => {
    return () => {
      if (derivedData) dispatch({ module: BANK_ACCOUNT_MODULE, type: CLEAR_DERIVE })
    }
  }, [derivedData, dispatch])

  const submit = (values) => {
    const data = {
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      bank: values.bank,
      typeRdn: values.typeRdn
    }

    if (values.isSystem) {
      data.isSystem = true;
    } else {
      data.ownerModel = values.ownerModel;
      data.owner = values.ownerId;
    }

    onSubmit(data)
  }

  const onValidate = async () => {
    const validate = await trigger(['ownerModel', 'ownerId', 'accountNumber', 'bank'])
    if (!validate) return

    setIsValidating(true)
    dispatch({
      type: null,
      call: ownerModel === 'User' ? userApi.validateBank : ownerModel === 'Partner' ? partnerApi.validateBank : merchantApi.validateBank,
      args: [ownerId, { accountNumber, bank }]
    })
      .then(({ data: { bankAccount: { name } } }) => {
        setValue('accountName', name)
        setValue('valid', true)
      })
      .catch(() => { })
      .finally(() => setIsValidating(false))
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Bank Provider</h5>
        </div>
        <div className="col-12 col-md-9">
          <SelectField
            onChange={v => setValue('bank', v?.value ?? null, { shouldValidate: true })}
            options={bankOpts}
            label="Select Bank"
            value={bank}
            error={errors.bank}
            helperText={errors.bank?.message ?? null}
            componentProps={{
              isClearable: true,
              isLoading: isGettingBank,
              isDisabled: valid
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <RadioGroup
            row
            value={String(typeRdn)}
            onChange={(e) => setValue('typeRdn', e.target.value, { shouldValidate: true })}
          >
            <FormControlLabel value="1" control={<Radio color="primary" />} label="RDN" />
            <FormControlLabel value="0" control={<Radio color="primary" />} label="No RDN" />
          </RadioGroup>
          <HookTextField
            ref={register}
            label="Account Name"
            name="accountName"
            error={errors.accountName}
            helperText={isSystem ? errors.accountName?.message ?? null : null}
            readOnly={!isSystem}
          />
          <HookTextField
            ref={register}
            label="Account Number"
            name="accountNumber"
            error={errors.accountNumber}
            helperText={errors.accountNumber?.message ?? null}
            readOnly={valid}
          />
          <EvyCheckbox
            disabled={Boolean(derivedData) || valid}
            checked={isSystem}
            onChange={(v) => setValue('isSystem', v.target.checked, { shouldValidate: true })}
            label="Please check the box if this Bank account belongs to Dipay"
            id="isSystem"
            error={errors.isSystem}
            helperText={errors.isSystem?.message ?? null}
          />
        </div>
      </div>
      {!isSystem &&
        <div className="row">
          <div className="col-12 col-md-3">
            <h5 className="card-title">Owner</h5>
          </div>
          <div className="col-12 col-md-9">
            <div>
              <RadioGroup
                row
                value={ownerModel}
                onChange={(e) => {
                  setValue('ownerId', null)
                  setValue('ownerModel', e.target.value)
                }}
              >
                <FormControlLabel disabled={Boolean(derivedData) || valid} value="Merchant" control={<Radio color="primary" />} label="Merchant" />
                <FormControlLabel disabled={Boolean(derivedData) || valid} value="User" control={<Radio color="primary" />} label="User" />
                <FormControlLabel disabled={Boolean(derivedData) || valid} value="Partner" control={<Radio color="primary" />} label="Partner" />
              </RadioGroup>
            </div>
            {ownerModel === 'Merchant'
              &&
              <MerchantSelect
                disabled={Boolean(derivedData) || valid}
                value={ownerId ?? null}
                onChange={(v) => setValue('ownerId', v?.value ?? null, { shouldValidate: true })}
                error={errors.ownerId}
                helperText={errors.ownerId?.message ?? null}
              />
            }
            {
              ownerModel === 'User'
              &&
              <UserSelect
                disabled={Boolean(derivedData) || valid}
                value={ownerId ?? null}
                onChange={(v) => setValue('ownerId', v?.value ?? null, { shouldValidate: true })}
                error={errors.ownerId}
                helperText={errors.ownerId?.message ?? null}
              />
            }
            {
              ownerModel === 'Partner'
              &&
              <PartnerSelect
                disabled={Boolean(derivedData) || valid}
                value={ownerId ?? null}
                onChange={(v) => setValue('ownerId', v?.value ?? null, { shouldValidate: true })}
                error={errors.ownerId}
                helperText={errors.ownerId?.message ?? null}
              />
            }
          </div>
        </div>
      }
      <div className="row">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            {
              !isSystem &&
              <LoadingBtn
                className="btn btn-primary mr-2"
                type="button"
                disabled={valid}
                onClick={onValidate}
                loading={isValidating}
              >
                {
                  valid ?
                    <>
                      <CheckIcon fontSize="small" /><span className="ml-1">Validated</span>
                    </>
                    :
                    'Validate'
                }
              </LoadingBtn>
            }
            {
              (valid || isSystem) &&
              <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
            }
          </div>
        </div>
      </div>
    </form>
  );
}

export default Form
