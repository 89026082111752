import React from 'react'
import EvyDatePicker from '../../components/Forms/EvyDatePicker';
import FormField from '../../components/Forms/FormField';

const DateFilter = ({
  fromDate,
  toDate,
  setFromDate = () => { },
  setToDate = () => { }
}) => {
  return (
    <>
      <div className="col-6">
        <FormField label="From Date">
          <EvyDatePicker
            value={fromDate}
            maxDate={toDate}
            onChange={setFromDate}
            variant="dialog"
            autoOk
          />
        </FormField>
      </div>
      <div className="col-6">
        <FormField label="End Date">
          <EvyDatePicker
            value={toDate}
            minDate={fromDate}
            onChange={setToDate}
            variant="dialog"
            autoOk
          />
        </FormField>
      </div>
    </>
  )
}

export default DateFilter
