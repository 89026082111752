import React, { useMemo } from 'react';
// import { format } from 'date-fns';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const TableMerchantIndofund = ({ items, period, selectedType, notes }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let totalInitialBalance = 0;
    let totalCreditIndofund = 0;
    let totalAllBalance = 0;

    items.forEach((row) => {
      totalInitialBalance += row.initialBalance;
      totalCreditIndofund += row.creditIndofund;
      totalAllBalance += row.endingBalance;
    })

    amountArr = [
      { value: totalInitialBalance },
      { value: totalCreditIndofund },
      { value: totalAllBalance }
    ]

    return amountArr;
  }, [items]);

  const columns = useMemo(() => {
    return [
      // { title: "Date", key: "date", name: "date", render: (v) => v ? format(new Date(v), 'yyyy-MM-dd HH:mm:ss') : "" },
      // { title: "Trans. No", key: "transactionNumber", name: "transactionNumber" },
      { title: "Nama Merchant | ID", key: "merchantName", name: "merchantName" },
      { title: "Nama Owner", key: "ownerName", name: "ownerName" },
      { title: "Saldo Awal Merchant", key: "initialBalance", name: "initialBalance", withCurrency: true },
      { title: "Cicilan Indofund", key: "creditIndofund", name: "creditIndofund", withCurrency: true },
      { title: "Saldo Akhir Merchant", key: "endingBalance", name: "endingBalance", withCurrency: true }
    ]
  }, [])

  return (
    <ReportTableTemplateV2
      title={`Direct Integration - ${selectedType}`}
      period={period}
      withIndex
      withExportExcel
      notes={notes}
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableMerchantIndofund
