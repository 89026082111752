import React, { useState, useRef } from 'react'
import * as yup from 'yup';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../../../components/Forms/HookTextField';
import { useDispatch } from 'react-redux';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import { businessesApi } from '../../../../../services/businessesApi';

const schema = yup.object().shape({
  archiveName: yup.string().required(),
  archiveDescription: yup.string().nullable(),
  attachment: yup.mixed().test(
    "required",
    "Attachment is required",
    value => value.length
  ),
});

const Archive = ({ businessId, onSuccess, uniqueKey, prevArchive }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMountedState();
  const inputFileRef = useRef();
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      archiveName: prevArchive ? prevArchive.name : '',
      archiveDescription: prevArchive ? prevArchive.description : '',
      attachment: undefined,
    }
  })

  const attachment = watch('attachment');
  const filename = attachment?.[0]?.name ?? prevArchive?.attachment?.name;

  const onSubmit = (values) => {
    const data = {
      key: uniqueKey,
      name: values.archiveName,
      description: values.archiveDescription,
    }
    if (values.attachment.length) data.attachment = values.attachment[0]

    setIsLoading(true);
    dispatch({
      type: null,
      call: businessesApi.updateArchive,
      args: [businessId, data]
    }).then(() => { if (isMounted()) onSuccess() })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  const showFileUpload = () => inputFileRef?.current?.click()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="archive-input">
        <div className="unique-key">
          <p>{uniqueKey}</p>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <HookTextField
              ref={register}
              label="Name"
              name="archiveName"
              error={errors.archiveName}
              helperText={errors.archiveName?.message ?? null}
              placeholder="e.g: SURAT SIUP 2019"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="archiveAttachment">Attachment</label>
              <div>
                <button type="button" className="btn btn-light btn-icon btn-file" onClick={showFileUpload}>
                  <div className="content-wrapper">
                    <i className={`btn-icon-wrapper ${filename ? 'lnr-cloud-sync' : 'lnr-cloud-upload'}`}></i>
                    <span>{filename ?? 'Upload'}</span>
                  </div>
                </button>
                {errors.attachment &&
                  <em className="error invalid-feedback d-block">{errors.attachment.message}</em>
                }
              </div>
              <input
                type="file"
                id="archiveAttachment"
                name="attachment"
                ref={(ref) => {
                  register(ref);
                  inputFileRef.current = ref;
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <HookTextField
              ref={register}
              label="Description"
              name="archiveDescription"
              error={errors.archiveDescription}
              helperText={errors.archiveDescription?.message ?? null}
              placeholder="e.g: Surat diatas telah disahkan"
              spellcheck={false}
              multiline
            />
          </div>
          <div className="col-12">
            <div className="d-flex flex-row-reverse flex-sm-row">
              <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Update</LoadingBtn>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Archive;
