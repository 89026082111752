import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import Table from '../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, AUDIT_LOG_MODULE, FILTER_TABLE } from '../../../utils/constants/actionTypes';
import { combineBy } from '../../../utils/helpers/combineBy';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ActorSelect from '../../../components/Select/ActorSelect';
import locale from 'react-json-editor-ajrm/locale/en';
import JSONInput from 'react-json-editor-ajrm';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import FormField from '../../../components/Forms/FormField';
import { auditLogApi } from '../../../services/auditLogApi';

const MainTab = () => {
  const dispatch = useDispatch();

  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total,
    filter,
  } = useSelector(state => state.logs.audit);

  const getAuditList = useMemo(
    () => AwesomeDebouncePromise(auditLogApi.list, 500),
    [],
  )

  const load = useCallback(
    () => {
      const search = filter.actor && typeof filter.actor === 'object' ? `actorModel|${filter.actor.actorModel},actor|${filter.actor.value}` : null;

      dispatch({
        module: AUDIT_LOG_MODULE,
        type: MAIN_TAB_LOAD,
        call: getAuditList,
        args: [
          currentPage,
          limit,
          sort,
          search,
          filter.startDate,
          filter.endDate,
        ]
      }).catch(() => { });
    },
    [currentPage, limit, sort, filter, dispatch, getAuditList],
  )

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: AUDIT_LOG_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const table = () => {
    return {
      inProgress,
      data: items,
      sort,
      limit,
      total,
      currentPage,
      collapse: {
        key: 'collapse',
        render: (v, row) => {
          return row.action.method === 'POST' && row.body ?
            <JSONInput
              locale={locale}
              placeholder={row.body}
              theme="light_mitsuketa_tribute"
              width="100%"
              height="300px"
              viewOnly
              confirmGood={false}
              style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
            /> : null
        }
      },
      columns: [
        {
          title: 'Actor', key: 'actor', name: 'actor', sortable: false, render: function (v, row) {
            let str;
            if (v) {
              str = combineBy([v.firstName, v.lastName, `(${row.actorModel})`, row.actorModel === 'User' ? `| ${v.uniqueId}` : '']);
            } else {
              str = '-';
            }
            return str;
          }
        },
        { title: 'Event Name', key: 'eventName', name: 'eventName' },
        {
          title: 'Method', key: 'method', name: 'method', render: (v, row) => row.action.method
        },
        {
          title: 'URL', key: 'action', name: 'action', render: (action, row) => (
            <Fragment>
              <span className={`badge badge-pill ${action.methods === 'GET' ? 'badge-focus' : 'badge-info'}`}>{action.methods}</span> {action.path}
            </Fragment>
          )
        },
        {
          title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
            return format(new Date(v), 'yyyy-MM-dd HH:mm:ss');
          }
        },
      ]
    };
  }

  useEffect(() => {
    load();
  }, [load])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table()}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
          <div className="form-row">
            <div className="col-12 col-md-4 col-lg-3 col-xl">
              <ActorSelect
                value={filter.actor}
                onChange={onFilter('actor')}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl">
              <FormField label="Start Date">
                <EvyDatePicker
                  value={filter.startDate}
                  onChange={onFilter('startDate')}
                  variant="dialog"
                  placeholder="Select date"
                  autoOk
                  clearable={true}
                  withTime
                />
              </FormField>
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl">
              <FormField label="End Date">
                <EvyDatePicker
                  value={filter.endDate}
                  onChange={onFilter('endDate')}
                  variant="dialog"
                  placeholder="Select date"
                  autoOk
                  clearable={true}
                  withTime
                />
              </FormField>
            </div>
          </div>
        </Table>
      </div>
    </div>
  )
}

export default MainTab
