import React, { useState } from 'react'
import { ARTICLE_MODULE, CREATE } from '../../utils/constants/actionTypes'
import Form from './Form'
import { useDispatch } from 'react-redux';
import useMountedState from '../../components/HooksUse/useMountedState';
import AllStatus from '../../utils/constants/enums/status';
import { articleApi } from '../../services/articleApi';

const CreateTab = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useMountedState()

  const onSubmit = (values) => {
    const data = {
      application: values.application,
      type: values.type,
      category: values.category,
      description: values.description,
      name: values.name,
      content: values.content,
      eventDate: values.eventDate,
      active: values.active === AllStatus.ACTIVE,
      isAlwaysActive: values.isAlwaysActive === 'YES',
      periodFrom: values.periodFrom || null,
      periodTo: values.periodTo || null,
      attachment: values.attachment[0]
    };

    setIsLoading(true);
    dispatch({
      module: ARTICLE_MODULE,
      type: CREATE,
      call: articleApi.create,
      args: [data]
    })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  };

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <Form
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default CreateTab
