import React, { Fragment } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import Timeline from '../../../../components/VerticalTimeline/Timeline';

const FailedAttemptCard = ({ item }) => {
  const generateDesc = row => {
    let desc;
    if (row.name === "CLOSED_USER") {
      const arr = row.description.split("||");
      arr.splice(-1, 1);
      desc = arr.map((row, key) => (
        <Fragment key={key}>
          • {row}
          <br />
        </Fragment>
      ))
    } else {
      desc = row.description;
    }

    return (
      <Fragment>
        {desc}
        {row.actor ?
          <b className="d-block text-black-50 mt-3">
            Actor: {combineBy([row.actor.firstName, row.actor.lastName])} [{row.actorModel}]
          </b>
          : ''
        }
      </Fragment>
    )
  }

  const clonedFA = [...(item?.failedAttempts ?? [])].reverse();

  return clonedFA.length > 0 ?
    <Timeline>
      {clonedFA.map((row, k) => (
        <Timeline.Dot
          key={k}
          title={row.name}
          desc={generateDesc(row)}
          date={format(new Date(row.createdAt), 'dd MMM yy HH:mm:ss')}
        />
      ))}
    </Timeline>
    :
    <h5 className="mb-0 p-3 text-center card-title">No data</h5>
}

export default FailedAttemptCard
