import AwesomeDebouncePromise from "awesome-debounce-promise";
import { endOfDay, startOfDay } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentApi } from "../../../../services/paymentApi";
import { MAIN_TAB_LOAD, TRANSACTIONS_QRIS_MPM_MODULE } from "../../../../utils/constants/actionTypes";
import { mccCategoryTypeOpts } from "../../../../utils/constants/enums/mccCategoryTypes";
import { merchantCriteriaTypeOpts } from "../../../../utils/constants/enums/merchantCriteriaTypes";
import OrderStatusOpts from '../../../../utils/constants/enums/status/orderStatusOpts';
import { issuerSwitcherMerchantTypesOpts, switcherMerchantTypesOpts } from '../../../../utils/constants/enums/switcherMerchantTypes';
import { DATE_PICKER, MERCHANT_SELECT, SELECT_FIELD, USER_SELECT, PARTNER_SELECT, TEXT_FIELD } from "../../../../utils/constants/inputTypes";
import { combineBy } from "../../../../utils/helpers/combineBy";
import { toIDR } from "../../../../utils/helpers/currency";
import useAsync from "../../../../components/HooksUse/useAsync";
import Resource from "../../../../components/Resource";
import { columns, excelColumns } from "./columns";
import DetailTab from "./DetailTab";
import PopUp from "../../../../components/PopUp";
import SelectField from "../../../../components/Forms/SelectField";
import { useForm } from "react-hook-form";

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

const schema = yup.object().shape({
  status: yup.string().required()
})

const Index = () => {
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [id, setId] = useState('');

  const dispatch = useDispatch()
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalMdr,
    totalMdrRevenue,
    totalMerchantReceive,
    totalUserPayment
  } = useSelector(state => state.transactions.qrisMPM);

  const {
    setValue,
    handleSubmit,
    errors,
    watch,
    register,
    unregister
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  })

  const {
    value: { data: { nns = [] } = {} } = {},
    pending: isGettingNns
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: paymentApi.listNns }),
    [dispatch]), true)
  const nnsOpts = nns.map(row => ({ value: row.nns, label: row.nnsName }))

  const listDebounced = useMemo(
    () => AwesomeDebouncePromise(paymentApi.listQris, 500),
    [],
  )

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.transactionCode ? `code|${filter.transactionCode}` : false,
      filter.user && typeof filter.user === 'object' ? `applicantModel|User,applicant|${filter.user.value}` : null,
      filter.search ? `metadata.creditRequest.merchantNameLocation|${filter.search},applicant.uniqueId|${filter.search}` : null
    ], ',');
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const merchant = filter.merchant?.value;
    const merchantPartner = filter.merchantPartner?.optData?.companyCode ?? filter?.merchantPartner;
    const connectedPartner = filter.connectedPartner?.optData?.companyCode ?? filter?.connectedPartner;

    return {
      page: currentPage,
      limit,
      sort,
      type: filter.type,
      search,
      startDate,
      endDate,
      status: filter.status,
      switcher: filter.switcher,
      issuer: filter.issuer,
      acquirer: filter.acquirer,
      issuerSWC: filter.issuerSWC,
      acquirerSWC: filter.acquirerSWC,
      oprstatus: filter.oprstatus,
      qrisMode: filter.qrisMode,
      merchant: merchant,
      merchantPartner: merchantPartner,
      merchantCriteria: filter.merchantCriteria,
      mccCategory: filter.mccCategory,
      connectedPartner: connectedPartner,
    }
  }, [filter, currentPage, limit, sort]);

  const {
    status
  } = watch([
    'status'
  ])

  const load = useCallback(() => {
    dispatch({
      module: TRANSACTIONS_QRIS_MPM_MODULE,
      type: MAIN_TAB_LOAD,
      call: listDebounced,
      args: [apiQuery],
    });
  }, [dispatch, listDebounced, apiQuery]);

  const onUpdateStatus = (values) => {
    setIsUpdating(true);
    paymentApi.updateStatus({ id, data: values })
      .then(() => {
        setIsUpdating(false);
        setOpenUpdateStatus(false)
        setCurrentStatus("")
        load()
      })
      .finally(() => {
        setIsUpdating(false);
      })
  }

  useEffect(() => {
    register('status');

    return () => {
      unregister('status')
    }
  }, [register, unregister])

  return (
    <div>
      <Resource
        title="Transactions QRIS MPM"
        subTitle="Full Transactions MPM list."
        icon="pe-7s-ribbon"
        list={{
          columns: columns,
          reducerPath: "transactions.qrisMPM",
          call: listDebounced,
          apiQuery: apiQuery,
          module: TRANSACTIONS_QRIS_MPM_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total User Payment: <b>{toIDR(totalUserPayment)}</b>
              </li>
              <li className="list-group-item px-0">
                Total MDR: <b>{toIDR(totalMdr)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Revenue: <b>{toIDR(totalMdrRevenue)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Merchant Received: <b>{toIDR(totalMerchantReceive)}</b>
              </li>
            </ul>
          ),
          customActionColumns: [{
            // role: {
            //   key: "status",
            //   value: AllStatus.PROCESSING
            // },
            title: "Update Status",
            onClick: (v) => {
              setCurrentStatus(v?.status)
              setOpenUpdateStatus(true)
              setId(v?._id)
            }
          }],
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Merchant_List',
            apiResponseKey: 'data.payments',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: OrderStatusOpts,
              value: filter.status,
            },
            {
              label: "Filter Connected Partner",
              type: PARTNER_SELECT,
              key: "connectedPartner",
              value: filter.connectedPartner || "",
              filterIntegrationType: "COMPANY",
              filterByCompanyCode: true
            },
            {
              label: "Filter Merchant Connected Partner",
              type: PARTNER_SELECT,
              key: "merchantPartner",
              value: filter.merchantPartner,
              filterIntegrationType: "COMPANY",
              filterByCompanyCode: true
            },
            {
              type: USER_SELECT,
              key: "user",
              value: filter.user
            },
            {
              type: MERCHANT_SELECT,
              key: "merchant",
              value: filter.merchant,
              selectProps: {
                search: (search) => `fullName|${search},uniqueId|${search},phoneNumber|${search}`,
                label: (merchant) => `${combineBy([merchant.firstName, merchant.lastName])} | ${merchant.uniqueId} | ${merchant.phoneNumber}`
              }
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
            {
              label: "Filter Switcher",
              type: SELECT_FIELD,
              key: "switcher",
              options: switcherMerchantTypesOpts,
              value: filter.switcher,
            },
            {
              label: "Filter Issuer",
              type: SELECT_FIELD,
              key: "issuer",
              loading: isGettingNns,
              options: nnsOpts,
              value: filter.issuer,
            },
            {
              label: "Filter Acquirer",
              type: SELECT_FIELD,
              key: "acquirer",
              loading: isGettingNns,
              options: nnsOpts,
              value: filter.acquirer,
            },
            {
              label: "Filter Issuer Switcher",
              type: SELECT_FIELD,
              key: "issuerSWC",
              options: issuerSwitcherMerchantTypesOpts,
              value: filter.issuerSWC,
            },
            {
              label: "Filter Acquirer Switcher",
              type: SELECT_FIELD,
              key: "acquirerSWC",
              options: switcherMerchantTypesOpts,
              value: filter.acquirerSWC,
            },
            {
              label: "Filter Merchant Criteria",
              type: SELECT_FIELD,
              key: "merchantCriteria",
              options: merchantCriteriaTypeOpts,
              value: filter.merchantCriteria
            },
            {
              label: "Filter MCC Category",
              type: SELECT_FIELD,
              key: "mccCategory",
              options: mccCategoryTypeOpts,
              value: filter.mccCategory
            },
            {
              label: "Filter Transaction Code",
              type: TEXT_FIELD,
              key: "transactionCode",
              value: filter.transactionCode,
              placeholder: "Search Code"
            }
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />

      <PopUp in={openUpdateStatus} onClose={() => setOpenUpdateStatus(false)}>
        <div className="main-card card">
          <form onSubmit={handleSubmit(onUpdateStatus)}>
            <div className="card-body">
              <h5 className="card-title mb-4">Update Status</h5>
              <div className="position-relative">
                <SelectField
                  className="form-control"
                  options={[
                    { value: "FAILED", label: 'Failed', disabled: Boolean(currentStatus === "FAILED") },
                    { value: "COMPLETED", label: 'Completed', disabled: Boolean(currentStatus === "COMPLETED") }
                  ]}
                  value={status}
                  error={Boolean(errors.status?.message)}
                  helperText={errors.status?.message ?? ''}
                  onChange={(v) => {
                    setValue("status", v?.value, { shouldValidate: true })
                  }}
                  componentProps={{
                    isClearable: true
                  }}
                />
              </div>
              <hr />

              <button
                className={`d-block btn btn-primary ${Boolean(currentStatus === status) ? 'btn-muted' : ''} w-100`}
                loading={isUpdating}
                disabled={Boolean(currentStatus === status)}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </PopUp>
    </div>
  );
};

export default Index;
