import React, { useEffect } from 'react'
import * as yup from 'yup';
import HookTextField from '../../../components/Forms/HookTextField';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { useForm } from 'react-hook-form';
import FormField from '../../../components/Forms/FormField';
import { yupResolver } from '@hookform/resolvers';
import SelectField from '../../../components/Forms/SelectField';
import { rejectReasonOpts } from '../../../utils/constants/enums/rejectReasonTypes';

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  category: yup.string().required(),
});

const Form = ({ submit, isLoading, initialValue }) => {
  const { setValue, watch, register, unregister, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValue
  });
  const { category } = watch(["category"]);

  useEffect(() => {
    register("category");

    return () => {
      unregister("category");
    };
  }, [register, unregister]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Title"
            name="title"
            placeholder="e.g: NAMA FORM TIDAK SESUAI"
            error={errors.title}
            helperText={errors.title ? errors.title.message : null}
            required
          />
          <HookTextField
            ref={register}
            label="Description"
            name="description"
            placeholder="e.g: Mohon Maaf, permintaan anda tidak dapat..."
            error={errors.description}
            helperText={errors.description ? errors.description.message : null}
            multiline
            required
          />
          <SelectField
            label="Select Type"
            name="category"
            placeholder="Select Type"
            options={rejectReasonOpts}
            onChange={(v) =>
              setValue("category", v?.value, { shouldValidate: true })
            }
            value={category}
            error={errors.category}
            helperText={errors.category ? errors.category.message : null}
            required
          />
        </div>
      </div>
      <FormField
        className="mb-0"
        row
      >
        <div className="d-flex flex-row-reverse flex-sm-row">
          <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
        </div>
      </FormField>
    </form>
  )
}

export default Form
