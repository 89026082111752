import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { TransactionType } from '../../../../utils/constants/enums/transactionTypes';
import { combineBy } from '../../../../utils/helpers/combineBy';
import AllStatus from '../../../../utils/constants/enums/status';
import { AdminRole } from '../../../../utils/constants/enums/adminRoles';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getApprover = row => {
  const statusHistories = row.metadata?.injection?.statusHistories ?? [];
  const history = statusHistories.find(row => row.status === AllStatus.COMPLETED) ?? null;
  const actor = history?.actor ?? null;
  if (actor && actor?.firstName) {
    return `${combineBy([actor?.firstName, actor?.lastName])} (${AdminRole.getStr(actor?.role)})`
  } else {
    return `Unknown`
  }
}

const TableTopup = ({ items, period, selectedModel }) => {
  const documentFilename = `Topup-${combineBy([selectedModel.firstName, selectedModel.lastName], "_")}-${format(new Date(period[0]), 'yyyyMMdd')}-${format(new Date(period[1]), 'yyyyMMdd')}`

  const grandTotal = useMemo(() => {
    let amountArr = [];
    let grandTotal = 0;

    items.forEach(v => {
      if (v?.amount && (v.type === TransactionType.TOPUP || v.type === TransactionType.TOPUP_FEE)) {
        if (v?.amount?.primary > 0) grandTotal += +v?.amount?.primary
        else grandTotal += +v?.amount?.point
      } else if (v.metadata?.injection?.amount && v.type === TransactionType.INJECTION_PRIMARY) {
        if (v.metadata?.injection?.amount?.primary > 0) grandTotal += +v.metadata?.injection?.amount?.primary
        else grandTotal += +v.metadata?.injection?.amount?.point
      }
    });

    amountArr = [
      { value: grandTotal }
    ]

    return amountArr;
  }, [items]);

  const columns = useMemo(() => [
    { title: "Trans. No", key: "code", name: "code" },
    {
      title: "From",
      key: "from",
      name: "from",
      render: (v, row) => {
        return Boolean(row.type === TransactionType.TOPUP || row.type === TransactionType.TOPUP_FEE) ?
          `${combineBy(
            [
              row.type === TransactionType.TOPUP
                ?
                row.recipient?.firstName
                :
                row.sender?.firstName,
              row.type === TransactionType.TOPUP
                ?
                row.recipient?.lastName
                :
                row.sender?.lastName
            ]
          )
          } | ${row.type === TransactionType.TOPUP
            ?
            row.recipient?.uniqueId
            :
            row.sender?.uniqueId
          }`
          :
          (row.type === TransactionType.INJECTION_PRIMARY)
            ?
            "Dipay Inject"
            :
            '-'
      }
    },
    {
      title: "To Bank",
      key: "toBankDesc",
      name: "bank",
      render: (v, row) =>
        (row.type === TransactionType.TOPUP || row.type === TransactionType.TOPUP_FEE)
          ?
          row?.toBankDesc ?? `Bank ${row.metadata ? row.metadata.bankCd : ''} VA ${row.metadata ? row.metadata.vacctNo : ''}`
          :
          (row.type === TransactionType.INJECTION_PRIMARY)
            ?
            `${combineBy([row.recipient?.firstName, row.recipient?.lastName])} | ${row.recipient?.uniqueId}`
            :
            '-'
    },
    {
      title: "Appr. Date",
      key: "createdAt",
      name: "createdAt",
      render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      title: "Approved By",
      key: "approver",
      name: "approver",
      render: (v, row) => (row.type === TransactionType.TOPUP || row.type === TransactionType.TOPUP_FEE)
        ?
        "SYSTEM"
        :
        (row.type === TransactionType.INJECTION_PRIMARY)
          ?
          getApprover(row)
          :
          '-'
    },
    {
      title: "Top up",
      key: "amount",
      name: "amount",
      withCurrency: true,
      render: (v, row) =>
        (row.type === TransactionType.TOPUP || row.type === TransactionType.TOPUP_FEE)
          ?
          row?.amount?.primary ?? '-'
          :
          (row.type === TransactionType.INJECTION_PRIMARY)
            ?
            row.metadata?.injection?.amount?.primary ?? '-'
            :
            '-'
    }
  ], [])

  return (
    <ReportTableTemplateV2
      title="Top Up"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      printProps={{
        documentFilename: documentFilename
      }}
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableTopup
