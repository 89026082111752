import {
  APP_LOAD,
  LOGOUT,
  REFRESH_TOKEN,
  CLEAN_AUTH_STATE,
  INIT_SOCKET,
  VERIFY_OTP,
} from '../../utils/constants/actionTypes';
import io from "socket.io-client";
import { decrypt } from '../../utils/helpers/crypto';

const socketEndPoint = process.env.REACT_APP_SOCKET_URL;
const socket = io(socketEndPoint);

const defaultState = {
  appName: 'Dipay',
  token: null,
  refreshToken: null,
  currentAdmin: null,
  socket: socket
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true,
        token: action.token || null,
        currentAdmin: !action.error && action.payload ? action.payload.data.admin : state.currentAdmin,
      };
    case LOGOUT:
      return { ...state, token: null, currentAdmin: null };
    case VERIFY_OTP: {
      let currentAdmin = null;
      if (!action.error && action.payload) {
        const { firstName, lastName } = action.payload.data.admin
        currentAdmin = {
          ...action.payload.data.admin,
          firstName: decrypt(firstName),
          lastName: lastName ? decrypt(lastName) : lastName
        }
      }
      return {
        ...state,
        token: !action.error && action.payload ? action.payload.data.accessToken : null,
        refreshToken: !action.error && action.payload ? action.payload.data.refreshToken : null,
        currentAdmin,
      };
    }
    case REFRESH_TOKEN:
      return {
        ...state,
        token: !action.error && action.payload ? action.payload.data.accessToken : null,
        refreshToken: !action.error && action.payload ? action.payload.data.refreshToken : null,
        currentAdmin: !action.error && action.payload ? action.payload.data.admin : null,
      }
    case CLEAN_AUTH_STATE:
      return {
        ...state,
        token: null,
        refreshToken: null,
        currentAdmin: null,
      }
    case INIT_SOCKET:
      return {
        ...state,
        socket: action.socket
      }
    default:
      return state;
  }
};

export default reducer
