import React, { useEffect } from 'react'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import EvySelectField from '../../../components/Forms/EvySelectField';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import LoadingBtn from '../../../components/Forms/LoadingBtn';

const schema = yup.object().shape({
  name: yup.string().required(),
  quota: yup.string().required(),
  isActive: yup.string().required(),
  startPeriod: yup.string().required(),
  endPeriod: yup.string().required()
})

const MyForm = ({ initialValues, onSubmit, isLoading }) => {
  const { register, unregister, setValue, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialValues?.name,
      quota: initialValues?.quota,
      isActive: initialValues?.isActive ?? "true",
      startPeriod: initialValues?.startPeriod,
      endPeriod: initialValues?.endPeriod
    }
  });

  const {
    isActive,
    startPeriod,
    endPeriod
  } = watch([
    'isActive',
    "startPeriod",
    "endPeriod"
  ]);

  useEffect(() => {
    register("isActive")
    register("startPeriod")
    register("endPeriod")

    return () => {
      unregister("isActive")
      unregister("startPeriod")
      unregister("endPeriod")
    };
  }, [register, unregister]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Referral Code"
            name="name"
            error={errors.name}
            helperText={errors?.name?.message ?? null}
            placeholder="e.g: VCR2019"
          />
          <HookTextField
            ref={register}
            label="Quota"
            name="quota"
            error={errors.quota}
            helperText={errors?.quota?.message ?? null}
            placeholder="e.g: 50"
          />
          <EvySelectField
            label="Select Status"
            options={[
              {
                value: "true",
                label: "Active"
              },
              {
                value: "false",
                label: "Inactive"
              }
            ]}
            onChange={(v) => setValue('isActive', v, { shouldValidate: true })}
            value={isActive}
            error={errors.isActive}
            helperText={errors?.isActive?.message ?? null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Period</h5>
        </div>
        <div className="col-12 col-md-9">
          <div className="date-range">
            <div>
              <EvyDatePicker
                autoOk
                disableToolbar
                variant="static"
                format="dd/MM/yyyy"
                value={startPeriod}
                onChange={(v) => setValue("startPeriod", v, { shouldValidate: true })}
                error={errors.startPeriod}
                helperText={errors?.startPeriod?.message ?? null}
              />
            </div>
            <div>
              <EvyDatePicker
                autoOk
                disableToolbar
                variant="static"
                format="dd/MM/yyyy"
                value={endPeriod}
                onChange={(v) => setValue("endPeriod", v, { shouldValidate: true })}
                error={errors.endPeriod}
                helperText={errors?.endPeriod?.message ?? null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MyForm
