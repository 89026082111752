import React from "react";
import RoundBadge from "../../../components/Badge/RoundBadge";
import HorizontalBadge from "../../../components/Badge/HorizontalBadge";
import SearchIcon from "@material-ui/icons/Search";
import BlockIcon from "@material-ui/icons/Block";
import WorkOffIcon from "@material-ui/icons/WorkOff";
import LockIcon from '@material-ui/icons/Lock';
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CancelIcon from "@material-ui/icons/Cancel";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import { format } from "date-fns";

const BusinessStatuses = ({ status = {}, item }) => {
  const getNewMerchant = () => (
    status.isNewMerchant
    &&
    <HorizontalBadge
      className="mr-2"
      title='New merchant'
      variant="danger"
      active={status.isNewMerchant}
      contentInActive={<div className='mr-3'></div>}
      content="NEW"
    />
  );

  const getChecked = () => (
    <RoundBadge
      title={
        status.checked
          ? `Checked at: ${format(
            new Date(status.checkedAt),
            "yyyy-MM-dd HH:mm:ss"
          )}`
          : "Not Checked"
      }
      active={status.checked}
      variant="warning"
      icon={SearchIcon}
    />
  );

  const getApproved = () => {
    if (status?.rejected && status?.rejectedAt && !status.approved) {
      return (
        <RoundBadge
          title={
            status.rejected
              ? `Rejected at: ${format(
                new Date(status.rejectedAt),
                "yyyy-MM-dd HH:mm:ss"
              )}`
              : "Not Rejected"
          }
          active={true}
          variant="danger"
          icon={ThumbDownIcon}
        />
      );
    }
    return (
      <RoundBadge
        title={
          status.approved
            ? `Approved at: ${status.approvedAt
              ? format(new Date(status.approvedAt), "yyyy-MM-dd HH:mm:ss")
              : "-"
            }`
            : "Not Approved"
        }
        active={status.approved}
        variant="primary"
        icon={ThumbUpIcon}
      />
    );
  };

  const getVerified = () => (
    <RoundBadge
      title={status.verified ? "Business Verified" : "Not Verified"}
      active={status.verified}
      variant="primary"
      icon={VerifiedUserIcon}
    />
  );

  const getSuspected = () => (
    <RoundBadge
      title={status.suspended ? "Business Suspected" : "Not Suspected"}
      active={status.suspended}
      variant="danger"
      icon={BlockIcon}
    />
  );

  const getClosed = () => (
    <RoundBadge
      title={status.closed ? "Business Closed" : "Not Closed"}
      active={status.closed}
      variant="danger"
      icon={CancelIcon}
      count={item.closedCount}
    />
  );

  const getLocked = () => (
    <RoundBadge
      title={status.locked ? "Business Locked" : "Not Locked"}
      active={status.locked}
      variant="danger"
      icon={LockIcon}
    />
  );

  const getBlacklist = () => (
    <RoundBadge
      title={status.blacklist ? "Business Blacklist" : "Not Blacklist"}
      active={status.blacklist}
      variant="danger"
      icon={WorkOffIcon}
    />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {getNewMerchant()}
      {getChecked()}
      {getApproved()}
      {getVerified()}
      {getLocked()}
      {getSuspected()}
      {getClosed()}
      {getBlacklist()}
    </div>
  );
};

export default BusinessStatuses;
