import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { promoConfigApi } from '../../../services/promoConfigApi';
import { GET_DETAIL, PROMO_CONFIG_MODULE, UPDATE } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import Form from './Form';

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs } = useSelector(state => state.promoConfig);
  const { loading, key } = tabs[index];
  const [isLoading, setIsLoading] = useState(false);

  const {
    value: { data: { promoConfig: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: PROMO_CONFIG_MODULE,
      type: GET_DETAIL,
      call: promoConfigApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const submit = (values) => {
    setIsLoading(true);

    const data = {
      ...values,
      maxAmount: values.subType === "FIXED" ? values.value : values.maxAmount
    }

    dispatch({
      module: PROMO_CONFIG_MODULE,
      type: UPDATE,
      call: promoConfigApi.update,
      args: [key, data],
      tabKey: index
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return item ?
    <Form
      initialValue={item}
      onSubmit={submit}
      isLoading={loading || isLoading}
      productSubCategory={item?.product.category}
    />
    :
    <div className="card">
      <div className="card-body">
        <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
      </div>
    </div>
}

export default UpdateTab
