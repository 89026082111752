import React from 'react'
const FormField = ({
  id,
  className,
  label,
  children,
  error,
  helperText,
  required,
  row,
  append: Append
}) => {
  return (
    <div className={`input-root form-group${row ? ' row' : ''}${error ? ' is-error' : ''}${required ? ' required' : ''}${className ? ' ' + className : ''}`}>
      <label className={row ? 'col-form-label col-sm-3 text-md-right' : (label ? '' : 'mb-0')} htmlFor={id}>{label}</label>
      <div className={`input-wrapper${row ? ' col-sm-9' : ''}`}>
        {children}
        {error ? <em className="invalid-feedback">{helperText}</em> : <small className="form-text text-muted">{helperText}</small>}
      </div>
      {Append &&
        <div className="mt-2">
          {Append}
        </div>
      }
    </div>
  )
}

export default FormField
