import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  CREATE_TAB,
  CLOSE_TAB,
  INJECTION_BULKING_MODULE,
  CREATE,
  APPROVE_OR_REJECT,
} from '../../utils/constants/actionTypes';
import { closeTab, createTab } from '../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'createdAt|desc',
  currentPage: 1,
  activeTabKey: 0,
  tabs: [],
};

const reducer = (state = defaultState, action) => {
  if (action.module !== INJECTION_BULKING_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      let items = action.payload && action.payload.data ? action.payload.data.banners : [];
      return {
        ...state,
        items: items,
        total: action.payload && action.payload.data ? action.payload.data.count : 0,
      };
    case CREATE_TAB:
      const newTabs = createTab(state.tabs, action.key, action.title, { item: state.items.find(b => b._id === action.key) });
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case CREATE:
      return {
        ...state,
        activeTabKey: action.error ? state.activeTabKey : 0,
      }
    case APPROVE_OR_REJECT:
      const tabs = state.tabs.filter((_, key) => key !== action.tabKey);
      return {
        ...state,
        tabs,
        activeTabKey: 0,
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
            refresh: false
          };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
