import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';
import { combineBy } from '../../../../utils/helpers/combineBy';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getWalletType = (obj) => {
  for (let key in obj) {
    if (obj[key] > 0) return key
  }
}

const getNoPelanggan = (row) => {
  let biller = row?.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return row.metadata?.payment?.metadata?.ppobResponse?.msisdn ?? '-';
  else if (biller === "RAJABILLER") return row.metadata?.payment?.metadata?.ppobResponse?.nohp ?? '-';
  else if (biller === "TOKOPEDIA") return row.metadata?.payment?.metadata?.ppobResponse?.data?.attributes?.nomor ?? '-';
  return '-'
}

const getTagihan = (row) => {
  let biller = row?.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return row.metadata?.payment?.metadata?.ppobResponse?.price ?? '-';
  else if (biller === "RAJABILLER") return row.metadata?.payment?.metadata?.ppobResponse?.nominal ?? '-'
  else if (biller === "TOKOPEDIA") return row.metadata?.payment?.metadata?.ppobResponse?.data?.attributes?.sales_price ?? '-'
  return '-'
}

const TableBillerPulsa = ({ items, period }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let sumHargaBiller = 0;
    let sumRevenue = 0;
    let sumTotal = 0;

    items.forEach(i => {
      sumHargaBiller += +getTagihan(i) || 0;
      sumRevenue += +i.metadata?.payment?.metadata?.ppob?.revenue || 0;
      sumTotal += +i.metadata?.payment?.amount || 0;
    });

    amountArr = [
      { value: sumHargaBiller },
      { value: sumRevenue },
      { value: sumTotal }
    ]
    return amountArr;
  }, [items]);

  const columns = useMemo(() => [
    { title: "Date", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: "Trans. No", key: "code", name: "code" },
    { title: "Type", key: "metadata.payment.metadata.ppob", name: "type", render: (v) => PpobType.getStrIdn(v.type) },
    { title: "Status", key: "metadata.payment.status", name: "Status" },
    { title: "Nama", key: "sender", name: "nama", render: v => combineBy([v.firstName, v.lastName]) },
    { title: "Payment", key: "payment", name: "payment", render: (v, row) => getNoPelanggan(row) },
    { title: "Wallet", key: "amount", name: "amount", render: v => getWalletType(v) },
    { title: "Harga Biller", key: "", name: "biller", withCurrency: true, render: (v, row) => getTagihan(row) },
    { title: "Revenue", key: "revenue", name: "revenue", withCurrency: true, render: (v, row) => row?.metadata?.payment?.metadata?.ppob?.revenue ?? '-' },
    { title: "Total", key: "total", name: "total", withCurrency: true, render: (v, row) => row.metadata?.payment?.amount ?? '-' }
  ], [])

  return (
    <ReportTableTemplateV2
      title="Biller Pulsa"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableBillerPulsa;
