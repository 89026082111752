import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DONATION_NEWS_MODULE, GET_DETAIL } from '../../../../../utils/constants/actionTypes'
import { format } from 'date-fns';
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker';
import NewsCard from './NewsCard';
import useMountedState from '../../../../../components/HooksUse/useMountedState';
import useAsync from '../../../../../components/HooksUse/useAsync';
import AllStatus from '../../../../../utils/constants/enums/status';
import { donationNewsApi } from '../../../../../services/donationNewsApi';

const DetailTab = ({ index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const { key, loading } = useSelector(state => state.donation.news.tabs[index]);

  const {
    execute: getDetail,
    value: { data: { donationNews: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: DONATION_NEWS_MODULE,
      type: GET_DETAIL,
      call: donationNewsApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const handleApprove = () => {
    setIsLoading(true);
    dispatch({
      type: null,
      call: donationNewsApi.approve,
      args: [key, item.requestUpdates?.uniqueId],
    })
      .then(() => { if (isMounted()) getDetail() })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  const handleReject = () => {
    setIsLoading(true);
    dispatch({
      type: null,
      call: donationNewsApi.reject,
      args: [key, item.requestUpdates?.uniqueId],
    })
      .then(() => { if (isMounted()) getDetail() })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) });
  }

  const getClass = status => {
    let className = 'badge-primary';
    if (status === AllStatus.PROCESSING) {
      className = 'badge-warning'
    } else if (status === AllStatus.APPROVED) {
      className = 'badge-success'
    } else if (status === AllStatus.REJECTED || status === AllStatus.INACTIVE) {
      className = 'badge-danger'
    }
    return className
  }

  return loading && !item ?
    null
    :
    item ?
      <>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">Partner Info</h5>
                <div className="form-group">
                  <label className="text-grey mb-1">Name</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.name}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">email</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.email}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Phone Number</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.phoneNumber}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Field</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.field}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Integration Type</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.partner?.integrationType}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">Donation Info</h5>
                <div className="form-group">
                  <label className="text-grey mb-1">Request Number</label>
                  <p className="mb-0 font-weight-semibold">{item.donation?.noRequest}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Created At</label>
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.donation?.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Last Updated At</label>
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.donation?.updatedAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
                <div className="row form-group align-items-center">
                  <div className="col-4">
                    <label className="text-grey mb-0">Current Status</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className={`badge ${getClass(item.donation?.status)}`}>{item.donation?.status}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">News Info</h5>
                <div className="form-group">
                  <label className="text-grey mb-1">Created At</label>
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
                <div className="form-group">
                  <label className="text-grey mb-1">Last Updated At</label>
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.updatedAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
                <div className="row form-group align-items-center">
                  <div className="col-4">
                    <label className="text-grey mb-0">Current Status</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className={`badge ${getClass(item.status)}`}>{item.status}</span>
                  </div>
                </div>

                <hr></hr>

                {
                  (item.status === AllStatus.PROCESSING) &&
                  <div className="mb-3">
                    <button
                      type="button"
                      className="btn btn-outline-danger mr-2"
                      onClick={handleReject}
                    >
                      Reject
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleApprove}
                    >
                      Approve
                    </button>
                  </div>
                }

                <div className="row">
                  <div className="col-md-6">
                    <h5 className="card-title mb-3">Current:</h5>
                    <NewsCard news={item} />
                  </div>
                  {
                    item.requestUpdates &&
                    <div className="col-md-6">
                      <h5 className="card-title mb-3">Request Update:</h5>
                      <NewsCard news={item.requestUpdates} />
                    </div>
                  }
                </div>
                <LoadingBlocker in={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
