import { yupResolver } from '@hookform/resolvers';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { format } from 'date-fns';
import { notificationApi } from '../../services/notificationApi';
import { CHANGE_TAB, IMAGE_FORMATS, NOTIFICATION_MODULE, UPDATE } from '../../utils/constants/actionTypes';
import { notificationsTypesOpts } from '../../utils/constants/enums/notificationsTypes';
import FormField from '../../components/Forms/FormField';
import HookImage from '../../components/Forms/HookImage';
import HookTextField from '../../components/Forms/HookTextField';
import LoadingBtn from '../../components/Forms/LoadingBtn';
import SelectField from '../../components/Forms/SelectField';
import MerchantSelect from '../../components/Select/MerchantSelect';
import UserSelect from '../../components/Select/UserSelect';
import EvyDatePicker from '../../components/Forms/EvyDatePicker';

const schema = yup.object().shape({
  title: yup.string().required().max(48, 'Must be exactly 48 digits'),
  desc: yup.string().required().max(250, 'Must be exactly 250 digits'),
  notificationType: yup.string()
    .when("userType", {
      is: "user",
      then: yup.string().required()
    }),
  action: yup.string()
    .when("userType", {
      is: "user",
      then: yup.string()
    }),
  isScheduling: yup.string()
    .when("userType", {
      is: "user",
      then: yup.string()
    }),
  period: yup.string()
    .when("isScheduling", {
      is: isScheduling => isScheduling === '1',
      then: yup.string().required()
    }),
  user: yup.string()
    .when("userType", {
      is: "user",
      then: yup.string().required()
    }),
  merchant: yup.string()
    .when("userType", {
      is: "merchant",
      then: yup.string().required()
    }),
  userType: yup.string().required(),
  notifImage: yup.mixed()
    .when('hasData', {
      is: hasData => !hasData,
      otherwise: yup => yup.nullable(),
    })
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),
  hasData: yup.string()
    .when("userType", {
      is: "user",
      then: yup.string()
    }),
  loginStatus: yup.string().required(),
  deviceType: yup.string().required()
});

const customOptions = [
  { label: 'SELECT ALL', value: 'ALL' },
  { label: 'SELECT ALL UPGRADED', value: 'ALL_UPGRADED' },
  { label: 'SELECT ALL NOT UPGRADED', value: 'ALL_NOT_UPGRADED' },
]

const FormTab = ({
  type,
}) => {
  const handleTab = type => key => dispatch({ module: NOTIFICATION_MODULE, type: type, key: key });
  const dispatch = useDispatch()

  const { register, handleSubmit, reset, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      userType: type,
      isScheduling: '0'
    }
  });

  const [isUpdating, setIsUpdating] = useState(false)

  const submit = (values) => {
    let data;

    if (type === 'user') {
      data = {
        title: values.title,
        type: values.notificationType,
        action: values.action,
        image: values.notifImage[0],
        body: values.desc,
        isScheduling: values.isScheduling,
        periodeSchedule: values.period,
        activeType: values.loginStatus,
        deviceType: values.deviceType,
        cases: {
          sms: {
            enabled: false
          },
          push: {
            enabled: true
          },
          email: {
            enabled: false
          }
        },
        data: {},
        user: values.user || null,
      }
    }
    if (type === 'merchant') {
      data = {
        title: values.title,
        body: values.desc,
        activeType: values.loginStatus,
        deviceType: values.deviceType,
        isScheduling: values.isScheduling,
        periodeSchedule: values.period,
        cases: {
          sms: {
            enabled: false
          },
          push: {
            enabled: true
          },
          email: {
            enabled: false
          }
        },
        data: {},
        merchant: values.merchant || null,
      }
    }

    setIsUpdating(true);
    dispatch({
      module: NOTIFICATION_MODULE,
      type: UPDATE,
      call: notificationApi.create,
      args: [data]
    })
      .then(() => {
        toast("Notification Successfully sent", { type: 'success' });
        handleTab(CHANGE_TAB)(0);
        reset(values);
      })
      .catch(() => {
        toast("Notification failed", { type: 'error' });
      })
      .finally(() => {
        setIsUpdating(false);
      })
  }

  const {
    user,
    merchant,
    loginStatus,
    deviceType,
    notificationType,
    isScheduling,
    period
  } = watch([
    'user',
    'merchant',
    'loginStatus',
    'deviceType',
    'notificationType',
    'isScheduling',
    'period'
  ]);

  useEffect(() => {
    register('user')
    register('merchant')
    register('userType')
    register('loginStatus')
    register('deviceType')
    register('notificationType')
    register('action')
    register('isScheduling')
    register('period')

    return () => {
      unregister('user')
      unregister('merchant')
      unregister('userType')
      unregister('loginStatus')
      unregister('deviceType')
      unregister('notificationType')
      unregister('action')
      unregister('isScheduling')
      unregister('period')
    }
  }, [register, unregister])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(submit)}>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Notification Information</h5>
            </div>
            <div className="col-12 col-md-9">
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Title"
                name="title"
                error={errors.title}
                helperText={errors.title?.message}
                placeholder="e.g: Dipay reborn dengan 200% CASHBACK!!!"
                maxLength={49}
              />
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Short desc"
                name="desc"
                error={errors.desc}
                helperText={errors.desc?.message}
                placeholder="e.g: Ayo pakai Dipay terus, CASHBACK 200% bakal buat kamu kaya mulai hari ini juga!"
                multiline
                maxLength={251}
              />
              {
                type &&
                  type === 'user'
                  ?
                  <HookTextField
                    ref={register}
                    autoComplete="off"
                    label="Action"
                    name="action"
                    error={errors.action}
                    helperText={errors.action?.message}
                    placeholder="e.g: VERIFICATION_EMAIL"
                  /> : null
              }
              <div className={clsx(type === 'user' ? '' : 'd-none')}>
                <HookImage
                  ref={register}
                  label="Image"
                  name="notifImage"
                  format={IMAGE_FORMATS}
                  error={errors.notifImage}
                  helperText={errors?.notifImage?.message ?? null}
                  onClear={() => setValue('notifImage', null, { shouldValidate: true })}
                  viewVariant="landscape"
                />
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12 col-md-3'>
              <h5 className='card-title'>Scheduling</h5>
            </div>
            <div className='col-12 col-md-9'>
              <RadioGroup
                row
                name="isScheduling"
                value={isScheduling}
                onChange={e => setValue('isScheduling', e.target.value)}
              >
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Enable" />
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Disable" />
              </RadioGroup>
              {
                isScheduling === "1"
                &&
                <FormField label="Schedule Date">
                  <EvyDatePicker
                    withTime
                    value={period}
                    onChange={e => setValue('period', format(new Date(e), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), { shouldValidate: true })}
                    variant="dialog"
                    placeholder="Select date"
                    clearable={false}
                    autoOk
                    minDate={new Date()}
                  />
                </FormField>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <h5 className="card-title">Filter</h5>
            </div>
            <div className="col-12 col-md-9">
              {
                type &&
                  type === 'user'
                  ?
                  <SelectField
                    label="Type"
                    name="notificationType"
                    options={notificationsTypesOpts}
                    onChange={v => setValue('notificationType', v?.value, { shouldValidate: true })}
                    value={notificationType}
                    error={errors.notificationType}
                    helperText={errors.notificationType?.message ?? null}
                  /> : null
              }
              <div className="row">
                {
                  type &&
                    type === "user"
                    ?
                    <div className="col-12">
                      <UserSelect
                        name="user"
                        customOptions={customOptions}
                        value={user}
                        onChange={(v) => setValue('user', v, { shouldValidate: true })}
                        error={errors.user}
                        helperText={errors.user?.message}
                      />
                    </div>
                    :
                    <div className="col-12">
                      <MerchantSelect
                        name="merchant"
                        customOptions={customOptions}
                        value={merchant}
                        onChange={(v) => setValue('merchant', v, { shouldValidate: true })}
                        error={errors.merchant}
                        helperText={errors.merchant?.message}
                      />
                    </div>
                }
              </div>
              <FormField
                label="Login Status"
                error={errors.loginStatus}
                helperText={errors.loginStatus?.message}
              >
                <RadioGroup
                  row
                  name="loginStatus"
                  value={loginStatus}
                  onChange={e => setValue("loginStatus", e.target.value, { shouldValidate: true })}
                >
                  <FormControlLabel value="ACTIVE" control={<Radio color="primary" />} label="ACTIVE" />
                  <FormControlLabel value="INACTIVE" control={<Radio color="primary" />} label="INACTIVE" />
                </RadioGroup>
              </FormField>
              <FormField
                label="Device Type"
                error={errors.deviceType}
                helperText={errors.deviceType?.message}
              >
                <RadioGroup
                  row
                  name="deviceType"
                  value={deviceType}
                  onChange={e => setValue("deviceType", e.target.value, { shouldValidate: true })}
                >
                  <FormControlLabel value="iOS" control={<Radio color="primary" />} label="iOS" />
                  <FormControlLabel value="Android" control={<Radio color="primary" />} label="Android" />
                  <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                </RadioGroup>
              </FormField>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn type="submit" loading={isUpdating} className="btn btn-primary">Submit</LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FormTab
