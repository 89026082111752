import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const TableVoucher = ({ items, period }) => {
  const [isPrimary, setIsPrimary] = useState(false);

  const filteredItems = useMemo(() =>
    items.filter(row => row?.status === "USED")
    , [items]);

  const grandTotal = useMemo(() => {
    let amountArr = [];
    let grandTotal = 0;

    filteredItems.forEach(v => {
      grandTotal += v?.transaction?.amount?.primary > 0 ? v?.transaction?.amount?.primary : v?.transaction?.amount?.point;
    });

    amountArr = [
      { value: grandTotal }
    ]

    return amountArr;
  }, [filteredItems]);

  const columns = useMemo(() => {
    return [
      { title: "Created At", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Code", key: "code", name: "code" },
      { title: "Type", key: "tnc", name: "type", render: (v) => v?.redeem ? "REDEEM" : "CASHBACK" },
      { title: "Masa Berlaku", key: "expiredAt", name: "expiredAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Used By", key: "user", name: "userBy", render: (v, row) => row?.user ? `${combineBy([row.user.firstName, row.user.lastName])} | ${row.user.uniqueId}` : '-' },
      { title: "Status", key: "status", name: "status" },
      { title: "Wallet", key: "wallet", name: "wallet", render: (v, row) => row?.transaction?.amount?.primary > 0 ? 'PRIMARY' : 'POINT' },
      {
        title: "Amount", key: "amount", name: "amount", withCurrency: isPrimary, render: (v, row) => {
          setIsPrimary(Boolean(row?.transaction?.amount?.primary > 0))
          return row?.transaction?.amount ?
            row?.transaction?.amount?.primary > 0 ?
              row?.transaction?.amount?.primary
              : row?.transaction?.amount?.point
            : '-'
        }
      }
    ]
  }, [isPrimary])

  return (
    <ReportTableTemplateV2
      title="Voucher"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: filteredItems,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableVoucher
