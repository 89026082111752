import React from 'react'
import * as yup from 'yup';

import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import HookTextField from '../../../components/Forms/HookTextField';

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  value: yup.string().required(),
});

const FormTab = ({
  initialValue,
  isLoading,
  onSubmit
}) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValue
  });

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
              <h5 className="card-title">General Information</h5>
            </div>
            <div className="col-12 col-md-9">
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Name"
                name="name"
                error={errors.name}
                helperText={errors.name ? errors.name.message : null}
                placeholder="e.g: MOBILE_ANDROID_VERSION"
                readOnly={initialValue?._id ? true : false}
              />
              <HookTextField
                ref={register}
                autoComplete="off"
                label="Description"
                name="description"
                error={errors.description}
                helperText={errors.description ? errors.description.message : null}
                placeholder="e.g: Mobile config for E-Wallet"
              />
              <HookTextField
                ref={register}
                label="Value"
                name="value"
                error={errors.value}
                helperText={errors.value ? errors.value.message : null}
                placeholder="e.g: 1.1"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-9">
              <div className="d-flex flex-row-reverse flex-sm-row">
                <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FormTab;
