import Fade from '@material-ui/core/Fade';
import { endOfMonth } from 'date-fns';
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux';
import { generateDataApi } from '../../../../services/generateDataApi';
import EvyDatePicker from '../../../../components/Forms/EvyDatePicker';
import FormField from '../../../../components/Forms/FormField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import TableComponent from './Table';

const MainTab = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(null)
  const [items, setItems] = useState(null)

  const submit = useCallback(() => {
    setLoading(true)
    dispatch({ type: null, call: generateDataApi.generateDataQRISToBI, args: [{ period: endOfMonth(new Date(date)).toISOString() }] })
      .then(({ data }) => {
        setItems(data)
      })
      .catch(() => { })
      .finally(() => setLoading(false))
  }, [dispatch, date])

  return (
    <div className="row">
      <div className="col-12">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <FormField
              label="Date Filter"
            >
              <EvyDatePicker
                autoOk
                openTo="month"
                views={["month", "year"]}
                variant="inline"
                value={date}
                onChange={v => setDate(new Date(v))}
              />
            </FormField>
            <LoadingBtn
              className="btn btn-primary btn-block btn-lg mt-4"
              loading={loading}
              type="button"
              disabled={!date || loading}
              onClick={submit}
            >
              Submit
            </LoadingBtn>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="report-table-wrapper">
          {
            items &&
            (
              items.length ?
                <TableComponent items={items} period={date} setLoading={setLoading} />
                :
                <h5 className="mb-0 px-3 py-4 text-center">No Data</h5>
            )
          }
          <Fade in={loading} timeout={300}>
            <div className="loader">
              <div className="line-scale-pulse-out">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}


export default MainTab
