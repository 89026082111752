import React, { Component } from 'react';
import { BusinessStatusHistoriesType } from '../../utils/constants/enums/businessStatusHistoriesTypes';

const Dot = ({
  title,
  desc,
  date,
  remarks
}) => {
  return (
    <div className="vertical-timeline-item vertical-timeline-element">
      <div>
        <span className="vertical-timeline-element-icon">
          <i className={`badge badge-dot badge-dot-xl ${BusinessStatusHistoriesType.getColor(title)}`}> </i>
        </span>
        <div className="vertical-timeline-element-content">
          <h4 className="timeline-title">{BusinessStatusHistoriesType.getStr(title)}</h4>
          <p>{desc}</p>
          {remarks ?
            <p>Remark: {remarks}</p>
            : null
          }
          <span className="vertical-timeline-element-date">{date}</span>
        </div>
      </div>
    </div>
  )
}

class BusinessTimeline extends Component {
  static Dot = Dot;

  render() {
    return (
      <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
        {this.props.children}
      </div>
    )
  }
}

export default BusinessTimeline
