class ProductCategoryType {
  static BANKTRANSFER = 'BANKTRANSFER';
  static PPOB = 'PPOB';
  static QRIS = 'QRIS';
  static PARTNER = 'PARTNER';

  static getStr(en) {
    switch (en) {
      case ProductCategoryType.BANKTRANSFER:
        return 'Bank Transfer';
      case ProductCategoryType.PPOB:
        return 'PPOB';
      case ProductCategoryType.QRIS:
        return 'Merchant';
      case ProductCategoryType.PARTNER:
        return 'Partner';
      default:
        return 'Unknown';
    }
  }
}

const ProductCategoryTypeOpts = [
  { value: ProductCategoryType.BANKTRANSFER, label: ProductCategoryType.getStr(ProductCategoryType.BANKTRANSFER) },
  { value: ProductCategoryType.PPOB, label: ProductCategoryType.getStr(ProductCategoryType.PPOB) },
  { value: ProductCategoryType.QRIS, label: ProductCategoryType.getStr(ProductCategoryType.QRIS) },
  { value: ProductCategoryType.PARTNER, label: ProductCategoryType.getStr(ProductCategoryType.PARTNER) },
];

export { ProductCategoryType, ProductCategoryTypeOpts };
