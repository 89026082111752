import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const blogApi = {
  list: ({ page, limit, sort }) => {
    let url = `/blog/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => agent.requests.post("/blog/create", data, true),
  update: (id, data) => agent.requests.patch(`/blog/${id}/update`, data, true),
  delete: (id) => agent.requests.delete(`/blog/${id}/delete`),

  uploadImage: (files) => agent.requests.post(`/files/upload`, { files }, true),
  uploadList: ({ page, limit, sort }) => {
    let url = `/files/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    url += `&type=LOCAL`;

    return agent.requests.get(url)
  },
  deleteUpload: (id) => agent.requests.delete(`/files/${id}/upload`)
}
