import React from "react";
import { Helmet } from "react-helmet";
import MainTab from "./MainTab";

const Index = () => (
  <div className="app-main__inner">
    <Helmet>
      <title>QRIS Rintis Recon</title>
    </Helmet>
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className="page-title-icon">
            <i className="pe-7s-note2 icon-gradient bg-sunny-morning"></i>
          </div>
          <div>
            QRIS Rintis Recon
            <div className="page-title-subheading">View QRIS Rintis Recon</div>
          </div>
        </div>
      </div>
    </div>
    <MainTab />
  </div>
)

export default Index;
