class applicablePromoTypes {
  static ALL_USER = 'ALL_USER';
  static PER_USER = 'PER_USER';

  static getStr(en) {
    switch (en) {
      case applicablePromoTypes.ALL_USER:
        return 'All User';
      case applicablePromoTypes.PER_USER:
        return 'Per User';
      default:
        return 'Unknown';
    }
  }
}

const applicablePromoTypesOpts = [
  { value: applicablePromoTypes.ALL_USER, label: applicablePromoTypes.ALL_USER },
  { value: applicablePromoTypes.PER_USER, label: applicablePromoTypes.PER_USER },
];

export { applicablePromoTypes, applicablePromoTypesOpts };
