import React from 'react'
import getPropByString from '../../../../../utils/helpers/getPropByString'

const ExcelTable = ({
  withIndex,
  columns,
  data,
  grandTotal,
  totalSpan,
  withNotes
}) => {
  return (
    <>
      <table border="1">
        <thead>
          <tr>
            {withIndex && <th>#</th>}
            {columns.map((col, key) => {
              return !col.hide &&
                <th key={key} align={col.align}>
                  {col.title}
                </th>
            })}
          </tr>
        </thead>
        <tbody>
          {
            data.map((row, key) => {
              return (
                <tr key={key}>
                  {withIndex && <td>{key + 1}</td>}
                  {
                    columns.map((col, i) =>
                      !col.hide ?
                        typeof col.render === "function" ?
                          <td key={i} align={col.align}>
                            {
                              !/[a-zA-Z]/g.test(col.render(getPropByString(row, col.key), row)) &&
                                !col.withCurrency &&
                                !String(col.render(getPropByString(row, col.key), row)).includes(" ") &&
                                String(col.render(getPropByString(row, col.key), row)).length > 11 ?
                                `'${col.render(getPropByString(row, col.key), row)}` :
                                col.withCurrency ?
                                  Math.round(col.render(getPropByString(row, col.key), row))
                                    ?
                                    Math.round(col.render(getPropByString(row, col.key), row))
                                    :
                                    col.render(getPropByString(row, col.key), row)
                                  :
                                  col.render(getPropByString(row, col.key), row)
                            }
                          </td>
                          :
                          <td key={i} align={col.align}>
                            {
                              !/[a-zA-Z]/g.test(getPropByString(row, col.key)) &&
                                !col.withCurrency &&
                                !String(getPropByString(row, col.key)).includes(" ") &&
                                String(getPropByString(row, col.key)).length > 11 ?
                                `'${getPropByString(row, col.key)}` :
                                col.withCurrency ?
                                  Math.round(getPropByString(row, col.key))
                                    ?
                                    Math.round(getPropByString(row, col.key))
                                    :
                                    getPropByString(row, col.key)
                                  :
                                  getPropByString(row, col.key)
                            }
                          </td>
                        : null
                    )
                  }
                </tr>
              )
            })
          }
        </tbody>
        {grandTotal && grandTotal.length ?
          <tfoot>
            <tr style={{ fontWeight: "bold" }}>
              <td align="center" colSpan={totalSpan}>Grand Total</td>
              {grandTotal.map((v, i) =>
                <td align={v.align} key={i}>{Math.round(v.value)}</td>
              )}
            </tr>
          </tfoot>
          : null
        }
      </table>
      {withNotes &&
        <>
          <br />
          <div>
            Notes:
            {withNotes}
          </div>
        </>
      }
    </>
  )
}

export default ExcelTable
