import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminApi } from '../../../services/adminApi';
import { ADMIN_MODULE, UPDATE, DELETE, CLOSE_TAB } from '../../../utils/constants/actionTypes';
import Form from './Form';

const UpdateTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs, items } = useSelector(state => state.admin);
  const { key } = tabs[index];
  const selectedItem = items.find(row => row._id === key);
  const [isLoading, setIsLoading] = useState(false);

  const handleTab = type => key => dispatch({ module: ADMIN_MODULE, type: type, key: key })

  const submit = (values) => {
    setIsLoading(true)
    dispatch({
      module: ADMIN_MODULE,
      type: UPDATE,
      call: adminApi.update,
      args: [key, values],
      tabKey: index
    })
      .catch((e) => setIsLoading(false))
  }

  const softDelete = () => {
    setIsLoading(true)
    dispatch({
      module: ADMIN_MODULE,
      type: DELETE,
      call: adminApi.softDelete,
      args: [key],
      tabKey: index
    })
      .then(() => handleTab(CLOSE_TAB)(key))
      .catch((e) => setIsLoading(false))
  }

  return selectedItem ?
    <Form
      initialValue={selectedItem}
      onSubmit={submit}
      onDelete={softDelete}
      isLoading={isLoading}
    />
    :
    <div className="card">
      <div className="card-body">
        <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
      </div>
    </div>
}

export default UpdateTab
