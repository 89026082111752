import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const nnsApi = {
  list: ({ page, limit, sort, type, search }) => {
    let url = `/nns/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => agent.requests.post(`/nns/create`, data),
  update: (id, data) => agent.requests.post(`/nns/${id}/update-nns`, data),
  delete: (id) => agent.requests.post(`/nns/${id}/remove`),
}
