import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusHistory from '../../../components/StatusHistory';
import { combineBy } from '../../../utils/helpers/combineBy';
import { format } from 'date-fns'
import { toIDR } from '../../../utils/helpers/currency';
import { TRANSACTIONS_TOPUP_TRANSFER_PARTNER_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import AllStatus from '../../../utils/constants/enums/status';
import { transactionApi } from '../../../services/transactionApi';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs } = useSelector(state => state.transactions.topupTransferPartner);
  const { key, loading } = tabs[index];

  const {
    value: { data: { transactions } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: TRANSACTIONS_TOPUP_TRANSFER_PARTNER_MODULE,
      type: GET_DETAIL,
      call: transactionApi.detailTopUpTransferIntegration,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const getClass = status => {
    let className = 'badge-primary';
    if (status === AllStatus.PENDING) {
      className = 'badge-warning'
    } else if (
      status === AllStatus.COMPLETED ||
      status === AllStatus.TOPUP_SUCCESS ||
      status === AllStatus.TRANSFER_SUCCESS
    ) {
      className = 'badge-success'
    } else if (status === AllStatus.TRANSFER_FAILED) {
      className = 'badge-danger'
    }
    return className
  }

  return loading && !transactions ?
    null
    :
    transactions ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title mb-3">Applicant Info</h5>
              <div className="row">
                <div className="col-4 pr-1">
                  <div className="d-flex justify-content-between">
                    <label className="text-grey mb-1">Name</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-8 pl-0">
                  <p className="mb-0 font-weight-semibold">{combineBy([transactions.applicant.firstName, transactions.applicant.lastName])}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-4 pr-1">
                  <div className="d-flex justify-content-between">
                    <label className="text-grey mb-1">Phone Number</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-8 pl-0">
                  <p className="mb-0 font-weight-semibold">{transactions.applicant.phoneNumber}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-4 pr-1">
                  <div className="d-flex justify-content-between">
                    <label className="text-grey mb-1">Email</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-8 pl-0">
                  <p className="mb-0 font-weight-semibold">{transactions.applicant.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-4 pr-1">
                  <div className="d-flex justify-content-between">
                    <label className="text-grey mb-1">Desc</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-8 pl-0">
                  <p className="mb-0 font-weight-semibold">{transactions.topup.description}</p>
                </div>
              </div>
            </div>
            <hr className="my-0" />
            <div className="card-body ">
              <h5 className="card-title mb-3">Top up Info</h5>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Date</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{format(new Date(transactions.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
              </div>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Code</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{transactions.customerNumber}</p>
                </div>
              </div>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Status</label>
                </div>
                <div className="col-8 text-right">
                  <span className={`badge ${getClass(transactions.status)}`}>{transactions.status === AllStatus.FAILED ? 'Declined' : AllStatus.getStr(transactions.status)}</span>
                </div>
              </div>
              {transactions.status === AllStatus.FAILED
                ?
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Remarks</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{transactions.remarks}</p>
                  </div>
                </div>
                :
                ''
              }
              <div className="row align-items-end mt-3">
                <div className="col-4">
                  <label className="text-grey mb-0">Amount</label>
                </div>
                <div className="col-8 text-right">
                  <span className="text-grey pr-1">Rp</span>
                  <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(transactions.topup.amount, false)}</span>
                </div>
              </div>
            </div>
            <hr className="my-0" />
          </div>
          {
            transactions.transactionTransfer
            &&
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">Transfer Info</h5>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Date</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{format(new Date(transactions.transactionTransfer?.createdAt || '-'), 'dd MMMM yyyy HH:mm:ss')}</p>
                  </div>
                </div>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Type</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{transactions.transactionTransfer?.type || '-'}</p>
                  </div>
                </div>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Bank Destination</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{transactions.withdraw?.bankType || '-'}</p>
                  </div>
                </div>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Bank Account Number</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{transactions.bankAccountNumber || '-'}</p>
                  </div>
                </div>
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Bank Code</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{transactions.withdraw?.code || '-'}</p>
                  </div>
                </div>
                <div className="row align-items-end mt-3">
                  <div className="col-4">
                    <label className="text-grey mb-0">Transfer Amount</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className="text-grey pr-1">Rp</span>
                    <span className="h5 mb-0 font-weight-bold text-primary">{toIDR(transactions.transactionTransfer?.amount.primary || '-', false)}</span>
                  </div>
                </div>
                {/* <div className="row align-items-end mt-3">
                  <div className="col-4">
                    <label className="text-grey mb-0">Sender Balance</label>
                  </div>
                  <div className="col-8 text-right">
                    <span className="text-grey pr-1">Rp</span>
                    <span className="h5 mb-0 font-weight-bold text-primary">{toIDR(transactions.transactionTransfer?.senderBalance?.primary || '-', false)}</span>
                  </div>
                </div> */}
              </div>
            </div>
          }
        </div>
        <div className="col-12 col-lg-6">
          <StatusHistory statusHistories={transactions.statusHistories} />
        </div>
      </div>
      :
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab;
