import React, { useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import DetailTab from "./DetailTab";
import { AUTO_DEBIT_MODULE } from "../../../utils/constants/actionTypes";
import { autoDebitApi } from "../../../services/autoDebitApi";
import { combineBy } from "../../../utils/helpers/combineBy";
import { PpobType } from "../../../utils/constants/enums/ppobTypes";

const Index = () => {
  const { currentPage, limit, sort, filter } = useSelector(
    (state) => state.autoDebit
  );

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Owner', key: 'owner', name: 'owner', render: v => v ? `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` : 'Unknown' },
    { title: 'Schedule Code', key: 'scheduleCode', name: 'scheduleCode' },
    { title: 'Ppob Type', key: 'ppobId.type', name: 'ppobId', sortable: false, render: v => PpobType.getStrIdn(v) },
    { title: 'Billing Date', key: 'date', name: 'date', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
  ];

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      startDate,
      endDate,
    };
  }, [currentPage, limit, sort, filter]);

  return (
    <div>
      <Resource
        title="Auto Debit"
        subTitle="Auto Debit list."
        icon="pe-7s-refresh-2"
        list={{
          columns: columns,
          reducerPath: "autoDebit",
          module: AUTO_DEBIT_MODULE,
          call: autoDebitApi.list,
          apiQuery: apiQuery
        }}
        detail={{ component: DetailTab }}
      />
    </div>
  );
};

export default Index;
