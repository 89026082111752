import React from "react";
import ReactDOMServer from "react-dom/server";
import leadingZero from "../../../utils/helpers/leadingZero";

const excelRow = (row, index) => {
  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td align="right">{leadingZero(row.TerminalID)}</td>
      <td align="right">{leadingZero(row.RRN)}</td>
      <td>{leadingZero(row.PAN)}</td>
      <td>{row.TRXdate}</td>
      <td>{leadingZero(row.TRXtime)}</td>
      <td>{leadingZero(row.ProcCode)}</td>
      <td>{row.Amount}</td>
      <td align="right">{row.FeeType}</td>
      <td>{row.Fee}</td>
      <td>{row.Currency}</td>
      <td>{leadingZero(row.MCC)}</td>
      <td align="right">{row.MCr}</td>
      <td>{leadingZero(row.NNSAcq)}</td>
      <td>{leadingZero(row.NNSIss)}</td>
      <td>{leadingZero(row.NNSSwc)}</td>
      <td>{leadingZero(row.RC)}</td>
      <td>{leadingZero(row.fromAccnt)}</td>
      <td align="right">{leadingZero(row.Invoice)}</td>
      <td align="right">{leadingZero(row.ApvCode)}</td>
    </tr>
  );
};

export const excelHref = (items) => {
  const table = (
    <table border="1">
      <thead>
        <tr>
          <th>#</th>
          <th>Terminal ID</th>
          <th>RRN</th>
          <th>PAN</th>
          <th>TRX Date</th>
          <th>TRX Time</th>
          <th>Proc Code</th>
          <th>Amount</th>
          <th>Fee Type</th>
          <th>Fee</th>
          <th>Currency</th>
          <th>MCC</th>
          <th>MCriteria</th>
          <th>NNS Acquirer</th>
          <th>NNS Issuer</th>
          <th>NNS Swc</th>
          <th>RC</th>
          <th>From Account</th>
          <th>Invoice</th>
          <th>Approval Code</th>
        </tr>
      </thead>
      <tbody>{items.map((row, index) => excelRow(row, index))}</tbody>
    </table>
  );

  const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])
  const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))

  const html = ReactDOMServer.renderToStaticMarkup(table);
  const excelHref = 'data:application/vnd.ms-excel;base64,'
  const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{html}</body></html>';
  const context = {
    worksheet: 'Worksheet',
    html,
  };

  return excelHref + base64(format(template, context))
}
