import React, { useState } from 'react';
import { UnlockAlt } from '../../../../components/Icons/Icons';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import roleHelper, { UNLOCK_MERCHANT } from '../../../../utils/helpers/roleHelper';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import { businessesApi } from '../../../../services/businessesApi';

const UnlockCard = ({ admin, item, onSuccess }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const locked = item.status.locked;

  const unlock = () => {
    if (!locked) return
    setIsLoading(true);
    dispatch({ type: null, call: businessesApi.unlock, args: [item._id] })
      .then(() => {
        onSuccess();
        toast("Unlock request success", { type: 'success' });
      })
      .catch(() => {
        toast("Unlock request failed", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    roleHelper.hasAccess(admin.role, UNLOCK_MERCHANT) ?
      <AlertDialog
        buttonComponent={(
          <div className={`card unlock-card ${locked ? 'locked' : ''}`} onClick={unlock}>
            <div className="card-body">
              <div className="text-center">
                <div className="lock-icon-animated">
                  <UnlockAlt className="fa-6x" />
                </div>
                {locked ?
                  <p className="mb-0"><b>Merchant locked</b>, do you want to request unlock now?</p>
                  :
                  <p className="mb-0"><b>Merchant not locked</b></p>
                }
              </div>
              <LoadingBlocker in={isLoading} />
            </div>
          </div>
        )}
        className="col-12 col-md-3 mbg-3"
        disabled={!locked}
        onAgree={unlock}
        title="Apakah anda yakin ingin meng-unlock merchant ini?"
        description="Tindakan ini tidak dapat dibatalkan"
      />
      :
      null
  )
}

export default UnlockCard;
