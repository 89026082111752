import React, { useState, useEffect, useCallback } from 'react';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { format } from 'date-fns';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import HookTextField from '../../../../components/Forms/HookTextField';
import EvyDatePicker from '../../../../components/Forms/EvyDatePicker';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormField from '../../../../components/Forms/FormField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import CitySelect from '../../../../components/Select/CitySelect';
import { yupResolver } from '@hookform/resolvers';
import useAsync from '../../../../components/HooksUse/useAsync';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import { phoneNumberRegex } from '../../../../utils/constants/regexTypes';
import { dataListApi } from '../../../../services/dataListApi';
import { userApi } from '../../../../services/userApi';
import { KYC_UPGRADES_MODULE } from '../../../../utils/constants/actionTypes';
import { VERIFY_ID } from '../../../../redux/reducers/kyc/upgrades';
import PopupCropperKYC from '../../../../components/PopUp/PopUpCropper';
import getCroppedImg, { urlToFile } from '../../../../utils/helpers/cropImage';
import VerifyIdHistoryCard from '../../../../components/templates/VerifyIdHistoryCard';

const getAlertVarian = string => {
  let className = '';
  if (string === "match") {
    className = "";
  } else if (string === "partial") {
    className = "text-warning";
  } else if (string === "nomatch") {
    className = "text-danger";
  }
  return className;
}

const convertArrayToObject = (array, key, value) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    };
  }, initialValue);
};

const BiodataCard = ({ item, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [matchStatus, setmatchStatus] = useState();
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  // Cropper
  const [showCropImage, setShowCropImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedPreview, setCroppedPreview] = useState(null)
  const [errorMinimumPixels, setErrorMinimumPixels] = useState('');
  const [imageKtp, setImageKtp] = useState(null);
  const token = window.localStorage.getItem('token');

  const selfieImage = item?.archive?.identityCard?.selfPhoto?.url + '?token=' + token;
  const aspect = 3 / 4;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true)
    try {
      const croppedImage = await getCroppedImg(
        selfieImage,
        croppedAreaPixels,
        rotation
      )
      if (croppedImage.error) {
        setErrorMinimumPixels(croppedImage.error)
      } else {
        setCroppedImage(croppedImage.file)
        setCroppedPreview(croppedImage.img)
        setErrorMinimumPixels('')
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, selfieImage, rotation])

  const handleSuccess = () => {
    setIsEditing(false);
    onSuccess();
  }

  const verify = async () => {
    await showCroppedImage()
    if (croppedImage && imageKtp) {
      const data = {
        nik: item.archive.identityCard.identificationNumber,
        name: `${item.firstName} ${item.lastName}`,
        birth_place: item.placeOfBirth,
        birth_date: format(new Date(item.dateOfBirth), 'dd-MM-yyyy'),
        image: croppedImage,
        imageKtp: imageKtp,
        userId: item._id,
      };

      setmatchStatus(null);
      return dispatch({ module: KYC_UPGRADES_MODULE, type: VERIFY_ID, call: userApi.verifyID, args: [data] })
        .then(res => {
          if (isMounted()) {
            setmatchStatus(convertArrayToObject(res.data, 'fieldName', 'status'));
            handleSuccess();
          }
        })
        .catch(err => {
          if (isMounted()) setmatchStatus(err.message);
        })
    }
  }

  const verifyAsync = useAsync(verify);

  const onVerify = () => {
    verifyAsync.execute()
    setShowCropImage(false)
    setCroppedImage(null)
  }

  const generateAlert = useCallback(
    () => {
      let html;

      if (typeof matchStatus === 'string') {
        html = (
          <div className="alert alert-danger">
            <b>{matchStatus}</b>
          </div>
        )
      } else {
        html = (
          <div className="alert alert-success">
            <span className="mr-2">NIK: <b className={getAlertVarian(matchStatus.nik)}>{matchStatus.nik}</b>,</span>
            <span className="mr-2">Nama: <b className={getAlertVarian(matchStatus.name)}>{matchStatus.name}</b>,</span>
            <span className="mr-2">Tempat Lahir: <b className={getAlertVarian(matchStatus.birth_place)}>{matchStatus.birth_place}</b>,</span>
            <span className="mr-2">Tanggal Lahir: <b className={getAlertVarian(matchStatus.birth_date)}>{matchStatus.birth_date}</b>,</span>
            <span className="mr-2">Tingkat Kesamaan: <b className={getAlertVarian(matchStatus.similarity)}>{matchStatus.similarity}</b>,</span>
          </div>
        );
      }

      return html
    }, [matchStatus])

  const GetInfo = () => {
    if (errorMinimumPixels) {
      return <p className="text-danger">Pixels harus lebih dari 480 x 640 pixels, mohon untuk zoom out</p>
    } else {
      return <p>Pastikan <b>wajah pengguna</b> memenuhi gambar.</p>
    }
  }

  const fetchData = useCallback(async () => {
    if (item?.archive?.identityCard?.cardPhoto?.url) {
      const ktpImage = await urlToFile(item?.archive?.identityCard?.cardPhoto?.url + '?token=' + token, "ktpImage.jpg")

      if (ktpImage) {
        setImageKtp(ktpImage)
      }
    }
  }, [item?.archive?.identityCard?.cardPhoto?.url, token])

  useEffect(() => {
    fetchData();

    return () => { }
  }, [fetchData])

  return (
    <>
      {
        matchStatus
        &&
        generateAlert()
      }
      <div className="mb-4">
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ?
            <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
            <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
          }
        </button>
        {
          item?.buttonVerifyEnabled &&
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={() => setShowCropImage(true)}
          >
            Verify ID
          </button>
        }
      </div>
      {item?.usedNikBy?.length ?
        <>
          <div className="p-3 border d-grid gap-8">
            {item?.usedNikBy.map((v, i) => {
              return (
                <div className="alert alert-warning font-weight-bold mb-0" key={i}>
                  {v}
                </div>
              )
            })}
          </div>
          <hr className="my-4" />
        </>
        :
        null
      }
      {isEditing ?
        <Form
          item={item}
          onClose={() => setIsEditing(false)}
          onSuccess={handleSuccess}
          setIsLoading={setIsLoading}
        />
        :
        <View
          item={item}
        />
      }
      <PopupCropperKYC
        showCropImage={showCropImage}
        setShowCropImage={setShowCropImage}
        croppedImage={croppedImage}
        croppedPreview={croppedPreview}
        cropperProps={{
          image: selfieImage,
          crop,
          setCrop,
          zoom,
          setZoom,
          rotation,
          setRotation,
          onCropComplete,
          aspect
        }}
        isLoading={isLoading}
        onVerify={onVerify}
        setCroppedImage={setCroppedImage}
        showCroppedImage={showCroppedImage}
        title="Potong Swafoto"
        info={<GetInfo />}
      />
      <LoadingBlocker in={isLoading} />
    </>
  )
}

const View = ({ item }) => {
  return (
    <div>
      <EvyTextField
        readOnly
        row
        label="NIK"
        value={item?.archive?.identityCard?.identificationNumber}
      />
      <EvyTextField
        readOnly
        row
        label="Unique ID"
        helperText={`Created at: ${format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm')}`}
        value={item.uniqueId}
      />
      <EvyTextField
        readOnly
        row
        label="First Name"
        value={item.firstName}
      />
      <EvyTextField
        readOnly
        row
        label="Last Name"
        value={item.lastName}
      />
      <EvyTextField
        readOnly
        row
        label="Email"
        value={item.email}
        helperText={`Verified at: ${item.status.emailVerifiedAt ? format(new Date(item.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
      />
      <EvyTextField
        readOnly
        row
        label="Phone Number"
        value={item.phoneNumber}
        helperText={`Verified at: ${item.status.phoneNumberVerifiedAt ? format(new Date(item.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
      />
      <EvyTextField
        readOnly
        row
        label="Date of Birth"
        value={item.dateOfBirth ? format(new Date(item.dateOfBirth), 'dd-MM-yyyy') : ''}
      />
      <EvyTextField
        readOnly
        row
        label="Place of Birth"
        value={item.placeOfBirth}
      />
      <EvyTextField
        readOnly
        row
        label="City"
        value={item.cityName}
        helperText={`Code: ${item.city ?? '-'}`}
      />
      <EvyTextField
        readOnly
        row
        label="Address"
        value={item.address}
      />
      <EvyTextField
        readOnly
        row
        label="Current Address"
        value={item.addressCurrent}
      />
      <EvyTextField
        readOnly
        row
        label="Job"
        value={item.job}
      />
      <EvyTextField
        readOnly
        row
        label="Religion"
        value={item.religion}
      />
      <EvyTextField
        readOnly
        row
        label="Registered At"
        value={item.registeredAt}
      />
      <EvyTextField
        readOnly
        row
        label="Latitude"
        value={item.latitude}
      />
      <EvyTextField
        readOnly
        row
        label="Longitude"
        value={item.longitude}
      />
      <EvyTextField
        readOnly
        row
        multiline
        label="Note"
        value={item.upgradeNote}
      />
      <hr className="my-4" />
      {item?.verifyIdHistory?.length ?
        <VerifyIdHistoryCard item={item?.verifyIdHistory} />
        : null
      }
    </div>
  )
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  email: yup.string().email().required(),
  phoneNumber: yup.string().matches(phoneNumberRegex, 'Format is invalid. e.g: +628xxxxxxxxxx').min(5, 'Minimum length 5 digits'),
  dateOfBirth: yup.string().required(),
  placeOfBirth: yup.string(),
  job: yup.string().nullable(),
  religion: yup.string(),
  city: yup.string().required().nullable(),
  address: yup.string(),
  registeredAt: yup.string().required()
});

const Form = ({ item, onClose, onSuccess, setIsLoading }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
      email: item.email ?? '',
      phoneNumber: item.phoneNumber ?? '',
      dateOfBirth: item.dateOfBirth ?? '',
      placeOfBirth: item.placeOfBirth ?? '',
      job: item.job ?? '',
      religion: item.religion ?? '',
      city: item.city,
      address: item.address ?? '',
      registeredAt: item.registeredAt ?? '',
    },
  });
  const { dateOfBirth, job, city } = watch(['dateOfBirth', 'job', 'city']);

  const {
    value: { data: jobs = [] } = {}
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: dataListApi.getJobs }),
    [dispatch]), true)

  const onSubmit = (values) => {
    const data = {
      ...values,
      job: values.job ?? "",
    };

    setIsLoading(true);
    dispatch({ type: null, call: userApi.update, args: [item._id, data] })
      .then(() => {
        if (isMounted()) onSuccess();
      })
      .finally(() => {
        if (isMounted()) setIsLoading(false);
      })
  };

  useEffect(() => {
    register("dateOfBirth");
    register("job");
    register("city");

    return () => {
      unregister("dateOfBirth")
      unregister("job")
      unregister("city");
    };
  }, [register, unregister])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HookTextField
        ref={register}
        label="First Name"
        name="firstName"
        error={errors.firstName}
        helperText={errors.firstName ? errors.firstName.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Last Name"
        name="lastName"
        error={errors.lastName}
        helperText={errors.lastName ? errors.lastName.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Email"
        name="email"
        error={errors.email}
        helperText={errors.email ? errors.email.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Phone Number"
        name="phoneNumber"
        error={errors.phoneNumber}
        helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
        row
      />
      <FormField
        row
        label="Date of Birth"
        error={errors.dateOfBirth}
        helperText={errors.dateOfBirth ? errors.dateOfBirth.message : null}
      >
        <EvyDatePicker
          value={dateOfBirth}
          variant="inline"
          autoOk
          format="dd-MM-yyyy"
          onChange={(v) => setValue('dateOfBirth', v, { shouldValidate: true })}
        />
      </FormField>
      <HookTextField
        ref={register}
        label="Place of Birth"
        name="placeOfBirth"
        error={errors.placeOfBirth}
        helperText={errors.placeOfBirth ? errors.placeOfBirth.message : null}
        row
      />
      <CitySelect
        row
        label="City"
        onChange={(v) => setValue('city', v?.value ?? null, { shouldValidate: true })}
        value={city}
        error={errors.city}
        helperText={errors.city ? errors.city.message : null}
      />
      <HookTextField
        ref={register}
        label="Address"
        name="address"
        error={errors.address}
        helperText={errors.address ? errors.address.message : null}
        row
      />
      <FormField
        row
        label="Job"
        helperText="Type manually if no choices match"
      >
        <Autocomplete
          id="autoCompleteJob"
          freeSolo
          options={jobs.map(job => job.occupation_id)}
          onChange={(e, val) => setValue('job', val)}
          value={job}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              fullWidth
              inputProps={{
                ...props.inputProps,
                onChange: e => setValue('job', e.target.value),
              }}
            />
          )}
        />
      </FormField>
      <HookTextField
        ref={register}
        label="Religion"
        name="religion"
        error={errors.religion}
        helperText={errors.religion ? errors.religion.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Registered At"
        name="registeredAt"
        error={errors.registeredAt}
        helperText={errors.registeredAt?.message}
        row
      />
      <FormField
        row
      >
        <LoadingBtn type="submit" className="btn btn-primary mr-2">Submit</LoadingBtn>
        <button type="button" onClick={onClose} className="btn btn-danger">Cancel</button>
      </FormField>
    </form>
  )
}

export default BiodataCard;
