import { endOfDay, format, startOfDay } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { transactionApi } from "../../../services/transactionApi";
import { TRANSACTIONS_MDR_MODULE } from "../../../utils/constants/actionTypes";
import { DATE_PICKER, MERCHANT_SELECT } from "../../../utils/constants/inputTypes";
import { combineBy } from "../../../utils/helpers/combineBy";
import { toIDR } from "../../../utils/helpers/currency";
import Resource from "../../../components/Resource";

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalAmount
  } = useSelector(state => state.transactions.mdr);

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      title: 'Trnno/RRN', key: 'codePayment', name: 'codePayment', render: (v, row) => `${v} | ${row.rnnPayment ?? '-'}`
    },
    {
      title: 'Business Name', key: 'bussinesName', name: 'bussinesName', render: v => `${v ?? '-'}`
    },
    { title: 'Owner', key: 'ownerName', name: 'ownerName', render: (v, row) => v ? `${v} | ${row.ownerUniqueId}` : "-" },
    {
      title: 'Mdr Amount', key: 'mdr', name: 'mdr', className: 'text-center', render: v => `${toIDR(v) ?? '-'}`
    },
  ];

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const merchant = filter.merchant?.value;

    return {
      page: currentPage,
      startDate,
      endDate,
      sort,
      limit,
      merchant,
    }
  }, [currentPage, filter.endDate, filter.merchant, filter.startDate, limit, sort]);

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Trnno/RRN', key: 'codePayment', name: 'codePayment', render: (v, row) => `${v} | ${row.rnnPayment ?? '-'}` },
    { title: 'Business Name', key: 'bussinesName', name: 'bussinesName', render: v => `${v ?? '-'}` },
    { title: 'Owner', key: 'ownerName', name: 'ownerName', render: (v, row) => v ? `${v} | ${row.ownerUniqueId}` : "-" },
    { title: 'Mdr Amount', key: 'mdr', name: 'mdr', className: 'text-center', render: v => `${toIDR(v, false) ?? '-'}` },
  ], [])

  return (
    <div>
      <Resource
        title="Transactions MDR"
        subTitle="Full Transactions MDR list."
        icon="pe-7s-ribbon"
        list={{
          columns: columns,
          call: transactionApi.listMDR,
          reducerPath: "transactions.mdr",
          apiQuery: apiQuery,
          module: TRANSACTIONS_MDR_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transactions_MDR_List',
            apiResponseKey: 'data.transactions',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
            {
              type: MERCHANT_SELECT,
              key: "merchant",
              value: filter.merchant,
              selectProps: {
                search: (search) => `fullName|${search},uniqueId|${search},phoneNumber|${search}`,
                label: (merchant) => `${combineBy([merchant.firstName, merchant.lastName])} | ${merchant.uniqueId} | ${merchant.phoneNumber}`
              }
            },
          ]
        }}
      />
    </div>
  )
}

export default Index;
