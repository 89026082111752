import React, { useMemo } from 'react';
import Resource from '../../../components/Resource';
import { endOfDay, format, startOfDay } from 'date-fns';
import { combineBy } from '../../../utils/helpers/combineBy';
import { useSelector } from 'react-redux';
import DetailTab from './DetailTab';
import { REQUEST_UPDATE_USER_MODULE } from '../../../utils/constants/actionTypes';
import { DATE_PICKER, SELECT_FIELD } from '../../../utils/constants/inputTypes';
import { requestUpdateApi } from '../../../services/requestUpdateApi';
import { RequestUpdateType, RequestUpdateTypeOpts } from '../../../utils/constants/enums/requestUpdateTypes';
import { RequestUpdateStatus } from '../../../utils/constants/enums/requestUpdateStatus';
import { partnerTypes } from '../../../utils/constants/enums/partnerTypes';
import styles from './list.module.scss'

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.users.requestUpdate);

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd hh:mm:ss') },
    {
      title: 'User', key: 'user', name: 'user_name', sortable: false, render: (v) => combineBy([v?.firstName, v?.lastName])
    },
    { title: 'Email', key: 'user.email', name: 'email', sortable: false },
    { title: 'Phone Number', key: 'user.phoneNumber', name: 'phone', sortable: false },
    {
      title: 'Connected Partner', key: 'user.connectedPartner', name: 'connectedPartner', render: (v) => (
        <div>
          {v?.length ? v.map((partner, index) => (
            <div key={index} className="d-flex align-items-center my-1">
              <span className='mr-1'>{partnerTypes.getStr(partner.companyCode)}</span>
              {partner?.privateUser ?
                <div className={styles.badge}>
                  <span>Private</span>
                </div>
                : null
              }
            </div>
          )) : '-'}
        </div>
      )
    },
    { title: 'Update Type', key: 'updateType', name: 'updateType', render: (v) => RequestUpdateType.getStr(v) },
    { title: 'Status', key: 'status', name: 'status', render: (v) => RequestUpdateStatus.getStr(v) },
  ]

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      status: filter.status,
      type: filter.type,
      startDate,
      endDate,
      conPartnerType: filter.conPartnerType,
    }
  }, [filter, currentPage, limit, sort])

  return (
    <Resource
      title="Admin approval List"
      subTitle="Approve or reject user changes request."
      icon="pe-7s-users"
      list={{
        columns: columns,
        reducerPath: "users.requestUpdate",
        module: REQUEST_UPDATE_USER_MODULE,
        call: requestUpdateApi.list,
        apiQuery: apiQuery,
        filters: [
          {
            label: "Filter Type",
            type: SELECT_FIELD,
            key: "type",
            options: RequestUpdateTypeOpts,
            value: filter.type
          },
          {
            label: "Start Date",
            type: DATE_PICKER,
            key: "startDate",
            value: filter.startDate,
            placeholder: "Select date"
          },
          {
            label: "End Date",
            type: DATE_PICKER,
            key: "endDate",
            value: filter.endDate,
            placeholder: "Select date"
          },
          {
            label: "Filter Tipe Connected Partner",
            type: SELECT_FIELD,
            key: "conPartnerType",
            options: [
              {
                value: "PRIVATE_USER",
                label: "Private"
              },
              {
                value: "NON_PRIVATE_USER",
                label: "Non Private"
              }
            ],
            value: filter.conPartnerType
          },
        ]
      }}
      detail={{
        component: DetailTab
      }}
    />
  );
}

export default Index;
