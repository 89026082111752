import React, { useCallback, useEffect, useMemo } from "react";
import Table from "../../components/Table/Table";
import PartnerSelect from "../../components/Select/PartnerSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  MAIN_TAB_LOAD,
  CREATE_TAB,
  FILTER_TABLE,
  REMOVE_DELETE_DATA,
  ADD_DELETE_DATA,
  DELETE,
} from "../../utils/constants/actionTypes";
import objectPath from "object-path";
import roleHelper, { EXPORT_EXCEL } from "../../utils/helpers/roleHelper";
import {
  SELECT_FIELD,
  PARTNER_SELECT,
  DATE_PICKER,
  MCC_SELECT,
  ADMIN_SELECT,
  USER_SELECT,
  DONATION_SELECT,
  TEXT_FIELD,
  MERCHANT_SELECT
} from "../../utils/constants/inputTypes";
import ButtonDispatch from "../ButtonDispatch";
import FormField from "../Forms/FormField";
import EvyDatePicker from "../Forms/EvyDatePicker";
import SelectField from "../Forms/SelectField";
import MCCSelect from "../Select/MCCSelect";
import AdminSelect from "../Select/AdminSelect";
import DonationSelect from "../Select/DonationSelect"
import UserSelect from "../Select/UserSelect";
import MerchantSelect from '../Select/MerchantSelect';
import EvyTextField from "../Forms/EvyTextField";

const getDetailTitle = (row, title) => {
  if (row.code) return `${title} - ${row.code}`
  else if (row.fullName) return `${title} - ${row.fullName}`
  else if (row.firstName) return `${title} - ${row.firstName} ${row.lastName}`
  else if (row.name) return `${title} - ${row.name}`
  else if (row.uniqueId) return `${title} - ${row.uniqueId}`
  else if (row._id) return `${title} - ${row._id}`

  return title
}

const ResourceList = ({
  list: {
    call,
    reducerPath,
    module,
    columns,
    apiQuery,
    excel,
    filters,
    search,
    customActionColumns = [],
    deleteButton,
    customExportExcel,
    renderTotal,
    withCheckbox,
    setCheckboxItem,
    checkboxItems,
    additionalButton,
    customTopLeftTable
  },
  detail,
  update,
  isLoading
}) => {
  const dispatch = useDispatch();
  const {
    inProgress,
    sort,
    items,
    limit,
    total,
    currentPage,
    filter,
    reload,
    deleteRowId,
  } = useSelector((state) => objectPath.get(state, reducerPath));

  const createTab = useCallback(
    ({ key, title, additionalKey, tabType }) => {
      dispatch({
        module: module,
        type: CREATE_TAB,
        [additionalKey?.key]: additionalKey?.value,
        key,
        title,
        tabType
      });
    },
    [dispatch, module]
  );

  const setTableConfig = useCallback(
    (type) => (val, key) => {
      dispatch({
        module: module,
        type: type,
        value: val,
        key: key,
      });
    },
    [dispatch, module]
  );

  const onFilter = useCallback(
    (key) => (val) => {
      setTableConfig(FILTER_TABLE)(val, key);
    },
    [setTableConfig]
  );

  const load = useCallback(() => {
    dispatch({
      module: module,
      type: MAIN_TAB_LOAD,
      call: call,
      args: [apiQuery],
    });
  }, [dispatch, module, call, apiQuery]);

  const customAction = useCallback(
    ({ key, call, args }) => {
      dispatch({
        type: null,
        call: call,
        args: [{ id: key, ...args }]
      })
        .then(() => load())
        .catch(() => { })
    },
    [dispatch, load]
  );

  const deleteSelected = useCallback(() => {
    if (deleteRowId) {
      dispatch({
        module: module,
        type: DELETE,
        call: deleteButton.api,
        args: [deleteRowId],
      }).catch(() => { });
    }
  }, [deleteRowId, dispatch, module, deleteButton]);

  const modifiedColumns = useMemo(() => {
    const newColumns = [...columns];
    const buttons = [...customActionColumns];

    if (update.isExist) {
      buttons.push({
        title: "Update",
        icon: "pe-7s-tools btn-icon-wrapper",
      });
    }

    if (detail.isExist) {
      buttons.push({
        title: "Detail",
        icon: "lnr-eye btn-icon-wrapper",
        additionalKey: detail?.additionalKey
      });
    }

    if (deleteButton || buttons.length) {
      newColumns.push({
        title: "Action",
        key: "_id",
        name: "action",
        className: "text-center",
        sortable: false,
        render: (v, row) => {
          return (
            <div className="d-flex justify-content-center">
              {buttons.map((btn, key) => {
                const Component = (
                  <button
                    key={key}
                    className="btn btn-icon btn-icon-only btn-sm btn-primary mx-1"
                    onClick={
                      btn?.action ?
                        () => {
                          customAction({
                            key: v,
                            call: btn?.action?.call,
                            args: btn?.action?.args
                          })
                        }
                        :
                        typeof btn?.onClick === 'function' ?
                          () => btn?.onClick(row)
                          :
                          () => {
                            createTab({
                              key: v,
                              tabType: btn?.title,
                              title: getDetailTitle(row, btn?.title),
                              additionalKey: {
                                key: btn?.additionalKey?.key,
                                value: objectPath.get(row, btn?.additionalKey?.value),
                              }
                            })
                          }
                    }
                  >
                    {btn.icon ? <i className={btn.icon}></i> : btn.title}
                  </button>
                )
                return btn?.role ?
                  objectPath.get(row, btn?.role?.key) === btn?.role?.value &&
                  Component
                  :
                  Component
              })}
              {deleteButton ? (
                <div className="btn-group ml-1">
                  {deleteRowId === v ? (
                    <>
                      <ButtonDispatch
                        type={REMOVE_DELETE_DATA}
                        module={module}
                        className="btn btn-sm btn-danger lh-1"
                      >
                        No
                      </ButtonDispatch>
                      <button
                        onClick={deleteSelected}
                        type="button"
                        className="btn btn-sm btn-danger lh-1 py-0"
                      >
                        Yes, Delete
                      </button>
                    </>
                  ) : (
                    <ButtonDispatch
                      className="btn btn-icon btn-icon-only btn-sm btn-danger"
                      args={{ _id: v }}
                      module={module}
                      type={ADD_DELETE_DATA}
                    >
                      <i className="pe-7s-trash btn-icon-wrapper"></i>
                    </ButtonDispatch>
                  )}
                </div>
              ) : null}
            </div>
          );
        },
      });
    }

    return newColumns;
  }, [
    createTab,
    customAction,
    update,
    detail,
    columns,
    customActionColumns,
    module,
    deleteButton,
    deleteRowId,
    deleteSelected,
  ]);

  const table = {
    inProgress: inProgress,
    data: items,
    sort: sort,
    limit: limit,
    total: total,
    search: search ?? false,
    currentPage: currentPage,
    columns: modifiedColumns,
    withCheckbox,
    setCheckboxItem,
    checkboxItems
  };

  const tableFilter = useMemo(() => {
    return (
      filters && (
        <div className="row">
          {filters.map((v, i) => {
            if (v.type === SELECT_FIELD) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <SelectField
                    label={v.label}
                    className="form-control"
                    placeholder={v.placeholder ?? "No Filter"}
                    options={v.options ?? []}
                    onChange={(e) => onFilter(v.key)(e?.value)}
                    value={v?.value}
                    isDisabled={inProgress}
                    componentProps={{
                      isClearable: true,
                      isLoading: v.loading
                    }}
                  />
                </div>
              );
            }

            if (v.type === TEXT_FIELD) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <EvyTextField
                    label={v.label}
                    value={v?.value}
                    onChange={(e) => onFilter(v.key)(e?.target?.value)}
                    placeholder={v.placeholder}
                  />
                </div>
              );
            }

            if (v.type === PARTNER_SELECT) {
              const Component = (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <PartnerSelect
                    label={v.label}
                    value={v.value}
                    getOptionValue={v.getOptionValue}
                    disabled={inProgress}
                    onChange={(e) =>
                      onFilter(v.key)(
                        v.filterByCompanyCode
                          ? e?.optData?.companyCode
                          : e
                      )
                    }
                    filterIntegrationType={v.filterIntegrationType}
                  />
                </div>
              )

              return typeof v.role === 'boolean' ? v.role && Component : Component
            }

            if (v.type === ADMIN_SELECT) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <AdminSelect
                    label={v.label}
                    value={v.value}
                    onChange={onFilter(v.key)}
                    disabled={inProgress}
                  />
                </div>
              );
            }

            if (v.type === USER_SELECT) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <UserSelect
                    label={v.label}
                    disabled={inProgress}
                    value={v?.value}
                    customOptions={v?.options}
                    onChange={onFilter(v.key)}
                    selectProps={v?.selectProps}
                  />
                </div>
              );
            }

            if (v.type === MERCHANT_SELECT) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <MerchantSelect
                    label={v.label}
                    disabled={inProgress}
                    value={v?.value}
                    customOptions={v?.options}
                    onChange={onFilter(v.key)}
                    selectProps={v?.selectProps}
                  />
                </div>
              );
            }

            if (v.type === DONATION_SELECT) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <DonationSelect
                    label={v.label}
                    placeholder={v.placeholder}
                    value={v.value}
                    onChange={onFilter(v.key)}
                  />
                </div>
              );
            }

            if (v.type === DATE_PICKER) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <FormField label={v.label}>
                    <EvyDatePicker
                      value={v?.value}
                      onChange={onFilter(v.key)}
                      variant={v.variant}
                      placeholder={v.placeholder}
                      autoOk
                      clearable={true}
                      disabled={v.disabled}
                    />
                  </FormField>
                </div>
              );
            }

            if (v.type === MCC_SELECT) {
              return (
                <div className="col-12 col-md-4 col-lg-3" key={i}>
                  <MCCSelect disabled={inProgress} value={v.value} onChange={onFilter(v.key)} />
                </div>
              );
            }

            return null;
          })}
        </div>
      )
    );
  }, [filters, onFilter, inProgress]);

  useEffect(() => {
    if (load || reload || isLoading) load()
  }, [load, reload, isLoading]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        {additionalButton && additionalButton}
        <Table
          table={table}
          onSearch={onFilter("search")}
          onClearSearch={() => onFilter("search")("")}
          searchValue={filter?.search}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
          renderTotal={renderTotal}
          customTopLeftTable={customTopLeftTable}
          excel={
            excel
              ? excel?.role
                ? roleHelper.hasAccess(excel?.role, EXPORT_EXCEL)
                  ? {
                    columns: excel?.columns,
                    filename: excel?.filename,
                    api: {
                      apiResponseKey: excel?.apiResponseKey,
                      service: call,
                      queryParams: apiQuery,
                    },
                  }
                  : null
                : {
                  columns: excel?.columns,
                  filename: excel?.filename,
                  api: {
                    apiResponseKey: excel?.apiResponseKey,
                    service: call,
                    queryParams: apiQuery,
                  },
                }
              : null
          }
          customExportExcel={customExportExcel}
        >
          {filters ? tableFilter : null}
        </Table>
      </div>
    </div>
  );
};

export default ResourceList;
