import React from 'react'
import { toIDR } from '../../../../utils/helpers/currency'
import EvyTextField from '../../../../components/Forms/EvyTextField'

const WalletCard = ({ item }) => {
  return (
    <div>
      <EvyTextField
        readOnly
        row
        label="Primary Balance"
        value={toIDR(item.balance.primary)}
      />
      <EvyTextField
        readOnly
        row
        label="Point Balance"
        value={toIDR(item.balance.point, false) || "0"}
      />
      <EvyTextField
        readOnly
        row
        label="Cumulative Balance"
        value={toIDR(item.balance.cumulative)}
      />
      <EvyTextField
        readOnly
        row
        label="Topup Balance"
        value={toIDR(item.balance.topup)}
      />
    </div>
  )
}

export default WalletCard
