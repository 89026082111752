import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { format, endOfMonth } from 'date-fns';
import React, { useState } from 'react'
import { combineBy } from '../../../../utils/helpers/combineBy';
import { toIDR } from '../../../../utils/helpers/currency';
import { TxtIcon } from '../../../../components/Icons/Icons';
import HookTextField from '../../../../components/Forms/HookTextField';
import styles from './reportG0003.module.scss';
import * as yup from 'yup'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { generateDataApi } from '../../../../services/generateDataApi';

const renderRow = (row, index) => (
  <TableRow key={index}>
    <TableCell>{index + 1}</TableCell>
    <TableCell>{combineBy([row.senderCityCode, row.senderCityName], ' - ')}</TableCell>
    <TableCell>{row.recipientCityCode}</TableCell>
    <TableCell>{row.recipientFullName}</TableCell>
    <TableCell>{row.senderFullName}</TableCell>
    <TableCell>{row.totalTransaction}</TableCell>
    <TableCell>{toIDR(row.totalAmount)}</TableCell>
  </TableRow>
)

const schema = yup.object().shape({
  reporterCode: yup.string().required().length(9),
});

const TableComponent = ({
  items,
  period,
  setLoading
}) => {
  const [openModal, setOpenModal] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const tableTitle = 'Report G0003';

  const exportToTxt = (values) => {
    setLoading(true)
    let a = document.createElement('a')
    a.href = process.env.REACT_APP_BASE_API + generateDataApi.downloadTxt(endOfMonth(new Date(period)).toISOString(), endOfMonth(new Date(period)).toISOString(), 'M', values.reporterCode)
    a.click()
    setLoading(false)
    setOpenModal(false)
  }

  return (
    <div className="card">
      <div className="table-header">
        <div className="info-cell">
          <div className="d-flex justify-content-between">
            <div>{tableTitle}</div>
            <div>
              {format(new Date(period), 'MMMM yyyy')}
            </div>
          </div>
        </div>
        <div className="action-cell">
          <button onClick={() => setOpenModal(true)} className={`${styles.btnExportTxt} btn`}>
            <TxtIcon className="mr-2" size="28" />
            Export to Text
          </button>
          <Dialog
            onClose={() => setOpenModal(false)}
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <form onSubmit={handleSubmit(exportToTxt)}>
                <HookTextField
                  label='Reporter Code'
                  ref={register}
                  name="reporterCode"
                  error={errors.reporterCode}
                  helperText={errors.reporterCode?.message}
                />
                <div className="d-flex justify-content-end">
                  <button type="button" className="btn btn-danger mr-2" onClick={() => setOpenModal(false)}>Cancel</button>
                  <button className="btn btn-primary">Download</button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="report-table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Kota Asal Pengiriman</TableCell>
              <TableCell>Kota Tujuan</TableCell>
              <TableCell>Nama Penerima</TableCell>
              <TableCell>Nama Pengirim</TableCell>
              <TableCell>Total Transaksi</TableCell>
              <TableCell>Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => renderRow(row, index))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default TableComponent
