import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../components/Table/Table';
import { format } from 'date-fns';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, TRANSACTION_LOG_MODULE, FILTER_TABLE } from '../../../utils/constants/actionTypes';
import { combineBy } from '../../../utils/helpers/combineBy';
import ActorSelect from '../../../components/Select/ActorSelect';
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { transactionLogApi } from '../../../services/transactionLogApi';

const MainTab = () => {
  const dispatch = useDispatch();

  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total,
    filter,
  } = useSelector(state => state.logs.transaction);

  const getTransactionList = useMemo(
    () => AwesomeDebouncePromise(transactionLogApi.list, 500),
    [],
  )

  const load = useCallback(
    () => {
      const search = filter.actor && typeof filter.actor === 'object' ? `actorModel|${filter.actor.actorModel},actor|${filter.actor.value}` : null;
      dispatch({
        module: TRANSACTION_LOG_MODULE,
        type: MAIN_TAB_LOAD,
        call: getTransactionList,
        args: [
          currentPage,
          limit,
          sort,
          search
        ]
      }).catch(() => { });
    },
    [currentPage, limit, sort, filter, dispatch, getTransactionList],
  )

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: TRANSACTION_LOG_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const table = () => {
    return {
      inProgress,
      data: items,
      sort,
      limit,
      total,
      currentPage,
      columns: [
        {
          title: 'Actor', key: 'actor', name: 'actor', sortable: false, render: function (v, row) {
            let str;
            if (v) {
              str = combineBy([v.firstName, v.lastName, `(${row.actorModel})`, row.actorModel === 'User' ? `| ${v.uniqueId}` : '']);
            } else {
              str = '-';
            }
            return str;
          }
        },
        { title: 'Event Name', key: 'transactions', name: 'transactions' },
        {
          title: 'Desc', key: 'description', name: 'description'
        },
        {
          title: 'Code', key: 'trn_cd', name: 'trn_cd'
        },
        {
          title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
            return format(new Date(v), 'yyyy-MM-dd HH:mm:ss');
          }
        },
      ]
    };
  }

  useEffect(() => {
    load();
  }, [load])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table()}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
          <div className="row">
            <div className="col-12">
              <ActorSelect
                value={filter.actor}
                onChange={onFilter('actor')}
              />
            </div>
          </div>
        </Table>
      </div>
    </div>
  )
}

export default MainTab;
