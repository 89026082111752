import React, { useCallback, useEffect } from 'react'
import Table from '../../../components/Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns'
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, ADD_DELETE_DATA, REMOVE_DELETE_DATA, DELETE, BANK_MODULE, CREATE_TAB } from '../../../utils/constants/actionTypes';
import ButtonDispatch from '../../../components/ButtonDispatch';
import Image from '../../../components/Table/DataImage';
import { bankApi } from '../../../services/bankApi';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total,
    deleteRowId,
    reload
  } = useSelector(state => state.bank);

  const load = useCallback(
    () => {
      dispatch({ module: BANK_MODULE, type: MAIN_TAB_LOAD, call: bankApi.list, args: [currentPage, limit, sort] });
    },
    [currentPage, limit, sort, dispatch],
  );

  const createTab = (key, title) => {
    dispatch({
      module: BANK_MODULE,
      type: CREATE_TAB,
      key,
      title
    });
  }

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (reload) load();
  }, [reload, load]);

  const deleteSelected = () => {
    if (deleteRowId) dispatch({ module: BANK_MODULE, type: DELETE, call: bankApi.delete, args: [deleteRowId] });
  }

  const setTableConfig = type => (value) => {
    dispatch({
      module: BANK_MODULE,
      type: type,
      value,
    });
  }

  const table = () => ({
    inProgress,
    data: items,
    sort,
    limit,
    total,
    currentPage,
    columns: [
      { title: 'Name', key: 'name', name: 'name' },
      { title: 'Code', key: 'code', name: 'code' },
      { title: 'Abbr', key: 'abbr', name: 'abbr' },
      {
        title: 'Logo', key: 'logo', name: 'logo', render: function (v, row) {
          return <Image src={v ? v.url : ''} />
        }
      },
      {
        title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd')
        }
      },
      {
        title: 'Action', key: '_id', name: 'action', className: 'action-column', sortable: false, render: (v, row) => {
          return (
            <div className="d-flex">
              <button
                className="btn btn-icon btn-icon-only btn-sm btn-primary"
                onClick={() => createTab(v, row.name)}
              >
                <i className="pe-7s-tools btn-icon-wrapper"></i>
              </button>
              <div className="btn-group ml-1">
                {deleteRowId === v ? (
                  <React.Fragment>
                    <ButtonDispatch type={REMOVE_DELETE_DATA} module={BANK_MODULE} className="btn btn-sm btn-danger lh-1">No</ButtonDispatch>
                    <button onClick={deleteSelected} type="button" className="btn btn-sm btn-danger lh-1 py-0">Yes, Delete</button>
                  </React.Fragment>
                ) : (
                  <ButtonDispatch className="btn btn-icon btn-icon-only btn-sm btn-danger" args={{ _id: v }} module={BANK_MODULE} type={ADD_DELETE_DATA}>
                    <i className="pe-7s-trash btn-icon-wrapper"></i>
                  </ButtonDispatch>
                )}
              </div>
            </div>
          )
        }
      }
    ]
  })

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table()}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        />
      </div>
    </div>
  )
}

export default MainTab;
