import React from "react";

class InputImage extends React.Component {
  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
  }

  state = {
    file: undefined,
    previousFile: undefined,
    imagePreviewUrl: undefined,
    imagePreviousUrl: undefined,

    imagePlaceholderUrl: process.env.PUBLIC_URL + '/assets/images/img-placeholder.webp'
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      imagePreviousUrl: nextProps.previousUrl
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.previousUrl !== prevProps.previousUrl) {
      this.clear();
    }

    if (this.props.field && !this.props.field.value && this.state.file) {
      this.setState({
        file: undefined,
        imagePreviewUrl: undefined,
      });
      this.fileUpload.current.value = "";
    }
  }

  reader = (file, cb) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      let res = reader.result;
      cb(res);
    };
    reader.readAsDataURL(file);
  }

  showFileUpload = () => {
    if (this.fileUpload) {
      this.fileUpload.current.click();
    }
  }

  handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      this.reader(file, res => {
        this.setState({
          file: file,
          imagePreviewUrl: res
        });
      });
      if (typeof this.props.onChange === 'function') this.props.onChange(file);
      if (typeof this.props.setFieldValue === 'function') this.props.setFieldValue(this.props.field.name, file);
    }
  }

  clear = () => {
    this.setState({
      file: undefined,
      imagePreviewUrl: undefined,
    });
    this.fileUpload.current.value = "";
    if (typeof this.props.onChange === 'function') this.props.onChange(null);
    if (typeof this.props.setFieldValue === 'function') this.props.setFieldValue(this.props.field.name, '');
  }

  showPreloadImage() {
    const { errorMessage } = this.props;
    const { file, imagePreviewUrl, imagePreviousUrl, imagePlaceholderUrl } = this.state;
    if (file && !errorMessage) {
      return imagePreviewUrl
    }
    if (imagePreviousUrl) {
      return imagePreviousUrl
    } else {
      return imagePlaceholderUrl
    }
  }

  render() {
    const {
      errorMessage,
      format,
      type,
      remove,
      className
    } = this.props;
    const name = this.props.name;
    
    return (
      <div className={`position-relative form-group input-image-container ${className ? className : ''} ${errorMessage && this.state.file ? 'is-invalid' : ''} ${type === 'landscape' ? 'landscape' : ''}`}>
        <label className="text-capitalize" htmlFor={name}>Select File</label>
        <input
          id={name}
          name={name}
          type="file"
          onChange={this.handleImageChange}
          ref={this.fileUpload}
          className="form-control-file"
          accept={format}
        />
        <div className="img-preview-wrapper">
          <div className="img-preview" onClick={this.showFileUpload}>
            <img src={this.showPreloadImage()} alt="" />
          </div>
          {errorMessage && this.state.file ? (
            <em className="error invalid-feedback">{errorMessage}</em>
          ) : null}
          <small className="form-text text-muted">Supported Format: {format}</small>
        </div>
        {this.state.file && remove !== false ? (
          <div className="btn-wrapper">
            <div onClick={this.clear} className="remove-button">
              <button className="btn btn-outline-danger" type="button">Remove</button>
            </div>
            {/* <div className="select-button">
              <button onClick={this.showFileUpload} className="btn btn-primary" type="button">Select</button>
            </div> */}
          </div>
        ) : null}
      </div>
    );
  }
}

export default InputImage;
