import agent from "./agent";

export const qrisApi = {
  getQrisFile: ({ type, date }) => {
    let url = `/artajasa/get-rpt-qris-file`;
    if (type) {
      url += `?type=${type}`;
    }
    if (date) {
      url += `&date=${date}`
    }
    return agent.requests.get(url);
  },
  getQrisRecon: ({ date }) => {
    let url = `/artajasa/rpt-recon`;
    if (date) {
      url += `?rptDate=${date}`;
    }
    return agent.requests.get(url);
  },
  reconnectAJ: () => agent.requests.get('/artajasa/mount-sftp')
};
