import React from 'react';
import { format } from 'date-fns'

const NewsCard = ({ news }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="form-group">
          <label className="text-grey mb-1">Title</label>
          <p className="mb-0 font-weight-semibold">{news.title}</p>
        </div>
        <div className="form-group">
          <label className="text-grey mb-1">Date</label>
          <p className="mb-0 font-weight-semibold">{format(new Date(news.date), 'dd MMMM yyyy')}</p>
        </div>
        <div className="form-group">
          <label className="text-grey mb-1">Content</label>
          <p className="mb-0 font-weight-semibold">{news.content}</p>
        </div>
      </div>
    </div>
  )
}

export default NewsCard
