import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  GET_DETAIL,
  IMAGE_FORMATS,
  PARTNER_SETTLEMENT_MODULE
} from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import IdentityImage from '../../../components/IdentityImage';
import { format } from 'date-fns'
import { toIDR } from '../../../utils/helpers/currency';
import StatusHistory from '../../../components/StatusHistory';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import roleHelper, { APPROVE_PARTNER_SETTLEMENT, REJECT_PARTNER_SETTLEMENT, EXECUTE_PARTNER_SETTLEMENT_WITHOUT_BANK_TRANSFER, EXECUTE_PARTNER_SETTLEMENT_WITH_BANK_TRANSFER } from '../../../utils/helpers/roleHelper';
import useMountedState from '../../../components/HooksUse/useMountedState';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import PopUp from '../../../components/PopUp';
import HookImage from '../../../components/Forms/HookImage';
import FormField from '../../../components/Forms/FormField';
import AllStatus from '../../../utils/constants/enums/status';
import { partnerSettlementApi } from '../../../services/partnerSettlementApi';

const getClass = status => {
  let className = 'badge-primary';
  if (status === AllStatus.REQUEST || status === AllStatus.PROCESSING) {
    className = 'badge-warning'
  } else if (status === AllStatus.COMPLETED) {
    className = 'badge-success'
  } else if (status === AllStatus.REJECTED || status === AllStatus.FAILED) {
    className = 'badge-danger'
  }
  return className
}

const Detail = ({ index }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState()
  const { key, loading } = useSelector(state => state.partner.settlement.tabs[index]);
  const { currentAdmin: admin } = useSelector(state => state.common);

  const [isUpdating, setIsUpdating] = useState(false)
  const [showPopUp, setShowPopUp] = useState(false)

  const {
    value: { data: { partnerSettlements } = {} } = {},
    execute: getDetail
  } = useAsync(useCallback(
    () => dispatch({
      module: PARTNER_SETTLEMENT_MODULE,
      type: GET_DETAIL,
      call: partnerSettlementApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const onExecute = () => {
    setIsUpdating(true);
    dispatch({ type: null, call: partnerSettlementApi.execute, args: [key] })
      .then(() => {
        if (isMounted()) getDetail()
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsUpdating(false)
      });
  }

  const onReject = (e, remark) => {
    setIsUpdating(true);
    dispatch({ type: null, call: partnerSettlementApi.reject, args: [key, remark] })
      .then(() => {
        if (isMounted()) getDetail()
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsUpdating(false)
      });
  }

  const onApprove = () => {
    setIsUpdating(true);
    dispatch({ type: null, call: partnerSettlementApi.approve, args: [key] })
      .then(() => {
        if (isMounted()) getDetail()
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsUpdating(false);
      });
  }

  const onExecuteWithoutTransfer = (file) => {
    setIsUpdating(true);
    dispatch({ type: null, call: partnerSettlementApi.approveWithoutTransfer, args: [key, { transferProof: file }] })
      .then(() => {
        if (isMounted()) {
          setShowPopUp(false)
          getDetail()
        }
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsUpdating(false);
      });
  }

  return loading && !partnerSettlements ?
    null
    :
    partnerSettlements ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <LoadingBlocker in={isUpdating} />
            <div className="card-body">
              <h5 className="card-title mb-3">Applicant Info</h5>
              <div className="form-group">
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Partner ID</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{partnerSettlements.applicant.partnerId}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Partner Name</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{partnerSettlements.applicant.name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Field</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{partnerSettlements.applicant.field}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Address</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{partnerSettlements.applicant.address}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Integration Type</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{partnerSettlements.applicant.integrationType}</p>
                  </div>
                </div>
              </div>
            </div>
            <BankAccount bankAccount={partnerSettlements.bankAccount} />
            <hr className="my-0" />
            <div className="card-body ">
              <h5 className="card-title mb-3">Settlement Info</h5>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Trans. Code</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{partnerSettlements.code}</p>
                </div>
              </div>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Date</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{format(new Date(partnerSettlements.createdAt), 'dd MMMM yyyy')}</p>
                </div>
              </div>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Code Number</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{partnerSettlements.codeNumber}</p>
                </div>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Current Status</label>
                </div>
                <div className="col-8 text-right">
                  <span className={`badge ${getClass(partnerSettlements.status)}`}>{partnerSettlements.status}</span>
                </div>
              </div>
              {
                partnerSettlements.status === AllStatus.REJECTED
                &&
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Remarks</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{partnerSettlements.remarks ?? '-'}</p>
                  </div>
                </div>
              }
              <div className="row align-items-center mt-3">
                <div className="col-4">
                  <label className="text-grey mb-0">Amount</label>
                </div>
                <div className="col-8 text-right">
                  <span className="text-grey pr-1">Rp</span>
                  <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(partnerSettlements.amount, false)}</span>
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-4">
                  <label className="text-grey mb-0">Fee</label>
                </div>
                <div className="col-8 text-right">
                  <span className="text-grey pr-1">Rp</span>
                  <span className="h4 mb-0 font-weight-bold text-muted">{toIDR(partnerSettlements.fee, false)}</span>
                </div>
              </div>
              {
                partnerSettlements.transferProof
                &&
                <div className="mt-3">
                  <label className="text-grey">Uploaded File</label>
                  <IdentityImage image={partnerSettlements.transferProof.url} />
                </div>
              }

              {
                (roleHelper.hasAccess(admin.role, EXECUTE_PARTNER_SETTLEMENT_WITH_BANK_TRANSFER) && partnerSettlements.status === AllStatus.REQUEST)
                &&
                <button className="btn btn-outline-primary mt-2 w-100" onClick={onExecute}>Execute with Bank Transfer API (Request to Supervisor)</button>
              }
              {
                (roleHelper.hasAccess(admin.role, EXECUTE_PARTNER_SETTLEMENT_WITHOUT_BANK_TRANSFER) && partnerSettlements.status === AllStatus.REQUEST)
                &&
                <button onClick={() => setShowPopUp(true)} className="btn btn-primary mt-2 w-100">Execute Without Bank Transfer API (Request Upload Bukti Kirim)</button>
              }

              {
                (roleHelper.hasAccess(admin.role, APPROVE_PARTNER_SETTLEMENT) && partnerSettlements.status === AllStatus.PROCESSING)
                &&
                <button onClick={onApprove} className="btn btn-primary mt-2 w-100">Approve</button>
              }

              {
                (roleHelper.hasAccess(admin.role, REJECT_PARTNER_SETTLEMENT) && (partnerSettlements.status === AllStatus.REQUEST || partnerSettlements.status === AllStatus.PROCESSING))
                &&
                <DeclineDialog className="btn btn-outline-danger mt-2 w-100" onSubmit={onReject} />
              }

              <PopUp in={showPopUp} onClose={() => setShowPopUp(false)}>
                <UploadTransferForm
                  onCancel={() => setShowPopUp(false)}
                  onSubmit={onExecuteWithoutTransfer}
                  loading={isUpdating}
                />
              </PopUp>
            </div>

          </div>
        </div>
        <div className="col-12 col-lg-6">
          <StatusHistory
            statusHistories={partnerSettlements.statusHistories}
            warningType={AllStatus.REQUEST}
            dangerType={AllStatus.REJECTED}
          />
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

const UploadTransferForm = ({
  onCancel = () => { },
  onSubmit,
  loading
}) => {
  const [file, setFile] = useState(null)

  return (
    <div className="main-card card">
      <div className="card-body">
        <h5 className="card-title">Upload Bukti Kirim</h5>
        <form className="p-3" onSubmit={(e) => {
          e.preventDefault()
          if (typeof onSubmit === 'function') onSubmit(file)
        }}>
          <HookImage
            label="Select File"
            row
            name="logo"
            format={IMAGE_FORMATS}
            onChange={(v) => setFile(v)}
            helperText={`Format Didukung: ${IMAGE_FORMATS.join(', ')}`}
            onClear={() => setFile(null)}
          />
          <FormField row>
            <LoadingBtn loading={loading} disabled={!file || loading} type="submit" className="btn btn-primary mr-2">Submit</LoadingBtn>
            <button type="button" onClick={onCancel} className="btn btn-danger">Cancel</button>
          </FormField>
        </form>
      </div>
    </div>
  )
}

const BankAccount = ({ bankAccount }) => (
  <div className="card-body">
    <h5 className="card-title mb-3">Bank Account</h5>
    <div className="bank-accounts-wrapper">
      <div className="bank-accounts-list">
        <div className="bank-account-data pr-0">
          <div className="bank-account-logo">
            <img src={bankAccount?.bank?.logo?.url ?? ''} alt={bankAccount?.bank?.abbr} />
          </div>
          <div>
            <p className="bank-account-bank">
              {bankAccount?.bank?.name ?? 'Bank Not Found'}
            </p>
            <p className="bank-account-name">
              {bankAccount?.accountName}
            </p>
            <p className="bank-account-number">
              {bankAccount?.accountNumber}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Detail
