import React from 'react';
import { Tabs, Tab } from '../../../../components/Tabs/Tabs';
import { useSelector, useDispatch } from 'react-redux';
import MainTab from './MainTab';
import { CHANGE_TAB, CONFIG_MODULE } from '../../../../utils/constants/actionTypes';
import { Helmet } from 'react-helmet';
import PPOBHome from './PPOBHome';

const Settings = () => {
  const { activeTabKey } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const handleTab = type => key => dispatch({ module: CONFIG_MODULE, type: type, key: key })

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-config icon-gradient bg-sunny-morning"></i>
            </div>
            <div>
              PPOB Settings
              <div className="page-title-subheading">Dipay PPOB Settings, make sure you know how this feature works before do any changes.</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab />
        </Tab>
        <Tab title="Set PPOB Position">
          <PPOBHome />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Settings;
