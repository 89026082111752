class ApprovedBy {
  static SYSTEM = 'SYSTEM';
  static ADMIN = 'ADMIN';

  static getStr(en) {
    switch (en) {
      case ApprovedBy.SYSTEM:
        return 'System';
      case ApprovedBy.ADMIN:
        return 'Admin';
      default:
        return 'Unknown';
    }
  }
}

const ApprovedByOpts = [
  { value: ApprovedBy.SYSTEM, label: ApprovedBy.getStr(ApprovedBy.SYSTEM) },
  { value: ApprovedBy.ADMIN, label: ApprovedBy.getStr(ApprovedBy.ADMIN) }
];

export { ApprovedBy, ApprovedByOpts };
