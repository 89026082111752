import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DISCLAIMER_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import EvyTextField from '../../../components/Forms/EvyTextField';
import { format } from 'date-fns'
import CollapseCard from '../../../components/CollapseCard';
import Timeline from '../../../components/VerticalTimeline/Timeline';
import { DisclaimerTypes } from '../../../utils/constants/enums/disclaimerTypes';
import Form from './Form';
import useAsync from '../../../components/HooksUse/useAsync';
import { disclaimerApi } from '../../../services/disclaimerApi';

const DetailTab = ({ index }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.disclaimer.tabs[index]);

  const {
    value: { data: { disclaimer: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: DISCLAIMER_MODULE,
      type: GET_DETAIL,
      call: disclaimerApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const generateDesc = (value) => {
    return (
      <b className="d-block text-black-50 mt-3">
        Content: {value.content}
      </b>
    )
  }

  return loading && !item ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="card-title">Current Version</h5>
                <button
                  className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {isEditing ?
                    <><i className="pe-7s-close btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Cancel</span></> :
                    <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
                  }
                </button>
              </div>
              {isEditing ?
                <Form item={item} />
                :
                <>
                  <EvyTextField
                    readOnly
                    row
                    label="Type"
                    value={DisclaimerTypes.getStr(item.type)}
                  />
                  <EvyTextField
                    readOnly
                    row
                    label="Version"
                    value={item.version}
                  />
                  <EvyTextField
                    readOnly
                    row
                    label="Content"
                    value={item.content}
                  />
                  <EvyTextField
                    readOnly
                    row
                    label="Release Date"
                    value={format(new Date(item.date), 'dd-MM-yyyy')}
                  />
                </>
              }
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <CollapseCard
            title="Update Histories"
            defaultOpen
          >
            {item.updateHistories &&
              <Timeline>
                {item.updateHistories.slice(0).reverse().map((s, k) => (
                  <Timeline.Dot
                    key={k}
                    title={`Version: ${s.version}`}
                    date={format(new Date(s.date), 'dd MMM HH:mm:ss')}
                    desc={generateDesc(s)}
                  />
                ))}
              </Timeline>
            }
          </CollapseCard>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab
