import React, { Component } from 'react';

class EvyCheckbox extends Component {
  render() {
    const {
      id,
      label,
      onChange,
      // onBlur,
      // error,
      // helperText,
      className,
      checked,
      defaultChecked,
      disabled
    } = this.props;

    return (
      <div className={`form-group ${className || ''}`}>
        <div className="custom-checkbox custom-control">
          <input
            disabled={disabled}
            type="checkbox"
            id={id}
            className="custom-control-input"
            onChange={onChange}
            checked={checked}
            defaultChecked={defaultChecked}
          />
          <label className="custom-control-label" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
    )
  }
}

export default EvyCheckbox;
