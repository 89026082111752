export class RequestUpdateBusinessType {
  static UNLOCK = 'UNLOCK';
  static UNSUSPEND = 'UNSUSPEND';
  static CLOSED = 'CLOSED';
  static UNBLACKLIST = 'UNBLACKLIST';
  static REQUEST_VERIFY = 'REQUEST_VERIFY';
  static REQUEST_PTEN_VERIFY = 'REQUEST_PTEN_VERIFY';

  static getStr(en) {
    switch (en) {
      case RequestUpdateBusinessType.UNLOCK:
        return 'Unlock';
      case RequestUpdateBusinessType.UNSUSPEND:
        return 'Unsuspend';
      case RequestUpdateBusinessType.CLOSED:
        return 'Closed Account';
      case RequestUpdateBusinessType.UNBLACKLIST:
        return 'Unblacklist';
      case RequestUpdateBusinessType.REQUEST_VERIFY:
        return 'Business Verify';
      case RequestUpdateBusinessType.REQUEST_PTEN_VERIFY:
        return 'Business PTEN Verify';
      default:
        return 'Unknown';
    }
  }
}

export const RequestUpdateBusinessTypeOpts = [
  { value: RequestUpdateBusinessType.UNLOCK, label: RequestUpdateBusinessType.getStr(RequestUpdateBusinessType.UNLOCK) },
  { value: RequestUpdateBusinessType.UNSUSPEND, label: RequestUpdateBusinessType.getStr(RequestUpdateBusinessType.UNSUSPEND) },
  { value: RequestUpdateBusinessType.CLOSED, label: RequestUpdateBusinessType.getStr(RequestUpdateBusinessType.CLOSED) },
  { value: RequestUpdateBusinessType.UNBLACKLIST, label: RequestUpdateBusinessType.getStr(RequestUpdateBusinessType.UNBLACKLIST) },
  { value: RequestUpdateBusinessType.REQUEST_VERIFY, label: RequestUpdateBusinessType.getStr(RequestUpdateBusinessType.REQUEST_VERIFY) },
  { value: RequestUpdateBusinessType.REQUEST_PTEN_VERIFY, label: RequestUpdateBusinessType.getStr(RequestUpdateBusinessType.REQUEST_PTEN_VERIFY) }
];
