import React from 'react'
import styles from './history.module.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const StatusHistoryCard = ({ title, children, className, withFooter }) => (
  <div className={`main-card card ${className ? + ' ' + className : ''}`}>
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <div className={styles.historyContainer}>
        {
          children?.length ?
            <PerfectScrollbar>
              <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                {children}
              </div>
            </PerfectScrollbar>
            :
            <div className="text-center py-3">
              No Data
            </div>
        }
      </div>
    </div>
    {
      withFooter && <div className="card-footer">
        {withFooter}
      </div>
    }
  </div>
)
