import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const referralApi = {
  list: ({ page, limit, sort, status, startPeriod, endPeriod, search }) => {
    let url = `/referral-code/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startPeriod) {
      url += `&startPeriod=${startPeriod}`;
    }
    if (endPeriod) {
      url += `&endPeriod=${endPeriod}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (status === "ACTIVE") {
      url += `&isActive=true`;
    } else if (status === "INACTIVE") {
      url += `&isActive=false`;
    }

    return agent.requests.get(url)
  },
  referredList: ({ referred, id, page, limit, sort, connectedPartner, startPeriod, endPeriod, search }) => {
    let url = `/referral-code/${id}/detail?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (referred) {
      url += `&referred=${referred}`;
    }
    if (startPeriod) {
      url += `&startPeriod=${startPeriod}`;
    }
    if (endPeriod) {
      url += `&endPeriod=${endPeriod}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (connectedPartner) {
      url += `&connectedPartner=${connectedPartner}`;
    }

    return agent.requests.get(url)
  },
  create: (data) => {
    return agent.requests.post(`/referral-code/create-referral-code`, data);
  },
  update: (id, data) => {
    return agent.requests.patch(`/referral-code/${id}/update`, data);
  },
  detail: (id) => {
    return agent.requests.get(`/referral-code/${id}/detail-referral-code`);
  }
}
