import React, { useCallback, useState } from 'react'
import { bankAccountApi } from '../../../../services/bankAccountApi'
import { BANK_ACCOUNT_MODULE, DERIVE } from '../../../../utils/constants/actionTypes'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import useAsync from '../../../../components/HooksUse/useAsync'
import { toast } from 'react-toastify'
import useMountedState from '../../../../components/HooksUse/useMountedState'
import { partnerApi } from '../../../../services/partnerApi'
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker'

const BankAccounts = ({
  item,
  partnerId,
  onSuccess
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loadingBankDefault, setLoadingBankDefault] = useState(false);
  const isMounted = useMountedState();

  const {
    value: { data: { bankAccounts = [] } = {} } = {},
    pending: loadingBank
  } = useAsync(useCallback(
    () => dispatch({ type: null, call: bankAccountApi.list, args: [0, 0, 'createdAt|desc', partnerId, 'Partner'] }),
    [dispatch, partnerId]), true)

  const handleClickAddAccount = () => {
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: DERIVE,
      key: 1,
      ownerId: partnerId,
      ownerModel: 'Partner',
      from: location.pathname,
    }).then(() => history.push('/internal/bank-accounts'))
  }

  const handleEditBankAccount = (bankAccountData) => {
    if (!bankAccountData?._id) return toast("Partner Bank Account Not Found", { type: 'error' });
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: DERIVE,
      createNewTab: true,
      key: bankAccountData._id,
      title: bankAccountData.accountName,
      ownerId: bankAccountData.owner?._id,
      ownerModel: 'Partner',
      from: location.pathname,
    }).then(() => history.push('/internal/bank-accounts'))
  }

  const handleSetDefaultBank = (bankAccId) => {
    setLoadingBankDefault(true);
    dispatch({ type: null, call: partnerApi.setDefaultBankAccount, args: [partnerId, bankAccId] })
      .then(() => {
        if (isMounted()) onSuccess()
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setLoadingBankDefault(false);
      });
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Bank Account</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-primary`}
          onClick={handleClickAddAccount}
        >
          Add Account
          <LoadingBlocker in={loadingBank || loadingBankDefault} />
        </button>
      </div>
      {
        bankAccounts.length
          ?
          <div className="bank-accounts-wrapper">
            {bankAccounts.map((row, i) =>
              <div className="bank-accounts-list" key={i}>
                <div className="bank-account-data pr-0">
                  <div className="bank-account-logo">
                    {row.bank
                      ? <img src={row.bank?.logo?.url ?? ''} alt={row.bank?.abbr} />
                      : 'Bank Not Found'
                    }
                  </div>
                  <div>
                    <p className="bank-account-bank">
                      {row.bank?.name ?? 'Bank Not Found'}
                    </p>
                    <p className="bank-account-name">
                      {row.accountName}
                    </p>
                    <p className="bank-account-number">
                      {row.accountNumber}
                    </p>
                  </div>
                </div>
                {
                  row._id === item.defaultBankAccount?._id ?
                    <div className="bank-account-default">
                      Default
                    </div>
                    :
                    <button className="btn bank-account-set-default p-0" onClick={() => handleSetDefaultBank(row._id)}>Set Default</button>
                }
                <button className="btn bank-account-set-default p-0 ml-2" onClick={() => handleEditBankAccount(row)}>Edit</button>
              </div>
            )}
          </div>
          :
          <div>
            <p className="mb-0">No Account Found</p>
          </div>
      }
    </div>
  )
}

export default BankAccounts
