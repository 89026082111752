import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_BILLERS_BANK_TRANSFER_FEE,
  ROUTE_BILLERS_PPOB_CHECKPRICE,
  ROUTE_BILLERS_PPOB_LIST,
  ROUTE_BILLERS_PROMO_CONFIG,
  ROUTE_BILLERS_PPOB_SETTINGS
} from "../utils/helpers/roleHelper";

import PPOB from '../pages/BillersConfig/PPOB/List'
import CheckPpobPrice from '../pages/BillersConfig/PPOB/CheckPpobPrice'
import PromoConfig from '../pages/BillersConfig/PromoConfig'
import BankTransferFee from '../pages/BillersConfig/BankTransferFee'
import Settings from "../pages/BillersConfig/PPOB/Settings";

export const billers = {
  type: NAVMENU,
  name: 'Biller Configuration',
  icon: 'pe-7s-cash',
  children: [
    {
      type: NAVMENU,
      name: 'PPOB Config',
      children: [
        {
          type: NAVLINK,
          name: 'List',
          url: '/billers/ppob/list',
          routeTitle: ROUTE_BILLERS_PPOB_LIST,
          component: PPOB
        },
        {
          type: NAVLINK,
          name: 'Check PPOB Price',
          url: '/billers/ppob/check-price',
          routeTitle: ROUTE_BILLERS_PPOB_CHECKPRICE,
          component: CheckPpobPrice
        },
        {
          type: NAVLINK,
          name: 'Settings',
          url: '/billers/ppob/settings',
          routeTitle: ROUTE_BILLERS_PPOB_SETTINGS,
          component: Settings
        },
      ]
    },
    {
      type: NAVLINK,
      name: 'Promo Config',
      url: '/billers/promo-config',
      routeTitle: ROUTE_BILLERS_PROMO_CONFIG,
      component: PromoConfig
    },
    {
      type: NAVLINK,
      name: 'Transfer Config',
      url: '/billers/bank-transfer-fee',
      routeTitle: ROUTE_BILLERS_BANK_TRANSFER_FEE,
      component: BankTransferFee
    },
  ]
}
