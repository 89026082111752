import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUSINESS_SETTLEMENT_MODULE,
  CHANGE_TAB,
  CLOSE_TAB
} from '../../../utils/constants/actionTypes';
import { Tab, Tabs } from '../../../components/Tabs/Tabs';
import DetailTab from './DetailTab';
import ListTab from './ListTab';
import MainTab from './MainTab';

function Index() {
  const dispatch = useDispatch();
  const {
    activeTabKey,
    tabs
  } = useSelector(state => state.businesses.settlement);

  const handleTab = type => key => dispatch({ module: BUSINESS_SETTLEMENT_MODULE, type: type, key: key });

  const handleDetailTab = (row) => {
    if (row.listType === "bulk") {
      return <ListTab
        idSettlement={row.key}
        code={row.code}
        statusSettlement={row.status}
        listType={row.listType}
      />
    }
    if (row.listType === "detail") return <DetailTab index={row.key} />
    return null;
  }

  return (
    <div className="app-main__inner">
      <Helmet>
        <title>Businesses Settlement</title>
      </Helmet>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-refresh-2 icon-gradient bg-sunny-morning"></i>
            </div>
            <div>
              Businesses Settlement
              <div className="page-title-subheading">Full Businesses Settlement Control</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={activeTabKey}
        onClick={handleTab(CHANGE_TAB)}
      >
        <Tab title="Main">
          <MainTab listType="bulks" />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.hasOwnProperty('status') ? row.code : row.title}
            isLoading={row.loading}
            onClose={() => handleTab(CLOSE_TAB)(row.key)}
            onClick={() => handleTab(CHANGE_TAB)(row.key)}
            active={activeTabKey === row.key}
          >
            {handleDetailTab(row, key)}
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default Index
