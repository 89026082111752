import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adminApi } from '../../services/adminApi';
import { merchantApi } from '../../services/merchantApi';
import { userApi } from '../../services/userApi';
import { AdminRole } from '../../utils/constants/enums/adminRoles';
import { combineBy } from '../../utils/helpers/combineBy';
import EvySelectField from '../Forms/EvySelectField';

const mapDispatchToProps = dispatch => ({
  getUsers: (page, limit, sort, search) =>
    dispatch({
      module: null,
      type: null,
      call: userApi.list,
      args: [
        {
          page,
          limit,
          sort,
          search: search ? `fullName|${search},uniqueId|${search}` : search
        }
      ]
    }),
  getMerchants: (page, limit, sort, search) =>
    dispatch({
      module: null,
      type: null,
      call: merchantApi.search,
      args: [{
        page,
        limit,
        sort,
        search: search ? `firstName|${search},lastName|${search}` : search
      }]
    }),
  getAdmins: (page, limit, sort, search) =>
    dispatch({
      module: null,
      type: null,
      call: adminApi.list,
      args: [
        page,
        limit,
        sort,
        search ? `firstName|${search},lastName|${search}` : search
      ]
    }),
});

const customStyles = {
  menuList: (provided, state) => ({
    ...provided,
    display: 'flex'
  }),
}

class ActorSelect extends Component {
  state = {
    sort: 'firstName|asc',
    limit: 10,
    page: 1,
    options: [],
    search: '',
    isLoading: false,
    selectedOption: null,

    totalUsers: 0,
    totalMerchants: 0,
    totalAdmins: 0,
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.search !== prevState.search) this.loadData();
  }

  loadData = () => {
    let page = 1;
    this.setState({
      isLoading: true,
      options: [],
      page: page
    });

    const { limit, sort, search } = this.state;
    var promise1 = this.props.getUsers(page, limit, sort, search);
    var promise2 = this.props.getMerchants(page, limit, sort, search);
    var promise3 = this.props.getAdmins(page, limit, sort, search);

    Promise.all([promise1, promise2, promise3])
      .then(values => {
        this.setDataOpts(values);
      })
      .catch(err => {
        console.log('Promise Failed', err)
      })
      .finally(
        this.setState({
          isLoading: false
        })
      )
  }

  loadMoreData = () => {
    const { page, limit, sort, search, totalUsers, totalMerchants, totalAdmins } = this.state;

    let nextPage = this.state.page + 1;
    this.setState({
      page: nextPage,
      isLoading: true
    });

    var promise1 = (page * limit) < totalUsers ? this.props.getUsers(nextPage, limit, sort, search) : null;
    var promise2 = (page * limit) < totalMerchants ? this.props.getMerchants(nextPage, limit, sort, search) : null;
    var promise3 = (page * limit) < totalAdmins ? this.props.getAdmins(nextPage, limit, sort, search) : null;

    Promise.all([promise1, promise2, promise3])
      .then(values => {
        this.pushDataOpts(values);
      })
      .catch(err => {
        console.log('Promise Failed', err)
      })
      .finally(
        this.setState({
          isLoading: false
        })
      )
  }

  setDataOpts = payloads => {
    const { customOptions = [] } = this.props;
    const users = payloads[0] ? payloads[0].data.users : [];
    const totalUsers = payloads[0] ? payloads[0].data.count : [];
    const userOpts = this.generateUserOpts(users);

    const merchants = payloads[1] ? payloads[1].data.merchants : [];
    const totalMerchants = payloads[1] ? payloads[1].data.count : [];
    const merchantOpts = this.generateMerchantOpts(merchants);

    const admins = payloads[2] ? payloads[2].data.admins : [];
    const totalAdmins = payloads[2] ? payloads[2].data.count : [];
    const adminOpts = this.generateAdminOpts(admins);

    const newOpts = [
      {
        label: 'Custom Options',
        options: customOptions
      },
      {
        label: 'Users',
        options: userOpts
      },
      {
        label: 'Merchants',
        options: merchantOpts
      },
      {
        label: 'Admins',
        options: adminOpts
      },
    ];
    this.setState({
      options: newOpts,
      totalUsers,
      totalMerchants,
      totalAdmins,
    })
  }

  pushDataOpts = payloads => {
    const users = payloads[0] ? payloads[0].data.users : [];
    const totalUsers = payloads[0] ? payloads[0].data.count : [];
    const userOpts = [...this.state.options[1].options, ...this.generateUserOpts(users)];

    const merchants = payloads[1] ? payloads[1].data.merchants : [];
    const totalMerchants = payloads[1] ? payloads[1].data.count : [];
    const merchantOpts = [...this.state.options[2].options, ...this.generateMerchantOpts(merchants)];

    const admins = payloads[2] ? payloads[2].data.admins : [];
    const totalAdmins = payloads[2] ? payloads[2].data.count : [];
    const adminOpts = [...this.state.options[3].options, ...this.generateAdminOpts(admins)];

    const newOpts = [
      this.state.options[0],
      {
        label: 'Users',
        options: userOpts
      },
      {
        label: 'Merchants',
        options: merchantOpts
      },
      {
        label: 'Admins',
        options: adminOpts
      },
    ];
    this.setState({
      options: newOpts,
      totalUsers,
      totalMerchants,
      totalAdmins,
    })
  }

  generateUserOpts = users => users.map(user => ({
    value: user._id,
    label: `${combineBy([user.firstName, user.lastName])} | ${user.uniqueId}`,
    actorModel: 'User'
  }))

  generateMerchantOpts = merchants => merchants.map(merchant => ({
    value: merchant._id,
    label: `${combineBy([merchant.firstName, merchant.lastName])} | ${merchant.email}`,
    actorModel: 'Merchant'
  }))

  generateAdminOpts = admins => admins.map(admin => ({
    value: admin._id,
    label: `${combineBy([admin.firstName, admin.lastName])} | ${AdminRole.getStr(admin.role)}`,
    actorModel: 'Admin'
  }))

  handleSelectChange = opt => {
    const { onChange, value, returnOption } = this.props;

    this.setState({
      selectedOption: opt,
      search: ''
    });

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined' || returnOption
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  handleInputChange = v => {
    this.setState({
      search: v
    })
  }

  render() {
    const { error, helperText, value } = this.props;
    const { options, search, isLoading, selectedOption } = this.state;
    const chosenOption = typeof value !== 'undefined' ? value : selectedOption;

    return (
      <EvySelectField
        label="Select Actor"
        id="selectActor"
        options={options}
        onChange={opt => this.handleSelectChange(opt)}
        value={chosenOption}
        advanceSelect
        componentProps={{
          placeholder: 'Type anything..',
          inputValue: search,
          onInputChange: newValue => this.handleInputChange(newValue),
          isClearable: true,
          isLoading: isLoading,
          onMenuScrollToBottom: this.loadMoreData,
          styles: customStyles
        }}
        error={error}
        helperText={helperText}
      />
    )
  }
}

export default connect(null, mapDispatchToProps)(ActorSelect)
