import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const merchantApi = {
  list: ({ page, limit, sort, search }) => {
    let url = `/merchants/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  search: ({ page, limit, sort, search }) => {
    let url = `/merchants/search?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  totalRegistered: ({ fromDate, toDate, status }) =>
    agent.requests.get(`/merchants/total-registered?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&status=${status}`),
  setDefaultBankAccount: (merchantId, bankAccId) =>
    agent.requests.post(`/merchants/${merchantId}/set-default-bank-account`, { bankAccount: bankAccId }),
  setTemporaryPassword: (id, data) =>
    agent.requests.post(`/merchants/${id}/set-temporary-password`, data),
  uploadIdentificationCard: (id, file) =>
    agent.requests.post(`/merchants/${id}/upload-identification`, { cardPhoto: file }, true),
  uploadSelfPhoto: (id, file) =>
    agent.requests.post(`/merchants/${id}/upload-identification`, { selfPhoto: file }, true),
  validateBank: (id, data) => agent.requests.post(`/merchants/${id}/validate`, data),
  // settlementType: (id, data) => agent.requests.post(`/merchants/${id}/change-type`, data),
  changeAccountInfo: (id, data) => agent.requests.post(`/merchants/${id}/change-Account-Information`, data),
  activeMerchants: ({ fromDate, toDate }) =>
    agent.requests.get(`/merchants/total-active-merchants?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
  totalMerchantBalance: () =>
    agent.requests.get('/merchants/merchant-total-balance'),
}
