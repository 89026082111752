import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const configApi = {
  list: ({ page, limit, sort, search }) => {
    let url = `/configs/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
  update: data =>
    agent.requests.post(`/configs/update`, data),
  delete: id =>
    agent.requests.post(`/configs/${id}/delete`),
  setPosition: data =>
    agent.requests.post(`/configs/set-position`, { data }),
  getPositions: data =>
    agent.requests.get(`/configs/show-ppob`, data),
}
