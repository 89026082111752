import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { store, history } from './redux/store';
import { ConnectedRouter } from 'connected-react-router'
import { createRoot } from 'react-dom/client';

/**
 * -------------------------
 * @DipayAdmin
 * @Version @1.7.0
 * -------------------------
**/

// ReactDOM.render((
//   <Provider store={store}>
//     <ConnectedRouter history={history}>
//       <Router history={history}>
//         <Switch>
//           <Route path="/" component={App} />
//         </Switch>
//       </Router>
//     </ConnectedRouter>
//   </Provider>
// ), document.getElementById('root'));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </ConnectedRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
