import React, { useMemo, useState } from 'react';
import Login from './Login'
import VerifyOtp from './VerifyOtp';

const AuthLayout = () => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')
  const [timer, setTimer] = useState()

  const renderView = useMemo(() => {
    if (step === 1) return <Login setStep={setStep} setEmail={setEmail} setTimer={setTimer} />
    else if (step === 2) return <VerifyOtp setStep={setStep} email={email} timer={timer} setTimer={setTimer} />
  }, [step, email, timer])

  return (
    <div className="app-container app-theme-white body-tabs-shadow">
      <div className="app-container">
        <div className="h-100 bg-arielle-smile bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="mx-auto app-login-box col-md-8">
              <div className="app-logo-inverse mx-auto mb-3"></div>
              {renderView}
              <div className="text-center text-white opacity-8 mt-3">Copyright © Dipay {new Date().getFullYear()}</div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default AuthLayout;
