import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const promoConfigApi = {
  list: (page, limit, sort, category, type, status) => {
    let url = `/promo-configs/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (category) url += `&category=${category}`
    if (type) url += `&type=${type}`
    if (status) url += `&status=${status}`
    return agent.requests.get(url)
  },
  detail: (id) => agent.requests.get(`/promo-configs/${id}/detail`),
  create: (data) => agent.requests.post('/promo-configs/create', data),
  update: (id, data) => agent.requests.post(`/promo-configs/${id}/update`, data),
  getSubCategoryProduct: (category) => {
    let url = "/promo-configs/list-sub-category-product"
    if (category) url += `?category=${category}`;
    return agent.requests.get(url)
  },

  // Promo QRIS
  createPromoQRIS: (data) => agent.requests.post('/promo-qris/create', data),
  deletePromoQRIS: (data) => agent.requests.post('/promo-qris/delete-many', data),
  inActivePromoQRIS: (data) => agent.requests.post('/promo-qris/set-inactive', data),
  listPromoQRIS: ({ page, limit, sort, filter, search, type, subType, validFrom, validTo }) => {
    let url = `/promo-qris/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (filter) url += `&filter=${filter}`
    if (search) url += `&search=${search}`
    if (type) url += `&type=${type}`
    if (subType) url += `&subType=${subType}`
    if (validFrom) url += `&validFrom=${validFrom}`
    if (validTo) url += `&validTo=${validTo}`
    return agent.requests.get(url)
  },
}
