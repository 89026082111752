import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const topUpApi = {
  list: ({ page, limit, sort, search, startDate, endDate, source, partner, status }) => {
    let url = `/topups/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (source) {
      url += `&source=${source}`;
    }
    if (partner) {
      url += `&partner=${partner}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) => {
    return agent.requests.get(`/topups/${id}/detail`)
  },
  updateLimit: (id, body) => {
    return agent.requests.post(`/topups/${id}/update`, body)
  },
}
