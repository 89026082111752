import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const requestUpdateBusinessesApi = {
  list: ({ page, limit, sort, status, type, startDate, endDate }) => {
    let url = `/request-update-businesses/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  create: data =>
    agent.requests.post(`/request-update-businesses/create`, data),
  detail: (id) =>
    agent.requests.get(`/request-update-businesses/${id}/detail`),
  approve: (id) =>
    agent.requests.post(`/request-update-businesses/${id}/approve`),
  reject: (id, remark) =>
    agent.requests.post(`/request-update-businesses/${id}/reject`, { remark }),
  updateSettlementStatus: (id) =>
    agent.requests.post(`/request-update-businesses/${id}/update-settlement-status`),
}
