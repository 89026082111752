import { AdminRole } from "../../utils/constants/enums/adminRoles";

export const ROUTE_NAVHEAD_ONE = 'ROUTE_NAVHEAD_ONE';

export const ROUTE_DASHBOARD = 'ROUTE_DASHBOARD';

export const ROUTE_NAVHEAD_TWO = 'ROUTE_NAVHEAD_TWO';

export const ROUTE_USERS_LIST = 'ROUTE_USERS_LIST';
export const ROUTE_USERS_REFERRALS = 'ROUTE_USERS_REFERRALS';
export const ROUTE_USERS_CLOSED_ACCOUNT = 'ROUTE_USERS_CLOSED_ACCOUNT';
export const ROUTE_USERS_KYC_UPGRADE_REQUEST = 'ROUTE_USERS_KYC_UPGRADE_REQUEST';
export const ROUTE_USERS_KYC_HISTORIES = 'ROUTE_USERS_KYC_HISTORIES';
export const ROUTE_USERS_APPROVAL = 'ROUTE_USERS_APPROVAL';

export const ROUTE_PARTNER_LIST = 'ROUTE_PARTNER_LIST';
export const ROUTE_PARTNER_SETTLEMENT = 'ROUTE_PARTNER_SETTLEMENT';
export const ROUTE_PARTNER_DONATION_LIST = 'ROUTE_PARTNER_DONATION_LIST';
export const ROUTE_PARTNER_DONATION_NEWS = 'ROUTE_PARTNER_DONATION_NEWS';
export const ROUTE_PARTNER_USER = 'ROUTE_PARTNER_USER';

export const ROUTE_BUSINESSES_LIST = 'ROUTE_BUSINESSES_LIST';
// export const ROUTE_PREVIOUS_BUSINESSES_LIST = 'ROUTE_PREVIOUS_BUSINESSES_LIST';
export const ROUTE_BUSINESS_REFERRAL_LIST = 'ROUTE_BUSINESS_REFERRAL_LIST';
export const ROUTE_BUSINESS_APPROVAL_LIST = 'ROUTE_BUSINESS_APPROVAL_LIST';
export const ROUTE_BUSINESS_VERIFY_LIST = 'ROUTE_BUSINESS_VERIFY_LIST';
export const ROUTE_BUSINESSES_CASHBACK = 'ROUTE_BUSINESSES_CASHBACK';
export const ROUTE_BUSINESS_REQUEST_UPDATE = 'ROUTE_BUSINESS_REQUEST_UPDATE';
export const ROUTE_BUSINESS_SETTLEMENT = 'ROUTE_BUSINESS_SETTLEMENT';

export const ROUTE_TRANSACTIONS_TOPUP = 'ROUTE_TRANSACTIONS_TOPUP';
export const ROUTE_TRANSACTIONS_TRANSFER_DIPAY = 'ROUTE_TRANSACTIONS_TRANSFER_DIPAY';
export const ROUTE_TRANSACTIONS_TRANSFER_BANK = 'ROUTE_TRANSACTIONS_TRANSFER_BANK';
export const ROUTE_TRANSACTIONS_PPOB_LIST = 'ROUTE_TRANSACTIONS_PPOB_LIST';
export const ROUTE_TRANSACTIONS_PPOB_AUTODEBIT = 'ROUTE_TRANSACTIONS_PPOB_AUTODEBIT';
export const ROUTE_TRANSACTIONS_PARTNER = 'ROUTE_TRANSACTIONS_PARTNER';
export const ROUTE_TRANSACTIONS_PARTNER_FEE = 'ROUTE_TRANSACTIONS_PARTNER_FEE';
export const ROUTE_TRANSACTIONS_REWARD = 'ROUTE_TRANSACTIONS_REWARD';
export const ROUTE_TRANSACTIONS_CASHBACK = 'ROUTE_TRANSACTIONS_CASHBACK';
export const ROUTE_TRANSACTIONS_QRIS_MPM = 'ROUTE_TRANSACTIONS_QRIS_MPM';
export const ROUTE_TRANSACTIONS_QRIS_CPM = 'ROUTE_TRANSACTIONS_QRIS_CPM';
export const ROUTE_TRANSACTIONS_QRIS_TUNTAS = 'ROUTE_TRANSACTIONS_QRIS_TUNTAS';
export const ROUTE_TRANSACTIONS_MDR = 'ROUTE_TRANSACTIONS_MDR';
export const ROUTE_TRANSACTIONS_SETTLEMENT = 'ROUTE_TRANSACTIONS_SETTLEMENT';
export const ROUTE_TRANSACTIONS_REFUNDS = 'ROUTE_TRANSACTIONS_REFUNDS';
export const ROUTE_TRANSACTIONS_TOPUP_TRANSFER_PARTER = 'ROUTE_TRANSACTIONS_TOPUP_TRANSFER_PARTER';
export const ROUTE_TRANSACTIONS_INJECTION_ONETOONE = 'ROUTE_TRANSACTIONS_INJECTION_ONETOONE';
export const ROUTE_TRANSACTIONS_INJECTION_BULK = 'ROUTE_TRANSACTIONS_INJECTION_BULK';
export const ROUTE_TRANSACTIONS_DEDUCTION_ONETOONE = 'ROUTE_TRANSACTIONS_DEDUCTION_ONETOONE';

export const ROUTE_ARTICLES_USER = 'ROUTE_ARTICLES_USER';
export const ROUTE_ARTICLES_BISNIS = 'ROUTE_ARTICLES_BISNIS';
export const ROUTE_ARTICLES_BLOG = 'ROUTE_ARTICLES_BLOG';

export const ROUTE_ANNOUNCEMENT_USER = 'ROUTE_ANNOUNCEMENT_USER';
export const ROUTE_ANNOUNCEMENT_BISNIS = 'ROUTE_ANNOUNCEMENT_BISNIS';

export const ROUTE_VOUCHERS = 'ROUTE_VOUCHERS';
export const ROUTE_BANK_ACCOUNTS = 'ROUTE_BANK_ACCOUNTS';

export const ROUTE_REPORTS_GENERAL = 'ROUTE_REPORTS_GENERAL';
export const ROUTE_REPORTS_302 = 'ROUTE_REPORTS_302';
export const ROUTE_REPORTS_G0003 = 'ROUTE_REPORTS_G0003';
export const ROUTE_REPORTS_DUKCAPIL = 'ROUTE_REPORTS_DUKCAPIL';
export const ROUTE_REPORTS_BI_MERCHANT = "ROUTE_REPORTS_BI_MERCHANT";
export const ROUTE_REPORTS_BI_TRANSACTION = "ROUTE_REPORTS_BI_TRANSACTION";
export const ROUTE_REPORTS_QRIS_TO_BI = "ROUTE_REPORTS_QRIS_TO_BI";

export const ROUTE_CAREER = "ROUTE_CAREER";

export const ROUTE_QRIS_ARTAJASA_RECON = "ROUTE_QRIS_ARTAJASA_RECON";
export const ROUTE_QRIS_ARTAJASA_NON_RECON = "ROUTE_QRIS_ARTAJASA_NON_RECON";
export const ROUTE_QRIS_RINTIS_RECON = "ROUTE_QRIS_RINTIS_RECON";
export const ROUTE_QRIS_RINTIS_NON_RECON = "ROUTE_QRIS_RINTIS_NON_RECON";
export const ROUTE_QRIS_RINTIS_REKAP = "ROUTE_QRIS_RINTIS_REKAP";

export const ROUTE_NAVHEAD_THREE = 'ROUTE_NAVHEAD_THREE';

export const ROUTE_BILLERS_PPOB_LIST = 'ROUTE_BILLERS_PPOB_LIST';
export const ROUTE_BILLERS_PPOB_SETTINGS = 'ROUTE_BILLERS_PPOB_SETTINGS';
export const ROUTE_BILLERS_PPOB_CHECKPRICE = 'ROUTE_BILLERS_PPOB_CHECKPRICE';
export const ROUTE_BILLERS_PPOB_MAINTENANCE = 'ROUTE_BILLERS_PPOB_MAINTENANCE';
export const ROUTE_BILLERS_PPOB_AVAILABILITY = 'ROUTE_BILLERS_PPOB_AVAILABILITY';
export const ROUTE_BILLERS_PROMO_CONFIG = 'ROUTE_BILLERS_PROMO_CONFIG';
export const ROUTE_BILLERS_BANK_TRANSFER_FEE = 'ROUTE_BILLERS_BANK_TRANSFER_FEE';

export const ROUTE_PROMO_QRIS_CONFIG = 'ROUTE_PROMO_QRIS_CONFIG';

export const ROUTE_INTERNAL_PARAMETERS = 'ROUTE_INTERNAL_PARAMETERS';
export const ROUTE_INTERNAL_COMPANY = 'ROUTE_INTERNAL_COMPANY';
export const ROUTE_INTERNAL_ADMINS = 'ROUTE_INTERNAL_ADMINS';
export const ROUTE_INTERNAL_DISCLAIMER = 'ROUTE_INTERNAL_DISCLAIMER';
export const ROUTE_INTERNAL_BANKS = 'ROUTE_INTERNAL_BANKS';
export const ROUTE_INTERNAL_DTTOT = 'ROUTE_INTERNAL_DTTOT';
export const ROUTE_INTERNAL_REJECT_REASON = 'ROUTE_INTERNAL_REJECT_REASON';
export const ROUTE_INTERNAL_AML = 'ROUTE_INTERNAL_AML';
export const ROUTE_INTERNAL_NNS = 'ROUTE_INTERNAL_NNS';
export const ROUTE_NOTIFICATIONS_USER = 'ROUTE_NOTIFICATIONS_USER';
export const ROUTE_NOTIFICATIONS_MERCHANT = 'ROUTE_NOTIFICATIONS_MERCHANT';
export const ROUTE_LOGS_AUDIT = 'ROUTE_LOGS_AUDIT';
export const ROUTE_LOGS_ERROR = 'ROUTE_LOGS_ERROR';
export const ROUTE_LOGS_TRANSACTION = 'ROUTE_LOGS_TRANSACTION';
export const ROUTE_LOGS_AUTODEBIT = 'ROUTE_LOGS_AUTODEBIT';
export const ROUTE_IMAGE_TEMP = 'ROUTE_IMAGE_TEMP';

export const APPROVE_BUSINESSES_SETTLEMENT = 'APPROVE_BUSINESSES_SETTLEMENT';
export const REJECT_BUSINESSES_SETTLEMENT = 'REJECT_BUSINESSES_SETTLEMENT';

export const APPROVE_PARTNER_SETTLEMENT = 'APPROVE_PARTNER_SETTLEMENT';
export const REJECT_PARTNER_SETTLEMENT = 'REJECT_PARTNER_SETTLEMENT';
export const EXECUTE_PARTNER_SETTLEMENT_WITHOUT_BANK_TRANSFER = 'EXECUTE_PARTNER_SETTLEMENT_WITHOUT_BANK_TRANSFER';
export const EXECUTE_PARTNER_SETTLEMENT_WITH_BANK_TRANSFER = 'EXECUTE_PARTNER_SETTLEMENT_WITH_BANK_TRANSFER';
export const BUSINESS_APPROVAL = 'BUSINESS_APPROVAL';
export const CREATE_UPDATE_FORM = 'CREATE_UPDATE_FORM';
export const EXPORT_EXCEL = 'EXPORT_EXCEL';
export const FORM_DETAIL_APPROVAL = 'FORM_DETAIL_APPROVAL';
export const SUSPEND_USER = 'SUSPEND_USER';
export const UNLOCK_USER = 'UNLOCK_USER';
export const CLOSED_ACCOUNT_USER = 'CLOSED_ACCOUNT_USER';
export const UNLOCK_MERCHANT = 'UNLOCK_MERCHANT';
export const SUSPEND_MERCHANT = 'SUSPEND_MERCHANT';
export const BLACKLIST_MERCHANT = 'BLACKLIST_MERCHANT';
export const CLOSED_ACCOUNT_MERCHANT = 'CLOSED_ACCOUNT_MERCHANT';

export const ROUTE_BILLERS_PPOB_SET_AVAILABILITY = 'ROUTE_BILLERS_PPOB_SET_AVAILABILITY';
export const ROUTE_BILLERS_PPOB_SET_MAINTENANCE = 'ROUTE_BILLERS_PPOB_SET_MAINTENANCE';
export const ROUTE_BILLERS_PPOB_SET_HIDE = 'ROUTE_BILLERS_PPOB_SET_HIDE';
export const ROUTE_BILLERS_PPOB_SET_PROMO = 'ROUTE_BILLERS_PPOB_SET_PROMO';
export const ROUTE_BILLERS_PPOB_SET_NEW = 'ROUTE_BILLERS_PPOB_SET_NEW';
export const ROUTE_BILLERS_PPOB_SET_APPEARANCE = 'ROUTE_BILLERS_PPOB_SET_APPEARANCE';

export const ROUTE_DOCUMENTATION_CMS_API = 'ROUTE_DOCUMENTATION_CMS_API'
class roleHelper {
  static getRoles(title) {
    let allAccess, roles;
    switch (title) {
      case ROUTE_NAVHEAD_ONE:
        allAccess = true;
        break;
      case ROUTE_NAVHEAD_TWO:
        allAccess = true;
        break;
      case ROUTE_NAVHEAD_THREE:
        allAccess = true;
        break;
      // Dashboard /
      case ROUTE_DASHBOARD:
        allAccess = true;
        break;

      // Users /
      case ROUTE_USERS_LIST:
        allAccess = true;
        break;
      case ROUTE_USERS_REFERRALS:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT, AdminRole.MARKETING];
        break;
      case ROUTE_USERS_CLOSED_ACCOUNT:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;
      case ROUTE_USERS_KYC_UPGRADE_REQUEST:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;
      case ROUTE_USERS_KYC_HISTORIES:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;
      case ROUTE_USERS_APPROVAL:
        roles = [AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;

      // Partner /
      case ROUTE_PARTNER_LIST:
        allAccess = true;
        break;
      case ROUTE_PARTNER_SETTLEMENT:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case ROUTE_PARTNER_DONATION_LIST:
        allAccess = false;
        roles = []
        // roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING];
        break;
      case ROUTE_PARTNER_DONATION_NEWS:
        allAccess = false;
        roles = []
        // roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING];
        break;
      case ROUTE_PARTNER_USER:
        allAccess = false;
        roles = []
        // roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING];
        break;

      // Businesses /
      case ROUTE_BUSINESSES_LIST:
        allAccess = true;
        break;
      case ROUTE_BUSINESS_APPROVAL_LIST:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;
      case ROUTE_BUSINESS_VERIFY_LIST:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;
      case ROUTE_BUSINESS_REQUEST_UPDATE:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.ROOT];
        break;
      // case ROUTE_PREVIOUS_BUSINESSES_LIST:
      //   roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.RISK, AdminRole.TECH];
      //   break;
      case ROUTE_BUSINESSES_CASHBACK:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING];
        break;
      case ROUTE_BUSINESS_SETTLEMENT:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;

      // Transactions /
      case ROUTE_TRANSACTIONS_TOPUP:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_TOPUP_TRANSFER_PARTER:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_TRANSFER_DIPAY:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_TRANSFER_BANK:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_PPOB_LIST:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_PPOB_AUTODEBIT:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_PARTNER:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_PARTNER_FEE:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_CASHBACK:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_REWARD:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_QRIS_MPM:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_QRIS_CPM:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_QRIS_TUNTAS:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_MDR:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case ROUTE_TRANSACTIONS_SETTLEMENT:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case ROUTE_TRANSACTIONS_REFUNDS:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_TRANSACTIONS_INJECTION_ONETOONE:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case ROUTE_TRANSACTIONS_INJECTION_BULK:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case ROUTE_TRANSACTIONS_DEDUCTION_ONETOONE:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;

      // case ROUTE_REPORTS_BI_TRANSACTION:
      //   roles = [AdminRole.FINANCE, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.RISK]
      //   break;

      // Articles
      case ROUTE_ARTICLES_USER:
        roles = [AdminRole.ROOT, AdminRole.MARKETING, AdminRole.SUPERVISOR, AdminRole.TECH];
        break;
      case ROUTE_ARTICLES_BISNIS:
        roles = [AdminRole.ROOT, AdminRole.MARKETING, AdminRole.SUPERVISOR, AdminRole.TECH];
        break;
      case ROUTE_ARTICLES_BLOG:
        roles = [AdminRole.ROOT, AdminRole.MARKETING, AdminRole.SUPERVISOR, AdminRole.TECH];
        break;

      // Announcement
      case ROUTE_ANNOUNCEMENT_USER:
        roles = [AdminRole.ROOT, AdminRole.MARKETING, AdminRole.SUPERVISOR, AdminRole.TECH];
        break;
      case ROUTE_ANNOUNCEMENT_BISNIS:
        roles = [AdminRole.ROOT, AdminRole.MARKETING, AdminRole.SUPERVISOR, AdminRole.TECH];
        break;

      // Vouchers
      case ROUTE_VOUCHERS:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.FINANCE];
        break;

      // Reports
      case ROUTE_REPORTS_GENERAL:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_REPORTS_DUKCAPIL:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE];
        break;

      case ROUTE_REPORTS_302:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.RISK];
        break;
      case ROUTE_REPORTS_G0003:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.RISK];
        break;
      case ROUTE_REPORTS_QRIS_TO_BI:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.RISK]
        break;
      case ROUTE_REPORTS_BI_MERCHANT:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.RISK]
        break;

      case ROUTE_QRIS_ARTAJASA_RECON:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE]
        break;
      case ROUTE_QRIS_ARTAJASA_NON_RECON:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE]
        break;

      case ROUTE_QRIS_RINTIS_RECON:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE]
        break;
      case ROUTE_QRIS_RINTIS_NON_RECON:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE]
        break;
      case ROUTE_QRIS_RINTIS_REKAP:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE]
        break;

      // Careers
      case ROUTE_CAREER:
        allAccess = true;
        break;

      // Biller Configuration
      case ROUTE_BILLERS_PPOB_LIST:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_CHECKPRICE:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_SETTINGS:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;

      case ROUTE_BILLERS_PPOB_SET_MAINTENANCE:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_SET_AVAILABILITY:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_SET_HIDE:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_SET_PROMO:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_SET_NEW:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_PPOB_SET_APPEARANCE:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;

      case ROUTE_BILLERS_PPOB_MAINTENANCE:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_PROMO_QRIS_CONFIG:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING];
        break;
      case ROUTE_BILLERS_PROMO_CONFIG:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case ROUTE_BILLERS_BANK_TRANSFER_FEE:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;

      // Internal Configuration /
      case ROUTE_INTERNAL_ADMINS:
        roles = [AdminRole.ROOT, AdminRole.OPERATION];
        break;
      case ROUTE_INTERNAL_PARAMETERS:
        roles = [AdminRole.ROOT, AdminRole.TECH];
        break;
      case ROUTE_INTERNAL_COMPANY:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.TECH];
        break;
      case ROUTE_INTERNAL_DISCLAIMER:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.TECH];
        break;
      case ROUTE_INTERNAL_BANKS:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING];
        break;
      case ROUTE_BANK_ACCOUNTS:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.FINANCE, AdminRole.RISK];
        break;
      case ROUTE_INTERNAL_NNS:
        roles = [AdminRole.OPERATION, AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case ROUTE_INTERNAL_REJECT_REASON:
        roles = [AdminRole.OPERATION, AdminRole.ROOT, AdminRole.OPERATION];
        break;
      case ROUTE_INTERNAL_AML:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.RISK, AdminRole.TECH];
        break;
      case ROUTE_INTERNAL_DTTOT:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.RISK, AdminRole.TECH];
        break;

      // on discuss
      case UNLOCK_USER:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.SUPERVISOR];
        break;
      case UNLOCK_MERCHANT:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.RISK];
        break;
      case CREATE_UPDATE_FORM:
        roles = [AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE];
        break;
      case SUSPEND_MERCHANT:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.RISK];
        break;
      case CLOSED_ACCOUNT_MERCHANT:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.RISK];
        break;
      case FORM_DETAIL_APPROVAL:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR];
        break;
      case SUSPEND_USER:
        roles = [AdminRole.OPERATION, AdminRole.RISK, AdminRole.SUPERVISOR];
        break;
      case BUSINESS_APPROVAL:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.RISK];
        break;
      case EXPORT_EXCEL:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.MARKETING, AdminRole.FINANCE];
        break;
      case APPROVE_PARTNER_SETTLEMENT:
        roles = [AdminRole.MARKETING, AdminRole.RISK, AdminRole.SUPERVISOR];
        break;
      case REJECT_PARTNER_SETTLEMENT:
        roles = [AdminRole.MARKETING, AdminRole.RISK, AdminRole.SUPERVISOR, AdminRole.FINANCE];
        break;
      case EXECUTE_PARTNER_SETTLEMENT_WITH_BANK_TRANSFER:
        roles = [AdminRole.MARKETING, AdminRole.RISK, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE];
        break;
      case EXECUTE_PARTNER_SETTLEMENT_WITHOUT_BANK_TRANSFER:
        roles = [AdminRole.MARKETING, AdminRole.RISK, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE];
        break;
      case APPROVE_BUSINESSES_SETTLEMENT:
        roles = [AdminRole.MARKETING, AdminRole.RISK, AdminRole.SUPERVISOR];
        break;
      case BLACKLIST_MERCHANT:
        roles = [AdminRole.OPERATION, AdminRole.SUPERVISOR, AdminRole.MARKETING, AdminRole.RISK];
        break;
      case REJECT_BUSINESSES_SETTLEMENT:
        roles = [AdminRole.MARKETING, AdminRole.RISK, AdminRole.SUPERVISOR, AdminRole.OPERATION, AdminRole.FINANCE];
        break;

      // Notifications /
      case ROUTE_NOTIFICATIONS_USER:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.MARKETING];
        break;
      case ROUTE_NOTIFICATIONS_MERCHANT:
        roles = [AdminRole.ROOT, AdminRole.OPERATION, AdminRole.MARKETING];
        break;

      // Logs /
      case ROUTE_LOGS_AUDIT:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.RISK, AdminRole.TECH];
        break;
      case ROUTE_LOGS_ERROR:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.RISK, AdminRole.TECH];
        break;
      case ROUTE_LOGS_TRANSACTION:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.RISK, AdminRole.TECH];
        break;
      case ROUTE_LOGS_AUTODEBIT:
        roles = [AdminRole.ROOT, AdminRole.SUPERVISOR, AdminRole.RISK, AdminRole.TECH];
        break;
      default:
        allAccess = false
        roles = [];
        break;
    }
    return {
      roles,
      allAccess
    };
  }

  static hasAccess(role, routeKey) {
    if (role === AdminRole.ROOT) return true
    const { allAccess, roles } = this.getRoles(routeKey)
    if (allAccess) return true
    return roles.includes(role);
  }
}

export default roleHelper;
