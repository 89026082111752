import { combineReducers } from 'redux';
import list from './list';
import referral from './referral';
import closeAccount from './closeAccount';
import requestUpdate from './requestUpdate';

export default combineReducers({
  list,
  referral,
  closeAccount,
  requestUpdate
});
