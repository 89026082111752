import React, { useEffect } from 'react'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import EvySelectField from '../../../components/Forms/EvySelectField';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FormField from '../../../components/Forms/FormField';

const schema = yup.object().shape({
  name: yup.string().required(),
  isQuotaEnabled: yup.string().required(),
  quota: yup
    .string()
    .when('isQuotaEnabled', {
      is: (isQuotaEnabled) => isQuotaEnabled === "1",
      then: yup.string().required(),
    }),
  isActive: yup.string().required(),
  startPeriod: yup.string().required(),
  endPeriod: yup.string().required()
})

const MyForm = ({ initialValues, onSubmit, isLoading }) => {
  const { register, unregister, setValue, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialValues?.name,
      quota: initialValues?.quota ? initialValues?.quota : "",
      isActive: initialValues?.isActive ? "ACTIVE" : "INACTIVE",
      isQuotaEnabled: initialValues?.isQuotaEnabled ? "1" : "0",
      startPeriod: initialValues?.startPeriod,
      endPeriod: initialValues?.endPeriod
    }
  });

  const {
    quota,
    isActive,
    startPeriod,
    endPeriod,
    isQuotaEnabled
  } = watch([
    "quota",
    'isActive',
    "startPeriod",
    "endPeriod",
    "isQuotaEnabled"
  ]);

  useEffect(() => {
    register("quota")
    register("isActive")
    register("startPeriod")
    register("endPeriod")
    register("isQuotaEnabled")

    return () => {
      register("quota")
      unregister("isActive")
      unregister("startPeriod")
      unregister("endPeriod")
      unregister("isQuotaEnabled")
    };
  }, [register, unregister]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Referral Code"
            name="name"
            error={errors.name}
            helperText={errors?.name?.message ?? null}
            placeholder="e.g: VCR2019"
          />
          <FormField
            label="Quota Status"
            error={errors.isQuotaEnabled}
            helperText={errors?.isQuotaEnabled?.message ?? null}
          >
            <RadioGroup
              row
              name="isQuotaEnabled"
              value={isQuotaEnabled}
              onChange={e => setValue("isQuotaEnabled", e.target.value, { shouldValidate: true })}
            >
              <FormControlLabel value="1" control={<Radio color="primary" />} label="Enable" />
              <FormControlLabel value="0" control={<Radio color="primary" />} label="Disable" />
            </RadioGroup>
          </FormField>
          {isQuotaEnabled === "1" ?
            <HookTextField
              label="Quotas"
              name="quota"
              error={errors.quota}
              helperText={errors?.quota?.message ?? null}
              placeholder="e.g: 50"
              value={quota}
              onChange={(v) => setValue("quota", v, { shouldValidate: true })}
              format={Number}
            /> : null
          }
          <EvySelectField
            label="Select Status"
            options={[
              {
                value: "ACTIVE",
                label: "Active"
              },
              {
                value: "INACTIVE",
                label: "Inactive"
              }
            ]}
            onChange={(v) => setValue('isActive', v, { shouldValidate: true })}
            value={isActive}
            error={errors.isActive}
            helperText={errors?.isActive?.message ?? null}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Period</h5>
        </div>
        <div className="col-12 col-md-9">
          <div className="row">
            <div className="col-6">
              <FormField
                label="Start Period"
                error={errors.startPeriod}
                helperText={errors?.startPeriod?.message ?? null}
              >
                <EvyDatePicker
                  value={startPeriod}
                  onChange={(v) => setValue("startPeriod", v, { shouldValidate: true })}
                  variant="dialog"
                  placeholder="Select date"
                  clearable={false}
                  disableToolbar
                  disablePast
                  autoOk
                  error={errors.startPeriod}
                  helperText={errors?.startPeriod?.message ?? null}
                />
              </FormField>
            </div>
            <div className="col-6">
              <FormField
                label="End Period"
                error={errors.endPeriod}
                helperText={errors?.endPeriod?.message ?? null}
              >
                <EvyDatePicker
                  value={endPeriod}
                  onChange={(v) => setValue("endPeriod", v, { shouldValidate: true })}
                  margin="dense"
                  variant="dialog"
                  placeholder="Select date"
                  clearable={false}
                  disableToolbar
                  disablePast
                  autoOk
                  error={errors.endPeriod}
                  helperText={errors?.endPeriod?.message ?? null}
                />
              </FormField>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <div className="d-flex flex-row-reverse flex-sm-row">
            <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
          </div>
        </div>
      </div>
    </form >
  );
};

export default MyForm
