import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_BANK_ACCOUNTS,
  ROUTE_INTERNAL_COMPANY,
  ROUTE_INTERNAL_PARAMETERS,
  ROUTE_INTERNAL_ADMINS,
  ROUTE_INTERNAL_DISCLAIMER,
  ROUTE_INTERNAL_BANKS,
  ROUTE_INTERNAL_DTTOT,
  ROUTE_INTERNAL_REJECT_REASON,
  ROUTE_INTERNAL_AML,
  ROUTE_INTERNAL_NNS,
  ROUTE_IMAGE_TEMP
} from "../utils/helpers/roleHelper";

import Parameters from '../pages/InternalConfig/Parameters'
import BankAccount from '../pages/InternalConfig/BankAccount'
import CompanyPage from '../pages/InternalConfig/Company'
import AdminComponent from '../pages/InternalConfig/AdminControl'
import Disclaimer from '../pages/InternalConfig/Disclaimer'
import Banks from '../pages/InternalConfig/Banks'
import DTTOT from '../pages/InternalConfig/DTTOT'
import RejectReason from '../pages/InternalConfig/RejectReason'
import AMLPage from '../pages/InternalConfig/AML'
import NNSPage from '../pages/InternalConfig/NNS'
import ImageTemp from "../pages/ImageTemp";

export const internal = {
  type: NAVMENU,
  name: 'Internal Configuration',
  icon: 'pe-7s-config',
  children: [
    {
      type: NAVLINK,
      name: 'Parameters',
      icon: 'pe-7s-config',
      url: '/internal/parameters',
      routeTitle: ROUTE_INTERNAL_PARAMETERS,
      component: Parameters
    },
    {
      type: NAVLINK,
      name: 'Bank Accounts',
      icon: 'pe-7s-credit',
      url: '/internal/bank-accounts',
      routeTitle: ROUTE_BANK_ACCOUNTS,
      component: BankAccount
    },
    {
      type: NAVLINK,
      name: 'About Us',
      icon: 'pe-7s-tools',
      url: '/internal/company',
      routeTitle: ROUTE_INTERNAL_COMPANY,
      component: CompanyPage
    },
    {
      type: NAVLINK,
      name: 'Admins',
      icon: 'pe-7s-medal',
      url: '/internal/admins',
      routeTitle: ROUTE_INTERNAL_ADMINS,
      component: AdminComponent
    },
    {
      type: NAVLINK,
      name: 'Disclaimer',
      icon: 'pe-7s-note2',
      url: '/internal/disclaimer',
      routeTitle: ROUTE_INTERNAL_DISCLAIMER,
      component: Disclaimer
    },
    {
      type: NAVLINK,
      name: 'Banks',
      icon: 'pe-7s-piggy',
      url: '/internal/banks',
      routeTitle: ROUTE_INTERNAL_BANKS,
      component: Banks
    },
    {
      type: NAVLINK,
      name: 'DTTOT',
      icon: 'pe-7s-shield',
      url: '/internal/dttot',
      routeTitle: ROUTE_INTERNAL_DTTOT,
      component: DTTOT
    },
    {
      type: NAVLINK,
      name: 'Reject Reason',
      icon: 'pe-7s-display2',
      url: '/internal/reject-reason',
      routeTitle: ROUTE_INTERNAL_REJECT_REASON,
      component: RejectReason
    },
    {
      type: NAVLINK,
      name: 'Block List',
      icon: 'pe-7s-umbrella',
      url: '/internal/aml',
      routeTitle: ROUTE_INTERNAL_AML,
      component: AMLPage
    },
    {
      type: NAVLINK,
      name: 'QRIS NNS',
      icon: 'pe-7s-leaf',
      url: '/internal/nns',
      routeTitle: ROUTE_INTERNAL_NNS,
      component: NNSPage
    },
    {
      type: NAVLINK,
      name: 'Image Temp',
      icon: 'pe-7s-leaf',
      url: '/internal/image-temp',
      routeTitle: ROUTE_IMAGE_TEMP,
      component: ImageTemp
    },
  ]
}
