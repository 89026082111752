import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const data = {
  labels: ['Pay QRIS', 'PPOB', 'Transfer In', 'Transfer Out'],
  datasets: [
    {
      label: '# of Votes',
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const option = {
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
        return currentValue + ' (' + percentage + '%)';
      },
      title: function (tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      }
    }
  }
}

const Statistik = ({
  isPercentageNull,
  trxData
}) => {
  return !isPercentageNull ? (
    <div>
      <h5 className="card-title">Transactions Percentage</h5>
      <div className="py-4">
        <Doughnut
          data={{
            ...data,
            datasets: [
              {
                ...data.datasets[0],
                data: trxData
              }
            ]
          }}
          options={option}
        />
      </div>
    </div>
  )
    :
    <div className="p-5 text-center">
      <h5 className="card-title mb-0">No Data</h5>
    </div>
}

export default Statistik
