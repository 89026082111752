import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';
import { combineBy } from '../../../../utils/helpers/combineBy';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getWalletType = (obj) => {
  for (let key in obj) {
    if (obj[key] > 0) return key
  }
}

const getNoPelanggan = (row) => {
  let biller = row.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return row.metadata?.payment?.metadata?.ppobResponse?.idPel ?? '-';
  else if (biller === "RAJABILLER") return row.metadata?.payment?.metadata?.ppobResponse?.customerid ?? '-';
  return '-'
}

const getAtasNama = (row) => {
  let biller = row.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return row.metadata?.payment?.metadata?.ppobResponse?.nama ?? '-';
  else if (biller === "RAJABILLER") return row.metadata?.payment?.metadata?.ppobResponse?.customername ?? '-';
  return '-';
}

const getBiller = (row, rawData) => {
  let biller = row.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return rawData ? row.metadata?.payment?.metadata?.ppobResponse?.totalTagihan : row.metadata?.payment?.metadata?.ppobResponse?.totalTagihan ?? '-';
  else if (biller === "RAJABILLER") return rawData ? row.metadata?.payment?.metadata?.ppobResponse?.nominal : row.metadata?.payment?.metadata?.ppobResponse?.nominal ?? '-';
  return '-';
}

const TableBillerPostpaid = ({ items, period }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let sumHargaBiller = 0;
    let sumRevenue = 0;
    let sumTotal = 0;

    items.forEach(i => {
      sumHargaBiller += +getBiller(i, true) || 0;
      sumRevenue += +i.metadata?.payment?.metadata?.ppob?.revenue || 0;
      sumTotal += +i.metadata?.payment?.amount || 0;
    });

    amountArr = [
      { value: sumHargaBiller },
      { value: sumRevenue },
      { value: sumTotal }
    ]
    return amountArr;
  }, [items]);

  const columns = useMemo(() => [
    { title: "Date", key: "createdAt", name: "createdAt", render: v => format(new Date(v), 'yyyy-MM-dd') },
    { title: "Trans. No", key: "code", name: "code" },
    { title: "Type", key: "metadata.payment.metadata.ppob", name: "type", render: (v) => PpobType.getStrIdn(v.type) },
    { title: "Nama", key: "sender", name: "nama", render: v => combineBy([v.firstName, v.lastName]) },
    { title: "Payment", key: "payment", name: "payment", render: (v, row) => getNoPelanggan(row) },
    { title: "Atas Nama", key: "payment", name: "payment", render: (v, row) => getAtasNama(row) },
    { title: "Wallet", key: "amount", name: "amount", render: v => getWalletType(v) },
    { title: "Harga Biller", key: "", name: "biller", withCurrency: true, render: (v, row) => getBiller(row, true) },
    { title: "Revenue", key: "revenue", name: "revenue", withCurrency: true, render: (v, row) => row?.metadata?.payment?.metadata?.ppob?.revenue ?? '-' },
    { title: "Total", key: "total", name: "total", withCurrency: true, render: (v, row) => row.metadata?.payment?.amount ?? '-' }
  ], [])

  return (
    <ReportTableTemplateV2
      title="Biller Phone Pascabayar"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableBillerPostpaid;
