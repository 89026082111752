import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from '../../services/authApi';
import { VERIFY_OTP, RESEND_OTP } from '../../utils/constants/actionTypes';
import { encrypt } from '../../utils/helpers/crypto';
import timeConvert from '../../utils/helpers/timeConverter';
import LoadingBtn from '../../components/Forms/LoadingBtn';
import OTPInput from '../../components/Forms/OTPInput'
import LoadingBlocker from '../../components/Loading/LoadingBlocker';

const VerifyOtp = ({ email, timer, setTimer, setStep }) => {
  const dispatch = useDispatch()
  const [currentOTP, setCurrentOTP] = useState('')
  const [error, setError] = useState('')
  const OTPRef = useRef()
  const { inProgress } = useSelector(state => state.auth)

  const [resendTimer, setResendTimer] = useState(30)
  const [resend, setResend] = useState(true)

  const submitOTP = useCallback((e) => {
    e?.preventDefault()

    if (currentOTP.length < 9) {
      setError('OTP tidak lengkap')
      return
    }

    const encryptedEmail = encrypt(email)
    const encryptedOtp = encrypt(currentOTP)

    dispatch({ type: VERIFY_OTP, call: authApi.verifyOtp, args: [encryptedEmail, encryptedOtp] })
      .catch(({ message }) => {
        setCurrentOTP('')
        setError(message)
        OTPRef.current?.focus()
      })
  }, [currentOTP, dispatch, email])

  const resendOTP = () => {
    const encryptedEmail = encrypt(email)

    dispatch({ type: RESEND_OTP, call: authApi.resendOTP, args: [encryptedEmail] })
      .then(({ data: { expiredIn } }) => {
        setResend(true);
        setResendTimer(30);
        setTimer(expiredIn)
      })
  }

  useEffect(() => {
    OTPRef.current?.focus()
  }, [])

  useEffect(() => {
    if (currentOTP.length === 9) submitOTP()
  }, [currentOTP, submitOTP])

  useEffect(() => {
    const countDown = setInterval(() => {
      if (timer) setTimer(prev => prev - 1)
      else {
        clearInterval(countDown)
        setStep(1)
      }
    }, 1000);
    return () => {
      clearInterval(countDown)
    }
  }, [timer, setTimer, setStep])

  useEffect(() => {
    const tryAgain = setInterval(() => {
      if (resendTimer) setResendTimer(prev => prev - 1)
      else {
        clearInterval(tryAgain)
        setResend(false)
      }
    }, 1000);
    return () => {
      clearInterval(tryAgain)
    }
  }, [resendTimer, setResendTimer, setResend])

  return (
    <div className="modal-dialog w-100 mx-auto">
      <form onSubmit={submitOTP} className="modal-content">
        <div className="modal-body">
          <h4 className="h5 modal-title text-center text-grey mb-0">Masukkan Kode Verifikasi</h4>
          <p className="text-center">Kode verifikasi telah dikirim melalui email ke {email}</p>
          <OTPInput
            ref={OTPRef}
            length={9}
            numberOnly
            value={currentOTP}
            onChange={(v) => setCurrentOTP(v)}
            error={error}
          />
          <LoadingBlocker in={inProgress} />
          <div className="d-flex justify-content-center mb-3">
            <LoadingBtn
              disabled={resend}
              type="button"
              className={`btn ${resend ? 'btn-secondary' : 'btn-primary'}`}
              onClick={resendOTP}
            >
              Resend OTP{resend && ` (${timeConvert(resendTimer)})`}
            </LoadingBtn>
          </div>
          <p className="text-center mb-0">Expires in: {timeConvert(timer)}</p>
        </div>
      </form>
    </div>
  )
}

export default VerifyOtp
