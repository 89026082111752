import React, { forwardRef, useState, useRef, useCallback, useMemo } from 'react';
import FormField from './FormField';
import { IMAGE_FORMATS } from '../../utils/constants/actionTypes';

const imagePlaceholderUrl = process.env.PUBLIC_URL + '/assets/images/img-placeholder.webp';

const readAsDataURL = (file, cb) => {
  let reader = new FileReader();
  reader.onloadend = () => {
    let res = reader.result;
    cb(res);
  };
  reader.readAsDataURL(file)
}

const HookImage = forwardRef((
  {
    error,
    helperText,
    format = IMAGE_FORMATS,
    name,
    className,
    viewVariant,
    previousImageUrl,
    onClear,
    onChange,

    label,
    row,
    readOnly
  },
  ref
) => {
  const [file, setFile] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const fileUpload = useRef();

  const token = window.localStorage.getItem('token');

  const clear = useCallback(() => {
    setImagePreviewUrl(undefined);
    setFile(undefined);
    if (typeof onClear === 'function') onClear();
  }, [onClear])

  const selectedImage = useMemo(() => {
    if (imagePreviewUrl) return imagePreviewUrl;
    if (previousImageUrl) return previousImageUrl + '?token=' + token;
    return imagePlaceholderUrl;
  }, [imagePreviewUrl, previousImageUrl, token])

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (!file) return

    setFile(file)
    readAsDataURL(file, base64Res => setImagePreviewUrl(base64Res));
    if (typeof onChange === 'function') onChange(file);
  }, [onChange])

  const showFileUpload = () => {
    if (readOnly) return
    fileUpload.current?.click?.()
  };

  return (
    <FormField
      id={name}
      row={row}
      label={label}
      error={error}
      helperText={helperText}
    >
      <div className={`position-relative input-image-container ${className ? className : ''} ${error ? 'is-invalid' : ''} ${viewVariant === 'landscape' ? 'landscape' : ''} ${!readOnly ? 'pointer' : ''}`}>
        <input
          id={name}
          name={name}
          type="file"
          onChange={handleImageChange}
          ref={(el) => {
            if (typeof ref === 'function') ref(el);
            fileUpload.current = el;
          }}
          className="form-control-file"
          accept={format.join(', ')}
          readOnly={readOnly}
        />
        <div className="img-preview-wrapper mb-0">
          <div className="img-preview" onClick={showFileUpload}>
            <img src={selectedImage} alt="" />
          </div>
        </div>
        {file && onClear ? (
          <div className="remove-img-button">
            <button onClick={clear} className="btn btn-danger" type="button">Remove</button>
          </div>
        ) : null}
      </div>
    </FormField>
  )
})

export default HookImage;
