import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const dttotApi = {
  list: (page, limit, sort) => {
    let url = `/terrorists/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    return agent.requests.get(url)
  },
  import: file =>
    agent.requests.post(`/terrorists/upload`, { file }, true)
}
