import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const auditLogApi = {
  list: (page, limit, sort, search, startDate, endDate) => {
    let url = `/audit-logs/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  create: (path, method, eventName) =>
    agent.requests.post('/audit-logs/create', { path, method, eventName }),
}
