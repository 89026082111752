import React, { useMemo } from 'react';
import Resource from '../../../components/Resource';
import { useSelector } from 'react-redux';
import DetailTab from './DetailTab';
import CreateTab from './CreateTab';
import { CLOSED_USER_ACCOUNT_MODULE } from '../../../utils/constants/actionTypes';
import ClosedUserStatusOpts from '../../../utils/constants/enums/status/closedUserStatusOpts';
import roleHelper, { CREATE_UPDATE_FORM } from '../../../utils/helpers/roleHelper';
import AllStatus from '../../../utils/constants/enums/status';
import { closedUserApi } from '../../../services/closedUserApi';
import { format } from 'date-fns';
import { combineBy } from '../../../utils/helpers/combineBy';
import { SELECT_FIELD } from '../../../utils/constants/inputTypes';

const Index = () => {
  const {
    users: {
      closeAccount: {
        currentPage,
        limit,
        sort,
        filter
      }
    },
    common: {
      currentAdmin
    }
  } = useSelector(state => state)

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: (v, row) => {
        return format(new Date(v), 'yyyy-MM-dd hh:mm:ss')
      }
    },
    {
      title: 'Requested By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories.find(history => history.status === AllStatus.PROCESSING);
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    {
      title: 'User (Target)', key: 'user', name: 'user', render: function (v, row) {
        if (v) {
          return `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}`;
        }
        return '';
      }
    },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) }
  ]

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    {
      title: 'Requested By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories.find(history => history.status === AllStatus.PROCESSING);
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    {
      title: 'User (Target)', key: 'user', name: 'user', render: function (v) {
        return v ? `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` : '-'
      }
    },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) }
  ], [])

  const apiQuery = useMemo(() => {
    const { status } = filter;
    const search = status ? `status|${status}` : '';
    return {
      page: currentPage,
      limit,
      sort,
      search,
      status: filter.status,
    }
  }, [currentPage, limit, sort, filter])

  return (
    <Resource
      title="Closed User Account"
      subTitle="Create, Approval, Denial Closing request for user."
      icon="pe-7s-hammer"
      role={roleHelper.hasAccess(currentAdmin.role, CREATE_UPDATE_FORM)}
      list={{
        columns: columns,
        reducerPath: "users.closeAccount",
        module: CLOSED_USER_ACCOUNT_MODULE,
        call: closedUserApi.list,
        apiQuery: apiQuery,
        excel: {
          columns: excelColumns,
          filename: 'Users_Closed_Account_List',
          apiResponseKey: 'data.closedUsers',
          queryParams: apiQuery
        },
        filters: [
          {
            label: "Filter Status",
            type: SELECT_FIELD,
            key: "status",
            value: filter.status,
            options: ClosedUserStatusOpts,
          }
        ]
      }}
      detail={{
        component: DetailTab
      }}
      create={{
        component: CreateTab
      }}
    />
  );
}

export default Index;
