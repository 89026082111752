import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Routes from '../components/Routes';
import { useSelector } from 'react-redux';
import routes from '../routes';

const Sidebar = () => {
  const { currentAdmin, socket } = useSelector(state => state.common);
  useEffect(() => {
    if (window.outerWidth < 992) {
      document.querySelector('.app-container').classList.add('closed-sidebar', 'closed-sidebar-mobile')
    }
  }, [])

  return (
    <div className="app-sidebar sidebar-shadow">
      <div className="app-header__logo">
        <div className="logo-src"></div>
        <div className="header__pane ml-auto">
          <div>
            <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
            <span className="btn-icon-wrapper">
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </button>
        </span>
      </div>
      <PerfectScrollbar className="scrollbar-sidebar">
        <div className="app-sidebar__inner">
          <Routes
            className="vertical-nav-menu metismenu"
            routes={routes}
            admin={currentAdmin}
            socket={socket}
          />
        </div>
      </PerfectScrollbar>
    </div>
  );
}

export default Sidebar;
