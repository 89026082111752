import React from 'react';
import Select from 'react-select';
import FormField from './FormField';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '.25rem',
    // minHeight: 'calc(1.5em + 1.3rem + 2px)',
    border: state.isFocused ? '1px solid #69b3ff' : '1px solid #ced4da',
    "&:hover": {
      border: state.isFocused ? '1px solid #69b3ff' : '1px solid #ced4da'
    },
    boxShadow: state.isFocused ? ' 0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
    transition: 'border-color .15s ease-in-out',
  }),
  // valueContainer: (provided) => ({
  //   ...provided,
  //   padding: '0 .75rem',
  // }),
  // menu: (provided) => ({
  //   ...provided
  // }),
  placeholder: (provided) => ({
    ...provided,
    "white-space": "nowrap",
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    "width": "-webkit-fill-available"
  })
}

const SelectField = ({
  row,
  label,
  error,
  formFieldAppend,
  helperText,
  id,
  onChange,
  onBlur,
  options,
  value: simpleValue = '',
  placeholder,
  isMulti = false,
  getOptionValue = opt => opt.value,
  componentProps,
  isDisabled,
  required
}) => {
  const value = getValue(options, simpleValue, getOptionValue, isMulti);

  return (
    <FormField
      row={row}
      label={label}
      error={error}
      helperText={helperText}
      append={formFieldAppend}
      required={required}
    >
      <Select
        {...componentProps}
        isMulti={isMulti}
        styles={customStyles}
        value={value}
        id={id}
        placeholder={placeholder}
        options={options}
        onChange={(v, e) => onChange(v, options, e)}
        onBlur={onBlur}
        isDisabled={isDisabled}
        isOptionDisabled={(option) => option.disabled}
        classNamePrefix="custom-select"
      />
    </FormField>
  )
}

// Method for Custom Select

function flatten(arr) {
  return arr.reduce((acc, val) => (Array.isArray(val.options)
    ? acc.concat(flatten(val.options))
    : acc.concat(val)
  ), []);
}

function getValue(opts, val, getOptVal, isMulti) {
  if (!val) return val;

  const options = flatten(opts);
  const value = isMulti
    ? options.filter(o => val.includes(getOptVal(o)))
    : options.find(o => typeof val === 'object' && Boolean(val) ? getOptVal(o) === getOptVal(val) : getOptVal(o) === val);

  return value;
}

export default SelectField;
