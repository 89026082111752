import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  DEDUCTION_MODULE,
  CREATE_TAB,
  CLOSE_TAB,
  GET_DETAIL,
  FILTER_TABLE,
  CREATE,
} from '../../utils/constants/actionTypes';
import { endOfMonth, startOfMonth } from 'date-fns';
import { closeTab, createTab } from '../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'createdAt|desc',
  currentPage: 1,
  activeTabKey: 0,
  tabs: [],
  filter: {
    user: null,
    merchant: null,
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    transactionCode: '',
    status: '',
  },
  isCreating: false,
};

const reducer = (state = defaultState, action) => {
  if (action.module !== DEDUCTION_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      let items = action.payload && action.payload.data ? action.payload.data.deductions : [];
      return {
        ...state,
        items: items,
        total: action.payload && action.payload.data ? action.payload.data.count : 0,
      };
    case GET_DETAIL: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.tabKey]) clonedTabs[action.tabKey].loading = false;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          user: action.key === "merchant" ? null : state.filter.user,
          merchant: action.key === "user" ? null : state.filter.merchant,
          [action.key]: action.value
        },
        currentPage: 1
      }
    case CREATE:
      return {
        ...state,
        isCreating: false,
        activeTabKey: !action.error ? 0 : state.activeTabKey,
      };
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1,
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
            refresh: false
          };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        case CREATE:
          return { ...state, isCreating: true };
        case GET_DETAIL:
          const clonedTabs = [...state.tabs];
          clonedTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: clonedTabs
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
