import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { AutoSizer } from 'react-virtualized';
import { combineBy } from '../../../utils/helpers/combineBy';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import VirtualTable from '../../../components/VirtualTable';

const TableBulk = ({
  items,
  selectedBulks,
  setSelectedBulks,
  loading
}) => {
  const filteredBulks = useMemo(() => items, [items])

  return (
    <div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width, border: '1px solid #E5E5EA', position: 'relative' }}>
            <LoadingBlocker in={loading} />
            <VirtualTable
              rowHeight={30}
              tableHeight={300}
              data={filteredBulks}
              components={{
                RowList: {
                  component: CustomRowList,
                  props: {
                    setSelectedBulks,
                    selectedBulks
                  }
                }
              }}
              columns={[
                {
                  render: (dataRow) => (
                    <input
                      type="checkbox"
                      className="form-check-input position-static m-0"
                      checked={Boolean(selectedBulks[dataRow?.merchantSettlementId])}
                      onChange={() => { }}
                    />
                  )
                },
                ...columns
              ]}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  )
}

const columns = [
  { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd hh:mm:ss') },
  { title: 'Applicant', key: 'uniqueId', name: 'uniqueId', sortable: false, render: (v, row) => row ? row?.businesses?.name + ' | ' + v : '-' },
  { title: 'Owner', key: 'firstName', name: 'firstName', render: (v, row) => combineBy([v, row?.lastName]) + ' | ' + row?.email },
]

const CustomRowList = ({ children, setSelectedBulks, rowData }) => {
  return (
    <tr
      onClick={() => setSelectedBulks(prev => ({ ...prev, [rowData?.merchantSettlementId]: !prev[rowData?.merchantSettlementId] ? rowData?.merchantSettlementId : false }))}
    >
      {children}
    </tr>
  )
}

export default TableBulk;
