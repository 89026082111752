import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  CREATE,
  ADD_DELETE_DATA,
  REMOVE_DELETE_DATA,
  DELETE,
  USER_ANNOUNCEMENT_APP_MODULE,
  CREATE_TAB,
  CLOSE_TAB,
  FILTER_TABLE,
  UPDATE,
} from '../../../utils/constants/actionTypes';
import { closeTab, createTab } from '../../../utils/helpers/tabHelper';

const defaultState = {
  inProgress: false,
  total: 0,
  items: [],
  limit: 10,
  sort: 'name|asc',
  currentPage: 1,
  activeTabKey: 0,
  deleteRowId: null,
  filter: {
    type: ''
  },
  tabs: []
};

const reducer = (state = defaultState, action) => {
  if (action.module !== USER_ANNOUNCEMENT_APP_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      return {
        ...state,
        items: action.payload && action.payload.data ? action.payload.data.announcements : [],
        total: action.payload && action.payload.data ? action.payload.data.count : 0,
      };
    case CREATE:
      return {
        ...state,
        activeTabKey: action.error ? state.activeTabKey : 0,
      }
    case CREATE_TAB:
      const newTabs = createTab(state.tabs, action.key, action.title, { item: state.items.find(b => b._id === action.key) });
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        },
        currentPage: 1
      }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        limit: action.value,
        currentPage: 1
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPage: action.value
      }
    case ADD_DELETE_DATA:
      return {
        ...state,
        deleteRowId: action.args._id
      }
    case REMOVE_DELETE_DATA:
      return {
        ...state,
        deleteRowId: null
      }
    case DELETE:
      return {
        ...state,
        deleteRowId: action.error ? state.deleteRowId : null,
        reload: action.error ? false : true
      }
    case UPDATE:
      return {
        ...state,
        tabs: state.tabs.filter((_, i) => i !== action.tabKey),
        activeTabKey: 0
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
            reload: false
          };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return { ...state, inProgress: true };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
