import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import auth from './auth';
import common from './common';
import bank from './bank';
import bankAccount from './bankAccount';
import logs from './logs';
import businesses from './businesses';
import kyc from './kyc';
import users from './users';
import partner from './partner';
import voucher from './voucher';
import career from "./career";
import config from './config';
import article from './article';
import topUp from './topUp';
import billers from './billers';
import transactions from './transactions';
import transfer from './transfer';
import notification from './notification';
import report from './report2';
import settlement from './settlement';
import admin from './admin';
import injection from './injection';
import dttot from './dttot';
import withdraw from './withdraw';
import rejectReason from './rejectReason';
import dashboard from './dashboard';
import deduction from './deduction';
import autoDebit from './autoDebit';
import disclaimer from './disclaimer';
import donation from './donation';
import injectionBulking from './injectionBulking';
import company from './company';
import promoConfig from './promoConfig';
import promo from './promo';
import qrisRecon from './qrisRecon';
import nns from './nns';
import announcement from './announcement';
import referral from './referral';
import imageTemp from './imageTemp';
import articles from './articles';
import outlet from './outlet';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  common,
  bank,
  bankAccount,
  logs,
  businesses,
  kyc,
  users,
  partner,
  voucher,
  career,
  config,
  article,
  topUp,
  billers,
  transactions,
  transfer,
  notification,
  report,
  settlement,
  admin,
  injection,
  dttot,
  withdraw,
  rejectReason,
  dashboard,
  deduction,
  autoDebit,
  disclaimer,
  donation,
  injectionBulking,
  company,
  promoConfig,
  qrisRecon,
  nns,
  promo,
  announcement,
  referral,
  imageTemp,
  articles,
  outlet
});

export default createRootReducer;
