import React, { memo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import EvySelectField from '../../../../../components/Forms/EvySelectField';

const options = [
  { value: '00:00', label: '00:00' },
  { value: '00:30', label: '00:30' },
  { value: '01:00', label: '01:00' },
  { value: '01:30', label: '01:30' },
  { value: '02:00', label: '02:00' },
  { value: '02:30', label: '02:30' },
  { value: '03:00', label: '03:00' },
  { value: '03:30', label: '03:30' },
  { value: '04:00', label: '04:00' },
  { value: '04:30', label: '04:30' },
  { value: '05:00', label: '05:00' },
  { value: '05:30', label: '05:30' },
  { value: '06:00', label: '06:00' },
  { value: '06:30', label: '06:30' },
  { value: '07:00', label: '07:00' },
  { value: '07:30', label: '07:30' },
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
  { value: '23:59', label: '23:59' },
]

const daysLabel = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const OperationHour = memo(({ day, close, open, isOpen, onChange }) => {
  const handleIsOpenChange = (e) => {
    onChange(day, {
      day,
      open,
      close,
      isOpen: e.target.checked
    });
  }

  const handleOpenTimeChange = (v) => {
    onChange(day, {
      day,
      open: v,
      close,
      isOpen: isOpen
    });
  }

  const handleCloseTimeChange = (v) => {
    onChange(day, {
      day,
      open,
      close: v,
      isOpen: isOpen
    });
  }

  return (
    <div className="operation-hour-wrapper">
      <div className="toggler">
        <label className="custom-checkbox custom-control">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={isOpen}
            onChange={handleIsOpenChange}
          />
          <span className="custom-control-label">
            {daysLabel[day] ?? '-'}
          </span>
        </label>
        {!isOpen && <span className="label-status">CLOSED</span>}
      </div>
      <Collapse in={isOpen}>
        <div className="content">
          <div className="row">
            <div className="col-6">
              <EvySelectField
                label="Open Time"
                options={options}
                onChange={handleOpenTimeChange}
                value={open}
              />
            </div>
            <div className="col-6">
              <EvySelectField
                label="Close Time"
                options={options}
                onChange={handleCloseTimeChange}
                value={close}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
});

export default OperationHour;
