import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { CHECK_PPOB_PRICE_MODULE } from '../../../../utils/constants/actionTypes';
import { PpobSupportedType, PpobSupportedTypeEnum, PpobSupportedTypeOpts } from '../../../../utils/constants/enums/ppobSupportedTypes';
import useAsync from '../../../../components/HooksUse/useAsync';
import SelectField from '../../../../components/Forms/SelectField';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import { toIDR } from '../../../../utils/helpers/currency';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { AutoSizer, Column, Table } from 'react-virtualized';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import { ppobApi } from '../../../../services/ppobApi';

const productKeys = ['biller', 'product', 'code', 'denom', 'price', 'own price']

const MainTab = () => {
  const [selectedType, setSelectedType] = useState(PpobSupportedType.PHONE_CREDIT);
  const [filter, setFilter] = useState('All');
  const [filterBiller, setFilterBiller] = useState();
  const [search, setSearch] = useState('');
  const [updating, setUpdating] = useState(false)

  const dispatch = useDispatch();

  const comparePpobPrice = useCallback(
    () => dispatch({
      module: CHECK_PPOB_PRICE_MODULE,
      type: null,
      call: ppobApi.comparePpobPrice,
      args: [selectedType]
    }),
    [selectedType, dispatch]
  )

  const { execute: getComparePpobPrice, value: { data: products = [] } = {}, pending: loading } = useAsync(comparePpobPrice, PpobSupportedTypeEnum.includes(selectedType))

  const processedItem = useMemo(() => {
    let processedItem = products
    if (filter !== 'All') processedItem = processedItem.filter(el => el.ownPrice && el.ownPrice !== el.price)
    if (search) processedItem = processedItem.filter(function (element) {
      let ref = ['code', 'product', 'biller', 'denom', 'price', 'ownPrice']
      return ref.some(el => String(element[el]).toLowerCase().includes(search.toLowerCase()));
    })
    if (filterBiller) processedItem = processedItem.filter(el => el.biller === filterBiller)
    return processedItem
  }, [filter, products, search, filterBiller])

  const getBillers = useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.getBillers
    }),
    [dispatch]
  )
  const { value: { data: billerOpts = [] } = {}, pending: gettingBillers } = useAsync(getBillers, true);
  const billerOptions = useMemo(() => billerOpts.map(row => ({ label: row, value: row })) ?? [], [billerOpts]);

  const updatePrice = () => {
    setUpdating(true)
    dispatch({
      type: null,
      call: ppobApi.updatePrice,
      args: [selectedType, filterBiller]
    })
      .then(() => getComparePpobPrice())
      .catch(() => { })
      .finally(() => setUpdating(false))
  }

  return (
    <div className="main-card mb-3 card">
      <LoadingBlocker in={loading || updating} />
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <div className="form-row">
          <div className="col-12 col-md-4">
            <EvyTextField
              label="Search PPOB"
              className="form-control"
              placeholder="Search"
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="col-12 col-md-4">
            <SelectField
              label="Filter"
              options={[
                {
                  label: 'All',
                  value: 'All'
                },
                {
                  label: 'Different Price',
                  value: 'DifferentPrice'
                }
              ]}
              onChange={(v) => setFilter(v?.value)}
              value={filter}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-md-4">
            <SelectField
              label="Select Type"
              options={PpobSupportedTypeOpts}
              onChange={(v) => {
                setSelectedType(v?.value)
              }}
              value={selectedType}
            />
          </div>
          <div className="col-12 col-md-4">
            <SelectField
              label="Filter Biller"
              options={billerOptions}
              onChange={(v) => setFilterBiller(v?.value)}
              value={filterBiller}
              componentProps={{
                isLoading: gettingBillers,
                isClearable: true
              }}
            />
          </div>
        </div>
        <AlertDialog
          buttonComponent={<button className="btn btn-primary mb-3">Update Different Price</button>}
          onAgree={updatePrice}
          title="Apakah anda yakin?"
          description="Tindakan ini tidak dapat dibatalkan"
        />
        <div className="table-wrapper">
          {
            processedItem.length > 0 ?
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    width={width}
                    height={500}
                    headerHeight={53}
                    rowHeight={53}
                    rowCount={processedItem.length}
                    rowGetter={({ index }) => processedItem[index]}
                    rowStyle={{
                      borderBottom: '1px solid #ccc',
                      paddingLeft: '10px'
                    }}
                    gridClassName="custom-virtualized-grid"
                  >
                    {productKeys.map((key, i) => (
                      <Column
                        key={i}
                        label={key}
                        dataKey={key}
                        width={width / productKeys.length}
                        cellRenderer={({ cellData, rowData }) => key === 'own price' ? (rowData.ownPrice ? toIDR(rowData.ownPrice) : '-') : (!cellData ? '-' : key === 'price' ? toIDR(cellData) : cellData)}
                      />
                    ))}
                  </Table>
                )}
              </AutoSizer>
              :
              <div className="p-5 text-center">
                <h5 className="card-title mb-0">No Data</h5>
              </div>
          }
        </div>
      </div>
    </div >
  )
}

export default MainTab
