import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const withdrawApi = {
  list: ({ page, limit, sort, type, search, startDate, endDate, status, selfAccount, beneficiary, bankType, partner }) => {
    let url = `/withdraws/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (selfAccount) {
      url += `&selfAccount=${selfAccount}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (partner) {
      url += `&partner=${partner}`;
    }
    if (beneficiary) {
      url += `&beneficiary=${beneficiary}`;
    }
    if (bankType) {
      url += `&bankType=${bankType}`;
    }
    return agent.requests.get(url)
  },
  updateStatus: (id, status) =>
    agent.requests.patch(`/withdraws/status/${id}`, { status }),
  createRequest: data =>
    agent.requests.post(`/withdraws/request`, data),
  approve: id =>
    agent.requests.post(`/withdraws/${id}/process`),
  reject: (id, remarks) =>
    agent.requests.post(`/withdraws/${id}/reject`, { remarks }),
  verify: (id, oneTimePin) =>
    agent.requests.post(`/withdraws/${id}/verify`, { oneTimePin }),
  detail: (id) =>
    agent.requests.get(`/withdraws/${id}/detail`),
}
