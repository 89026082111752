class AdminRole {
  static ROOT = 'ROOT';
  static FINANCE = 'FINANCE';
  static OPERATION = 'OPERATION';
  static SUPERVISOR = 'SUPERVISOR';
  static RISK = 'RISK';
  static MARKETING = 'MARKETING';
  static TECH = 'TECH';

  static getStr(en) {
    switch (en) {
      case AdminRole.ROOT:
        return 'Super Admin';
      case AdminRole.FINANCE:
        return 'Finance';
      case AdminRole.OPERATION:
        return 'Operation';
      case AdminRole.SUPERVISOR:
        return 'Supervisor';
      case AdminRole.RISK:
        return 'Risk';
      case AdminRole.MARKETING:
        return 'Marketing';
      case AdminRole.TECH:
        return 'Tech';
      default:
        return 'Unknown';
    }
  }
}

const AdminRoleOpts = [
  { value: AdminRole.ROOT, label: AdminRole.getStr(AdminRole.ROOT) },
  { value: AdminRole.FINANCE, label: AdminRole.getStr(AdminRole.FINANCE) },
  { value: AdminRole.OPERATION, label: AdminRole.getStr(AdminRole.OPERATION) },
  { value: AdminRole.SUPERVISOR, label: AdminRole.getStr(AdminRole.SUPERVISOR) },
  { value: AdminRole.RISK, label: AdminRole.getStr(AdminRole.RISK) },
  { value: AdminRole.MARKETING, label: AdminRole.getStr(AdminRole.MARKETING) },
  { value: AdminRole.TECH, label: AdminRole.getStr(AdminRole.TECH) },
];

export { AdminRole, AdminRoleOpts };
