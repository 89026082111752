import { yupResolver } from '@hookform/resolvers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { partnerApi } from '../../../../services/partnerApi';
import { toIDR } from '../../../../utils/helpers/currency';
import FormField from '../../../../components/Forms/FormField';
import HookTextField from '../../../../components/Forms/HookTextField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';

const RulesCard = ({ item, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Rules</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ?
            <><CloseIcon fontSize="small" /><span className="ml-2 text-uppercase">Cancel</span></> :
            <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
          }
        </button>
      </div>
      {isEditing
        ?
        <Form
          item={item}
          onSuccess={onSuccess}
          setIsLoading={setIsLoading}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
        />
        : <View
          item={item}
        />
      }
      <LoadingBlocker in={isLoading} />
    </div>
  )
}

const schema = yup.object().shape({
  enabledRules: yup.string(),
  customFee: yup.number().typeError('Input must be numbers').when(['enabledRules'], {
    is: (enabledRules) => enabledRules === 'enable',
    then: yup => yup.required('Custom Fee is required')
  }),
  uniquePartnerNumber: yup.string().typeError('Input must be numbers').when(['enabledRules'], {
    is: (enabledRules) => enabledRules === 'enable',
    then: yup => yup.required('Custom Fee is required').min(2, 'Must be exactly 2 digits').max(2, 'Must be exactly 2 digits')
  }),

  enabledBankTransferRules: yup.string(),
  bankTransferCustomFee: yup.number().typeError('Input must be numbers').when(['enabledBankTransferRules'], {
    is: (enabledBankTransferRules) => enabledBankTransferRules === 'enable',
    then: yup => yup.required('Custom Fee is required')
  }),
});

const View = ({ item }) => {
  return (
    <>
      <FormField
        row
        className="mt-4"
        label={
          <div className="d-flex justify-content-between align-items-center mt-2">
            <h5 className="card-title mb-0">Rules Virtual Account</h5>
          </div>
        }
      >
        <RadioGroup
          row
          value={item?.rules?.enable ? 'enable' : 'disable'}
        >
          <FormControlLabel value="enable" disabled={true} control={<Radio color="primary" />} label="Enable" />
          <FormControlLabel value="disable" disabled={true} control={<Radio color="primary" />} label="Disable" />
        </RadioGroup>
      </FormField>
      {
        item?.rules?.enable
        &&
        <>
          <HookTextField
            label="Unique Partner Number"
            value={item?.rules?.uniquePartnerNumber}
            disabled={true}
            row
          />
          <HookTextField
            label="Minimum Top Up"
            value={item?.rules?.minimumTopUp}
            disabled={true}
            row
          />
          <HookTextField
            label="Custom Fee"
            prepend="Rp"
            value={item?.rules?.customFee}
            disabled={true}
            row
          />
        </>
      }
      <FormField
        row
        className="mt-4"
        label={
          <div className="d-flex justify-content-between align-items-center mt-2">
            <h5 className="card-title mb-0">Rules Bank Transfer</h5>
          </div>
        }
      >
        <RadioGroup
          row
          name="enabledBankTransferRules"
          value={item?.bankTransferRules?.enable ? 'enable' : 'disable'}
        >
          <FormControlLabel value="enable" disabled={true} control={<Radio color="primary" />} label="Enable" />
          <FormControlLabel value="disable" disabled={true} control={<Radio color="primary" />} label="Disable" />
        </RadioGroup>
      </FormField>
      {
        item?.bankTransferRules?.enable
        &&
        <HookTextField
          label="Custom Fee"
          prepend="Rp"
          value={item?.bankTransferRules?.customFee}
          disabled={true}
          row
        />
      }
    </>
  )
}

const Form = ({ item, onSuccess = () => { }, setIsLoading, isEditing, setIsEditing }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      enabledRules: item?.rules?.enable ? 'enable' : 'disable',
      customFee: item?.rules?.customFee,
      minimumTopUp: item?.rules?.minimumTopUp,
      uniquePartnerNumber: item?.rules?.uniquePartnerNumber,
      enabledBankTransferRules: item?.bankTransferRules?.enable ? 'enable' : 'disable',
      bankTransferCustomFee: item?.bankTransferRules?.customFee
    },
  });
  const {
    enabledRules,
    customFee,
    minimumTopUp,
    enabledBankTransferRules,
    bankTransferCustomFee
  } = watch([
    'enabledRules',
    'customFee',
    'minimumTopUp',
    'enabledBankTransferRules',
    'bankTransferCustomFee'
  ]);

  const onSubmit = (values) => {
    const data = {
      ...item,
      rules: {
        enable: values.enabledRules === 'enable' ? 1 : 0,
        customFee: values.enabledRules === 'enable' ? values?.customFee : 0,
        minimumTopUp: values.enabledRules === 'enable' ? values?.minimumTopUp : 0,
        uniquePartnerNumber: values.enabledRules === 'enable' ? values?.uniquePartnerNumber : ""
      },
      bankTransferRules: {
        enable: values.enabledBankTransferRules === 'enable' ? 1 : 0,
        customFee: values.enabledBankTransferRules === 'enable' ? values?.bankTransferCustomFee : 0
      }
    };

    if (item.integrationType === 'ZAKAT') {
      data.maal = item.zakat.maal ?? false
      data.fitrah = item.zakat.fitrah ?? false
      data.profesi = item.zakat.profesi ?? false
    }

    setIsLoading(true);
    dispatch({ type: null, call: partnerApi.update, args: [item._id, data] })
      .then(() => {
        onSuccess();
        setIsEditing(false);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      })
  };

  useEffect(() => {
    register("enabledRules");
    register("enabledBankTransferRules")

    return () => {
      unregister("enabledRules");
      unregister("enabledBankTransferRules");
    };
  }, [unregister, register])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormField
        row
        className="mt-4"
        label={
          <div className="d-flex justify-content-between align-items-center mt-2">
            <h5 className="card-title mb-0">Rules Virtual Account</h5>
          </div>
        }
      >
        <RadioGroup
          row
          name="enabledRules"
          value={enabledRules}
          onChange={e => setValue('enabledRules', e.target.value)}
        >
          <FormControlLabel value="enable" disabled={!isEditing} control={<Radio color="primary" />} label="Enable" />
          <FormControlLabel value="disable" disabled={!isEditing} control={<Radio color="primary" />} label="Disable" />
        </RadioGroup>
      </FormField>
      {
        enabledRules === 'enable'
        &&
        <>
          <HookTextField
            ref={register}
            label="Unique Partner Number"
            name="uniquePartnerNumber"
            disabled={enabledRules === 'disable' || !isEditing}
            error={errors.uniquePartnerNumber}
            helperText={errors.uniquePartnerNumber ? errors.uniquePartnerNumber.message : null}
            row
          />
          <HookTextField
            ref={register}
            label="Minimum Top Up"
            name="minimumTopUp"
            disabled={enabledRules === 'disable' || !isEditing}
            error={errors.minimumTopUp}
            helperText={errors.minimumTopUp ? errors.minimumTopUp.message : toIDR(minimumTopUp)}
            row
          />
          <HookTextField
            ref={register}
            label="Custom Fee"
            name="customFee"
            prepend="Rp"
            disabled={enabledRules === 'disable' || !isEditing}
            error={errors.customFee}
            helperText={errors.customFee ? errors.customFee.message : toIDR(customFee)}
            row
          />
        </>
      }
      <FormField
        row
        className="mt-4"
        label={
          <div className="d-flex justify-content-between align-items-center mt-2">
            <h5 className="card-title mb-0">Rules Bank Transfer</h5>
          </div>
        }
      >
        <RadioGroup
          row
          name="enabledBankTransferRules"
          value={enabledBankTransferRules}
          onChange={e => setValue('enabledBankTransferRules', e.target.value)}
        >
          <FormControlLabel value="enable" disabled={!isEditing} control={<Radio color="primary" />} label="Enable" />
          <FormControlLabel value="disable" disabled={!isEditing} control={<Radio color="primary" />} label="Disable" />
        </RadioGroup>
      </FormField>
      {
        enabledBankTransferRules === 'enable'
        &&
        <HookTextField
          prepend="Rp"
          ref={register}
          label="Custom Fee"
          name="bankTransferCustomFee"
          disabled={enabledBankTransferRules === 'disable' || !isEditing}
          error={errors.bankTransferCustomFee}
          helperText={errors.bankTransferCustomFee ? errors.bankTransferCustomFee.message : toIDR(bankTransferCustomFee)}
          row
        />
      }
      {
        isEditing
        &&
        <FormField
          row
        >
          <LoadingBtn type="submit" className="btn btn-primary mr-2">Submit</LoadingBtn>
          <button onClick={() => setIsEditing(false)} className="btn btn-danger">Cancel</button>
        </FormField>
      }
    </form>
  )
}

export default RulesCard;
