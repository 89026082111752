import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { INJECTION_BULKING_MODULE } from "../../../utils/constants/actionTypes";
import DetailTab from "./DetailTab";
import InjectTab from "./InjectTab";
import { injectionBulkingApi } from "../../../services/injectionBulkingApi";
import { combineBy } from "../../../utils/helpers/combineBy";

const Index = () => {
  const { currentPage, sort, limit } = useSelector(state => state.injectionBulking);

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Applicant Model', key: 'data', name: 'data', render: (v) => v[0][1] },
    {
      title: 'Requested By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[0];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    {
      title: 'Approval By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[1];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    { title: 'Description', key: 'description', name: 'description', render: v => v ? v : '-' },
    {
      title: 'Status', key: 'status', name: 'status'
    }
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort
    };
  }, [currentPage, limit, sort]);

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Applicant Model', key: 'data', name: 'data', render: (v) => v[0][1] },
    {
      title: 'Requested By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[0];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    {
      title: 'Approval By', key: 'createdBy', name: 'createdBy', render: function (v, row) {
        const history = row.statusHistories[1];
        const actor = history ? history.actor : null;
        return actor ? `${combineBy([actor.firstName, actor.lastName])} | ${actor.email}` : '-';
      }
    },
    { title: 'Description', key: 'description', name: 'description', render: v => v ? v : '-' },
    {
      title: 'Status', key: 'status', name: 'status'
    }
  ], [])

  return (
    <div>
      <Resource
        title="Injection Bulking"
        subTitle="Full Injection Bulking Control includes Create & Approval."
        icon="pe-7s-next"
        list={{
          columns: columns,
          reducerPath: "injectionBulking",
          call: injectionBulkingApi.list,
          apiQuery: apiQuery,
          module: INJECTION_BULKING_MODULE,
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Injection_Bulking',
            apiResponseKey: 'data.banners',
            queryParams: apiQuery
          },
        }}
        additionalTab={{
          component: InjectTab,
          title: "Inject"
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
