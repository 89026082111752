import { yupResolver } from '@hookform/resolvers'
import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import useMountedState from '../../../../components/HooksUse/useMountedState'
import HookTextField from '../../../../components/Forms/HookTextField'
import LoadingBtn from '../../../../components/Forms/LoadingBtn'
import * as yup from 'yup';
import styles from './detail.module.scss';
import SelectField from '../../../../components/Forms/SelectField'
import { companyApi } from '../../../../services/companyApi'

const schema = yup.object().shape({
  addresses: yup.array().of(
    yup.object().shape({
      address: yup.string().required('Address is required'),
      latitude: yup.string().required('Latitude is required'),
      longitude: yup.string().required('Longitude is required'),
      type: yup.string().required('Type is required')
    })
  )
});

const Address = ({ item, onSuccess = () => { } }) => {
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [updating, setUpdating] = useState(false)

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      addresses: item?.addresses
    }
  });

  const { fields, remove, append } = useFieldArray(
    {
      control,
      name: "addresses"
    }
  );

  const onSubmit = (values) => {
    setUpdating(true);
    dispatch({ type: null, call: companyApi.update, args: [item._id, values] })
      .then(() => {
        if (isMounted()) onSuccess();
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setUpdating(false) });
  };

  return (
    <div>
      <h5 className="card-title">Addresses</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          fields.map((item, index) => (
            <div key={item.id} className={styles.addressBox}>
              {
                fields.length > 1 &&
                <button type="button" className={`btn btn-sm btn-danger ${styles.deleteBtn}`} onClick={() => remove(index)}>Remove</button>
              }
              <h3>{index + 1}</h3>
              <div className="row">
                <div className="col-12 col-xl-4">
                  <Controller
                    defaultValue={item.type}
                    control={control}
                    name={`addresses[${index}].type`}
                    render={({ onChange, value }) => (
                      <SelectField
                        label="Type"
                        options={[
                          { label: 'Branch', value: 'BRANCH' },
                          { label: 'Headquarter', value: 'HQ' }
                        ]}
                        onChange={(v) => onChange(v?.value)}
                        value={value}
                        error={Boolean(errors?.addresses?.[index]?.type)}
                        helperText={errors?.addresses?.[index]?.type?.message}
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-xl-4">
                  <HookTextField
                    ref={register()}
                    defaultValue={item.latitude}
                    label="Latitude"
                    name={`addresses[${index}].latitude`}
                    error={Boolean(errors?.addresses?.[index]?.latitude)}
                    helperText={errors?.addresses?.[index]?.latitude?.message}
                  />
                </div>
                <div className="col-12 col-xl-4">
                  <HookTextField
                    defaultValue={item.longitude}
                    ref={register()}
                    label="Longitude"
                    name={`addresses[${index}].longitude`}
                    error={Boolean(errors?.addresses?.[index]?.longitude)}
                    helperText={errors?.addresses?.[index]?.longitude?.message}
                  />
                </div>
              </div>
              <HookTextField
                defaultValue={item.address}
                ref={register()}
                label="Address"
                name={`addresses[${index}].address`}
                error={Boolean(errors?.addresses?.[index]?.address)}
                helperText={errors?.addresses?.[index]?.address?.message}
              />
            </div>
          ))
        }

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-success"
            type="button"
            onClick={() => append()}
          >
            + Add Address
          </button>
        </div>

        <LoadingBtn type="submit" loading={updating} className="btn btn-primary btn-lg">Update</LoadingBtn>
      </form>
    </div>
  )
}

export default Address
