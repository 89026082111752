import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import ReportTableTemplateV2 from './ReportTableTemplateV2';
import { PpobType } from '../../../../utils/constants/enums/ppobTypes';

const getWalletType = (obj) => {
  for (let key in obj) {
    if (obj[key] > 0) return key
  }
}

const getName = (row) => {
  let biller = row.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return row.metadata?.payment?.metadata?.ppobResponse?.nama ?? '-';
  else if (biller === "RAJABILLER") return row.metadata?.payment?.metadata?.ppobResponse?.customername ?? '-';
  else if (biller === "TOKOPEDIA") return row.metadata?.payment?.metadata?.customerName ?? '-';
  return '-'
}

const getTagihan = (row) => {
  let biller = row.metadata?.payment?.metadata?.biller ?? null;
  if (biller === "BOSBILLER") return row.metadata?.payment?.metadata?.ppobResponse?.totalTagihan ?? '-';
  else if (biller === "RAJABILLER") return row.metadata?.payment?.metadata?.ppobResponse?.nominal ?? '-'
  else if (biller === "TOKOPEDIA") return row.metadata?.payment?.metadata?.ppobResponse?.data?.attributes?.sales_price ?? '-'
  return '-'
}

const TableBillerStreaming = ({ items, period }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let sumHargaBiller = 0;
    let sumRevenue = 0;
    let sumTotal = 0;

    items.forEach(row => {
      sumHargaBiller += +getTagihan(row) || 0;
      sumRevenue += +row.metadata?.payment?.metadata?.ppob?.revenue || 0;
      sumTotal += +row.metadata?.payment?.amount || 0;
    })

    amountArr = [
      { value: sumHargaBiller },
      { value: sumRevenue },
      { value: sumTotal }
    ]
    return amountArr;
  }, [items]);

  const columns = useMemo(() => {
    return [
      { title: "Date", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Trans .No", key: "code", name: "code" },
      { title: "Type", key: "type", name: "type", render: (v, row) => PpobType.getStrIdn(row.metadata.payment.metadata.ppob.type) },
      { title: "Nama", key: "name", name: "name", render: (v, row) => combineBy([row.sender?.firstName, row.sender?.lastName]) },
      { title: "Atas Nama", key: "custumerName", name: "custumerName", render: (v, row) => getName(row) },
      { title: "Wallet", key: "amount", name: "amount", render: (v) => getWalletType(v) },
      { title: "Harga Biller", key: "revenue", name: "revenue", withCurrency: true, render: (v, row) => getTagihan(row) },
      { title: "Revenue", key: "revenue", name: "revenue", withCurrency: true, render: (v, row) => row?.metadata?.payment?.metadata?.ppob?.revenue ?? '-' },
      { title: "Total", key: "total", name: "total", withCurrency: true, render: (v, row) => row.metadata?.payment?.amount ?? '-' }
    ]
  }, [])

  return (
    <ReportTableTemplateV2
      title="Biller Streaming"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableBillerStreaming
