export class DisclaimerTypes {
  static TNC = 'TNC';
  static PNP = 'PNP';
  static getStr(en) {
    switch (en) {
      case DisclaimerTypes.TNC:
        return 'Term & Condition';
      case DisclaimerTypes.PNP:
        return 'Privacy & Policy';
      default:
        return 'Unknown';
    }
  }
}
export const DisclaimerTypeOpts = [
  { value: DisclaimerTypes.TNC, label: DisclaimerTypes.getStr(DisclaimerTypes.TNC) },
  { value: DisclaimerTypes.PNP, label: DisclaimerTypes.getStr(DisclaimerTypes.PNP) },
];
