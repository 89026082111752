export const NAVHEAD = 'NAVHEAD';
export const NAVMENU = 'NAVMENU';
export const NAVLINK = 'NAVLINK';

export const CREDIT = 'CREDIT';
export const DEBIT = 'DEBIT';
export const PRIMARY = 'PRIMARY';
export const POINT = 'POINT';

export const MERCHANT = 'MERCHANT';
export const PPOB = 'PPOB';

export const REPORT_TOPUP = 'REPORT_TOPUP';
export const REPORT_MUTASI = 'REPORT_MUTASI';
export const REPORT_MF_INDOFUND = 'REPORT_MF_INDOFUND';
export const REPORT_BILLER_PULSA = 'REPORT_BILLER_PULSA';
export const REPORT_BILLER_POSTPAID = 'REPORT_BILLER_POSTPAID';
export const REPORT_BILLER_PHONE_PACKAGE = 'REPORT_BILLER_PHONE_PACKAGE';
export const REPORT_BILLER_PLN = 'REPORT_BILLER_PLN';
export const REPORT_BILLER_PLN_TOKEN = 'REPORT_BILLER_PLN_TOKEN';
export const REPORT_BILLER_PLN_NON_BILL = 'REPORT_BILLER_PLN_NON_BILL';
export const REPORT_BILLER_PDAM = 'REPORT_BILLER_PDAM';
export const REPORT_BILLER_GAME_VOUCHER = 'REPORT_BILLER_GAME_VOUCHER';
export const REPORT_BILLER_ETOLL = 'REPORT_BILLER_ETOLL';
export const REPORT_BILLER_TELKOM = 'REPORT_BILLER_TELKOM';
export const REPORT_BILLER_BPJS = 'REPORT_BILLER_BPJS';
export const REPORT_BILLER_PGN = 'REPORT_BILLER_PGN';
export const REPORT_BILLER_TV = 'REPORT_BILLER_TV';
export const REPORT_BILLER_SAMOLNAS = 'REPORT_BILLER_SAMOLNAS';
export const REPORT_BILLER_CC = 'REPORT_BILLER_CC';
export const REPORT_BILLER_PBB = 'REPORT_BILLER_PBB';
export const REPORT_BILLER_MULTIFINANCE = 'REPORT_BILLER_MULTIFINANCE';
export const REPORT_DIRECT_INTEGRATION = 'REPORT_DIRECT_INTEGRATION';
export const REPORT_MERCHANT_INDOFUND = 'REPORT_MERCHANT_INDOFUND';
export const REPORT_WALLET = 'REPORT_WALLET';
export const REPORT_INJECTION = 'REPORT_INJECTION';
export const REPORT_DEDUCTION = 'REPORT_DEDUCTION';
export const REPORT_SETTLEMENT = 'REPORT_SETTLEMENT';
export const REPORT_WITHDRAW = 'REPORT_WITHDRAW';
export const REPORT_VOUCHER = 'REPORT_VOUCHER';
export const REPORT_BILLER_INTERNET = 'REPORT_BILLER_INTERNET';
export const REPORT_BILLER_STREAMING = 'REPORT_BILLER_STREAMING';
export const REPORT_BILLER_EDU_VOUCHER = 'REPORT_BILLER_EDU_VOUCHER';
export const REPORT_BILLER_EDUCATION = 'REPORT_BILLER_EDUCATION';

export const balanceOptions = [
  { value: POINT, label: POINT },
  { value: PRIMARY, label: PRIMARY },
];
