import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MAIN_TAB_LOAD, CREATE_TAB, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, REJECT_REASON_MODULE, ADD_DELETE_DATA, REMOVE_DELETE_DATA, DELETE } from '../../../utils/constants/actionTypes';
import ButtonDispatch from '../../../components/ButtonDispatch';
import { rejectReasonApi } from '../../../services/rejectReasonApi';
import { rejectReasonTypes } from '../../../utils/constants/enums/rejectReasonTypes';

const MainTab = () => {
  const dispatch = useDispatch();
  const { inProgress, currentPage, limit, sort, items, total, deleteRowId } = useSelector(state => state.rejectReason);

  const load = useCallback(
    () => {
      dispatch({
        module: REJECT_REASON_MODULE,
        type: MAIN_TAB_LOAD,
        call: rejectReasonApi.list,
        args: [
          {
            page: currentPage,
            limit,
            sort
          }
        ]
      });
    },
    [currentPage, limit, sort, dispatch],
  )

  const setTableConfig = type => val => {
    dispatch({
      module: REJECT_REASON_MODULE,
      type: type,
      value: val
    });
  }

  const deleteSelected = useCallback(id => e => {
    dispatch({
      module: REJECT_REASON_MODULE,
      type: DELETE,
      call: rejectReasonApi.delete,
      args: [id]
    })
      .then(() => load())
      .catch(() => { })
  }, [dispatch, load])

  const createTab = useCallback((key, title) => {
    dispatch({
      module: REJECT_REASON_MODULE,
      type: CREATE_TAB,
      key,
      title
    })
  }, [dispatch])

  const table = useMemo(() => ({
    inProgress: inProgress,
    data: items,
    sort: sort,
    limit: limit,
    total: total,
    currentPage: currentPage,
    columns: [
      {
        title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd')
        }
      },
      { title: 'Title', key: 'title', name: 'title' },
      { title: 'Desc', key: 'description', name: 'description' },
      { title: 'Type', key: 'category', name: 'category', render: (v) => rejectReasonTypes.getStr(v) },
      {
        title: 'Action', key: '_id', name: 'action', className: 'action-column', sortable: false, render: (v, row) => {
          return (
            <div className="d-flex">
              <button
                className="btn btn-icon btn-icon-only btn-sm btn-primary"
                onClick={() => createTab(v, row.title)}
              >
                <i className="pe-7s-tools btn-icon-wrapper"></i>
              </button>
              <div className="btn-group ml-1">
                {deleteRowId === v ? (
                  <>
                    <ButtonDispatch type={REMOVE_DELETE_DATA} module={REJECT_REASON_MODULE} className="btn btn-sm btn-danger lh-1">No</ButtonDispatch>
                    <button onClick={deleteSelected(v)} type="button" className="btn btn-sm btn-danger lh-1 py-0">Yes, Delete</button>
                  </>
                ) : (
                  <ButtonDispatch className="btn btn-icon btn-icon-only btn-sm btn-danger" args={{ _id: v }} module={REJECT_REASON_MODULE} type={ADD_DELETE_DATA}>
                    <i className="pe-7s-trash btn-icon-wrapper"></i>
                  </ButtonDispatch>
                )}
              </div>
            </div>
          )
        }
      }
    ]
  }), [createTab, currentPage, deleteRowId, deleteSelected, inProgress, items, limit, sort, total])

  useEffect(() => {
    load();
  }, [load])

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        />
      </div>
    </div>
  )
}

export default MainTab
