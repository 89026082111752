class AllStatus {
  static PROCESSING = 'PROCESSING'
  static PENDING = 'PENDING'
  static REQUEST = 'REQUEST';

  static TOPUP_SUCCESS = 'TOPUP_SUCCESS';
  static TRANSFER_FAILED = 'TRANSFER_FAILED';
  static TRANSFER_SUCCESS = 'TRANSFER_SUCCESS';

  static UNLOCKED_PROCESSING = 'UNLOCKED_PROCESSING';
  static UNSUSPEND_PROCESSING = 'UNSUSPEND_PROCESSING';
  static UNBLACKLIS_PROCESSING = 'UNBLACKLIS_PROCESSING';

  static PROCESSING_COMPLETED = 'PROCESSING_COMPLETED';
  static PROCESSING_FAILED = 'PROCESSING_FAILED';
  static PROCESSING_SUSPECTED = 'PROCESSING_SUSPECTED';

  static REFUND_FAILED = 'REFUND_FAILED';
  static REFUND_COMPLETED = 'REFUND_COMPLETED';
  static VERIFIED = 'VERIFIED';

  static REJECT_UNBLACKLIST = 'REJECT_UNBLACKLIST'
  static REJECT_UNLOCKED = 'REJECT_UNLOCKED'
  static REJECT_UNSUSPEND = 'REJECT_UNSUSPEND'

  static REJECTED_VERIFY = 'REJECTED_VERIFY'
  static REJECTED = 'REJECTED'

  static DECLINED = 'DECLINED'
  static CANCELED = "CANCELED"
  static CANCELLED = 'CANCELLED'
  static FAILED = 'FAILED'

  static CHECKED = 'CHECKED'
  static APPROVED = 'APPROVED'
  static COMPLETED = 'COMPLETED'
  static REFUNDED = 'REFUNDED'
  static SUSPECTED = 'SUSPECTED'
  static REFUND_PROCESSING = 'REFUND_PROCESSING'

  static KYC = 'KYC'
  static NON_KYC = 'NON_KYC'
  static FORCE_KYC = 'FORCE_KYC'

  static SUSPENDED = 'SUSPENDED';
  static BLACKLIST = 'BLACKLIST';
  static LOCKED = 'LOCKED';
  static UNLOCKED = 'UNLOCKED';
  static PENDING_VERIFY = 'PENDING_VERIFY';
  static UPGRADED = 'UPGRADED';
  static NOT_UPGRADED = 'NOT_UPGRADED';
  static FLAGGED = 'FLAGGED';
  static CLOSED = 'CLOSED';
  static CLOSED_ACCOUNT = 'CLOSED';
  static UNBLACKLIST = 'UNBLACKLIST'
  static UNSUSPEND = 'UNSUSPEND'

  static USABLE = 'USABLE';
  static USED = 'USED';
  static EXPIRED = 'EXPIRED';

  static ACTIVE = 'ACTIVE';
  static INACTIVE = 'INACTIVE';
  static NEW = 'NEW';
  static ALL = 'ALL';

  static getStr(en) {
    switch (en) {
      case AllStatus.PROCESSING:
        return 'Processing'
      case AllStatus.PENDING:
        return 'Pending'
      case AllStatus.REQUEST:
        return 'Request'
      case AllStatus.TOPUP_SUCCESS:
        return 'Topup Success'
      case AllStatus.TRANSFER_FAILED:
        return 'Transfer Failed'
      case AllStatus.TRANSFER_SUCCESS:
        return 'Transfer Success'
      case AllStatus.UNLOCKED_PROCESSING:
        return 'Unlock Processing'
      case AllStatus.UNSUSPEND_PROCESSING:
        return 'Unsuspend Processing'
      case AllStatus.UNBLACKLIS_PROCESSING:
        return 'Unblacklist Processing'
      case AllStatus.PROCESSING_COMPLETED:
        return 'Processing Completed'
      case AllStatus.PROCESSING_FAILED:
        return 'Processing Failed'
      case AllStatus.PROCESSING_SUSPECTED:
        return 'Processing Suspected'
      case AllStatus.REFUND_COMPLETED:
        return 'Refund Processing'
      case AllStatus.VERIFIED:
        return 'Verified'
      case AllStatus.REFUND_FAILED:
        return 'Refund Failed'
      case AllStatus.REJECTED:
        return 'Rejected'
      case AllStatus.REJECTED_VERIFY:
        return 'Rejected PTEN'
      case AllStatus.REJECT_UNBLACKLIST:
        return 'Unblacklist Process Rejected'
      case AllStatus.REJECT_UNLOCKED:
        return 'Unlocked Process Rejected'
      case AllStatus.REJECT_UNSUSPEND:
        return 'Unsuspend Process Rejected'
      case AllStatus.SUSPECTED:
        return 'Suspected'
      case AllStatus.DECLINED:
        return 'Declined'
      case AllStatus.CANCELED:
        return 'Canceled'
      case AllStatus.CANCELLED:
        return 'Cancelled'
      case AllStatus.FAILED:
        return 'Failed'
      case AllStatus.APPROVED:
        return 'Approved'
      case AllStatus.COMPLETED:
        return 'Completed'
      case AllStatus.REFUNDED:
        return 'Refunded'
      case AllStatus.REFUND_PROCESSING:
        return 'Refund Processing'
      case AllStatus.KYC:
        return 'KYC'
      case AllStatus.NON_KYC:
        return 'Non KYC'
      case AllStatus.FORCE_KYC:
        return 'Force KYC'
      case AllStatus.SUSPENDED:
        return 'Suspended'
      case AllStatus.BLACKLIST:
        return 'Blacklist'
      case AllStatus.LOCKED:
        return 'Locked'
      case AllStatus.UNLOCKED:
        return 'Unlocked'
      case AllStatus.PENDING_VERIFY:
        return 'Pending Verify'
      case AllStatus.UPGRADED:
        return 'Upgraded'
      case AllStatus.NOT_UPGRADED:
        return 'Not Upgraded'
      case AllStatus.FLAGGED:
        return 'Flagged'
      case AllStatus.CLOSED:
        return 'Closed'
      case AllStatus.UNBLACKLIST:
        return 'Unblacklist'
      case AllStatus.UNSUSPEND:
        return 'Unsuspend'
      case AllStatus.USABLE:
        return 'Usable'
      case AllStatus.USED:
        return 'Used'
      case AllStatus.EXPIRED:
        return 'Expired'
      case AllStatus.ACTIVE:
        return 'Active'
      case AllStatus.INACTIVE:
        return 'Inactive'
      case AllStatus.NEW:
        return 'New'
      case AllStatus.CHECKED:
        return 'Checked'
      case AllStatus.ALL:
        return 'All'
      default:
        return 'Unknown'
    }
  }

  static getStrIdn(en) {
    switch (en) {
      case AllStatus.PROCESSING:
        return 'Sedang Diproses'
      case AllStatus.PENDING:
        return 'Pending'
      case AllStatus.REQUEST:
        return 'Meminta'
      case AllStatus.TOPUP_SUCCESS:
        return 'Topup Berhasil'
      case AllStatus.TRANSFER_FAILED:
        return 'Transfer Gagal'
      case AllStatus.TRANSFER_SUCCESS:
        return 'Transfer Berhasil'
      case AllStatus.UNLOCKED_PROCESSING:
        return 'Proses Membuka Kunci'
      case AllStatus.UNSUSPEND_PROCESSING:
        return 'Proses Membuka Tangguhan'
      case AllStatus.UNBLACKLIS_PROCESSING:
        return 'Proses Membuka Daftar Hitam'
      case AllStatus.PROCESSING_COMPLETED:
        return 'Proses Selesai'
      case AllStatus.PROCESSING_FAILED:
        return 'Proses Gagal'
      case AllStatus.PROCESSING_SUSPECTED:
        return 'Proses Suspect'
      case AllStatus.REFUND_COMPLETED:
        return 'Refund Selesai'
      case AllStatus.VERIFIED:
        return 'Diverifikasi PTEN'
      case AllStatus.REFUND_FAILED:
        return 'Refund Gagal'
      case AllStatus.REJECTED:
        return 'Ditolak'
      case AllStatus.REJECTED_VERIFY:
        return 'Verify PTEN Ditolak'
      case AllStatus.REJECT_UNBLACKLIST:
        return 'Proses Membuka Daftar Hitam Ditolak'
      case AllStatus.REJECT_UNLOCKED:
        return 'Proses Membuka Kunci Ditolak'
      case AllStatus.REJECT_UNSUSPEND:
        return 'Proses Membuka Tangguhan Ditolak'
      case AllStatus.SUSPECTED:
        return 'Dicurigai'
      case AllStatus.DECLINED:
        return 'Ditolak'
      case AllStatus.CANCELED:
        return 'Dibatalkan'
      case AllStatus.CANCELLED:
        return 'Cancelled'
      case AllStatus.FAILED:
        return 'Transaksi Gagal'
      case AllStatus.APPROVED:
        return 'Diverifikasi'
      case AllStatus.COMPLETED:
        return 'Transaksi Selesai'
      case AllStatus.REFUNDED:
        return 'Berhasil di Refund'
      case AllStatus.REFUND_PROCESSING:
        return 'Refund Diproses'
      case AllStatus.KYC:
        return 'KYC'
      case AllStatus.NON_KYC:
        return 'Non KYC'
      case AllStatus.FORCE_KYC:
        return 'Paksa KYC'
      case AllStatus.SUSPENDED:
        return 'Ditangguhkan'
      case AllStatus.BLACKLIST:
        return 'Daftar Hitam'
      case AllStatus.LOCKED:
        return 'Dikunci'
      case AllStatus.UNLOCKED:
        return 'Buka Kunci'
      case AllStatus.PENDING_VERIFY:
        return 'Verifikasi Tertunda'
      case AllStatus.UPGRADED:
        return 'Berhasil Di-Upgrade'
      case AllStatus.NOT_UPGRADED:
        return 'Belum Di-Upgrade'
      case AllStatus.FLAGGED:
        return 'Ditandai'
      case AllStatus.CLOSED:
        return 'Ditutup'
      case AllStatus.UNBLACKLIST:
        return 'Buka Daftar Hitam'
      case AllStatus.UNSUSPEND:
        return 'Buka Tangguhan'
      case AllStatus.USABLE:
        return 'Dapat Digunakan'
      case AllStatus.USED:
        return 'Telah Digunakan'
      case AllStatus.EXPIRED:
        return 'Kadaluarsa'
      case AllStatus.ACTIVE:
        return 'Aktif'
      case AllStatus.INACTIVE:
        return 'Tidak Aktif'
      case AllStatus.NEW:
        return 'Baru'
      case AllStatus.CHECKED:
        return 'Diperiksa'
      case AllStatus.ALL:
        return 'Semua'
      default:
        return 'Unknown'
    }
  }
}

export default AllStatus
