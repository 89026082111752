import React from 'react'
import BusinessInformation from './BusinessInformation'

const GeneralInformation = ({ item, module, onSuccess = () => { }, readOnly }) => {
  return (
    <div className="row">
      <div className="col-12">
        <BusinessInformation
          item={item}
          module={module}
          onSuccess={onSuccess}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

export default GeneralInformation;
