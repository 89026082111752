import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { transactionApi } from '../../../../services/transactionApi';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const TableWallet = ({ items, period, model, totalBalance }) => {
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let totalCumulative = 0;
    let totalPoint = 0;

    items.forEach(i => {
      totalCumulative += +i.transferIn || 0;
      totalPoint += +i.point || 0;
    });

    if (model === 'USER') {
      amountArr = [
        { value: totalCumulative },
        { value: totalBalance },
        { value: totalPoint },
      ]
    } else {
      amountArr = [
        { value: totalCumulative },
        { value: totalBalance }
      ]
    }

    if (model !== 'USER') amountArr.slice(0, -1)
    return amountArr;
  }, [items, model, totalBalance]);

  const columns = useMemo(() => [
    { title: "Date", key: "date", name: "date", render: (v) => v ? format(new Date(v), 'yyyy-MM-dd HH:mm:ss') : "-" },
    {
      title: 'Name | Unique ID',
      key: "fullName",
      name: "name",
      render: (v, row) => `${v} | ${row.uniqueId}`
    },
    { title: "Transfer In", key: "transferIn", sortable: true, withCurrency: true },
    {
      title: "Saldo",
      key: "balance",
      name: "balance",
      render: (v, row) => v || Number(v) === 0
        ? v === null
          ? <span className="text-black-50">Data error</span>
          : v
        : 'No Transaction Found',
      withCurrency: true,
      sortable: true
    },
    {
      title: model === 'USER' ? "Point" : null, sortable: true,
      key: "point",
      name: "point",
      hide: !Boolean(model === 'USER'),
      withCurrency: true,
      render: (v, row) => model === 'USER'
        ? v || Number(v) === 0
          ? v === null
            ? <span className="text-black-50">Data error</span>
            : v
          : 'No Transaction Found'
        : null
    }
  ], [model])

  return (
    <ReportTableTemplateV2
      title="Wallet"
      period={period}
      withIndex
      withResetCache
      withExportExcel
      withPrintButton
      // dataPosition={`${format(new Date(dataPosition), 'yyyy-MM-dd HH:mm:ss')} - ${admin.firstName} ${admin.lastName}`}
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal,
      }}
      excel={{
        service: transactionApi.walletReport,
        apiResponseKey: "data.transactions",
        queryParams: {
          uType: model,
          fromDate: format(new Date(period[0]), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          toDate: format(new Date(period[1]), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        }
      }}
    />
  )
}

export default TableWallet
