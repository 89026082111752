import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  PARTNER_SETTLEMENT_MODULE
} from "../../../utils/constants/actionTypes";
import Resource from "../../../components/Resource";
import Detail from "./Detail";
import { partnerSettlementApi } from "../../../services/partnerSettlementApi";
import useAsync from "../../../components/HooksUse/useAsync";
import { toIDR } from "../../../utils/helpers/currency";
import LoadingBlocker from "../../../components/Loading/LoadingBlocker";

const Index = () => {
  const dispatch = useDispatch();
  const { currentPage, limit, sort } = useSelector(
    (state) => state.partner.settlement
  );

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Code', key: 'code', name: 'code' },
    {
      title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: v => `${v?.name} | ${v?.uniqueId}`
    },
    { title: 'Amount', key: 'amount', name: 'amount', render: v => toIDR(v) },
    { title: 'Status', key: 'status', name: 'status' }
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit,
      sort,
    };
  }, [currentPage, limit, sort]);

  const {
    execute: createBulk,
    pending: isCreatingBulk
  } = useAsync(
    useCallback(
      () => dispatch({ type: null, call: partnerSettlementApi.createBulk })
      , [dispatch])
  )

  const {
    execute: createReqSettlement9,
    pending: isRequestingSettlement9
  } = useAsync(
    useCallback(
      () => dispatch({ type: null, call: partnerSettlementApi.createReqSettlement(9) })
      , [dispatch])
  )

  const {
    execute: createReqSettlement14,
    pending: isRequestingSettlement14
  } = useAsync(
    useCallback(
      () => dispatch({ type: null, call: partnerSettlementApi.createReqSettlement(14) })
      , [dispatch])
  )

  return (
    <div>
      <Resource
        title="Partner Settlement"
        subTitle="Full Partner Settlement Control."
        icon="pe-7s-global"
        list={{
          columns: columns,
          reducerPath: "partner.settlement",
          module: PARTNER_SETTLEMENT_MODULE,
          call: partnerSettlementApi.list,
          apiQuery: apiQuery,
          additionalButton: (
            <>
              <LoadingBlocker in={Boolean(isCreatingBulk || isRequestingSettlement9 || isRequestingSettlement14)} />
              <div className="mb-3 d-flex justify-content-end gap-2">
                <button
                  className="btn btn-outline-primary mr-2"
                  onClick={createBulk}
                >
                  Create Bulk Settlement
                </button>
                <CreateReqSettlement9
                  createReqSettlement9={createReqSettlement9}
                  isRequestingSettlement9={isRequestingSettlement9}
                />
                <CreateReqSettlement14
                  createReqSettlement14={createReqSettlement14}
                  isRequestingSettlement14={isRequestingSettlement14}
                />
              </div>
            </>
          )
        }}
        detail={{ component: Detail }}
      />
    </div >
  );
};

const CreateReqSettlement9 = ({
  createReqSettlement9,
  isRequestingSettlement9
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [date, setDate] = useState(new Date())

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const hoursStart = 9
  const minutesStart = 0
  const hoursEnd = 13
  const minutesEnd = 20

  useEffect(() => {
    const start = hours <= hoursStart ? hours >= hoursStart && minutes >= minutesStart : hours >= hoursStart
    const end = hours >= hoursEnd ? hours <= hoursEnd && minutes < minutesEnd : hours <= hoursEnd
    if (start && end) setIsVisible(true);
    else setIsVisible(false);
  }, [date, hours, minutes]);

  useEffect(() => {
    const countTime = setInterval(() => {
      setDate(new Date())
    }, 1000)

    return () => {
      clearInterval(countTime)
    }
  }, [])

  if (!isVisible) return <></>
  return (
    <button
      className="btn btn-primary"
      disabled={isRequestingSettlement9}
      onClick={createReqSettlement9}
    >
      Create Request Settlement Partner (09.00 WIB)
    </button>
  )
}

const CreateReqSettlement14 = ({
  createReqSettlement14,
  isRequestingSettlement14
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [date, setDate] = useState(new Date())

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const hoursStart = 13
  const minutesStart = 25
  const hoursEnd = 23
  const minutesEnd = 59

  useEffect(() => {
    const start = hours <= hoursStart ? hours >= hoursStart && minutes >= minutesStart : hours >= hoursStart
    const end = hours >= hoursEnd ? hours <= hoursEnd && minutes < minutesEnd : hours <= hoursEnd
    if (start && end) setIsVisible(true);
    else setIsVisible(false);
  }, [date, hours, minutes]);

  useEffect(() => {
    const countTime = setInterval(() => {
      setDate(new Date())
    }, 1000)

    return () => {
      clearInterval(countTime)
    }
  }, [])

  if (!isVisible) return <></>
  return (
    <button
      className="btn btn-primary"
      disabled={isRequestingSettlement14}
      onClick={createReqSettlement14}
    >
      Create Request Settlement Partner (14.10 WIB)
    </button>
  )
}

export default Index;
