import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';

const CollapseCard = ({ defaultOpen, className, title, children }) => {
  const [open, setOpen] = useState(defaultOpen ?? false)

  return (
    <div className={`card collapse-card${open ? ' open' : ''}${className ? ` ${className}` : ''}`}>
      <div className="card-header" onClick={() => setOpen(!open)}>
        <h5 className="card-title mb-0">{title}</h5>
        <div className="btn-actions-pane-right actions-icon-btn">
          <button className="btn-icon btn-icon-only btn btn-link">
            <i className="pe-7s-angle-down btn-icon-wrapper"></i>
          </button>
        </div>
      </div>
      <Collapse in={open}>
        <div className="card-body">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default CollapseCard