class BusinessStatusHistoriesType {
    static BUSINESS_VERIFY_APPROVED = 'BUSINESS_VERIFY_APPROVED';
    static BUSINESS_VERIFY_REJECTED = 'BUSINESS_VERIFY_REJECTED';
    static BUSINESS_VERIFY_PTEN_APPROVED = 'BUSINESS_VERIFY_PTEN_APPROVED';
    static BUSINESS_VERIFY_PTEN_REJECTED = 'BUSINESS_VERIFY_PTEN_REJECTED';
    static BUSINESS_UNLOCK_APPROVED = 'BUSINESS_UNLOCK_APPROVED';
    static BUSINESS_UNLOCK_REJECTED = 'BUSINESS_UNLOCK_REJECTED';
    static BUSINESS_SUSPENDED = 'BUSINESS_SUSPENDED';
    static BUSINESS_BLACKLIST = 'BUSINESS_BLACKLIST';
    static BUSINESS_UNSUSPEND_APPROVED = 'BUSINESS_UNSUSPEND_APPROVED';
    static BUSINESS_UNSUSPEND_REJECTED = 'BUSINESS_UNSUSPEND_REJECTED';
    static BUSINESS_CLOSE_APPROVED = 'BUSINESS_CLOSE_APPROVED';
    static BUSINESS_CLOSE_REJECTED = 'BUSINESS_CLOSE_REJECTED';
    static BUSINESS_UNBLACKLIST_APPROVED = 'BUSINESS_UNBLACKLIST_APPROVED';
    static BUSINESS_UNBLACKLIST_REJECTED = 'BUSINESS_UNBLACKLIST_REJECTED';
    static BUSINESS_CHECKED = 'BUSINESS_CHECKED';

    static BUSINESS_REQUEST_VERIFY = 'BUSINESS_REQUEST_VERIFY';
    static BUSINESS_REQUEST_UNLOCK = 'BUSINESS_REQUEST_UNLOCK';
    static BUSINESS_REQUEST_UNSUSPEND = 'BUSINESS_REQUEST_UNSUSPEND';
    static BUSINESS_REQUEST_UNBLACKLIST = 'BUSINESS_REQUEST_UNBLACKLIST';

    static getStr(en) {
        switch (en) {
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_APPROVED:
                return 'Business Verify Approved';
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_REJECTED:
                return 'Business Verify Rejected';
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_PTEN_APPROVED:
                return 'Business Approved';
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_PTEN_REJECTED:
                return 'Business Rejected';
            case BusinessStatusHistoriesType.BUSINESS_SUSPENDED:
                return 'Business Suspended';
            case BusinessStatusHistoriesType.BUSINESS_BLACKLIST:
                return 'Business Blacklist';
            case BusinessStatusHistoriesType.BUSINESS_UNSUSPEND_APPROVED:
                return 'Business Unsuspended';
            case BusinessStatusHistoriesType.BUSINESS_UNSUSPEND_REJECTED:
                return 'Business Unsuspend Rejected';
            case BusinessStatusHistoriesType.BUSINESS_UNLOCK_APPROVED:
                return 'Business Unlocked';
            case BusinessStatusHistoriesType.BUSINESS_UNLOCK_REJECTED:
                return 'Business Unlock Rejected';
            case BusinessStatusHistoriesType.BUSINESS_CLOSE_APPROVED:
                return 'Business Closed';
            case BusinessStatusHistoriesType.BUSINESS_CLOSE_REJECTED:
                return 'Business Close Rejected';
            case BusinessStatusHistoriesType.BUSINESS_UNBLACKLIST_APPROVED:
                return 'Business Unblacklist Approved';
            case BusinessStatusHistoriesType.BUSINESS_UNBLACKLIST_REJECTED:
                return 'Business Unblacklist Rejected';
            case BusinessStatusHistoriesType.BUSINESS_CHECKED:
                return 'Business Checked';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_VERIFY:
                return 'Business Request Verify';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_UNLOCK:
                return 'Business Request Verify';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_UNSUSPEND:
                return 'Business Request Unsuspend';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_UNBLACKLIST:
                return 'Business Request Unblacklist';
            default:
                return 'Unknown';
        }
    }

    static getColor(en) {
        switch (en) {
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_APPROVED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_REJECTED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_PTEN_APPROVED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_VERIFY_PTEN_REJECTED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_SUSPENDED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_UNSUSPEND_APPROVED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_UNSUSPEND_REJECTED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_UNLOCK_APPROVED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_UNLOCK_REJECTED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_CLOSE_APPROVED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_CLOSE_REJECTED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_UNBLACKLIST_APPROVED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_UNBLACKLIST_REJECTED:
                return 'badge-danger';
            case BusinessStatusHistoriesType.BUSINESS_CHECKED:
                return 'badge-primary';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_VERIFY:
                return 'badge-warning';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_UNLOCK:
                return 'badge-warning';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_UNSUSPEND:
                return 'badge-warning';
            case BusinessStatusHistoriesType.BUSINESS_REQUEST_UNBLACKLIST:
                return 'badge-warning';
            default:
                return 'Unknown';
        }
    }

}

const BusinessStatusHistoriesEnum = [
    BusinessStatusHistoriesType.BUSINESS_VERIFY_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_VERIFY_REJECTED,
    BusinessStatusHistoriesType.BUSINESS_VERIFY_PTEN_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_VERIFY_PTEN_REJECTED,
    BusinessStatusHistoriesType.BUSINESS_UNLOCK_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_UNLOCK_REJECTED,
    BusinessStatusHistoriesType.BUSINESS_SUSPENDED,
    BusinessStatusHistoriesType.BUSINESS_BLACKLIST,
    BusinessStatusHistoriesType.BUSINESS_UNSUSPEND_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_UNSUSPEND_REJECTED,
    BusinessStatusHistoriesType.BUSINESS_UNLOCK_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_CLOSE_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_CLOSE_REJECTED,
    BusinessStatusHistoriesType.BUSINESS_UNBLACKLIST_APPROVED,
    BusinessStatusHistoriesType.BUSINESS_UNBLACKLIST_REJECTED,
    BusinessStatusHistoriesType.BUSINESS_CHECKED,
    BusinessStatusHistoriesType.BUSINESS_REQUEST_VERIFY,
    BusinessStatusHistoriesType.BUSINESS_REQUEST_UNLOCK,
    BusinessStatusHistoriesType.BUSINESS_REQUEST_UNSUSPEND,
    BusinessStatusHistoriesType.BUSINESS_REQUEST_UNBLACKLIST,
];

export { BusinessStatusHistoriesType, BusinessStatusHistoriesEnum };
