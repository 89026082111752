import { useState, useEffect } from "react";

export default function useDetectBrowser() {
  const userAgent = navigator.userAgent;
  let detectBrowserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    detectBrowserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    detectBrowserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    detectBrowserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    detectBrowserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    detectBrowserName = "edge";
  } else {
    detectBrowserName = "No browser detection";
  }

  const [browserName, setBrowserName] = useState({
    browser: detectBrowserName,
  });

  useEffect(() => {
    if (detectBrowserName) {
      setBrowserName({ browser: detectBrowserName });
    }
  }, [detectBrowserName]);

  return browserName;
}
