import React, { useMemo } from 'react';
import Resource from '../../../components/Resource';
import { endOfDay, format, startOfDay } from 'date-fns';
import { combineBy } from '../../../utils/helpers/combineBy';
import { useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import objectPath from 'object-path';
import { businessesApi } from '../../../services/businessesApi';
import BusinessStatuses from './BusinessStatuses';
import { ExcelTablePTEN } from './ExcelTablePTEN';
import useDetectBrowser from '../../../components/HooksUse/useDetectBrowser';
import { DATE_PICKER, SELECT_FIELD, MCC_SELECT, PARTNER_SELECT } from '../../../utils/constants/inputTypes';
import Detail from './Detail'
import { BUSINESS_ADMIN_PTEN_VERIFY_MODULE, BUSINESS_ADMIN_VERIFY_MODULE, BUSINESS_MODULE } from '../../../utils/constants/actionTypes';
// import HistoryStatusOpts from '../../../utils/constants/enums/status/historyStatusOpts';
import { merchantCriteriaTypeOpts } from '../../../utils/constants/enums/merchantCriteriaTypes';
import { merchantCriteriaType } from '../../../utils/constants/enums/merchantCriteriaTypes';
import { mccCategoryTypeOpts } from '../../../utils/constants/enums/mccCategoryTypes';

const statusOpts = [
  { value: "verified", label: "Verified" },
  { value: "checked", label: "Checked" },
  { value: "approved", label: "Approved" },
  { value: "blacklist", label: "Blacklist" },
  { value: "suspended", label: "Suspected" },
  { value: "closed", label: "Closed" },
  { value: "processing-close", label: "Closed Processing" },
  { value: "locked", label: "Locked" },
  { value: "not-verified", label: "Not Verified" },
  { value: "not-checked", label: "Not Checked" },
  { value: "not-approved", label: "Not Approved" },
  { value: "not-blacklist", label: "Not Blacklist" },
  { value: "not-suspended", label: "Not Suspected" },
  { value: "not-locked", label: "Not Locked" },
  { value: "new", label: "New Merchant" },
]

const BusinessList = ({
  reducerPath,
  module,
  title,
  subTitle
}) => {
  const { currentAdmin: { role } } = useSelector(state => state.common);
  const { browser } = useDetectBrowser();
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector((state) => objectPath.get(state, reducerPath));

  const isBusinessList = useMemo(() => {
    if (module === BUSINESS_MODULE) return true
    return false
  }, [module])

  const getBusinessDebounced = useMemo(
    () => AwesomeDebouncePromise(businessesApi.list, 500),
    [],
  )

  const formatFile = useMemo(() => {
    if (browser === "firefox") return ".xls";
    return ""
  }, [browser])

  const excelColumnsForPTEN = useMemo(() => [
    { title: 'NMID Eksisting', key: 'nmid', align: 'left', width: "200px" },
    { title: 'Nama Merchant (max 50)', key: 'name', name: 'name', render: function (v) { if (v && v.length > 50) return v.slice(0, 50); return v }, width: "300px" },
    { title: 'Nama Merchant (max 25)', key: 'name', name: 'name', render: function (v) { if (v && v.length > 25) return v.slice(0, 25); return v }, width: "250px" },
    { title: 'MPAN', key: 'MPAN', align: 'left', render: function (v) { if (v.length) return `'${v || ""}` }, width: "200px" },
    { title: 'MID', key: 'merchant.uniqueId', align: 'left', render: function (v) { if (v && v.length > 15) return v.slice(0, 15); return v }, width: "150px" },
    { title: 'Kota', key: 'locationForPten', width: "200px", render: function (v) { if (v && v.length > 15) return v.slice(0, 15); return v } },
    { title: 'Kodepos', key: 'postalCode', align: 'left', render: function (v) { if (v && v.length > 5) return v.slice(0, 5); return v }, width: "100px" },
    { title: 'Kriteria', key: 'MCriteria', width: "100px" },
    { title: 'MCC', key: 'MCC', align: 'left', width: "100px" },
    { title: 'Jml Terminal', key: 'TotalTerminal', align: 'center', width: "150px" },
    { title: 'Tipe Merchant', key: 'MrchntType', align: 'center', width: "150px" },
    { title: 'NPWP', key: 'npwp', align: 'left', width: "200px" },
    { title: 'KTP', key: 'merchant.archive.identityCard.identificationNumber', align: 'left', render: function (v) { if (v && v.length > 16) return v.slice(0, 16); return `'${v || ""}` }, width: "200px" },
    { title: 'Tipe QR', key: 'QRType', align: 'center', width: "100px" },
  ], [])

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Updated At', key: 'updatedAt', name: 'updatedAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Business Name', key: 'name', name: 'name', render: (v, row) => v + ' | ' + row?.merchant?.uniqueId },
    { title: 'Merchant Criteria', key: 'MCriteria', name: 'MCriteria', render: (v) => v ? merchantCriteriaType.getStr(v) : "-" },
    { title: 'Phone Number', key: 'phoneNumber', name: 'phoneNumber' },
    { title: 'Email', key: 'email', name: 'email', sortable: false },
    { title: 'Owner', key: 'merchant', name: 'merchant', render: v => combineBy([v?.firstName, v?.lastName]) + ' | ' + v?.email + ' | ' + v?.archive?.identityCard?.identificationNumber },
    { title: 'Referral Code', key: 'merchant', name: 'merchant', render: (v) => v?.referralCode ?? "-" },
    { title: 'Statuses', key: 'status', name: 'status', className: 'text-center', sortable: false, render: (v, row) => <BusinessStatuses status={v} item={row} /> },
  ]

  const excelColumns = useMemo(() => [
    // { title: 'Dipay ID', key: 'uniqueId' },
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm') },
    { title: 'Business Name', key: 'name', render: (v, row) => v + ' | ' + row?.merchant?.uniqueId },
    { title: 'Merchant Criteria', key: 'MCriteria', render: (v) => v ? merchantCriteriaType.getStr(v) : "-" },
    { title: 'Phone Number', key: 'phoneNumber' },
    { title: 'Email', key: 'email' },
    { title: 'Owner', key: 'merchant', name: 'merchant', render: v => combineBy([v?.firstName, v?.lastName]) + ' | ' + v?.email + ' | ' + v?.archive?.identityCard?.identificationNumber },
    { title: 'Referral Code', key: 'merchant', name: 'merchant', render: (v) => v?.referralCode ?? "-" },
    // { title: 'Balance/Primary', key: 'balance.primary' },
    // { title: 'Phone Number', key: 'phoneNumber', render: v => `'${v}` },
    // { title: 'Fullname', key: 'firstName', render: (v, row) => combineBy([row.firstName, row.lastName]) },
    // { title: 'Email', key: 'email' },
    // { title: 'Device 1/Last Login', key: 'devices', render: (v, row) => row.devices?.[0]?.lastLogin ? format(new Date(row.devices?.[0]?.lastLogin), 'yyyy-MM-dd HH:mm') : '-' },
    // { title: 'Device 1/UUID', key: 'devices', render: (v, row) => row.devices?.[0]?.uuid ?? '-' },
    // { title: 'Device 2/Last Login', key: 'devices', render: (v, row) => row.devices?.[1]?.lastLogin ? format(new Date(row.devices?.[1]?.lastLogin), 'yyyy-MM-dd HH:mm') : '-' },
    // { title: 'Device 2/UUID', key: 'devices', render: (v, row) => row.devices?.[1]?.uuid ?? '-' },
    // { title: 'Device 3/Last Login', key: 'devices', render: (v, row) => row.devices?.[2]?.lastLogin ? format(new Date(row.devices?.[2]?.lastLogin), 'yyyy-MM-dd HH:mm') : '-' },
    // { title: 'Device 3/UUID', key: 'devices', render: (v, row) => row.devices?.[2]?.uuid ?? '-' }
  ], [])

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const activeStartDate = filter.activeStartDate ? startOfDay(new Date(filter.activeStartDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const activeEndDate = filter.activeEndDate ? endOfDay(new Date(filter.activeEndDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const startDateHistories = filter.startDateHistories ? startOfDay(new Date(filter.startDateHistories), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const endDateHistories = filter.endDateHistories ? endOfDay(new Date(filter.endDateHistories), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").toISOString() : null;
    const mcc = filter.MCC?.value ? `${filter.MCC?.value}` : null
    const search = filter.search ? `phoneNumber|${filter.search},name|${filter.search},email|${filter.search},cityName|${filter.search},merchant.uniqueId|${filter.search},merchant.archive.identityCard.identificationNumber|${filter.search},note|${filter.search},fullName|${filter.search},merchant.referralCode|${filter.search}` : null;
    const status =
      module === BUSINESS_ADMIN_PTEN_VERIFY_MODULE ? filter.verified
        :
        module === BUSINESS_ADMIN_VERIFY_MODULE ? filter.approved
          : filter.status

    return {
      page: currentPage,
      limit,
      sort,
      startDate,
      endDate,
      // mccGroup: filter.mccGroup,
      merchantCriteria: filter.merchantCriteria,
      mccCategory: filter.mccCategory,
      mcc,
      search,
      status,
      // statusHistories: filter.statusHistories,
      startDateHistories,
      endDateHistories,
      connectedPartner: filter.connectedPartner,
      activeStartDate,
      activeEndDate
    }
  }, [currentPage, limit, sort, filter, module]);

  // useEffect(() => {
  //   if (!Boolean(filter.statusHistories)) {
  //     dispatch({
  //       module: module,
  //       type: FILTER_TABLE,
  //       key: "startDateHistories",
  //       value: null
  //     });
  //     dispatch({
  //       module: module,
  //       type: FILTER_TABLE,
  //       key: "endDateHistories",
  //       value: null
  //     });
  //   }
  // }, [filter.statusHistories, dispatch, module])

  return (
    <Resource
      title={title}
      subTitle={subTitle}
      icon="pe-7s-portfolio"
      list={{
        // search: isBusinessList,
        search: true,
        columns: columns,
        reducerPath: reducerPath,
        module: module,
        call: getBusinessDebounced,
        apiQuery,
        excel: isBusinessList && {
          role: role,
          columns: excelColumns,
          filename: `Merchant_List_${format(
            new Date(),
            "yyyy-MM-dd"
          )}${formatFile}`,
          apiResponseKey: 'data.businesses',
        },
        customExportExcel: isBusinessList && [
          {
            allData: true,
            columns: excelColumnsForPTEN,
            filename: `QRIS_NMR_${format(
              new Date(),
              "yyyy-MM-dd"
            )}${formatFile}`,
            buttonExport: "Generate excel for PTEN",
            customTable: ExcelTablePTEN,
            api: {
              queryParams: {
                ...apiQuery,
                action: 'pten'
              },
            }
          },
        ],
        filters: isBusinessList && [
          {
            label: "Filter Status",
            type: SELECT_FIELD,
            key: "status",
            options: statusOpts,
            value: filter.status
          },
          {
            label: "Start Date",
            type: DATE_PICKER,
            key: "startDate",
            value: filter.startDate,
            placeholder: "Select date"
          },
          {
            label: "End Date",
            type: DATE_PICKER,
            key: "endDate",
            value: filter.endDate,
            placeholder: "Select date"
          },
          {
            type: MCC_SELECT,
            key: "MCC",
            value: filter.MCC,
          },
          // {
          //   label: "Filter Merchant Group",
          //   type: SELECT_FIELD,
          //   key: "mccGroup",
          //   options: [...mccCategoryTypeOpts, ...merchantCriteriaTypeOpts],
          //   value: filter.mccGroup
          // },
          // {
          //   label: "Filter Status Histories",
          //   type: SELECT_FIELD,
          //   key: "statusHistories",
          //   options: HistoryStatusOpts,
          //   value: filter.statusHistories
          // },
          // {
          //   label: "Start Date Histories",
          //   type: DATE_PICKER,
          //   key: "startDateHistories",
          //   value: filter.startDateHistories,
          //   placeholder: "Select date",
          //   disabled: !Boolean(filter.statusHistories)
          // },
          // {
          //   label: "End Date Histories",
          //   type: DATE_PICKER,
          //   key: "endDateHistories",
          //   value: filter.endDateHistories,
          //   placeholder: "Select date",
          //   disabled: !Boolean(filter.statusHistories)
          // },
          {
            label: "Filter Connected Partner",
            type: PARTNER_SELECT,
            key: "connectedPartner",
            value: filter.connectedPartner || "",
            getOptionValue: v => v.optData.companyCode,
            filterIntegrationType: "COMPANY",
            filterByCompanyCode: true
          },
          {
            label: "Filter Merchant Criteria",
            type: SELECT_FIELD,
            key: "merchantCriteria",
            options: merchantCriteriaTypeOpts,
            value: filter.merchantCriteria
          },
          {
            label: "Filter MCC Category",
            type: SELECT_FIELD,
            key: "mccCategory",
            options: mccCategoryTypeOpts,
            value: filter.mccCategory
          },
          {
            label: "Active Start Date",
            type: DATE_PICKER,
            key: "activeStartDate",
            value: filter.activeStartDate,
            placeholder: "Select date"
          },
          {
            label: "Active End Date",
            type: DATE_PICKER,
            key: "activeEndDate",
            value: filter.activeEndDate,
            placeholder: "Select date"
          },
        ]
      }}
      detail={{
        component: Detail,
        props: {
          module: module,
          reducerPath: reducerPath
        }
      }}
    />
  );
}

export default BusinessList;
