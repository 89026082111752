import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CountUp from 'react-countup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import { beginning } from '../../redux/reducers/dashboard';
import { SET_FROM_DATE_FILTER, SET_TO_DATE_FILTER } from '../../utils/constants/actionTypes';
import RemoveIcon from '@material-ui/icons/Remove';
import { toIDR } from '../../utils/helpers/currency';
import { isEqual, format, endOfDay, startOfDay } from 'date-fns';

const useStyles = makeStyles(theme => ({
  datePickerBtn: {
    fontFamily: 'inherit',
    fontWeight: '400',
    opacity: '.6',
    transition: [
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    ].join(','),
    '&:hover': {
      opacity: '1',
    },
    '&:active': {
      opacity: '1',
    },
    '&:focus': {
      opacity: '1',
    },
  },
}));
const countUpDuration = 3;

const Widget = (
  {
    title,
    value,
    call,
    fromDate,
    toDate,
    onChange,
    type = 'primary',
    buttonTitle,
    onToggleButton,
    showCash,
  }
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isFromDateOpen, setIsFromDateOpen] = useState(false);
  const [isToDateOpen, setIsToDateOpen] = useState(false);
  const [dispatchParams] = useState(call);

  const handleFromDateChange = v => {
    if (typeof onChange === 'function') onChange(SET_FROM_DATE_FILTER, v, 'fromDate')
  }

  const handleToDateChange = v => {
    if (typeof onChange === 'function') onChange(SET_TO_DATE_FILTER, v, 'toDate')
  }

  useEffect(() => {
    if (dispatchParams) dispatch({ ...dispatchParams, args: [startOfDay(new Date(fromDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"), endOfDay(new Date(toDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")] })
  }, [fromDate, toDate, dispatch, dispatchParams]);

  return (
    <div className="widget-chart">
      {/* <div className="icon-wrapper rounded-circle">
        <div className={`icon-wrapper-bg bg-${color}`}></div>
        <i className={`${iconClass} text-${color}`}></i>
      </div> */}
      <div className="widget-numbers">
        {showCash ? 'Rp ' : ''}
        <CountUp
          className='d-flex justify-content-center'
          end={value}
          duration={countUpDuration}
          formattingFn={(v) => toIDR(v)}
        />
      </div>
      <div className="widget-subheading">{title}</div>
      <div className="mb-n2 d-flex justify-content-center">
        {
          type === 'primary' ?
            (
              <>
                <div>
                  <Button
                    size="small"
                    className={classes.datePickerBtn}
                    onClick={() => setIsFromDateOpen(true)}
                  >
                    {isEqual(new Date(fromDate), new Date(beginning)) ? 'Beginning' : format(new Date(fromDate), 'd MMM yyyy')}
                  </Button>
                  <DatePicker
                    className="d-none"
                    variant="dialog"
                    format="dd-MM-yyyy"
                    open={isFromDateOpen}
                    onOpen={() => setIsFromDateOpen(true)}
                    onClose={() => setIsFromDateOpen(false)}
                    value={fromDate}
                    onChange={handleFromDateChange}
                    clearable
                    autoOk
                  />
                </div>
                <div className="px-1 opacity-6 d-flex align-self-center">
                  <RemoveIcon style={{ fontSize: '10px' }} />
                </div>
                <div>
                  <Button
                    size="small"
                    className={classes.datePickerBtn}
                    onClick={() => setIsToDateOpen(true)}
                  >
                    {format(new Date(toDate), 'd MMM yyyy')}
                  </Button>
                  <DatePicker
                    className="d-none"
                    variant="dialog"
                    format="dd-MM-yyyy"
                    open={isToDateOpen}
                    onOpen={() => setIsToDateOpen(true)}
                    onClose={() => setIsToDateOpen(false)}
                    value={toDate}
                    onChange={handleToDateChange}
                    clearable
                    autoOk
                  />
                </div>
              </>
            )
            :
            buttonTitle && (
              <div>
                <Button
                  size="small"
                  className={classes.datePickerBtn}
                  onClick={onToggleButton}
                >
                  {buttonTitle}
                </Button>
              </div>
            )
        }
      </div>
      {/* <LoadingBlocker in={inProgress} /> */}
    </div>
  )
}

export default Widget
