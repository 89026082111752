import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useMountedState from '../HooksUse/useMountedState';
import SelectField from '../Forms/SelectField';
import { dataListApi } from '../../services/dataListApi';

const generateOpts = data => data.map(row => ({
  value: row.mcc,
  label: `(${row.mcc}) - ${row.edited_description || row.combined_description}`
}))

const MCCSelect = ({
  label,
  row,
  error,
  helperText,
  value,
  onChange,
  disabled,
  customOptions = []
}) => {
  const dispatch = useDispatch()
  const [customOpts] = useState(customOptions)
  const isMounted = useMountedState()
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleSelectChange = opt => {
    setSelectedOption(opt)

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setOptions([])
      setIsLoading(true)

      dispatch({
        module: null,
        type: null,
        call: dataListApi.merchantCategory,
      })
        .then(({ data: { MCC } }) => {
          if (isMounted()) {
            const dataOpts = generateOpts(MCC);
            const newOpts = [...customOpts, ...dataOpts];

            setOptions(newOpts);
          }
        })
        .finally(() => {
          if (isMounted()) setIsLoading(false)
        });
    }
    loadData()
  }, [isMounted, dispatch, customOpts])

  return (
    <SelectField
      row={row}
      label={label || "Pilih MCC"}
      id="selectMCC"
      options={options}
      onChange={opt => handleSelectChange(opt)}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder="Pilih MCC.."
      componentProps={{
        isClearable: true,
        isLoading: isLoading,
      }}
      error={error}
      helperText={helperText}
      isDisabled={disabled}
    />
  )
}

export default MCCSelect;
