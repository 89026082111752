import React, { useMemo } from 'react';
import Resource from '../../../components/Resource';
import { useSelector } from 'react-redux';
import UpdateTab from './UpdateTab';
import CreateTab from './CreateTab';
import { format } from 'date-fns';
import { SELECT_FIELD } from '../../../utils/constants/inputTypes';
import objectPath from 'object-path';
import { announcementApi } from '../../../services/announcementApi';
import { PpobTypeOpts } from '../../../utils/constants/enums/ppobTypes';
import { USER_ANNOUNCEMENT_APP_MODULE } from '../../../utils/constants/actionTypes';

const AnnouncementList = ({
  reducerPath,
  module,
  title,
  subTitle,
  filterOptions
}) => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => objectPath.get(state, reducerPath));

  const columns = [
    { title: 'Name', key: 'name', name: 'name', render: v => v },
    { title: 'Type', key: 'type', name: 'type', render: v => v },
    { title: 'Active', key: 'isActive', name: 'isActive', render: v => v ? 'Yes' : 'No' },
    { title: 'Closeable', key: 'isCloseable', name: 'isCloseable', render: v => v ? 'Yes' : 'No' },
    { title: 'Position', key: 'position', name: 'position' },
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
        return format(new Date(v), 'yyyy-MM-dd')
      }
    }
  ]

  const apiQuery = useMemo(() => {
    const type = filter.type;
    const application = module === USER_ANNOUNCEMENT_APP_MODULE ? "USER" : "BUSINESS"

    return {
      page: currentPage,
      limit,
      sort,
      type,
      application
    }
  }, [currentPage, limit, sort, filter, module])

  return (
    <Resource
      title={title}
      subTitle={subTitle}
      icon="pe-7s-news-paper"
      list={{
        columns: columns,
        reducerPath: reducerPath,
        module: module,
        call: announcementApi.list,
        apiQuery: apiQuery,
        deleteButton: {
          api: announcementApi.delete
        },
        filters: module === USER_ANNOUNCEMENT_APP_MODULE ? [
          {
            label: "Filter Type",
            type: SELECT_FIELD,
            key: "type",
            options: [
              {
                value: "HOME_INFO",
                label: "HOME_INFO"
              },
              {
                value: "PPOB_EWALLET_DANA",
                label: "PPOB_EWALLET_DANA"
              },
              {
                value: "PPOB_EWALLET_GOPAY",
                label: "PPOB_EWALLET_GOPAY"
              },
              {
                value: "PPOB_EWALLET_OVO",
                label: "PPOB_EWALLET_OVO"
              },
              {
                value: "PPOB_EWALLET_SHOPEE",
                label: "PPOB_EWALLET_SHOPEE"
              },
              ...PpobTypeOpts
            ],
            value: filter.type
          }
        ] : null
      }}
      update={{
        component: UpdateTab,
        props: {
          reducerPath,
          filterOptions,
          module
        }
      }}
      create={{
        component: CreateTab,
        props: {
          filterOptions,
          module
        }
      }}
    />
  );
}

export default AnnouncementList;
