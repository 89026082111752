import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { USER_MODULE, GET_DETAIL } from '../../../../utils/constants/actionTypes';
import UnlockCard from './UnlockCard';
import SuspendCard from './SuspendCard';
import BiodataCard from './BiodataCard';
import WalletCard from './WalletCard';
import IdentityCard from './IdentityCard';
import StatusCard from './StatusCard';
import DeviceCard from './DeviceCard';
import VACard from './VACard';
import FailedAttemptCard from './FailedAttemptCard';
import useAsync from '../../../../components/HooksUse/useAsync';
import HistoryChanges from '../../../../components/templates/HistoryDialog/HistoryChanges'
import HistoryTransaction from './HistoryTransaction';
import { Tab, Tabs, TabHeader } from '../../../../components/TabsLandscape/TabsLandscape';
import AllStatus from '../../../../utils/constants/enums/status';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import ConnectedPartner from './ConnectedPartner';
import { userApi } from '../../../../services/userApi';
import Statistik from './Statistik';
import ClosedAccountCard from './ClosedAccountCard';
import UserStatusHistories from '../../../../components/templates/HistoryDialog/UserStatusHistories';

const Detail = ({ index }) => {
  const [showChanges, setShowChanges] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showStatusHistories, setShowStatusHistories] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [isPercentageNull, setIsPercentageNull] = useState(false)

  const dispatch = useDispatch();
  const { userList: { key, loading }, admin } = useSelector(state => ({ userList: state.users.list.tabs[index], admin: state.common.currentAdmin }));

  // Get User Detail
  const {
    execute: getDetail,
    value: { data: { user: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: USER_MODULE,
      type: GET_DETAIL,
      call: userApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const onSuccess = () => {
    getDetail();
  };

  const forceKYC = (isForce) => {
    dispatch({
      type: null,
      call: userApi.updateKYCProgress,
      args: [
        key,
        {
          kycProgress: {
            isForce,
            forcedAt: isForce ? new Date() : null
          }
        }
      ]
    })
      .then(() => {
        getDetail()
        getStatusHistories(key)
      })
      .catch(() => { })
  }


  // Get Transaction Percentage
  const {
    value
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: userApi.transactionPercentage,
      args: [key]
    }),
    [dispatch, key]), true)

  const trxData = useMemo(() => {
    if (value) {
      let chartData = value.data;
      return [
        chartData.payQris,
        chartData.ppob,
        chartData.trxIn,
        chartData.trxOut,
      ]
    }

    return null
  }, [value]);

  useEffect(() => {
    if (trxData) {
      const empty = !trxData.some(v => v > 0);
      setIsPercentageNull(empty)
    }
  }, [trxData]);

  const {
    execute: getStatusHistories,
    value: { data } = {}
  } = useAsync(userApi.statusHistories, key);

  return loading && !item ?
    null
    :
    item ?
      <div>
        <div className="row">
          <UnlockCard
            admin={admin}
            item={item}
            onSuccess={onSuccess}
          />
          <SuspendCard
            admin={admin}
            item={item}
            onSuccess={() => {
              getStatusHistories(key)
              onSuccess()
            }}
          />
          <ClosedAccountCard
            admin={admin}
            item={item}
            onSuccess={onSuccess}
          />
        </div>
        {!item?.status?.upgraded ?
          !item?.kycProgress?.isForce ?
            <AlertDialog
              buttonComponent={<button className="btn btn-primary mb-3 w-100">Force KYC</button>}
              onAgree={() => forceKYC(true)}
              title="Apakah anda yakin?"
              description="Tindakan ini tidak dapat dibatalkan"
            />
            :
            <AlertDialog
              buttonComponent={<button className="btn btn-primary mb-3 w-100">Un-Force KYC</button>}
              onAgree={() => forceKYC(false)}
              title="Apakah anda yakin?"
              description="Tindakan ini tidak dapat dibatalkan"
            />
          : null
        }
        <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowChanges(true)}>
          Show Changes History
        </button>
        <HistoryChanges
          serviceApi={userApi.updateHistoryList}
          id={item._id}
          show={showChanges}
          setShow={setShowChanges}
          historyKey='userHistory'
        />
        <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowTransaction(true)}>
          Show Last 5 Transactions History
        </button>
        <HistoryTransaction userId={item._id} show={showTransaction} setShow={setShowTransaction} />

        <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setShowStatusHistories(true)}>
          Show Status History
        </button>
        <UserStatusHistories statusHistories={data?.statusHistories?.history} show={showStatusHistories} setShow={setShowStatusHistories} />
        <div className="mb-5">
          <Tabs
            activeKey={activeKey}
            onClick={setActiveKey}
          >
            <TabHeader title="Primary" />
            <Tab title="Biodata">
              <BiodataCard
                item={item}
                onSuccess={onSuccess}
              />
            </Tab>
            <Tab title="Wallet">
              <WalletCard
                item={item}
              />
            </Tab>
            <Tab title="Status">
              <StatusCard
                item={item}
              />
            </Tab>
            <Tab title="Virtual Account">
              <VACard
                item={item}
                onSuccess={onSuccess}
              />
            </Tab>
            <Tab title="Connected Partner">
              <ConnectedPartner
                item={item}
              />
            </Tab>
            {
              item.archive.identityCard.status === AllStatus.COMPLETED &&
              <TabHeader title="KYC" />
            }
            {
              item.archive.identityCard.status === AllStatus.COMPLETED &&
              <Tab title="Identity">
                <IdentityCard
                  item={item}
                />
              </Tab>
            }
            <TabHeader title="Activity" />
            <Tab title="Device">
              <DeviceCard
                item={item}
              />
            </Tab>
            <Tab title="Failed Attempt">
              <FailedAttemptCard
                item={item}
              />
            </Tab>
            <Tab title="Statistik">
              <Statistik
                isPercentageNull={isPercentageNull}
                trxData={trxData}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail;
