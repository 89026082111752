export class RequestUpdateType {
  static KYC = 'KYC';
  static UNLOCK = 'UNLOCK';
  static OPEN = 'OPEN';
  static UNSUSPEND = 'UNSUSPEND';
  static CLOSED = 'CLOSED';

  static getStr(en) {
    switch (en) {
      case RequestUpdateType.UNLOCK:
        return 'Unlock';
      case RequestUpdateType.OPEN:
        return 'Open';
      case RequestUpdateType.UNSUSPEND:
        return 'Unsuspend';
      case RequestUpdateType.KYC:
        return 'KYC';
      case RequestUpdateType.CLOSED:
        return 'Closed';
      default:
        return 'Unknown';
    }
  }
}

export const RequestUpdateTypeOpts = [
  { value: RequestUpdateType.KYC, label: RequestUpdateType.getStr(RequestUpdateType.KYC) },
  { value: RequestUpdateType.UNLOCK, label: RequestUpdateType.getStr(RequestUpdateType.UNLOCK) },
  { value: RequestUpdateType.OPEN, label: RequestUpdateType.getStr(RequestUpdateType.OPEN) },
  { value: RequestUpdateType.UNSUSPEND, label: RequestUpdateType.getStr(RequestUpdateType.UNSUSPEND) },
  { value: RequestUpdateType.CLOSED, label: RequestUpdateType.getStr(RequestUpdateType.CLOSED) },
];
