import React from 'react'
import AllStatus from '../../../../utils/constants/enums/status';
import EvyTextField from '../../../../components/Forms/EvyTextField'

const StatusCard = ({ item }) => {
  const failedAttempts = item?.failedAttempts?.length ? [...item.failedAttempts] : [];
  const lastSuspendAttempt = failedAttempts.reverse().find(att => att.name === AllStatus.SUSPENDED);

  return (
    <div>
      <EvyTextField
        readOnly
        row
        label="Upgraded"
        value={item.status.upgraded ? 'Yes' : 'No'}
      />
      <EvyTextField
        readOnly
        row
        label="Locked"
        value={item.status.locked ? 'Yes' : 'No'}
      />
      <EvyTextField
        readOnly
        row
        label="Suspended"
        value={item.status.suspended ? 'Yes' : 'No'}
        helperText={item.status.suspended && lastSuspendAttempt ? `Remarks: ${lastSuspendAttempt.description ?? 'No remarks'}` : ''}
      />
      <EvyTextField
        readOnly
        row
        label="Pin Set"
        value={item.status.isPinSet ? 'Yes' : 'No'}
      />
      <EvyTextField
        readOnly
        row
        label="Password Set"
        value={item.status.isPasswordSet ? 'Yes' : 'No'}
      />
      <EvyTextField
        readOnly
        row
        label="Closed"
        value={item.status?.closed ? 'Yes' : 'No'}
      />
    </div>
  )
}

export default StatusCard
