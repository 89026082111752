import React from 'react'

export default function StickyScrollbar({
  tableWidth,
  divRef
} = {}) {
  const scrollStickyScrollbar = (Xoffset) => {
    document.getElementById('target-table').scrollLeft = Xoffset;
  }

  const handleStickyScrollbar = (e) => {
    if (e.target.scrollLeft > 0) return scrollStickyScrollbar(e.target.scrollLeft)
    return scrollStickyScrollbar(-e.target.scrollLeftMax)
  }

  return (
    <div
      onScroll={(e) => handleStickyScrollbar(e)}
      className="table-responsive"
      style={{
        position: 'sticky',
        bottom: 0,
        display: `${divRef?.clientWidth < divRef?.scrollWidth || divRef?.clientHeight < divRef?.scrollHeight ? 'block' : 'none'}`
      }}
    >
      <div className="table-wrapper" style={{ width: `${tableWidth}px`, height: '0.1px' }} />
    </div>
  )
}
