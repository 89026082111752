import React, { useMemo } from 'react';
import Resource from '../../../../components/Resource';
import { endOfDay, format, startOfDay } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import { KYC_UPGRADES_MODULE } from '../../../../utils/constants/actionTypes';
import { useSelector } from 'react-redux';
import { userApi } from '../../../../services/userApi';
import DetailTab from './DetailTab';
import CreateTab from './CreateTab';
import { DATE_PICKER, PARTNER_SELECT, SELECT_FIELD } from '../../../../utils/constants/inputTypes';
import { partnerTypes } from '../../../../utils/constants/enums/partnerTypes';
import styles from './list.module.scss'

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter
  } = useSelector(state => state.kyc.upgrades);

  const columns = [
    {
      title: 'Created At', key: 'archive', name: 'createdAt', render: function (v, row) {
        const d = v?.identityCard?.requestedAt ?? null;
        return d ? format(new Date(d), 'yyyy-MM-dd HH:mm:ss') : "-";
      }
    },
    {
      title: 'Updated At', key: 'updatedAt', name: 'updatedAt', sort, render: function (v) {
        return v ? format(new Date(v), 'yyyy-MM-dd HH:mm:ss') : "-";
      }
    },
    {
      title: 'User', key: 'firstName', name: 'user_name', render: function (v, row) {
        return combineBy([row.firstName, row.lastName]);
      }
    },
    { title: 'Email', key: 'email', name: 'email' },
    { title: 'Phone Number', key: 'phoneNumber', name: 'email' },
    {
      title: 'Connected Partner', key: 'connectedPartner', name: 'connectedPartner', render: (v) => (
        <div>
          {v?.length ? v.map((partner, index) => (
            <div key={index} className="d-flex align-items-center my-1">
              <span className='mr-1'>{partnerTypes.getStr(partner.companyCode)}</span>
              {partner?.privateUser ?
                <div className={styles.badge}>
                  <span>Private</span>
                </div>
                : null
              }
            </div>
          )) : '-'}
        </div>
      )
    },
  ]

  const apiQuery = useMemo(() => {
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      status: filter.status,
      startDate,
      endDate,
      connectedPartner: filter.connectedPartner,
      conPartnerType: filter.conPartnerType,
    }
  }, [filter, currentPage, limit, sort])

  return (
    <Resource
      title="KYC Upgrade List"
      subTitle="Know Your Customer Upgrade List. Approve and Change User data based on Identity Card / Data."
      icon="pe-7s-id"
      list={{
        columns: columns,
        reducerPath: "kyc.upgrades",
        module: KYC_UPGRADES_MODULE,
        call: userApi.kycList,
        apiQuery: apiQuery,
        filters: [
          {
            label: "Start Date",
            type: DATE_PICKER,
            key: "startDate",
            value: filter.startDate,
            placeholder: "Select date"
          },
          {
            label: "End Date",
            type: DATE_PICKER,
            key: "endDate",
            value: filter.endDate,
            placeholder: "Select date"
          },
          {
            label: "Filter Connected Partner",
            type: PARTNER_SELECT,
            key: "connectedPartner",
            value: filter.connectedPartner || "",
            getOptionValue: v => v.optData.companyCode,
            filterIntegrationType: "COMPANY",
            filterByCompanyCode: true
          },
          {
            label: "Filter Tipe Connected Partner",
            type: SELECT_FIELD,
            key: "conPartnerType",
            options: [
              {
                value: "PRIVATE_USER",
                label: "Private"
              },
              {
                value: "NON_PRIVATE_USER",
                label: "Non Private"
              }
            ],
            value: filter.conPartnerType
          },
        ]
      }}
      create={{
        component: CreateTab
      }}
      detail={{
        component: DetailTab
      }}
    />
  );
}

export default Index;
