import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EvyTextField from '../../../components/Forms/EvyTextField'
import { format } from 'date-fns';
import StatusHistory from '../../../components/StatusHistory';
import { TRANSACTIONS_REFUNDS_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import { combineBy } from '../../../utils/helpers/combineBy';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import useAsync from '../../../components/HooksUse/useAsync';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import useMountedState from '../../../components/HooksUse/useMountedState';
import AllStatus from '../../../utils/constants/enums/status';
import { paymentRefundApi } from '../../../services/paymentRefundApi';
import { AdminRole } from '../../../utils/constants/enums/adminRoles';

const Detail = ({ index }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.transactions.refunds.tabs[index]);
  const isMounted = useMountedState();

  const {
    execute: getDetail,
    value: { data: { paymentRefund: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: TRANSACTIONS_REFUNDS_MODULE,
      type: GET_DETAIL,
      call: paymentRefundApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const processHistory = item ? item.statusHistories.find(history => history.status === AllStatus.PROCESSING) : null;
  const approveHistory = item ? item.statusHistories.find(history => history.status === AllStatus.APPROVED) : null;
  const declineHistory = item ? item.statusHistories.find(history => history.status === AllStatus.DECLINED) : null;
  const actor = processHistory ? processHistory.actor : null;

  const getClass = (status) => {
    let className = 'badge-primary';
    if (status === AllStatus.PROCESSING) {
      className = 'badge-warning'
    } else if (status === AllStatus.APPROVED) {
      className = 'badge-success'
    } else if (status === AllStatus.DECLINED) {
      className = 'badge-danger'
    }
    return className
  }

  const approveDeclineAsync = useAsync((type, id, remarks) => {
    return dispatch({
      type: null,
      call: type === 'approve' ? paymentRefundApi.approve : paymentRefundApi.decline,
      args: [id, remarks]
    });
  });

  const handleAction = key => (id, remarks) => {
    approveDeclineAsync.execute(key, item?._id, remarks)
      .then(() => {
        if (isMounted()) getDetail();
      })
  }

  return loading ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Requester | Admin</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Full Name"
                  value={combineBy([actor?.firstName, actor?.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={actor?.email}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Role"
                  value={AdminRole.getStr(actor?.role)}
                />
              </div>
            </div>
          </div>
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Applicant</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.applicant?.uniqueId ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="First Name"
                  value={item.applicant?.firstName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Last Name"
                  value={item.applicant?.lastName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.applicant?.phoneNumber ?? '-'}
                  helperText={`Verified at: ${item.applicant?.status?.phoneNumberVerifiedAt ? format(new Date(item.applicant.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.applicant?.email ?? '-'}
                  helperText={`Verified at: ${item.applicant?.status?.emailVerifiedAt ? format(new Date(item.applicant.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title mb-3">Request refund</h5>
              <div className="form-group">
                <label className="text-grey mb-1">Request Date</label>
                <p className="mb-0 font-weight-semibold">{format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
              </div>

              {
                item.payment?.metadata?.biller &&
                <div className="form-group">
                  <label className="text-grey mb-1">Biller</label>
                  <p className="mb-0 font-weight-semibold">{item.payment.metadata.biller}</p>
                </div>
              }

              <div className="form-group">
                <label className="text-grey mb-1">Transaction Code</label>
                <p className="mb-0 font-weight-semibold">{item?.payment?.code ?? '-'}</p>
              </div>
              <div className="form-group">
                <label className="text-grey mb-1">Amount</label>
                <p className="mb-0 font-weight-semibold">{item?.payment?.amount ?? '-'}</p>
              </div>
              <div className="form-group">
                <label className="text-grey mb-1">Remark</label>
                <p className="mb-0 font-weight-semibold">{item.remark ? item.remark : '-'}</p>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Current Status</label>
                </div>
                <div className="col-8 text-right">
                  <span className={`badge ${getClass(item.status)}`}>{item.status}</span>
                </div>
              </div>
              {item.status === AllStatus.PROCESSING ?
                <div className="d-flex justify-content-center">
                  <DeclineDialog
                    className="btn btn-outline-danger mr-2"
                    label="Reject"
                    desc="Please fill reject reason clearly"
                    fullWidth={true}
                    maxWidth="sm"
                    dataId={item._id}
                    onSubmit={handleAction('decline')}
                  />
                  <LoadingBtn type="button" onClick={handleAction('approve')} className="btn btn-primary">Approve</LoadingBtn>
                </div>
                :
                <>
                  <hr />
                  <h5 className="card-title mb-3">Details</h5>
                  {
                    item.status === AllStatus.APPROVED &&
                    <>
                      <div className="form-group">
                        <label className="text-grey mb-1">Approval Date</label>
                        <p className="mb-0 font-weight-semibold">{format(new Date(approveHistory.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                      </div>
                      <div className="form-group">
                        <label className="text-grey mb-1">Refund Transaction Code</label>
                        <p className="mb-0 font-weight-semibold">{item.code ?? '-'}</p>
                      </div>
                    </>
                  }
                  {
                    item.status === AllStatus.DECLINED &&
                    <>
                      <div className="form-group">
                        <label className="text-grey mb-1">Disapproval Date</label>
                        <p className="mb-0 font-weight-semibold">{format(new Date(declineHistory.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                      </div>
                      <div className="form-group">
                        <label className="text-grey mb-1">Reject Reason</label>
                        <p className="mb-0 font-weight-semibold">{item.rejectReason ? item.rejectReason : '-'}</p>
                      </div>
                    </>
                  }
                </>
              }
              <LoadingBlocker in={approveDeclineAsync.pending} />
            </div>
          </div>
          <StatusHistory className="mb-3" statusHistories={item.statusHistories} warningType={AllStatus.PROCESSING} successType={AllStatus.APPROVED} dangerType={AllStatus.DECLINED} />
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail;
