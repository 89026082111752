import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EvyTextField from '../../../components/Forms/EvyTextField'
import { format } from 'date-fns';
import { toIDR } from '../../../utils/helpers/currency';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { PpobType } from '../../../utils/constants/enums/ppobTypes';
import StatusHistory from '../../../components/StatusHistory';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { TRANSACTIONS_PPOB_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import { combineBy } from '../../../utils/helpers/combineBy';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import useAsync from '../../../components/HooksUse/useAsync';
import { toast } from 'react-toastify';
import useMountedState from '../../../components/HooksUse/useMountedState';
import AllStatus from '../../../utils/constants/enums/status';
import { paymentApi } from '../../../services/paymentApi';
import { paymentRefundApi } from '../../../services/paymentRefundApi';
import { ppobApi } from '../../../services/ppobApi';

const Detail = ({ index }) => {
  const [isLoadingManual, setIsloadingManual] = useState(false);
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.transactions.PPOB.tabs[index]);
  const isMounted = useMountedState();

  const {
    execute: getDetail,
    value: { data: { payments: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: TRANSACTIONS_PPOB_MODULE,
      type: GET_DETAIL,
      call: paymentApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const refundAsync = useAsync((id, remarks) => {
    return dispatch({
      type: null,
      call: paymentRefundApi.create,
      args: [id, remarks]
    })
      .then(() => {
        toast("Refund Requested", { type: 'success' })
        if (isMounted()) getDetail()
      })
      .catch(() => { })
  });

  const {
    execute: checkStatus,
    pending: checking
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.checkStatus,
      args: [{ id: key, model: 'payment' }]
    })
      .then(() => {
        if (isMounted()) getDetail()
      })
      .catch(() => { }),
    [dispatch, key, getDetail, isMounted]));

  const getType = (item) => PpobType.getStrIdn(item.metadata.ppob.type)

  const manualAdvice = () => {
    const id = item.metadata.ppob._id;
    const data = {
      payment: item._id,
      hashId: item.metadata.ppobResponse.manualAdviceHashID,
    };

    setIsloadingManual(true);
    dispatch({ type: null, call: ppobApi.manualAdvice, args: [id, data] })
      .then(() => {
        if (isMounted()) getDetail();
      })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsloadingManual(false);
      });
  }

  return loading ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Payment Information</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Payment ID"
                  value={item._id}
                  helperText={`Created at: ${item.createdAt ? format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Biller"
                  value={item.metadata.biller}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Transaction Code"
                  value={item.code}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Type"
                  value={getType(item)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Amount"
                  value={item.metadata.point > 0 ? toIDR(item.amount, false) : toIDR(item.amount)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Wallet"
                  value={item.metadata.primary > 0 ? 'PRIMARY' : item.metadata.point > 0 ? 'POINT' : '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Status"
                  value={AllStatus.getStr(item.status)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Remarks"
                  value={item.remarks ? item.remarks : '-'}
                />
              </div>
              {item.referralBonus?.user &&
                <>
                  <h5 className="card-title">Referral Bonus</h5>
                  <div>
                    <EvyTextField
                      readOnly
                      row
                      label="Referral"
                      value={`${combineBy([item.referralBonus?.user.firstName, item.referralBonus?.user.lastName])} | ${item.referralBonus?.user.uniqueId}`}
                      helperText={`Joined at: ${item.applicant?.referralInputAt ? format(new Date(item.applicant?.referralInputAt), 'yyyy-MM-dd HH:mm:ss') : '-'}`}
                    />
                    <EvyTextField
                      readOnly
                      row
                      label="Amount"
                      value={toIDR(item.referralBonus.amount, false)}
                    />
                  </div>
                </>
              }
            </div>
          </div>
          <StatusHistory className="mb-3" statusHistories={item.statusHistories} />
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Meta Data</h5>
              <div>
                <JSONInput
                  locale={locale}
                  placeholder={item.metadata}
                  theme="light_mitsuketa_tribute"
                  width="100%"
                  height="300px"
                  viewOnly
                  confirmGood={false}
                  style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Applicant</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.applicant?.uniqueId ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="First Name"
                  value={item.applicant?.firstName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Last Name"
                  value={item.applicant?.lastName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.applicant?.phoneNumber ?? '-'}
                  helperText={`Verified at: ${item.applicant?.status?.phoneNumberVerifiedAt ? format(new Date(item.applicant.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.applicant?.email ?? '-'}
                  helperText={`Verified at: ${item.applicant?.status?.emailVerifiedAt ? format(new Date(item.applicant.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
              </div>
            </div>
          </div>
          {
            item.status === AllStatus.PROCESSING &&
            <LoadingBtn
              className="btn btn-primary btn-wide btn-lg btn-block"
              loading={checking}
              onClick={checkStatus}
            >
              Check Status
            </LoadingBtn>
          }
          {
            item.status === AllStatus.COMPLETED &&
            <DeclineDialog
              className="btn btn-outline-primary btn-wide btn-lg btn-block"
              label="Request Refund"
              desc="Please fill below remarks clearly"
              colorType="primary"
              fullWidth={true}
              maxWidth="sm"
              dataId={item._id}
              onSubmit={refundAsync.execute}
              isLoading={refundAsync.pending}
            />
          }
          {+item.metadata.ppobResponse?.responseCode === 9983 &&
            <div className="alert alert-warning">
              <p>{item.metadata.ppobResponse.message}</p>
              <LoadingBtn type="button" disabled={isLoadingManual} className="btn btn-success btn-lg btn-block" onClick={manualAdvice}>Manual Advice</LoadingBtn>
            </div>
          }
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail;
