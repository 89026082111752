import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_UPDATE_USER_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import { combineBy } from '../../../utils/helpers/combineBy';
import EvyTextField from '../../../components/Forms/EvyTextField';
import StatusHistory from '../../../components/StatusHistory';
import { format } from 'date-fns';
import UserStatuses from '../../../components/UserStatuses';
import DeclineDialog from '../../../components/Dialog/DeclineDialog';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import useAsync from '../../../components/HooksUse/useAsync';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import { RequestUpdateType } from '../../../utils/constants/enums/requestUpdateTypes';
import IdentityImage from '../../../components/IdentityImage';
import { requestUpdateApi } from '../../../services/requestUpdateApi';
import { AdminRole } from '../../../utils/constants/enums/adminRoles';
import { rejectReasonApi } from '../../../services/rejectReasonApi';
import { rejectReasonTypes } from '../../../utils/constants/enums/rejectReasonTypes';
import { useHistory } from 'react-router-dom';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import { toIDR } from '../../../utils/helpers/currency';

const DetailTab = ({ index }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.users.requestUpdate.tabs[index]);
  const [rejecting, setRejecting] = useState(false);

  const {
    execute: getDetail,
    value: { data: { requestUpdate: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: REQUEST_UPDATE_USER_MODULE,
      type: GET_DETAIL,
      call: requestUpdateApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const approveHistory = item ? item.statusHistories.find(history => history.status === 'APPROVED') : null;
  const declineHistory = item ? item.statusHistories.find(history => history.status === 'REJECTED') : null;

  const getClass = (status) => {
    let className = 'badge-primary';
    if (status === 'PROCESSING') {
      className = 'badge-warning'
    } else if (status === 'APPROVED') {
      className = 'badge-success'
    } else if (status === 'REJECTED') {
      className = 'badge-danger'
    }
    return className
  }

  const approveDeclineAsync = useAsync((type, id, remarks) => {
    return dispatch({
      type: null,
      call: type === 'approve' ? requestUpdateApi.approve : requestUpdateApi.reject,
      args: [id, remarks]
    });
  });

  const handleAction = key => (id, remarks) => {
    setRejecting(true)
    approveDeclineAsync.execute(key, item?._id, remarks)
      .then(() => {
        getDetail();
        history.push('/users/list')
      })
      .catch(() => { })
      .finally(() => setRejecting(false))
  }

  const {
    value: { data: { reasons = [] } = {} } = {},
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: rejectReasonApi.list,
      args: [{
        page: 0,
        limit: 0,
        category: rejectReasonTypes[item?.updateType ?? "KYC"]
      }]
    }),
    [dispatch, item?.updateType]), true)

  return loading && !item ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Requester | Admin</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Full Name"
                  value={combineBy([item.applicant.firstName, item.applicant.lastName])}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.applicant.email}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Role"
                  value={AdminRole.getStr(item.applicant.role)}
                />
              </div>
            </div>
          </div>
          <StatusHistory className="mb-3" statusHistories={item.statusHistories} warningType={"PROCESSING"} successType={"APPROVED"} dangerType={"REJECTED"} />
          <div className="card mb-3">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <IdentityImage
                    label="Card Photo"
                    image={item.user.archive?.identityCard?.cardPhoto?.url}
                  />
                </div>
                <div className="col-12 col-xl-6">
                  <IdentityImage
                    label="Self Photo"
                    image={item.user.archive?.identityCard?.selfPhoto?.url}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title mb-3">Account Information</h5>
              <div className="form-group">
                <label className="text-grey mb-1">Saldo Balance</label>
                <p className="mb-0 font-weight-semibold">{toIDR(item.user.balance.primary) ?? '-'}</p>
              </div>
              <div className="form-group">
                <label className="text-grey mb-1">Reason {RequestUpdateType.getStr(item.updateType)}</label>
                <p className="mb-0 font-weight-semibold">{item.rejectReason || item.remark || '-'}</p>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title mb-3">Request Update</h5>
              <div className="form-group">
                <label className="text-grey mb-1">Request Date</label>
                <p className="mb-0 font-weight-semibold">{format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
              </div>
              <div className="form-group">
                <label className="text-grey mb-1">Update Type</label>
                <p className="mb-0 font-weight-semibold">
                  {RequestUpdateType.getStr(item.updateType)}
                </p>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-6">
                  <label className="text-grey mb-0">Current Status</label>
                </div>
                <div className="col-6 text-right">
                  <span className={`badge ${getClass(item.status)}`}>{item.status}</span>
                </div>
              </div>
              <hr />
              {
                item.updateType === 'KYC' ?
                  <div>
                    <h5 className="card-title mb-3">Update Information</h5>
                    <div className="row mb-3">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label className="text-grey mb-1">Identification Number</label>
                          <p className="mb-0 font-weight-semibold">{item.user.archive.identityCard.identificationNumber}</p>
                          <small className="text-muted">Verified Dukcapil at: {item.user.status.idVerified ? <>{format(new Date(item.user.status.idVerifiedAt), 'yyyy-MM-dd HH:mm')}<br />{`By: ${combineBy([item.user.status.idVerifier.firstName, item.user.status.idVerifier.lastName])} [${item.user.status.idVerifier.role}]`}</> : '-'}</small>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Job</label>
                          <p className="mb-0 font-weight-semibold">{item.user.job ? item.user.job : '-'}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Date of Birth</label>
                          <p className="mb-0 font-weight-semibold">{format(new Date(item.user.dateOfBirth), 'dd MMMM yyyy')}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Place of Birth</label>
                          <p className="mb-0 font-weight-semibold">{item.user.placeOfBirth ? item.user.placeOfBirth : '-'}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Religion</label>
                          <p className="mb-0 font-weight-semibold">{item.user.religion ? item.user.religion : '-'}</p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label className="text-grey mb-1">First Name</label>
                          <p className="mb-0 font-weight-semibold">{item.user.firstName}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Last Name</label>
                          <p className="mb-0 font-weight-semibold">{item.user.lastName ? item.user.lastName : '-'}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Address</label>
                          <p className="mb-0 font-weight-semibold">{item.user.address ? item.user.address : '-'}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">City</label>
                          <p className="mb-0 font-weight-semibold">{item.user.cityName ? item.user.cityName : '-'}</p>
                        </div>
                      </div>
                    </div>
                    <h5 className="card-title">Upgrade Note</h5>
                    <p>{item.user.upgradeNote ?? '-'}</p>
                  </div>
                  :
                  <div>
                    <h5 className="card-title mb-3">User Information</h5>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label className="text-grey mb-1">Unique ID</label>
                          <p className="mb-0 font-weight-semibold">{item.user.uniqueId}</p>
                        </div>

                        <div className="form-group">
                          <label className="text-grey mb-1">Full Name</label>
                          <p className="mb-0 font-weight-semibold">{combineBy([item.user.firstName, item.user.lastName])}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Phone Number</label>
                          <p className="mb-0 font-weight-semibold">{item.user.phoneNumber}</p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label className="text-grey mb-1">Statuses</label>
                          <UserStatuses user={item.user} />
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Email</label>
                          <p className="mb-0 font-weight-semibold">{item.user.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              {
                item.status === 'PROCESSING' ?
                  <div className="d-flex justify-content-center">
                    <DeclineDialog
                      isLoading={rejecting}
                      className="btn btn-outline-danger mr-2"
                      label="Reject"
                      desc="Please fill reject reason clearly"
                      fullWidth={true}
                      maxWidth="sm"
                      dataId={item._id}
                      reasons={reasons}
                      onSubmit={handleAction('decline')}
                    />
                    <AlertDialog
                      fullWidth={false}
                      title={`Apakah Anda yakin akan menyetujui permintaan ini?`}
                      onAgree={handleAction('approve')}
                      notes={
                        item.updateType === RequestUpdateType.CLOSED ?
                          <>
                            <div className="font-weight-bold mb-2">Sebelum melakukan approve perhatikan hal berikut :</div>
                            <ul className="pl-4 mb-0">
                              <li>Cek saldo apakah sudah menjadi 0</li>
                            </ul>
                          </>
                          :
                          null
                      }
                      buttonComponent={
                        <LoadingBtn type="button" className="btn btn-primary">Approve</LoadingBtn>
                      }
                    />
                  </div>
                  :
                  <>
                    <hr />
                    <h5 className="card-title mb-3">Details</h5>
                    {
                      item.status === 'APPROVED' &&
                      <div className="form-group">
                        <label className="text-grey mb-1">Approval Date</label>
                        <p className="mb-0 font-weight-semibold">{format(new Date(approveHistory.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                      </div>
                    }
                    {
                      item.status === 'REJECTED' &&
                      <>
                        <div className="form-group">
                          <label className="text-grey mb-1">Disapproval Date</label>
                          <p className="mb-0 font-weight-semibold">{format(new Date(declineHistory.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                        </div>
                        <div className="form-group">
                          <label className="text-grey mb-1">Reject Reason</label>
                          <p className="mb-0 font-weight-semibold">{item.rejectReason ? item.rejectReason : '-'}</p>
                        </div>
                      </>
                    }
                  </>
              }
              <LoadingBlocker in={approveDeclineAsync.pending} />
            </div>
          </div>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default DetailTab
