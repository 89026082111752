import React from 'react'
import { toIDR } from '../../../../../utils/helpers/currency';
import IdentityImage from '../../../../../components/IdentityImage';

const DonationCard = ({ title, item, customCover }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        {
          item.updateReason &&
          <div className="form-group">
            <label className="text-grey mb-1">Update Reason</label>
            <p className="mb-0 font-weight-semibold">{item.updateReason}</p>
          </div>
        }
        <div className="form-group">
          <label className="text-grey mb-0">Cover</label>
          <IdentityImage image={customCover ? customCover : item.cover?.url} />
        </div>
        <div className="form-group">
          <label className="text-grey mb-1">Name</label>
          <p className="mb-0 font-weight-semibold">{item.name}</p>
        </div>
        <div className="form-group">
          <label className="text-grey mb-1">Category</label>
          <p className="mb-0 font-weight-semibold">{item.category}</p>
        </div>
        <div className="form-group">
          <label className="text-grey mb-1">Description</label>
          <p className="mb-0 font-weight-semibold">{item.description}</p>
        </div>
        <div className="row align-items-center mb-1">
          <div className="col-4">
            <label className="text-grey mb-0">Minimum Donation</label>
          </div>
          <div className="col-8 text-right">
            <span className="text-grey pr-1">Rp</span>
            <span className="h5 mb-0 font-weight-bold text-muted">{toIDR(item.minimumDonation, false)}</span>
          </div>
        </div>
        <div className="row align-items-center mb-1">
          <div className="col-4">
            <label className="text-grey mb-0">Donation Target</label>
          </div>
          <div className="col-8 text-right">
            <span className="text-grey pr-1">Rp</span>
            <span className="h5 mb-0 font-weight-bold text-warning">{toIDR(item.targetFund, false)}</span>
          </div>
        </div>
        <div className="row align-items-center mb-1">
          <div className="col-4">
            <label className="text-grey mb-0">Amount</label>
          </div>
          <div className="col-8 text-right">
            <span className="text-grey pr-1">Rp</span>
            <span className="h5 mb-0 font-weight-bold text-primary">{toIDR(item.amountFund, false)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DonationCard
