import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import ReportTableTemplateV2 from './ReportTableTemplateV2';
import PopUp from '../../../../components/PopUp';
import HookTextField from '../../../../components/Forms/HookTextField';
import { transactionApi } from '../../../../services/transactionApi';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';

const TableDirectIntegration = ({ items, period, selectedType, submit }) => {
  const [showUpdateDescription, setShowUpdateDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const grandTotal = useMemo(() => {
    let amountArr = [];
    let totalPayment = 0;
    let revenueFee = 0;
    let total = 0;

    items.forEach(v => {
      totalPayment += v.totalPayment;
      revenueFee += v.revenueFee;
      total += v.total;
    });

    amountArr = [
      { value: totalPayment },
      { value: revenueFee },
      { value: total }
    ]

    return amountArr;
  }, [items]);

  const columns = useMemo(() => [
    { title: "Date", key: "date", name: "date", render: (v) => v ? `${format(new Date(v), 'yyyy-MM-dd HH:mm:ss')}` : '-' },
    { title: "Trans. No", key: "transactionNumber", name: "transactionNumber" },
    { title: "Nama User | Unique Code", key: "userInfo", name: "userInfo" },
    { title: "Produk", key: "productCode", name: "productCode" },
    { title: "Jumlah Bayar", key: "totalPayment", name: "totalPayment", withCurrency: true },
    { title: "Deskripsi", key: "description", name: "description" },
    { title: "Revenue", key: "revenueFee", name: "revenueFee", withCurrency: true },
    { title: "Total", key: "total", name: "total", withCurrency: true },
    {
      title: 'Action', key: '_id', name: 'action', className: 'action-column', sortable: false, render: (v, row) => {
        return selectedType === "INDOFUND" ? (
          <div className="d-flex">
            <button
              className="btn btn-icon btn-icon-only btn-sm btn-primary"
              onClick={() => {
                setId(v)
                setDescription(row.description)
                setShowUpdateDescription(true)
              }}
            >
              <i className="pe-7s-note btn-icon-wrapper" />
            </button>
          </div>
        ) : "-"
      }
    }
  ], [selectedType])

  const onUpdateDescription = () => {
    if (selectedType === "INDOFUND") {
      setIsLoading(true)
      transactionApi.update({ id, data: { description } })
        .then(() => {
          submit()
          setShowUpdateDescription(false)
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }

  return (
    <>
      <ReportTableTemplateV2
        title={`Direct Integration - ${selectedType}`}
        period={period}
        withIndex
        withExportExcel
        withPrintButton
        table={{
          columns: columns,
          data: items,
          grandTotal: grandTotal
        }}
      />
      <PopUp in={showUpdateDescription} onClose={() => setShowUpdateDescription(false)}>
        <div className="main-card card">
          <div className="card-body">
            <h5 className="card-title mb-4">
              Update Description
            </h5>
            <div>
              <HookTextField
                placeholder="Description"
                rows={8}
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <LoadingBtn
                loading={isLoading}
                onClick={onUpdateDescription}
                className="btn btn-primary w-100"
              >
                Save
              </LoadingBtn>
            </div>
          </div>
        </div>
      </PopUp>
    </>
  )
}

export default TableDirectIntegration
