import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import lowerCaseToSentenceCase from '../../../utils/helpers/lowerCaseToSentenceCase'
import { combineBy } from '../../../utils/helpers/combineBy'
import useAsync from '../../HooksUse/useAsync'
import PopUp from '../../PopUp'
import { HistoryCard, HistoryCardList } from './HistoryCard'

const HistoryChanges = ({ show, setShow, id, serviceApi, historyKey }) => {
  const dispatch = useDispatch()
  const {
    execute: getUpdateHistory,
    value: { data } = {},
    pending: loading,
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: serviceApi,
      args: [id]
    }),
    [dispatch, id, serviceApi]))

  useEffect(() => {
    if (show) getUpdateHistory()
  }, [show, getUpdateHistory])

  return (
    <div>
      <PopUp
        in={show}
        onClose={() => setShow(false)}
      >
        <HistoryCard
          title="Changes History"
          withFooter={<Footer onClick={() => setShow(false)} />}
          isLoading={loading}
        >
          {
            data?.[historyKey]?.history?.map((el, i) => (
              <HistoryCardList
                key={i}
                content={<Content item={el} />}
                date={format(new Date(el.createdAt), 'dd MMM HH:mm:ss')}
              />
            ))
          }
        </HistoryCard>
      </PopUp>
    </div>
  )
}

const Content = ({ item }) => {
  const getBeforeValue = (el) => {
    if (el === 'dateOfBirth') return format(new Date(item[el]?.before), 'yyyy-MM-dd');
    if ((typeof item[el]?.before === 'string' || typeof item[el]?.before === 'number') && (item[el].before !== "null" && item[el].before !== "")) return item[el]?.before;
    return 'Empty field'
  }

  const getAfterValue = (el) => {
    if (el === 'dateOfBirth') return format(new Date(item[el]?.after), 'yyyy-MM-dd');
    if ((typeof item?.[el]?.after === 'string' || typeof item?.[el]?.after === 'number') && item[el].after !== "") return item[el]?.after;
    return 'Empty field'
  }

  return (
    <div>
      {item.updatedByModel && <div className="mb-2 font-weight-bold text-muted">UPDATED BY: {item?.updatedBy?.uniqueId ? `${item?.updatedBy?.uniqueId} | ${item?.updatedBy?.firstName}` : `${item?.updatedBy?.email} | ${combineBy([item.updatedBy?.firstName, item.updatedBy?.lastName])}`} [{item?.updatedByModel}]</div>}
      {
        Object.keys(item).map((el, i) =>
          (item?.[el] && !['_id', 'createdAt', 'updatedBy', 'updatedByModel'].includes(el)) &&
          <div className="mb-2" key={el}>
            <div>
              <span className="font-weight-bold">{getBeforeValue(el)}</span>
              <span> to </span>
              <span className="font-weight-bold">{getAfterValue(el)}</span>
            </div>
            <div className="text-muted">Change: {getFieldName(el)}</div>
          </div>
        )
      }
    </div>
  )
}

const Footer = ({ onClick }) => (
  <div className="d-flex justify-content-end w-100">
    <button onClick={onClick} className="btn btn-primary">Close</button>
  </div>
)

const getFieldName = (propName) => {
  let fieldName;

  // Merchant Registration Detail
  // Merchant Owner
  if (propName === 'fullName') fieldName = 'Nama Pemilik'
  if (propName === 'identificationNumber') fieldName = 'Nomor KTP'
  if (propName === 'placeOfBirth') fieldName = 'Tempat Lahir'
  if (propName === 'dateOfBirth') fieldName = 'Tanggal Lahir'
  if (propName === 'businessAddress') fieldName = 'Alamat sesuai KTP'

  // Merchant Info
  if (propName === 'name') fieldName = 'Nama Usaha'
  if (propName === 'mCriteria') fieldName = 'MCriteria'
  if (propName === 'mcc') fieldName = 'MCC'

  // Merchant Outlet
  if (propName === 'email') fieldName = 'Email'
  if (propName === 'address') fieldName = 'Alamat Lengkap Outlet'
  if (propName === 'latitude') fieldName = 'Latitude Coordinate'
  if (propName === 'longtitude') fieldName = 'Longitude Coordinate'
  if (propName === 'province') fieldName = 'Provinsi'
  if (propName === 'cityName') fieldName = 'Kota'
  if (propName === 'subDistrict') fieldName = 'Kecamatan'
  if (propName === 'landmark') fieldName = 'Patokan Lokasi'

  return fieldName || lowerCaseToSentenceCase(propName);
}

export default HistoryChanges
