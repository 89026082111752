import { NAVLINK } from "../utils/constants/dataTypes";
import { ROUTE_CAREER } from "../utils/helpers/roleHelper";

import Career from "../pages/Career";

export const career = {
    type: NAVLINK,
    name: "Career",
    icon: "pe-7s-id",
    url: "/career",
    routeTitle: ROUTE_CAREER,
    component: Career
}
