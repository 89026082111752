class userTypes {
  static NEW_USER = 'NEW_USER';
  static EXIST_USER = 'EXIST_USER';
  static PREMIUM_USER = 'PREMIUM_USER';
  static ALL_USER = 'ALL_USER';

  static getStr(en) {
    switch (en) {
      case userTypes.NEW_USER:
        return 'New User';
      case userTypes.EXIST_USER:
        return 'Non Premium User';
      case userTypes.PREMIUM_USER:
        return 'Premium User';
      case userTypes.ALL_USER:
        return 'All User';
      default:
        return 'Unknown';
    }
  }
}

const UserTypesOpts = [
  { value: userTypes.NEW_USER, label: userTypes.getStr(userTypes.NEW_USER) },
  { value: userTypes.EXIST_USER, label: userTypes.getStr(userTypes.EXIST_USER) },
  { value: userTypes.PREMIUM_USER, label: userTypes.getStr(userTypes.PREMIUM_USER) },
  { value: userTypes.ALL_USER, label: userTypes.getStr(userTypes.ALL_USER) },
];

export { userTypes, UserTypesOpts };
