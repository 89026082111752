import { format } from 'date-fns'
import React from 'react'
import PopUp from '../../../PopUp'
import { combineBy } from '../../../../utils/helpers/combineBy';
import { StatusHistoryCard } from '../StatusHistoryCard';
import UserTimeline from '../../../VerticalTimeline/UserTimeline';

const generateActor = (value) => {
  return (
    <b className="d-block text-black-50 mt-3">
      Actor: {value?.updatedBy?.uniqueId ? `${value.updatedBy.uniqueId} | ${value.updatedBy.firstName}` : `${value?.updatedBy?.email} | ${combineBy([value.updatedBy?.firstName, value.updatedBy?.lastName])}`} [{value.updatedByModel} ]
    </b>
  )
}

const UserStatusHistories = ({
  show,
  setShow,
  statusHistories = []
}) => {
  return (
    <div>
      <PopUp
        in={show}
        onClose={() => setShow(false)}
      >
        <StatusHistoryCard
          title="Status History"
          withFooter={<Footer onClick={() => setShow(false)} />}
        >
          {statusHistories?.map((s, k) => {
            return (
              <UserTimeline.Dot
                key={k}
                title={s.status}
                date={format(new Date(s.createdAt), 'dd MMM HH:mm:ss')}
                desc={generateActor(s)}
                remarks={s.remarks}
              />
            )
          })}
        </StatusHistoryCard>
      </PopUp>
    </div>
  )
}

const Footer = ({ onClick }) => (
  <div className="d-flex justify-content-end w-100">
    <button onClick={onClick} className="btn btn-primary">Close</button>
  </div>
)

export default UserStatusHistories
