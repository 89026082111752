import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import EvyTextField from '../../../../components/Forms/EvyTextField';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import HookTextField from '../../../../components/Forms/HookTextField';
import FormField from '../../../../components/Forms/FormField';
import LoadingBtn from '../../../../components/Forms/LoadingBtn';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { toIDR } from '../../../../utils/helpers/currency';
import CloseIcon from '@material-ui/icons/Close';
import { partnerApi } from '../../../../services/partnerApi';
import SelectField from '../../../../components/Forms/SelectField';

const FeeCard = ({ item, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title mb-0">Fee</h5>
        <button
          className={`btn btn-icon btn-icon-only btn-sm btn-${isEditing ? 'danger' : 'primary'}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ?
            <><CloseIcon fontSize="small" /><span className="ml-2 text-uppercase">Cancel</span></> :
            <><i className="pe-7s-pen btn-icon-wrapper"></i><span className="ml-2 text-uppercase">Edit</span></>
          }
        </button>
      </div>
      {isEditing ?
        <Form
          item={item}
          onSuccess={onSuccess}
          setIsLoading={setIsLoading}
          setIsEditing={setIsEditing}
        />
        :
        <View
          item={item}
        />
      }
      <LoadingBlocker in={isLoading} />
    </div>
  )
}

const View = ({ item }) => (
  <>
    {
      item.integrationType === "COMPANY" &&
      <EvyTextField
        readOnly
        row
        label="Admin Fee"
        value={toIDR(item.adminFee ?? 0, false)}
        prepend="Rp"
      />
    }
    <EvyTextField
      readOnly
      row
      label="Revenue Fee"
      value={toIDR(item.revenueFee ?? 0, false)}
      prepend="Rp"
    />
    <EvyTextField
      readOnly
      row
      label="Minimum Settlement"
      value={toIDR(item.minSettlement ?? 0, false)}
      prepend="Rp"
    />
    <EvyTextField
      readOnly
      row
      label="Fee Rules"
      value={item.feeRules.type}
      prepend="Rp"
    />
    {
      item.feeRules.type !== "NONE" &&
      <>
        <FormField
          row
          label="Fee Apply Limit"
        >
          <EvyTextField
            readOnly
            value={item.feeRules.operator === "<" ? "LESS THAN" : "GREATER THAN"}
          />
          <EvyTextField
            readOnly
            value={toIDR(item.feeRules.feeApplyLimit ?? 0, false)}
            prepend="Rp"
          />
        </FormField>

        <FormField
          row
          label="Fee Type"
        >
          <RadioGroup
            row
            name="feeType"
            value={item.feeType}
          >
            <FormControlLabel disabled value="AMOUNT" control={<Radio color="primary" />} label="Amount" />
            <FormControlLabel disabled value="PERCENTAGE" control={<Radio color="primary" />} label="Percentage" />
          </RadioGroup>
        </FormField>
        <EvyTextField
          readOnly
          row
          label="Fee"
          value={item.feeType === 'PERCENTAGE' ? item.fee : toIDR(item.fee, false)}
          append={item.feeType === 'PERCENTAGE' ? '%' : null}
          prepend={item.feeType === 'AMOUNT' ? 'Rp' : null}
          className={item.feeType === 'PERCENTAGE' ? 'text-right' : null}
        />
        {
          item.feeType === 'PERCENTAGE' &&
          <EvyTextField
            readOnly
            row
            label="Maximum Settlement"
            value={toIDR(item.maxSettlement ?? 0, false)}
            prepend="Rp"
          />
        }
      </>
    }
  </>
)

const schema = yup.object().shape({
  feeType: yup.string().required('Fee Type is required'),
  minSettlement: yup.number().typeError('Input must be numbers').required('Maximum Settlement is required').min(0, 'Input must be greater than or equal to 0'),
  revenueFee: yup.number().typeError('Input must be numbers').required('Revenue Fee is required'),
  maxSettlement: yup.number().typeError('Input must be numbers')
    .when(['feeType', 'feeRulesType'], {
      is: (feeType, feeRulesType) => feeRulesType !== 'NONE' && feeType === 'PERCENTAGE',
      then: yup => yup.required('Maximum Settlement is required').min(0, 'Input must be greater than or equal to 0')
    }),
  fee: yup.number().typeError('Input must be numbers').required('Fee is required')
    .when(['feeType', 'feeRulesType'], (feeType, feeRulesType, schema) => {
      if (feeType === 'PERCENTAGE') return schema.lessThan(100, 'Input must be 0 - 100%').moreThan(0, 'Input must be 0 - 100%')
      if (feeRulesType === 'NONE') return schema.notRequired().min(0)
    }),
  operator: yup.string()
    .when('feeRulesType', {
      is: type => type === 'RULES',
      then: yup => yup.required('Operator is required')
    }),
  integrationType: yup.string().required('Integration Type is required'),
  adminFee: yup.number().typeError('Input must be numbers')
    .when('integrationType', {
      is: type => type === 'COMPANY',
      then: yup => yup.required('Revenue Fee is required')
    }),
  feeRulesType: yup.string().required('Fee Rules Type is required'),
  feeApplyLimit: yup.number().typeError('Input must be numbers')
    .when('feeRulesType', {
      is: type => type === 'RULES',
      then: yup => yup.required('Maximum Settlement is required').min(0, 'Input must be greater than or equal to 0')
    })
});

const Form = ({ item, onSuccess = () => { }, setIsLoading, setIsEditing }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      maxSettlement: item.maxSettlement ?? '',
      minSettlement: item.minSettlement ?? '',
      revenueFee: item.revenueFee ?? '',
      fee: item.fee ?? '',
      feeType: item.feeType ?? '',
      feeRulesType: item.feeRules.type ?? '',
      operator: item.feeRules.operator ?? '',
      feeApplyLimit: item.feeRules.feeApplyLimit ?? '',
      integrationType: item.integrationType ?? '',
      adminFee: item.adminFee ?? '',
    },
  });
  const { feeType, minSettlement, integrationType, feeRulesType, operator, revenueFee, maxSettlement, fee, feeApplyLimit, adminFee } = watch(['feeType', 'integrationType', 'adminFee', 'feeApplyLimit', 'minSettlement', 'revenueFee', 'feeRulesType', 'operator', 'maxSettlement', 'fee']);

  const onSubmit = (values) => {
    const data = {
      ...values,
      maxSettlement: values.maxSettlement ?? 0,
      integrationType: item.integrationType,
      name: item.name,
      field: item.field,
      address: item.address,
      picName: item.picName,
      email: item.email,
      picJobTitle: item.picJobTitle,
      phoneNumber: item.phoneNumber,
      picIdNumber: item.picIdNumber,
      partnerId: item.partnerId,
      companyCode: item.companyCode ?? null,
      feeRules: {
        type: values.feeRulesType ?? 'NONE',
        operator: values.operator ?? '<',
        feeApplyLimit: values.feeApplyLimit ?? 0
      }
    };

    if (item.integrationType === 'ZAKAT') {
      data.maal = item.zakat.maal ?? false
      data.fitrah = item.zakat.fitrah ?? false
      data.profesi = item.zakat.profesi ?? false
    }

    setIsLoading(true);
    dispatch({ type: null, call: partnerApi.update, args: [item._id, data] })
      .then(() => {
        onSuccess();
        setIsEditing(false);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      })
  };

  useEffect(() => {
    register("feeType");
    register("feeRulesType");
    register("operator");
    register("integrationType");

    return () => {
      unregister("feeType");
      unregister("feeRulesType");
      unregister("operator")
      unregister("integrationType")
    };
  }, [unregister, register])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        integrationType === "COMPANY" &&
        <HookTextField
          ref={register}
          label="Admin Fee"
          name="adminFee"
          error={errors.adminFee}
          helperText={errors.adminFee ? errors.adminFee.message : toIDR(adminFee)}
          row
          prepend="Rp"
        />
      }
      <HookTextField
        ref={register}
        label="Revenue Fee"
        name="revenueFee"
        error={errors.revenueFee}
        helperText={errors.revenueFee ? errors.revenueFee.message : toIDR(revenueFee)}
        row
        prepend="Rp"
      />
      <HookTextField
        ref={register}
        label="Minimum Settlement"
        name="minSettlement"
        error={errors.minSettlement}
        helperText={errors.minSettlement ? errors.minSettlement.message : toIDR(minSettlement)}
        row
        prepend="Rp"
      />
      <SelectField
        name="Fee Rules"
        label="Fee Rules"
        options={[
          {
            value: "NONE",
            label: "None"
          },
          {
            value: "RULES",
            label: "Rules"
          },
          {
            value: "FIXED",
            label: "Fixed"
          }
        ]}
        onChange={(v) => setValue('feeRulesType', v?.value, { shouldValidate: true })}
        value={feeRulesType}
        error={errors.feeRulesType}
        row
        helperText={errors.feeRulesType ? errors.feeRulesType.message : null}
        componentProps={{
          isClearable: true
        }}
      />
      {
        feeRulesType !== "NONE" &&
        <>
          {
            feeRulesType !== "FIXED" &&
            <FormField
              row
              label="Fee Apply Limit"
            >
              <SelectField
                name="operator"
                options={[
                  {
                    value: ">",
                    label: "Greater Than"
                  },
                  {
                    value: "<",
                    label: "Less Than"
                  }
                ]}
                onChange={(v) => setValue('operator', v?.value, { shouldValidate: true })}
                value={operator}
                error={errors.operator}
                helperText={errors.operator ? errors.operator.message : null}
                componentProps={{
                  isClearable: true
                }}
              />
              <HookTextField
                ref={register}
                name="feeApplyLimit"
                error={errors.feeApplyLimit}
                helperText={errors.feeApplyLimit ? errors.feeApplyLimit.message : toIDR(feeApplyLimit)}
                prepend="Rp"
              />
            </FormField>
          }
          <FormField
            row
            label="Fee Type"
          >
            <RadioGroup
              row
              name="feeType"
              value={feeType}
              onChange={e => setValue('feeType', e.target.value)}
            >
              <FormControlLabel value="AMOUNT" control={<Radio color="primary" />} label="Amount" />
              <FormControlLabel value="PERCENTAGE" control={<Radio color="primary" />} label="Percentage" />
            </RadioGroup>
          </FormField>
          <HookTextField
            ref={register}
            label="Fee"
            name="fee"
            error={errors.fee}
            helperText={errors.fee ? errors.fee.message : feeType === 'AMOUNT' ? toIDR(fee) : null}
            row
            append={feeType === 'PERCENTAGE' ? '%' : null}
            prepend={feeType === 'AMOUNT' ? 'Rp' : null}
            className={feeType === 'PERCENTAGE' ? 'text-right' : null}
          />
          {
            feeType === 'PERCENTAGE' &&
            <HookTextField
              ref={register}
              label="Maximum Settlement"
              name="maxSettlement"
              error={errors.maxSettlement}
              helperText={errors.maxSettlement ? errors.maxSettlement.message : toIDR(maxSettlement)}
              row
              prepend="Rp"
            />
          }
        </>
      }
      <FormField
        row
      >
        <LoadingBtn type="submit" className="btn btn-primary mr-2">Submit</LoadingBtn>
        <button onClick={() => setIsEditing(false)} className="btn btn-danger">Cancel</button>
      </FormField>
    </form>
  )
}

export default FeeCard;
