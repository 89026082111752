import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs, TabHeader } from '../../../../components/TabsLandscape/TabsLandscape';
// import OperationHours from './OperationHours'
import Contact from './Contact'
import Address from './Address'
import { toast } from 'react-toastify';
import { COMPANY_MODULE, GET_DETAIL } from '../../../../utils/constants/actionTypes';
import GeneralInfo from './GeneralInfo';
import Staff from './Staff';
import useMountedState from '../../../../components/HooksUse/useMountedState';
import { useEffect } from 'react';
import { companyApi } from '../../../../services/companyApi';

const Detail = ({ index }) => {
  const dispatch = useDispatch()
  const { key, loading } = useSelector(state => state.company.tabs[index]);
  const [activeKey, setActiveKey] = useState(1)
  const [item, setItem] = useState(null)
  const isMounted = useMountedState()

  const getDetail = useCallback(() => dispatch({
    module: COMPANY_MODULE,
    type: GET_DETAIL,
    call: companyApi.detail,
    args: [key],
    tabKey: index
  })
    .then(({ data: { company } }) => { if (isMounted()) setItem(company) })
    .catch(() => { })
    , [dispatch, key, index, isMounted])

  const onSuccess = () => {
    toast('Company Updated', { type: "success" });
    getDetail()
  }

  useEffect(() => {
    getDetail()
  }, [getDetail])

  return loading && !item ?
    null
    :
    item ?
      <div className="mb-3">
        <Tabs
          activeKey={activeKey}
          onClick={setActiveKey}
        >
          <TabHeader title="Primary" />
          <Tab
            title="General Info"
          >
            <GeneralInfo
              onSuccess={onSuccess}
              item={item}
            />
          </Tab>
          <Tab
            title="Contact"
          >
            <Contact
              onSuccess={onSuccess}
              item={item}
            />
          </Tab>
          {/* <Tab
            title="Operation Hours"
          >
            <OperationHours
              onSuccess={onSuccess}
              item={item}
            />
          </Tab> */}
          <Tab
            title="Address"
          >
            <Address
              onSuccess={onSuccess}
              item={item}
            />
          </Tab>
          <Tab
            title="Staff"
          >
            <Staff
              refresh={getDetail}
              item={item}
            />
          </Tab>
        </Tabs>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail
