import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './position.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { configApi } from '../../../../../services/configApi';
import { mobileShowPPOBTypes } from '../../../../../utils/constants/enums/mobileShowPPOBTypes';
import LoadingBtn from '../../../../../components/Forms/LoadingBtn';
import { CHANGE_TAB, CONFIG_MODULE, MAIN_TAB_LOAD } from '../../../../../utils/constants/actionTypes';
import useAsync from '../../../../../components/HooksUse/useAsync';
import { toast } from 'react-toastify';
import AppearanceList from './List';

function mergeAndReplaceDuplicates(arr1, arr2) {
  var mergedArray = [];

  for (var i = 0; i < arr1.length; i++) {
    var obj1 = arr1[i];
    var duplicateFound = false;

    for (var j = 0; j < arr2.length; j++) {
      var obj2 = arr2[j];

      if (obj1.name === obj2.name) {
        mergedArray.push(obj2);
        duplicateFound = true;
        break;
      }
    }

    if (!duplicateFound) {
      mergedArray.push({
        ...obj2,
        ...obj1
      });
    }
  }

  return mergedArray;
}

function sortByString(arr) {
  return arr.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  })
}

const PPOBHome = () => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [PPOBOrder, setPPOBOrder] = useState([]);
  const [PPOBOrderTemp, setPPOBOrderTemp] = useState([]);
  const [items, setItems] = useState([]);
  const [appearancelist, setAppearanceList] = useState([]);

  const {
    activeTabKey,
    inProgress
  } = useSelector(state => state.config);

  const load = useCallback(
    () => {
      dispatch({
        module: CONFIG_MODULE,
        type: MAIN_TAB_LOAD,
        call: configApi.list,
        args: [{
          page: 0,
          limit: 200,
          sort: 'name|asc',
          search: `name|SHOW_MOBILE_PPOB_`
        }]
      })
        .then(({ data: { configs } }) => setItems(configs))
    },
    [dispatch]
  )

  const {
    pending: loading,
    execute: loadShownList,
    value: { data } = {}
  } = useAsync(useCallback(
    () => dispatch({
      type: null,
      call: configApi.getPositions
    }),
    [dispatch]))

  const handleSort = useCallback(() => {
    let _items = [...PPOBOrder];
    const draggedItemContent = _items.splice(dragItem.current, 1)[0];

    _items.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setPPOBOrder(_items)
    setPPOBOrderTemp(_items)
  }, [PPOBOrder]);

  const onSave = () => {
    setIsSaving(true);
    const orderArr = PPOBOrder.map((v, i) => ({ index: i + 1, name: v?.name?.slice(17).includes("TELKOM") ? "TELKOM" : v?.name?.slice(17) }))
    configApi.setPosition(orderArr)
      .then(() => {
        loadShownList();
        load()
        toast.success("PPOB Positions Updated!")
      })
      .catch((err) => { })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const onRemove = (name, row) => {
    setPPOBOrder(PPOBOrder.filter((v) => v?.name !== name))
    setAppearanceList(sortByString([...appearancelist, row]))
    dispatch({
      module: CONFIG_MODULE,
      type: null,
      call: configApi.update,
      args: [{
        name,
        value: "1"
      }]
    })
      .catch(() => { })
  }

  const onAdd = (name, row) => {
    setPPOBOrderTemp(sortByString([...PPOBOrderTemp.filter((v) => v?.name !== name), { name: row.name.slice(17) }]))
    setPPOBOrder(sortByString([...PPOBOrder.filter((v) => v?.name !== name), { name: row.name }]))
    setAppearanceList(appearancelist.filter(v => v?.name !== name))
    dispatch({
      module: CONFIG_MODULE,
      type: null,
      call: configApi.update,
      args: [{
        name,
        value: "0"
      }]
    })
      .catch(() => { })
  }

  useEffect(() => {
    loadShownList();
    load();
  }, [loadShownList, load]);

  useEffect(() => {
    if (Boolean(items?.length) && Boolean(data?.length)) {
      setPPOBOrderTemp(data.map(v => ({ name: v.name })))
      setAppearanceList(items.filter(v => mobileShowPPOBTypes.getStr(v.name) !== 'Unknown' && v.value === "1"))
      setPPOBOrder(mergeAndReplaceDuplicates(items.filter(v => v.value === "0"), data)?.sort((a, b) => a.index - b.index))
    }
  }, [items, data])

  useEffect(() => {
    if (
      JSON.stringify(data?.map(v => ({ name: v.name }))) !== JSON.stringify(PPOBOrderTemp) &&
      activeTabKey === 0
    ) {
      var confirmed = window.confirm("You have unsaved changes. Do you want to leave the page without saving?");
      if (!confirmed) {
        dispatch({ module: CONFIG_MODULE, type: CHANGE_TAB, key: 1 })
        return;
      }
    }
  }, [activeTabKey, data, PPOBOrderTemp, dispatch])

  return (
    <div className={styles.position}>
      <div className="main-card mb-3 card">
        <div className="position-relative">
          <div className={styles.heading}>
            <h5 className="card-title">Set Positions</h5>
            <p>To change the order of the PPOB menu on mobile, simply drag and drop the menu cards located below, then click the Save button to save the changes.</p>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.appearancelist}>
              <AppearanceList
                onAdd={onAdd}
                data={appearancelist}
              />
            </div>
            <div className={styles.line} />
            <div className={styles.setPosition}>
              {!loading && !inProgress ?
                <div className={styles.setPositionWrapper}>
                  {PPOBOrder?.map((v, i) => {
                    return (
                      <div
                        key={i}
                        draggable
                        className={styles.ppobItem}
                        onDragStart={() => dragItem.current = i}
                        onDragEnter={() => dragOverItem.current = i}
                        onDragEnd={handleSort}
                        onDragOver={e => e.preventDefault()}
                      >
                        <div className={styles.ppobItemCard}>
                          <div>
                            <i className="pe-7s-menu btn-icon-wrapper mr-3"></i>
                            <span>{i + 1}. {mobileShowPPOBTypes.getStr(v?.name)}</span>
                          </div>
                        </div>
                        <LoadingBtn
                          title='Remove PPOB'
                          className={styles.remove}
                          onClick={() => onRemove(v?.name, v)}
                        >
                          <i className="pe-7s-trash btn-icon-wrapper"></i>
                        </LoadingBtn>
                        {/* <FontAwesomeIcon icon={faGripVertical} /> */}
                      </div>
                    )
                  })}
                </div>
                :
                <div>
                  <span>Loading...</span>
                </div>
              }
            </div>
          </div>
          <div className={styles.saveBtn}>
            <LoadingBtn
              onClick={onSave}
              className="btn btn-primary"
              loading={isSaving}
            >
              Save Changes
            </LoadingBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PPOBHome
