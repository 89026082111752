import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const donationApi = {
  list: ({ page, limit, sort, startDate, endDate, search }) => {
    let url = `/donations/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/donations/${id}/detail`),
  approve: (id, type) =>
    agent.requests.post(`/donations/${id}/approve`, { type }),
  reject: (id, type, rejectReason) =>
    agent.requests.post(`/donations/${id}/reject`, { type, rejectReason }),
  setStatus: (id, data) =>
    agent.requests.post(`/donations/${id}/set-status`, data),
}
