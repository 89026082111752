import React from 'react'
import styles from "./roundBadge.module.scss"
import Tooltip from '@material-ui/core/Tooltip';

const RoundBadge = ({
  icon: Icon,
  title,
  variant,
  active,
  content,
  count
}) => {
  return (
    <Tooltip title={title}>
      <div className={`${styles.root}${active ? ' ' + styles.active : ''}${variant ? ' ' + styles[variant] : ''}`}>
        {count && <div className={styles.count}>{count}</div>}
        <div className={styles.icon}>
          {content ? content : <Icon />}
        </div>
      </div>
    </Tooltip>
  )
}

export default RoundBadge
