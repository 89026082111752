import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { businessesApi } from "../../../../services/businessesApi";
import { promoConfigApi } from "../../../../services/promoConfigApi";
import {
  CREATE,
  CREATE_TAB_LOAD,
  FILTER_TABLE,
  LIMIT_TABLE,
  PROMO_QRIS_CONFIG_MODULE,
  SET_PAGE_TABLE,
  SORT_TABLE
} from "../../../../utils/constants/actionTypes";
import { applicablePromoTypes } from "../../../../utils/constants/enums/applicablePromoTypes";
import { merchantCriteriaType } from "../../../../utils/constants/enums/merchantCriteriaTypes";
import { promoTypes } from "../../../../utils/constants/enums/promoTypes";
import AllStatus from "../../../../utils/constants/enums/status";
import { userTypes } from "../../../../utils/constants/enums/userTypes";
import { combineBy } from "../../../../utils/helpers/combineBy";
import { toIDR } from "../../../../utils/helpers/currency";
import LoadingBtn from "../../../../components/Forms/LoadingBtn";
import PartnerSelect from "../../../../components/Select/PartnerSelect";
import Table from "../../../../components/Table/Table";
import BusinessStatuses from "../../../Businesses/BusinessList/BusinessStatuses";
import Form from "../Form";
import DetailTab from "./DetailTab";

const CreateTab = (columns) => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState('')
  const [selectedRow, setSelectedRow] = useState({});
  const [itemsPromo, setItemsPromo] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    merchants,
    total,
    filter
  } = useSelector(state => state.promo.qris);

  const handleApplyToAll = () => {
    let selectedAll = {}
    for (let i = 0; i < merchants.length; i++) {
      selectedAll = { ...selectedAll, [merchants[i]?.merchant?._id]: merchants[i]?.merchant?._id }
    }
    if (Boolean(Object.keys(selectedAll).length)) {
      setSelectedRow({ ...selectedAll })
    }
  }

  const handleReset = () => {
    setItemsPromo([])
    setIsApply(false)
    setTabs('')
  }

  const handleUpdate = (values) => {
    const data = {
      ...values
    }
    const dataPromo = {
      type: data?.type,
      subType: data?.subType,
      value: data?.value,
      minAmount: data?.minAmount,
      maxAmount: data?.maxAmount,
      status: data?.status,
      maxQuota: data?.maxQuota,
      applicableType: data?.applicableType,
      maxApplicableQuota: data?.maxQuotaApplicable ?? 0,
      userType: data?.userType,
    }
    const index = itemsPromo.findIndex(obj => obj.merchant === tabs)
    const merchantsItem = merchants.filter(item => item?.merchant?._id === tabs)[0]
    if (index !== -1) {
      itemsPromo[index] = {
        ...dataPromo,
        merchant: tabs,
        merchants: { ...merchantsItem }
      };
    }
    setItemsPromo([...itemsPromo])
    setTabs('')
  }

  const submit = (payload) => {
    return dispatch({
      module: PROMO_QRIS_CONFIG_MODULE,
      type: CREATE,
      call: promoConfigApi.createPromoQRIS,
      args: [payload]
    })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false)
        setSelectedRow({})
        onFilter('connectedPartner')('')
      })
  }

  const load = useCallback(
    () => {
      const search = filter.search ? `phoneNumber|${filter.search},name|${filter.search},email|${filter.search},cityName|${filter.search},merchant.uniqueId|${filter.search},merchant.archive.identityCard.identificationNumber|${filter.search},note|${filter.search},fullName|${filter.search}` : null;
      dispatch({
        module: PROMO_QRIS_CONFIG_MODULE,
        call: businessesApi.list,
        type: CREATE_TAB_LOAD,
        args: [{
          page: currentPage,
          limit,
          sort,
          connectedPartner: filter.connectedPartner,
          search
        }]
      });
    },
    [dispatch, currentPage, limit, sort, filter]
  )

  const handleSubmit = (values) => {
    setIsApply(true)
    if (isApply) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    const dataPromo = {
      type: values?.type,
      subType: values?.subType,
      value: values?.value,
      maxAmount: values?.maxAmount,
      minAmount: values?.minAmount,
      status: AllStatus.ACTIVE,
      maxQuota: values?.maxQuota,
      applicableType: values?.applicableType,
      maxApplicableQuota: values?.maxQuotaApplicable ?? 0,
      userType: values?.userType,
    }

    const generatePromoPayload = () => {
      let promoData = []
      let columnsData = []
      for (const key in selectedRow) {
        if (selectedRow.hasOwnProperty(key) && selectedRow[key]) {
          promoData.push({
            ...dataPromo,
            merchant: key,
          })
          columnsData.push({
            ...dataPromo,
            merchant: key,
            merchants: merchants.filter(merchat => merchat?.merchant?._id === key)[0]
          })
        }
      }
      setItemsPromo([...columnsData])
      return promoData;
    }

    const payload = {
      mainPromo: {
        description: values?.description,
        validFrom: values?.periodFrom,
        validTo: values?.periodTo,
        ...dataPromo
      },
      promoQris: itemsPromo?.length ? itemsPromo : generatePromoPayload()
    }

    if (isApply) {
      submit(payload)
    }
  }

  const handleCheckboxRow = useCallback((e, row) => {
    const _id = row?.merchant?._id;
    const generate = (prev) => {
      let obj;
      if (!prev?.[_id]) {
        obj = { ...prev, [_id]: _id }
      } else {
        obj = { ...prev }
        delete obj[_id]
      }
      return obj;
    }
    setSelectedRow(prev => (generate(prev)))
  }, [setSelectedRow]);

  const tableApply = useMemo(() => {
    return {
      inProgress: inProgress,
      data: itemsPromo,
      sort: sort,
      limit: limit,
      total: itemsPromo.length,
      currentPage: currentPage,
      columns: [
        { title: 'Merchant Name', key: 'merchants', name: 'name', render: v => v?.name + ' | ' + v?.merchant?.uniqueId },
        { title: 'Type', key: 'type', name: 'type', render: v => promoTypes.getStr(v) ?? '-' },
        { title: 'Applicable Type', key: 'applicableType', name: 'applicableType', render: v => applicablePromoTypes.getStr(v) ?? '-' },
        { title: 'User Type', key: 'userType', name: 'userType', render: v => userTypes.getStr(v) ?? '-' },
        { title: 'Value', key: 'value', name: 'value', render: (v, row) => row?.subType === "FIXED" ? toIDR(Number(v) || 0) : `${v}%` },
        { title: 'Max Amount', key: 'maxAmount', name: 'maxAmount', render: v => toIDR(Number(v) || 0) },
        { title: 'Min Amount', key: 'minAmount', name: 'minAmount', render: v => toIDR(Number(v) || 0) },
        { title: 'Max Quota', key: 'maxQuota', name: 'maxQuota' },
        { title: 'Max Quota Applicable:', key: 'maxApplicableQuota', name: 'maxApplicableQuota' },
        { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) ?? '-' },
        {
          title: 'Action', key: 'merchant', name: 'action', className: 'text-center', sortable: false, render: (v, row) => {
            return (
              <div className="td-action">
                <button
                  className="btn btn-icon btn-icon-only btn-sm btn-primary"
                  onClick={() => setTabs(v)}
                >
                  <i className="pe-7s-tools btn-icon-wrapper"> </i>
                </button>
              </div>
            )
          }
        }
      ],
    }
  }, [inProgress, itemsPromo, sort, limit, currentPage])

  const table = useMemo(() => {
    return {
      search: true,
      inProgress: inProgress,
      data: merchants,
      sort: sort,
      limit: limit,
      total: total,
      currentPage: currentPage,
      withCheckbox: true,
      checkboxItems: selectedRow,
      setCheckboxItem: setSelectedRow,
      propCheckboxRow: "merchant",
      columns: [
        {
          title: ' ',
          key: '_id',
          name: 'checkboxComponent',
          render: (v, row) => {
            return (
              <input
                type="checkbox"
                checked={Boolean(selectedRow[row?.merchant?._id])}
                className="form-check-input position-static m-0 checkbox-table"
                onChange={(e) => handleCheckboxRow(e, row)}
              />
            )
          }
        },
        { title: 'Merchant Name', key: 'name', name: 'name', render: (v, row) => v + ' | ' + row?.merchant?.uniqueId },
        { title: 'Merchant Criteria', key: 'MCriteria', name: 'MCriteria', render: (v) => v ? merchantCriteriaType.getStr(v) : "-" },
        { title: 'Phone Number', key: 'phoneNumber', name: 'phoneNumber' },
        { title: 'Email', key: 'email', name: 'email', sortable: false },
        { title: 'Owner', key: 'merchant', name: 'merchant', render: v => combineBy([v?.firstName, v?.lastName]) + ' | ' + v?.email + ' | ' + v?.archive?.identityCard?.identificationNumber },
        { title: 'Statuses', key: 'status', name: 'status', noEffectCheckbox: true, className: 'text-center', sortable: false, render: (v, row) => <BusinessStatuses status={v} item={row} /> },
      ],
    }
  }, [inProgress, merchants, sort, limit, total, currentPage, selectedRow, setSelectedRow, handleCheckboxRow])

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: PROMO_QRIS_CONFIG_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  useEffect(() => {
    load();
  }, [load]);

  const renderView = () => {
    if (tabs) {
      return (
        <DetailTab
          setTabs={setTabs}
          onSubmit={handleUpdate}
          isLoading={isLoading}
          items={itemsPromo.filter(item => item?.merchant === tabs)?.[0]}
        />
      )
    } else if (itemsPromo.length) {
      return (
        <div className="main-card mb-3 card">
          <div className="card-body">
            <h5 className="card-title">List Data to Submit</h5>
            <Table
              table={tableApply}
              customTopLeftTable={
                <>
                  <LoadingBtn
                    className="btn btn-outline-primary mr-3"
                    onClick={handleReset}
                    disabled={!Object.keys(selectedRow).length}
                  >
                    Reset
                  </LoadingBtn>
                  <LoadingBtn
                    type="submit"
                    loading={isLoading}
                    form="cashbackForm"
                    className="btn btn-primary"
                    disabled={!Boolean(Object.keys(selectedRow).length)}
                  >
                    Submit
                  </LoadingBtn>
                </>
              }
            >
            </Table>
          </div >
        </div >
      )
    } else {
      return (
        <div className="main-card mb-3 card">
          <div className="card-body">
            <h5 className="card-title">List Data Merchant</h5>
            <Table
              table={table}
              onReload={load}
              onSort={setTableConfig(SORT_TABLE)}
              onChangeShow={setTableConfig(LIMIT_TABLE)}
              onChangePage={setTableConfig(SET_PAGE_TABLE)}
              onSearch={onFilter("search")}
              onClearSearch={() => onFilter("search")("")}
              searchValue={filter?.search}
              customTopLeftTable={
                <>
                  <LoadingBtn
                    className="btn btn-outline-primary mr-3"
                    onClick={() => {
                      setSelectedRow({})
                      onFilter('connectedPartner')('')
                    }}
                    disabled={!Object.keys(selectedRow).length}
                  >
                    Reset
                  </LoadingBtn>
                  <LoadingBtn
                    className="btn btn-primary mr-3"
                    onClick={handleApplyToAll}
                  >
                    Select all
                  </LoadingBtn>
                  <LoadingBtn
                    type="submit"
                    loading={isLoading}
                    form="cashbackForm"
                    className="btn btn-primary"
                    disabled={!Boolean(Object.keys(selectedRow).length)}
                  >
                    Apply promo to selected merchant
                  </LoadingBtn>
                </>
              }
            >
              <div className="form-row">
                <div className="col-12 col-md-4 col-lg-3">
                  <PartnerSelect
                    label="Filter Connected Partner"
                    value={filter.connectedPartner || ''}
                    getOptionValue={v => v.optData.companyCode}
                    disabled={inProgress}
                    onChange={(e) => onFilter('connectedPartner')(e?.optData?.companyCode)}
                    filterIntegrationType="COMPANY"
                  />
                </div>
              </div>
            </Table>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <Helmet>
        <title>Create - Promo QRIS Config</title>
      </Helmet>
      <Form
        onSubmit={handleSubmit}
        selectedRowLength={Boolean(Object.keys(selectedRow).length)}
        isLoading={isLoading}
        isApply={isApply}
        handleReset={handleReset}
      />
      {renderView()}
    </div>
  )
}

export default CreateTab
