import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LIMIT_TABLE, MAIN_TAB_LOAD, REFERRED_MODULE, SET_PAGE_TABLE, SORT_TABLE } from '../../../utils/constants/actionTypes';
import { referralApi } from '../../../services/referralApi';
import { format } from 'date-fns';
import Table from '../../../components/Table/Table';

const ReferredList = ({
  index
}) => {
  const dispatch = useDispatch();
  const {
    tabs
  } = useSelector(state => state.referral.mainList);
  const { key } = tabs[index];

  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total
  } = useSelector(state => state.referral.referredList);

  const load = useCallback(
    () => {
      dispatch({
        module: REFERRED_MODULE,
        type: MAIN_TAB_LOAD,
        call: referralApi.referredList,
        args: [{
          id: key,
          page: currentPage,
          limit,
          sort,
          referred: "user"
        }]
      });
    },
    [dispatch, currentPage, limit, sort, key]
  )

  useEffect(() => {
    load();
  }, [load]);

  const table = useMemo(() => {
    return ({
      inProgress,
      data: items,
      sort,
      limit,
      total,
      currentPage,
      columns: [
        { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
        { title: 'Full Name', key: 'fullName', name: 'fullName' },
        { title: 'Phone Number', key: 'phoneNumber', name: 'phoneNumber' },
        { title: 'Email', key: 'email', name: 'email' }
      ]
    })
  }, [inProgress, items, sort, limit, total, currentPage])

  const setTableConfig = type => (value, key) => {
    dispatch({
      module: REFERRED_MODULE,
      type: type,
      value,
      key
    });
  }

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Referred List</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
        >
        </Table>
      </div>
    </div>
  );
}

export default ReferredList;
