import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { promiseMiddleware, localStorageMiddleware, exportExcelMiddleware } from './middlewares/middleware';
import createRootReducer from './reducers';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
const myRouterMiddleware = routerMiddleware(history);
const reducers = createRootReducer(history);

const getMiddleware = () => applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, exportExcelMiddleware);

export const store = createStore(
  reducers,
  composeWithDevTools(getMiddleware())
);
