class IntegrationTypes {
  static COMPANY = 'COMPANY';
  static COOPERATIVE = 'COOPERATIVE';

  static getStr(en) {
    switch (en) {
      case IntegrationTypes.COMPANY:
        return 'COMPANY';
      case IntegrationTypes.COOPERATIVE:
        return 'COOPERATIVE';
      default:
        return 'Unknown';
    }
  }
}

const IntegrationTypeOpts = [
  { value: IntegrationTypes.COMPANY, label: IntegrationTypes.getStr(IntegrationTypes.COMPANY) },
  { value: IntegrationTypes.COOPERATIVE, label: IntegrationTypes.getStr(IntegrationTypes.COOPERATIVE) },
];

export { IntegrationTypes, IntegrationTypeOpts };
