import React, { useMemo } from "react";
import Resource from "../../../components/Resource";
import { PARTNER_USER_MODULE } from "../../../utils/constants/actionTypes";
import { partnerUserApi } from "../../../services/partnerUserApi";
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

const Index = () => {
  const {
    currentPage,
    limit,
    sort
  } = useSelector(state => state.partner.user);

  const columns = [
    {
      title: "Created At",
      key: "createdAt",
      name: "createdAt",
      render: (v) => format(new Date(v), "yyyy-MM-dd HH:mm:ss"),
      sortable: false,
    },
    {
      title: "Fullname",
      key: "fullName",
      name: "fullName",
      sortable: false,
    },
    {
      title: "Email",
      key: "email",
      name: "email",
      sortable: false,
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      name: "phoneNumber",
      sortable: false,
    },
    {
      title: "Partner",
      key: "partnerId",
      name: "partnerId",
      sortable: false,
      render: (v) => v?.name && v?.integrationType ?
        <div>
          <div className="text-primary">{v?.name}</div>
          <div className="text-muted">{v?.integrationType}</div>
        </div>
        : '-'
      ,
    },
  ];

  const apiQuery = useMemo(() => {
    return {
      page: currentPage,
      limit: limit,
      sort: sort
    };
  }, [currentPage, limit, sort]);

  return (
    <Resource
      title="Partner User"
      subTitle="Partner User List."
      icon="pe-7s-global"
      list={{
        columns: columns,
        reducerPath: "partner.user",
        module: PARTNER_USER_MODULE,
        call: partnerUserApi.list,
        apiQuery: apiQuery,
      }}
    />
  );
};

export default Index;
