export class partnerTypes {
  static INDOFUND = 'INDOFUND';
  static PIGIJO = 'PIGIJO';
  static INKOPPOL_INDONESIA = 'INKOPPOL_INDONESIA';
  static DIPAY = 'DIPAY';
  static TOP_INDOKU = 'TOP_INDOKU';
  static VENTENY = 'VENTENY';

  static getStr(en) {
    switch (en) {
      case partnerTypes.INDOFUND:
        return 'Indofund';
      case partnerTypes.INKOPPOL_INDONESIA:
        return 'Inkoppol';
      case partnerTypes.DIPAY:
        return 'Dipay';
      case partnerTypes.PIGIJO:
        return 'Pigijo';
      case partnerTypes.TOP_INDOKU:
        return 'Topindoku';
      case partnerTypes.VENTENY:
        return 'Venteny';
      default:
        return 'Unknown';
    }
  }

  static getAllType() {
    return partnerTypesOpts.map(prop => prop.value).join(',')
  }
}

export const partnerTypesOpts = [
  { value: partnerTypes.INDOFUND, label: partnerTypes.getStr(partnerTypes.INDOFUND) },
  { value: partnerTypes.PIGIJO, label: partnerTypes.getStr(partnerTypes.PIGIJO) },
  { value: partnerTypes.INKOPPOL_INDONESIA, label: partnerTypes.getStr(partnerTypes.INKOPPOL_INDONESIA) },
  { value: partnerTypes.DIPAY, label: partnerTypes.getStr(partnerTypes.DIPAY) },
  { value: partnerTypes.TOP_INDOKU, label: partnerTypes.getStr(partnerTypes.TOP_INDOKU) },
  { value: partnerTypes.VENTENY, label: partnerTypes.getStr(partnerTypes.VENTENY) },
];
