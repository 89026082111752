export class RequestUpdateStatus {
  static PROCESSING = 'PROCESSING';
  static APPROVED = 'APPROVED';
  static REJECTED = 'REJECTED';

  static getStr(en) {
    switch (en) {
      case RequestUpdateStatus.PROCESSING:
        return 'Processing';
      case RequestUpdateStatus.APPROVED:
        return 'Approved';
      case RequestUpdateStatus.REJECTED:
        return 'Rejected';
      default:
        return 'Unknown';
    }
  }
}

export const RequestUpdateStatusOpts = [
  { value: RequestUpdateStatus.PROCESSING, label: RequestUpdateStatus.getStr(RequestUpdateStatus.PROCESSING) },
  { value: RequestUpdateStatus.APPROVED, label: RequestUpdateStatus.getStr(RequestUpdateStatus.APPROVED) },
  { value: RequestUpdateStatus.REJECTED, label: RequestUpdateStatus.getStr(RequestUpdateStatus.REJECTED) },
];
