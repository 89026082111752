class articleCategoryTypes {
  static NEWS = 'NEWS';
  static BUSINESS = 'BUSINESS';
  static EVENT = 'EVENT';

  static getStr(en) {
    switch (en) {
      case articleCategoryTypes.NEWS:
        return 'Berita';
      case articleCategoryTypes.BUSINESS:
        return 'Bisnis';
      case articleCategoryTypes.EVENT:
        return 'Event';
      default:
        return '-';
    }
  }
}

const articleCategoryOpts = [
  { value: articleCategoryTypes.NEWS, label: articleCategoryTypes.getStr(articleCategoryTypes.NEWS) },
  { value: articleCategoryTypes.BUSINESS, label: articleCategoryTypes.getStr(articleCategoryTypes.BUSINESS) },
  { value: articleCategoryTypes.EVENT, label: articleCategoryTypes.getStr(articleCategoryTypes.EVENT) }
];

export { articleCategoryTypes, articleCategoryOpts };
