import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const rejectReasonApi = {
  list: ({ page, limit, sort, category }) => {
    let url = `/reasons/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (category) {
      url += `&filter=${category}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => {
    return agent.requests.post(`/reasons/create`, data);
  },
  update: (id, data) => {
    return agent.requests.post(`/reasons/${id}/update`, data);
  },
  delete: (id) =>
    agent.requests.post(`/reasons/${id}/delete`)
}
