import { NAVLINK, NAVMENU } from "../utils/constants/dataTypes";
import {
  ROUTE_USERS_APPROVAL,
  ROUTE_USERS_CLOSED_ACCOUNT,
  ROUTE_USERS_KYC_HISTORIES,
  ROUTE_USERS_KYC_UPGRADE_REQUEST,
  ROUTE_USERS_LIST,
  ROUTE_USERS_REFERRALS
} from "../utils/helpers/roleHelper";

import UserList from '../pages/Users/List';
import Referral from '../pages/Users/Referral';
import ClosedAccount from '../pages/Users/ClosedAccount';
import RequestUpdate from '../pages/Users/RequestUpdate';
import KYCUpgrades from '../pages/Users/KnowYourCustomer/Upgrades';
import KYCHistories from '../pages/Users/KnowYourCustomer/Histories';
import { EventType } from "../utils/constants/enums/eventTypes";

export const users = {
  type: NAVMENU,
  name: 'Users',
  icon: 'pe-7s-users',
  children: [
    {
      type: NAVLINK,
      name: 'List',
      url: '/users/list',
      routeTitle: ROUTE_USERS_LIST,
      component: UserList
    },
    {
      type: NAVLINK,
      name: 'Referrals',
      url: '/users/referrals',
      routeTitle: ROUTE_USERS_REFERRALS,
      component: Referral
    },
    {
      type: NAVLINK,
      name: 'Closed account',
      url: '/users/closed-account',
      routeTitle: ROUTE_USERS_CLOSED_ACCOUNT,
      component: ClosedAccount
    },
    {
      type: NAVMENU,
      name: 'KYC',
      icon: 'pe-7s-id',
      children: [
        {
          type: NAVLINK,
          name: 'Upgrades',
          url: '/users/kyc/upgrades',
          routeTitle: ROUTE_USERS_KYC_UPGRADE_REQUEST,
          eventType: EventType.NEW_KYC,
          component: KYCUpgrades
        },
        {
          type: NAVLINK,
          name: 'Histories',
          url: '/users/kyc/histories',
          routeTitle: ROUTE_USERS_KYC_HISTORIES,
          component: KYCHistories
        }
      ]
    },
    {
      type: NAVLINK,
      name: 'Approval',
      url: '/users/approval',
      routeTitle: ROUTE_USERS_APPROVAL,
      eventType: EventType.NEW_UPDATE_REQUEST,
      component: RequestUpdate
    },
  ]
}
