import React from 'react';
import { Route } from 'react-router-dom';
import roleHelper from './utils/helpers/roleHelper';
import NotFound from './components/Error/NotFound';

function RoledRoute({ component: Component, role, routeTitle, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        roleHelper.hasAccess(role, routeTitle) ? (
          <Component {...rest} />
        ) : (
          <Route component={NotFound} {...rest} />
        )
      )}
    />
  )
}

export default RoledRoute;
