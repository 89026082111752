import React from 'react'
import EvyTextField from '../../../../components/Forms/EvyTextField'
import { format } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

const DeviceCard = ({ item: { devices } }) => {
  const sortedDevices = devices?.sort((a, b) => {
    const dateA = a?.lastLogin ? format(new Date(a.lastLogin), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const dateB = b?.lastLogin ? format(new Date(b.lastLogin), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    if (!dateA || isBefore(dateA, dateB)) {
      return 1;
    }
    if (isAfter(dateA, dateB)) {
      return -1;
    }

    return 0;
  })

  const loggedInDevice = devices.find(row => row.isLogin);

  return (
    <>
      <h5 className="card-title">Currently Logged In Device</h5>
      {loggedInDevice ?
        <>
          <EvyTextField
            readOnly
            row
            label="Name"
            value={loggedInDevice.name}
          />
          <EvyTextField
            readOnly
            row
            label="Type"
            value={loggedInDevice?.type ?? '-'}
          />
          <EvyTextField
            readOnly
            row
            label="Is Login"
            value={typeof loggedInDevice?.isLogin ? loggedInDevice.isLogin.toString() : '-'}
          />
          <EvyTextField
            readOnly
            row
            label="Last Login"
            value={loggedInDevice?.lastLogin ? format(new Date(loggedInDevice.lastLogin), "dd-MM-yyyy HH:mm:ss") : '-'}
          />
        </>
        :
        <p className="mb-0 mt-4 text-center">There is no logged in device</p>
      }
      <hr />
      {sortedDevices.map((device, key) =>
        <div key={key}>
          <h5 className="card-title">Device List #{key + 1}</h5>
          <EvyTextField
            readOnly
            row
            label="Name"
            value={device.name}
          />
          <EvyTextField
            readOnly
            row
            label="Type"
            value={device?.type ?? '-'}
          />
          <EvyTextField
            readOnly
            row
            label="Is Login"
            value={typeof device?.isLogin ? device.isLogin.toString() : '-'}
          />
          <EvyTextField
            readOnly
            row
            label="Last Login"
            value={device?.lastLogin ? format(new Date(device.lastLogin), "dd-MM-yyyy HH:mm:ss") : '-'}
          />
        </div>
      )}
    </>
  )
}

export default DeviceCard
