import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const disclaimerApi = {
  list: (page, limit, sort, startDate, endDate) => {
    let url = `/disclaimers/list?${paginate(limit, page)}`
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (startDate) {
      url += `&fromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&toDate=${encodeURIComponent(endDate)}`;
    }
    return agent.requests.get(url)
  },
  detail: (id) =>
    agent.requests.get(`/disclaimers/${id}/detail`),
  update: (data) =>
    agent.requests.post('/disclaimers/update', data),
}
