import React, { useEffect, useMemo, useCallback } from 'react'
import InputImage from '../../../components/Forms/InputImage';
import { IMAGE_FORMATS } from '../../../utils/constants/actionTypes';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import * as yup from 'yup';
import useAsync from '../../../components/HooksUse/useAsync';
import EvySelectField from '../../../components/Forms/EvySelectField';
import { useDispatch } from 'react-redux';
import { bankApi } from '../../../services/bankApi';

const schema = yup.object().shape({
  name: yup.string().required(),
  code: yup.string().min(3).required(),
  abbr: yup.string().min(3).required(),
  logo: yup.mixed().test(
    "fileFormat",
    "Unsupported Format",
    value => value ? value && IMAGE_FORMATS.includes(value.type) : true
  ),
  artajasaCode: yup.object().typeError('Artajasa code is required').required('Artajasa code is required'),
});

const FormTab = ({ submit, isLoading, defaultValues: { prevImageUrl, ...defaultValues } }) => {
  const dispatch = useDispatch();
  const { register, unregister, setValue, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });
  const artajasaCode = watch('artajasaCode');

  const getArtajasaTypes = useCallback(() => {
    return dispatch({
      type: null,
      call: bankApi.getArtajasaTypes,
    })
  }, [dispatch]);

  const { value } = useAsync(getArtajasaTypes, true);
  const options = useMemo(() => {
    return value?.data?.artajasaBank.map(row => ({
      label: `${row.artajasaCode} - ${row.name}`,
      value: row.enum
    })) ?? []
  }, [value])

  useEffect(() => {
    register('logo')
    register('artajasaCode')
    return () => {
      unregister('logo')
      unregister('artajasaCode')
    }
  }, [register, unregister])

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Name"
            name="name"
            error={errors.name}
            helperText={errors.name ? errors.name.message : null}
            placeholder="e.g: Bank Central Asia"
          />
          <HookTextField
            ref={register}
            label="Code"
            name="code"
            error={errors.code}
            helperText={errors.code ? errors.code.message : null}
            placeholder="e.g: 451"
          />
          <HookTextField
            ref={register}
            label="Abbrevation"
            name="abbr"
            error={errors.abbr}
            helperText={errors.abbr ? errors.abbr.message : null}
            placeholder="e.g: BCA"
          />
          <EvySelectField
            name="artajasaCode"
            label="Artajasa Code"
            options={options}
            onChange={(v) => setValue('artajasaCode', v, { shouldValidate: true })}
            value={artajasaCode}
            error={errors.artajasaCode}
            helperText={errors.artajasaCode ? errors.artajasaCode.message : null}
            advanceSelect
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">Logo</h5>
        </div>
        <div className="col-12 col-md-9">
          <InputImage
            previousUrl={prevImageUrl}
            name="logo"
            format={IMAGE_FORMATS.join(', ')}
            errorMessage={errors.logo ? errors.logo.message : null}
            onChange={(v) => setValue('logo', v, { shouldValidate: true })}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
        </div>
      </div>
    </form>
  )
}

export default FormTab
