import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { toIDR } from '../../../utils/helpers/currency';
import ApprovalCard from './ApprovalCard';
import { format } from 'date-fns'
import StatusHistory from '../../../components/StatusHistory';
import { SETTLEMENT_MODULE, GET_DETAIL } from '../../../utils/constants/actionTypes';
import useAsync from '../../../components/HooksUse/useAsync';
import AllStatus from '../../../utils/constants/enums/status';
import { settlementApi } from '../../../services/settlementApi';

const Detail = ({ index }) => {
  const dispatch = useDispatch();
  const { key, loading } = useSelector(state => state.settlement.tabs[index]);

  const {
    execute: getDetail,
    value: { data: { merchantSettlement: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: SETTLEMENT_MODULE,
      type: GET_DETAIL,
      call: settlementApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const onSuccess = () => {
    getDetail();
  };

  const getClass = status => {
    let className = 'badge-primary';
    if (status === AllStatus.PENDING) {
      className = 'badge-warning'
    } else if (status === AllStatus.COMPLETED) {
      className = 'badge-success'
    } else if (status === AllStatus.FAILED) {
      className = 'badge-danger'
    }
    return className
  }

  return loading && !item ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title mb-3">Merchant Info</h5>
              <div className="form-group">
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Merchant ID</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{item.applicant?.uniqueId}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Owner</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{`${item.applicant?.firstName} ${item.applicant?.lastName}`}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Merchant Name</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{item.applicant?.businesses?.name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pr-1">
                    <div className="d-flex justify-content-between">
                      <label className="text-grey mb-1">Address</label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-8 pl-0">
                    <p className="mb-0 font-weight-semibold">{item.applicant?.address}</p>
                  </div>
                </div>
              </div>
              <label>To bank</label>
              <div className="bank-accounts-list">
                <div className="bank-account-data">
                  <div className="bank-account-logo">
                    {item.bankAccount.bank ?
                      <img src={item.bankAccount.bank.logo ? item.bankAccount.bank.logo.url : ''} alt={item.bankAccount.bank.name} />
                      : 'Bank Not Found'
                    }
                  </div>
                  <div>
                    <p className="bank-account-bank">
                      {item.bankAccount.bank ?
                        item.bankAccount.bank.name
                        : 'Bank Not Found'
                      }
                    </p>
                    <p className="bank-account-name">
                      {item.bankAccount.accountName}
                    </p>
                    <p className="bank-account-number">
                      {item.bankAccount.accountNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-0" />
            <div className="card-body ">
              <h5 className="card-title mb-3">Settlement Info</h5>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Trans. Code</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{item.code}</p>
                </div>
              </div>
              <div className="row mb-2 align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Date</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{format(new Date(item.createdAt), 'dd MMMM yyyy HH:mm:ss')}</p>
                </div>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Current Status</label>
                </div>
                <div className="col-8 text-right">
                  <span className={`badge ${getClass(item.status)}`}>{item.status}</span>
                </div>
              </div>
              {item.status === AllStatus.FAILED
                ?
                <div className="row mb-2 align-items-end">
                  <div className="col-4">
                    <label className="text-grey mb-0">Remarks</label>
                  </div>
                  <div className="col-8 text-right">
                    <p className="mb-0 font-weight-semibold">{item.remarks}</p>
                  </div>
                </div>
                :
                ''
              }
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Bank Biller</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{item.aggrBank}</p>
                </div>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Settlement Amount</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{toIDR(item.total)}</p>
                </div>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">Fee</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{toIDR(item.fee)}</p>
                </div>
              </div>
              <div className="row form-group align-items-end">
                <div className="col-4">
                  <label className="text-grey mb-0">MDR</label>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0 font-weight-semibold">{toIDR(item.mdr)}</p>
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-4">
                  <label className="text-grey mb-0">Amount</label>
                </div>
                <div className="col-8 text-right">
                  <span className="text-grey pr-1">Rp</span>
                  <span className="h4 mb-0 font-weight-bold text-primary">{toIDR(item.amount, false)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          {item.status === AllStatus.PENDING ?
            <ApprovalCard selectedItem={item} onSuccess={onSuccess} />
            :
            <StatusHistory statusHistories={item.statusHistories} />
          }
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail;
