export const headColumns = [
    // Jumlah Pengguna
    { title: 'Total Jumlah Akun Pengguna yang memiliki fasilitas pembayaran QRIS', group: '1', colSpan: "3", height: "50px" },
    { title: 'Jumlah Pengguna QRIS', group: '1', rowSpan: "4", },
    { title: 'Jumlah Pengguna AKTIF QRIS', group: '1', rowSpan: "4", },
    // Jumlah Merchant QR/QRIS
    { title: 'Total Merchant QRIS', key: 'inActive', group: '2', rowSpan: "4" },
    { title: 'Total Merchant QRIS AKTIF**', key: 'total', group: '2', rowSpan: "4" },
    { title: 'Merchant QRIS Baru', key: 'new', group: '2', rowSpan: "4" },
    { title: 'Merchant QRIS Ditutup', key: 'closed', group: '2', rowSpan: "4" },
    // Jumlah Merchant QRIS Berdasarkan Kriteria Merchant
    { title: 'Jumlah Merchant Usaha Mikro (UMI)', key: 'umi', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Kecil (UKE)', key: 'uke', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Menengah (UME)', key: 'ume', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Besar (UBE)', key: 'ube', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Regular (URE) *', key: 'categoryRegular', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Public Service Obligation (PSO)', key: 'categoryEdlucation', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant di Merchant Badan Layanan Umum (BLU)', key: 'categorySPBU', group: '2', rowSpan: "4" },
    // Jumlah Merchant QRIS Berdasarkan AKTIF Kriteria Merchant **
    { title: 'Jumlah Merchant Usaha Mikro (UMI)', key: 'umi', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Kecil (UKE)', key: 'uke', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Menengah (UME)', key: 'ume', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Besar (UBE)', key: 'ube', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Regular (URE) *', key: 'categoryRegular', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Public Service Obligation (PSO)', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant di Merchant Badan Layanan Umum (BLU)', group: '2', rowSpan: "4" },
    // Jumlah Merchant QRIS Berdasarkan Kategori Merchant (MCC)
    { title: 'Jumlah Merchant MCC Reguler', group: '2', rowSpan: "4" },
    { title: 'MCC Khusus', group: '2', colSpan: "5", height: "50px" },
    // Jumlah Merchant QRIS AKTIF Berdasarkan Kategori Merchant (MCC) **
    { title: 'Jumlah Merchant MCC Reguler', group: '2', rowSpan: "4" },
    { title: 'MCC Khusus', group: '2', colSpan: "5", height: "50px" },
    // Jumlah Merchant QRIS CPM Berdasarkan Kriteria Merchant ***
    { title: 'Jumlah Merchant Usaha Mikro (UMI)', key: 'umi', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Kecil (UKE)', key: 'uke', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Menengah (UME)', key: 'ume', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Besar (UBE)', key: 'ube', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Usaha Regular (URE) *', key: 'categoryRegular', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant Public Service Obligation (PSO)', group: '2', rowSpan: "4" },
    { title: 'Jumlah Merchant di Merchant Badan Layanan Umum (BLU)', group: '2', rowSpan: "4" },
    // Jumlah Merchant QRIS CPM Berdasarkan Kategori Merchant (MCC) ***
    { title: 'Jumlah Merchant MCC Reguler', group: '2', rowSpan: "4" },
    { title: 'MCC Khusus', group: '2', colSpan: "5", height: "50px" },
    // Total Volume/Frekuensi Transaksi QRIS
    { title: 'Total Volume/Frekuensi Transaksi QRIS', key: 'transactionTotalFrekuensi', group: '3', rowSpan: "4" },
    // Total Nominal Transaksi QRIS
    { title: 'Total Nominal Transaksi QRIS', key: 'transactionTotalAmount', group: '3', rowSpan: "4" },
    { title: 'Volume/Frekuensi Transaksi as Issuer - On Us', group: '3', colSpan: "23", rowSpan: "2" },
    { title: 'Nominal Transaksi as Issuer - On Us', group: '3', colSpan: "23", rowSpan: "2" },
    { title: 'Volume/Frekuensi Transaksi as Issuer - Off Us', group: '3', colSpan: "23", rowSpan: "2" },
    { title: 'Nominal Transaksi as Issuer - Off Us', group: '3', colSpan: "23", rowSpan: "2" },
    { title: 'Volume/Frekuensi Transaksi as Acquirer - Off Us', group: '3', colSpan: "23", rowSpan: "2" },
    { title: 'Nominal Transaksi as Acquirer - Off Us', group: '3', colSpan: "23", rowSpan: "2" },
    // VALIDASI
    { title: 'check jml merchant by criteria', group: '4', rowSpan: "5", border: "none" },
    { title: 'check jml merchant by category', group: '4', rowSpan: "5", border: "none" },
    { title: 'check volume on/offus', group: '4', rowSpan: "5", border: "none" },
    { title: 'check nominal on/offus', group: '4', rowSpan: "5", border: "none" },
    { title: 'check volume criteria', group: '4', rowSpan: "5", border: "none" },
    { title: 'check nominal criteria', group: '4', rowSpan: "5", border: "none" },
    { title: 'check volume category', group: '4', rowSpan: "5", border: "none" },
    { title: 'check nominal category', group: '4', rowSpan: "5", border: "none" },
    { title: 'check Volume Metode', group: '4', rowSpan: "5", border: "none" },
    { title: 'check Nominal Metode', group: '4', rowSpan: "5", border: "none" },
    { title: 'check Volume Sumber Dana', group: '4', rowSpan: "5", border: "none" },
    { title: 'check Nominal Sumber Dana', group: '4', rowSpan: "5", border: "none" },
    { title: 'check OVERALL', group: '4', rowSpan: "5", border: "none" },
];

export const headColumns1 = [
    { title: 'UE Server Based', group: '1', height: "250px", rowSpan: "3" },
    { title: 'Mobile Banking', group: '1', height: "250px", rowSpan: "3" },
    { title: 'Lainnya', group: '1', height: "250px", rowSpan: "3" },
    // Sub Jumlah Merchant QRIS Berdasarkan Kategori Merchant (MCC)
    { title: 'Jumlah Merchant Pendidikan', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant SPBU', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant Goverment to People (G2P)', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant People to Goverment (P2G)', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant Donasi Sosial', group: '2', rowSpan: "3" },
    // Sub Jumlah Merchant QRIS AKTIF Berdasarkan Kategori Merchant (MCC) **
    { title: 'Jumlah Merchant Pendidikan', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant SPBU', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant Goverment to People (G2P)', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant People to Goverment (P2G)', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant Donasi Sosial', group: '2', rowSpan: "3" },
    // Sub Jumlah Merchant QRIS CPM Berdasarkan Kategori Merchant (MCC) ***
    { title: 'Jumlah Merchant Pendidikan', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant SPBU', group: '2', rowSpan: "3" },

    { title: 'Jumlah Merchant Goverment to People (G2P)', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant People to Goverment (P2G)', group: '2', rowSpan: "3" },
    { title: 'Jumlah Merchant Donasi Sosial', group: '2', rowSpan: "3" },
]

export const headColumns2 = [
    // Total Volume/Frekuensi Transaksi QRIS
    { title: 'Total', group: '3', height: "50px", colSpan: "2" },
    { title: 'Berdasarkan Metode', group: '3', height: "50px", colSpan: "2", bgColor: '#fff2cc' },
    { title: 'Berdasarkan Instrumen/Sumber Dana', group: '3', height: "50px", colSpan: "6", bgColor: "#fbe5d6" },
    { title: 'Berdasarkan Merchant Kriteria', group: '3', height: "50px", colSpan: "7", bgColor: "#c9c9c9" },
    { title: 'Berdasarkan Merchant Category Code (MCC)', group: '3', height: "50px", colSpan: "6", bgColor: "#d6dce5" },
    // Total Nominal Transaksi QRIS
    { title: 'Total', group: '3', height: "50px", colSpan: "2" },
    { title: 'Berdasarkan Metode', group: '3', height: "50px", colSpan: "2", bgColor: '#fff2cc' },
    { title: 'Berdasarkan Instrumen/Sumber Dana', group: '3', height: "50px", colSpan: "6", bgColor: "#fbe5d6" },
    { title: 'Berdasarkan Merchant Kriteria', group: '3', height: "50px", colSpan: "7", bgColor: "#c9c9c9" },
    { title: 'Berdasarkan Merchant Category Code (MCC)', group: '3', height: "50px", colSpan: "6", bgColor: "#d6dce5" },
    // Volume/Frekuensi Transaksi as Issuer - Off Us
    { title: 'Total', group: '3', height: "50px", colSpan: "2" },
    { title: 'Berdasarkan Metode', group: '3', height: "50px", colSpan: "2", bgColor: '#fff2cc' },
    { title: 'Berdasarkan Instrumen/Sumber Dana', group: '3', height: "50px", colSpan: "6", bgColor: "#fbe5d6" },
    { title: 'Berdasarkan Merchant Kriteria', group: '3', height: "50px", colSpan: "7", bgColor: "#c9c9c9" },
    { title: 'Berdasarkan Merchant Category Code (MCC)', group: '3', height: "50px", colSpan: "6", bgColor: "#d6dce5" },
    // Nominal Transaksi as Issuer - Off Us
    { title: 'Total', group: '3', height: "50px", colSpan: "2" },
    { title: 'Berdasarkan Metode', group: '3', height: "50px", colSpan: "2", bgColor: '#fff2cc' },
    { title: 'Berdasarkan Instrumen/Sumber Dana', group: '3', height: "50px", colSpan: "6", bgColor: "#fbe5d6" },
    { title: 'Berdasarkan Merchant Kriteria', group: '3', height: "50px", colSpan: "7", bgColor: "#c9c9c9" },
    { title: 'Berdasarkan Merchant Category Code (MCC)', group: '3', height: "50px", colSpan: "6", bgColor: "#d6dce5" },
    // Volume/Frekuensi Transaksi as Acquirer - Off Us
    { title: 'Total', group: '3', height: "50px", colSpan: "2" },
    { title: 'Berdasarkan Metode', group: '3', height: "50px", colSpan: "2", bgColor: '#fff2cc' },
    { title: 'Berdasarkan Instrumen/Sumber Dana', group: '3', height: "50px", colSpan: "6", bgColor: "#fbe5d6" },
    { title: 'Berdasarkan Merchant Kriteria', group: '3', height: "50px", colSpan: "7", bgColor: "#c9c9c9" },
    { title: 'Berdasarkan Merchant Category Code (MCC)', group: '3', height: "50px", colSpan: "6", bgColor: "#d6dce5" },
    // Nominal Transaksi as Acquirer - Off Us
    { title: 'Total', group: '3', height: "50px", colSpan: "2" },
    { title: 'Berdasarkan Metode', group: '3', height: "50px", colSpan: "2", bgColor: '#fff2cc' },
    { title: 'Berdasarkan Instrumen/Sumber Dana', group: '3', height: "50px", colSpan: "6", bgColor: "#fbe5d6" },
    { title: 'Berdasarkan Merchant Kriteria', group: '3', height: "50px", colSpan: "7", bgColor: "#c9c9c9" },
    { title: 'Berdasarkan Merchant Category Code (MCC)', group: '3', height: "50px", colSpan: "6", bgColor: "#d6dce5" },
]

export const headColumns3 = [
    // Total Volume/Frekuensi Transaksi QRIS
    { title: 'By Kriteria', group: '3' },
    { title: 'By Category', group: '3' },
    { title: 'MPM', group: '3', bgColor: '#fff2cc' },
    { title: 'CPM', group: '3', bgColor: '#fff2cc' },
    { title: 'UE', group: '3', bgColor: "#fbe5d6" },
    { title: 'Simpanan', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Debet', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Kredit', group: '3', bgColor: "#fbe5d6" },
    { title: 'Fasilitas Kredit Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'UMI', group: '3', bgColor: "#c9c9c9" },
    { title: 'UKE', group: '3', bgColor: "#c9c9c9" },
    { title: 'UME', group: '3', bgColor: "#c9c9c9" },
    { title: 'UBE', group: '3', bgColor: "#c9c9c9" },
    { title: 'URE*', group: '3', bgColor: "#c9c9c9" },
    { title: 'PSO', group: '3', bgColor: "#c9c9c9" },
    { title: 'BLU', group: '3', bgColor: "#c9c9c9" },
    { title: 'MCC Regular', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Pendidikan', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-SPBU', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-G2P', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-P2G', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Donasi Sosial', group: '3', bgColor: "#d6dce5" },
    // Total Nominal Transaksi QRIS
    { title: 'By Kriteria', group: '3' },
    { title: 'By Category', group: '3' },
    { title: 'MPM', group: '3', bgColor: '#fff2cc' },
    { title: 'CPM', group: '3', bgColor: '#fff2cc' },
    { title: 'UE', group: '3', bgColor: "#fbe5d6" },
    { title: 'Simpanan', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Debet', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Kredit', group: '3', bgColor: "#fbe5d6" },
    { title: 'Fasilitas Kredit Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'UMI', group: '3', bgColor: "#c9c9c9" },
    { title: 'UKE', group: '3', bgColor: "#c9c9c9" },
    { title: 'UME', group: '3', bgColor: "#c9c9c9" },
    { title: 'UBE', group: '3', bgColor: "#c9c9c9" },
    { title: 'URE*', group: '3', bgColor: "#c9c9c9" },
    { title: 'PSO', group: '3', bgColor: "#c9c9c9" },
    { title: 'BLU', group: '3', bgColor: "#c9c9c9" },
    { title: 'MCC Regular', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Pendidikan', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-SPBU', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-G2P', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-P2G', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Donasi Sosial', group: '3', bgColor: "#d6dce5" },
    // Volume/Frekuensi Transaksi as Issuer - Off Us
    { title: 'By Kriteria', group: '3' },
    { title: 'By Category', group: '3' },
    { title: 'MPM', group: '3', bgColor: '#fff2cc' },
    { title: 'CPM', group: '3', bgColor: '#fff2cc' },
    { title: 'UE', group: '3', bgColor: "#fbe5d6" },
    { title: 'Simpanan', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Debet', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Kredit', group: '3', bgColor: "#fbe5d6" },
    { title: 'Fasilitas Kredit Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'UMI', group: '3', bgColor: "#c9c9c9" },
    { title: 'UKE', group: '3', bgColor: "#c9c9c9" },
    { title: 'UME', group: '3', bgColor: "#c9c9c9" },
    { title: 'UBE', group: '3', bgColor: "#c9c9c9" },
    { title: 'URE*', group: '3', bgColor: "#c9c9c9" },
    { title: 'PSO', group: '3', bgColor: "#c9c9c9" },
    { title: 'BLU', group: '3', bgColor: "#c9c9c9" },
    { title: 'MCC Regular', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Pendidikan', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-SPBU', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-G2P', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-P2G', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Donasi Sosial', group: '3', bgColor: "#d6dce5" },
    // Nominal Transaksi as Issuer - Off Us
    { title: 'By Kriteria', group: '3' },
    { title: 'By Category', group: '3' },
    { title: 'MPM', group: '3', bgColor: '#fff2cc' },
    { title: 'CPM', group: '3', bgColor: '#fff2cc' },
    { title: 'UE', group: '3', bgColor: "#fbe5d6" },
    { title: 'Simpanan', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Debet', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Kredit', group: '3', bgColor: "#fbe5d6" },
    { title: 'Fasilitas Kredit Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'UMI', group: '3', bgColor: "#c9c9c9" },
    { title: 'UKE', group: '3', bgColor: "#c9c9c9" },
    { title: 'UME', group: '3', bgColor: "#c9c9c9" },
    { title: 'UBE', group: '3', bgColor: "#c9c9c9" },
    { title: 'URE*', group: '3', bgColor: "#c9c9c9" },
    { title: 'PSO', group: '3', bgColor: "#c9c9c9" },
    { title: 'BLU', group: '3', bgColor: "#c9c9c9" },
    { title: 'MCC Regular', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Pendidikan', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-SPBU', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-G2P', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-P2G', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Donasi Sosial', group: '3', bgColor: "#d6dce5" },
    // Volume/Frekuensi Transaksi as Acquirer - Off Us
    { title: 'By Kriteria', group: '3' },
    { title: 'By Category', group: '3' },
    { title: 'MPM', group: '3', bgColor: '#fff2cc' },
    { title: 'CPM', group: '3', bgColor: '#fff2cc' },
    { title: 'UE', group: '3', bgColor: "#fbe5d6" },
    { title: 'Simpanan', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Debet', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Kredit', group: '3', bgColor: "#fbe5d6" },
    { title: 'Fasilitas Kredit Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'UMI', group: '3', bgColor: "#c9c9c9" },
    { title: 'UKE', group: '3', bgColor: "#c9c9c9" },
    { title: 'UME', group: '3', bgColor: "#c9c9c9" },
    { title: 'UBE', group: '3', bgColor: "#c9c9c9" },
    { title: 'URE*', group: '3', bgColor: "#c9c9c9" },
    { title: 'PSO', group: '3', bgColor: "#c9c9c9" },
    { title: 'BLU', group: '3', bgColor: "#c9c9c9" },
    { title: 'MCC Regular', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Pendidikan', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-SPBU', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-G2P', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-P2G', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Donasi Sosial', group: '3', bgColor: "#d6dce5" },
    // Nominal Transaksi as Acquirer - Off Us
    { title: 'By Kriteria', group: '3' },
    { title: 'By Category', group: '3' },
    { title: 'MPM', group: '3', bgColor: '#fff2cc' },
    { title: 'CPM', group: '3', bgColor: '#fff2cc' },
    { title: 'UE', group: '3', bgColor: "#fbe5d6" },
    { title: 'Simpanan', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Debet', group: '3', bgColor: "#fbe5d6" },
    { title: 'Kartu Kredit', group: '3', bgColor: "#fbe5d6" },
    { title: 'Fasilitas Kredit Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'Lainnya', group: '3', bgColor: "#fbe5d6" },
    { title: 'UMI', group: '3', bgColor: "#c9c9c9" },
    { title: 'UKE', group: '3', bgColor: "#c9c9c9" },
    { title: 'UME', group: '3', bgColor: "#c9c9c9" },
    { title: 'UBE', group: '3', bgColor: "#c9c9c9" },
    { title: 'URE*', group: '3', bgColor: "#c9c9c9" },
    { title: 'PSO', group: '3', bgColor: "#c9c9c9" },
    { title: 'BLU', group: '3', bgColor: "#c9c9c9" },
    { title: 'MCC Regular', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Pendidikan', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-SPBU', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-G2P', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-P2G', group: '3', bgColor: "#d6dce5" },
    { title: 'MCC Khusus-Donasi Sosial', group: '3', bgColor: "#d6dce5" },
];