import React, { useCallback, useMemo } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TRANSACTIONS_REFUNDS_MODULE } from "../../../utils/constants/actionTypes";
import DetailTab from "./DetailTab";
import RefundStatusOpts from '../../../utils/constants/enums/status/refundStatusOpts';
import AllStatus from "../../../utils/constants/enums/status";
import { combineBy } from "../../../utils/helpers/combineBy";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { paymentRefundApi } from "../../../services/paymentRefundApi";
import { ppobApi } from "../../../services/ppobApi";
import useAsync from "../../../components/HooksUse/useAsync";
import { DATE_PICKER, SELECT_FIELD } from "../../../utils/constants/inputTypes";
import { ApprovedByOpts } from '../../../utils/constants/enums/approvedBy';
import { toIDR } from "../../../utils/helpers/currency";

const Index = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    totalAmount,
    filter,
    totalRevenue
  } = useSelector(state => state.transactions.refunds);

  const getRefundDebounced = useMemo(
    () => AwesomeDebouncePromise(paymentRefundApi.list, 500),
    [],
  );

  const getBillers = useCallback(
    () => dispatch({
      type: null,
      call: ppobApi.getBillers
    }),
    [dispatch]
  )
  const { value: { data: billerOpts = [] } = {}, pending: gettingBillers } = useAsync(getBillers, true);
  const billerOptions = useMemo(() => billerOpts.map(row => ({ label: row, value: row })) ?? [], [billerOpts]);

  const columns = [
    {
      title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}`
    },
    {
      title: 'Amount', key: 'payment', name: 'payment', render: v => v?.amount ? toIDR(v.amount) : '-'
    },
    {
      title: 'Biller', key: 'payment.metadata.biller', name: 'payment.metadata.biller', render: v => v ?? '-'
    },
    {
      title: 'Transaction Code', key: 'payment.code', name: 'transactionCode'
    },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) }
  ];

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Applicant', key: 'applicant', render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Amount', key: 'payment.amount', render: (v) => toIDR(v, false) },
    { title: 'Biller', key: 'payment.metadata.biller', render: v => v ?? '-' },
    { title: 'Transaction Code', key: 'payment.code' },
    { title: 'Status', key: 'status', render: v => AllStatus.getStr(v) },
  ], [])

  const apiQuery = useMemo(() => {
    const search = filter.search ? `payment.code|${filter.search},applicant.fullName|${filter.search},applicant.uniqueId|${filter.search}` : null;

    return {
      page: currentPage,
      limit,
      sort,
      startDate: filter.startDate,
      endDate: filter.endDate,
      status: filter.status,
      biller: filter.biller,
      search,
      approvedBy: filter.approvedBy
    }
  }, [filter, currentPage, limit, sort])

  return (
    <div>
      <Resource
        title="Refunds"
        subTitle="Full Refund List."
        icon="pe-7s-next"
        list={{
          search: true,
          columns: columns,
          reducerPath: "transactions.refunds",
          call: getRefundDebounced,
          apiQuery: apiQuery,
          module: TRANSACTIONS_REFUNDS_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Revenue: <b>{toIDR(totalRevenue)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Transactions_Refund_List',
            apiResponseKey: 'data.paymentRefund',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: RefundStatusOpts,
              value: filter.status,
            },
            {
              label: "Filter Biller",
              type: SELECT_FIELD,
              key: "biller",
              options: billerOptions,
              value: filter.biller,
              loading: gettingBillers
            },
            {
              label: "Approved By",
              type: SELECT_FIELD,
              key: "approvedBy",
              options: ApprovedByOpts,
              value: filter.approvedBy,
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
