import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { combineBy } from '../../../../utils/helpers/combineBy';
import AllStatus from '../../../../utils/constants/enums/status';
import { AdminRole } from '../../../../utils/constants/enums/adminRoles';
import ReportTableTemplateV2 from './ReportTableTemplateV2';

const getApprover = row => {
  const statusHistories = row.metadata?.injection?.statusHistories ?? [];
  const history = statusHistories.find(row => row.status === AllStatus.COMPLETED) ?? null;
  const actor = history?.actor ?? null;
  if (actor && actor?.firstName) {
    return `${combineBy([actor?.firstName, actor?.lastName])} (${AdminRole.getStr(actor?.role)})`
  } else {
    return `Unknown`
  }
}

const getCreator = row => {
  const statusHistories = row.metadata?.injection?.statusHistories ?? [];
  const history = statusHistories.find(row => row.status === AllStatus.PENDING) ?? null;
  const actor = history?.actor ?? null;
  if (actor && actor?.firstName) {
    return `${combineBy([actor?.firstName, actor?.lastName])} (${AdminRole.getStr(actor?.role)})`
  } else {
    return `Unknown`
  }
}

const TableInjection = ({ items, period }) => {
  const [isPrimary, setIsPrimary] = useState(false);

  const grandTotal = useMemo(() => {
    let amountArr = [];
    let grandTotal = 0;

    items.forEach(v => {
      grandTotal += v?.amount?.primary > 0 ? v?.amount?.primary : v?.amount?.point;
    });

    amountArr = [
      { value: grandTotal }
    ]

    return amountArr;
  }, [items]);

  const columns = useMemo(() => {
    return [
      { title: "Trans. No", key: "code", name: "code" },
      { title: "Name | Unique ID", key: "name", name: "name", render: (v, row) => row?.recipient ? combineBy([combineBy([row.recipient.firstName, row.recipient.lastName]), row.recipient.uniqueId], ' | ') : '-' },
      { title: "Description", key: "description", name: "description", render: (v, row) => row?.metadata?.injection?.description ?? '-' },
      { title: "Created Date", key: "date", name: "date", render: (v, row) => format(new Date(row?.metadata?.injection?.createdAt), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Created By", key: "creator", name: "creator", render: (v, row) => getCreator(row) },
      { title: "Approved Date", key: "createdAt", name: "createdAt", render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
      { title: "Approved By", key: "approver", name: "approver", render: (v, row) => getApprover(row) },
      { title: "Wallet", key: "wallet", name: "wallet", render: (v, row) => row?.amount?.primary > 0 ? 'PRIMARY' : 'POINT' },
      {
        title: "Amount", key: "amount", name: "amount", withCurrency: isPrimary, render: (v, row) => {
          setIsPrimary(Boolean(row?.amount?.primary > 0))
          return row?.amount ?
            row?.amount?.primary > 0 ?
              row?.amount?.primary
              : row?.amount?.point
            : '-'
        }
      }
    ]
  }, [isPrimary])

  return (
    <ReportTableTemplateV2
      title="Injection"
      period={period}
      withIndex
      withExportExcel
      withPrintButton
      table={{
        columns: columns,
        data: items,
        grandTotal: grandTotal
      }}
    />
  )
}

export default TableInjection
