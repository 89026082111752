import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EvyTextField from '../../../components/Forms/EvyTextField';
import { format } from 'date-fns'
import useAsync from '../../../components/HooksUse/useAsync';
import { GET_DETAIL, PROMO_CONFIG_MODULE } from '../../../utils/constants/actionTypes';
import { promoConfigApi } from '../../../services/promoConfigApi';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { tabs } = useSelector(state => state.promoConfig);
  const { key } = tabs[index];
  const detailId = useMemo(() => key.replace(/detail/g, ''), [key]);

  const {
    value: { data: { promoConfig: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: PROMO_CONFIG_MODULE,
      type: GET_DETAIL,
      call: promoConfigApi.detail,
      args: [detailId],
      tabKey: index
    }),
    [dispatch, detailId, index]), true)

  return item ?
    <div className="row">
      <div className="col-12">
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">General Information</h5>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="Method"
                value={item.method}
              />
              <EvyTextField
                readOnly
                row
                label="Type"
                value={item.type}
              />
              <EvyTextField
                readOnly
                row
                label="SubType"
                value={item.subType}
              />
              <EvyTextField
                readOnly
                row
                label="Maximum Amount"
                value={item.maxAmount}
              />
              <EvyTextField
                readOnly
                row
                label="Value"
                value={item.value}
              />
              <EvyTextField
                readOnly
                row
                label="Status"
                value={item.status}
              />
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title">Date</h5>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="Period From"
                value={format(new Date(item.periodFrom), 'dd-MM-yyyy | HH:mm')}
              />
              <EvyTextField
                readOnly
                row
                label="Period To"
                value={format(new Date(item.periodTo), 'dd-MM-yyyy | HH:mm')}
              />
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title">Quota</h5>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="Maximum Quota"
                value={item.maxQuota}
              />
              <EvyTextField
                readOnly
                row
                label="Current Quota"
                value={item.currentQuota}
              />
              <EvyTextField
                readOnly
                row
                label="Quota Applicable"
                value={item.quotaApplicable}
              />
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title">Rules</h5>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="Type"
                value={item.rules.type}
              />
              <EvyTextField
                readOnly
                row
                label="SubType"
                value={item.rules.subType}
              />
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title">Product</h5>
            <div className="mb-4">
              <EvyTextField
                readOnly
                row
                label="Type"
                value={item.product.category}
              />
              <EvyTextField
                readOnly
                row
                label="SubType"
                value={item.product.subCategory}
              />
              <EvyTextField
                readOnly
                row
                label="type"
                value={item.product.type}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <div className="main-card mb-3 card">
      <div className="card-body">
        <p className="text-center mb-0">Item Not Found, Please <b>Re-Select</b> from Main Tab</p>
      </div>
    </div>
}

export default DetailTab;
