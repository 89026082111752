import { faPrint, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { startOfDay } from 'date-fns'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { qrisRintisApi } from '../../../services/qrisRintisApi'
import EvyDatePicker from '../../../components/Forms/EvyDatePicker'
import FormField from '../../../components/Forms/FormField'
import LoadingBtn from '../../../components/Forms/LoadingBtn'
import SelectField from '../../../components/Forms/SelectField'
import Resource from '../../../components/Resource'
import { toast } from 'react-toastify'

function Index() {
  return (
    <Resource
      title="QRIS Rintis Rekap"
      subTitle="View QRIS Rintis Rekap"
      icon="pe-7s-ribbon"
      customComponent={FilterComponent}
    />
  )
}

const FilterComponent = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [reconnecting, setReconnecting] = useState(false)
  const [type, setType] = useState(null)
  const [txtData, setTxtData] = useState('');
  const [date, setDate] = useState(null);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const submit = useCallback(() => {
    setLoading(true)
    dispatch({
      type: null,
      call: qrisRintisApi.getQrisRekap,
      args: [{ type: type, date: date.toISOString() }]
    })
      .then(({ data: { fileBuffer: { data } } }) => {
        var enc = new TextDecoder("utf-8");
        var arr = new Uint8Array(data);
        var decodedText = enc.decode(arr);
        setTxtData(decodedText);
      })
      .catch(() => setTxtData(''))
      .finally(() => setLoading(false))
  }, [dispatch, type, date])

  const reconnect = useCallback(() => {
    setReconnecting(true)
    dispatch({
      type: null,
      call: qrisRintisApi.nginxReconnectRintis
    })
      .then(() => {
        toast("Berhasil terkoneksi dengan Rintis", { type: 'success' });
      })
      .catch(() => {
        toast("Gagal terkoneksi dengan Rintis", { type: 'error' });
      })
      .finally(() => setReconnecting(false))
  }, [dispatch])

  return (
    <div>
      <div className="main-card mb-3 card">
        <div className="card-body">
          <SelectField
            label="Status Filter"
            onChange={v => setType(v?.value ?? null)}
            options={[
              { label: 'Q1 - Transaksi sebagai issuer', value: 'Q1' },
              { label: 'Q2 - Rekap sebagai issuer', value: 'Q2' },
              { label: 'Q4 - Transaksi sebagai acquirer', value: 'Q4' },
              { label: 'Q5 - Rekap sebagai acquirer', value: 'Q5' },
            ]}
            placeholder="No Filter"
            value={type}
            componentProps={{
              isClearable: true
            }}
          />
          <FormField label="Date Filter">
            <EvyDatePicker
              value={date}
              onChange={v => setDate(startOfDay(new Date(v)))}
              variant="inline"
              placeholder="Select date"
              autoOk
            />
          </FormField>
          <LoadingBtn
            className="btn btn-primary btn-block btn-lg"
            loading={loading}
            type="button"
            disabled={!type || loading}
            onClick={submit}
          >
            Submit
          </LoadingBtn>
          <LoadingBtn
            className="btn btn-outline-primary btn-block btn-lg"
            type="button"
            onClick={reconnect}
            loading={reconnecting}
            disabled={reconnecting}
          >
            <FontAwesomeIcon icon={faSyncAlt} className="mr-1" />
            Reconnect
          </LoadingBtn>
        </div>
      </div>
      {
        txtData ?
          <div className="main-card mb-3 card">
            <div className="card-body">
              <button className="btn btn-secondary" onClick={handlePrint}>
                Print
                <FontAwesomeIcon className="ml-2" icon={faPrint} size="1x" />
              </button>
              <div ref={componentRef}>
                <pre>{txtData}</pre>
              </div>
            </div>
          </div> :
          <h5 className="mb-0 px-3 py-4 text-center">No Data</h5>
      }
    </div>
  )
}

export default Index
