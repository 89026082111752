import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MyForm from './Form'
import { BANK_ACCOUNT_MODULE, GET_DETAIL, UPDATE } from '../../../utils/constants/actionTypes';
import { useHistory } from 'react-router-dom';
import useAsync from '../../../components/HooksUse/useAsync';
import useMountedState from '../../../components/HooksUse/useMountedState';
import { bankAccountApi } from '../../../services/bankAccountApi';

const CreateTab = ({ index }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { bankAccount: { derivedData }, tabs: { key, loading } } = useSelector(state => ({ bankAccount: state.bankAccount, tabs: state.bankAccount.tabs[index] }))
  const isMounted = useMountedState();

  const {
    value: { data: { bankAccount: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: GET_DETAIL,
      call: bankAccountApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  const onSubmit = (data) => {
    setIsLoading(true)
    dispatch({
      module: BANK_ACCOUNT_MODULE,
      type: UPDATE,
      call: bankAccountApi.update,
      args: [key, data],
      tabKey: index
    })
      .then(() => {
        if (isMounted()) {
          if (Boolean(derivedData)) history.push(derivedData.from)
        }
      })
      .catch(() => { })
      .finally(() => { if (isMounted()) setIsLoading(false) })
  }

  return loading && !item ?
    null
    :
    item ?
      <div className="main-card mb-3 card">
        <div className="card-body">
          <MyForm
            initialValues={{
              accountName: item.accountName,
              accountNumber: item.accountNumber,
              bank: item.bank?._id,
              ownerModel: item.ownerModel,
              ownerId: item.owner?._id,
              isSystem: item.isSystem,
              typeRdn: item.type ? "1" : "0"
            }}
            onSubmit={onSubmit}
            isLoading={isLoading}
          >
          </MyForm>
        </div>
      </div>
      :
      <div className="card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default CreateTab
