import {
  ASYNC_START,
  ASYNC_END,
  MAIN_TAB_LOAD,
  LIST_SETTLEMENT_TAB_LOAD,
  LIST_SETTLEMENT_REJECTED,
  CHANGE_TAB,
  SORT_TABLE,
  LIMIT_TABLE,
  SET_PAGE_TABLE,
  CREATE_TAB,
  CLOSE_TAB,
  GET_DETAIL,
  BUSINESS_SETTLEMENT_MODULE,
  PRINT_REPORT,
  UPDATE,
  FILTER_TABLE,
} from '../../../utils/constants/actionTypes';
import { closeTab, createTab } from '../../../utils/helpers/tabHelper';

const defaultState = {
  sort: 'createdAt|desc',
  inProgress: false,
  isPrinted: false,
  total: 0,
  limit: 10,
  limitBulk: 10,
  items: [],
  itemsList: [],
  itemsReport: [],
  itemsReportBulks: [],
  itemsSummaryBank: [],
  itemsSummaryBiller: [],
  itemsSummaryBillerPrint: [],
  itemsBulksReject: [],
  code: "",
  listType: "",
  tabs: [],
  currentPageBulks: 1,
  currentPageBulk: 1,
  activeTabKey: 0,
  totalSettlementAmount: 0,
  totalAmountReport: 0,
  totalFee: 0,
  totalMDR: 0,
  totalAmount: 0,
  filter: {
    status: '',
    checker: '',
    approver: '',
    statusSettlement: '',
    aggrBank: '',
    bankRecipient: '',
    search: '',
    startDate: '',
    endDate: '',
    type: ''
  }
};

const reducer = (state = defaultState, action) => {
  if (action.module !== BUSINESS_SETTLEMENT_MODULE) return { ...state }
  switch (action.type) {
    case MAIN_TAB_LOAD:
      return {
        ...state,
        items: !action.error ? action.payload.data.merchantSettlementBulks : state.items,
        totalAmount: !action.error ? action.payload.data.amount : state.totalAmount,
        totalSettlementAmount: !action.error ? action.payload.data.total : state.totalSettlementAmount,
        totalFee: !action.error ? action.payload.data.fee : state.totalFee,
        totalMDR: !action.error ? action.payload.data.mdr : state.totalMDR,
        total: !action.error ? action.payload.data.count : state.total,
      };
    case LIST_SETTLEMENT_TAB_LOAD:
      return {
        ...state,
        itemsSummaryBank: !action.error ? action.payload.data.summaryMerchantSettlement.sumByBankRecipient : state.itemsSummaryBank,
        itemsSummaryBiller: !action.error ? action.payload.data.summaryMerchantSettlement.sumByAggrBank : state.sumByAggrBank,
        itemsList: !action.error ? action.payload.data.merchantSettlements : state.itemsList,
        isPrinted: !action.error ? action.payload.data.merchantSettlementBulk.isPrinted : state.isPrinted,
        totalAmount: !action.error ? action.payload.data.amount : state.totalAmount,
        totalSettlementAmount: !action.error ? action.payload.data.total : state.totalSettlementAmount,
        totalFee: !action.error ? action.payload.data.fee : state.totalFee,
        totalMDR: !action.error ? action.payload.data.mdr : state.totalMDR,
        total: !action.error ? action.payload.data.count : state.total,
        statusSettlement: !action.error ? action.payload.data.merchantSettlementBulk.status : state.statusSettlement,
      };
    case LIST_SETTLEMENT_REJECTED:
      return {
        ...state,
        itemsBulksReject: !action.error ? action.payload.data.merchant : state.items,
      };
    case PRINT_REPORT:
      return {
        ...state,
        itemsReport: !action.error ? action.payload.data.merchantSettlements : state.itemsReport,
        totalAmountReport: !action.error ? action.payload.data.amount : state.totalAmountReport,
        itemsSummaryBillerPrint: !action.error ? action.payload.data.summaryMerchantSettlement.sumByAggrBank : state.itemsSummaryBillerPrint,
        code: !action.error && action.payload.data.code,
      };
    case UPDATE: {
      if (action.error) return { ...state }
      const newTabs = state.tabs.filter((row, key) => key !== action.tabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: 0,
      }
    };
    case GET_DETAIL: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.tabKey]) clonedTabs[action.tabKey].loading = false;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title, action.restData);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case SORT_TABLE:
      return {
        ...state,
        sort: action.value,
      }
    case LIMIT_TABLE:
      return {
        ...state,
        listType: action.listType,
        limit: action.value,
        limitBulk: action.listType === "bulk" ? action.value : 10,
        currentPageBulk: 1,
        currentPageBulks: 1
      }
    case SET_PAGE_TABLE:
      return {
        ...state,
        currentPageBulk: action.listType === "bulk" ? action.value : 1,
        currentPageBulks: action.listType === "bulks" ? action.value : 1
      }
    case FILTER_TABLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        },
        currentPageBulk: 1,
        currentPageBulks: 1
      }
    case ASYNC_END:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        case LIST_SETTLEMENT_TAB_LOAD:
          return {
            ...state,
            inProgress: false,
          };
        default:
          return { ...state };
      }
    case ASYNC_START:
      switch (action.subtype) {
        case MAIN_TAB_LOAD:
          return {
            ...state,
            inProgress: true
          };
        case LIST_SETTLEMENT_TAB_LOAD:
          return {
            ...state,
            inProgress: true,
          };
        case GET_DETAIL:
          const clonedTabs = [...state.tabs];
          clonedTabs[action.actionData.tabKey].loading = true;
          return {
            ...state,
            tabs: clonedTabs
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};

export default reducer
