import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EXPORT_EXCEL_ALL } from '../../utils/constants/actionTypes';
import { ExcelTable } from './ExcelTable';
import ReactDOMServer from 'react-dom/server';
import { auditLogApi } from '../../services/auditLogApi';
import { CircularProgress } from '@material-ui/core';
import { ExcelIcon } from '../Icons/Icons';
import PopUp from '../PopUp';
import LoadingBlocker from '../Loading/LoadingBlocker';

const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))
const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const ExportExcel = ({
  customExportExcel,
  hideExcelTableButton,
  excel,
  table
}) => {
  const [showExcelAction, setShowExcelAction] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const dispatch = useDispatch()

  const exportExcel = useCallback(async ({ allData, index } = {}) => {
    const generatedTable = customExportExcel?.[index]?.customTable ?? ExcelTable({ columns: customExportExcel?.[index]?.columns ? customExportExcel?.[index]?.columns : excel.columns, items: table.data })
    const html = ReactDOMServer.renderToStaticMarkup(generatedTable);
    const excelHref = 'data:application/vnd.ms-excel;base64,'
    const filename = customExportExcel?.[index]?.filename ? customExportExcel?.[index]?.filename : (!allData ? `${excel?.filename}(${table?.currentPage} of ${Math.ceil(table?.total / table?.limit)})` : excel.filename) + '.xls'

    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{html}</body></html>';

    const context = {
      worksheet: 'Worksheet',
      html,
    };

    // check if table is fetching, cancel function 
    if (table.inProgress) return setLoadingExcel(true);
    if (allData) {
      const totalPage = Math.ceil(table?.total / table?.limit);

      dispatch({
        type: EXPORT_EXCEL_ALL,
        columns: customExportExcel?.[index]?.columns ?? excel.columns,
        totalPage: totalPage,
        filename: customExportExcel?.[index]?.filename ?? excel.filename,
        limit: table?.limit,
        api: {
          apiResponseKey: customExportExcel?.[index]?.api?.apiResponseKey ?? excel.api.apiResponseKey,
          service: customExportExcel?.[index]?.api?.service ?? excel.api.service,
          queryParams: customExportExcel?.[index]?.api?.queryParams ?? excel.api.queryParams
        },
      })
      setShowExcelAction(false)
      setLoadingExcel(false)
    } else if (!allData) {
      dispatch({
        type: null,
        call: auditLogApi.create,
        args: [filename, 'GET', 'Export Excel']
      }).finally(() => {
        let a = document.createElement('a')
        a.href = excelHref + base64(format(template, context));
        a.download = filename
        if (a.download.split(".").filter(el => el.includes("xls")).length > 1) {
          a.download = a.download.replace(".xls", "")
        }
        a.click()

        setShowExcelAction(false)
        setLoadingExcel(false)
      })
    }
  }, [
    excel,
    table,
    dispatch,
    customExportExcel
  ]);

  const renderCustomExport = useMemo(() => {
    if (customExportExcel?.length) return (
      customExportExcel?.map((el, i) => {
        return (
          <button
            key={i}
            onClick={() => exportExcel({ allData: el?.allData, index: i })}
            className="d-block btn btn-secondary w-100 mb-2"
          >
            {el?.buttonExport}
          </button>
        )
      })
    )
    return null
  }, [customExportExcel, exportExcel])

  return (
    <>
      <button
        disabled={loadingExcel || table.inProgress}
        onClick={() => setShowExcelAction(true)}
        className="btn btn-excel btn-icon btn-icon-only btn-sm"
      >
        {
          loadingExcel &&
          <div className="position-absolute d-flex" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%' }}>
            <CircularProgress style={{ color: 'white' }} size="1rem" />
          </div>
        }
        <ExcelIcon size={20} />
      </button>
      <PopUp in={showExcelAction} onClose={() => setShowExcelAction(false)}>
        <div className="main-card card">
          <div className="card-body">
            <h5 className="card-title mb-4">Export excel</h5>
            <div className="position-relative">
              {!hideExcelTableButton ?
                <>
                  <button onClick={() => exportExcel({ allData: true })} className="d-block btn btn-primary w-100 mb-2">All Page</button>
                  <button onClick={() => exportExcel({ allData: false })} className="d-block btn btn-secondary w-100 mb-2">Current Page</button>
                </>
                : null
              }
              {renderCustomExport}
              <LoadingBlocker className="m-n1" in={loadingExcel} />
            </div>
            <hr />
            <button onClick={() => setShowExcelAction(false)} className="d-block btn btn-outline-primary w-100">Close</button>
          </div>
        </div>
      </PopUp>
    </>
  )
}

export default ExportExcel
