import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { KYC_VERIFY_HISTORIES_MODULE, MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, FILTER_TABLE } from '../../../utils/constants/actionTypes';
import { startOfDay, format, endOfDay } from 'date-fns';
import Table from '../../../components/Table/Table';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import EvySelectField from '../../../components/Forms/EvySelectField';
import useMountedState from '../../../components/HooksUse/useMountedState';
import FormField from '../../../components/Forms/FormField';
import { finfleetApi } from '../../../services/finfleetApi';
import locale from 'react-json-editor-ajrm/locale/en';
import JSONInput from 'react-json-editor-ajrm';
import { combineBy } from '../../../utils/helpers/combineBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    filter,
    inProgress,
    items,
    total,
  } = useSelector(state => state.kyc.verifyHistories);
  const [count, setCount] = useState(0)
  const [failed, setFailed] = useState(0)
  const [success, setSuccess] = useState(0)
  const isMounted = useMountedState()

  const apiQuery = useMemo(() => {
    let search;
    if (filter.status) search = `responseStatus|${filter.status}`;

    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      sort,
      page: currentPage,
      limit,
      startDate,
      endDate,
      search
    }
  }, [
    filter,
    sort,
    currentPage,
    limit
  ])

  const load = useCallback(
    () => {
      const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
      const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

      dispatch({
        module: KYC_VERIFY_HISTORIES_MODULE,
        type: null,
        call: finfleetApi.count,
        args: [startDate, endDate]
      })
        .then(({ data: { count, failed, success } }) => {
          if (isMounted()) {
            setCount(count)
            setFailed(failed)
            setSuccess(success)
          }
        })
        .catch(() => { })

      dispatch({
        module: KYC_VERIFY_HISTORIES_MODULE,
        type: MAIN_TAB_LOAD,
        call: finfleetApi.list,
        args: [apiQuery]
      });
    },
    [apiQuery, filter, dispatch, isMounted],
  )

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: KYC_VERIFY_HISTORIES_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const table = () => ({
    inProgress: inProgress,
    data: items,
    sort: sort,
    limit: limit,
    total: total,
    currentPage: currentPage,
    collapse: {
      key: 'collapse',
      render: (v, row) => {
        return row.metadata ?
          <JSONInput
            locale={locale}
            placeholder={row.metadata}
            theme="light_mitsuketa_tribute"
            width="100%"
            height="300px"
            viewOnly
            confirmGood={false}
            style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
          /> : null
      }
    },
    columns: [
      {
        title: 'Created At', key: 'createdAt', name: 'processedAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd HH:mm:ss');
        }
      },
      {
        title: 'User', key: 'name', name: 'user_name', render: (v) => {
          return (
            <div className="d-flex align-items-center">
              <span>{v?.value}</span>
              {
                v?.status ?
                  <div className="ml-1">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                  </div>
                  :
                  <div className="ml-1">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                  </div>
              }
            </div>
          )
        }
      },
      {
        title: 'ID Number', key: 'identificationNumber', name: 'id_number', render: (v) => {
          return (
            <div className="d-flex align-items-center">
              <span>{v?.value}</span>
              {
                v?.status ?
                  <div className="ml-1">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                  </div>
                  :
                  <div className="ml-1">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                  </div>
              }
            </div>
          )
        }
      },
      {
        title: 'Date of Birth', key: 'dateOfBirth', name: 'date_of_birth', render: (v) => {
          return (
            <div className="d-flex align-items-center">
              <span>{format(new Date(v?.value), 'yyyy-MM-dd HH:mm:ss')}</span>
              {
                v?.status ?
                  <div className="ml-1">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                  </div>
                  :
                  <div className="ml-1">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                  </div>
              }
            </div>
          )
        }
      },
      { title: 'Status', key: 'responseStatus', name: 'status', sortable: false, render: (v) => v?.value },
      { title: 'Verifier', key: 'checker', name: 'checker', render: (v) => v ? `${combineBy([v?.firstName, v?.lastName])} | ${v?.role}` : '-' },
      { title: 'Similarity', key: 'similarity', name: 'similarity', sortable: false, render: (v) => `${v}%` },
    ]
  })

  const excelColumns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: (v) => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'User', key: 'name', name: 'user_name', render: (v) => v?.value },
    { title: 'ID Number', key: 'identificationNumber', name: 'id_number', render: (v) => v?.value },
    { title: 'Date of Birth', key: 'dateOfBirth', name: 'date_of_birth', render: v => format(new Date(v?.value), 'yyyy-MM-dd hh:mm:ss') },
    { title: 'Status', key: 'responseStatus', name: 'status', render: (v) => v?.value },
    { title: 'Verifier', key: 'checker', name: 'checker', render: (v) => v ? `${combineBy([v?.firstName, v?.lastName])} | ${v?.role}` : '-' },
    { title: 'Similarity', key: 'similarity', name: 'similarity', render: (v) => `${v}%` },
  ]

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <div className="mb-2">
          Penembakan ke verihub <b>total: {count}</b>, <b>berhasil: {success}</b>, <b>gagal: {failed}</b>.
        </div>
        <Table
          table={table()}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
          excel={{
            columns: excelColumns,
            filename: "KYC Verify Histories",
            api: {
              apiResponseKey: "data.verificationHistory",
              service: finfleetApi.list,
              queryParams: apiQuery
            },
          }}
        >
          <div className="form-row">
            <div className="col-12 col-md-4 col-lg-3">
              <EvySelectField
                label="Filter Status"
                className="form-control"
                id="filterStatus"
                placeholder="No Filter"
                options={[
                  {
                    label: 'FAILED',
                    value: 'FAILED',
                  },
                  {
                    label: 'SUCCESS',
                    value: 'SUCCESS'
                  }
                ]}
                onChange={(val) => setTableConfig(FILTER_TABLE)(val, 'status')}
                value={filter.status}
                nullable
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <FormField label="Start Date">
                <EvyDatePicker
                  value={filter.startDate}
                  onChange={(val) => setTableConfig(FILTER_TABLE)(val, 'startDate')}
                  variant="dialog"
                  placeholder="Select date"
                  autoOk
                  clearable={true}
                />
              </FormField>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <FormField label="End Date">
                <EvyDatePicker
                  value={filter.endDate}
                  onChange={(val) => setTableConfig(FILTER_TABLE)(val, 'endDate')}
                  variant="dialog"
                  placeholder="Select date"
                  autoOk
                  clearable={true}
                />
              </FormField>
            </div>
          </div>
        </Table>
      </div>
    </div>
  )
}

export default MainTab
