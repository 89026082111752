import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { cashbackApi } from '../../../services/cashbackApi';
import { CASHBACK_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import AllStatus from '../../../utils/constants/enums/status';
import Form from './FormTab';

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const submit = (values) => {
    const data = {
      ...values,
      businesses: values.businesses.map(v => v.value),
      active: values.active === AllStatus.INACTIVE ? '' : values.active
    }

    setIsLoading(true);
    dispatch({
      module: CASHBACK_MODULE,
      type: CREATE,
      call: cashbackApi.create,
      args: [data]
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return (
    <Form
      onSubmit={submit}
      isLoading={isLoading}
    />
  )
}

export default CreateTab
