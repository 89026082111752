import agent from './agent'
import { paginate } from '../utils/helpers/paginate'

export const bankAccountApi = {
  list: (page, limit, sort, owner, ownerModel, isSystem = false, search, bank) => {
    let url = `/bank-accounts/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (owner) {
      url += `&owner=${owner}`;
    }
    if (ownerModel) {
      url += `&ownerModel=${ownerModel}`;
    }
    if (isSystem) {
      url += `&isSystem=1`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (bank) {
      url += `&bank=${bank}`;
    }
    return agent.requests.get(url)
  },
  update: (id, data) => {
    return agent.requests.post(`/bank-accounts/${id}/update`, data, true);
  },
  create: (data) => {
    return agent.requests.post(`/bank-accounts/create`, data, true);
  },
  delete: (id) =>
    agent.requests.post(`/bank-accounts/${id}/delete-for-admin`),
  detail: (id) =>
    agent.requests.get(`/bank-accounts/${id}/detail`),
}
