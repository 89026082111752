import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { DISCLAIMER_MODULE, CREATE } from '../../../utils/constants/actionTypes';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import HookTextField from '../../../components/Forms/HookTextField';
import FormField from '../../../components/Forms/FormField';
import { useDispatch } from 'react-redux';
import EvyDatePicker from '../../../components/Forms/EvyDatePicker';
import SelectField from '../../../components/Forms/SelectField';
import { DisclaimerTypeOpts } from '../../../utils/constants/enums/disclaimerTypes';
import useMountedState from '../../../components/HooksUse/useMountedState'
import { disclaimerApi } from '../../../services/disclaimerApi';

const schema = yup.object().shape({
  version: yup.string().required('Version is required'),
  content: yup.string().required('Content is required'),
  date: yup.string().typeError('Field required').required('Date is required'),
  type: yup.string().required('Type is required'),
});

const Form = ({ item }) => {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, setValue, watch, unregister } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      version: item?.version ?? '',
      content: item?.content ?? '',
      type: item?.type ?? '',
      date: item?.date ?? '',
    }
  });
  const { date, type } = watch(['date', 'type']);

  useEffect(() => {
    register("type");
    register("date");

    return () => {
      unregister("type");
      unregister("date");
    };
  }, [register, unregister])

  const onSubmit = (values) => {
    setIsLoading(true);
    dispatch({ module: DISCLAIMER_MODULE, type: CREATE, call: disclaimerApi.update, args: [values] })
      .then(() => { })
      .catch(() => { })
      .finally(() => {
        if (isMounted()) setIsLoading(false);
      })
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SelectField
        row
        label="Type"
        value={type}
        options={DisclaimerTypeOpts}
        onChange={(v) => setValue('type', v?.value, { shouldValidate: true })}
        error={errors.type}
        helperText={errors?.type?.message ?? null}
        componentProps={{
          isDisabled: item
        }}
      />
      <HookTextField
        ref={register}
        label="Version"
        name="version"
        error={errors.version}
        helperText={errors.version ? errors.version.message : null}
        row
      />
      <HookTextField
        ref={register}
        label="Content"
        name="content"
        error={errors.content}
        helperText={errors.content ? errors.content.message : null}
        row
      />
      <FormField
        row
        label="Release Date"
        error={errors.date}
        helperText={errors.date ? errors.date.message : null}
        className="align-items-center"
      >
        <EvyDatePicker
          value={date}
          variant="inline"
          autoOk
          format="dd-MM-yyyy"
          onChange={(v) => setValue('date', v, { shouldValidate: true })}
        />
      </FormField>
      <FormField
        row
      >
        <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary mr-2">Submit</LoadingBtn>
      </FormField>
    </form>
  )
}

export default Form
