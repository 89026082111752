import React from 'react'
import { useDispatch } from 'react-redux';
import { auditLogApi } from '../../../../services/auditLogApi';
import { ExcelIcon } from '../../../../components/Icons/Icons';

const ExcelExportBtn = ({ filename, excelHref }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({
      type: null,
      call: auditLogApi.create,
      args: [filename, 'GET', 'Export Excel']
    }).finally(() => {
      let a = document.createElement('a')
      a.href = excelHref
      a.download = filename
      a.click()
    })
  }

  return (
    <button
      onClick={handleClick}
      className="btn btn-excel"
    >
      <ExcelIcon size={32} />
      <span className="ml-2">Export to Excel</span>
    </button>
  )
}

export default ExcelExportBtn
