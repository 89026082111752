import React, { useState } from 'react';
import { Slash } from '../../../../components/Icons/Icons';
import LoadingBlocker from '../../../../components/Loading/LoadingBlocker';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DeclineDialog from '../../../../components/Dialog/DeclineDialog';
import roleHelper, { BLACKLIST_MERCHANT } from '../../../../utils/helpers/roleHelper';
import { businessesApi } from '../../../../services/businessesApi';
import AllStatus from '../../../../utils/constants/enums/status';
import { combineBy } from '../../../../utils/helpers/combineBy';

const BlackListCard = ({ admin, item, onSuccess }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const failedAttempts = item?.failedAttempts?.length ? [...item.failedAttempts] : [];
  const lastBlacklistAttempt = failedAttempts.reverse().find(att => att.name === AllStatus.BLACKLIST);
  const openDialogRef = React.createRef();
  const blacklistStatus = item.status.blacklist;

  const blacklist = (id, holdReason) => {
    setIsLoading(true);
    dispatch({ type: null, call: businessesApi.blacklist, args: [id, holdReason] })
      .then(() => {
        onSuccess();
        toast("Blacklist success", { type: 'success' });
      })
      .catch(() => {
        toast("Blacklist failed", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const unBlacklist = (id, releaseReason) => {
    setIsLoading(true);
    dispatch({ type: null, call: businessesApi.unBlacklist, args: [id, releaseReason] })
      .then(() => {
        onSuccess();
        toast("Un-Blacklist request success", { type: 'success' });
      })
      .catch(() => {
        toast("Un-Blacklist request failed", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    roleHelper.hasAccess(admin.role, BLACKLIST_MERCHANT) ?
      <div className="col-12 col-md-3 mbg-3">
        {
          blacklistStatus ?
            <>
              <ButtonCard
                onClick={() => openDialogRef.current.click()}
                blacklist={blacklistStatus}
                lastBlacklistAttempt={lastBlacklistAttempt}
                isLoading={isLoading}
              />
              <DeclineDialog
                className="d-none"
                fieldLabel="Release Reason"
                ref={openDialogRef}
                dataId={item._id}
                onSubmit={unBlacklist}
                label="Un-Blacklist"
                desc="Are you sure want to un-Blacklist this merchant?"
              />
            </>
            :
            <>
              <ButtonCard
                onClick={() => openDialogRef.current.click()}
                blacklist={blacklistStatus}
                lastBlacklistAttempt={lastBlacklistAttempt}
                isLoading={isLoading}
              />
              <DeclineDialog
                className="d-none"
                fieldLabel="Hold Reason"
                ref={openDialogRef}
                dataId={item._id}
                onSubmit={blacklist}
                label="Blacklist"
                desc="Are you sure want to blacklist this merchant? merchant will automatically logged out after blacklist"
              />
            </>
        }
      </div>
      :
      null
  )
}

const ButtonCard = ({ onClick, blacklist, lastBlacklistAttempt, isLoading }) => (
  <div className="card suspend-card" onClick={onClick}>
    <div className="card-body">
      <div className="text-center">
        <div className={`ban-icon-animated${blacklist ? ' suspended' : ''}`}>
          <Slash className="fa-6x" />
        </div>
        <p className="mb-2">
          <b>{blacklist ? 'Blacklist' : 'Not Blacklist'}</b>
          {blacklist &&
            <>
              <br />
              <b>
                By: {lastBlacklistAttempt ? `${combineBy([lastBlacklistAttempt.actor.firstName, lastBlacklistAttempt.actor.lastName])} [${lastBlacklistAttempt.actorModel}]` : '-'}
              </b>
            </>
          }
        </p>
        {blacklist && lastBlacklistAttempt &&
          <p className="mb-0 text-black-50">{lastBlacklistAttempt.description || 'No remarks'}</p>
        }
      </div>
      <LoadingBlocker in={isLoading} />
    </div>
  </div>
)

export default BlackListCard;
