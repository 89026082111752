// import CryptoJS from "crypto-js";

// export const encrypt = (text) => {
//   let iv = CryptoJS.lib.WordArray.random(+process.env.REACT_APP_IV_LENGTH);
//   let key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);

//   let encrypted = CryptoJS.AES.encrypt(text, key, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7
//   });

//   return iv.toString() + ':' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
// }

// export const decrypt = (text) => {
//   let [ivHex, msgHex] = text.split(':');
//   let iv = CryptoJS.enc.Utf8.parse(ivHex);
//   let key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
//   let encryptedText = CryptoJS.enc.Hex.parse(msgHex);

//   let decrypted = CryptoJS.AES.decrypt(
//     { ciphertext: encryptedText },
//     key,
//     { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
//   );

//   return decrypted.toString(CryptoJS.enc.Utf8);
// }

import crypto from "crypto";

export const encrypt = (text) => {
  let iv = crypto.randomBytes(+process.env.REACT_APP_IV_LENGTH);
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString('hex') + ':' + encrypted.toString('hex');
}

export const decrypt = (text) => {
  let [iv, msg] = text.split(':');
  iv = Buffer.from(iv, 'hex');
  let encryptedText = Buffer.from(msg, 'hex');
  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
