import React, { useEffect } from 'react'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import HookTextField from '../../../components/Forms/HookTextField';
import SelectField from '../../../components/Forms/SelectField';
import { numberRegExp } from '../../../utils/constants/regexTypes';
import LoadingBtn from '../../../components/Forms/LoadingBtn';
import { PpobTypeOpts } from '../../../utils/constants/enums/ppobTypes';

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  content: yup.string()
    .required()
    .max(135, 'Maximum 135 characters allowed.'),
  position: yup.string().matches(numberRegExp, 'Only Number Allowed').required(),
  isActive: yup.string().required(),
  isCloseable: yup.string().required()
});

const Form = ({ initialValues, onSubmit, isLoading, module }) => {
  const { register, unregister, setValue, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValues,
      isActive: initialValues?.isActive ? "true" : "false",
      isCloseable: initialValues?.isCloseable ? "true" : "false",
    }
  });

  const {
    type,
    position,
    isActive,
    isCloseable
  } = watch([
    'type',
    'position',
    'isActive',
    'isCloseable'
  ]);

  useEffect(() => {
    register("type")
    register('position')
    register("isActive")
    register("isCloseable")

    return () => {
      unregister("type")
      unregister('position')
      unregister("isActive")
      unregister("isCloseable")
    };
  }, [register, unregister]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
          <h5 className="card-title">General Information</h5>
        </div>
        <div className="col-12 col-md-9">
          <HookTextField
            ref={register}
            label="Name"
            name="name"
            error={errors.name}
            helperText={errors.name ? errors.name.message : null}
            placeholder="e.g: info lebaran"
          />
          <SelectField
            label="Select Type"
            name="type"
            placeholder="Select Type"
            options={[
              {
                value: "HOME_INFO",
                label: "HOME_INFO"
              },
              {
                value: "PPOB_EWALLET_DANA",
                label: "PPOB_EWALLET_DANA"
              },
              {
                value: "PPOB_EWALLET_GOPAY",
                label: "PPOB_EWALLET_GOPAY"
              },
              {
                value: "PPOB_EWALLET_OVO",
                label: "PPOB_EWALLET_OVO"
              },
              {
                value: "PPOB_EWALLET_SHOPEE",
                label: "PPOB_EWALLET_SHOPEE"
              },
              ...PpobTypeOpts
            ]}
            onChange={v => setValue('type', v?.value, { shouldValidate: true })}
            value={type}
            error={errors.type}
            helperText={errors.type?.message ?? null}
          />
          <SelectField
            label="Set Active Status"
            name="isActive"
            options={[
              {
                value: "true",
                label: "Active"
              },
              {
                value: "false",
                label: "Inactive"
              }
            ]}
            onChange={v => setValue('isActive', v?.value, { shouldValidate: true })}
            value={isActive}
            error={errors.isActive}
            helperText={errors.isActive?.message ?? null}
          />
          <SelectField
            label="Set Closable Status"
            name="isCloseable"
            options={[
              {
                value: "true",
                label: "Closeable"
              },
              {
                value: "false",
                label: "Nonclosable"
              }
            ]}
            onChange={v => setValue('isCloseable', v?.value, { shouldValidate: true })}
            value={isCloseable}
            error={errors.isCloseable}
            helperText={errors.isCloseable?.message ?? null}
          />
          <HookTextField
            label="Position"
            name="position"
            onChange={(v) => {
              setValue('position', v, { shouldValidate: true })
            }}
            value={position}
            error={errors.position}
            helperText={errors.position ? errors.position.message : null}
            placeholder="e.g: 1"
            format={Number}
          />
          <HookTextField
            ref={register}
            label="Content"
            name="content"
            error={errors.content}
            helperText={errors.content ? errors.content.message : null}
            multiline
            maxLength={136}
            placeholder="e.g: Selama cuti bersama Hari Raya Idul Fitri 1444 H, operasional kami akan tutup sementara dari 17 - 20 Mei 2023."
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-3">
        </div>
        <div className="col-12 col-md-9">
          <LoadingBtn type="submit" loading={isLoading} className="btn btn-primary">Submit</LoadingBtn>
        </div>
      </div>
    </form>
  )
}

export default Form
