import {
  LOGIN,
  REGISTER,
  ASYNC_START,
  APP_LOAD,
  VERIFY_OTP,
} from '../../utils/constants/actionTypes';

const defaultState = {
  inProgress: false,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
      };
    case LOGIN:
    case REGISTER:
    case VERIFY_OTP:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.message : null
      };
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER || action.subtype === VERIFY_OTP) {
        return { ...state, inProgress: true };
      }
      break;
    default:
      return state;
  }

  return state;
};

export default reducer
