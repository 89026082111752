import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useState } from 'react'
import NumberFormat from 'react-number-format';
import FormField from './FormField';

const HookTextField = forwardRef((
  {
    id,
    name,
    value,
    label,
    onChange,
    onBlur,
    error,
    helperText,
    placeholder,
    multiline,
    className,
    autoComplete = "off",
    spellCheck = false,
    readOnly,
    disabled,
    row,
    rows,
    append,
    prepend,
    required,
    defaultValue,
    formFieldClassName,
    showPassword,
    moneyInput,
    format,
    maxLength,
    allowLeadingZero,
    onFocus,
    allowDot,
    copyable
  },
  ref
) => {
  let field;
  const [isShowPassword, setIsShowPassword] = useState(true);

  if (multiline) {
    field = <textarea
      disabled={disabled}
      readOnly={readOnly}
      id={id || name}
      name={name}
      rows={rows}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      spellCheck={spellCheck}
      maxLength={maxLength}
      className={`form-control ${className ? className : ''}`}
      ref={ref}
    />
  } else if (format || moneyInput) {
    field =
      <NumberFormat
        name={name}
        value={value}
        defaultValue={defaultValue}
        mask={format}
        allowNegative={false}
        allowLeadingZeros={allowLeadingZero}
        onValueChange={({ value }) => {
          if (typeof onChange === "function") onChange(value)
        }}
        onBlur={(e) => {
          if (typeof onBlur === "function") onBlur(e);
        }}
        onFocus={(e) => {
          if (typeof onFocus === "function") onFocus(e);
        }}
        thousandSeparator={Boolean(moneyInput) ? "." : null}
        decimalSeparator={Boolean(moneyInput) ? "," : false}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readOnly}
        className={`form-control ${className ? className : ''}`}
        isAllowed={(values) => {
          const { value, floatValue } = values;

          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }

          if (allowDot) {
            if (allowLeadingZero) {
              if (maxLength) {
                if (value.length <= maxLength) {
                  return true
                }
              } else {
                return true
              }
            } else {
              if (value.charAt(0) !== '0') {
                if (maxLength) {
                  if (value.length <= maxLength) {
                    return true
                  }
                } else {
                  return true
                }
              }
            }
          } else {
            if (value.charAt(0) !== '.') {
              if (allowLeadingZero) {
                if (maxLength) {
                  if (value.length <= maxLength) {
                    return true
                  }
                } else {
                  return true
                }
              } else {
                if (value.charAt(0) !== '0') {
                  if (maxLength) {
                    if (value.length <= maxLength) {
                      return true
                    }
                  } else {
                    return true
                  }
                }
              }
            }
          }
        }}
      />
  } else {
    field = <input
      title={readOnly ? value : ''}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      id={id || name}
      name={name}
      maxLength={maxLength}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      type={(showPassword && isShowPassword) ? 'password' : 'text'}
      className={`form-control ${className ? className : ''}`}
      ref={ref}
      onCopy={copyable}
    />
  }

  return (
    <FormField
      id={id}
      row={row}
      required={required}
      label={label}
      error={error}
      helperText={helperText}
      className={formFieldClassName}
    >
      <div className={`${append || prepend || showPassword ? 'input-group' : ''}`}>
        {prepend ? (
          <div className="input-group-prepend">
            <span className="input-group-text">
              {prepend}
            </span>
          </div>
        ) : ''}
        {field}
        {append ? (
          <div className="input-group-append">
            <span className="input-group-text">
              {append}
            </span>
          </div>
        ) : ''}
        {showPassword ? (
          <div className="input-group-append">
            <button
              type="button"
              className="btn border text-grey"
              onClick={() => setIsShowPassword(isShowPassword ? false : true)}
            >
              <FontAwesomeIcon icon={isShowPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        ) : ''}
      </div>
    </FormField>
  )
})

export default HookTextField;
