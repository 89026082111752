import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const ppobApi = {
  list: (page, limit, sort, type, search, status, biller, revenue) => {
    let url = `/ppob/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (biller) {
      url += `&biller=${biller}`;
    }
    if (revenue) {
      url += `&revenue=${revenue}`;
    }
    return agent.requests.get(url)
  },
  create: (data) => (
    agent.requests.post(`/ppob/create`, data, true)
  ),
  update: (id, data) => (
    agent.requests.post(`/ppob/${id}/update`, data, true)
  ),
  getProduct: (type, productId = null) => {
    let url = `/ppob/${type}/product-list`;
    if (productId) {
      url += `?productId=${productId}`;
    }
    return agent.requests.get(url)
  },
  getGameVoucherCategory: () => (
    agent.requests.get(`/ppob/get-game-voucher-category`)
  ),
  getBillers: () => (
    agent.requests.get('/ppob/list-biller')
  ),
  manualAdvice: (id, data) => (
    agent.requests.post(`/ppob/${id}/manual-advice`, data)
  ),
  checkStatus: ({ id, model }) => {
    let url = `/ppob/${id}/check-status-all`
    if (model) {
      url += `?model=${model}`
    }
    return agent.requests.post(url)
  },
  comparePpobPrice: (type) => {
    let url = `/ppob/${type}/compare-price`;
    return agent.requests.get(url)
  },
  updatePrice: (filter, biller) => {
    let url = `/ppob/map-price?filter=${filter}`;
    if (biller) url += `&biller=${biller}`
    return agent.requests.post(url)
  }
  // delete: (id) =>
  //   agent.requests.post(`/ppob/${id}/delete`)
}
