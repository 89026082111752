import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const paginate = (
  totalItems,
  currentPage,
  pageSize,
  maxPages
) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  // let startIndex = (currentPage - 1) * pageSize;
  // let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

  // return object with all pager properties required by the view
  return pages;
}

const maxPages = 5;

const Pagination = ({ total, limit, currentPage, onChangePage }) => {
  const pages = paginate(total, currentPage, limit, maxPages);
  const totalPage = Math.ceil(total / limit);

  const viewPage = page => {
    if (page > 0 && page <= totalPage) onChangePage(page)
  }

  return (
    totalPage > 0 ?
      <ul className="pagination">
        <PaginationItem
          disabled={currentPage <= 1}
          onClick={() => viewPage(1)}
          label={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
        />
        <PaginationItem
          disabled={currentPage <= 1}
          onClick={() => viewPage(currentPage - 1)}
          label={<FontAwesomeIcon icon={faAngleLeft} />}
        />
        {
          pages.map((page, key) =>
            <PaginationItem
              key={key}
              active={currentPage === page}
              onClick={() => viewPage(page)}
              label={page}
            />
          )
        }
        <PaginationItem
          disabled={currentPage >= totalPage}
          onClick={() => viewPage(currentPage + 1)}
          label={<FontAwesomeIcon icon={faAngleRight} />}
        />
        <PaginationItem
          disabled={currentPage >= totalPage}
          onClick={() => viewPage(totalPage)}
          label={<FontAwesomeIcon icon={faAngleDoubleRight} />}
        />
      </ul>
      :
      null
  )
}

const PaginationItem = ({ disabled, active, onClick, label }) => {
  return (
    <li className={`page-item${disabled ? ' disabled' : ''}${active ? ' active' : ''}`}>
      <button className="page-link" disabled={disabled} onClick={onClick}>{label}</button>
    </li>
  )
}

export default Pagination
