import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EvyTextField from '../../../../components/Forms/EvyTextField'
import { format } from 'date-fns'
import { toIDR } from '../../../../utils/helpers/currency';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import StatusHistory from '../../../../components/StatusHistory';
import { combineBy } from '../../../../utils/helpers/combineBy';
import useAsync from '../../../../components/HooksUse/useAsync';
import { GET_DETAIL, TRANSACTIONS_QRIS_MPM_MODULE } from '../../../../utils/constants/actionTypes';
import { paymentApi } from '../../../../services/paymentApi';

const Detail = ({ index }) => {
  const { key, loading } = useSelector(state => state.transactions.qrisMPM.tabs[index]);
  const dispatch = useDispatch();

  const {
    value: { data: { payments: item } = {} } = {}
  } = useAsync(useCallback(
    () => dispatch({
      module: TRANSACTIONS_QRIS_MPM_MODULE,
      type: GET_DETAIL,
      call: paymentApi.detail,
      args: [key],
      tabKey: index
    }),
    [dispatch, key, index]), true)

  return loading ?
    null
    :
    item ?
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Payment Information</h5>
              <div className="mb-4">
                <EvyTextField
                  readOnly
                  row
                  label="Payment Code"
                  value={item.code}
                  helperText={`Created at: ${item.createdAt ? format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="User Payment"
                  value={toIDR(item.amount)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="MDR Percentage"
                  value={item?.metadata?.MDRPCT ? `${item?.metadata?.MDRPCT}%` : '0%'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="MDR Amount"
                  value={toIDR(item?.metadata?.MDR)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Revenue"
                  value={toIDR(item?.metadata?.Rev)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Merchant Receive"
                  value={toIDR(item?.merchantReceive)}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Status"
                  value={item.status}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Remarks"
                  value={item.remarks ? item.remarks : '-'}
                />
              </div>
              {item.referralBonus?.user &&
                <>
                  <h5 className="card-title">Referral Bonus</h5>
                  <div>
                    <EvyTextField
                      readOnly
                      row
                      label="Referral"
                      value={`${combineBy([item.referralBonus?.user.firstName, item.referralBonus?.user.lastName])} | ${item.referralBonus?.user.uniqueId}`}
                      helperText={`Joined at: ${item.applicant?.referralInputAt ? format(new Date(item.applicant?.referralInputAt), 'yyyy-MM-dd HH:mm:ss') : '-'}`}
                    />
                    <EvyTextField
                      readOnly
                      row
                      label="Amount"
                      value={toIDR(item.referralBonus.amount, false)}
                    />
                  </div>
                </>
              }
            </div>
          </div>
          <StatusHistory className="mb-3" statusHistories={item.statusHistories} />
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Meta Data</h5>
              <div>
                <JSONInput
                  locale={locale}
                  placeholder={item.metadata}
                  theme="light_mitsuketa_tribute"
                  width="100%"
                  height="300px"
                  viewOnly
                  confirmGood={false}
                  style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Applicant</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Unique ID"
                  value={item.applicant?.uniqueId ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="First Name"
                  value={item.applicant?.firstName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Last Name"
                  value={item.applicant?.lastName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Phone Number"
                  value={item.applicant?.phoneNumber ?? '-'}
                  helperText={`Verified at: ${item.applicant?.status?.phoneNumberVerifiedAt ? format(new Date(item.applicant.status.phoneNumberVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Email"
                  value={item.applicant?.email ?? '-'}
                  helperText={`Verified at: ${item.applicant?.status?.emailVerifiedAt ? format(new Date(item.applicant.status.emailVerifiedAt), 'yyyy-MM-dd HH:mm') : '-'}`}
                />
              </div>
            </div>
          </div>
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Merchant Information</h5>
              <div>
                <EvyTextField
                  readOnly
                  row
                  label="Location"
                  value={item?.merchantLocation ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Name"
                  value={item?.merchantName ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Acquirer"
                  value={item?.acquirer ?? '-'}
                />
                <EvyTextField
                  readOnly
                  row
                  label="Issuer"
                  value={item?.issuer ?? '-'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="text-center mb-0">Not Found</h5>
        </div>
      </div>
}

export default Detail;
