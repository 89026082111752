import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const settlementApi = {
  list: ({ page, limit, sort, search, startDate, endDate, status, aggrBank, bankRecipient }) => {
    let url = `/merchant-settlements/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (startDate) {
      url += `&trxFromDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
      url += `&trxToDate=${encodeURIComponent(endDate)}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (aggrBank) {
      url += `&aggrBank=${aggrBank}`;
    }
    if (bankRecipient) {
      url += `&bankRecipient=${bankRecipient}`;
    }
    return agent.requests.get(url)
  },
  // approve: (id, data) =>
  //   agent.requests.post(`/merchant-settlements/bulk/${id}/approve`, data),
  // decline: (id, remarks) =>
  //   agent.requests.post(`/merchant-settlements/${id}/decline`, { remarks }),
  check: (id) => {
    return agent.requests.post(`/merchant-settlements/${id}/check-status`)
  },
  detail: (id) => {
    return agent.requests.get(`/merchant-settlements/${id}/detail`)
  },
}
