import agent from "./agent";
import { paginate } from "../utils/helpers/paginate";

export const errorLogApi = {
  list: (page, limit, sort, search) => {
    let url = `/logs/list?${paginate(limit, page)}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return agent.requests.get(url)
  },
}
