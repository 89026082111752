import {
  CHECK_PPOB_PRICE_MODULE,
} from '../../../utils/constants/actionTypes';

const defaultState = {
  activeTabKey: 0,
};

const reducer = (state = defaultState, action) => {
  if (action.module !== CHECK_PPOB_PRICE_MODULE) return { ...state }
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer
