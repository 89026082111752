import React, { useCallback, useMemo } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { SETTLEMENT_MODULE } from "../../../utils/constants/actionTypes";
import { combineBy } from "../../../utils/helpers/combineBy";
import { DATE_PICKER, MERCHANT_SELECT, SELECT_FIELD, TEXT_FIELD } from "../../../utils/constants/inputTypes";
import { toIDR } from "../../../utils/helpers/currency";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { settlementApi } from "../../../services/settlementApi";
import AllStatus from "../../../utils/constants/enums/status";
import DetailTab from './DetailTab'
import useAsync from "../../../components/HooksUse/useAsync";
import { bankApi } from "../../../services/bankApi";

const getClass = status => {
  let className = 'badge-primary';
  if (status === AllStatus.PENDING) {
    className = 'badge-warning'
  } else if (status === AllStatus.COMPLETED) {
    className = 'badge-success'
  } else if (status === AllStatus.REJECTED || status === AllStatus.FAILED) {
    className = 'badge-danger'
  }
  return className
}

const Index = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalSettlementAmount,
    totalFee,
    totalMDR,
    totalAmount,
  } = useSelector(state => state.settlement);
  const { currentAdmin: { role } } = useSelector(state => state.common);

  const getSettlementDebounced = useMemo(
    () => AwesomeDebouncePromise(settlementApi.list, 500),
    [],
  )

  const columns = [
    { title: 'Created At', key: 'transactionUnHold', name: 'createdAt', render: (v, row) => v?.createdAt ? format(new Date(v?.createdAt), 'yyyy-MM-dd hh:mm:ss') : format(new Date(row?.updatedAt), 'yyyy-MM-dd hh:mm:ss') },
    { title: 'Applicant', key: 'applicant.businesses', name: 'applicantName', render: (v, row) => row.applicant ? v?.name + ' | ' + row.applicant.uniqueId : 'Unknown' },
    { title: 'Status', key: 'status', name: 'status', className: 'text-center', render: v => <div className="text-center"><span className={`badge ${getClass(v)}`}>{v}</span></div> },
    { title: 'Trans. Code', key: 'code', name: 'code' },
    { title: 'Bank', key: 'bankAccount', name: 'Bank', render: (v, row) => <div><div>{v?.bank?.name}</div><div className="text-muted">{row?.bankAccount?.accountNumber}</div></div> },
    { title: 'Bank Biller', key: 'aggrBank', name: 'Bank', render: v => v ?? '-' },
    { title: 'Settlement Amount', key: 'totalAmount', name: 'totalAmount', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
    { title: 'Fee', key: 'fee', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
    { title: 'MDR', key: 'mdr', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
    { title: 'Transfer Amount', key: 'amount', name: 'amount', render: v => <div className="font-weight-semibold">{toIDR(v)}</div> },
  ];

  const excelsColumns = [
    { title: 'Created At', key: 'transactionUnHold', name: 'createdAt', render: (v, row) => v?.createdAt ? format(new Date(v?.createdAt), 'yyyy-MM-dd hh:mm:ss') : format(new Date(row?.updatedAt), 'yyyy-MM-dd hh:mm:ss') },
    { title: 'Applicant', key: 'applicant.businesses', name: 'applicantName', render: (v, row) => row.applicant ? v?.name + ' | ' + row.applicant.uniqueId : 'Unknown' },
    { title: 'Status', key: 'status', name: 'status', className: 'text-center', render: v => <div className="text-center"><span className={`badge ${getClass(v)}`}>{v}</span></div> },
    { title: 'Trans. Code', key: 'code', name: 'code' },
    { title: 'Bank', key: 'bankAccount', name: 'Bank', render: (v, row) => <div>{v?.bank?.name} | {row?.bankAccount?.accountNumber}</div> },
    { title: 'Bank Biller', key: 'aggrBank', name: 'Bank', render: v => v ?? '-' },
    { title: 'Settlement Amount', key: 'totalAmount', name: 'totalAmount', render: v => <div className="font-weight-semibold">{toIDR(v, false)}</div> },
    { title: 'Fee', key: 'fee', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v, false)}</div> },
    { title: 'MDR', key: 'mdr', name: 'Fee', render: v => <div className="font-weight-semibold">{toIDR(v, false)}</div> },
    { title: 'Transfer Amount', key: 'amount', name: 'amount', render: v => <div className="font-weight-semibold">{toIDR(v, false)}</div> },
  ];

  const getArtajasaTypes = useCallback(() => {
    return dispatch({
      type: null,
      call: bankApi.getArtajasaTypes,
    })
  }, [dispatch]);

  const { value } = useAsync(getArtajasaTypes, true);
  const options = useMemo(() => {
    return value?.data?.artajasaBank.map(row => ({
      label: `${row.artajasaCode} - ${row.name}`,
      value: row.enum
    })) ?? []
  }, [value])

  const apiQuery = useMemo(() => {
    const search = combineBy([
      filter.merchant && typeof filter.merchant === 'object' ? `applicantModel|Merchant,applicant|${filter.merchant.value}` : false,
      filter.transactionCode ? `code|${filter.transactionCode}` : false
    ], ',');
    const aggrBank = filter.aggrBank
    const bankRecipient = filter.bankRecipient
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      search,
      startDate,
      endDate,
      aggrBank,
      bankRecipient,
      status: filter.status
    }
  }, [filter, currentPage, limit, sort])

  return (
    <div>
      <Resource
        title="Settlement"
        subTitle="Pending Settlement list."
        icon="pe-7s-safe"
        list={{
          columns: columns,
          reducerPath: "settlement",
          call: getSettlementDebounced,
          apiQuery: apiQuery,
          excel: {
            role,
            columns: excelsColumns,
            apiResponseKey: 'data.merchantSettlements',
            filename: 'Merchant_Settlement_List',
            queryParams: apiQuery
          },
          module: SETTLEMENT_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Total Settlement Amount: <b>{toIDR(totalSettlementAmount)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Fee: <b>{toIDR(totalFee)}</b>
              </li>
              <li className="list-group-item px-0">
                Total MDR: <b>{toIDR(totalMDR)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Transfer Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          customActionColumns: [{
            role: {
              key: "status",
              value: AllStatus.PROCESSING
            },
            title: "Check Status",
            action: {
              call: settlementApi.check
            }
          }],
          filters: [
            {
              label: "Filter Transaction Code",
              type: TEXT_FIELD,
              key: "transactionCode",
              value: filter.transactionCode,
              placeholder: "Search code"
            },
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: [
                {
                  value: 'COMPLETED',
                  label: 'Completed'
                },
                {
                  value: 'FAILED',
                  label: 'Failed'
                }
              ],
              value: filter.status,
            },
            {
              type: MERCHANT_SELECT,
              key: "merchant",
              value: filter.merchant
            },
            {
              label: "Filter Bank Biller",
              type: SELECT_FIELD,
              key: "aggrBank",
              options: [
                { label: 'Artajasa', value: 'ARTAJASA' },
                { label: 'BCA', value: 'BCA' },
                { label: 'Mandiri', value: 'MANDIRI' },
                { label: 'CIMB Niaga', value: 'CIMB_NIAGA' },
                { label: 'BNI', value: 'BNI' },
                { label: 'BRI', value: 'BRI' },
                { label: 'BNI Disbursement', value: 'BNI_DISBURSEMENT' }
              ],
              value: filter.aggrBank,
            },
            {
              label: "Filter Bank",
              type: SELECT_FIELD,
              key: "bankRecipient",
              options: options,
              value: filter.bankRecipient,
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            }
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
