import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { CREATE, EXCEL_FORMATS, INJECTION_BULKING_MODULE } from '../../../utils/constants/actionTypes';
import ListErrors from '../../../components/ListErrors';
import LoadingBlocker from '../../../components/Loading/LoadingBlocker';
import styles from './injectionBulking.module.scss';
import useMountedState from '../../../components/HooksUse/useMountedState';
import CheckIcon from '@material-ui/icons/Check';
import HookTextField from '../../../components/Forms/HookTextField';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { injectionBulkingApi } from '../../../services/injectionBulkingApi';

const formId = "formInjectionSubmit"

const InjectTab = () => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState('');
  const [error, setError] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const [errorList, setErrorList] = useState('');
  const [valid, setValid] = useState(false);
  const inputFileRef = useRef();
  const isMounted = useMountedState();

  const handleChange = useCallback(
    e => {
      const file = e.target.files[0];
      if (!file) return
      if (!EXCEL_FORMATS.includes(file.type)) {
        setError('Invalid File Format')
        return
      }

      setFilename(file.name)
      setIsLoading(true)

      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = e => {
          let wb;
          try {
            wb = XLSX.read(e.target.result, { type: "buffer" })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_json(ws)
            setError('')
            setCurrentFile(file)
            setValid(false)
            setErrorList('')
            resolve(data)
          } catch (err) {
            reject(err);
          }
        }
        reader.onerror = err => reject(err)
      })

      promise
        .then(v => { if (isMounted()) setItems(v) })
        .catch(err => { if (isMounted()) setError(err.message) })
        .finally(() => { if (isMounted()) setIsLoading(false) })
    },
    [isMounted]
  )

  const handleRemove = useCallback(
    () => {
      setCurrentFile('')
      setItems([])
      setFilename('')
      setError('')
      setErrorList('')
      setValid(false);
      inputFileRef.current.value = ''
    },
    []
  )

  const onValidate = useCallback(
    () => {
      if (!currentFile) {
        setError('File is required')
        return
      }

      setError('')
      setIsLoading(true)

      dispatch({ type: null, call: injectionBulkingApi.validate, args: [currentFile] })
        .then(() => {
          if (isMounted()) {
            setErrorList('');
            setValid(true);
          }
        })
        .catch(({ message, error }) => {
          if (isMounted()) {
            setValid(false);
            if (Array.isArray(message) && message?.length) {
              const arr = message.map(v => v.data.map(el => `No. ${el.index}, ${el.morph}, ${v.message}`))
              const joinedArray = arr.flat();

              setErrorList(joinedArray);
            }
          }
        })
        .finally(() => { if (isMounted()) setIsLoading(false) })
    },
    [currentFile, dispatch, isMounted],
  )

  const showFileUpload = useCallback(
    () => inputFileRef?.current?.click(),
    []
  )

  const onSubmit = useCallback(
    (values) => {
      if (!currentFile || !valid) return

      setIsLoading(true)
      const data = {
        file: currentFile,
        ...values
      }

      dispatch({ module: INJECTION_BULKING_MODULE, type: CREATE, call: injectionBulkingApi.create, args: [data] })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) })
    },
    [currentFile, valid, dispatch, isMounted]
  )

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <div>
          <a
            className='btn btn-warning mb-2'
            href='/injection-bulking-template-new.xlsx'
            download='injection-bulking-template-new.xlsx'
            target='_blank'
          >
            Download Template
          </a>
          <div className={`mb-3 ${styles.uploadButtonWrapper}`}>
            <button type="button" className="btn btn-light btn-icon" onClick={showFileUpload}>
              <i className="btn-icon-wrapper pe-7s-upload" />
              <span>{filename ? filename : 'No File Selected'}</span>
            </button>
            {error && <em className="error invalid-feedback d-block">{error}</em>}
            <input
              type="file"
              ref={inputFileRef}
              onChange={handleChange}
              accept={EXCEL_FORMATS.join(', ')}
            />
          </div>
          {
            valid && <Form onSubmit={onSubmit} />
          }
          {
            Boolean(items.length) &&
            <button type="button" onClick={handleRemove} className="btn btn-danger mr-1">Remove</button>
          }
          {Boolean(currentFile) ?
            <button
              type="button"
              disabled={valid}
              className={`btn ${valid ? 'btn-success' : 'btn-primary'}`}
              onClick={onValidate}
            >
              {
                valid ?
                  <>
                    <CheckIcon fontSize="small" /><span className="ml-1">Validated</span>
                  </>
                  :
                  'Validate'
              }
            </button>
            : null
          }
          {
            valid &&
            <button
              type="submit"
              form={formId}
              className="ml-1 btn btn-primary"
              disabled={!valid}
            >
              Submit
            </button>
          }
        </div>
        <hr></hr>
        <ListErrors
          errors={errorList}
        />
        <div>
          {
            Boolean(items.length) ?
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    {
                      Object.keys(items[0]).map((el, i) => (
                        <TableCell key={i}>{el}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      {
                        Object.keys(item).map((el, i) => (
                          <TableCell key={i}>{item[el]}</TableCell>
                        ))
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              :
              <div className="text-center mb-3">No Data</div>
          }
        </div>
        <LoadingBlocker in={isLoading} />
      </div>
    </div >
  )
}

const schema = yup.object().shape({
  description: yup.string()
});

const Form = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <HookTextField
        ref={register}
        label="Description"
        name="description"
      />
    </form>
  )
}

export default InjectTab;
