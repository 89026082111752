import React, { useMemo, useState } from 'react'
import { AutoSizer } from 'react-virtualized'
import HookTextField from '../../../../../components/Forms/HookTextField'
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker'
import VirtualTable from '../../../../../components/VirtualTable'
import styles from './staff.module.scss'

const filter = (data, search, column = []) => data.filter(({ user }) => column.some(el => String(user[el]).toLowerCase().includes(search.toLowerCase())));
const column = ['uniqueId', 'fullName', 'email', 'phoneNumber']

const ListStaff = ({
  staff = [],
  loading,
  selectedStaffs,
  setSelectedStaffs
}) => {
  const [search, setSearch] = useState('')
  const filteredStaff = useMemo(() => search ? filter(staff, search, column) : staff, [staff, search])

  return (
    <div className={styles.tableWrapper}>
      <div className="d-flex justify-content-between flex-wrap">
        <h5 className="card-title">List Staff</h5>
        <HookTextField
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          className={styles.searchInput}
          prepend={<i className="pe-7s-search"></i>}
        />
      </div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width, border: '1px solid #E5E5EA', position: 'relative' }}>
            <LoadingBlocker in={loading} />
            <VirtualTable
              rowHeight={30}
              tableHeight={240}
              data={filteredStaff}
              components={{
                RowList: {
                  component: CustomRowList,
                  props: {
                    setSelectedStaffs
                  }
                }
              }}
              columns={[
                {
                  render: (_, dataRow) => (
                    <input
                      type="checkbox"
                      className="form-check-input position-static m-0"
                      checked={Boolean(selectedStaffs[dataRow.user._id])}
                      onChange={() => { }}
                    />
                  )
                },
                { title: 'Dipay ID', key: 'user.uniqueId' },
                { title: 'Full Name', key: 'user.fullName' },
                { title: 'Email', key: 'user.email' },
                { title: 'Phone Number', key: 'user.phoneNumber' }
              ]}
            />
          </div>
        )}
      </AutoSizer>
    </div >
  )
}

const CustomRowList = ({ children, setSelectedStaffs, rowData }) => {
  return (
    <tr
      className={styles.rowList}
      onClick={() => setSelectedStaffs(prev => ({ ...prev, [rowData.user._id]: !prev[rowData.user._id] ? rowData.user._id : false }))}
    >
      {children}
    </tr>
  )
}

export default ListStaff
