import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { promoConfigApi } from '../../../services/promoConfigApi';
import { CREATE, PROMO_CONFIG_MODULE } from '../../../utils/constants/actionTypes';
import Form from './Form';

const CreateTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const submit = (values) => {
    setIsLoading(true);

    const data = {
      ...values,
      maxAmount: values.subType === "FIXED" ? values.value : values.maxAmount
    }

    dispatch({
      module: PROMO_CONFIG_MODULE,
      type: CREATE,
      call: promoConfigApi.create,
      args: [data]
    })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return (
    <Form
      onSubmit={submit}
      isLoading={isLoading}
    />
  )
}

export default CreateTab
