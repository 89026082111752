import React from 'react'
import EvyTextField from '../../../../components/Forms/EvyTextField'
import IdentityImage from '../../../../components/IdentityImage'
import { combineBy } from '../../../../utils/helpers/combineBy'
import { format } from 'date-fns';

const IdentityCard = ({ item }) => {
  return (
    <div>
      <EvyTextField
        readOnly
        row
        label="Identification Number"
        value={item.archive.identityCard.identificationNumber}
        helperText={(
          <div>
            Approved by: <b>{item.archive.identityCard.checker ? combineBy([item.archive.identityCard.checker.firstName, item.archive.identityCard.checker.lastName]) + ' | ' + item.archive.identityCard.checker.email : ''}</b>
            <br />
            At: <b>{format(new Date(item.archive.identityCard.updatedAt), 'yyyy-MM-dd HH:mm:ss')}</b>
          </div>
        )}
      />
      <div className="form-group row">
        <label className="col-form-label col-sm-3 text-md-right">Card Photo</label>
        <div className="col-sm-9">
          <IdentityImage variant="grey" image={item?.archive?.identityCard?.cardPhoto?.url} />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-3 text-md-right">Self Photo</label>
        <div className="col-sm-9">
          <IdentityImage variant="grey" image={item?.archive?.identityCard?.selfPhoto?.url} />
        </div>
      </div>
    </div>
  )
}

export default IdentityCard
