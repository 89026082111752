import React, { useMemo } from "react";
import { format } from "date-fns";
import { endOfDay, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import Resource from "../../../components/Resource";
import { TRANSACTIONS_TOPUP_TRANSFER_PARTNER_MODULE } from "../../../utils/constants/actionTypes";
import AllStatus from "../../../utils/constants/enums/status";
import { combineBy } from "../../../utils/helpers/combineBy";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { transactionApi } from "../../../services/transactionApi";
import { toIDR } from "../../../utils/helpers/currency";
import DetailTab from './DetailTab'
import { DATE_PICKER, SELECT_FIELD } from "../../../utils/constants/inputTypes";
import { partnerTypes, partnerTypesOpts } from "../../../utils/constants/enums/partnerTypes";

const Index = () => {
  const {
    currentPage,
    limit,
    sort,
    filter,
    totalTransfer
  } = useSelector(state => state.transactions.topupTransferPartner);

  const {
    BCA, BNI, BRI, Mandiri, CIMB, Others, totalAmount
  } = totalTransfer

  const getTopupTransferPartnerDebounced = useMemo(
    () => AwesomeDebouncePromise(transactionApi.topUpTransferIntegration, 500),
    [],
  );

  const columns = [
    { title: 'Created At', key: 'createdAt', name: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Code', key: 'customerNumber', name: 'customerNumber' },
    { title: 'Description', key: 'topup.description', name: 'topup.description' },
    { title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Amount', key: 'topup', name: 'topup', render: v => v?.amount ? toIDR(v.amount) : '-' },
    { title: 'Client', key: 'clientStr', name: 'clientStr', render: v => partnerTypes.getStr(v) },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) }
  ];

  const apiQuery = useMemo(() => {
    const clientStr = filter.clientStr || partnerTypes.getAllType()  /*"INDOFUND,INKOPPOL_INDONESIA"*/
    const status = filter.status
    const startDate = filter.startDate ? startOfDay(new Date(filter.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    const endDate = filter.endDate ? endOfDay(new Date(filter.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;

    return {
      page: currentPage,
      limit,
      sort,
      clientStr,
      status,
      startDate,
      endDate
    }
  }, [currentPage, limit, sort, filter])

  const excelColumns = useMemo(() => [
    { title: 'Created At', key: 'createdAt', render: v => format(new Date(v), 'yyyy-MM-dd HH:mm:ss') },
    { title: 'Code', key: 'customerNumber', name: 'customerNumber' },
    { title: 'Description', key: 'topup.description', name: 'topup.description' },
    { title: 'Applicant', key: 'applicant', name: 'applicant', sortable: false, render: v => `${combineBy([v.firstName, v.lastName])} | ${v.uniqueId}` },
    { title: 'Amount', key: 'topup', name: 'topup', render: v => v?.amount ? toIDR(v.amount) : '-' },
    { title: 'Client', key: 'clientStr', name: 'clientStr', render: v => partnerTypes.getStr(v) },
    { title: 'Status', key: 'status', name: 'status', render: v => AllStatus.getStr(v) }
  ], [])

  return (
    <div>
      <Resource
        title="Topup Transfer Partner"
        subTitle="Full Topup Transfer Partner List."
        icon="pe-7s-news-paper"
        list={{
          search: true,
          columns: columns,
          reducerPath: "transactions.topupTransferPartner",
          call: getTopupTransferPartnerDebounced,
          apiQuery: apiQuery,
          module: TRANSACTIONS_TOPUP_TRANSFER_PARTNER_MODULE,
          renderTotal: () => (
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-0">
                Transaksi BCA sebanyak: <b>{BCA.count}</b>, senilai: <b>{toIDR(BCA.amount)}</b>
              </li>
              <li className="list-group-item px-0">
                Transaksi BNI sebanyak: <b>{BNI.count}</b>, senilai: <b>{toIDR(BNI.amount)}</b>
              </li>
              <li className="list-group-item px-0">
                Transaksi BRI sebanyak: <b>{BRI.count}</b>, senilai: <b>{toIDR(BRI.amount)}</b>
              </li>
              <li className="list-group-item px-0">
                Transaksi Bank Mandiri sebanyak: <b>{Mandiri.count}</b>, senilai: <b>{toIDR(Mandiri.amount)}</b>
              </li>
              <li className="list-group-item px-0">
                Transaksi CIMB sebanyak: <b>{CIMB.count}</b>, senilai: <b>{toIDR(CIMB.amount)}</b>
              </li>
              <li className="list-group-item px-0">
                Transaksi Bank Lain sebanyak: <b>{Others.count}</b>, senilai: <b>{toIDR(Others.amount)}</b>
              </li>
              <li className="list-group-item px-0">
                Total Amount: <b>{toIDR(totalAmount)}</b>
              </li>
            </ul>
          ),
          excel: {
            columns: excelColumns,
            filename: 'Top_Up_By_Partner_List',
            apiResponseKey: 'data.transactions',
            queryParams: apiQuery
          },
          filters: [
            {
              label: "Filter Client",
              type: SELECT_FIELD,
              key: "clientStr",
              options: partnerTypesOpts,
              value: filter.clientStr,
            },
            {
              label: "Start Date",
              type: DATE_PICKER,
              key: "startDate",
              value: filter.startDate,
              placeholder: "Select date"
            },
            {
              label: "End Date",
              type: DATE_PICKER,
              key: "endDate",
              value: filter.endDate,
              placeholder: "Select date"
            },
            {
              label: "Filter Status",
              type: SELECT_FIELD,
              key: "status",
              options: [
                { value: "COMPLETED", label: "Completed" },
                { value: "NOT_COMPLETED", label: "Not Completed" },
              ],
              value: filter.status,
            },
          ]
        }}
        detail={{
          component: DetailTab
        }}
      />
    </div>
  );
};

export default Index;
