import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NAVMENU, NAVLINK } from '../utils/constants/dataTypes';
import { useLocation } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import roleHelper from '../utils/helpers/roleHelper';
import { useNotification } from './HooksUse/useNotification';
import { EventType } from '../utils/constants/enums/eventTypes';

const Routes = ({ routes, admin, socket, className, setParentHasNews }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const haveChildPermissions = (role, route) => {
    const { children } = route;
    if (children && children.length) {
      return children.find(c => roleHelper.hasAccess(role, c.routeTitle));
    } else {
      return false
    }
  }

  return (
    <ul className={className}>
      {routes.map((row, k) =>
        (roleHelper.hasAccess(admin.role, row.routeTitle) || haveChildPermissions(admin.role, row)) &&
        <Route
          key={k}
          index={k}
          route={row}
          openIndex={openIndex}
          setOpenIndex={setOpenIndex}
          admin={admin}
          socket={socket}
          setParentHasNews={setParentHasNews}
        />
      )}
    </ul>
  )
}

const Route = ({ route, index, openIndex, setOpenIndex, admin, socket, setParentHasNews }) => {
  const location = useLocation();
  const [hasNews, setHasNews] = useState(false);
  const [notify] = useNotification('info', true, { autoClose: false });
  const open = index === openIndex;
  const match = route.url === location.pathname;

  const handleClick = () => {
    if (route.type === NAVMENU) setOpenIndex(open ? null : index);
  }

  useEffect(() => {
    if (route.type === NAVMENU && route.children.find(c => c.url === location.pathname)) setOpenIndex(index);
  }, [location.pathname, index, route.children, route.type, setOpenIndex])

  useEffect(() => {
    const eventHandler = (routeEvent) => (data) => {
      notify(EventType.getMsg(routeEvent || route.eventType));
      setHasNews(true);
    }

    if (route.eventType && typeof socket !== 'undefined') Array.isArray(route.eventType) ?
      route.eventType.forEach(event => {
        socket.on(event, eventHandler(event))
      })
      : socket.on(route.eventType, eventHandler());

    return () => {
      if (route.eventType && typeof socket !== 'undefined') Array.isArray(route.eventType) ?
        route.eventType.forEach(event => {
          socket.off(event, eventHandler(event))
        })
        : socket.off(route.eventType, eventHandler());
    }
  }, [notify, socket, route.eventType]);

  useEffect(() => {
    if (hasNews) setHasNews(false);
  }, [match, hasNews])

  useEffect(() => {
    if (typeof setParentHasNews === 'function') setParentHasNews(hasNews);
  }, [hasNews, setParentHasNews])

  return (
    route.type === NAVMENU || route.type === NAVLINK
      ?
      <li className={`nav-menu${open || match ? ' mm-active' : ''}${hasNews ? ' has-news' : ''}`}>
        <NavLink
          onClick={handleClick}
          exact={route.exact ? true : false}
          to={route.url || '#'}
          activeClassName={route.type === NAVLINK ? "mm-active" : ''}
          aria-expanded={(route.type === NAVMENU && open) || match ? 'true' : 'false'}
        >
          <i className={`metismenu-icon ${route.icon}`}></i>{route.name}
          {route.type === NAVMENU &&
            <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
          }
        </NavLink>
        {route.type === NAVMENU &&
          <Collapse in={openIndex === index}>
            <Routes
              className="mm-collapse mm-show"
              routes={route.children}
              admin={admin}
              socket={socket}
              setParentHasNews={(hasNews) => setHasNews(hasNews)}
            />
          </Collapse>
        }
      </li>
      :
      <li className="app-sidebar__heading">{route.name}</li>
  )
}

export default Routes;
