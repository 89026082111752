import React from 'react'
import { useQuery } from "../../utils/helpers/useQueryHelper"
const Preview = () => {
  const query = useQuery()
  const imageURL = query.get("url")
  return (
    <div>
      <img src={imageURL} alt="preview"></img>
    </div>
  )
}

export default Preview
