import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { isBefore, addMonths, isEqual, format, endOfDay, endOfMonth } from 'date-fns';
import LoadingBlocker from '../../../../../components/Loading/LoadingBlocker';
import DateFilter from '../../../../Dashboard/DateFilter';
import { GET_GRAPH_DATA, SET_GRAPH_DATA } from '../../../../../utils/constants/actionTypes';
import { toIDR } from '../../../../../utils/helpers/currency';

const chartOptions = {
  tooltips: {
    mode: 'index',
    intersect: false,
    xPadding: 12,
    yPadding: 12,
    backgroundColor: '#fff',
    titleFontColor: '#000',
    titleFontSize: 14,
    titleMarginBottom: 10,
    bodyFontColor: 'rgba(0, 0, 0, 0.8)',
    bodySpacing: 6,
    bodyFontSize: 14,
    borderColor: '#dee2e6',
    borderWidth: 1,
  },
  layout: {
    padding: 5,
  },
  legend: {
    display: false
  },
  scales: {
    yAxes: [{
      display: false,
    }],
    xAxes: [{
      display: false
    }],
  },
  maintainAspectRatio: false
}

const TransactionGraphCard = ({
  title: {
    en,
    id
  },
  filter,
  inProgress,
  data,
  call,
  objectName,
  children,
  merchantId,
  typeProperty: {
    avgProp,
    resultProp,
    totalProp
  },
}) => {
  const dispatch = useDispatch();
  const [avg, setAvg] = useState('')
  const [total, setTotal] = useState('')

  const getGraphData = useCallback(() => {
    const args = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      merchantId: merchantId
    }
    dispatch({
      type: GET_GRAPH_DATA,
      objectName: objectName,
      call: call,
      args: [args]
    })
      .then(({ data }) => {
        setAvg(data?.[avgProp])
        setTotal(data?.[totalProp])
        const rawData = data[resultProp]
        let labels = [];
        let graphData = [];
        let startDate = new Date(filter.fromDate)
        let endDate = new Date(filter.toDate)

        while (isBefore(startDate, endDate) || isEqual(endOfDay(endOfMonth(startDate)), endDate)) {
          const l = format(startDate, 'MMMM yyyy')
          const d = rawData.find(r => format(new Date(r._id.year, (r._id.month - 1)), 'MMMM yyyy') === l)

          labels.push(l);
          graphData.push(d?.[totalProp] || 0);
          startDate = addMonths(startDate, 1)
        }

        dispatch({
          type: SET_GRAPH_DATA,
          labels: labels,
          graphData: graphData,
          objectName: objectName,
        })
      })
  }, [dispatch, filter, call, objectName, merchantId, avgProp, resultProp, totalProp])

  useEffect(() => {
    getGraphData();
  }, [getGraphData])

  return (
    <div className="mb-3">
      <div>
        <h5 className="card-title m-0">{en}</h5>
        <div className="row mt-3">
          <DateFilter
            objectName={objectName}
            fromDate={filter?.fromDate}
            toDate={filter?.toDate}
            module={module}
          />
        </div>
        {children}
        <div className="mb-5" >
          <Line
            data={data}
            height={245}
            options={chartOptions}
          />
        </div>
        <h5 className="card-title m-0">Average Monthly {en}</h5>
        <span className="font-italic text-grey">rata-rata total {id} bulanan</span>
        <div className="row">
          <div className="col-8">
            <div className="widget-content p-0">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-numbers text-dark">
                      {resultProp === "resultAmount" && toIDR(Math.round(avg))}
                      {resultProp === "resultTotal" && Math.round(avg)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget-progress-wrapper mt-1">
                <div className="progress-bar-xs progress-bar-animated-alt progress">
                  <div className="progress-bar bg-info" role="progressbar"></div>
                </div>
                <div className="progress-sub-label">
                  <div className="sub-label-left font-size-md">Total {en}</div>
                  <div className="sub-label-right font-size-md">
                    {resultProp === "resultAmount" && toIDR(total)}
                    {resultProp === "resultTotal" && total}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingBlocker in={inProgress} />
      </div>
    </div>
  )
}

export default TransactionGraphCard
