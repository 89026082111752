import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import getPropByString from '../../utils/helpers/getPropByString';
import LoadingBtn from '../Forms/LoadingBtn';
import Pagination from './Pagination';
import Reload from './Reload';
import Search from './Search';
import Show from './Show';
import StickyScrollbar from './StickyScrollbar';
import styles from './table.module.scss';
import Th from './Th';
import ExportExcel from './ExportExcel';

const Table = ({
  table,
  children,
  onChangeShow,
  onSearch,
  onReload,
  onSort,
  onChangePage,
  renderTotal,
  searchValue,
  onClearSearch,
  excel,
  customExportExcel,
  customTopRightTable,
  customTopLeftTable
}) => {
  const tableRef = useRef(null)
  const divTableRef = useRef(null)
  const searchable = table.columns.filter(o => o.searchable).map(o => o.key);
  const [selectedRow, setSelectedRow] = useState([]);
  const [modTableData, setModTableData] = useState([]);
  const [collapseKeys, setCollapseKeys] = useState([]);

  const setCollapse = (key) => {
    const shownState = Array.from(collapseKeys);
    const index = shownState.indexOf(key);

    if (index >= 0) {
      shownState.splice(index, 1)
      setCollapseKeys(shownState)
    } else {
      shownState.push(key)
      setCollapseKeys(shownState)
    }
  }

  useEffect(() => {
    if (table?.collapse) {
      setModTableData(table.data.map((row, i) => {
        return {
          ...row,
          collapseKey: `${table.currentPage}${i}`
        }
      }))
    } else {
      setModTableData(table.data)
    }

  }, [table])

  const onChecked = (id) => {
    if (selectedRow.includes(id)) {
      setSelectedRow(prev => prev.filter(v => v !== id))
    } else {
      setSelectedRow(prev => [...prev, id])
    }
  }

  const handleCheckAll = () => {
    for (let i = 0; i < table?.withCheckbox?.disabledSelected?.length; i++) {
      if (!table?.withCheckbox?.disabledSelected[i]) {
        onChecked(table?.data[i]?._id);
      }
    }
  }

  useEffect(() => {
    if (table?.withCheckbox?.enable) {
      table?.withCheckbox?.callback(selectedRow)
    }
  }, [selectedRow, table?.withCheckbox])

  useEffect(() => {
    if (table?.withCheckbox?.isReset) {
      setSelectedRow([]);
    }
  }, [table?.withCheckbox?.isReset])

  return (
    <div className="table-container">
      <div className="table-child">
        {children}
      </div>
      {
        (
          customTopRightTable ||
          customTopLeftTable ||
          table.search ||
          onReload ||
          excel ||
          table?.withCheckbox?.enable
        ) ?
          <div className="table-control-top mt-3">
            <div className="table-control-top-left mb-3">
              {
                <>
                  {customTopLeftTable}
                  {table?.withCheckbox?.enable ?
                    <>
                      {table?.withCheckbox?.resetAllEnable ?
                        <LoadingBtn
                          className="btn btn-primary mr-3"
                          onClick={() => setSelectedRow([])}
                        >
                          Reset
                        </LoadingBtn>
                        :
                        null
                      }
                      {table?.withCheckbox?.checkAllEnable ?
                        <LoadingBtn
                          className="btn btn-primary"
                          onClick={handleCheckAll}
                        >
                          Check All
                        </LoadingBtn>
                        :
                        null
                      }
                    </>
                    :
                    null
                  }
                </>
              }
            </div>

            <div className="table-control-top-right mb-3">
              {customTopRightTable}
              {table.search ? <Search onChange={onSearch} searchable={searchable} value={searchValue ? searchValue : ""} onClearSearch={onClearSearch} /> : null}
              {onReload ? <Reload onReload={onReload} /> : null}
              {excel ?
                <div className="ml-2">
                  <ExportExcel
                    customExportExcel={customExportExcel}
                    excel={excel}
                    table={table}
                  />
                </div>
                : null}
            </div>
          </div>
          : null
      }
      <div className="table-responsive">
        <div
          className={`${navigator.userAgent.indexOf('Mac OS X') !== -1 ? styles.wrapperTableScrollbarMAC : styles.wrapperTableScrollbar} table-wrapper`}
          id="target-table"
          ref={divTableRef}
        >
          <table className="mb-0 table table-hover table-striped" ref={tableRef}>
            <thead>
              <tr>
                {
                  (table?.collapse && table?.data?.length > 0)
                    ? <th></th>
                    : null
                }
                {table?.withCheckbox?.enable ?
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleCheckAll()
                        } else {
                          setSelectedRow([])
                        }
                      }}
                    />
                  </th>
                  :
                  null
                }
                {
                  table.columns.map((col, key) =>
                    <Th
                      key={key}
                      className={col.className}
                      col={col}
                      sort={table.sort}
                      onSort={onSort}
                    />
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                table?.data?.length > 0 ?
                  modTableData.map((row, key) => {
                    return (
                      <Fragment key={key}>
                        <tr
                          style={{ cursor: table?.withCheckbox?.enable ? 'pointer' : '' }}
                          onClick={table?.withCheckbox?.enable && !(table?.withCheckbox?.disabledSelected[key]) ? () => onChecked(getPropByString(row, table?.withCheckbox?.key), row) : null}
                        >
                          {table?.withCheckbox?.enable ?
                            <td>
                              <input
                                type="checkbox"
                                onChange={() => onChecked(getPropByString(row, table?.withCheckbox?.key), row)}
                                disabled={table?.withCheckbox?.disabledSelected[key]}
                                checked={selectedRow.includes(getPropByString(row, table?.withCheckbox?.key))}
                              />
                            </td>
                            :
                            null
                          }
                          {table?.collapse ? table.collapse.render(getPropByString(row, table.collapse.key), row) ?
                            <td onClick={() => setCollapse(row?.collapseKey)}>
                              {collapseKeys.includes(row?.collapseKey) ?
                                <FontAwesomeIcon icon={faChevronUp} /> :
                                <FontAwesomeIcon icon={faChevronDown} />
                              }
                            </td> :
                            <td></td> : null
                          }
                          {table.columns.map((col, i) =>
                            typeof col.render === "function" ?
                              <td
                                className={col.className}
                                key={i}
                              >
                                {col.render(getPropByString(row, col.key), row)}
                              </td>
                              :
                              <td key={i}>
                                {getPropByString(row, col.key)}
                              </td>
                          )}
                        </tr>
                        {collapseKeys.includes(row?.collapseKey) && table.collapse.render(getPropByString(row, table.collapse.key), row) &&
                          <tr className={styles.collapse}>
                            <td colSpan={table.columns.length + 1}>
                              {table.collapse.render(getPropByString(row, table.collapse.key), row)}
                            </td>
                          </tr>
                        }
                      </Fragment>
                    )
                  }
                  )
                  :
                  <tr>
                    <td className="text-center" colSpan={table.columns.length}>No Data</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <div className={`table-loading ${table.inProgress ? 'loading' : 'done'}`}></div>
      </div>
      <StickyScrollbar
        tableWidth={tableRef?.current?.clientWidth}
        divRef={divTableRef?.current}
      />
      <div className="mt-3">
        {
          typeof renderTotal === 'function' && renderTotal()
        }
      </div>
      {table.total ?
        <div className="table-control-bottom">
          <Show
            total={table.total}
            show={table.limit}
            onChangeShow={onChangeShow}
            currentPage={table.currentPage}
          />
          <Pagination
            total={table.total}
            limit={table.limit}
            currentPage={table.currentPage}
            onChangePage={onChangePage}
          />
        </div>
        :
        null
      }
    </div>
  )
}

export default Table;
