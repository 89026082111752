class CareerOptsTypes {
  static FULL_TIME = "FULL_TIME";
  static PART_TIME = "PART_TIME";
  static INTERN = "INTERN";

  static getStr(en) {
    switch (en) {
      case CareerOptsTypes.FULL_TIME:
        return "Full Time";
      case CareerOptsTypes.PART_TIME:
        return "Part Time";
      case CareerOptsTypes.INTERN:
        return "Intern";
      default:
        return "Unknown";
    }
  }
}

const CareerOpts = [
  { value: CareerOptsTypes.FULL_TIME, label: CareerOptsTypes.getStr(CareerOptsTypes.FULL_TIME) },
  { value: CareerOptsTypes.PART_TIME, label: CareerOptsTypes.getStr(CareerOptsTypes.PART_TIME) },
  { value: CareerOptsTypes.INTERN, label: CareerOptsTypes.getStr(CareerOptsTypes.INTERN) },
];

export { CareerOptsTypes, CareerOpts }
