import { useState, useEffect, useCallback } from 'react';
import useMountedState from './useMountedState';

const useAsync = (asyncFunction, immediateArgs) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const isMounted = useMountedState();

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback((...args) => {
    setPending(true);
    setValue(undefined);
    setError(undefined);
    return asyncFunction(...args)
      .then(response => {
        if (isMounted()) {
          setValue(response);
          return response
        }
      })
      .catch(error => {
        if (isMounted()) {
          setError(error);
          throw error
        }
      })
      .finally(() => {
        if (isMounted()) setPending(false)
      });
  }, [asyncFunction, isMounted]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediateArgs) execute(immediateArgs);
  }, [execute, immediateArgs]);

  return { execute, pending, value, error };
};

export default useAsync
