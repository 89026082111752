import React from 'react';

const Th = ({ col, sort = "", onSort, className }) => {
  const sortable = col?.sortable === false ? false : true;
  const [key, direction] = sort.split('|');

  const getClass = () => {
    let classList = className ?? '';

    if (sortable) {
      classList += `${classList ? ' ' : ''}sortable`;

      if (col.key === key) {
        classList += ` ${direction}`;
      } else {
        classList += ` both`;
      }
    }

    return classList;
  }

  const doSort = () => {
    if (!sortable) return

    let v = `${col.key}|`;
    let newDirection = '';

    if (key === col.key) {
      newDirection = direction === 'asc' ? 'desc' : 'asc';
    } else {
      newDirection = 'asc';
    }
    v += newDirection;
    onSort(v)
  }

  return (
    <th
      name={col.name}
      onClick={doSort}
      className={getClass()}
    >
      {col.title}
    </th>
  )
}

export default Th
