import React, { Component } from 'react';
import { connect } from 'react-redux';
import EvySelectField from '../Forms/EvySelectField';
import Collapse from '@material-ui/core/Collapse';
import './select.scss';
import CitySelect from './CitySelect';
import { components } from 'react-select';
import { businessesApi } from '../../services/businessesApi';

const mapDispatchToProps = dispatch => ({
  onLoadData: (page, limit, sort, search) =>
    dispatch({
      module: null,
      type: null,
      call: businessesApi.list,
      args: [
        {
          page,
          limit,
          sort,
          search
        }
      ]
    }),
});

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: 300,
    overflowY: 'auto'
  })
};

class BusinessSelect extends Component {
  state = {
    sort: 'name|asc',
    limit: 10,
    page: 1,
    options: [],
    search: '',
    isLoading: false,
    totalItems: 0,
    selectedOption: null,
    filter: {
      city: null
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.search !== prevState.search ||
      JSON.stringify(this.state.filter) !== JSON.stringify(prevState.filter)
    ) this.loadData();
  }

  loadData = () => {
    const { limit, sort, search, filter } = this.state;
    const page = 1;

    this.setState({
      page: page,
      options: [],
      isLoading: true
    });

    this.props.onLoadData(page, limit, sort, this.generateSearchQuery(search, filter))
      .then(res => {
        const { customOptions = [] } = this.props;
        const businesses = res.data.businesses;
        const totalData = res.data.count;
        const dataOpts = this.generateOpts(businesses);
        const newOpts = [...customOptions, ...dataOpts];

        this.setState({
          options: newOpts,
          totalItems: totalData
        })
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  loadMoreData = () => {
    const { page, limit, sort, search, totalItems, filter } = this.state;

    if ((page * limit) < totalItems) {
      const nextPage = this.state.page + 1;

      this.setState({
        page: nextPage,
        isLoading: true
      });

      this.props.onLoadData(nextPage, limit, sort, this.generateSearchQuery(search, filter))
        .then(res => {
          const businesses = res.data.businesses;
          const totalData = res.data.count;
          const dataOpts = this.generateOpts(businesses);
          const newOpts = [...this.state.options, ...dataOpts];
          this.setState({
            options: newOpts,
            totalItems: totalData
          })
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  generateOpts = datas => datas.map(row => ({
    value: row._id,
    label: row.name + ' | ' + row.cityName + ' | ' + row.phoneNumber,
    model: 'BUSINESS'
  }))

  generateSearchQuery = (search, filter) => {
    let list = [];
    if (search) list.push(`name|${search}`);
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) list.push(`${key}|${filter[key]}`);
      });
    };
    return list.join();
  }

  handleSelectChange = opt => {
    const { onChange, value } = this.props;

    this.setState({
      selectedOption: opt,
      search: ''
    });

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  handleInputChange = v => {
    this.setState({
      search: v
    })
  }

  onFilterChange = (type, val) => {
    this.setState({
      filter: {
        [type]: val
      }
    });
  }

  onSelectAll = () => {
    const { page, sort, search, filter } = this.state;
    const limit = 0;

    this.setState({
      options: [],
      isLoading: true,
    });

    this.props.onLoadData(page, limit, sort, this.generateSearchQuery(search, filter))
      .then(res => {
        const { customOptions = [], onChange, value } = this.props;
        const businesses = res.data.businesses;
        const totalData = res.data.count;
        const dataOpts = this.generateOpts(businesses);
        const newOpts = [...customOptions, ...dataOpts];

        this.setState({
          page: totalData >= 10 ? Math.ceil(totalData / 10) : 1,
          selectedOption: dataOpts,
          options: newOpts,
          totalItems: totalData
        });

        if (typeof onChange === 'function') {
          onChange(typeof value !== 'undefined'
            ? dataOpts
            : []
          );
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { error, helperText, value, withFilter, isMulti, closeMenuOnSelect, enableSelectAll } = this.props;
    const { options, search, isLoading, selectedOption } = this.state;

    return (
      <EvySelectField
        label="Select Business"
        id="selectBusinesses"
        options={options}
        onChange={opt => this.handleSelectChange(opt)}
        value={typeof value !== 'undefined' ? value : selectedOption}
        advanceSelect
        componentProps={{
          placeholder: 'Type anything..',
          inputValue: search,
          onInputChange: newValue => this.handleInputChange(newValue),
          isClearable: true,
          isLoading: isLoading,
          onMenuScrollToBottom: this.loadMoreData,
          isMulti: isMulti,
          closeMenuOnSelect: closeMenuOnSelect,
          components: { MenuList },
          enableSelectAll: enableSelectAll,
          onSelectAll: this.onSelectAll,
          styles: customStyles
        }}
        error={error}
        helperText={helperText}
        formFieldAppend={withFilter && <Filter onFilterChange={this.onFilterChange} />}
      />
    )
  }
}

class Filter extends Component {
  state = {
    open: false,
  }

  handleFilterBtnClick = e => {
    this.setState({
      open: !this.state.open
    });
  }

  handleChange = type => val => {
    this.props.onFilterChange(type, val);
  }

  render() {
    const { open } = this.state;

    return (
      <div>
        <Collapse in={open}>
          <div className="filter-wrapper">
            <h5 className="card-title text-center">FILTER</h5>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <CitySelect
                  label="City"
                  onChange={this.handleChange('city')}
                />
              </div>
            </div>
          </div>
        </Collapse>
        <div className={`filter-button${open ? ' open' : ''}`} onClick={this.handleFilterBtnClick}>
          <p className="mb-0 line-height-1">Filter</p>
          <i className="lnr-chevron-down"></i>
        </div>
      </div>
    )
  }
}

const MenuList = props => {
  const handleClick = e => {
    props.selectProps.onSelectAll();
  }

  return (
    <components.MenuList {...props}>
      {props.selectProps.enableSelectAll && props.selectProps.value.length < props.options.length ?
        <div className="select-all-option" onClick={handleClick}>Select All</div>
        : ''
      }
      {props.children}
    </components.MenuList>
  );
};

// const CustomOption = props => {
//   const { innerProps, isFocused, ...otherProps } = props;
//   const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
//   const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
//   return (
//     <components.Option {...newProps} className="custom-option">{props.children}
//     </components.Option>
//   );
// }

export default connect(null, mapDispatchToProps)(BusinessSelect)
