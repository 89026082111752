import React, { useState } from 'react'
import EvyTextField from '../../../../components/Forms/EvyTextField'
import LoadingBtn from '../../../../components/Forms/LoadingBtn'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { userApi } from '../../../../services/userApi';

const VACard = ({ item, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const registerVa = () => {
    setIsLoading(true);
    dispatch({ type: null, call: userApi.registerVa, args: [item._id] })
      .then(res => {
        onSuccess();
      })
      .catch(err => {
        toast("Register Virtual Account Failed", { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      {item.virtualAccounts.length ? (
        item.virtualAccounts.map((item, key) =>
          <EvyTextField
            key={key}
            readOnly
            row
            label={item.bankCode}
            value={item.number}
          />
        )
      ) : ''}
      <div>
        <LoadingBtn
          type="button"
          className="btn btn-primary btn-block btn-loading"
          loading={isLoading}
          onClick={registerVa}
        >
          Register VA
        </LoadingBtn>
      </div>
    </div>
  )
}

export default VACard
