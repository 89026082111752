import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '../../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MAIN_TAB_LOAD, SORT_TABLE, LIMIT_TABLE, SET_PAGE_TABLE, CONFIG_MODULE, CREATE_TAB, FILTER_TABLE, REMOVE_DELETE_DATA, ADD_DELETE_DATA } from '../../../utils/constants/actionTypes';
import { configApi } from '../../../services/configApi';
import ButtonDispatch from '../../../components/ButtonDispatch';

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    currentPage,
    limit,
    sort,
    inProgress,
    items,
    total,
    deleteRowId,
    filter
  } = useSelector(state => state.config);

  const load = useCallback(
    () => {
      const search = filter.search ? `name|${filter.search},value|${filter.search}` : null;

      dispatch({
        module: CONFIG_MODULE,
        type: MAIN_TAB_LOAD,
        call: configApi.list,
        args: [{
          page: currentPage,
          limit,
          sort,
          search
        }]
      });
    },
    [currentPage, limit, sort, dispatch, filter.search],
  )

  const createTab = useCallback((key, title, tabType) => {
    dispatch({
      module: CONFIG_MODULE,
      type: CREATE_TAB,
      key,
      title,
      tabType
    });
  }, [dispatch])

  const setTableConfig = type => (val, key) => {
    dispatch({
      module: CONFIG_MODULE,
      type: type,
      value: val,
      key: key
    });
  }

  const onFilter = key => val => {
    setTableConfig(FILTER_TABLE)(val, key)
  }

  const onDelete = useCallback((id) => {
    dispatch({
      type: null,
      call: configApi.delete,
      args: [id]
    }).then(() => load())
  }, [dispatch, load])

  const table = useMemo(() => ({
    inProgress: inProgress,
    data: items,
    sort: sort,
    limit: limit,
    total: total,
    currentPage: currentPage,
    search: true,
    columns: [
      { title: 'Name', key: 'name', name: 'name' },
      { title: 'Value', key: 'value', name: 'value' },
      { title: 'Description', key: 'description', name: 'description' },
      {
        title: 'Updated At', key: 'updatedAt', name: 'updatedAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd HH:mm');
        }
      },
      {
        title: 'Created At', key: 'createdAt', name: 'createdAt', render: function (v, row) {
          return format(new Date(v), 'yyyy-MM-dd')
        }
      },
      {
        title: 'Action', key: '_id', name: 'action', className: 'action-column text-center', sortable: false, render: (v, row) => {
          return (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-icon btn-icon-only btn-sm btn-primary"
                onClick={() => createTab(v, row.name)}
              >
                <i className="pe-7s-tools btn-icon-wrapper"></i>
              </button>
              <div className="btn-group ml-2">
                {deleteRowId === v ? (
                  <>
                    <ButtonDispatch
                      type={REMOVE_DELETE_DATA}
                      module={CONFIG_MODULE}
                      className="btn btn-sm btn-danger lh-1"
                    >
                      No
                    </ButtonDispatch>
                    <button
                      onClick={() => onDelete(v)}
                      type="button"
                      className="btn btn-sm btn-danger lh-1 py-0"
                    >
                      Yes, Delete
                    </button>
                  </>
                ) : (
                  <ButtonDispatch
                    className="btn btn-icon btn-icon-only btn-sm btn-danger"
                    args={{ _id: v }}
                    module={CONFIG_MODULE}
                    type={ADD_DELETE_DATA}
                  >
                    <i className="pe-7s-trash btn-icon-wrapper"></i>
                  </ButtonDispatch>
                )}
              </div>
            </div>
          )
        }
      }
    ]
  }), [createTab, currentPage, deleteRowId, inProgress, items, limit, onDelete, sort, total])

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="main-card mb-3 card">
      <div className="card-body">
        <h5 className="card-title">Data List</h5>
        <Table
          table={table}
          onReload={load}
          onSort={setTableConfig(SORT_TABLE)}
          onChangeShow={setTableConfig(LIMIT_TABLE)}
          onChangePage={setTableConfig(SET_PAGE_TABLE)}
          onSearch={onFilter('search')}
          onClearSearch={() => onFilter('search')('')}
          searchValue={filter.search}
        />
      </div>
    </div>
  )
}

export default MainTab
