import React, { useCallback, useEffect, useRef, useState } from 'react';
import SelectField from '../../../components/Forms/SelectField';
import { productOpts } from '../../../utils/constants/enums/productOptsTypes';
import styles from './position.module.scss';
import { UserAppOpts } from '../../../utils/constants/enums/userAppTypes';
import Card from './Card';
import EmptyState from './EmptyState';
import { ARTICLE_MODULE, FILTER_POSITION, POSITION_TAB_LOAD } from '../../../utils/constants/actionTypes';
import { articleApi } from '../../../services/articleApi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Position = () => {
  const [data, setData] = useState([]);
  const [currentId, setCurrentId] = useState("");

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dispatch = useDispatch();

  const {
    datas,
    filterPosition: {
      type,
      application,
      category,
      active
    } } = useSelector(state => state.articles);

  const load = useCallback(
    () => {
      dispatch({
        module: ARTICLE_MODULE,
        type: POSITION_TAB_LOAD,
        call: articleApi.list,
        args: [{
          application,
          type,
          active,
          category,
          sort: 'position|asc'
        }]
      });
    },
    [
      dispatch,
      application,
      type,
      active,
      category
    ]
  )

  useEffect(() => {
    if (application && (type || category)) {
      setData([])
      load()
    }
  }, [application, type, category, load])

  useEffect(() => {
    if (datas?.length) {
      setData(datas)
    }
  }, [datas])

  const handleSort = useCallback((id) => {
    let _items = [...data];
    const draggedOverItemIndex = dragOverItem.current;
    const draggedItemIndex = dragItem.current;

    if (draggedOverItemIndex !== undefined && draggedItemIndex !== undefined) {
      // Swap items in the array
      const draggedOverItem = _items[draggedOverItemIndex];
      const draggedItem = _items[draggedItemIndex];

      // Swap the positions of the dragged items
      _items[draggedOverItemIndex] = draggedItem;
      _items[draggedItemIndex] = draggedOverItem;

      // Update the positions in the database
      if (draggedOverItemIndex !== draggedItemIndex) {
        articleApi.position(id, { position: draggedOverItemIndex })
          .then(() => {
            return articleApi.position(currentId, { position: draggedItemIndex });
          })
          .then(() => {
            toast.success('Berhasil Diperbarui!');
          })
          .catch(() => {
            toast.error('Terjadi Kesalahan!');
          })
          .finally(() => {
            // Reset drag item references
            dragItem.current = null;
            dragOverItem.current = null;
          });
      }

      // Update the local state with the reordered items
      setData(_items);
    }
  }, [data, currentId]);

  const setTableConfig = useCallback(
    (type) => (val, key) => {
      dispatch({
        module: ARTICLE_MODULE,
        type,
        value: val,
        key: key,
      });
    },
    [dispatch]
  );

  const onFilter = useCallback(
    (key) => (val) => {
      setTableConfig(FILTER_POSITION)(val, key);
    },
    [setTableConfig]
  );

  return (
    <div className="main-card card">
      <div className="card-body">
        <form>
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <SelectField
                label="Select Product"
                name="product"
                placeholder="Select Product"
                options={productOpts}
                onChange={(v) => {
                  onFilter('application')(v.value ?? '');
                }}
                value={application}
              />
            </div>
            <div className="col-12 col-md-6">
              <SelectField
                label="Select Type"
                name="type"
                placeholder="Select Type"
                options={UserAppOpts}
                onChange={(v) => {
                  onFilter('type')(v.value ?? '');
                }}
                value={type}
              />
            </div>
          </div>
        </form>
        <div className={styles.show}>
          {data.length !== 0 ? (
            <>
              <h5 className={styles.header}>{application && type ? `${application} - ${type}` : ''}</h5>
              <div className={styles.container}>
                {data.map((v, i) => (
                  <div
                    key={v._id}
                    draggable
                    onDragStart={() => {
                      dragItem.current = i;
                    }}
                    onDragEnter={() => {
                      dragOverItem.current = i;
                      setCurrentId(v._id);
                    }}
                    onDragEnd={() => {
                      handleSort(v._id);
                    }}
                    onDragOver={e => e.preventDefault()}
                    className={
                      i === dragItem
                        ? styles.draggedItem
                        : i === dragOverItem
                          ? styles.dragOverItem
                          : ''
                    }
                  >
                    <Card
                      number={i + 1}
                      title={v.name}
                      imageUrl={'/app-icon.png'}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
    </div>
  );
};

export default Position;
