import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useMountedState from '../HooksUse/useMountedState';
import SelectField from '../Forms/SelectField';
import { companyApi } from '../../services/companyApi';

const staticValues = {
  sort: 'createdAt|desc',
  limit: 10,
}

const CompanySelect = ({
  label,
  disabled,
  error,
  helperText,
  onChange,
  value,
  customOptions = []
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [options, setOptions] = useState([])
  const [customOpts,] = useState(customOptions)
  const [totalItems, setTotalItems] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const { sort, limit } = staticValues;
  const isMounted = useMountedState();

  const getUserListDebounced = useMemo(
    () => AwesomeDebouncePromise(companyApi.list, 500),
    [],
  )

  const dispatchLoad = useCallback(
    (call, page, limit, sort) => {
      return dispatch({
        type: null,
        call: call,
        args: [
          page,
          limit,
          sort,
        ]
      })
    },
    [dispatch],
  )

  const generateOpts = companies => companies.map(company => ({
    value: company._id,
    label: company.fullName,
    model: 'Company',
    phoneNumber: company.phoneNumber,
    optData: company
  }))

  const handleScrollToBottom = () => {
    if ((page * limit) < totalItems) {
      setIsLoading(true);
      const newPage = page + 1;
      setPage(newPage);
      dispatchLoad(companyApi.list, newPage, limit, sort)
        .then(({ data: { company, count } }) => {
          if (isMounted()) {
            setOptions([...options, ...generateOpts(company)])
            setTotalItems(count)
          }
        })
        .catch(() => { })
        .finally(() => { if (isMounted()) setIsLoading(false) });
    }
  }

  const handleSelectChange = opt => {
    setSelectedOption(opt)

    if (typeof onChange === 'function') {
      onChange(typeof value !== 'undefined'
        ? opt
        : opt ? opt.value : opt
      );
    }
  }

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      const newPage = 1;
      setPage(newPage);
      let options = []
      dispatchLoad(getUserListDebounced, newPage, limit, sort)
        .then(({ data: { company } = {} } = {}) => {
          if (isMounted() && company) options = [...generateOpts(company), ...options]
        })
        .catch(() => { })
        .finally(() => {
          if (isMounted()) {
            setOptions(options)
            setIsLoading(false)
          }
        });
    }

    loadData()
  }, [sort, limit, dispatchLoad, getUserListDebounced, customOpts, isMounted])

  return (
    <SelectField
      id="selectCompany"
      label={label ?? "Select Company"}
      options={options}
      onChange={handleSelectChange}
      value={typeof value !== 'undefined' ? value : selectedOption}
      placeholder="Type anything.."
      componentProps={{
        isClearable: true,
        isLoading: isLoading,
        onMenuScrollToBottom: handleScrollToBottom,
        isDisabled: disabled ? disabled : isLoading
      }}
      error={error}
      helperText={helperText}
    />
  )
}

export default CompanySelect
