import { combineReducers } from 'redux';
import list from './list';
import settlement from './settlement';
import user from './user';

export default combineReducers({
  list,
  settlement,
  user
});
